import * as React from 'react';
import * as styles from './SearchPageFilterGroupSkeleton.scss';
import Icon from '../../../Base/components/Icon/Icon';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';

class SearchPageFilterGroupSkeleton extends React.Component<{}, {}> {
	public render() {
		const randomNumber = Math.floor(Math.random() * (85 - 30 + 1)) + 30;
		const randomNumberDetail1 = Math.floor(Math.random() * (95 - 30 + 1)) + 30;
		const randomNumberDetail2 = Math.floor(Math.random() * (95 - 30 + 1)) + 30;
		const randomNumberDetail3 = Math.floor(Math.random() * (95 - 30 + 1)) + 30;
		const randomNumberDetail4 = Math.floor(Math.random() * (95 - 30 + 1)) + 30;


		return (
		
				<div className={styles.groupLabel}>
					<div className={styles.skeletonWrapper}>
						<span className={styles.skeletonToggle}>
							<Icon icon="arrow-down" />
						</span>
						<span className={styles.skeletonTitle}>
							<SkeletonInline width={randomNumber} />
						</span>
					</div>

					<div className={styles.skeletonDetailWrapper}>

						<span className={styles.skeletonDetail}>
							<SkeletonInline width={randomNumberDetail1} />
						</span>
						<span className={styles.skeletonDetail}>
							<SkeletonInline width={randomNumberDetail2} />
						</span>
						<span className={styles.skeletonDetail}>
							<SkeletonInline width={randomNumberDetail3} />
						</span>
						<span className={styles.skeletonDetail}>
							<SkeletonInline width={randomNumberDetail4} />
						</span>
						<span className={styles.skeletonDetail}>
							<SkeletonInline width={randomNumberDetail1} />
						</span>
						<span className={styles.skeletonDetail}>
							<SkeletonInline width={randomNumberDetail2} />
						</span>
						<span className={styles.skeletonDetail}>
							<SkeletonInline width={randomNumberDetail3} />
						</span>
					</div>
				</div>
		);
	}
}


export default SearchPageFilterGroupSkeleton;


