import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import DataStateService from '../../../../services/DataStateService';
import { SlugType } from '../../../Base/types/SlugType';
import NearestShop from '../../components/NearestShop/NearestShop';
import NearestShopSkeleton from '../../components/NearestShop/NearestShopSkeleton';
import { IShopType } from '../../types/IShopType';

interface INearestShopProps {
	vendorSlug: SlugType,
	queryKey: string,
}

class NearestShopContainerInner extends React.Component<INearestShopProps & IWithDataProps> {
	public render() {
		const { data } = this.props;

		return (
			<NearestShop
				shop={data as IShopType}
			/>
		);
	}
}

export default function NearestShopContainer(props: INearestShopProps) {
	const RenderComponent = withData(
		NearestShopContainerInner,
		'nearestShopReducer',
		`nearest-shop-${props.vendorSlug}-${DataStateService.getStateKey()}`,
		{
			store_vendor: props.vendorSlug,
		},
		{
			LoadingComponent: () => <NearestShopSkeleton />,
			ErrorComponent: () => null,
		}
	);

	return <RenderComponent {...props} />
}

