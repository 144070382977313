import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addPromotionworldEntry, setPromotionworldErrors, PROMOTIONWORLD_FETCH_ENTRY,
} from './promotionworldActions';



/**
 * Fetch entry list
 */
function* fetchPromotionworld(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);
	
	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: `/api/public/v1/promotion-worlds/${action.params.leafletSlug}`,
				} as IRequestObject
			);
		});

		yield put(addPromotionworldEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionworldErrors(action.queryKey, e));
	}
}

export function* promotionworldSaga() {
	yield takeEvery(PROMOTIONWORLD_FETCH_ENTRY, fetchPromotionworld);
}
