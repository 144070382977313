import * as React from 'react';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import LeafletImagePlaceholder from '../LeafletImagePlaceholder/LeafletImagePlaceholder';
import * as styles from './LeafletItem.scss';

interface ILeafletItemSkeletonProps {
	size: 's' | 'm' | 'l',
}

class LeafletItemSkeleton extends React.Component<ILeafletItemSkeletonProps, {}> {
	public static defaultProps = {
		size: 'm',
	};

	public render () {
		return (
			<a href="#" className={`${styles.leafletItemSkeleton} ${styles.leafletItem}`}>
				<LeafletImagePlaceholder activatedPlaceholder={true} alt="Placeholder" />

				<span className={styles.leafletItemContent}>
					<span className={styles.leafletItemLabel}>
						<SkeletonInline />
					</span>
					<span className={styles.leafletItemValid}>
						<SkeletonInline />
					</span>

				</span>
			</a>
		);
	}
}

export default LeafletItemSkeleton;
