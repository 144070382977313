/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { ILeafletType } from '../../types/ILeafletType';
import { IPromotionLeafletType } from '../../types/IPromotionLeafletType';

export const LEAFLET_WIDGET_FETCH_ENTRIES = '@BasePipeline/leafletWidgetReducer_FETCH_ENTRIES';
export const LEAFLET_WIDGET_FETCH_ENTRY = '@BasePipeline/leafletWidgetReducer_FETCH_ENTRY';
export const LEAFLET_WIDGET_ADD_ENTRIES = '@BasePipeline/leafletWidgetReducer_ADD_ENTRIES';
export const LEAFLET_WIDGET_ADD_ENTRY = '@BasePipeline/leafletWidgetReducer_ADD_ENTRY';

export const LEAFLET_WIDGET_SET_ERRORS = '@BasePipeline/leafletWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchLeafletWidgets(queryKey: string) {
    return {
        type: LEAFLET_WIDGET_FETCH_ENTRIES,
        queryKey,
    };
}

export function addLeafletWidgetEntries(queryKey: string, entries: ILeafletWidgetType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: LEAFLET_WIDGET_ADD_ENTRIES,
        queryKey,
        entries: entries.map(transformLeafletWidgetEntry),
        metaData,
        options,
    };
}

export function addLeafletWidgetEntry(queryKey: string, entry: ILeafletWidgetType, metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: LEAFLET_WIDGET_ADD_ENTRY,
        queryKey,
        entry: transformLeafletWidgetEntry(entry),
        metaData,
        options,
    };
}

export function setLeafletWidgetErrors(queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: LEAFLET_WIDGET_SET_ERRORS,
        queryKey,
        errors,
        metaData,
    };
}

function transformLeafletWidgetEntry(leafletWidgetEntry: ILeafletType | IPromotionLeafletType): ILeafletType | IPromotionLeafletType {
    return {
        ...leafletWidgetEntry,
        onlyRelativeDate: onlyRelativeDateHoferHack(leafletWidgetEntry),
    };
}

/// Applies a temporary hack for the 'Hofer Aktionswelten' to show special date-format
function onlyRelativeDateHoferHack(leafletWidgetEntry: ILeafletType | IPromotionLeafletType): boolean {
    // if we got a json with onlyRelativeDate, we use it
    if (typeof leafletWidgetEntry.onlyRelativeDate != 'undefined') {
        return leafletWidgetEntry.onlyRelativeDate ?? false;
    }

    // Otherwise use the special hack case for 'Hofer Aktionswelten' to show special date-format
    // This is just a temporary solution, because the backend is not ready yet and they need it urgently.
    // This should be removed, as soon as we get the `onlyRelativeDate`-flag from the backend.
    // Check for the `onlyRelativeDate`-flag again around 01.06.2023, then it should be there at the latest!
    // If we get the flag, then we can just remove this whole function and just assign the json value, like in lines 129 - 131
    if (leafletWidgetEntry.vendorSlug == 'hofer' && (leafletWidgetEntry.promotionWorld ?? false) == true) {
        return true;
    }

    return false;
}
