import {
	SET_SHOPPING_NOTE, SET_SHOPPING_STATE,
	SHOPPING_NOTE_SET_ERRORS,
} from './shoppingNoteActions';

export interface IShoppingNoteStateType {
	note: string;
	error: null | string;
	loaded: boolean;
	state: 'saved' | 'unsaved' | 'saving' | 'error' | null;
}

const initialState = {
	note: '',
	loaded: false,
	error: null,
	state: null,
} as IShoppingNoteStateType;


export function shoppingNoteReducer (
	state = initialState,
	action: any,
) {
	switch (action.type) {
		case SET_SHOPPING_NOTE:
			return Object.assign({}, state, { loaded: true, note: action.note });

		case SET_SHOPPING_STATE:
			return Object.assign({}, state, { state: action.state });

		case SHOPPING_NOTE_SET_ERRORS:
			return Object.assign({}, state, { error: action.error });

		default:
			return state;
	}
}
