import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import LoginContainer from '../App/modules/Account/containers/Login/LoginContainer';
import BaseRoute from './_BaseRoute';

class LoginRoute extends BaseRoute {
	public render() {
		return (
			<div>
				<Header />
				<MainNavigation />

				<LoginContainer />

				<Footer fluid={true} />
			</div>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		LoginRoute,
		`login`,
		{
			metaTitle: `Jetzt einloggen | Aktionsfinder`,
			metaDescription: `Sie wollen sich in Ihr Aktionsfinder-Konto einloggen? Hier haben Sie die Möglichkeit dazu! » Jetzt klicken und anmelden!`,
		} as IRouteMetadataSetterType
	)
);
