import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './FlexItem.scss';

interface IFlexItemProps {
	children: ReactChild | JSX.Element[] | string[],
	shrink?: number,
	grow?: number,
}

class FlexItem extends React.PureComponent<IFlexItemProps, {}> {
	private styleObj: any = {};

	public render () {
		const { children } = this.props;

		const shrinkClass = this.getShrinkClass();

		return (
			<div className={`${styles.flexItem} ${shrinkClass}`} style={this.styleObj}>
				{children}
			</div>
		);
	}

	private getShrinkClass(): string {
		const { grow, shrink } = this.props;

		// cover grows
		if (grow) {
			if (grow === 1) {
				return styles.flexItemGrow1;
			} else {
				this.styleObj.flexGrow = grow;
				return '';
			}
		}

		// cover shrinks
		if (shrink) {
			if (shrink === 1) {
				return styles.flexItemShrink1;
			} else {
				this.styleObj.flexShrink = shrink;
				return '';
			}
		}

		return styles.flexItemFlex1;
	}
}

export default FlexItem;
