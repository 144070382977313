import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IShopType } from '../../types/IShopType';
import * as shopActions from './shopActions';

export type ShopAction = ActionType<typeof shopActions>;

export interface IShopStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IShopType };
	readonly queries: { [queryKey: string]: IShopType[] };
}

export function shopReducer(
	state = baseInitialState as IShopStateType,
	action: ShopAction,
) {
	const Reducer = new BaseReducer('shopReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}
