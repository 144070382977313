import * as React from 'react';
import * as styles from './LotteryListItem.scss';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';

interface ILotteryListItemSkeletonProps {
    columns?: '2' | '3';
}

class LotteryListItemSkeleton extends React.Component<ILotteryListItemSkeletonProps> {

	public render () {
		const { columns } = this.props;

        let className = `${styles.lotteryListItem} ${styles['lotteryListItemColumns-'+ columns]}`;

		return (
        
            <span className={className}>
                
                <span className={styles.lotteryListItemImageContainer}>
                    <SkeletonBlock style={{ width: '100%', height: 110 }} />
                </span>

                <span className={styles.lotteryListItemTextContainer}>
                    
                    <span className={styles.lotteryListItemContent}>
                        <SkeletonInline />
                    </span>
         
                </span>
            </span>
		);
	}
}

export default LotteryListItemSkeleton;
