import * as React from 'react';
import Icon from '../../../Base/components/Icon/Icon';
import * as styles from './PromotionworldFooter.scss';
import Button from '../../../../components/General/Button/Button';
import ShopListWidgetContainer from '../../../Vendor/containers/ShopListWidgetContainer/ShopListWidgetContainer';
import { IPromotionworldType } from '../../types/IPromotionworldType';
import { ILeafletType } from '../../../Leaflet/types/ILeafletType';
import PromotionworldFooterMenu from './PromotionworldFooterMenu';
import { IFilterChip } from '../../../Tag/types/IFilterChip';

interface IPromotionworldFooterPropsType {
    promotionworld: IPromotionworldType;
    data: ILeafletType[];
    pageQueryKey: string;
    onActiveTagChange: (item: IFilterChip | null) => void;
}

interface IPromotionworldFooterStatesType {
    showMenu: string;
}

class PromotionworldFooter extends React.Component<IPromotionworldFooterPropsType, IPromotionworldFooterStatesType> {
    constructor(props: IPromotionworldFooterPropsType) {
        super(props);
        this.state = {
            showMenu: '',
        };
    }

    public render() {
        const { promotionworld, data, pageQueryKey, promotionworld: { leaflet }, onActiveTagChange } = this.props;

        const { showMenu } = this.state;

        const currentItemIdx = data.findIndex(item => item.slug === promotionworld.leaflet.slug);
		
		if (currentItemIdx === -1) {
			return null;
		}

        return (
            <React.Fragment>
                <div className={`${styles.promotionworldFooterContainer} ${showMenu ? styles.promotionworldFooterExpanded : ''}`}>

                    <div className={`${styles.promotionworldFooterWrapper}`}>

                        {!data[currentItemIdx + 1] ? null : (
                            <Button 
                                theme="primary"
                                uppercase={false}
                                customClassName={styles.promotionworldFooterToShopButton}
                                route="LeafletDetailRoute"
                                routeParams={{
                                    leaflet: data[currentItemIdx + 1].slug
                                }}
                                fontSize={14}
                                size={'l'}
                            >
                                Nächstes Prospekt
                                <Icon icon="arrow-right" className={styles.promotionworldFooterToShopButtonIcon} />
                            </Button>
                        )}

                        <div className={styles.promotionworldFooterActions}>
                            <button className={styles.promotionworldFooterOverviewWrapper} onClick={() => this.toggleMenu('overview')}>
                                <Icon icon="thumbnails" className={`${styles.promotionworldFooterIcon} ${showMenu === 'overview' ? styles.active : ''}`} title="Seitenübersicht" />
                            </button>

                            <ShopListWidgetContainer
                                widgetKey={`leaflet-page-${leaflet.slug}-shops-nearby`}
                                orderBy="distance"
                                orderDir="desc"
                                limit={10}
                                widgetType="leaflet"
                                leafletSlug={leaflet.slug}
                            >
                                <button
                                    className={`${styles.promotionworldFooterIcon} ${showMenu === 'shops' ? styles.active : ''}`}
                                    onClick={() => this.toggleMenu('shops')}
                                    title="Märkte"
                                >
                                    <Icon icon="vendor" />
                                    <span className={styles.promotionworldFooterLabel}>Filialen</span>
                                </button>
                            </ShopListWidgetContainer>
                        </div>

                    </div>
                </div>

                <PromotionworldFooterMenu
                    openMenuItem={showMenu}
                    onCloseMenu={this.toggleMenu}
                    promotionworld={promotionworld}
                    pageQueryKey={pageQueryKey}
                    onActiveTagChange={onActiveTagChange}
                />
            </React.Fragment>
        );
    }

    /**
     * Toggles the input footer visibility
     */
    private toggleMenu = (name: string) => {
        let action = name;

        if (name === this.state.showMenu) {
            action = '';
        }

        this.setState({
            showMenu: action,
        });
    };
}

export default PromotionworldFooter;
