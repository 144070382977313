import RequestMiddlewarePipeline from '../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../../../modules/Base/services/GeolocationService';
import { IRequestObject } from '../../../../boilerplate/redux/types/IRequestObject';

const LOCAL_SEARCH_HISTORY_KEY = 'seahi';
const LOCAL_SEARCH_HISTORY_MAX_SIZE = 5;

const SearchSuggestionService = {

	requestBouncerTimeout: undefined,

	popularSearchQueries: [],

	/**
	 * Gets the search suggestions when no search term is entered
	 *
	 * @param callbk
	 */
	getSearchSuggestionsWithoutTerm(callbk: any) {
		const suggestionArray = [];

		// add local history
		const localHistory = this.getSearchHistory();

		if (localHistory.length > 0) {
			suggestionArray.push({
				title: 'Zuletzt gesucht',
				suggestions: localHistory,
			})
		}

		// if we already have popularSearchQueries -> use them
		if (this.popularSearchQueries.length > 0) {
			suggestionArray.push({
				title: 'Top Suchbegriffe',
				suggestions: this.popularSearchQueries,
			});
			callbk(suggestionArray);

			return;
		} else {
			this.fetchPopularSearchQueries(() => {
				if (this.popularSearchQueries.length > 0) {
					suggestionArray.push({
						title: 'Top Suchbegriffe',
						suggestions: this.popularSearchQueries,
					});

					callbk(suggestionArray);
				}
			});
		}

	},

	/**
	 * Fetches popular search queries
	 *
	 * @param callbk
	 */
	fetchPopularSearchQueries(callbk: (searchTerms: string[]) => void) {
		const afCoreRequest = new AfCoreRequest('fetch-popular-queries');
		afCoreRequest.getRequest({
				url: '/api/public/v1/search/most-used',
				params: { size: 10 }
			} as IRequestObject).then((searchTerms: RequestMiddlewarePipeline) => {
				this.popularSearchQueries = searchTerms.getContent('content');

				if (callbk) {
					callbk(this.popularSearchQueries);
				}
		});
	},

	/**
	 * Adds a search term to the search history
	 *
	 * @param searchTerm
	 */
	addToSearchHistory(searchTerm: string) {
		const searchHistory = (SearchSuggestionService.getSearchHistory() || []);
		const searchTermSluggified = SearchSuggestionService.slugify(searchTerm);

		if (!searchTermSluggified || searchTermSluggified.length < 3) {
			return '';
		}

		// filter out similar queries from our history
		const searchHistoryCleaned = searchHistory.filter((historyItem: string) => {
			const itemSluggified = SearchSuggestionService.slugify(historyItem);

			return (searchTermSluggified !== itemSluggified);
		});

		// add it
		searchHistoryCleaned.unshift(searchTerm);

		// save it
		localStorage.setItem(LOCAL_SEARCH_HISTORY_KEY, JSON.stringify(searchHistoryCleaned.slice(0, LOCAL_SEARCH_HISTORY_MAX_SIZE)));
	},

	/**
	 * Slugify method to make sure we only insert unique values even if typing or spacing is different
	 * @param text
	 */
	slugify(text: string): string {
		return text.toString().toLowerCase()
		           .replace(/\s+/g, '-')
		           .replace(/[^\w\-]+/g, '')
		           .replace(/\-\-+/g, '-')
		           .replace(/^-+/, '')
		           .replace(/-+$/, '');
	},

	/**
	 * Get local search history
	 */
	getSearchHistory(): string[] {
		const searchHistory = localStorage.getItem(LOCAL_SEARCH_HISTORY_KEY);

		if (!searchHistory) {
			return [];
		}

		try {
			const parsedHistory = JSON.parse(searchHistory);

			if (typeof parsedHistory !== 'object') {
				return [];
			}

			return parsedHistory;
		} catch(e) {
			return [];
		}
	},

	/**
	 * Fetches autocomplete suggestions for a specific searchterm
	 *
	 * @param {string} searchTerm
	 * @param callbk
	 */
	getSuggestions(searchTerm: string, callbk: (suggestions: any) => void) {
		if (this.requestBouncerTimeout) {
			clearTimeout(this.requestBouncerTimeout);
		}

		if (!searchTerm || searchTerm.length < 1) {
			this.getSearchSuggestionsWithoutTerm(callbk);
			return;
		}

		if (!searchTerm || searchTerm.length < 3) {
			return;
		}

		this.requestBouncerTimeout = setTimeout(() => {
			const params = {
				q: searchTerm,
				size: 10,
			} as any;

			const currentLocation = GeolocationService.getCurrentLocation();
			params.lat       = currentLocation.lat;
			params.lng       = currentLocation.lng;

			if (!params.lat) {
				return;
			}

			const afCoreRequest = new AfCoreRequest('internal-event');
			afCoreRequest.getRequest({
				url: '/api/public/v2/search/suggest',
				params,
			} as IRequestObject).then((res: any) => {
				if (callbk) {
					const searchSuggestionOutputRaw = res.getContent();

					if (searchSuggestionOutputRaw && searchSuggestionOutputRaw.items && searchSuggestionOutputRaw.items.length > 0) {
						const searchSuggestions = searchSuggestionOutputRaw.items.map((searchSuggestionItem: { text: string, queryPlusText: string }) => {
							return searchSuggestionItem.queryPlusText;
						});

						callbk([{
							title: null,
							suggestions: searchSuggestions,
						}]);
					} else {
						callbk([]);
					}
				}
			}).catch(() => {

			});
		}, 200);
	},

} as any;

export default SearchSuggestionService;
