import { withRouter } from 'react-router';
import * as React from 'react';
import withShop from '../App/modules/Vendor/hocs/withShop';
import PromotionsInShopAndProductGroupContainer from '../App/modules/Promotion/containers/PromotionsInShopAndProductGroup/PromotionsInShopAndProductGroupContainer';
import BaseRoute from './_BaseRoute';


class PromotionsInShopAndProductGroupRoute extends BaseRoute {
	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<PromotionsInShopAndProductGroupContainer
				productGroupSlug={this.props.match.params.productGroup}
				shopSlug={this.props.match.params.shop}
				{...this.props}
			/>

		);
	}
}


export default withRouter((props: any) => {
	return (
		<React.Fragment>
			{withShop(
				PromotionsInShopAndProductGroupRoute,
				props.match.params.shop,
				props,
				PromotionsInShopAndProductGroupContainer
		)}
		</React.Fragment>
	);
});
