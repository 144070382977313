import { RouterProps } from 'react-router';
import * as React from 'react';
import Footer from '../../../../components/Layout/Footer/Footer';
import Header from '../../../../components/Layout/Header/Header';
import MainNavigation from '../../../../components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../../../../hocs/withRouteMetadata';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import PromotionsInShopAndProductGroup
	from '../../components/PromotionsInShopAndProductGroup/PromotionsInShopAndProductGroup';
import {
	pluralizerPromotionsAndSpecials,
	withTitlePostfix,
	withClickbait,
	maxDiscountInTitle, currentEntryWord
} from '../../../../utils/routeMetadataUtils';
import FlexWrapper from '../../../../components/Layout/Flexbox/FlexWrapper';
import AdItem from '../../../../components/General/AdItem/AdItem';
import AdConfigContainer from '../../../../components/General/AdConfig/AdConfigContainer';

class PromotionsInShopAndProductGroupContainer extends React.Component<RouterProps> {

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};
		
		return (
			<React.Fragment>

				{(!this.props.shop || !this.props.shop.vendor) ? null :
					<AdConfigContainer
						slug={this.props.shop.vendor.slug} 
						desktopIds={[4403491]}
						mobileIds={[4403480, 4403479]}
						superbannerId={4403492}
						sitebarId={4403490}
					/>
				}

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<PromotionsInShopAndProductGroup
							productGroupSlug={query.productGroup}
							shopSlug={query.shop}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

function generateTitle (routeMetadata: IRouteMetadataType, props: any) {
	let titleString = `${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)}`;

	if (props.shop) {
		titleString += ` bei ${props.shop.title}`;

		if (props.shop.address) {
			titleString += ` ${props.shop.address || ''}`;
		}
	
		if (props.shop.zipCode || props.shop.city) {
			titleString += `, ${props.shop.zipCode} ${props.shop.city}`;
		}
	} else {
		titleString += ` bei ${routeMetadata.shop.title}`;
	}

	return titleString;
}

function generateMetaTitle (routeMetadata: IRouteMetadataType, props: any) {
	let titleString = `${routeMetadata.totalItemsCount || ''} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)}`;

	if (props.shop) {
		titleString += ` bei ${props.shop.title}`;

		if (props.shop.address) {
			titleString += ` ${props.shop.address || ''}`;
		}

		if (props.shop.zipCode || props.shop.city) {
			titleString += `, ${props.shop.zipCode} ${props.shop.city}`;
		}
	} else {
		titleString += ` bei ${routeMetadata.shop.title}`;
	}

	titleString += maxDiscountInTitle(routeMetadata.maxItemDiscount);

	return withTitlePostfix(titleString);
}

function generateMetaDescription (routeMetadata: IRouteMetadataType, props: any) {
	let titleString = `Alle ${routeMetadata.productGroup.title} Angebote & Aktionen`;

	if (props.shop) {
		titleString += ` bei ${props.shop.title}`;

		if (props.shop.address) {
			titleString += ` ${props.shop.address || ''}`;
		}

		if (props.shop.zipCode || props.shop.city) {
			titleString += `, ${props.shop.zipCode} ${props.shop.city}`;
		}
	} else {
		titleString += ` bei ${routeMetadata.shop.title}`;
	}

	titleString += ' entdecken » Rabatt sichern ✓ Geld sparen';

	return withClickbait(titleString, `✓ Fündig werden!`);
}

export default withRouteMetadata(
	PromotionsInShopAndProductGroupContainer,
	(theProps: any) => {
		return `ppcs-${theProps.productGroupSlug}-${theProps.shopSlug}`;
	},
	{
		pageTitle: generateTitle,
		metaTitle: generateMetaTitle,
		metaDescription: generateMetaDescription,
	} as IRouteMetadataSetterType,
);
