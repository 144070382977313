import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Datespan from '../../../Base/components/Datespan/Datespan';
import Link from '../../../Base/components/Link/Link';
import { ILeafletType } from '../../types/ILeafletType';
import LeafletImagePlaceholder from '../LeafletImagePlaceholder/LeafletImagePlaceholder';
import * as styles from './LeafletItem.scss';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { showLeafletDiscountModal, setLeafletDiscountData, onlyRelativeDateHoferHack } from '../../store/Leaflet/leafletActions';
import Icon from '../../../Base/components/Icon/Icon';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import LeafletService from '../../services/LeafletService';
import LeafletRelativeValidDate from '../LeafletRelativeValidDate/LeafletRelativeValidDate';


const leafletPlaceHolderImage = require('./leafletImagePlaceholder.png');

interface ILeafletItemPropsType {
  leaflet: ILeafletType;
  size: 's' | 'm' | 'l';
  showLeafletDiscount?: (leaflet: ILeafletType) => void;
  href?: string;
}

interface ILeafletItemStatesType {
  isModalOpenned: boolean;
}

class LeafletItem extends React.Component<ILeafletItemPropsType, ILeafletItemStatesType> {
  public static defaultProps = {
    size: 'm'
  };

  public componentDidMount() {
    const { leaflet } = this.props;
    const expired = DateTime.local().startOf('day') > DateTime.fromISO(leaflet.validTo);

    // don't track it if it is expired
    if (expired || !leaflet?.id) {
      return;
    }
 
    InternalTrackingService.batchTrack(`leaflet-impression-${leaflet.id}`, 'LEAFLET_IMPRESSION', {
      industry_slug: leaflet.vendorIndustrySlugs?.join(','),
      leafletId: leaflet.id,
      vendorId: leaflet.vendorId,
    });
  }

  public render() {
    const { leaflet, href } = this.props;

    let style = styles.leafletItem;

    const expired = DateTime.local().startOf('day') > DateTime.fromISO(leaflet.validTo);

    if (expired || href) {
      style += expired ? ` ${styles.leafletItemExpired}` : '';

      return (
        <a className={`${style}`} href={href}>
          {this.getLeafletInnerItem()}
        </a>
      );
    }


    return (
      <div className={styles.leafletItemWrapper}>
        <Link { ...LeafletService.generateLeafletRoute(leaflet) }>
          <a className={`${style}`}>
            {this.getLeafletInnerItem()}
          </a>

          {/* TODO: readd this as soon as the unread feature is to implement. This is just the styling, nothing else has been done yet. {( leaflet.unread) ? null :*/}
          {/*<span className={styles.leafletItemUnreadIndicatorWrapper}>*/}
          {/*<span className={styles.leafletItemUnreadIndicator}></span>*/}
          {/*</span>*/}
          {/*}*/}
        </Link>
        <Link route='AppExclusiveRoute'>
          <a className={styles.leafletItemBookmark}>
            <Icon icon="heart" />
          </a>
        </Link>
      </div>
    );
  }

  /**
   * Returns the inner entry for a leaflet entry
   */
  private getLeafletInnerItem(): any {
    const { leaflet } = this.props;

    const onlyRelativeDate = leaflet.onlyRelativeDate || onlyRelativeDateHoferHack(leaflet);

    return (
      <React.Fragment>

        <LeafletImagePlaceholder alt={leaflet.title} />

        {(!leaflet.coverPage || !leaflet.coverPage.resolutions) ?
          <LeafletImagePlaceholder alt={leaflet.title} />
          :
          <ImageEnhanced
            className={styles.leafletItemImage}
            source={leaflet.coverPage.resolutions}
            size={'m'}
            alt={leaflet.title}
            placeHolderImage={(ref, theProps) => <img src={leafletPlaceHolderImage} ref={ref} {...theProps} alt={leaflet.title} />}
          // bgImage={true}
          />
        }

        <span className={`${styles.leafletItemContent}`} data-scope="leaflet-title">
          <span className={`${styles.leafletItemLabel}`}>
            {leaflet.title}
          </span>
          <span className={`${styles.leafletItemValid}`} data-scope="leaflet-description">
            <time>
              {onlyRelativeDate ?
                <LeafletRelativeValidDate
                  validFrom={leaflet.validFrom}
                  validTo={leaflet.validTo}
                />
                :
                <Datespan
                  from={leaflet.validFrom}
                  to={leaflet.validTo}
                />
              }
            </time>
          </span>

          <span className={`${styles.leafletItemTitelFadeout}`} /> 
          {/* Removed due to message at https://dev.azure.com/postat/Aktionsfinder/_workitems/edit/497665 */}
          {/* {(!leaflet.discountType || leaflet.discountType === 'NONE' || !showLeafletDiscount) ? <span className={`${styles.leafletItemTitelFadeout}`} /> :
            <span className={styles.leafletItemTitelFadeout}>
              <span className={styles.leafletItemSticker} onClick={() => showLeafletDiscount(leaflet)}>
                <Icon icon="postkasten" />
              </span>
            </span>
          } */}
        </span>

        {/* <HumanReadableDate
          from={leaflet.validFrom}
          to={leaflet.validTo}
          outputIfEnded="abgelaufen"
          type="short"
          className={styles.productPromotionExtraTime}
        /> */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
  return {
    showLeafletDiscount: (leaflet: ILeafletType) => {
      dispatch(setLeafletDiscountData(leaflet));
      dispatch(showLeafletDiscountModal());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeafletItem);
