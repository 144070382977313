import { withRouter } from 'react-router';
import * as React from 'react';
import { ReactNode } from 'react';

interface ISubNavigationGroupProps {
	children?: ReactNode | ReactNode[];
	submenuId: string;
	currentSubmenu: string;
}

class SubNavigationGroup extends React.Component<ISubNavigationGroupProps> {
	public render () {
		const { children, currentSubmenu, submenuId } = this.props;

		if (currentSubmenu !== submenuId) {
			return null;
		}

		return (
			<React.Fragment>
				{children}
			</React.Fragment>
		);
	}
}

export default withRouter(SubNavigationGroup);
