import * as React from 'react';
import ContentFragment from '../../components/ContentFragment/ContentFragment';
import ContentFragmentSkeleton from '../../components/ContentFragment/ContentFragmentSkeleton';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';

interface IContentFragmentContainerProps {
    fragmentKey: string,
    pageTitle: string,
}

class ContentFragmentContainerInner extends React.Component<IContentFragmentContainerProps & IWithDataProps> {

	public render () {
        const { data, pageTitle, fragmentKey } = this.props;

		return (
			<ContentFragment 				
				fragmentKey={fragmentKey}
                content={data.content}
                pageTitle={pageTitle}
            />
		);
	}
}


export default function ContentFragmentContainer (theProps: IContentFragmentContainerProps) {
	const props = Object.assign({
		
	}, theProps);

	const RenderComponent = withData(
		ContentFragmentContainerInner,
		'contentFragmentReducer',
		`contentFragment-container-${props.fragmentKey}`,
		{
			key: props.fragmentKey,
		},
		{
			LoadingComponent: () => <ContentFragmentSkeleton />
		}
	);

	return <RenderComponent {...props} />;
}


