import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import DataStateService from '../../../../services/DataStateService';
import { ILeafletType } from '../../types/ILeafletType';
import CurrentVendorLeaflet from '../../components/CurrentVendorLeaflet/CurrentVendorLeaflet';
import CurrentVendorLeafletSkeleton from '../../components/CurrentVendorLeaflet/CurrentVendorLeafletSkeleton';

interface ICurrentVendorLeafletProps {
	widgetKey: string;
	children: React.ReactNode;
	vendorSlug?: SlugType;
	title?: string;
	centered?: boolean;
}

class CurrentVendorLeafletContainerInner extends React.Component<ICurrentVendorLeafletProps & IWithDataProps> {
	public render () {
		const { data, children, ...restProps } = this.props;

		if (!data || data.length < 1) {
			return null;
		}

		return (
			<CurrentVendorLeaflet
				leaflet={data[0] as ILeafletType}
				children={children}
				{...restProps}
			/>
		);
	}
}


export default function CurrentVendorLeafletContainer (props: ICurrentVendorLeafletProps) {
	const limit = 1;

	const RenderComponent = withData(
		CurrentVendorLeafletContainerInner,
		'leafletWidgetReducer',
		`leaflet-widget-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			size: limit,
			store_vendor: props.vendorSlug,
		},
		{
			LoadingComponent: () => (
				<CurrentVendorLeafletSkeleton>{props.children}</CurrentVendorLeafletSkeleton>
			),
		},
	);

	return <RenderComponent {...props} />;
}
