import { SlugType } from '../../../Base/types/SlugType';
import { IShoppingListItemType } from '../../types/IShoppingListItemType';
import { IdType } from '../../../Base/types/IdType';

/* ### CONSTANTS ### */

export const ADD_SHOPPING_LIST_ITEM = '@BasePipeline/shoppingListReducer_ADD_ITEM';
export const UPDATE_SHOPPING_LIST_ITEM = '@BasePipeline/shoppingListReducer_UPDATE_ITEM';
export const REMOVE_SHOPPING_LIST_ITEM = '@BasePipeline/shoppingListReducer_REMOVE_ITEM';
export const REPLACE_SHOPPING_LIST_ITEM = '@BasePipeline/shoppingListReducer_REPLACE_ITEM';
export const SET_SHOPPING_LIST_STATE = '@BasePipeline/shoppingListReducer_SET_LIST_STATE';
export const GET_SHOPPING_LIST = '@BasePipeline/shoppingListReducer_GET_LIST';
export const SET_SHOPPING_LIST = '@BasePipeline/shoppingListReducer_SET_LIST';
export const ADD_SHOPPING_LIST_ITEM_LINE = '@BasePipeline/shoppingListReducer_ADD_ITEM_LINE';

export const SAVE_SHOPPING_LIST       = '@BasePipeline/shoppingListReducer_SAVE_LIST';
export const SHOPPING_LIST_SET_ERRORS = '@BasePipeline/shoppingListReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function getShoppingList () {
	return {
		type: GET_SHOPPING_LIST,
	};
}

export function addShoppingListItemLine () {
	return {
		type: ADD_SHOPPING_LIST_ITEM_LINE,
	};
}

export function addShoppingListItem (item: IShoppingListItemType) {
	return {
		type: ADD_SHOPPING_LIST_ITEM,
		item,
	};
}

export function updateShoppingListItem (item: IShoppingListItemType) {
	return {
		type: UPDATE_SHOPPING_LIST_ITEM,
		item,
	};
}

export function replaceShoppingListItem (replaceId: IdType | undefined, item: IShoppingListItemType) {
	return {
		type: REPLACE_SHOPPING_LIST_ITEM,
		item,
		replaceId,
	};
}

export function removeShoppingListItem (id: IdType) {
	return {
		type: REMOVE_SHOPPING_LIST_ITEM,
		id,
	};
}

export function setShoppingList (items: IShoppingListItemType[]) {
	return {
		type: SET_SHOPPING_LIST,
		items,
	};
}

export function setShoppingListSavingState (state: 'saved' | 'unsaved' | 'saving' | 'error' | null) {
	return {
		type: SET_SHOPPING_LIST_STATE,
		state,
	};
}

export function saveShoppingList (note: string) {
	return {
		type: SAVE_SHOPPING_LIST,
		note,
	};
}

export function setShoppingListErrors (error: string) {
	return {
		type: SHOPPING_LIST_SET_ERRORS,
		error,
	};
}
