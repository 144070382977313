import { call, put, takeEvery } from 'redux-saga/effects';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import CookieService from '../../../../services/CookieService';
import {
    ACCOUNT__FETCH_ENTRIES, accountLogout,
    addAccountEntries, setAccountData, setAccountErrors,
} from './accountActions';

/**
 * Fetch entry list
 */
function* fetchAccounts(action: any) {
    const afBeCoreRequest = new AfCoreRequest(action.queryKey);

    if (isServer()) {
        return;
    }

    const accessToken = CookieService.get('at');

    if (accessToken) {
        try {
            const response = yield call((): Promise<RequestMiddlewarePipeline> => {
                return afBeCoreRequest.getRequest({
                      url: '/api/v1/profile',
                      params: action.params,
                  } as IRequestObject);
            });

            yield put(addAccountEntries(
                action.queryKey,
                response.getContent(),
                response.getMetaData(),
                action.options,
            ));

            yield put(setAccountData(response.getContent()));

        } catch (e) {
            if (e.status === 401 || e.status === 404) {
                yield put(accountLogout());
            }

            yield put(setAccountErrors(action.queryKey, e));
        }
    } else {
        yield put({
                      type: 'SET_FETCHED_STATE',
                  });

        yield put(addAccountEntries(
            action.queryKey,
            [],
            {},
            action.options,
        ));
    }
}

export function* accountSaga() {
    yield takeEvery(ACCOUNT__FETCH_ENTRIES, fetchAccounts);
}
