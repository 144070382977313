import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IProductGroupWidgetType } from '../../types/IProductGroupWidgetType';
import * as productGroupWidgetActions from './productGroupWidgetActions';

export type ProductGroupWidgetAction = ActionType<typeof productGroupWidgetActions>;

export interface IProductGroupWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IProductGroupWidgetType };
	readonly queries: { [queryKey: string]: IProductGroupWidgetType[] };
}

export function productGroupWidgetReducer(
	state = baseInitialState as IProductGroupWidgetStateType,
	action: ProductGroupWidgetAction,
) {
	const Reducer = new BaseReducer('productGroupWidgetReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}
