import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './Skeleton.scss';

interface IColProps {
  children?: ReactChild,
  width?: number,
}

class SkeletonInline extends React.Component<IColProps, {}> {
  public shouldComponentUpdate() {
  	return false;
  }

  public render () {
    const { children, width } = this.props;

    return (
      <div className={styles.skeletonInline} style={{ minWidth: width }}>
        {children} &nbsp;
      </div>
    );
  }
}

export default SkeletonInline;
