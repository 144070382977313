import { withRouter } from 'react-router';
import * as React from 'react';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import AdItem from '../App/components/General/AdItem/AdItem';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import VendorListing from '../App/modules/Vendor/components/VendorListing/VendorListing';
import BaseRoute from './_BaseRoute';


class VoucherListingRoute extends BaseRoute {
	public static async getInitialProps(state: any) {
		const reduxData = await VendorListingRouteProps(state);

		return { reduxData };
	}

	public render() {
		return (
			<React.Fragment>
				<AdConfig
					desktopIds={[4403494]}
					mobileIds={[4403482, 4403481]}
					superbannerId={4403495}
					sitebarId={4403493}
				/>

				<Header />
				
				<MainNavigation />

				<FlexWrapper>
					<div>
						<VendorListing
							title="Alle  österreichischen Händler im Überblick"
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403493}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}


export default withRouter(
	withRouteMetadata(
		VoucherListingRoute,
		(props: any) => `vendorListing`,
		{
			metaTitle: `Alle Händler mit Aktionen im Überblick | Aktionsfinder`,
			metaDescription: `Alle verfügbaren Händler im Überblick bei Aktionsfinder » Hier klicken und über alle Einzelheiten informieren.`,
		} as IRouteMetadataSetterType,
	),
);
