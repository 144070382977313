import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';
import * as promotionsAggregatedByVendorsWidgetActions from './promotionsAggregatedByVendorsWidgetActions';

export type PromotionsAggregatedByVendorsWidgetAction = ActionType<typeof promotionsAggregatedByVendorsWidgetActions>;

export interface IPromotionsAggregatedByVendorsWidgetVendorType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IPromotionsAggregatedByVendorType };
	readonly queries: { [queryKey: string]: IPromotionsAggregatedByVendorType[] };
}

export function promotionsAggregatedByVendorsWidgetReducer(
	state = baseInitialState as IPromotionsAggregatedByVendorsWidgetVendorType,
	action: PromotionsAggregatedByVendorsWidgetAction,
) {
	const Reducer = new BaseReducer('promotionsAggregatedByVendorsWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
