import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import LoadingIndicator from '../../../../components/Layout/LoadingIndicator/LoadingIndicator';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import ConfirmLotteryRegistration from '../../components/ConfirmLotteryRegistration/ConfirmLotteryRegistration';
import GenericErrorMap from '../../../../components/Layout/GenericErrorMap/GenericErrorMap';
import serverErrorMap from '../../components/ConfirmLotteryRegistration/serverErrorMap';

interface IConfirmLotteryRegistrationContainerProps {
    mail: string,
    token: string,
}

class ConfirmLotteryRegistrationContainerInner extends React.Component<IConfirmLotteryRegistrationContainerProps & IWithDataProps> {

	public render () {
		return (
			<ConfirmLotteryRegistration />
		)
	}
}

export default function ConfirmLotteryRegistrationContainer (theProps: IConfirmLotteryRegistrationContainerProps) {
	const props = Object.assign({

	}, theProps);

	const RenderComponent = withData(
		ConfirmLotteryRegistrationContainerInner,
		'confirmLotteryRegistrationReducer',
		`confirmLotteryRegistration`,
		{
            email: props.mail,
            verificationToken: props.token,
        },
        {
	        ErrorComponent: (errorProps: any) => (
		        <GenericErrorMap error={errorProps} serverErrorMap={serverErrorMap}/>
	        ),
	        LoadingComponent: (errorProps: any) => (
		        <LoadingIndicator>
					Daten werden geprüft ...
		        </LoadingIndicator>
	        ),
        }
        
	);

	return <RenderComponent {...props} />;
}


