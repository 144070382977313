import * as React from 'react';
import BlankPixel from '../../../../components/General/BlankPixel/BlankPixel';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import * as styles from './VendorLogoWidget.scss';

interface IVendorLogoWidgetSkeletonProps {
	limit: number,
	title?: string | undefined;
	widgetKey?: string;
}

class VendorLogoWidgetSkeleton extends React.Component<IVendorLogoWidgetSkeletonProps, {}> {
	public static defaultProps = {
		widgetKey: 'vendor-logo-widget',
	};

	public render () {
		const { title, widgetKey, limit } = this.props;

		const PlaceholderCol = [];
		for (let i = 0; i < limit; i++) {
			PlaceholderCol.push(
				<div
					key={`${widgetKey}-placeholder-${i}`}
					className={styles.vendorLogoPlaceholder}
				>
					<SkeletonBlock noBorderRadius={true}>
						<BlankPixel />
					</SkeletonBlock>
				</div>,
			);
		}

		return (
			<WidgetBox title={title} style="none" noBackground={true}>
				<WidgetBoxContent>
					<div className={styles.vendorLogoWidgetWrapperOuter}>
						<div className={styles.vendorLogoWidgetWrapper}>
							{PlaceholderCol}
						</div>
					</div>
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default VendorLogoWidgetSkeleton;
