import 'intersection-observer';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import { configureStore, history } from './boilerplate/razzle-redux/configureStore';
import rootSaga from './App/sagas';
import { HelmetProvider } from 'react-helmet-async';
import TelemetryProvider from './App/providers/TelemetryProvider';
import appConfig from './App/config/appConfig';

declare const window: any;

// if we got a preloaded state -> use it
let preloadedState = {};
if (typeof window !== 'undefined' && window.__PRELOADED_STATE__) {
	preloadedState = (window.__PRELOADED_STATE__) ? JSON.parse(window.__PRELOADED_STATE__) : {}; // JSON.parse#(window.__PRELOADED_STATE__);
}

const store = configureStore(preloadedState);
store.runSaga(rootSaga);

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<HelmetProvider>
				<TelemetryProvider connectionString={appConfig.azureApplicationInsightsConnectionString}>
					<App appProps={preloadedState?.appProps} />
				</TelemetryProvider>
			</HelmetProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'),
);

if (module.hot) {
	module.hot.accept();
}
