import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import BrandDetailPageContainer from '../App/modules/Brand/containers/BrandDetailPage/BrandDetailPageContainer';
import { maxDiscountInTitle, pluralizerPromotionsAndSpecials, withTitlePostfix, withClickbait } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';

class BrandDetailRoute extends BaseRoute {
	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<BrandDetailPageContainer
							brandSlug={( query.brand ) ? query.brand : ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		BrandDetailRoute,
		(props: any) => {
			return (props.match && props.match.params) ? `b-${props.match.params.brand}` : 'brand-undefined';
		},
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => 
				`${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`Alle aktuellen ${routeMetadata.brand.title} Aktionen & Angebote bei Aktionsfinder » Preise vergleichen ⭐ Rabatt sichern ⭐ bares Geld sparen`, `⭐ Jetzt entdecken!`),
		} as IRouteMetadataSetterType
	)
);
