import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ProductGroupDetailPageContainer
	from '../App/modules/Category/containers/ProductGroupDetailPage/ProductGroupDetailPageContainer';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import AdItem from '../App/components/General/AdItem/AdItem';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';

class ProductGroupDetailRoute extends BaseRoute {

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						
						<ProductGroupDetailPageContainer
							queryKey={`productGroupDetail${query.productGroup}`}
							productGroup={query.productGroup + ''}
							{...this.props}
						/>
				
						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default ProductGroupDetailRoute;