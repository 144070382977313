import * as React from 'react';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import * as styles from './ProductPromotionDiscountBar.scss';

interface IProductPromotionDiscountBarSkeletonProps {
	size: 's' | 'l',
}

// TRANSLATE
class ProductPromotionDiscountBarSkeleton extends React.Component<IProductPromotionDiscountBarSkeletonProps, {}> {
	public static defaultProps = {
		size: 's',
	};

	public render () {
		const { size } = this.props;

		const sizeClassName = ( size === 's' ) ? styles.productPromotionDiscountSmall : styles.productPromotionDiscountLarge;

		return (
			<span className={`${styles.productPromotionDiscount} ${sizeClassName}`}>
		      <span className={styles.productPromotionDiscountValue}>
		        &nbsp;&nbsp;
		      </span>
		      <span className={styles.productPromotionDiscountType}>
		        &nbsp;&nbsp;
		      </span>
		    </span>
		);
	}
}

export default ProductPromotionDiscountBarSkeleton;
