import { IApiErrorType } from 'App/types/BaseTypes';
import AfCoreRequest from '../../../bootstrap/redux-data/AfCoreRequest';
import CookieService from '../../../services/CookieService';
import BookmarkService from '../../Bookmark/services/BookmarkService';
import serverErrorMap from '../components/Login/serverErrorMap';

const LoginService = {

	/**
	 * Allows the calling of an event
	 */
	sendLoginData (data: {}, stayLoggedIn: boolean, callbk: (response: IAccountLoginType) => void, errorCallbk: (error: string) => void) {

		const afCoreRequest = new AfCoreRequest('loginPage');

		afCoreRequest.postRequest({
			url: '/api/v1/auth/token',
			body: data,
		} as IRequestObject).then((res: any) => {
			const response = res.getContent();

			// save tokens
			if (stayLoggedIn) {
				CookieService.set('at', response.access_token, ( response.expires_in / 60 ));
				CookieService.set('rfshtkn', response.refresh_token, (60 * 24 * 31));
				CookieService.set('sli', '1', (60 * 24 * 31));
			} else {
				CookieService.set('at', response.access_token, ( response.expires_in / 60 ));
				CookieService.set('rfshtkn', response.refresh_token);
				CookieService.set('sli', '0');
			}

			// get data
			BookmarkService.initBookmarks();

			if (callbk) {
				callbk(response);
			}
		}).catch((error: IApiErrorType) => {

			const errStatus = (error[0] && error[0].status) ? error[0].status : (error.status) ? error.status : '';
			const err = (serverErrorMap[errStatus]) ? serverErrorMap[errStatus] : 'Nicht erfolgreich';

			if (errorCallbk) {
				errorCallbk(err);
			}
		});
	},

};

export default LoginService;
