import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ProductPromotionDetailPageContainer
	from '../App/modules/Promotion/containers/ProductPromotionDetailPage/ProductPromotionDetailPageContainer';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class PromotionDetailRoute extends BaseRoute {
	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<ProductPromotionDetailPageContainer
							promotion={`${query.promotion}`}
							promotionSlug={`${query.promotion}`}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(PromotionDetailRoute);
