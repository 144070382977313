import * as React from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import * as styles from './HeaderSearchItem.scss';

interface IHeaderSearchItemProps {
	result: string;
}

class HeaderSearchItem extends React.Component<IHeaderSearchItemProps> {
	public render () {
		const { result } = this.props;

		return (
				<a
					className={styles.item}
				>
					<span>
						{result}
					</span>
				</a>
		);
	}
}

export default HeaderSearchItem;
