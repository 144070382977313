import * as React from 'react';
import * as styles from '../AppExclusive.scss';
import InternalTrackingService from '../../../../../services/InternalTrackingService';
import DataLayerService from '../../../services/DataLayerService';

interface MyComponentState {
    operatingSystem: string;
}

class AppExclusiveMainBlock extends React.PureComponent<{}, MyComponentState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            operatingSystem: this.detectOperatingSystem(),
        };
    }
    
    public render() {
        let badgeHref = '';
        let linkAndroid = 'https://play.google.com/store/apps/details?id=at.aktionsfinder.app&referrer=utm_source%3Daf-pwa-app-landingpage%26utm_medium%3Dreferral';
        let linkApple = 'https://apps.apple.com/app/apple-store/id485749840?pt=854624&ct=af-pwa-app-landingpage&mt=8';
        let linkHuawei = 'https://appgallery.huawei.com/app/C101705493';

        // Switches the link based on the operating system
        switch (this.state.operatingSystem) {
            case 'Windows':
                badgeHref = linkAndroid;
                break;
            case 'iOS':
                badgeHref = linkApple;
                break;
            case 'Huawei':
                badgeHref = linkHuawei;
                break;
            default:
                badgeHref = linkAndroid;
        }

        return (
            <div className={styles.mainBlockWrapper}>
                <img src={require('../assets/title.svg')} alt='Die App für Prospekte & Aktionen in Österreich' className={styles.title} />
                <img src={require('../assets/AppTeaser.svg')} alt='Aktionsfinder App' className={styles.teaser} />
                <span className={styles.installWrapper}>
                    <a href='https://1aktionsfinder.qr1.at/9osucw'>
                        <img src={require('../assets/QRCode.svg')} alt='QR code' className={styles.qrcode} />
                    </a>

                    {/* Mobile */}
                    <span className={`${styles.appStoreButton} ${styles.mobile}`}>
                        <a href={badgeHref} target='_blank' onClick={() => this.trackAppEvent(badgeHref, 'Jetzt downloaden')}>
                            <span className={styles.appStoreText}>Jetzt downloaden</span>
                        </a>
                        <img src={require('../assets/RatingWhite.svg')} alt='Rating' />
                    </span>

                    {/* Desktop */}
                    <span className={`${styles.appStoreBadges} ${styles.desktop}`}>
                        <a href={linkAndroid} target='_blank' className={styles.appStoreAndroid} onClick={() => this.trackAppEvent(linkAndroid, 'Google Play')} />
                        <a href={linkApple} target='_blank' className={styles.appStoreApple} onClick={() => this.trackAppEvent(linkApple, 'App Store')} />
                        <a href={linkHuawei} target='_blank' className={styles.appStoreHuawei} onClick={() => this.trackAppEvent(linkHuawei, 'AppGallery')} />
                        <img src={require('../assets/Badges.svg')} alt='Badges' />
                    </span>
                </span>

                <img src={require('../assets/Shape.svg')} alt='Shape' className={styles.shape} />
                <img src={require('../assets/Affe.png')} alt='Affe' className={styles.monkey} />
                <img src={require('../assets/iphone.png')} alt='iPhone' className={styles.appPreview} />
            </div>
        );
    }

    /**
    * Determines the operating system of the device
    */
   private detectOperatingSystem() {
        if (typeof window !== 'undefined') {
            const userAgent = window.navigator.userAgent.toLowerCase();

            if (/windows/.test(userAgent)) {
                return 'Windows';
            } 

            if (/macintosh|mac os x|iPad|iPhone|iPod/.test(userAgent)) {
                return 'iOS';
            }
            
            if (/huawei/.test(userAgent)) {
                return 'Huawei';
            }
        }
        return 'Unknown';
    }

    /**
     * Track App click event
     */
	private trackAppEvent = (linkHref: string, buttonText: string) => {
        InternalTrackingService.track('PWA_TO_APP', {
            'targetUrl': linkHref,
            'pageTitle': document.title,
        });

        DataLayerService.push('PWA_TO_APP', {
			'targetUrl': linkHref,
            'pageTitle': document.title,
            'pagePath': window.location.pathname,
            'buttonText': buttonText,
        });
    }
}

export default AppExclusiveMainBlock;