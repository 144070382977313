import * as React from 'react';
import { IBrandType } from '../../types/IBrandType';
import * as styles from './BrandListGrouped.scss';
import BrandListGroup from './BrandListGroup';
import { IBrandGroupedType } from '../../types/IBrandGroupedType';
import Link from '../../../Base/components/Link/Link';

interface IBrandProps {
  brands: IBrandGroupedType[];
  gridKey?: string;
  contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}


class BrandListGrouped extends React.Component<IBrandProps, {}> {
  public static defaultProps = {
    gridKey: 'brand-list'
  };

  // TRANSLATE

  public render () {
    const { brands, gridKey, contentBreaks } = this.props;

    return ( 
        <div className={styles.brandListGroupedWrapper}>
          {brands.map((item: IBrandGroupedType, indx: number) => {
            const groupLetter = this.getGroup(item);

            return (
              <React.Fragment key={`vendor-list-${gridKey}`}>

                {(!contentBreaks || !contentBreaks[indx]) ? null :
									<React.Fragment>
										{contentBreaks[indx]}
									</React.Fragment>
								}

                <h2 className={styles.brandListGroupedTitle}>
                  {groupLetter}
                </h2>

                <BrandListGroup
                  brands={item.items.sort(this.sortAlphabetically)}
                />

                <div className={`${styles.brandListGroupedMoreItem}`}>
                  <Link
                    route="BrandsWithFirstLetterRoute"
                    params={{
                      letter: groupLetter
                    }}
                  >
                    <a className={styles.brandListGroupedMore}>
                      Alle Marken mit Anfangsbuchstabe "{groupLetter}" anzeigen
                    </a>
                  </Link>
                </div>

              </React.Fragment>
             )
          })} 
        </div>
    );
  }

  /**
   * Group brands by first letters of names.
   * Number brands are in "0-9" group.
   */
  private getGroup(brand: IBrandGroupedType) {

    if (brand.group.initialLetter) {
      return brand.group.initialLetter
    }

    const group = brand.items[0] && brand.items[0].name && brand.items[0].name.toUpperCase();

    return group;
  }

  /**
	 * Function which sorts vendors list alphabetically by name value
	 */
	private sortAlphabetically(a: IBrandType, b: IBrandType) {
		if (a.name.toLowerCase() < b.name.toLowerCase())
		  return -1;
		if (a.name.toLowerCase() > b.name.toLowerCase())
		  return 1;
		return 0;
	}
}

export default BrandListGrouped;
