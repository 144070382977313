import * as React from 'react';
import { ReactNode } from 'react';
import Col from '../../../modules/Base/components/Grid/Col';
import * as styles from './AppMainCol.scss';

interface IAppMainColProps {
	children: ReactNode | ReactNode[],
	boxed?: boolean,
	onTop?: boolean,
	darkBackground?: boolean,
	className?: string;
}

class AppMainCol extends React.Component<IAppMainColProps, {}> {
	public render() {
		const { children, boxed, onTop, darkBackground, className: customClassName } = this.props;

		let mainClass = styles.mainContent;

		if (!darkBackground) {
			mainClass += ` ${styles.mainContentColOuter}`
		} else {
			mainClass += ` ${styles.mainContentColOuterDarkBackground}`
		}

		let classStr = customClassName;

		if (onTop) {
			classStr += ` ${styles.boxedOnTop}`;
		}

		if (boxed) {
			return (
				<Col size={10} width={"66.66%"} className={mainClass}>
					<div className={`${styles.mainContentCol} ${styles.boxed} ${classStr}`}>
						{children}
					</div>
				</Col>
			);
		}

		return (
			<Col size={10} width={"66.66%"} className={mainClass}>
				<div className={styles.mainContentCol}>
					{children}
				</div>
			</Col>
		);
	}
}

export default AppMainCol;
