import * as React from 'react';
import * as styles from './BrandListGroup.scss';
import BrandListItemSkeleton from '../BrandListItem/BrandListItemSkeleton';


interface IBrandProps {
  gridKey?: string,
}

class BrandListGroupSkeleton extends React.Component<IBrandProps, {}> {
  public static defaultProps = {
    gridKey: 'brand-list'
  };

  // TRANSLATE

  public render () {
    const { gridKey } = this.props;

    return (
      <div className={styles.brandListGroupWrapper}>
        {[1,2,3,4,5,6,7].map(brand => {
          return (
            <div className={styles.brandListGroupItemWrapper} key={`${gridKey}`}>
              <BrandListItemSkeleton />
            </div>
          )
        })}
      </div>
    );
  }
}

export default BrandListGroupSkeleton;
