import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addmostUsedQueriesEntries,
	MOST_USED_QUERIES_FETCH_ENTRIES, setmostUsedQueriesErrors,
} from './mostUsedQueriesActions';

/**
 * Fetch entry list
 */
function* fetchmostUsedQueries(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/search/most-used',
				params: action.params,
			} as IRequestObject);
		});

		yield put(addmostUsedQueriesEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setmostUsedQueriesErrors(action.queryKey, e));
	}
}

export function* mostUsedQueriesSaga() {
	yield takeEvery(MOST_USED_QUERIES_FETCH_ENTRIES, fetchmostUsedQueries);
}
