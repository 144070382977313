import * as React from 'react';
import Link from '../../../../modules/Base/components/Link/Link';
import { IProductPromotionWithShopType } from '../../types/IProductPromotionWithShopType';
import * as styles from './ProductPromotionInOtherShopsItem.scss';
import Distance from '../../../Vendor/components/Distance/Distance';
import { IProductType } from '../../types/IProductType';
import ProductPromotionInOtherShopsItemDetails from './ProductPromotionInOtherShopsItemDetails';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';

interface IProductPromotionInOtherShopsItemProps {
  promotionWithShop: IProductPromotionWithShopType,
  size: 's' | 'm' | 'l',
}

class ProductPromotionInOtherShopsItem extends React.Component<IProductPromotionInOtherShopsItemProps, {}> {
  public static defaultProps = {
    size: 'm',
  };

  public render () {
    const { promotionWithShop } = this.props;

    const promotion = promotionWithShop.promotion;
    const product = (promotion.product) ? promotion.product : {} as IProductType;
    const shop = promotionWithShop.store;

    const vendorLogo = (shop.vendor && shop.vendor.resolutions) ? shop.vendor.resolutions : null;

    return (
      <Link
        route="PromotionDetailRoute"
        params={{ promotion: promotion.slug }}
      >
        <a
          className={styles.Item}
        >
          <span
            className={styles.ItemImage}
          >
	          {( !vendorLogo ) ? null :
	           <ImageEnhanced
		           source={vendorLogo}
		           alt={`${shop.vendor.name} Logo`}
	           />
	          }
          </span>

          <span className={styles.ItemContent}>
            <span className={styles.ItemLabel}>
              {shop.title}, {shop.address}, {shop.zipCode} {shop.city}
            </span>

            <span className={styles.Description}>
                {promotion.title} {product.productQuantity} {this.getProductQuantityUnit(product)} / {this.getPackageQuantityUnit(product)}
                <span className={styles.DescriptionDiscount}>
	                <ProductPromotionInOtherShopsItemDetails
		                promotion={promotion}
	                />
                </span>
            </span>

            <span className={styles.ItemNavi}>
              <Distance distanceInMeters={shop.distance} />
            </span>

          </span>
        </a>
      </Link>
    );
  }

	/**
	 * Returns the package quantity unit for this product
	 *
	 * @return {string}
	 */
	private getProductQuantityUnit (product: IProductType): string {
		if (!product || !product.productQuantityUnit || !product.productQuantityUnit.name) {
			return ''; // TRANSLATE
		}

		return product.productQuantityUnit.shortName;
	}

	/**
	 * Returns the package quantity unit for this product
	 *
	 * @return {string}
	 */
	private getPackageQuantityUnit (product: IProductType): string {
		if (!product || !product.packageQuantityUnit || !product.packageQuantityUnit.name) {
			return 'Stk.'; // TRANSLATE
		}

		return product.packageQuantityUnit.shortName;
	}
}

export default ProductPromotionInOtherShopsItem;
