import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { IdType } from '../../../Base/types/IdType';
import TagCloudWidget from '../../components/TagCloudWidget/TagCloudWidget';
import DataStateService from '../../../../services/DataStateService';

interface ITagCloudWidgetContainerProps {
	title: string;
	widgetKey: string;
	limit?: number;
	style?: IWidgetStyleType;
	orderBy?: 'relevance' | 'promotionCount' | 'rank';
	orderDir?: 'asc' | 'desc';

	grayscaled?: boolean;

	routeCallbk: (item: ITagWidgetItemType) => { name: string, params: {[key: string]: any} },

	itemCountLabel?: string;
	itemCountLabelPlural?: string;

	vendorSlug?: IdType;
	productGroupSlug?: IdType;
	shopSlug?: IdType;
	industrySlug?: IdType;
	brandSlug?: IdType[];
}

class TagCloudWidgetContainerInner extends React.Component<ITagCloudWidgetContainerProps & IWithDataProps> {
	public render () {
		const { title, data, style, widgetKey, grayscaled, routeCallbk, itemCountLabel, itemCountLabelPlural } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<TagCloudWidget
				title={title}
				tags={data}
				style={style}
				widgetKey={widgetKey}
				grayscaled={grayscaled}
				routeCallbk={routeCallbk}
				itemCountLabel={itemCountLabel}
				itemCountLabelPlural={itemCountLabelPlural}
			/>
		);
	}
}

export default function TagCloudWidgetContainer (props: ITagCloudWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;

	const RenderComponent = withData(
		TagCloudWidgetContainerInner,
		'tagWidgetReducer',
		`tag-cloud-widget-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			size: limit,

			store_vendor: props.vendorSlug,
			product_group: props.productGroupSlug,
			store: props.shopSlug,
			brand: props.brandSlug,
			industry: props.industrySlug,

			orderBy: props.orderBy,
			orderDir: props.orderDir,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={limit}
					style={props.style}
					widgetKey={props.widgetKey}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={limit}
					style={props.style}
					widgetKey={props.widgetKey}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
