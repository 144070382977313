import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import { IShoppingListItemType } from '../../types/IShoppingListItemType';
import {
	ADD_SHOPPING_LIST_ITEM,
	GET_SHOPPING_LIST, REMOVE_SHOPPING_LIST_ITEM, replaceShoppingListItem,
	SAVE_SHOPPING_LIST,
	setShoppingList,
	setShoppingListErrors,
	setShoppingListSavingState, UPDATE_SHOPPING_LIST_ITEM,
} from './shoppingListActions';

/**
 * fetch shoppingLists
 */
function* fetchShoppingList (action: any) {
	const afCoreRequest = new AfCoreRequest(`fetchShoppingList`);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: '/api/public/v1/personal-list/custom-item',
				params: { size: 150 },
			} as IRequestObject);
		});

		yield put(setShoppingList(
			response.getContent('content'),
		));
	} catch (e) {
		yield put(setShoppingListErrors('Konnte Notiz nicht laden. Bitte versuche es später erneut.'));
	}
}

/**
 * Add shoppingLists
 */
function* addShoppingListItemSaga (action: any) {
	const afCoreRequest = new AfCoreRequest(`saveShoppingListItem`);

	yield put(setShoppingListSavingState('saving'));

	try {
		const item = action.item as IShoppingListItemType;

		const { id, ...itemToSave } = item;

		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.postRequest({
				url: '/api/public/v1/personal-list/custom-item',
				body: itemToSave,
			} as IRequestObject);
		});

		yield put(replaceShoppingListItem(id, response.getContent()));
		yield put(setShoppingListSavingState('saved'));

	} catch (e) {
		yield put(setShoppingListSavingState('error'));
		yield put(setShoppingListErrors('Konnte Notiz nicht speichern. Bitte versuche es später erneut.'));
	}
}

/**
 * Update shoppingLists
 */
function* updateShoppingListItemSaga (action: any) {
	const afCoreRequest = new AfCoreRequest(`updateShoppingListItem`);

	yield put(setShoppingListSavingState('saving'));

	try {
		const item = action.item as IShoppingListItemType;

		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.putRequest({
				url: `/api/public/v1/personal-list/custom-item/${item.id}`,
				body: item,
			} as IRequestObject);
		});

		yield put(setShoppingListSavingState('saved'));

	} catch (e) {
		yield put(setShoppingListSavingState('error'));
		yield put(setShoppingListErrors('Konnte Notiz nicht speichern. Bitte versuche es später erneut.'));
	}
}

/**
 * Remove shoppingLists
 */
function* removeShoppingListItemSaga (action: any) {
	const afCoreRequest = new AfCoreRequest(`updateShoppingListItem`);

	yield put(setShoppingListSavingState('saving'));

	try {
		yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.deleteRequest({
				url: `/api/public/v1/personal-list/custom-item/${action.id}`,
			} as IRequestObject);
		});

		yield put(setShoppingListSavingState('saved'));

	} catch (e) {
		console.error('ERRR: ', e);
		yield put(setShoppingListSavingState('error'));
		yield put(setShoppingListErrors('Konnte Notiz nicht löschen. Bitte versuche es später erneut.'));
	}
}


export function* shoppingListSaga () {
	yield takeEvery(ADD_SHOPPING_LIST_ITEM, addShoppingListItemSaga);
	yield takeEvery(UPDATE_SHOPPING_LIST_ITEM, updateShoppingListItemSaga);
	yield takeEvery(REMOVE_SHOPPING_LIST_ITEM, removeShoppingListItemSaga);
	yield takeEvery(GET_SHOPPING_LIST, fetchShoppingList);
}
