import { IQueryMetaDataType } from "../../../../../boilerplate/redux/types/IQueryMetaDataType";
import { IApiErrorType } from "../../../../types/BaseTypes";

/* ### CONSTANTS ### */
export const VERIFY_MAIL_FETCH_ENTRIES = '@BasePipeline/verifyMailReducer_FETCH_ENTRIES';
export const VERIFY_MAIL_ADD_ENTRIES = '@BasePipeline/verifyMailReducer_ADD_ENTRIES';
export const VERIFY_MAIL_ADD_ENTRY = '@BasePipeline/verifyMailReducer_ADD_ENTRY';

export const VERIFY_MAIL_SET_ERRORS   = '@BasePipeline/verifyMailReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function addVerifyMailEntries (queryKey: string, entries: [], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VERIFY_MAIL_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setVerifyMailErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VERIFY_MAIL_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
