import * as React from 'react';
import * as styles from './ContentFragment.scss';
import H1 from '../../../../components/Layout/H1/H1';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';

interface IContentFragmentProps {
	content: string,
	pageTitle: string,
	fragmentKey: string,
}

class ContentFragment extends React.Component<IContentFragmentProps, {}> {
	public static defaultProps = {};

	public componentDidMount () {
		const { fragmentKey } = this.props;

		if (isBrowser()) {
			document.body.setAttribute('id', 'content-page-'+ fragmentKey);
		}
	}

	public componentWillUnmount () {
		if (isBrowser()) {
			document.body.setAttribute('id', ' ');
		}
	}

	public render() {
		const { content, pageTitle } = this.props;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
				<H1>
					{pageTitle}
				</H1>

				<div className={styles.contentFragmentWrapper}>
					<article className="contentFragmentContent">
						{(!content) ? null :
							<div dangerouslySetInnerHTML={{ __html: content }} />
						}
					</article>
				</div>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: this.props.pageTitle,
			} as IBreadcrumbItemType,
		];
	}
}

export default ContentFragment;
