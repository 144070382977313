import * as React from 'react';
import * as styles from './SearchPageFilterGroupCheckboxGroup.scss';
import SearchPageFilterGroupCheckboxGroupItem, { SearchPageFilterItemMetadataType, SearchPageFilterValueType } from './SearchPageFilterGroupCheckboxGroupItem';
import { SearchPageFilterInputType } from '../SearchPageFilterGroup';
import DrawerContentOutset from '../../../../Base/components/Drawer/DrawerContentOutset';
import Icon from '../../../../Base/components/Icon/Icon';
import { isMobile } from '../../../../../utils/responsiveUtils';

interface ISearchPageFilterGroupCheckboxGroupPropType {
	values: SearchPageFilterValueType;
	metadataKey: string;
	metadataOptions: SearchPageFilterItemMetadataType[];
	onChangeValue: (changeObj: {[key: string]: SearchPageFilterValueType}) => void;
	type: SearchPageFilterInputType;
	closeDrawer: () => void;
	hideEmptyOptions?: boolean;
	showSeachField?: boolean;
}

interface ISearchPageFilterGroupCheckboxGroupStateType {
	searchTerm: string;
	valueChange?: SearchPageFilterValueType;
}

class SearchPageFilterGroupCheckboxGroup extends React.Component<ISearchPageFilterGroupCheckboxGroupPropType, ISearchPageFilterGroupCheckboxGroupStateType> {
	constructor (props: ISearchPageFilterGroupCheckboxGroupPropType) {
		super(props);

		this.state = {
			searchTerm: '',
		};
	}

	public render () {
		const { values, metadataOptions, metadataKey, type, hideEmptyOptions, showSeachField } = this.props;
		const { searchTerm, valueChange } = this.state;

		const searchTermLower = searchTerm.toLowerCase();

		const localValues = (valueChange) ? valueChange : values;

		return (
			<div>
				<div className={styles.FilterGroupSearchFieldWrapper}>
					{!showSeachField ? null :
						<label htmlFor="filter-group-search-field" className={styles.FilterGroupSearchFieldOuter}>
							<span className={styles.FilterGroupSearchFieldIcon}>
								<Icon icon="search" />
							</span>

							<input
								type="text"
								placeholder="Suchen ..."
								value={searchTerm}
								id="filter-group-search-field"
								onChange={this.changeSearchTerm}
							/>
						</label>
					}

					<button
						type="button"
						onClick={this.submitCheckboxGroup}
					>
						Fertig
					</button>
				</div>

				<DrawerContentOutset>
					<div className={styles.FilterWrapper}>
						{
							metadataOptions.map((metadata: SearchPageFilterItemMetadataType) => {
								// if we don't want to show null options -> hide it
								if (hideEmptyOptions && metadata.count < 1) {
									return null;
								}

								// if we filtered for an answer, that doesn't match -> don't show it
								if (searchTermLower
										&& metadata.title.toLowerCase().indexOf(searchTermLower) === -1
								    && metadata.slug.toLowerCase().indexOf(searchTermLower) === -1) {
									return null;
								}

								return (
									<SearchPageFilterGroupCheckboxGroupItem
										key={`searchpage-filter-${metadataKey}-${metadata.slug}`}
										metadataKey={metadataKey}
										values={localValues}
										type={type}
										metadata={metadata}
										onChangeValue={this.changeValue}
									/>
								);
							})
						}
					</div>
				</DrawerContentOutset>
			</div>
		);
	}

	/**
	 * Updates the search term
	 *
	 * @param e
	 */
	private changeSearchTerm = (e: React.FormEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		this.setState({
			searchTerm: value,
		});
	}

	/**
	 * Submits a group selection and applies the filter
	 */
	private changeValue = (key: string, newValues: SearchPageFilterValueType) => {
		const { onChangeValue } = this.props;

		// if it is mobile -> we don't want to submit right now, but just save in state
		if (isMobile()) {
			this.setState({
			  valueChange: newValues,
			} as any);

			return;
		}

		onChangeValue({
			[key]: newValues,
		});
	}

	/**
	 * Submits a group selection and applies the filter
	 */
	private submitCheckboxGroup = () => {
		const { onChangeValue, metadataKey, closeDrawer } = this.props;

		// transfer the changes from this drawer to SearchPageFilters overview
		const changeObj = {};
		changeObj[metadataKey] = this.state.valueChange;
		onChangeValue(changeObj);

		// now close the drawer
		closeDrawer();
	}
}


export default SearchPageFilterGroupCheckboxGroup;


