/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IShoppingCenterGroupedType } from '../../types/IShoppingCenterGroupedType';

export const SHOPPING_CENTER_GROUPED_FETCH_ENTRIES = '@BasePipeline/shoppingCenterGroupedReducer_FETCH_ENTRIES';
export const SHOPPING_CENTER_GROUPED_FETCH_ENTRY   = '@BasePipeline/shoppingCenterGroupedReducer_FETCH_ENTRY';
export const SHOPPING_CENTER_GROUPED_ADD_ENTRIES = '@BasePipeline/shoppingCenterGroupedReducer_ADD_ENTRIES';
export const SHOPPING_CENTER_GROUPED_ADD_ENTRY = '@BasePipeline/shoppingCenterGroupedReducer_ADD_ENTRY';

export const SHOPPING_CENTER_GROUPED_SET_ERRORS   = '@BasePipeline/shoppingCenterGroupedReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchShoppingCenterGroupeds (queryKey: string) {
	return {
		type: SHOPPING_CENTER_GROUPED_FETCH_ENTRIES,
		queryKey,
	};
}

export function addShoppingCenterGroupedEntries (queryKey: string, entries: IShoppingCenterGroupedType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOPPING_CENTER_GROUPED_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addShoppingCenterGroupedEntry (queryKey: string, entry: IShoppingCenterGroupedType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOPPING_CENTER_GROUPED_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setShoppingCenterGroupedErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOPPING_CENTER_GROUPED_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
