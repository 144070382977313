import * as React from 'react';
import { isMobile } from '../../../../utils/responsiveUtils';
import {
    DrawerChildrenType
} from './DrawerItem';
import withDrawer from './withDrawer';
import { WithDrawerConsumerProps } from './DrawerType';
import { DrawerConfigType } from './DrawerProvider';

interface IDrawerPropType extends Omit<DrawerConfigType, 'content'> {
    children: DrawerChildrenType;
}

class Drawer extends React.PureComponent<IDrawerPropType & WithDrawerConsumerProps> {
    public static defaultProps = {
        position: 'right',
    };

    public constructor(props: IDrawerPropType & WithDrawerConsumerProps) {
        super(props);
    }

    public componentWillUnmount() {
        const { drawer, idname } = this.props;

        if (!isMobile()) {
            return;
        }

        // clean up when unmounting
        drawer.removeDrawer(idname);
    }

    public componentDidMount() {
    	const { drawer, idname, position, onOpenDrawer, onCloseDrawer, isOpen, children, level } = this.props;

    	if (!isMobile()) {
    	    return;
      }

    	// if no idname given -> nothing to do
      if (!idname) {
          console.warn('Tried to initialize a drawer without an idname. Please make sure to add the idname prop accordinly to make this drawer work correctly!');
          return;
      }

    	drawer.addDrawer({
          idname: idname,
          level: level,
          content: children,
          isOpen: isOpen,
          position: position,
          onCloseDrawer: onCloseDrawer,
          onOpenDrawer: onOpenDrawer,
      } as DrawerConfigType);
    }

    public componentDidUpdate(
        prevProps: Readonly<IDrawerPropType>
    ) {
        const { drawer, idname, position, onOpenDrawer, onCloseDrawer, isOpen, children, level } = this.props;

        if (!isMobile()) {
            return;
        }

        if (prevProps !== this.props) {
            drawer.addDrawer({
                idname: idname,
                level: level,
                content: children,
                isOpen: isOpen,
                position: position,
                onCloseDrawer: onCloseDrawer,
                onOpenDrawer: onOpenDrawer,
            } as DrawerConfigType);
        }
    }

    public render() {
        const { children, onCloseDrawer, isOpen } = this.props;

        if (!isMobile()) {
            return <React.Fragment>{children(onCloseDrawer)}</React.Fragment>;
        }

        return null;
    }
}

// TODO: transform into hook
export default withDrawer(Drawer);
