import * as React from 'react';
import * as styles from './ProductImagePlaceholder.scss';
import productImagePlaceholder from './productImageSizer.png';

interface IProductImagePlaceholderProps {
	className?: string,
	style?: any,
	alt?: string,
  innerRef?: (ref: HTMLImageElement) => void;
}

class ProductImagePlaceholder extends React.Component<IProductImagePlaceholderProps, {}> {
  public static defaultProps = {
    size: 'm',
  };

  public render () {
  	const { className, innerRef, ...restProps } = this.props;

  	let theClassName = styles.ResponsivePlaceholder;

  	if (className) {
  		theClassName += ` ${className}`;
    }

    return (
      <img className={theClassName} src={productImagePlaceholder} {...restProps} ref={innerRef} />
    );
  }
}

export default ProductImagePlaceholder;
