import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import H1 from '../App/components/Layout/H1/H1';
import Header from '../App/components/Layout/Header/Header';
import MobileAppWidget from '../App/components/Layout/MobileAppWidget/MobileAppWidget';
import AppMainCol from '../App/components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../App/components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../App/components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../App/components/Layout/PageWrapper/PageWrapper';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import Row from '../App/modules/Base/components/Grid/Row';
import BookmarkPageNavigation from '../App/modules/Bookmark/components/BookmarkPageNavigation/BookmarkPageNavigation';
import PromotionBookmarkListingPageContainer
	from '../App/modules/Bookmark/containers/PromotionBookmarkListingPage/PromotionBookmarkListingPageContainer';
import withPermission from '../App/hocs/withPermission';
import BaseRoute from './_BaseRoute';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';

class PromotionBookmarkListingRoute extends BaseRoute {
	public render () {
		const { routeMetadata } = this.props;

		return (

			<React.Fragment>
				<AdConfig
					desktopIds={[4493475]}
					sitebarId={4493473}
				/>

				<Header />
				<BookmarkPageNavigation />

				<FlexWrapper>
					<div>
						<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
							<div>
								<AdItem
									desktopAdId={4493475}
									maxHeight={250}
									type="billboard"
								/>
							</div>
							<H1>{routeMetadata.pageTitle}</H1>
							<Row>
								<AppMainCol
									boxed={true}
									onTop={true}
								>
									<AppMainColBox nested={true} boxed={true}>
										<PromotionBookmarkListingPageContainer />
									</AppMainColBox>
								</AppMainCol>

								<AppSideCol boxed={true} onTop={true}>
									<section>
										<MobileAppWidget />
									</section>
								</AppSideCol>
							</Row>
						</PageWrapper>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493473}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>
			</React.Fragment>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: "Meine Einkaufsliste",
			} as IBreadcrumbItemType,
		];
	}
}

export default withPermission(withRouter(
	withRouteMetadata(PromotionBookmarkListingRoute,
		'bookmark-promotion-listing',
		{
			pageTitle: () => 'Meine Einkaufsliste',
		} as IRouteMetadataSetterType),
));
