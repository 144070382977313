/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IPromotionworldGridGroupType } from '../../types/IPromotionworldGridGroupType';
import { IPromotionworldGroupedStateType } from './promotionworldGridGroupedReducer';

export const PROMOTIONWORLD_GROUPED_FETCH_ENTRIES = '@BasePipeline/promotionworldGroupedReducer_FETCH_ENTRIES';
export const PROMOTIONWORLD_GROUPED_FETCH_ENTRY   = '@BasePipeline/promotionworldGroupedReducer_FETCH_ENTRY';
export const PROMOTIONWORLD_GROUPED_ADD_ENTRIES = '@BasePipeline/promotionworldGroupedReducer_ADD_ENTRIES';
export const PROMOTIONWORLD_GROUPED_ADD_ENTRY = '@BasePipeline/promotionworldGroupedReducer_ADD_ENTRY';

export const PROMOTIONWORLD_GROUPED_SET_ERRORS   = '@BasePipeline/promotionworldGroupedReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchPromotionworldGrouped (queryKey: string) {
	return {
		type: PROMOTIONWORLD_GROUPED_FETCH_ENTRIES,
		queryKey,
	};
}

export function addPromotionworldGroupedEntries (queryKey: string, entries: IPromotionworldGridGroupType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONWORLD_GROUPED_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addPromotionworldGroupedEntry (queryKey: string, entry: IPromotionworldGroupedStateType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONWORLD_GROUPED_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setPromotionworldGroupedErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONWORLD_GROUPED_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
