import * as React from 'react';
import { ReactNode } from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import ImageEnhanced from '../../General/ImageEnhanced/ImageEnhanced';
import * as styles from './WidgetBox.scss';

interface IWidgetListItemProps {
	children?: ReactNode;
	image?: string | ReactNode,
	withImage?: boolean; // If this is set, there will be space even if we didn'T get an image to have all entries in line
	title: string | ReactNode,
	subtitle?: string | ReactNode,
	special?: string | ReactNode,
	subtitleExtra?: string | ReactNode,
	extra?: string | ReactNode,

	route?: string,
	href?: string,
	params?: { [ key: string ]: string },
}

class WidgetListItem extends React.Component<IWidgetListItemProps> {
	public static defaultProps = {
		showArrow: true,
	};

	public render () {
		const { route, params, href } = this.props;

		const routeProps = ( href ) ? { href } : {
			route,
			params,
		};

		if (!route && !href) {
			return (
				<span className={styles.widgetListItemWrapper}>
					{this.getListItemContent()}
				</span>
			);
		}

		return (
			<Link
				{...routeProps}
			>
				<a className={styles.widgetListItemWrapper}>
					{this.getListItemContent()}
				</a>
			</Link>
		);
	}

	private getListItemContent () {
		const { title, subtitle, special, subtitleExtra, extra } = this.props;

		return (
			<React.Fragment>
				{this.getImage()}

				<span className={styles.widgetListItemBlock}>
					<span className={styles.widgetListItemContent}>
						<span
							className={`${styles.widgetListItemTitle} ${( extra ) ? styles.widgetListItemTitleWide : ''}`}
						>
							{title}
						</span>

						{( !special ) ? null :
						 <span className={styles.widgetListItemSpecial}>
							{special}
						</span>
						}

						{( !subtitle ) ? null :
						 <span className={styles.widgetListItemSubtitle}>
							{subtitle}

							{( !subtitleExtra ) ? null :
							  	<span className={styles.widgetListItemSubtitleExtra}>
									{subtitleExtra}
								</span>
							}
						</span>
						}
					</span>

					{( !extra ) ? null :
					 	<span className={styles.widgetListItemExtra}>
							{extra}
						</span>
					}
				</span>
			</React.Fragment>
		);
	}

	/**
	 * Returns the correct image depending on image type
	 */
	private getImage () {
		const { image, withImage } = this.props;

		if (!image) {
			if (withImage) {
				return (
					<span className={styles.widgetListItemImageWrapperPlaceholder} />
				);
			}

			return null;
		}

		if (typeof image === 'string') {
			return (
				<span className={styles.widgetListItemImageWrapper}>
			        <span>{image}</span>
				</span>
			);
		}

		if (image && ( image.type === 'ImageResolution' || image.small )) {
			return (
				<span className={styles.widgetListItemImageWrapper}>
					<ImageEnhanced
						source={image}
					/>
				</span>
			);
		}

		return (
			<span className={styles.widgetListItemImageWrapper}>
			    <img src={image} alt={( typeof title === 'string' ) ? title : ''} />
			</span>
		);
	}
}

export default WidgetListItem;
