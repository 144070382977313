import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import { CAROUSEL_FETCH_ENTRIES, addCarouselTeasers, setCarouselTeasersErrors } from './carouselActions';
import { checkServerIdentity } from 'tls';

/**
 * Fetch entry list
 */
function* fetchCarouselTeasers(action: any) {
    const afCoreRequest = new AfCoreRequest(action.queryKey);

    try {
        const response = yield call(
            (): Promise<RequestMiddlewarePipeline> => {
                return afCoreRequest.getRequest({
                    url: '/api/public/v1/teasers/',
                    params: {},
                } as IRequestObject);
            },
        );

        yield put(addCarouselTeasers(action.queryKey, response.getContent('content'), response.getMetaData(), action.options));
    } catch (e) {
        yield put(setCarouselTeasersErrors(action.queryKey, e));
    }
}

export function* carouselSaga() {
    yield takeEvery(CAROUSEL_FETCH_ENTRIES, fetchCarouselTeasers);
}
