import * as React from 'react';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import TipBox from '../../../../components/Layout/TipBox/TipBox';
import ProductPromotionGrid from '../../../Promotion/components/ProductPromotionGrid/ProductPromotionGrid';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import ShoppingNoteContainer from '../../containers/ShoppingNote/ShoppingNoteContainer';
import * as styles from '../VendorBookmarkListingPage/VendorBookmarkListingPage.scss';
import ShoppingListContainer from '../../containers/ShoppingList/ShoppingListContainer';


interface IPromotionBookmarkListingPageProps {
    bookmarks: IProductPromotionType[],
}

class PromotionBookmarkListingPage extends React.Component<IPromotionBookmarkListingPageProps, {}> {
	public static defaultProps = {};

	public render () {
		const { bookmarks } = this.props;

		return (
			<div className={styles.Wrapper}>
				<TipBox>
					Auf deiner Einkaufsliste findest du alle germerkten Aktionen.
				</TipBox>

				<ShoppingNoteContainer />

				{/* <ShoppingListContainer /> */}

				<ProductPromotionGrid
					products={bookmarks}
					enablePagination={true}
					showBookmarkIcon={true}
				>
					<NoDataError />
				</ProductPromotionGrid>
			</div>
		);
	}
}

export default PromotionBookmarkListingPage;
