import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionInOtherShops from '../../components/ProductPromotionInOtherShops/ProductPromotionInOtherShops';
import DataStateService from '../../../../services/DataStateService';
import ProductPromotionInOtherShopsSkeleton from './ProductPromotionInOtherShopsContainerSkeleton';

interface IProductPromotionInOtherShopsProps {
	title: string;
	widgetKey: string;

	limit?: number;
	radius?: number;
	referenceSlug?: SlugType;
}

class ProductPromotionInOtherShopsInnerContainer extends React.Component<IProductPromotionInOtherShopsProps & IWithDataProps> {
	public render() {
		const { title, data } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<ProductPromotionInOtherShops
				title={title}
				promotions={data}
			/>
		);
	}
}

export default function ProductPromotionInOtherShopsContainer(props: IProductPromotionInOtherShopsProps) {
	const limit = (props.limit) ? props.limit : 5;
	const radius = (props.radius) ? props.radius : 50000;

	const RenderComponent = withData(
		ProductPromotionInOtherShopsInnerContainer,
		'productPromotionInOtherShopReducer',
		`product-promotion-in-other-shop-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			size: limit,
			radius,
			reference: props.referenceSlug,
		}, {
			LoadingComponent: () => <ProductPromotionInOtherShopsSkeleton />
		}
	);

	return <RenderComponent {...props} />
}
