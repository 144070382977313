const serverErrorMap = {
    401: 'E-Mail Adresse oder Passwort ungültig.',
    400: 'Fehlerhafte Eingabe',
    403: 'Diese Aktion ist leider nicht erlaubt.',
    409: 'Diese E-Mail Adresse nimmt bereits an unserem Gewinnspiel teil.',
    404: 'Konnte nicht gefunden werden',
    424: 'Bei deiner Anmeldung ist ein Fehler aufgetreten. Bitte versuche es erneut oder wende dich an office@aktionsfinder.at um Hilfe zu erhalten.',
    500: 'Leider ist ein unerwarteter Fehler aufgetreten, bitte versuche es erneut.'
};

export default serverErrorMap;
