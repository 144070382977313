import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import MobileAppWidget from '../../../../components/Layout/MobileAppWidget/MobileAppWidget';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';
import CurrentLeafletWidgetContainer from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';


interface IPromotionsInProductGroupAndVendorProps {
	vendorSlug: SlugType;
	productGroupSlug: SlugType;
}

class PromotionsInProductGroupAndVendor extends React.Component<IPromotionsInProductGroupAndVendorProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, productGroupSlug, vendorSlug } = this.props;

		const vendorName = (routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title) ? routeMetadata.vendor.title : vendorSlug;
		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		if (!routeMetadata) {
			return null;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<ProductPromotionGridContainer
								limit={20}
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								radius={100000}
								vendorSlug={vendorSlug}
								productGroupSlug={productGroupSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
	
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>

							
						<CurrentLeafletWidgetContainer
							widgetKey={`promotions-vendor-leaflet-widget-${vendorSlug}`}
							title={`Der aktuelle ${vendorName} Prospekt`}
							btnLabel={`Alle ${vendorName} Prospekte`}
							vendorSlug={vendorSlug}
							limit={1}
							orderBy="validFrom"
							orderDir="asc"
							btnRoute="LeafletsByVendorRoute"
							btnParams={{
								vendor: vendorSlug
							}}
						/>
							
						<div>
							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<MobileAppWidget />
						</div>

					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.vendor.title : '',
				route: 'PromotionsByVendorRoute',
				routeParams: {vendor: this.props.vendorSlug},
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.productGroup.title : '',
				route: 'ProductGroupDetailRoute',
				routeParams: {productGroup: this.props.productGroupSlug},
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.pageTitle : '',
			} as IBreadcrumbItemType,
		];
	}
}

export default PromotionsInProductGroupAndVendor;
