import React, { Component } from 'react';
import * as styles from './PromotionBadge.scss';
import { IProductPromotionType } from '../../types/IProductPromotionType';

interface PromotionBadgeProps {
  promotion: IProductPromotionType;
  size?: string;
  percentage?: number;
}

class PromotionBadge extends Component<PromotionBadgeProps> {
  getBadgeContent() {
    const { promotion, percentage } = this.props;

    if (promotion.isPriceHit) {
        return 'Top Deal';
    } else if (promotion.type === 'voucher') {
        return 'Gut-schein';
    } else if (promotion.type === 'assortment') {
        return `-${percentage != null ? percentage.toFixed(0) : 0}%`;
    } else {
        return null;
    }
  }

  render() {
    const { size } = this.props;
    const badgeContent = this.getBadgeContent();

    const badgeStyle: React.CSSProperties = {
      transform: `scale(${size === 'm' ? 1.2 : size === 'l' ? 1.4 : 1})`,
    };

    return (
      <div className={styles.badgeWrapper} style={badgeStyle}>
        <div className={styles.badgeImage}>
          <img src={require('./badge.png')} alt="Badge" />
        </div>
        <div className={styles.badgeShadow}>
          <img src={require('./Shadow.svg')} alt="Shadow" />
        </div>
        <div className={styles.badgeContent}>
          <span>{badgeContent}</span>
        </div>
      </div>
    );
  }
}

export default PromotionBadge;
