import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import ThemeworldCards from '../ThemeworldCards/ThemeworldCards';
import TagCloudWidgetContainer from '../../../Tag/containers/TagCloudWidget/TagCloudWidgetContainer';
import ThemeworldCardsSkeleton from '../ThemeworldCards/ThemeworldCardsSkeleton';
import AdItem from '../../../../components/General/AdItem/AdItem';
import { IThemeworldType } from '../../types/IThemeworldType';
import * as styles from './ThemeworldListing.scss';


interface IThemeworldListingProps {
	themeworlds?: IThemeworldType[];
}

class ThemeworldListing extends React.Component<IThemeworldListingProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { themeworlds } = this.props;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
				<H1>
					Themenwelten
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<div className={styles.themeworldListingWrapper}>
							{( themeworlds ) ?
							 <ThemeworldCards
								 widgetKey="themeworld-listing-story-cards"
								 themeworlds={themeworlds}
							 />
			                 :
							 <ThemeworldCardsSkeleton
								 boxed={true}
							 />
							}
						</div>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<TagCloudWidgetContainer
								title="Beliebte Themen"
								widgetKey={`themeworld-listing-tag-widget`}
								routeCallbk={this.routerCallbkTagListWidget}
								orderBy="rank"
								orderDir="desc"
							/>

							<AdItem
								desktopAdId={4493482}
								maxHeight={250}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {

		return [
			{
				label: 'Themenwelten',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkTagListWidget = (item: ITagWidgetItemType) => {
		return {
			name: 'TagDetailRoute',
			params: {
				tag: item.group.slug,
			},
		};
	}

}

export default ThemeworldListing;
