import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import ShopsByVendorAndCity from '../App/modules/Vendor/components/ShopsByVendorAndCity/ShopsByVendorAndCity';
import { pluralizerShops, withTitlePostfix, withClickbait } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';
import withData from '../boilerplate/redux/hocs/withData';


class ShopsByVendorAndCityRouteInner extends BaseRoute {
	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403494]}
					mobileIds={[4403482, 4403481]}
					superbannerId={4403495}
					sitebarId={4403493}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>

						<ShopsByVendorAndCity
							vendorSlug={query.vendor + ''}
							citySlug={query.city + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403493}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

function ComponentWithVendor(props) {
	const vendorSlug = (props.match.params.vendor) ? props.match.params.vendor : null;


	const Comp = withRouteMetadata(
		ShopsByVendorAndCityRouteInner,
		(props: any) => `svc-${props.match.params.vendor}-${props.match.params.city}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${routeMetadata.vendor.title} ${pluralizerShops(routeMetadata.totalItemsCount)} in ${routeMetadata.city.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.vendor.title.toUpperCase()} ${routeMetadata.city.title} » Öffnungszeiten & Filialen`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle ${routeMetadata.totalItemsCount || ''} ${routeMetadata.vendor.title.toUpperCase()} Filialen in ${routeMetadata.city.title} im Überblick » aktuelle Öffnungszeiten ✓ Aktionen & Angebote ✓ Geld sparen`, `✓ Jetzt auf Schnäppchenjagd gehen!`),
		} as IRouteMetadataSetterType
	);

	return (
		<>
			<Comp {...props} vendorSlug={vendorSlug} />
		</>
	);
};

export default class ShopsByVendorAndCityRoute extends BaseRoute {

	public render() {
		const props = this.props;

		const ComponentWithRouter = withRouter(
			ComponentWithVendor
		);

		return (
			<div>
				<ComponentWithRouter {...props} />
			</div>
		);
	}
}
