/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IBrandWidgetType } from '../../types/IBrandWidgetType';

export const BRAND_WIDGET_FETCH_ENTRIES = '@BasePipeline/brandWidgetReducer_FETCH_ENTRIES';
export const BRAND_WIDGET_FETCH_ENTRY   = '@BasePipeline/brandWidgetReducer_FETCH_ENTRY';
export const BRAND_WIDGET_ADD_ENTRIES = '@BasePipeline/brandWidgetReducer_ADD_ENTRIES';
export const BRAND_WIDGET_ADD_ENTRY = '@BasePipeline/brandWidgetReducer_ADD_ENTRY';

export const BRAND_WIDGET_SET_ERRORS   = '@BasePipeline/brandWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchBrandWidgets (queryKey: string) {
	return {
		type: BRAND_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addBrandWidgetEntries (queryKey: string, entries: IBrandWidgetType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addBrandWidgetEntry (queryKey: string, entry: IBrandWidgetType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_WIDGET_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setBrandWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
