import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import Link from '../../../Base/components/Link/Link';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import * as styles from './ProductPromotionInLeafletGrid.scss';
import PromotionImage from '../PromotionImage/PromotionImage';
import Percentage from '../../../Base/components/Percentage/Percentage';
import Currency from '../../../Base/components/Currency/Currency';
import { DateTime } from 'luxon';
import Scroller from '../../../../components/Layout/Scroller/Scroller';

interface IPromotionGridProps {
	products: IProductPromotionType[],
}

class ProductPromotionInLeafletGrid extends React.Component<IPromotionGridProps & IWithDataProps, {}> {
	public static defaultProps = {
		gridKey: 'lfgrid',
		enablePagination: true,
	};

	public render() {
		const { products } = this.props;

		return (
			<div className={styles.promotion}>

				<Scroller height={230}>

					{products.map((product: IProductPromotionType) => {

						const statusClassName = ( DateTime.local().startOf('day') > product.validTo ) ? styles.inactiveItem : styles.activeItem;
						
						return (
							<div
								className={`${styles.promotionItem} ${statusClassName}`}
								key={`promotion-product-leaflet-grid-${product.slug}`}
							>
								<div className={styles.promotionItemInner}>
									<Link
										route="PromotionDetailRoute"
										params={{promotion: product.slug}}
									>
										<a className={styles.productPromotionItem}>

											<span className={styles.productPromotionItemImageContainer}>
												<PromotionImage
													promotion={product}
													className={styles.productPromotionItemImage}
													alt={product.fullDisplayName ?? product.title}
													size="m"
												/>
											</span>

											<span className={styles.productPromotionItemContent}>
												<span className={styles.productPromotionItemLabel}>
													{product.fullDisplayName ?? product.title}
												</span>

												<span className={styles.productPromotionItemInfo}>
													{this.getDiscountValue(product)}&nbsp;

													{( !product.discountPercentage ) ? null :
														<span>(<Percentage amount={product.discountPercentage} />)</span>
													}
												</span>
											</span>

										</a>
									</Link>
								</div>
							</div>
						)})
					}
				</Scroller>
			</div>
		);
	}

	/**
	 * Returns the correct discount value based on the available discounts we have
	 */
	private getDiscountValue(product): string | React.ReactNode {

		if (product.newPrice) {
			return (
				<Currency
					amount={product.newPrice}
					currency={product.currency}
				/>
			);
		}

		if (product.discountAmount) {
			return (
				<Currency
					amount={product.discountAmount}
					currency={product.currency}
				/>
			);
		}

		if (product.discountedPrice) {
			return (
				<Currency
					amount={product.discountedPrice}
					currency={product.currency}
				/>
			);
		}

		return '';
	}
}

export default ProductPromotionInLeafletGrid;