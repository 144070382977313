import * as React from 'react';
import { IVendorType } from '../../types/IVendorType';
import * as styles from './VendorList.scss';
import VendorListItem from './VendorListItem';

interface IVendorProps {
  vendors: IVendorType[],
  gridKey?: string
}

class VendorList extends React.Component<IVendorProps, {}> {
  public static defaultProps = {
    gridKey: 'vendor-list'
  };

  // TRANSLATE

  public render () {
    const { vendors, gridKey } = this.props;

    return (
        <div className={styles.vendorListWrapper}>
          {vendors.map(vendor => {
            return (
              <div className={styles.vendorListItemWrapper} key={`${gridKey}-${vendor.slug}`}>
                <VendorListItem
                  vendor={vendor}
                />
              </div>
            )
          })}
        </div>
    );
  }
}

export default VendorList;
