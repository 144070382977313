import * as React from 'react';
import * as styles from './AppExclusive.scss';
import AppIcon from '../../../../components/Layout/MobileAppBanner/AppIcon.svg';
import HistoryService from '../../../../services/HistoryService';
import { history } from '../../../../../boilerplate/razzle/history';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import AppExclusiveAlertBox from './components/AppExclusiveAlertBox';
import AppExclusiveMobileBanner from './components/AppExclusiveMobileBanner';
import AppExclusiveMainBlock from './components/AppExclusiveMainBlock';
import AppExclusiveAdvantages from './components/AppExclusiveAdvantages';
import AppExclusiveMobileButton from './components/AppExclusiveMobileButton';
import AppExclusiveRatings from './components/AppExclusiveRatings';
import Button from '../../../../components/General/Button/Button';

interface IAppExclusiveProps {
    showAlertBox?: boolean;
}
class AppExclusive extends React.PureComponent<IAppExclusiveProps, { showAlertBox: boolean }> {

    constructor(props: IAppExclusiveProps) {
        super(props);
        this.state = {
            showAlertBox: true,
        };
    }

    // the info alert is displayed only when the page is navigated within the website.   
    // if the page is navigated from outside, the alert is not shown.
    componentDidMount() {
        if (isBrowser()) {
            if (HistoryService.history.length === 0) {
                this.setState({ showAlertBox: false });
            }
        };
    };

    public render() {
        const { showAlertBox } = this.state;

        return (
            <PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} showMobileBanner={false}>
                
                {showAlertBox && (
                    <AppExclusiveAlertBox text="Dieses Feature gibt es nur in der kostenlosen Aktionsfinder App!" />
                )}

                <AppExclusiveMobileBanner icon={AppIcon} title='Aktionsfinder' image={require('./assets/RatingBanner.svg')} buttonText='Zur App' buttonLink='https://aktionsfinder.page.link/app' />

                <AppExclusiveMainBlock />

                <AppExclusiveAdvantages />

                <AppExclusiveMobileButton title='Zur App' href="https://aktionsfinder.page.link/app" />

                <AppExclusiveRatings />

                <div className={styles.bottomContainer}>
                    <div className={styles.backButton}>
                        <Button onClick={this.goBack} theme="flat" size="l" uppercase={false} fullWidth={true} fontSize={14}>Zurück</Button>
                    </div>
                </div>
            </PageWrapper>
        );
    }

    /**
    * Returns the breadcrumb path for this page
    */
    private getBreadcrumbs() {

        return [
            {
                label: 'App',
            } as IBreadcrumbItemType,
        ];
    }

    /**
    * Go back to previous page
    */
    private goBack = function () {
        if (!isBrowser()) {
            return;
        }

        // if we have a history on this page -> go back
        if (HistoryService.last()) {
            history.goBack();
        } else {
            window.location.href = '/';
        }
    };
}

export default AppExclusive;