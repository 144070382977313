import * as React from 'react';
import * as styles from './PromotionsInShoppingCenterAndProductGroup.scss';
import ShopLocationMapWidget from '../../../Vendor/components/ShopLocationMapWidget/ShopLocationMapWidget';
import { IShoppingCenterType } from '../../../ShoppingCenter/types/IShoppingCenterType';

interface IPromotionsInShoppingCenterAndProductGroupShopWidgetProps {
	shoppingCenter: IShoppingCenterType,
}

class PromotionsInShoppingCenterAndProductGroupShopWidget extends React.Component<IPromotionsInShoppingCenterAndProductGroupShopWidgetProps> {
	public static defaultProps = {};

	public render () {
		const { shoppingCenter } = this.props;
        
		if (!shoppingCenter) {
			return null;
		}

		return (
            <ShopLocationMapWidget
                title={shoppingCenter.title}
                center={{
                    lat: shoppingCenter.latitude,
                    lng: shoppingCenter.longitude,
                }}
            >
                <span className={styles.mapDescription}>
                    { (shoppingCenter.address) ? <span>{shoppingCenter.address}, </span> : null }

                    { (shoppingCenter.zipCode) ? <span>{shoppingCenter.zipCode} </span> : null } 
                    { (shoppingCenter.city && shoppingCenter.city.name) ? <span>{shoppingCenter.city.name}</span> : null }<br/>

                    { (shoppingCenter.website) ? <a href={shoppingCenter.website} target="_blank" rel="nofollow noopener noreferrer">{shoppingCenter.website}</a> : null }<br/>
            
                    { (shoppingCenter.phone) ? <a href={`tel:${shoppingCenter.phone}`} target="_blank" rel="nofollow noopener noreferrer">{shoppingCenter.phone}</a> : null }<br/>
                </span>

            </ShopLocationMapWidget>
		);
	}
}

export default PromotionsInShoppingCenterAndProductGroupShopWidget;
