import * as React from 'react';
import { IFederalStateType } from '../../../Geographic/types/IFederalStateType';
import { IShopType } from '../../types/IShopType';
import * as styles from './ShopListGroupedByStateGroup.scss';
import ShopList from '../ShopList/ShopList';

interface IShopListGroupedByStateGroupedProps {
	shops: IShopType[];
	state: IFederalStateType;
	widgetKey?: string;
}

class ShopListGroupedByStateGroup extends React.Component<IShopListGroupedByStateGroupedProps, {}> {
	public static defaultProps = {
		widgetKey: 'default'
	};

	public render() {
		const { shops, widgetKey } = this.props;

		return (
			<div>
				<div className={styles.shopGroupWrapper} key={`shops-grid-list-${widgetKey} shopListGroupedByStateGroupItems`}>
					<ShopList
						shops={shops}
						design="large"
					/>
				</div>		
			</div>
		);
	}
}

export default ShopListGroupedByStateGroup;
