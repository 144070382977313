import * as React from 'react';
import { ReactChild, ReactNode } from 'react';
import Intro from '../PageWrapper/Intro';
import * as styles from './TipBox.scss';
import Icon from '../../../modules/Base/components/Icon/Icon';

interface ITipBoxProps {
	children?: ReactNode[] | ReactNode | string[],
}

class TipBox extends React.PureComponent<ITipBoxProps, {}> {
	public render() {
		const { children } = this.props;

		return (
			<div className={`${styles.TipBox}`}>
				<span className={`${styles.TipBoxIcon}`}>
					<Icon icon="info" />
				</span>

				{children}
			</div>
		);
	}
}

export default TipBox;
