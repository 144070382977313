import { ActionType } from 'typesafe-actions';
import BaseReducer, { baseInitialState } from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IProductPromotionWithShopType } from '../../types/IProductPromotionWithShopType';
import * as productPromotionInOtherShopActions from './productPromotionInOtherShopActions';

export type ProductPromotionInOtherShopGridAction = ActionType<typeof productPromotionInOtherShopActions>;

export interface IPromotionWithShopStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IProductPromotionWithShopType };
	readonly queries: { [queryKey: string]: IProductPromotionWithShopType[] };
}

export function productPromotionInOtherShopReducer(
	state = baseInitialState as IPromotionWithShopStateType,
	action: ProductPromotionInOtherShopGridAction,
) {
	const Reducer = new BaseReducer('productPromotionInOtherShopReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}
