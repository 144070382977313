import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import MostUsedQueries from '../../components/MostUsedQueries/MostUsedQueries';

interface IMostUsedQueriesContainerProps {

	widgetKey?: string,
    title: string,
    
	page?: number,
    limit?: number,

	orderBy?: 'relevance',
	orderDir?: 'desc',
}

class MostUsedQueriesContainerInner extends React.Component<IMostUsedQueriesContainerProps & IWithDataProps, {}> {
	public static defaultProps = {
		orderBy: 'relevance',
		orderDir: 'desc',
	};

	public render () {
		const { data, title, widgetKey } = this.props;

		return  (
			<MostUsedQueries 
                queries={data}
                title={title}
                widgetKey={widgetKey}
            />
		)
	}
}

export default function MostUsedQueriesContainer (theProps: IMostUsedQueriesContainerProps) {
	const props = Object.assign({
		limit: 10,

		enablePagination: false,
	}, theProps);

	const RenderComponent = withData(
		MostUsedQueriesContainerInner,
		'mostUsedQueriesReducer',
		`mostUsedQueries-${props.widgetKey}`,
		{
			size: props.limit,
            page: props.page,
		},
	);

	return <RenderComponent {...props} />;
}


