import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addLeafletsAggregatedByStatesWidgetEntries,
	LEAFLETS_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES, setLeafletsAggregatedByStatesWidgetErrors,
} from './leafletsAggregatedByStatesWidgetActions';

/**
 * Fetch entry list
 */
function* fetchLeafletsAggregatedByStatesWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const { params } = action;

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: '/api/public/v1/widgets/states-for-leaflets',
					params,
				} as IRequestObject
			);
		});

		const theContent = response.getContent('content');

		yield put(addLeafletsAggregatedByStatesWidgetEntries(
			action.queryKey,
			theContent,
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setLeafletsAggregatedByStatesWidgetErrors(action.queryKey, e));
	}
}


export function* leafletsAggregatedByStatesWidgetSaga() {
	yield takeEvery(LEAFLETS_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES, fetchLeafletsAggregatedByStatesWidget);
}
