import * as React from 'react';
import { IShopType } from '../../types/IShopType';
import * as styles from './ShopListItem.scss';
import Link from '../../../Base/components/Link/Link';
import Icon from '../../../Base/components/Icon/Icon';
import Distance from '../Distance/Distance';

interface IShopListItemProps {
	shop: IShopType,
	widgetKey?: string,
}

class ShopListItem extends React.Component<IShopListItemProps, {}> {
	public static defaultProps = {
		widgetKey: 'default',
	};

	public render () {
		const { shop, widgetKey } = this.props;

		return (

			<Link
				route="ShopDetailRoute"
				params={{
					shop: shop.slug,
				}}
				key={`shop-list-item-${widgetKey}-${shop.slug}`}
			>
				<a className={styles.shopListItem}>

					<div className={styles.shopTextContainer}>
						<span className={styles.shopListItemTitle}>
							{shop.title}
						</span>

						<span className={styles.shopListItemAddress}>
							{shop.address}, {shop.zipCode} {shop.city}
						</span>
					</div>
					
					{( !shop.distance ) ? null :
						<div className={styles.shopDistanceContainer}>
							<Icon icon="direction-filled" className={styles.shopListDistanceIcon}/>&nbsp;
							<span className={styles.shopListItemDistance}>
								<Distance distanceInMeters={shop.distance} hideIcon={true}/>
							</span>
						</div>
					}

				</a>
			</Link>
		);
	}
}

export default ShopListItem;
