import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import LeafletsAggregatedByProductGroupsWidget
	from '../../components/LeafletsAggregatedByProductGroupsWidget/LeafletsAggregatedByProductGroupsWidget';
import { ILeafletsAggregatedByProductGroupType } from '../../types/ILeafletsAggregatedByProductGroupType';
import DataStateService from '../../../../services/DataStateService';

interface ILeafletsAggregatedByProductGroupsWidgetContainerProps {
	title: string;
	widgetKey: string;

	limit?: number;
	placeholderLimit?: number;
	page?: number;

	style?: IWidgetStyleType;
	orderBy?: 'leafletCount'
	orderDir?: 'desc';

	onlyNearby?: boolean;

	routeCallbk: (item: ILeafletsAggregatedByProductGroupType) => { name: string, params: { [ key: string ]: any } },

	parentProductGroupSlug?: SlugType,
	excludeSlug?: SlugType,

}

class LeafletsAggregatedByProductGroupsWidgetContainerInner extends React.Component<ILeafletsAggregatedByProductGroupsWidgetContainerProps & IWithDataProps> {
	public static defaultProps = {
		leafletLabel: 'Aktion',
		leafletLabelPlural: 'Aktionen',
		type: 'leaflets',
	};

	public render () {
		const { data, widgetKey, title, style, excludeSlug, routeCallbk, metadata } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<LeafletsAggregatedByProductGroupsWidget
				title={( !metadata || !metadata.title ) ? title : metadata.title}
				aggregatedEntries={(excludeSlug) ? this.excludedEntry(data) : data}
				widgetKey={widgetKey}
				style={style}
				routeCallbk={routeCallbk}
			/>
		);
	}

	/**
	 * Cleans up our entries and makes sure the excluded entry is not in there
	 *
	 * @param {IProductPromotionType} entries
	 *
	 * @return {IProductPromotionType}
	 */

	private excludedEntry(
		entries: ILeafletsAggregatedByProductGroupType[],
	): ILeafletsAggregatedByProductGroupType[] {
		const { limit, excludeSlug } = this.props;

		// now try to filter it out
		return entries.filter((entry: ILeafletsAggregatedByProductGroupType, indx: number) => {
			if (entry.group.slug === excludeSlug) {
				return false;
			}

			// if we are over our limit -> also return false (this might be because, we don't have the excludee in our data set,
			// but to make sure we have the correct amount of data even after removing the excludee, we have added +1 to limit on fetch
			if (limit && indx > limit) {
				return false;
			}

			// otherwise return true and add it to our results
			return true;
		});
	}
}

export default function LeafletsAggregatedByProductGroupsWidgetContainer (props: ILeafletsAggregatedByProductGroupsWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;

	const RenderComponent = withData(
		LeafletsAggregatedByProductGroupsWidgetContainerInner,
		'leafletsAggregatedByProductGroupsWidgetReducer',
		`leaflets-aggregated-by-product-groups-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			page: props.page,
			size: limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			nearby_only: props.onlyNearby,

			parent_product_group: props.parentProductGroupSlug,
			exclude: props.excludeSlug,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={(props.parentProductGroupSlug) ? ' ' : props.title}
					rows={( props.placeholderLimit ) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={( props.placeholderLimit ) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		},
	);

	return <RenderComponent {...props} />;
}
