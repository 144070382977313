import * as React from 'react';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import LeafletItemSkeleton from '../LeafletItem/LeafletItemSkeleton';
import * as styles from './LeafletGrid.scss';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';

interface ILeafletGridSkeletonProps {
	size?: 's' | 'm' | 'l',
	gridKey?: string,
	title?: string,
	columns?: '1' | '2' | '3' | '4' | '5',

	boxed?: boolean,
	nested?: boolean,

	moreLabel?: string,
	limit?: number;
	mobileScrollable?: boolean;
}

class LeafletGridSkeleton extends React.Component<ILeafletGridSkeletonProps, {}> {
	public static defaultProps = {
		columns: 5,
		gridKey: 'lfgrid',
		size: 'm',
	};

	public render () {
		const { size, gridKey, moreLabel, columns, boxed, nested, title, limit, mobileScrollable } = this.props;

		const theLimit = (limit) ? limit : + (columns || 5);
		const limitArr = Array.from(Array(theLimit).keys());

		return (
			<div className={styles.leafletGroupOuter}>

				<AppMainColBox boxed={boxed} nested={nested}>
					<React.Fragment>

						{(!title) ? null :
							<SectionTitle
								title={title}
							/>
						}

						<div
							className={`${styles.leafletGroup} ${styles['leafletGroupColumns' + columns]} ${mobileScrollable ? styles.leafletGroupScrollable : ''}`}
						>
							{
								limitArr.map((indx) => (
									<div
										className={styles.leafletGroupItem}
										key={`promotion-leaflet-grid-${indx}`}
									>
										<div className={styles.leafletGroupItemInner}>
											<LeafletItemSkeleton
												key={`leaflet-grid-item-${gridKey}-${indx}`}
												size={size}
											/>
										</div>
									</div>
								))
							}

							{( !moreLabel ) ? null :
							 <div className={styles.leafletGroupMoreItem}>
								 <a href="#" className={styles.leafletGroupMore}>
						              <SkeletonInline />
								 </a>
							 </div>
							}
						</div>

					</React.Fragment>
				</AppMainColBox>
			</div>
		);
	}
}

export default LeafletGridSkeleton;
