import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import BrandListGrouped from '../../components/BrandListGrouped/BrandListGrouped';
import { IBrandGroupedType } from '../../types/IBrandGroupedType';
import BrandListGroupedSkeleton from '../../components/BrandListGrouped/BrandListGroupedSkeleton';
import DataStateService from '../../../../services/DataStateService';

interface IBrandListGroupedContainerProps {
	title?: string;
	queryKey?: string;
	limit?: number;
	orderBy?: 'relevance' | 'createdAt' | 'published';
	orderDir?: 'asc' | 'desc';

	limitItemsPerGroup?: number;
	page?: number;

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class BrandListGroupedContainerInner extends React.Component<IBrandListGroupedContainerProps & IWithDataProps> {
	public render () {
		const { data, ...restProps } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<BrandListGrouped
				brands={data as IBrandGroupedType[]}
				{...restProps}
			/>
		);
	}
}

export default function BrandListGroupedContainer (theProps: IBrandListGroupedContainerProps) {

	const props = Object.assign({
		limit: 100,
		limitItemsPerGroup: 9
	}, theProps) as IBrandListGroupedContainerProps;

	const RenderComponent = withData(
		BrandListGroupedContainerInner,
		'brandGroupedReducer',
		`brand-grouped-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			limit_per_group: props.limitItemsPerGroup,
			orderBy: props.orderBy,
			orderDir: props.orderDir,
		},
		{
			LoadingComponent: () => (
				<BrandListGroupedSkeleton />
			),
		}
	);

	return <RenderComponent {...props} />
}
