import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RouteMetadataService from '../../../../services/RouteMetadataService';
import GeolocationService from '../../../Base/services/GeolocationService';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import {
    addProductPromotionGroupedEntries,
    PRODUCT_PROMOTION_GROUPED_FETCH_ENTRIES, setProductPromotionGroupedErrors,
} from './productPromotionGroupedActions';

/**
 * Fetch entry list
 */
function* fetchProductPromotionGrouped(action: any) {
    const afCoreRequest = new AfCoreRequest(action.queryKey);

    let url       = '/api/public/v1/promotions/grouped';
    let theParams = action.params;

    // check if we want only nearby or all of them
    if (action.params.onlyNearbyPromotions) {
        url                      = '/api/public/v1/promotions/nearbygrouped';
        const geolocationReducer = yield select((state: any) => state.geolocationReducer);
        theParams                = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);
    }

    if (action.params.groupBy === 'vendor') {
        url = '/api/public/v1/promotions/groupedbyvendor';
    }

    if (action.params.groupBy === 'city') {
        url = '/api/public/v1/promotions/groupedbycity';
    }

    // add default params
    theParams.include_publish_to_all = true;
    theParams.include_ended = true;

    try {
        const response = yield call((): Promise<RequestMiddlewarePipeline> => {
            return afCoreRequest.getRequest({
                url,
                params: theParams,
            } as IRequestObject);
        });

        const content = response.getContent('content');

        // put real content
        yield put(addProductPromotionGroupedEntries(
            action.queryKey,
            content,
            response.getMetaData(),
            action.options,
        ));

        // put route metadata
        if (action.params.pushRouteMetadata) {
            // get max discount
            let maxDiscount = 0;
            for (const item of content) {
                if (item.maxDiscountPercentage > maxDiscount) {
                    maxDiscount = item.maxDiscountPercentage;
                }
            }

            RouteMetadataService.setMetadata(action.queryKey, {
                totalItemsCount: response.getContent('totalGroupMembersCount'),
                maxItemDiscount: maxDiscount,
            } as IRouteMetadataType);
        }
    } catch (e) {
        yield put(setProductPromotionGroupedErrors(action.queryKey, e));
    }
}

export function* productPromotionGroupedSaga() {
    yield takeEvery(PRODUCT_PROMOTION_GROUPED_FETCH_ENTRIES, fetchProductPromotionGrouped);
}
