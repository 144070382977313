import * as React from 'react';
import MainNavigation from '../../../../components/Layout/MainNavigation/MainNavigation';
import NavigationItem from '../../../../components/Layout/MainNavigation/NavigationItem';

class BookmarkPageNavigation extends React.Component<{}, {}> {
	public render () {
		return (
			<MainNavigation>
				<NavigationItem label="Einkaufsliste" route="PromotionBookmarkListingRoute" type="subnavigation" />
				<NavigationItem label="Merkliste" route="VendorBookmarkListingRoute" type="subnavigation" />
			</MainNavigation>
		);
	}
}

export default BookmarkPageNavigation;
