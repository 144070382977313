import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import FilterTagsListing from '../../../Tag/components/FilterTagsListing/FilterTagsListing';
import { SlugType } from '../../../Base/types/SlugType';
import FilterTagsListingSkeleton from '../../../Tag/components/FilterTagsListing/FilterTagsListingSkeleton';

interface IThemeworldTagsListingProps {
	title: string;
	themeworldSlug: SlugType;
	activeTag: ITagType;
    onActiveTagChange: (item: ITagType) => void;
}

class ThemeworldTagsListingContainerInner extends React.Component<IThemeworldTagsListingProps & IWithDataProps> {
	public render() {
		const { data, ...restProps } = this.props;

		if (data.length === 0) {
			return null;
		}

		return (
			<FilterTagsListing
				tags={data as ITagType[]}
				{...restProps}
			/>
		);
	}
}

export default function ThemeworldTagsListingContainer(theProps: IThemeworldTagsListingProps) {
	const props = Object.assign({
		orderBy: 'rank',
		orderDir: 'desc',
	}, theProps);

	const RenderComponent = withData(
		ThemeworldTagsListingContainerInner,
		'themeworldTagsReducer',
		`themeworld-tags-listing-${props.themeworldSlug}`,
		{
			themeworldSlug: props.themeworldSlug,
		},
		{
			primaryKeyName: 'themeworldSlug',
			LoadingComponent: () => <FilterTagsListingSkeleton {...props} />
		}
	);

	return <RenderComponent {...props} />
}

