import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import Datespan from '../../../Base/components/Datespan/Datespan';
import Link from '../../../Base/components/Link/Link';
import LeafletService from '../../services/LeafletService';
import { ILeafletType } from '../../types/ILeafletType';
import LeafletImagePlaceholder from '../LeafletImagePlaceholder/LeafletImagePlaceholder';
import * as styles from './LeafletWidget.scss';
import LeafletRelativeValidDate from '../LeafletRelativeValidDate/LeafletRelativeValidDate';

interface ILeafletWidgetPropsType {
	widgetKey?: string;
	title?: string;
	leaflets: ILeafletType[];
}

class LeafletWidget extends React.PureComponent<ILeafletWidgetPropsType> {
	public static defaultProps = {};

	public render() {
		const { leaflets, title, widgetKey } = this.props;

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					{(leaflets.map((leaflet: ILeafletType) => {
						return (
							<div className={styles.leafletItemWrapper} key={`${widgetKey}-${leaflet.slug}`}>
								<Link { ...LeafletService.generateLeafletRoute(leaflet) }>
									<a className={styles.leafletItemContainer}>

										<span className={styles.leafletItemImageContainer}>

											<ImageEnhanced
												source={(leaflet.coverPage && leaflet.coverPage.resolutions) ? leaflet.coverPage.resolutions : null}
												className={styles.leafletItemImage}
												alt={leaflet.title}
												size={'m'}
												bgImage={true}
											>
												<LeafletImagePlaceholder alt={leaflet.title} />
											</ImageEnhanced>

										</span>

										<span className={styles.leafletItemInfoContainer}>

											<span className={styles.leafletItemTextContainer}>
												<span className={styles.leafletItemTitle}>{leaflet.title}</span>
												<span className={styles.leafletInfoBlockDatespan}>

													{leaflet.onlyRelativeDate ?
														<LeafletRelativeValidDate
															validFrom={leaflet.validFrom}
															validTo={leaflet.validTo}
														/>
														:
														<Datespan
															from={leaflet.validFrom}
															to={leaflet.validTo}
														/>
													}
												</span>
											</span>
										</span>
									</a>
								</Link>
							</div>
						);
					}))}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default LeafletWidget;
