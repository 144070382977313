import * as React from 'react';
import { connect } from 'react-redux';
import { accountLogout } from '../../store/Account/accountActions';
import AlertService from '../../../Base/services/AlertService';
import { resetBookmarks } from '../../../Bookmark/store/Bookmark/bookmarkActions';
import { withRouter, RouterProps } from 'react-router';

interface ILogoutContainerProps {
	accountReducer?: any,
    accountLogout: () => void,
	resetBookmarks: () => void,
}

class LogoutContainer extends React.Component<ILogoutContainerProps & RouterProps, {}> {

	componentDidMount() {
		this.props.accountLogout();
		this.props.resetBookmarks();

		this.props.history.push('/');

		setTimeout(() => { 
			AlertService.add(
				'logout',
				'Du wurdest erfolgreich abgemeldet',
			);
		}, 500);
	}

	public render () {
		return null;
	}
}

function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		accountLogout: () => {
			dispatch(accountLogout());
		},
		resetBookmarks: () => {
			dispatch(resetBookmarks());
		},
	};
}

function mapStateToProps (state: any) {
	return {
		accountReducer: state.accountReducer,
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutContainer));
