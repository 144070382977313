import React, { ComponentType } from 'react';
import { DrawerConsumer } from './DrawerProvider';

const withDrawer = (Component: ComponentType<any>) => {
    class ThemedComponent extends React.Component {
        render() {
            return (
                <DrawerConsumer>
                    {(drawerObj) => <Component drawer={drawerObj} {...this.props} />}
                </DrawerConsumer>
            );
        }
    }

    return ThemedComponent;
};

export default withDrawer;
