import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { IBrandType } from '../../types/IBrandType';
import { IBrandWidgetType } from '../../types/IBrandWidgetType';
import * as styles from './BrandListWidget.scss';

interface IBrandListWidgetProps {
	brands: IBrandType[] ;
	title: string;
	widgetKey: string;
	style: IWidgetStyleType;

	routeCallbk: (item: IBrandType) => { name: string, params: { [key: string]: any } },
}

class BrandListWidget extends React.Component<IBrandListWidgetProps, {}> {
	public render() {
		const { brands, title, widgetKey, routeCallbk } = this.props;
		
		// TRANSLATE
		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					<div className={styles.brandList}>
						{brands.map((item) => {
							const route = routeCallbk(item);
							const definedItem: IBrandType = (item as unknown as IBrandWidgetType).group || item;

							return (
								<WidgetListItemLinkRow
									key={`${widgetKey}-brandlistwidg-${definedItem.slug}`}
									route={route.name}
									routeParams={route.params}
									subtitle={(!item.maxDiscountPercentage) ? '' : `  bis -${item.maxDiscountPercentage}%`}
								>
									{definedItem.name}
								</WidgetListItemLinkRow>
							);
						})}
					</div>
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default BrandListWidget;
