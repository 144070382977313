import * as React from 'react';
import * as styles from './SearchPageFilters.scss';
import SearchPageFilterGroupSkeleton from './SearchPageFilterGroupSkeleton';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';


class SearchPageFiltersSkeleton extends React.Component<{}, {}> {
	public render() {
		return (
			<AppSideCol boxed={true} onTop={true}>
				<div className={styles.FilterWrapper}>
					<h2 className="h2SmallStyle">Filter</h2>
					<div style={{ paddingTop: '20px' }}>
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
						<SearchPageFilterGroupSkeleton />
					</div>
				</div>
			</AppSideCol>
		);
	}
}

export default SearchPageFiltersSkeleton;


