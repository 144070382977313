import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { IdType } from '../../../Base/types/IdType';
import VendorLogoWidget from '../../components/VendorLogoWidget/VendorLogoWidget';
import VendorLogoWidgetSkeleton from '../../components/VendorLogoWidget/VendorLogoWidgetSkeleton';
import { isMobile } from '../../../../utils/responsiveUtils';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';

interface IVendorLogoWidgetContainerProps {
	title: string;
	widgetKey: string;
	limit?: number;
	mobileLimit?: number;

	moreLabel?: string;
	moreRoute?: string;

	page?: number;
	style?: IWidgetStyleType;
	orderBy?: 'relevance' | 'created' | 'popularity';
	orderDir?: 'asc' | 'desc';

	industrySlug?: IdType; // ID of industry
	referenceSlug?: IdType; // ID of industry
	popular?: boolean;
}

class VendorLogoWidgetContainerInner extends React.PureComponent<IVendorLogoWidgetContainerProps & IWithDataProps> {
	public render () {
		const { data, widgetKey, title, moreLabel, moreRoute } = this.props;

		if (data.length < 1) {
			return null;
		}
		
		// TRANSLATE
		return (
			<VendorLogoWidget
				title={title}
				vendors={data}
				widgetKey={widgetKey}
				moreLabel={moreLabel}
				moreRoute={moreRoute}
			/>
		);
	}
}

export default function VendorLogoWidgetContainer (props: IVendorLogoWidgetContainerProps) {
	let limit = ( props.limit ) ? props.limit : 16;

	const Skeleton = () => (
		<VendorLogoWidgetSkeleton
			widgetKey={props.widgetKey}
			limit={limit}
			title={props.title}
		/>
	)

	// Do not render on server
	if (!isBrowser()) {
		return <Skeleton />;
	}

	if (isMobile() && props.mobileLimit) {
		limit = props.mobileLimit;
	}

	const type = ( props.orderBy === 'popularity' ) ? 'POPULAR' : undefined; // 'SIMILAR';

	const RenderComponent = withData(
		VendorLogoWidgetContainerInner,
		'vendorReducer',
		`vendor-list-widget-${props.widgetKey}`,
		{
			page: props.page,
			size: limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			type,
			industry: props.industrySlug,
			exclude: props.referenceSlug,
			popular: props.popular ? 'true' : 'false',
		},
		{
			LoadingComponent: () => {
				return <Skeleton />;
			},
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: true,
						special: true,
						extra: true,
					}}
				/>
			),
		},
	);

	return <RenderComponent {...props} />;
}
