import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import BrandsWithFirstLetter from '../App/modules/Brand/components/BrandsWithFirstLetter/BrandsWithFirstLetter';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class BrandsWithFirstLetterRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await BrandsWithFirstLetterRouteProps(state);

		return { reduxData };
	}

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};
 
		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<BrandsWithFirstLetter
							letter={query.letter + ''}
							title={`Alle Marken mit Anfangsbuchstabe "${query.letter}"`}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}
export default withRouter(BrandsWithFirstLetterRoute);