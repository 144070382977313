import { call, put, takeLatest } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import { entitiesToResolve } from '../../../../services/SimpleEntityDetailService';
import {
	ROUTE_METADATA_FETCH_ENTRIES,
	setRouteMetadata, setRouteMetadataErrors,
} from './routeMetadataActions';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';
import { getPathWithAlterations } from '../../../../../server/addCorrectResponseHeaders';

/**
 * Fetch entry list
 */
function* fetchrouteMetadatas(action: any) {
                                               const afCoreRequest = new AfCoreRequest(action.queryKey);

                                               const path = action.params?.pathname ?? location.pathname;

                                               let headers;
                                               if (isBrowser()) {
                                                   headers = {
                                                       'X-Route-Path': getPathWithAlterations(path),
                                                   };
                                               }

                                               try {
                                                   const response = yield call(
                                                       (): Promise<RequestMiddlewarePipeline> => {
                                                           return afCoreRequest.getRequest({
                                                               url: '/api/public/v1/slugs',
                                                               params: action.params,
                                                               headers,
                                                           } as IRequestObject);
                                                       },
                                                   );

                                                   // make sure to use our internal entity names
                                                   const content = response.getContent();

                                                   for (const internalKey of Object.keys(entitiesToResolve)) {
                                                       const coreKey = entitiesToResolve[internalKey];

                                                       if (typeof content[coreKey] !== 'undefined' && coreKey !== internalKey) {
                                                           content[internalKey] = content[coreKey];
                                                       }
                                                   }

                                                   if (headers && response.pipelineObject.response.headers) {
                                                       content.headers = response.pipelineObject.response.headers;
                                                   }

                                                   yield put(setRouteMetadata(action.queryKey, content));
                                               } catch (e) {
                                                   yield put(setRouteMetadataErrors(action.queryKey, e));
                                               }
                                           }

export function* routeMetadataSaga() {
	yield takeLatest(ROUTE_METADATA_FETCH_ENTRIES, fetchrouteMetadatas);
}
