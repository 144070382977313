import * as React from 'react';
import { ReactNode } from 'react';
import { RouterProps, withRouter } from 'react-router';
import { isBrowser } from '../../../../boilerplate/razzle/razzleUtils';
import Icon from '../../../modules/Base/components/Icon/Icon';
import Link from '../../../modules/Base/components/Link/Link';
import routes from '../../../routes';
import { isMobile } from '../../../utils/responsiveUtils';
import * as dropDownMenu from '../Menu/DropDown.scss';
import * as styles from './NavigationItem.scss';

interface INavigationItemProps extends RouterProps {
    children?: ReactNode | ReactNode[];
    dropDownItems?: ReactNode | ReactNode[];
    label: string;
    icon: string;
    customIcon?: string;
    route: string;
    exact: boolean;
    params: { [key: string]: any };
    active?: boolean;
    type: 'main' | 'subnavigation';
    submenuId?: string;
    selectSubmenu?: (submenuId: string, disableLeaveTimeout: boolean) => void;
    resetSubmenu?: () => void;
}

interface INavigationItemState {
    showDropDownMenu: boolean;
    dropdownLeftPosition: number;
}

class NavigationItem extends React.Component<INavigationItemProps & RouterProps, INavigationItemState> {
    public static defaultProps = {
        type: 'main',
    };

    constructor(props: INavigationItemProps) {
        super(props);

        this.state = {
            showDropDownMenu: false,
            dropdownLeftPosition: 0,
        };
    }

    public render() {
        const { label, route, icon, type, exact, params, customIcon } = this.props;
        const style = type === 'subnavigation' ? `subnavigation-item ${styles.SubNavigationItem}` : styles.navigationItem;
        const { showDropDownMenu, dropdownLeftPosition } = this.state;

        let activeClassName = '';
        let navigationItem = 'navigation-item';
        if (type === 'subnavigation') {
            activeClassName += ` ${styles.SubNavigationItemActive}`;
        } else {
            activeClassName += ` ${styles.mainNavigationItemActive}`;
        }

        return (
            <React.Fragment>
                <span className={navigationItem} onMouseLeave={this.handleMouseLeave} onClick={this.handleMouseEnter} onMouseOver={this.handleMouseEnter}>
                    <span className={`${style} ${navigationItem}`}>
                        {!route ? (
                            <a onClick={this.clickSelectSubmenu} className={this.checkActive() ? activeClassName : ''}>
                                <span className={styles.navigationItemIcon}>
                                    <Icon icon={icon} customIcon={customIcon} />
                                </span>

                                <span className={styles.navigationItemLabel}>{label}</span>
                            </a>
                        ) : (
                            <Link route={route} params={params} activeClassName={activeClassName} exact={exact}>
                                <a onClick={this.clickSelectSubmenu} className={this.checkActive() ? styles.SubNavigationItemActive : ''}>
                                    <span className={styles.navigationItemIcon}>
                                        <Icon icon={icon} customIcon={customIcon} />
                                    </span>

                                    <span className={styles.navigationItemLabel}>{label}</span>
                                </a>
                            </Link>
                        )}
                    </span>
                    {showDropDownMenu && (
                        <div className={dropDownMenu.dropDownWrapper} style={{ left: dropdownLeftPosition }}>
                            {this.renderSubItems()}
                        </div>
                    )}
                </span>
            </React.Fragment>
        );
    }

    private renderSubItems() {
        const { dropDownItems } = this.props;

        if (!dropDownItems) {
            return null;
        }

        return (
            <React.Fragment>
                <div onMouseLeave={this.handleMouseLeave} className={dropDownMenu.dropDownViewContainer}>
                    {dropDownItems}
                </div>
            </React.Fragment>
        );
    }

    /**
     * Checks if any of sublinks are active by checking isBrowser to check if window object is available to access the window.location.pathname property
     */
    private checkActive(): boolean {
        if (isBrowser() && this.props.dropDownItems) {
            const routeLinks = this.props.dropDownItems.props.children.map((child) => child.props.routeLink);
            const routeHrefs = routeLinks.map((route) => routes[route]);
            let currentPathname = window.location.pathname;

            // Special rule for category pages
            if (currentPathname.includes('ppc') || currentPathname.includes('ppcdest')) {
                currentPathname = '/aktionen/';
            }

            return routeHrefs.includes(currentPathname);
        }

        return false;
    }

    /**
     * Sets the correct submenu on hover or click
     */
    private selectSubmenu = () => {
        const { submenuId, selectSubmenu, type } = this.props;

        if (type === 'subnavigation') {
            return;
        }

        if (submenuId && selectSubmenu) {
            selectSubmenu(submenuId, false);
        }
    };

    /**
     * Sets the correct submenu on hover or click
     */
    private clickSelectSubmenu = () => {
        const { submenuId, selectSubmenu, type } = this.props;

        if (type === 'subnavigation') {
            return;
        }

        if (submenuId && selectSubmenu) {
            selectSubmenu(submenuId, true);
        }

        this.setState({
            showDropDownMenu: true,
        });
    };

    /**
     * Sets the correct submenu on hover or click
     */
    private resetSubmenu = () => {
        const { submenuId, resetSubmenu, type } = this.props;

        if (type === 'subnavigation') {
            return;
        }

        if (submenuId && resetSubmenu) {
            resetSubmenu();
        }
    };

    /**
     * Handles the drop down event
     * For the mobile: It will open the menu on click and check the position of the menu item to open the menu on the correct position
     * For that, it gets the navigation item id to ensure it takes the parent element to get the correct position
     */
    private handleMouseEnter = (event) => {
        if (isMobile()) {
            return;
        }

        const elem = document.getElementById('subnavigation-container');
        const parentElem = event.target.closest('.navigation-item');
        const dropdownLeftPosition = parentElem.offsetLeft - (elem?.scrollLeft || 0);

        this.setState({
            showDropDownMenu: true,
            dropdownLeftPosition,
        });

        if (!elem) return;

        elem.addEventListener('scroll', this.handleMouseLeave);
    };

    /**
     * Handles the drop down close event
     * For the mobile: It will open the menu on click and check the position of the menu item to open the menu on the correct position
     */
    private handleMouseLeave = () => {
        if (isMobile()) {
            return;
        }

        const elem = document.getElementById('subnavigation-container');

        this.setState({
            showDropDownMenu: false,
        });

        if (!elem) return;

        elem.removeEventListener('scroll', this.handleMouseLeave);
    };
}

export default withRouter(NavigationItem);
