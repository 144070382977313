import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import H1 from '../../../../components/Layout/H1/H1';
import Percentage from '../../../Base/components/Percentage/Percentage';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import { IProductType } from '../../types/IProductType';
import ProductPromotionDiscountBar from '../ProductPromotionDiscountBar/ProductPromotionDiscountBar';
import * as styles from './ProductPromotionDetailHeader.scss';
import ProductImagePlaceholder from '../ProductImagePlaceholder/ProductImagePlaceholder';
import ProductPromotionImageModal from '../ProductPromotionImageModal/ProductPromotionImageModal';
import Modal from '../../../Base/components/Modal/Modal';
import BookmarkIconContainer from '../../../Bookmark/containers/BookmarkIcon/BookmarkIconContainer';
import ProductPromotionDetailActionBookmark from '../ProductPromotionDetailAction/ProductPromotionDetailActionBookmark';
import ProductPromotionDetailAction from '../ProductPromotionDetailAction/ProductPromotionDetailAction';
import HumanReadableDate from '../../../Base/components/HumanReadableDate/HumanReadableDate';
import { getMainProductImage } from '../PromotionImage/PromotionImage';

interface IProductPromotionDetailHeaderPropsType {
	promotion: IProductPromotionType;
	productData: IProductType;
	promotionExpired?: boolean;
}

interface IProductPromotionDetailHeaderStatesType {
	isModalImageOpened: boolean;
}

class ProductPromotionDetailHeader extends React.Component<IProductPromotionDetailHeaderPropsType, IProductPromotionDetailHeaderStatesType> {

	constructor(props: IProductPromotionDetailHeaderPropsType){
		super(props);
		this.state = {
			isModalImageOpened: false,
		};
	}

	public render () {
		const { promotion, productData, promotionExpired } = this.props;

		const { isModalImageOpened } = this.state;
		
		const mainImage = getMainProductImage(promotion);
		
		return (
			<div className={styles.Container}>

				{( typeof mainImage === 'undefined' ) ? null :
					<span
						className={`${styles.Image} ${promotionExpired ? styles.inactiveItem : null}`}
						onClick={this.toggleImageModal}
					>
						<ImageEnhanced
							className={styles.ImageStyle}
							size="m"
							source={mainImage.resolutions}
							alt={mainImage.title}
						>
							<ProductImagePlaceholder
								alt={(mainImage.title) ? mainImage.title : promotion.title}
							/>
						</ImageEnhanced>

						{( !promotion.discountPercentage || promotion.discountPercentage < 1 ) ? null :
							<span className={styles.Ribbon}>
								<Percentage amount={promotion.discountPercentage} />
							</span>
						}

						{!promotionExpired ? null :
							<span className={`${styles.Ribbon} ${styles.expiredRibbon}`}>
								<HumanReadableDate
									from={promotion.validFrom}
									to={promotion.validTo}
									outputIfEnded="abgelaufen"
									openValidity={promotion.openValidity}
								/>
							</span>
						}
					</span>
				}

				{ (!isModalImageOpened) ? null:
					<Modal
						content={ProductPromotionImageModal}
						contentData={{...mainImage, ...promotion}}
						onCloseModal={this.toggleImageModal}
						size="l"
					/>
				}

				<div className={styles.Info}>
					<H1 className={styles.Title}>{promotion.fullDisplayName}</H1>

					<div className={styles.promotionHeaderRow}>
						{( promotion.couponCode ) ?
							<div className={styles.promotionCouponCodeBox}>
								{promotion.couponCode}
							</div>
							:
							<ProductPromotionDiscountBar
								promotion={promotion}
								size="l"
							/>
						}

						<BookmarkIconContainer
							entity="promotion"
							slug={promotion.slug}
							component={ProductPromotionDetailActionBookmark}
							className={styles.ActionLink}
						/>
					</div>

					<div className={styles.promotionHeaderDesktopAction}>
						<ProductPromotionDetailAction
							promotion={promotion}
							productData={productData}
							promotionExpired={promotionExpired}
						/>
					</div>
					
				</div>
			</div>
		);
	}

	/**
	 * Toggles the button
	 */
	private toggleImageModal = () => {
		this.setState({
			isModalImageOpened: !this.state.isModalImageOpened,
		})
	}

}

export default ProductPromotionDetailHeader;
