import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IShoppingCenterGroupedType } from '../../types/IShoppingCenterGroupedType';
import ShoppingCenterGrouped from '../../components/ShopListGroupedByState/ShoppingCenterGrouped';
import Pagination from '../../../../components/Layout/Pagination/Pagination';

interface IShoppingCenterGroupedContainerProps {
	queryKey?: string,
	pushRouteMetadata?: boolean;
	titleLabel?: string,
	titleLabelPlural?: string,
	moreLabel?: string,
	moreRouteCallbk?: (shopGroup: IShoppingCenterGroupedType) => { name: string, params: {[key: string]: any} },

	page?: number,
	limit?: number,
	limitItemsPerGroup?: number,

	orderBy?: 'relevance',
	orderDir?: 'desc',
	enablePagination?: boolean;

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class ShoppingCenterGroupedContainerInner extends React.Component<IShoppingCenterGroupedContainerProps & IWithDataProps, {}> {

	public render () {
		const { data, fetchMore, metadata, ...restProps } = this.props;

		return (
			<Pagination	
				// LoaderComponent={<ProductPromotionGridSkeleton limit={((metadata && metadata.totalItemsCount || 2) - data.length)} />}
				fetchMore={fetchMore}
				metadata={metadata}
			>
				<ShoppingCenterGrouped
					shoppingCenterGroups={data as IShoppingCenterGroupedType[]}
					{...restProps}
				/>
			</Pagination>
		);
	}
}

export default function ShoppingCenterGroupedContainer (theProps: IShoppingCenterGroupedContainerProps) {
	const props = Object.assign({
		limit: 50,
		enablePagination: false,
	}, theProps);

	const RenderComponent = withData(
		ShoppingCenterGroupedContainerInner,
		'shoppingCenterGroupedReducer',
		props.queryKey,
		{
			size: props.limit,
			page: props.page,
			limit_per_group: props.limitItemsPerGroup,

			pushRouteMetadata: props.pushRouteMetadata,
		},
	);

	return <RenderComponent {...props} />;
}


