import * as React from 'react';
import Link from '../../../Base/components/Link/Link';
import * as styles from './LotteryList.scss';
import { ILotteryType } from '../../types/ILotteryType';
import LotteryListItem from '../LotteryListItem/LotteryListItem';
import Icon from '../../../Base/components/Icon/Icon';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';

interface ILotteryListProps {
    lotteries: ILotteryType[];
    title?: string;
    widgetKey?: string;
    moreLabel?: string;
    moreRoute?: string;
    columns?: '2' | '3';
    boxed?: boolean;
    mobileScrollable?: boolean;
}

class LotteryList extends React.Component<ILotteryListProps, {}> {
    public static defaultProps = {
        columns: '2',
    };

    public render() {
        const {
            lotteries,
            title,
            moreLabel,
            moreRoute,
            columns,
            boxed,
            mobileScrollable,
        } = this.props;

        let className = `${styles.lotteryListItemsContainer} ${
            styles['lotteryListItemsContainerColumns-' + columns]
        }`;

        if (boxed) {
            className += ` ${styles.lotteryListBoxed}`;
        }

        if (mobileScrollable) {
			className += ` ${styles.lotteryListScrollable}`
		}

        return (
            <>
                {(!title) ? null :
                    <SectionTitle
                        title={title}
                        route={moreRoute}
                        boxed={true}
                    />
                }

                <div className={className}>
                    {lotteries.map((lottery: ILotteryType) => {
                        return (
                            <LotteryListItem
                                key={`${lottery.id}`}
                                lottery={lottery}
                                columns={columns}
                            />
                        );
                    })}
                </div>

                {!moreLabel ? null : (
                    <div className={styles.lotteryListMoreItem}>
                        <Link route={moreRoute + ''}>
                            <a href="#" className={styles.lotteryListMore}>
                                <span className={styles.lotteryListMoreLabel}>
                                    {moreLabel}

                                    <Icon
                                        icon="arrow-right"
                                        className={
                                            styles.lotteryListMoreLabelIcon
                                        }
                                    />
                                </span>
                            </a>
                        </Link>
                    </div>
                )}
            </>
        );
    }
}

export default LotteryList;
