import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addThemeworldEntries, addThemeworldEntry,
	setThemeworldErrors, THEMEWORLD_FETCH_ENTRIES, THEMEWORLD_FETCH_ENTRY,
} from './themeworldActions';

/**
 * Fetch entry list
 */
function* fetchThemeworlds(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/widgets/themeworlds',
				params: action.params,
			} as IRequestObject);
		});

		yield put(addThemeworldEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setThemeworldErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry list
 */
function* fetchThemeworld(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);
	
	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: `/api/public/v1/themeworlds/${action.params.themeworldSlug}`,
				} as IRequestObject
			);
		});

		yield put(addThemeworldEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setThemeworldErrors(action.queryKey, e));
	}
}

export function* themeworldSaga() {
	yield takeEvery(THEMEWORLD_FETCH_ENTRY, fetchThemeworld);
	yield takeEvery(THEMEWORLD_FETCH_ENTRIES, fetchThemeworlds);
}
