import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import VendorListWidget from '../../components/VendorListWidget/VendorListWidget';
import { SlugType } from '../../../Base/types/SlugType';
import { IVendorType } from '../../types/IVendorType';

interface IVendorListWidgetContainerProps {
	title: string;
	widgetKey: string;
	limit?: number;
	page?: number;
	style?: IWidgetStyleType;
	orderBy?: 'relevance' | 'created' | 'popularity';
	orderDir?: 'asc' | 'desc';

	industrySlug?: SlugType;
	referenceSlug?: SlugType;
	vendorSlug?: SlugType;
	citySlug?: SlugType;
	brandSlug?: SlugType;

	routeCallbk: (item: IVendorType) => { name: string, params: { [key: string]: any } },
}

class VendorListWidgetContainerInner extends React.Component<IVendorListWidgetContainerProps & IWithDataProps> {
  public render () {
	const { data, ...restProps } = this.props;
	
	if (data.length < 1) {
		return null;
	}

    // TRANSLATE
    return (
		<VendorListWidget
			vendors={data}
			{...restProps}
		/>
    );
  }
}

export default function VendorListWidgetContainer (props: IVendorListWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;

	const type = (props.orderBy === 'popularity') ? 'POPULAR' : undefined; // 'SIMILAR';

	const RenderComponent = withData(
		VendorListWidgetContainerInner,
		'vendorWidgetReducer',
		`vendor-list-widget-${props.widgetKey}`,
		{
			page: props.page,
			size: props.limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			type: type,
			industry: props.industrySlug,
			exclude: props.referenceSlug,
			vendor: props.vendorSlug,
			city: props.citySlug,
			brand: props.brandSlug,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={limit}
					type="item"
					widgetKey={props.widgetKey}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={limit}
					type="item"
					widgetKey={props.widgetKey}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
