import * as React from 'react';
import { ReactNode } from 'react';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import Link from '../../../Base/components/Link/Link';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import ProductPromotionItem from '../ProductPromotionItem/ProductPromotionItem';
import * as styles from './ProductPromotionGrid.scss';
import ProductPromotionGridSkeleton from './ProductPromotionGridSkeleton';
import ProductPromotionItemHorizontal from '../ProductPromotionItemHorizontal/ProductPromotionItemHorizontal';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';

interface IPromotionGridProps {
	products: IProductPromotionType[];
	metadata?: IQueryMetaDataType;
	size?: 's' | 'm' | 'l';
	gridKey?: string;
	title?: string;
	maxItems?: number;
	description?: string;
	subtitle?: string;
	titleLevel?: number;
	columns?: '1' | '2' | '3' | '4' | '5';
	theme?: "default" | "horizontal";

	moreHref?: string;
	moreLabel?: string;
	moreRoute?: string;
	moreRouteParams?: { [key: string]: any };

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing

	children?: ReactNode; // if this is given, this will be shown if the array doesn't return any results

	boxed?: boolean;
	enablePagination?: boolean;
	onPromotionClick?: (promotion: IProductPromotionType) => void;
	showBookmarkIcon?: boolean;
	useLeafletRoute?: boolean;
	directClickout?: boolean;  // if we have a leaflet (=we are in a Promotionworld, not a normal themeworld => open clickouts directly)
}

class ProductPromotionGrid extends React.Component<IPromotionGridProps & IWithDataProps, {}> {
	public static defaultProps = {
		columns: 3,
		gridKey: 'lfgrid',
		size: 'm',
		enablePagination: true,
		theme: "default",
	};

	public render() {
		const {
			products,
			size,
			gridKey,
			moreLabel,
			columns,
			boxed,
			theme,
			title,
			subtitle,
			children,
			moreRoute,
			moreRouteParams,
			fetchMore,
			metadata,
			queryKey,
			enablePagination,
			onPromotionClick,
			maxItems,
			moreHref,
			showBookmarkIcon,
			contentBreaks,
			useLeafletRoute,
			directClickout,
		} = this.props;

		if (!products || products.length < 1) {
			if (children) {
				return (
					<div>
						{children}
					</div>
				);
			} else {
				return null;
			}
		}

		const theProducts = (maxItems) ? products.slice(0, maxItems) : products;

		const routeProps = (moreHref) ? { href: moreHref } : {
			route: moreRoute,
			params: moreRouteParams,
		};

		return (
			<div className={styles.promotionOuter}>
				{!title ? null :
					<SectionTitle
						title={title}
						route={moreRoute}
						boxed={true}
					/>
				}

				{(subtitle) ? <div className={styles.SubTitle}>{subtitle}</div> : null}
				
				<Pagination
					fetchMore={fetchMore}
					metadata={metadata}
					queryKey={queryKey}
					enablePagination={enablePagination}
					LoaderComponent={
						<ProductPromotionGridSkeleton
							// limit={((metadata && metadata.totalItemsCount) ? metadata.totalItemsCount : 0) - products.length}
							limit={6}
						/>
					}
				>
					<AppMainColBox boxed={boxed} nested={true}>
						<div className={`${styles.promotion} ${styles['promotionColumns' + columns]}`}>
							{ theProducts.map((product: IProductPromotionType, indx: number) => {
								const itemProps = {
									key: `product-grid-item-${gridKey}-${product.slug}`,
									promotion: product,
									size: size,
									onClick: onPromotionClick,
									showBookmarkIcon: showBookmarkIcon,
									useLeafletRoute: useLeafletRoute,
									directClickout: directClickout,
								};
								
								return (
									<React.Fragment key={`promotion-product-grid-${product.slug}`}>

										{(!contentBreaks || !contentBreaks[indx]) ? null :
											<React.Fragment>
												{contentBreaks[indx]}
											</React.Fragment>
										}

										<div className={`${styles.promotionItem} ${theme === 'horizontal' ? styles.promotionItemHorizontal : ''}`}>
											<div className={styles.promotionItemInner}>
												{ theme === 'horizontal' ? 
													<ProductPromotionItemHorizontal {...itemProps} />
													: 
													<ProductPromotionItem {...itemProps} />
												}
											</div>
										</div>

									</React.Fragment>
								)
							})}

							{(!moreLabel) ? null :
								<div className={styles.promotionMoreItem}>
									<Link
										{...routeProps}
									>
										<a className={styles.promotionMore}>
											{moreLabel}
										</a>
									</Link>
								</div>
							}
						</div>
					</AppMainColBox>
				</Pagination>
			</div>
		);
	}

	// /**
	//  * Returns the correct title tag, respecting the correct headline level and title content
	//  *
	//  * @return {string}
	//  */
	// private getCorrectTitleTag(): ReactNode | null {
	// 	const { titleLevel, description } = this.props;

	// 	const title = this.getTitle();

	// 	if (!title) {
	// 		return null;
	// 	}

	// 	if (titleLevel === 2) {
	// 		return (
	// 			<h2>{title}</h2>
	// 		);
	// 	}

	// 	if (titleLevel === 3) {
	// 		return (
	// 			<h3>{title}</h3>
	// 		);
	// 	}

	// 	if (titleLevel === 4) {
	// 		return (
	// 			<h4>{title}</h4>
	// 		);
	// 	}

	// 	return (
	// 		<H1 title={title}>{
	// 			(!description) ? null :
	// 				<div dangerouslySetInnerHTML={{ __html: description }} />
	// 		}</H1>
	// 	);
	// }

	/**
	 * Returns a string containing the url.
	 * Will also replace some placeholders with real values
	 */
	private getTitle(): string {
		const { title, metadata } = this.props;

		if (!title) {
			return '';
		}

		let retTitle = title;

		// replace placeholders
		if (metadata) {
			retTitle = retTitle.replace('%total%', metadata.totalItemsCount);
		}

		return retTitle;
	}
}

export default ProductPromotionGrid;
