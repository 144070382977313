import * as React from 'react';
import AppIcon from './AppIcon.svg';
import * as styles from './MobileAppBanner.scss';
import Icon from '../../../modules/Base/components/Icon/Icon';
import { isBrowser } from '../../../../boilerplate/razzle/razzleUtils';
import Button from '../../General/Button/Button';
import CookieService from '../../../services/CookieService';
import InternalTrackingService from '../../../services/InternalTrackingService';
import DataLayerService from '../../../modules/Base/services/DataLayerService';

interface IMobileAppBannerProps {}

interface IMobileAppBannerStates {
	hideBanner: boolean;
}

class MobileAppBanner extends React.Component<IMobileAppBannerProps, IMobileAppBannerStates> {
    constructor(props: IMobileAppBannerProps) {
        super(props);
        this.state = {
            hideBanner: CookieService.get("mblbnr") === "true" || false,
        };
	}

    public render() {

		const { hideBanner } = this.state;

		const operatingSystem = this.getMobileOperatingSystem();
		const linkHref = 'https://aktionsfinder.page.link/app';

		if (!operatingSystem || hideBanner) {
			return null;
		}

        return (
            <div className={styles.MobileAppBannerWrapper}>
                <button
                    type="button"
                    onClick={this.closeBanner}
                    className={styles.MobileAppBannerClose}
                >
                    <Icon icon="close" />
                </button>

				<div className={styles.MobileAppBannerIconWrapper}>
					<img
						src={AppIcon}
						alt="Installiere die App"
						className={styles.MobileAppBannerIcon}
					/>
				</div>

				<div className={styles.MobileAppBannerInfo}>
					<span className={styles.MobileAppBannerTitle}>
						Aktionsfinder
					</span>
					<span className={styles.MobileAppBannerDescription}>
						Kostenlos für {operatingSystem}
					</span>
				</div>

				<div className={styles.MobileAppBannerButton}>
					<Button
						theme="primary"
						size="l"
						href={linkHref}
						target="_blank"
						uppercase
						onClick={() => this.trackAppEvent(linkHref)}
					>
						Zur App
					</Button>
				</div>
            </div>
        );
	}

	/**
     * Track App click event
     */
	private trackAppEvent = (linkHref: string) => {
        InternalTrackingService.track('PWA_TO_APP', {
            'targetUrl': linkHref,
            'pageTitle': document.title,
        });

        DataLayerService.push('PWA_TO_APP', {
			'targetUrl': linkHref,
            'pageTitle': document.title,
			'pagePath': window.location.pathname,
			'buttonText': 'Zur App',
        });
    }
	
	/**
	 * Operating system detector
	 */
    private getMobileOperatingSystem = (): string | void => {

		if (!isBrowser()) {
			return;
		}

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		
        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
		}
	};
	
	/**
	 * Closes a banner and sets cookies for about 1 month
	 */
	private closeBanner = (): void => {

		CookieService.set("mblbnr", "true", 40000);

		this.setState({
			hideBanner: !this.state.hideBanner
		});
	}
}

export default MobileAppBanner;
