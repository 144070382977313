import { ActionType } from 'typesafe-actions';
import BaseReducer, { baseInitialState } from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import CookieService from "../../../../services/CookieService";
import AccountService from '../../services/AccountService';
import * as accountActions from './accountActions';
import { history } from '../../../../../boilerplate/razzle/history';
import BookmarkService from '../../../Bookmark/services/BookmarkService';

export type AccountGridAction = ActionType<typeof accountActions>;

export interface IAccountStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: {} };
	readonly queries: { [queryKey: string]: [] };
	readonly accountInfo: IAccountLoginType;
	readonly isLoggedIn: boolean;
	readonly isFetched: boolean;
}

const defaultLoginState = {
	isLoggedIn: false,
	isFetched: false,
	accountInfo: {} as IAccountLoginType,
};

export function accountReducer(
	state = Object.assign({}, baseInitialState, defaultLoginState) as IAccountStateType,
	action: any,
) {
	const Reducer = new BaseReducer('accountReducer');

	return Reducer.extend(state, action, (innerState, innerAction) => {

		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});
	
			case 'ACCOUNT_LOGOUT':
				CookieService.deleteArr(['at', 'rfshtkn']);

				AccountService.setLoggedInState(false);

				setTimeout(() => {
					history.push('/');		
				}, 500);

				return Object.assign({}, state, {
					isLoggedIn: false,
					isFetched: true,
					accountInfo: {}
				});

			case 'SET_ACCOUNT_DATA':

				AccountService.setLoggedInState(true);

				return Object.assign({}, state, {
					isLoggedIn: true,
					isFetched: true,
					accountInfo: action.account
				});

			case 'SET_FETCHED_STATE':

				AccountService.setLoggedInState(false);

				return Object.assign({}, state, {
					isFetched: true,
				});
				
			default:
				return innerState;
		}
	});
}

