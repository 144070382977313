import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import LeafletViewerContent from '../../components/LeafletViewer/LeafletViewerContent';
import { ILeafletType } from '../../types/ILeafletType';
import { LeafletViewerType } from '../../hocs/withLeaflet';	

interface ILeafletViewerContentProps extends IWithDataProps {
	leaflet: ILeafletType,

	page?: number;
	limit?: number;
	orderBy?: 'relevance';
	orderDir?: 'asc' | 'desc';
	leafletViewerType: LeafletViewerType;
}

class LeafletViewerContentContainerInner extends React.Component<ILeafletViewerContentProps> {
	public render () {
		const { leaflet, data, leafletViewerType } = this.props;

		return (
			<LeafletViewerContent
				leaflet={leaflet}
				pages={data}
				leafletViewerType={leafletViewerType}
			/>
		);
	}
}


export default function LeafletViewerContentContainer (props: ILeafletViewerContentProps) {
	const RenderComponent = withData(
		LeafletViewerContentContainerInner,
		'leafletPageReducer',
		props.queryKey,
		{
			leaflet: props.leaflet.slug,
		},
		{
			LoadingComponent: () => (<div>&nbsp;</div>)
		});

	return <RenderComponent {...props} />;
}
