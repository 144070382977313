import * as React from 'react';
import { ReactNode } from 'react';
import Icon from '../Icon/Icon';
import * as styles from './ModalTitle.scss';

interface IModalTitleProps {
	children: ReactNode | ReactNode[];
	onClose?: () => void;
	titleStyle: 'center' | 'responsive';
}

class ModalTitle extends React.PureComponent<IModalTitleProps> {
  public render() {
  	const { children, onClose, titleStyle } = this.props;

  	// if (!children) {
  	// 	return null;
		// }
		
		let style = styles.Wrapper;

		if (titleStyle === 'center') {
			style += ` ${styles.centerTitleStyle}`;
		} else if (titleStyle === 'responsive') {
			style += ` ${styles.responsiveTitleStyle}`;
		}

    return (
		<header className={style}>
			{( !children ) ? null : 
				<div className={styles.Title}>{children}</div>
			}

			{ (!onClose) ? null :
			  <button
				  type="button"
				  onClick={onClose}
				  className={styles.CloseButtonDesktop}
			  >
				<Icon icon="close" />
			  </button>
			}
		</header>
    );
  }
}

export default ModalTitle;
