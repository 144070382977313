import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addThemeworldTagsEntries, setThemeworldTagsErrors, THEMEWORLD_TAGS_FETCH_ENTRIES,
} from './themeworldTagsActions';

/**
 * Fetch entry list
 */
function* fetchThemeworldTags(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: `/api/public/v1/themeworlds/${action.params.themeworldSlug}/tags`
			} as IRequestObject);
		});

		yield put(addThemeworldTagsEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setThemeworldTagsErrors(action.queryKey, e));
	}
}

export function* themeworldTagsSaga() {
	yield takeEvery(THEMEWORLD_TAGS_FETCH_ENTRIES, fetchThemeworldTags);
}
