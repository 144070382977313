import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsInProductCategoryAndIndustry from '../App/modules/Promotion/components/PromotionsInProductCategoryAndIndustry/PromotionsInProductCategoryAndIndustry';
import {
	pluralizerPromotionsAndSpecials,
	maxDiscountInTitle,
	withTitlePostfix,
	withClickbait,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';


class PromotionsInProductCategoryAndIndustryRoute extends BaseRoute {

	public static async getInitialProps(state: any) {
		const reduxData = await PromotionsInProductCategoryAndIndustryRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<div>
				<Header />
				<MainNavigation />

				<PromotionsInProductCategoryAndIndustry
					productGroupSlug={query.productGroup}
					industrySlug={query.industry}
					{...this.props}
				/>

				<Footer />
			</div>
		);
	}
}


export default withRouter(
	withRouteMetadata(
		PromotionsInProductCategoryAndIndustryRoute,
		(props: any) => `ppci-${props.match.params.productGroup}-${props.match.params.industry}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} in ${routeMetadata.industry.title} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} in ${routeMetadata.industry.title} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle ${routeMetadata.productGroup.title} Aktionen ${maxDiscountInTitle(routeMetadata.maxItemDiscount, true)} » Preiskracher ermitteln ✓ kein Schnäppchen verpassen ✓ bares Geld sparen`, `✓ Stöbern & fündig werden!`),
		} as IRouteMetadataSetterType,
	),
);


