import { DateTime } from 'luxon';
import { IAlertType } from '../../types/IAlertType';
import { ADD_ALERT, CLOSE_ALERT, MARK_ALERT } from './alertActions';

export interface IAlertStateType {
	readonly alerts: IAlertType[];
}

const defaultAlertState = {
	alerts: [],
};

export function alertReducer(
	state = defaultAlertState as IAlertStateType,
	action: any,
) {
	switch (action.type) {
		case ADD_ALERT:
			// make sure this message is not in there yet
			const newAlerts = state.alerts.filter((alert: IAlertType) => {
				return (alert.id !== action.id);
			});

			newAlerts.unshift({
				id: action.id,
				title: action.title,
				icon: action.icon,
				message: action.message,
				level: action.level,
				type: action.type,
				timeoutInSeconds: action.timeoutInSeconds,
				created: DateTime.local(),
				shown: false,
			} as IAlertType);

			return Object.assign({}, state, {
				alerts: newAlerts,
			});

		case CLOSE_ALERT:
			// make sure this message is not in there yet
			const newAlertArray = state.alerts.filter((alert: IAlertType) => {
				return (alert.id !== action.id);
			});

			return Object.assign({}, state, {
				alerts: newAlertArray,
			});

		case MARK_ALERT:
			// make sure this message is not in there yet

			const markedAlertArray = state.alerts.map((alert: IAlertType) => {
				if (alert.id === action.id) {
					alert.shown = true;
				}
				return alert
			});

			return Object.assign({}, state, {
				alerts: markedAlertArray,
			});

		default:
			return state;
	}
}
