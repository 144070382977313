import * as React from 'react';
import * as styles from './AccountChangeData.scss';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import AccountService from '../../services/AccountService';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import InputField from '../../../../components/Layout/Form/InputField';
import Button from '../../../../components/General/Button/Button';
import { withRouter, RouterProps } from 'react-router';
import AlertService from '../../../Base/services/AlertService';
import { connect } from 'react-redux';

interface IAccountChangeDataProps {
    data: IAccountInfoType,
    accountReducer: any;
}

interface IAccountChangeDataStates {
    firstName: string,
	lastName: string,
	loading: boolean,
}

class AccountChangeData extends React.Component<IAccountChangeDataProps & RouterProps, IAccountChangeDataStates> {

	constructor (props: IAccountChangeDataProps) {
		super(props);

		this.state = {
            firstName: props.accountReducer.accountInfo.firstName,
			lastName: props.accountReducer.accountInfo.lastName,
            loading: false,
		};
    }

    private onSubmit = () => {

        this.setState({ loading: true });

        const { firstName, lastName } = this.state;

        const data = Object.assign({}, this.props.data, { firstName, lastName });

        AccountService.changeAccountData(data, (response: any) => {

            this.setState({ loading: false });

            if (response) {

                setTimeout(() => { 
					AlertService.add(
                        'change-data-success',
                        'Deine Daten wurden erfolgreich geändert.',
					);
				}, 100);

                this.props.history.push('/mein-benutzerkonto/meine-daten/');
            }

        },( error: any ) => {

            this.setState({ loading: false });

            AlertService.error(
				'error-'+ error,
				error,
			);
        });
    }

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {

		return [
			{
				label: 'Mein Benutzerkonto',
				route: 'AccountInfoRoute',
			} as IBreadcrumbItemType,
			{
				label: 'Allgemeine Daten Ändern',
			} as IBreadcrumbItemType,
		];
	}

	public render () {
        const { loading } = this.state;
		return (
			<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
				<H1 align="center">
                    Allgemeine Daten ändern
				</H1>

                <FormWrapper
                    onSubmit={this.onSubmit}
                >

                    <InputField
						title="Vorname"
						name="firstName"
						placeholder="Erforderlich"
						onChange={this.onChangeInput}
						value={this.state.firstName}
						required={true}
					/>

					<InputField
						title="Nachname"
						name="lastName"
						onChange={this.onChangeInput}
						value={this.state.lastName}
					/>

                    <p>&nbsp;</p>

                    <Button
                        theme="primary"
                        size="xxl"
                        fullWidth={true}
                        type="submit"
                        loading={loading}
                    >
                        Änderungen speichern
                    </Button>

                    <Button
                        theme="default"
                        size="xxl"
                        fullWidth={true}
                        loading={loading}
                        route="AccountInfoRoute"
                    >
                        Abbrechen
                    </Button>

                </FormWrapper>
			</PageWrapper>
		);
    }

    private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[name]: value
        });
    }
}

function mapStateToProps(state: any) {
	return {
		accountReducer: state.accountReducer,
	}
}


export default withRouter(connect(mapStateToProps, {})(AccountChangeData));
