import * as React from 'react';
import * as styles from './ShopDetailInfo.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';


class ShopDetailInfoSkeleton extends React.Component<{}> {
	public render () {

		return (
			<div className={styles.shopDetailInfoContainer}>
			
				<div className={styles.shopDetailInfoItem}>
					<SkeletonInline width={200}/>
					<SkeletonInline width={200}/>
					<SkeletonInline width={200}/>
					<SkeletonInline width={200}/>
				</div>

			</div>
		);
	}
}

export default ShopDetailInfoSkeleton;
