import * as React from 'react';
import * as styles from './SectionTitle.scss';
import SkeletonInline from '../Skeleton/SkeletonInline';

interface ISectionTitleSkeletonProps {
	boxed?: boolean;
	customClassName?: string;
}

class SectionTitleSkeleton extends React.Component<ISectionTitleSkeletonProps> {

	public render() {
		const { boxed, customClassName } = this.props;

		return (
			<div className={`${styles.sectionTitleWrapper} ${boxed ? styles.sectionTitleWrapperBoxed : ''} ${customClassName ?? ''}`}>
				<div className={styles.sectionTitleHeadline}>
                    <SkeletonInline width={150}/>
				</div>

				<span className={styles.sectionTitleShowAll}>
                    <SkeletonInline />
                </span>
			</div>
		);
	}

}

export default SectionTitleSkeleton;
