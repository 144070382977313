import { ActionType } from 'typesafe-actions';
import BaseReducer, { baseInitialState} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { ILeafletGroupedType } from '../../types/ILeafletGroupedType';
import * as leafletGroupedActions from './leafletGroupedActions';

export type LeafletGroupedAction = ActionType<typeof leafletGroupedActions>;

export interface ILeafletGroupedStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: ILeafletGroupedType };
	readonly queries: { [queryKey: string]: ILeafletGroupedType[] };
}

export function leafletGroupedReducer(
	state = baseInitialState as ILeafletGroupedStateType,
	action: LeafletGroupedAction,
) {
	const Reducer = new BaseReducer('leafletGroupedReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
