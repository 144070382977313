import * as React from 'react';
import { ReactNode } from 'react';
import { withRouter } from 'react-router';
import Button from '../App/components/General/Button/Button';
import Error from '../App/components/Layout/Error/Error';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
// import AccountService from '../App/modules/Account/services/AccountService';
import LoadingIndicator from '../App/components/Layout/LoadingIndicator/LoadingIndicator';
// import LoadingIndicator from '../App/components/Layout/LoadingIndicator/LoadingIndicator';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import PageWrapper from '../App/components/Layout/PageWrapper/PageWrapper';
import AccountService from '../App/modules/Account/services/AccountService';
import BookmarkPageNavigation from '../App/modules/Bookmark/components/BookmarkPageNavigation/BookmarkPageNavigation';
import BaseRoute from './_BaseRoute';
// import BookmarkPageNavigation from './PromotionBookmarkListingRoute';


interface IAccessDeniedRoutePropType {
	title?: string;
	content?: string | ReactNode[] | ReactNode;
}

class AccessDeniedRoute extends BaseRoute {
	constructor(props) {
		super(props);

		this.state = {
			rerender: 0,
		};
	}

	public render () {
		
		// check if the user has even tried to fetch
		if (!AccountService.fetchFinished) {
			return (
				<div>
					<Header />
					<BookmarkPageNavigation />

					<PageWrapper>
						<LoadingIndicator />
					</PageWrapper>

					<Footer />
				</div>
			);
		}

		const title = (this.props.title) ? this.props.title : 'Bitte melde Dich an!';
		const content = (this.props.content) ? this.props.content : 'Um diese Funktion nützen zu können musst du angemeldet sein. <br />Erstelle jetzt dein kostenloses Benutzerkonto oder melde dich mit deinem vorhandenen Benutzerkonto an.';

		return (
			<div>
				<Header />
				<MainNavigation />

				<PageWrapper>
					<Error
						title={title}
					>
						<div dangerouslySetInnerHTML={{ __html: content }} />

						<br />
						<br />

						<Button
							theme="primary"
							size="l"
							route="LoginRoute"
						>
							Jetzt anmelden
						</Button>
					</Error>
				</PageWrapper>

				<Footer />
			</div>
		);
	}
}

export default withRouter(AccessDeniedRoute);
