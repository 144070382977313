/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const THEMEWORLD_FETCH_ENTRIES = '@BasePipeline/themeworldReducer_FETCH_ENTRIES';
export const THEMEWORLD_FETCH_ENTRY   = '@BasePipeline/themeworldReducer_FETCH_ENTRY';
export const THEMEWORLD_ADD_ENTRIES = '@BasePipeline/themeworldReducer_ADD_ENTRIES';
export const THEMEWORLD_ADD_ENTRY = '@BasePipeline/themeworldReducer_ADD_ENTRY';

export const THEMEWORLD_SET_ERRORS   = '@BasePipeline/themeworldReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchThemeworlds (queryKey: string) {
	return {
		type: THEMEWORLD_FETCH_ENTRIES,
		queryKey,
	};
}

export function addThemeworldEntries (queryKey: string, entries: IThemeworldType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: THEMEWORLD_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addThemeworldEntry (queryKey: string, entry: IThemeworldType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: THEMEWORLD_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setThemeworldErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: THEMEWORLD_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
