import * as React from 'react';
import { connect } from 'react-redux';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import geolocationConfig from '../../../../config/geolocationConfig';
import LocationInputModal from '../../components/LocationInputModal/LocationInputModal';
import { setGeolocation } from '../../store/Geolocation/geolocationActions';
import { IGeolocationType } from '../../types/IGeolocationType';
import DataStateService from '../../../../services/DataStateService';

interface ILocationInputModalContainerState {
	searchResults?: IGeolocationType[];
	searchTerm: string;
}


interface ILocationInputModalContainerProps {
	setGeolocation?: (geolocation: IGeolocationType, expiry: ExpiryType) => void,
	geolocationReducer?: any,
	onCloseModal: () => void;
}

type ExpiryType = 'short' | 'medium' | 'long' | null;

class LocationInputModalContainer extends React.Component<ILocationInputModalContainerProps, ILocationInputModalContainerState> {

	/**
	 * Returns an array of suggested geolocations from config
	 */
	private static getDefaultGeolocationSuggestions(): IGeolocationType[] {
		return geolocationConfig.defaultLocationSuggestions;
	}

	private searchDefererTimeout: any;

	constructor(props: ILocationInputModalContainerProps) {
		super(props);

		this.state = {
			searchTerm: '',
		};
	}

	public render () {
		const { geolocationReducer, onCloseModal } = this.props;
		const { searchResults } = this.state;
		const setGeolocationFunc = this.props.setGeolocation;

		const currentLocation = ( geolocationReducer.currentLocation ) ? geolocationReducer.currentLocation : null;

		return (
			<LocationInputModal
				currentLocation={currentLocation}
				setGeolocation={setGeolocationFunc}
				defaultGeolocationSuggestions={LocationInputModalContainer.getDefaultGeolocationSuggestions()}
				geolocationSuggestions={searchResults}
				searchForLocations={this.searchForLocations}
				onCloseModal={onCloseModal}
			/>
		);
	}

	/**
	 * Triggers search by defering it first and making sure we only send requests, when the user stopped typing for a while
	 *
	 * @param searchterm
	 */
	private searchForLocations = (searchterm: string) => {
		if (this.searchDefererTimeout) {
			clearTimeout(this.searchDefererTimeout);
		}

		this.searchDefererTimeout = setTimeout(() => {
			this.fetchSearchResults(searchterm);
		}, 400);
	};

	/**
	 * Makes the request to api to get location suggestions based on the searchterm
	 *
	 * @param searchterm
	 */
	private async fetchSearchResults(searchterm: string) {
		const queryKey = `location-${searchterm}`;
		const afCoreRequest = new AfCoreRequest(queryKey);

		if (!searchterm || searchterm.length < 2) {
			this.setState({
				searchTerm: '',
			});
			return;
		}

		afCoreRequest.getRequest({
			url: '/api/public/v1/cities/postcodes/autocomplete',
			params: {
				q: searchterm,
			},
		} as IRequestObject)
         .then((response: RequestMiddlewarePipeline) => {
			const locationArrayRaw = response.getContent();
			const locationArr = [];

			for (const location of locationArrayRaw) {
				const city = (location.city) ? location.city.name : '';
				const zipCode = (location.zipCode) ? `${location.zipCode}, ${location.country.name} ` : '';

				locationArr.push({
					label: city,
					zipCode,
					lat: location.latitude,
					manualSelection: true,
					id: location.id,
				} as IGeolocationType);
			}

			this.setState({
				searchResults: locationArr,
			});
		});
	}
}

function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		setGeolocation: (geolocation: IGeolocationType, expiry: ExpiryType = 'long') => {
			DataStateService.refreshStateKey({ geolocationKey : `${geolocation.lng}-${geolocation.lat}`});
			dispatch(setGeolocation(geolocation, expiry));
		},
	};
}

function mapStateToProps (state: any) {
	return {
		geolocationReducer: state.geolocationReducer,
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(LocationInputModalContainer);
