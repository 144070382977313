import * as React from 'react';
import * as styles from './ProductPromotionInLeafletGrid.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';


class ProductPromotionInLeafletGridSkeleton extends React.Component<{}> {

	public render() {

		const limitArr = Array.from(Array(5).keys());

		return (
			<div className={styles.promotion}>

				{limitArr.map((index) => {
					
					return (
						<div
							className={styles.promotionItem}
							key={`promotion-product-leaflet-grid-${index}`}
						>
							<div className={styles.promotionItemInner}>
							
								<a className={styles.productPromotionItem}>

									<span className={styles.productPromotionItemImageContainer}>
										<span className={styles.productPromotionItemImage} style={{display: 'block'}}></span>
									</span>

									<span className={styles.productPromotionItemContent}>
										<span className={styles.productPromotionItemLabel}>
											<SkeletonInline />
										</span>

										<span className={styles.productPromotionItemInfo}>
											<SkeletonInline />
										</span>
									</span>

								</a>

							</div>
						</div>
					)})
				}
			</div>
		);
	}
}

export default ProductPromotionInLeafletGridSkeleton;
