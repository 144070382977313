import * as React from 'react';
import { DateTime } from 'luxon';

interface ILeafletItemPropsType {
    validFrom: DateTime | string;
    validTo: DateTime | string;
}

/*
* An alternative ValidDate component only used for a specific set of client leaflets (for example Hofer)
* that shows the date not as full value, but as relative label (aktuell, jetzt neu, abgelaufen) for the users to avoid issues with non available items before validTo date.
*/
export default class LeafletRelativeValidDate extends React.Component<ILeafletItemPropsType> {
    public static defaultProps = {
        size: 'm'
    };

    public render() {
        const { validFrom, validTo } = this.props;

        const from = (typeof validFrom === 'string') ? DateTime.fromISO(validFrom) : validFrom;
        const to = (typeof validTo === 'string') ? DateTime.fromISO(validTo) : validTo;

        const today = DateTime.local().startOf('day');
        const fromDiff = from.startOf('day').diff(today, 'days').days;
        const toDiff = to.startOf('day').diff(today, 'days').days;

        // if run out already
        if (toDiff < 0) {
            return 'abgelaufen';
        }

        // if in the future
        if (fromDiff > 0) {
            return `ab ${from.toFormat('dd.LL')}`;
        }

        // if published within the last 6 days
        if (fromDiff >= -6) {
            return 'jetzt neu';
        }

        // otherwise show it as current
        return 'aktuell';
    }
}