/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IProductPromotionType } from '../../types/IProductPromotionType';

export const PRODUCT_PROMOTION_FETCH_ENTRIES = '@BasePipeline/productPromotionReducer_FETCH_ENTRIES';
export const PRODUCT_PROMOTION_FETCH_ENTRY = '@BasePipeline/productPromotionReducer_FETCH_ENTRY';
export const PRODUCT_PROMOTION_ADD_ENTRIES = '@BasePipeline/productPromotionReducer_ADD_ENTRIES';
export const PRODUCT_PROMOTION_ADD_ENTRY = '@BasePipeline/productPromotionReducer_ADD_ENTRY';

export const PRODUCT_PROMOTION_SET_ERRORS   = '@BasePipeline/productPromotionReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function addPromotionEntries (queryKey: string, entries: IProductPromotionType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_ADD_ENTRIES,
		queryKey,
		entries: entries.map(transformProductPromotion),
		metaData,
		options
	};
}

export function addPromotionEntry (queryKey: string, entry: IProductPromotionType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_ADD_ENTRY,
		queryKey,
		entry: transformProductPromotion(entry),
		metaData,
		options,
	};
}

export function setPromotionErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}





/* #### Global leaflet transformations #### */

function transformProductPromotion(productPromotion: IProductPromotionType): IProductPromotionType {
	return {
		...productPromotion,
	};
}