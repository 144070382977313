import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import AccountInfoContainer from '../App/modules/Account/containers/AccountInfo/AccountInfoContainer';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import BaseRoute from './_BaseRoute';


class AccountInfoRoute extends BaseRoute {
	public render () {

		return (
			<div>
				<Header />
				<MainNavigation />

				<AccountInfoContainer
					{...this.props}
				/>

				<Footer fluid={true} />
			</div>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		AccountInfoRoute,
		`accountInfo`,
		{
			metaTitle: `Meine Daten | Aktionsfinder`,
		} as IRouteMetadataSetterType
	)
);
