const serverErrorMap = {
    201: 'Deine Anmeldung zum Newsletter ist bereits bestätigt.',
    400: 'Fehlerhafte Eingabe.',
    401: 'Die angegebene E-Mail Adresse ist ungültig.',
    403: 'Diese Aktion ist leider nicht erlaubt.',
    404: 'Konnte nicht gefunden werden.',
    424: 'Bei deiner Anmeldung ist ein Fehler aufgetreten. Bitte versuche es erneut oder wende dich an office@aktionsfinder.at um Hilfe zu erhalten.',
	500: 'Leider ist ein unerwarteter Fehler aufgetreten, bitte versuche es erneut.'
};

export default serverErrorMap;
