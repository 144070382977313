import * as React from 'react';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import Button from '../../../../components/General/Button/Button';
import appConfig from '../../../../config/appConfig';
import CookieService from '../../../../services/CookieService';
import GeolocationService from '../../services/GeolocationService';
import { IGeolocationType } from '../../types/IGeolocationType';
import ModalList from '../Modal/ModalList';
import ModalListItem from '../Modal/ModalListItem';
import LocationInputField from './LocationInputField';
import * as styles from './LocationInputModal.scss';

interface ILocationInputModalState {
	inputValue: string,
}
type ExpiryType = 'short' | 'medium' | 'long' | null;

interface ILocationInputModalProps {
	currentLocation: IGeolocationType;
	setGeolocation?: (geolocation: IGeolocationType, expiry: ExpiryType) => void;
	defaultGeolocationSuggestions: IGeolocationType[];
	geolocationSuggestions?: IGeolocationType[];
	searchForLocations: (searchterm: string) => void;
	onCloseModal?: () => void;
}

class LocationInputModal extends React.PureComponent<ILocationInputModalProps, ILocationInputModalState> {
	constructor(props: ILocationInputModalProps) {
		super(props);

		this.state = {
			inputValue: '',
		};
	}

	public render() {
		const { currentLocation, defaultGeolocationSuggestions, geolocationSuggestions } = this.props;
		const { inputValue } = this.state;
		const suggestions = (geolocationSuggestions && inputValue) ? geolocationSuggestions : defaultGeolocationSuggestions;

		return (
			<React.Fragment>
				{(!currentLocation || !currentLocation.manualSelection) ? null :
					<React.Fragment>
						<div className={styles.CurrentLocation}>{currentLocation.label}</div>
						<div className={styles.CurrentLocationSource}>
							{(currentLocation.manualSelection) ? '(manuell gewählt)' : '(automatisch erkannt)'}
						</div>

						{(!currentLocation.manualSelection) ? null :
							<Button
								theme="primary"
								size={"l"}
								fullWidth={true}
								onClick={this.deselectLocationFromClick}
							>
								Automatisch erkennen
							</Button>
						}
						<div>&nbsp;</div>
					</React.Fragment>
				}

				<div className={styles.InputField}>
					<LocationInputField
						onChange={this.onChangeLocationInputField}
						placeholder="Ort oder Postleitzahl suchen ..."
						value={inputValue}
						onClickCancel={this.clearSearchField}
					/>
				</div>

				<ModalList
					title="Vorschläge"
				>
					{
						(suggestions.map((item: IGeolocationType) => {

							return (
								<ModalListItem
									key={`location-input-item-${(item.id) ? item.id : item.label}`}
									onClick={this.selectLocationFromClick}
									onClickItem={item}
									showIcon={true}
									icon='map-marker'
								>
									<span className={styles.ListItemInfo}>

										{item.label}

										<span className={styles.ListItemZipCode}>
											{item.zipCode}
										</span>

									</span>

								</ModalListItem>
							);
						}))
					}
				</ModalList>

				{(suggestions.length > 0) ? null :
					<p className={styles.NoResults}>
						Keine Ergebnisse
					</p>
				}
			</React.Fragment>
		);
	}

	/**
	 * Handles click on a location item
	 *
	 * @param item
	 */
	private selectLocationFromClick = (item: IGeolocationType) => {
		const { setGeolocation, onCloseModal } = this.props;

		if (!item || !setGeolocation) {
			return;
		}

		setGeolocation(item, 'long');

		if (onCloseModal) {
			onCloseModal();
		}
	};

	/**
	 * Deselects the current location
	 */
	private deselectLocationFromClick = () => {
		const { setGeolocation, onCloseModal } = this.props;

		CookieService.delete('loc');

		if (isServer()) {
			return;
		}

		GeolocationService.initGeolocation((geolocation: IGeolocationType) => {
			if (appConfig.isDev) {
				console.log('I GOT TO THE LOCA: ', geolocation);
			}
			if (setGeolocation) {
				setGeolocation(geolocation, 'short');
			}
		});

		if (onCloseModal) {
			onCloseModal();
		}
	};

	/**
	 * Handle search input change
	 *
	 * @param value
	 */
	private onChangeLocationInputField = (value: string) => {
		this.setState({
			inputValue: value,
		}, () => {
			this.props.searchForLocations(value);
		});
	}

	/**
	 * Removes the input field content
	 */
	private clearSearchField = () => {

		this.setState({
			inputValue: '',
		});
	}
}

export default LocationInputModal;
