import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ThemeworldDetailContainer from '../App/modules/Themeworld/containers/ThemeworldDetail/ThemeworldDetailContainer';
import DataStateService from '../App/services/DataStateService';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { withTitlePostfix, withClickbait, pluralizerPromotionsAndSpecials, maxDiscountInTitle } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class ThemeworldDetailRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await ThemeworldDetailRouteProps(state);

		return { reduxData };
	}

	shouldComponentUpdate (nextProps) {
		if ((nextProps.routeMetadata && nextProps.routeMetadata.queryKey) === (this.props.routeMetadata && this.props.routeMetadata.queryKey)) {
			return false;
		}
		return true;
	}

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};
		
		return (
			<React.Fragment>

				{/*
				<AdConfig
					desktopIds={[4493482]}
					mobileIds={[4493492, 4493491]}
					sitebarId={4493481}
		/>*/}

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<ThemeworldDetailContainer
							themeworldSlug={`${query.themeworld}`}
							{...this.props}
						/>

						<Footer />
					</div>

					{/*
					<AdItem
						desktopAdId={4493481}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
	/>*/}
				</FlexWrapper>

			</React.Fragment>
		);
	}
}


export default withRouter(
	withRouteMetadata(
		ThemeworldDetailRoute,
		(props: any) => `tw-${props.match.params.themeworld}`,
		{
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} zu ${routeMetadata.themeworld.title} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`${routeMetadata.themeworld.title} » ${routeMetadata.totalItemsCount || ''} aktuelle  ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} zum Durchstöbern » Schnäppchen im Überblick ✓ Rabatt sichern ✓ bares Geld sparen ✓ Hier mehr erfahren!`),
		} as IRouteMetadataSetterType
	)
);
