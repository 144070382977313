import { call, put, takeEvery } from 'redux-saga/effects';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import {
	addConfirmNewslettersEntries,
	CONFIRM_NEWSLETTERS_FETCH_ENTRIES, setConfirmNewslettersErrors,
} from './confirmNewslettersActions';


/**
 * Fetch entry list
 */
function* confirmNewsletters(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.postRequest({
				url: '/api/public/v1/newsletters/verify-subscriptions',
				body: action.params,
			} as IRequestObject);
		});

		yield put(addConfirmNewslettersEntries(
			action.queryKey,
			[],
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setConfirmNewslettersErrors(action.queryKey, e));
	}
}

export function* confirmNewslettersSaga() {
	yield takeEvery(CONFIRM_NEWSLETTERS_FETCH_ENTRIES, confirmNewsletters);
}
