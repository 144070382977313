import * as React from 'react';
import { ILeafletType } from '../../types/ILeafletType';
import * as styles from './LeafletHeader.scss';

import SkeletonInline from '../../../../../App/components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../../App/components/Layout/Skeleton/SkeletonBlock';

interface ILeafletHeaderPropsType {
    leaflet: ILeafletType;
}

class LeafletHeaderSkeleton extends React.PureComponent<ILeafletHeaderPropsType, {}> {
    public static defaultProps = {};

    public render() {

        return (
            <div className={styles.leafletHeaderContainer}>
                <div className={styles.leafletHeaderBlockInfo}>
                    <a>
                        <SkeletonInline width={50} />
                    </a>

                    <div className={styles.leafletHeaderInfo}>
                        <h1 className={styles.leafletHeaderInfoTitle}>
                            <SkeletonBlock style={{ marginBottom: 5, width: 40 }} />
                        </h1>

                        <div>
                            <SkeletonBlock style={{ width: 70 }} />
                        </div>

                    </div>
                </div>
                <div className={styles.leafletHeaderBlockZoom}>
                    <SkeletonInline width={180} />
                </div>
                <div className={styles.leafletHeaderBlockControls}>

                    <button type="button" className={styles.leafletHeaderIcon}>
                        <SkeletonInline />
                    </button>

                </div>
            </div>
        );
    }


}

export default LeafletHeaderSkeleton;
