import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addBrandEntries, addBrandEntry,
	BRAND_FETCH_ENTRIES, BRAND_FETCH_ENTRY, setBrandErrors,
} from './brandActions';

/**
 * Fetch entry list
 */
function* fetchBrands(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/brands/',
				params: action.params,
			} as IRequestObject);
		});

		yield put(addBrandEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setBrandErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry list
 */
function* fetchBrand(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: `/api/public/v1/brands/${action.params.brand}`,
				} as IRequestObject
			);
		});

		yield put(addBrandEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setBrandErrors(action.queryKey, e));
	}
}

export function* brandSaga() {
	yield takeEvery(BRAND_FETCH_ENTRY, fetchBrand);
	yield takeEvery(BRAND_FETCH_ENTRIES, fetchBrands);
}
