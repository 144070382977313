import * as React from 'react';
import * as styles from '../AppExclusive.scss';

interface IAlertBoxProps {
    text: string;
}
class AppExclusiveAlertBox extends React.PureComponent<IAlertBoxProps> {
    public render() {
        const { text } = this.props;

        return (
            <div>
                <div className={styles.appAlertWrapper}>
                    <div className={styles.appAlertLine} />
                    <span >
                        {text}
                    </span>
                </div>
            </div>
        );
    }
}

export default AppExclusiveAlertBox;