import * as React from 'react';
import { ReactChild } from 'react';
import H1 from '../H1/H1';
import PageWrapper from '../PageWrapper/PageWrapper';
import * as styles from './GenericErrorMap.scss';

interface IGenericErrorMapProps {
	children?: ReactChild,
	error: any,
	serverErrorMap: {[key: number]: string},
}

class GenericErrorMap extends React.PureComponent<IGenericErrorMapProps, {}> {
	public render () {

		const { children, error, serverErrorMap} = this.props;

		let err = 'No success';

		if (error.errors && error.errors[0] && error.errors[0].status) {
			err = (serverErrorMap[error.errors[0].status])
		}

		return (
			<PageWrapper>
				<div className={styles.ErrorWrapper}>
					<H1 title="Ein Fehler ist aufgetreten">
						{children}
						{err}
					</H1>
				</div>
			</PageWrapper>
		);
	}
}

export default GenericErrorMap;
