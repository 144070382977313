import * as React from 'react';
import bookmarkConfig from '../../../../config/bookmarkConfig';
import Icon from '../../../Base/components/Icon/Icon';
import * as styles from './BookmarkIcon.scss';
import { BookmarkEntityType } from '../../types/BookmarkEntityType';

export interface IBookmarkIconProps {
	isActive?: boolean;
	label?: string;
	activeIcon?: string;
	inactiveIcon?: string;
	component?: any;
	entity: BookmarkEntityType,
	addBookmark: () => void;
	removeBookmark: () => void;
	className?: string;
	children?: () => void;
}

class BookmarkIcon extends React.Component<IBookmarkIconProps, {}> {
	public render() {
		const { isActive, className, removeBookmark, addBookmark, label } = this.props;

		const globalLabel = this.getLabel();

		if (isActive) {
			return (
				<button
					className={`${styles.bookmarkButton} ${styles.activeButton} ${className}`}
					type="button"
					onClick={removeBookmark}
					aria-label={globalLabel}
					title={globalLabel}
				>
					<i>{this.getIconOrContent()}</i>
					{(label) ? <span>&nbsp;{label}</span> : null}
				</button>
			);
		}

		return (
			<button
				className={`${styles.bookmarkButton} ${styles.inactiveButton} ${className}`}
				type="button"
				onClick={addBookmark}
				aria-label={label}
				title={label}
			>
				<i>{this.getIconOrContent()}</i>
				{(label) ? <span className={styles.label}>&nbsp;{label}</span> : null}
			</button>
		);
	}

	/**
	 * Returns the correct label
	 */
	private getLabel() {
		const { isActive, entity } = this.props;
		const bookmarkProfile = bookmarkConfig.entities[entity];

		if (!bookmarkProfile || !bookmarkProfile.labels) {
			return null;
		}

		return (isActive) ? bookmarkProfile.labels.remove : bookmarkProfile.labels.add;
	}

	/**
	 * Returns the correct icon or button content
	 */
	private getIconOrContent() {
		const { isActive, activeIcon, inactiveIcon, entity } = this.props;
		const ChildComponent = this.props.component;

		if (ChildComponent) {
			return (
				<ChildComponent
					isActive={isActive}
				/>
			);
		}

		if (isActive && activeIcon) {
			return (
				<Icon icon={activeIcon + ''} />
			);
		}

		if (!isActive && inactiveIcon) {
			return (
				<Icon icon={inactiveIcon + ''} />
			);
		}

		// if no custom icon or component was found -> fetch the one from bookmark profiles
		const bookmarkProfile = bookmarkConfig.entities[entity];

		if (!bookmarkProfile || !bookmarkProfile.icons) {
			console.warn('No bookmark Profile icons found for profile: ' + entity);
			return null;
		}

		const theIconString = (isActive) ? bookmarkProfile.icons.active : bookmarkProfile.icons.inactive;

		return (
			<Icon icon={theIconString} />
		);
	}
}

export default BookmarkIcon;
