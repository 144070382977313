import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import PromotionsAggregatedByBrandsWidgetContainer
	from '../../containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import PromotionsAggregatedByVendorsWidgetContainer
	from '../../containers/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';
import AdItem from '../../../../components/General/AdItem/AdItem';
import CurrentLeafletWidgetContainer from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';


interface IPromotionsFromBrandAndVendorProps {
	vendorSlug: SlugType;
	brandSlug: SlugType;
}

class PromotionsFromBrandAndVendor extends React.Component<IPromotionsFromBrandAndVendorProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, brandSlug, vendorSlug } = this.props;

		const vendorName = (routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title) ? routeMetadata.vendor.title : vendorSlug;
		const brandName = (routeMetadata && routeMetadata.brand && routeMetadata.brand.title) ? routeMetadata.brand.title : brandSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridContainer
								limit={20}
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								vendorSlug={vendorSlug}
								brandSlug={brandSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								onlyNearbyPromotions={false}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByBrandsWidgetContainer
								title={`Ähnliche Marken bei ${vendorName}`}
								widgetKey={`${routeMetadata.queryKey}-brand-widget`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								referenceSlug={brandSlug}
								vendorSlug={vendorSlug}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<PromotionsAggregatedByVendorsWidgetContainer
								widgetKey={`${routeMetadata.queryKey}-vendor-widget`}
								title={`Händler mit ${brandName} Aktionen`}
								limit={5}
								brandSlug={brandSlug}
								excludeSlug={vendorSlug}
								routeCallbk={this.routerCallbkVendorListWidget}
							/>

							
							<CurrentLeafletWidgetContainer
								widgetKey={`promotions-vendor-leaflet-widget-${vendorSlug}`}
								title={`Der aktuelle ${vendorName} Prospekt`}
								btnLabel={`Alle ${vendorName} Prospekte`}
								vendorSlug={vendorSlug}
								limit={1}
								orderBy="validFrom"
								orderDir="asc"
								btnRoute="LeafletsByVendorRoute"
								btnParams={{
									vendor: vendorSlug
								}}
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.brand.title : '',
				route: 'BrandDetailRoute',
				routeParams: {brand: this.props.routeMetadata ? this.props.routeMetadata.brandSlug: ''}
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.vendor.title: '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the brand list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { vendorSlug } = this.props;

		return {
			name: 'PromotionsFromBrandAndVendorRoute',
			params: {
				vendor: vendorSlug,
				brand: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkVendorListWidget = (item: IPromotionsAggregatedByVendorType) => {
		const { brandSlug } = this.props;

		return {
			name: 'PromotionsFromBrandAndVendorRoute',
			params: {
				vendor: item.group.slug,
				brand: brandSlug,
			},
		};
	}
}

export default PromotionsFromBrandAndVendor;
