import React from 'react';
import Link from '../../../Base/components/Link/Link';
import * as styles from './LeafletHeader.scss';
import { isLeafletTypeBasis, isLeafletTypePremium } from '../../../../utils/leafletTypeChecker';
import { ILeafletType } from '../../types/ILeafletType';
import { LeafletViewerType } from '../../hocs/withLeaflet';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import DataLayerService from '../../../Base/services/DataLayerService';

interface LeafletHeaderVendorLogoPropsType {
    leaflet: ILeafletType;
    leafletViewerType: LeafletViewerType;
}

class LeafletHeaderVendorLogo extends React.Component<LeafletHeaderVendorLogoPropsType, {}> {
    public render() {
        const { leaflet, leafletViewerType } = this.props;

        const vendorLogo = leaflet.vendorLogoResolutions && typeof leaflet.vendorLogoResolutions['small'] !== 'undefined' ? leaflet.vendorLogoResolutions['small'] : null;
        const type = leaflet.rwvPublishRangeType;

        if (vendorLogo) {
            // Linking only for default leaflets internally in aktionsfinder
            if (leafletViewerType === LeafletViewerType.DEFAULT) {
                return (
                    <Link
                        route="VendorDetailRoute"
                        params={{
                            vendor: leaflet.vendorSlug,
                        }}
                    >
                        <a
                            className={styles.leafletHeaderVendorItemImage}
                            style={{
                                backgroundImage: `url(${vendorLogo})`,
                            }}
                            onClick={() => {
                                this.openClickOutUrl();
                            }}
                        />
                    </Link>
                );
            } else
                // or if we are in premium mode, but then link to vendor website and not aktionsfinder vendor page
                if (leafletViewerType === LeafletViewerType.INFOPOST && isLeafletTypePremium(type)) {
                    return (
                        <a
                            href={leaflet.vendor?.websiteURL}
                            className={styles.leafletHeaderVendorItemImage}
                            style={{
                                backgroundImage: `url(${vendorLogo})`,
                            }}
                            onClick={() => {
                                this.openClickOutUrl();
                            }}
                            target="_blank"
                        />
                    );
            }

            if (isLeafletTypeBasis(type) && leafletViewerType === LeafletViewerType.INFOPOST) {
                return (
                    <span
                        className={styles.leafletHeaderVendorItemImage}
                        style={{
                            backgroundImage: `url(${vendorLogo})`,
                        }}
                    />
                );
            }
        }

        return null;
    }

    /**
	 * Track click out
	 */
	private openClickOutUrl = () => {
		const { leaflet, leafletViewerType } = this.props;

        if (leafletViewerType === LeafletViewerType.INFOPOST && isLeafletTypePremium(leaflet.rwvPublishRangeType)) {
            
            const clickoutUrl = leaflet.vendor?.websiteURL;
    
            if (clickoutUrl) {
                InternalTrackingService.track('CLICKOUT_LEAFLET', {
                    leafletId: leaflet.id,
                    targetUrl: clickoutUrl,
                    leafletPageId: localStorage.getItem('leafletPagesLastOpenedPage') ?? '',
                    industry_slug: leaflet.vendorIndustrySlugs?.join(','),
                    infopost: leafletViewerType === LeafletViewerType.INFOPOST
                });
    
                DataLayerService.push('CLICKOUT_LEAFLET', {
                    leafletId: leaflet.id,
                    leafletTitle: leaflet.title,
                    vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
                    vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
                    targetUrl: clickoutUrl,
                });
    
                return;
            }
        }
	}
}

export default LeafletHeaderVendorLogo;
