import * as React from 'react';
import * as styles from './CurrentVendorLeaflet.scss';
import { ILeafletType } from '../../types/ILeafletType';
import LeafletImagePlaceholder from '../LeafletImagePlaceholder/LeafletImagePlaceholder';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Datespan from '../../../Base/components/Datespan/Datespan';
import HumanReadableDate from '../../../Base/components/HumanReadableDate/HumanReadableDate';
import { DateTime } from 'luxon';
import Link from '../../../Base/components/Link/Link';
import CurrentVendorLeafletStructuredData from './CurrentVendorLeafletStructuredData';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import LeafletService from '../../services/LeafletService';
import LeafletRelativeValidDate from '../LeafletRelativeValidDate/LeafletRelativeValidDate';
import { onlyRelativeDateHoferHack } from '../../store/Leaflet/leafletActions';

export interface ICurrentVendorLeafletProps {
	leaflet: ILeafletType;
	children: React.ReactNode;
	title?: string;
	centered?: boolean;
}

class CurrentVendorLeaflet extends React.Component<ICurrentVendorLeafletProps, {}> {
	public static defaultProps = {
		children: null,
		centered: true,
	};
	
	public componentDidMount() {
	  const { leaflet } = this.props;
	  const expired = DateTime.local().startOf('day') > DateTime.fromISO(leaflet.validTo);
  
	  // don't track it if it is expired
	  if (expired || !leaflet?.id) {
		return;
	  }

	  InternalTrackingService.batchTrack(`leaflet-impression-${leaflet.id}`, 'LEAFLET_IMPRESSION', {
		industry_slug: leaflet.vendorIndustrySlugs?.join(','),
		leafletId: leaflet.id,
		vendorId: leaflet.vendorId,
	  });
	}

	// TRANSLATE
	public render() {
		const { leaflet, children, title, centered } = this.props;

		const expired = DateTime.local().startOf('day') > DateTime.fromISO(leaflet.validTo);

		return (
			<div className={styles.vendorDetailCurrentLeaflet}>
				<div className={`${styles.leadingLeafletWrapper} ${centered ? styles.leadingLeafletWrapperCentered : null}`}>

					{(!title) ? null : 
						<div className={styles.subHeadingMobile}>{title}</div>
					}

					<div className={styles.currentLeaflet}>
						<Link { ...expired ? {} : LeafletService.generateLeafletRoute(leaflet) }>
							<a className={`${styles.leafletItem} ${expired ? styles.leafletItemExpired : null}`} href="#">
								{( !leaflet.coverPage || !leaflet.coverPage.resolutions ) ?
									<LeafletImagePlaceholder alt={leaflet.title} />
										:
									<ImageEnhanced
										className={styles.leafletItemImage}
										source={leaflet.coverPage.resolutions}
										size={'m'}
										alt={leaflet.title}
									>
										<LeafletImagePlaceholder alt={leaflet.title} />
									</ImageEnhanced>
								}

								<span className={styles.leafletItemContent}>
									<span className={styles.leafletItemLabel} title={leaflet.title}>
										<span className={styles.leafletTitle}>
										{leaflet.title}
										</span>
									</span>
									<span className={styles.leafletItemValid}>
										<time>
											{leaflet.onlyRelativeDate || onlyRelativeDateHoferHack(leaflet) ?
												<LeafletRelativeValidDate
													validFrom={leaflet.validFrom}
													validTo={leaflet.validTo}
												/>
												:
												<Datespan
													from={leaflet.validFrom}
													to={leaflet.validTo}
												/>
											}
										</time>
									</span>
								</span>
								
								<HumanReadableDate
									from={leaflet.validFrom}
									to={leaflet.validTo}
									outputIfEnded="abgelaufen"
									type="short"
									className={styles.productPromotionExtraTime}
								/>
							</a>
						</Link>
					</div>

					<div className={styles.childrenWrapper}>
						{(!title) ? null : 
							<div className={styles.subHeadingDesktop}>{title}</div>
						}

						{children}
					</div>
				</div>

				<CurrentVendorLeafletStructuredData leaflet={leaflet} />
			</div>
		);
	}
}

export default CurrentVendorLeaflet;
