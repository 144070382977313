import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import StaticWidget from '../../components/StaticWidget/StaticWidget';

interface IStaticWidgetContainerPropsType {
	title: string;
	data: any;
	widgetKey: string;
}

class StaticWidgetContainer extends React.Component<IStaticWidgetContainerPropsType & IWithDataProps> {
	public render () {
		const { title, data, widgetKey } = this.props;

		return (
			<StaticWidget
				title={title}
				data={data}
				widgetKey={widgetKey}
			/>
		);
	}
}

export default StaticWidgetContainer;
