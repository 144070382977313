import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addPromotionBookmarkEntries,
	PROMOTION_BOOKMARK_FETCH_ENTRIES, setPromotionBookmarkErrors,
} from './promotionBookmarkActions';

/**
 * Fetch entry list
 */
function* fetchPromotionBookmarks(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: '/api/public/v1/personal-list/promotion/detail',
				params: action.params,
			} as IRequestObject);
		});

		yield put(addPromotionBookmarkEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionBookmarkErrors(action.queryKey, e));
	}
}

export function* promotionBookmarkSaga() {
	yield takeEvery(PROMOTION_BOOKMARK_FETCH_ENTRIES, fetchPromotionBookmarks);
}
