import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import ProductGroupDetailDestinationPage
	from '../../components/ProductGroupDetailPage/ProductGroupDetailDestinationPage';
import ProductGroupDetailHubPage from '../../components/ProductGroupDetailPage/ProductGroupDetailHubPage';
import { IProductGroupType } from '../../types/IProductGroupType';
import withRouteMetadata from '../../../../hocs/withRouteMetadata';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import {
	currentEntryWord,
	maxDiscountInTitle,
	pluralizerPromotionsAndSpecials, withClickbait,
	withTitlePostfix
} from '../../../../utils/routeMetadataUtils';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';

interface IProductGroupDetailPageProps {
	data?: IProductGroupType,
	productGroup: string,
	queryKey: string,
	forceDestinationPage: boolean,
}

class ProductGroupDetailPageContainerInner extends React.Component<IProductGroupDetailPageProps & IWithDataProps & IWithRouteMetadataType> {
	public render() {
		const { data, forceDestinationPage, productGroup, ...restProps } = this.props;

		// if we have subpages -> this is a hub page
		if (!forceDestinationPage && data.childProductGroups && data.childProductGroups.length > 0) {
			return (
				<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
					<ProductGroupDetailHubPage
						productGroupSlug={productGroup}
						productGroup={data as IProductGroupType}
						{...restProps}
					/>
				</PageWrapper>
			);
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
				<ProductGroupDetailDestinationPage
					productGroup={data as IProductGroupType}
					productGroupSlug={productGroup}
					{...restProps}
				/>
			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
		private getBreadcrumbs() {

			return [
				{
					label: 'Aktionen',
					route: 'PromotionListingRoute'
				} as IBreadcrumbItemType,
				{
					label: this.props.data ? this.props.data.title : '',
				} as IBreadcrumbItemType,
			];
		}
}

const ProductGroupDetailPageContainerOuter = withRouteMetadata(
	ProductGroupDetailPageContainerInner,
	(props: any) => {
		return props.queryKey;
	},
	{
		pageTitle: (routeMetadata: IRouteMetadataType) =>
			`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount, true)}`,
		metaTitle: (routeMetadata: IRouteMetadataType) =>
			withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount, true)}`),
		metaDescription: (routeMetadata: IRouteMetadataType, props: {forceDestinationPage: boolean}) => {

			if (props.forceDestinationPage) {
				return withClickbait(`Entdecken Sie alle ${routeMetadata.productGroup.title} Aktionen & Angebote mit bis zu ${maxDiscountInTitle(routeMetadata.maxItemDiscount, true)} Preissenkung » bares Geld sparen ⭐ Preise vergleichen`, '⭐ Jetzt fündig werden!')
			}
			
			return withClickbait(`Alle ${routeMetadata.totalItemsCount || ''} aktuellen ${routeMetadata.productGroup.title} Aktionen » Händler finden ⭐ Preise vergleichen ⭐ Geld sparen`, '⭐ Die besten Deals & Angebote finden! 🤩')
		}
	} as IRouteMetadataSetterType,
	true,
);


export default function ProductGroupDetailPageContainer(props: IProductGroupDetailPageProps) {	
	const queryKey = `productGroup-detail-${props.productGroup}`;

	const RenderComponent = withData(
		ProductGroupDetailPageContainerOuter,
		'productGroupReducer',
		queryKey,
		{
			product_group: props.productGroup,
			forceDestinationPage: props.forceDestinationPage,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'productGroup',
			LoadingComponent: () => {
				if (props.forceDestinationPage) {
					return (
						<ProductGroupDetailDestinationPage
							routeMetadata={{
								pageTitle: '',
								queryKey,
							}}
							productGroupSlug={props.productGroup}
						/>
					)
				}

				return (
					<ProductGroupDetailHubPage
						routeMetadata={{
							pageTitle: '',
							queryKey,
						}}
						productGroupSlug={props.productGroup}
					/>
				)
			}
		},
	);

	return <RenderComponent {...props} />
}

