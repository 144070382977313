import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataOptions } from '../../../../../boilerplate/redux/types/IWithDataOptions';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IdType } from '../../../Base/types/IdType';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionGridGrouped from '../../components/ProductPromotionGridGrouped/ProductPromotionGridGrouped';
import ProductPromotionGridGroupedSkeleton
	from '../../components/ProductPromotionGridGrouped/ProductPromotionGridGroupedSkeleton';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import { getPromotionTypeApiFilterString } from '../../utils/fetchTypeHelper';
import DataStateService from '../../../../services/DataStateService';

type moreRouteCallbkResultType = { name: string, params: {[key: string]: any} } | string;

interface IProductPromotionGridGroupedContainerProps {
	size?: 's' | 'm' | 'l',
	columns?: '1' | '2' | '3',
	gridKey?: string,

	// types to show
	fetchPriceProductPromotion?: boolean,
	fetchAssortementProductPromotions?: boolean,
	fetchProductPromotionGridGroupeds?: boolean,
	fetchVoucherProductPromotions?: boolean,

	pushRouteMetadata?: boolean;

	page?: number,
	limit?: number,
	limitItemsPerGroup?: number,
	orderBy?: 'relevance' | 'createdAt' | 'published',
	orderDir?: 'asc' | 'desc',
	enablePagination?: boolean;

	onlyNearbyPromotions?: boolean; // defines whether only nearby promotions should be used or all of them

	lat?: number,
	lng?: number,
	radius?: number,

	groupBy?: 'productGroup' | 'vendor' | 'city',
	groupTitle?: string,
	groupTitlePlural?: string,

	city?: string,
	zipCode?: IdType,
	vendorSlug?: SlugType,
	productGroupSlug?: SlugType,
	shopSlug?: SlugType,
	industrySlug?: SlugType,
	stateSlug?: SlugType,
	shoppingCenterSlug?: SlugType,
	brandSlug?: SlugType,
	// searchTerm?: string,

	moreLabel?: string,
	moreRouteCallbk?: (productGroup: IProductPromotionGroupedType) => moreRouteCallbkResultType,
	moreRouteChangeble?: boolean;

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing

	children?: React.ReactNode;  // if this is given, this will be shown if the array doesn't return any results

	useLeafletRoute?: boolean;
}

class ProductPromotionGridGroupedContainerInner extends React.Component<IProductPromotionGridGroupedContainerProps & IWithDataProps, {}> {
	public static defaultProps = {
		limit: 6,
		orderBy: 'relevance',
		orderDir: 'desc',
		size: 'm',
		useLeafletRoute: true,
	};

	public render () {
		const { data, children, ...restProps } = this.props;

		return (
			<ProductPromotionGridGrouped
				products={data}
				{...restProps}
			>
				{children}
			</ProductPromotionGridGrouped>
		);
	}
}

export default function ProductPromotionGridGroupedContainer (theProps: IProductPromotionGridGroupedContainerProps) {
	const props = Object.assign({
		limit: 10,
		orderBy: 'relevance',
		orderDir: 'desc',
		groupBy: 'productGroup',
		size: 'm',
		limitItemsPerGroup: 6,
		// radius: 50000,

		onlyNearbyPromotions: true,

		enablePagination: false,
	}, theProps) as IProductPromotionGridGroupedContainerProps;

	const productPromotionTypes = getPromotionTypeApiFilterString(theProps);

	const RenderComponent = withData(
		ProductPromotionGridGroupedContainerInner,
		'productPromotionGroupedReducer',
		`${props.gridKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			page: props.page,

			groupBy: props.groupBy,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			limit_promotions_per_category: props.limitItemsPerGroup,

			lat: props.lat,
			lng: props.lng,
			radius: props.radius,

			onlyNearbyPromotions: props.onlyNearbyPromotions,
			pushRouteMetadata: props.pushRouteMetadata,

			promotion_type: productPromotionTypes,
			city: props.city,
			zip_code: props.zipCode,
			store_vendor: props.vendorSlug,
			product_group: props.productGroupSlug,
			store: props.shopSlug,
			shopping_center: props.shoppingCenterSlug,
			industry: props.industrySlug,
			state: props.stateSlug,
			brand: props.brandSlug,
			// search_term: props.searchTerm,
		},
		{
			LoadingComponent: () => (
				<ProductPromotionGridGroupedSkeleton
					limit={props.limit}
					limitItemsPerGroup={props.limitItemsPerGroup}
					size={props.size}
					columns={props.columns}
					gridKey={props.gridKey}
				/>
			),
		} as IWithDataOptions
	);

	return <RenderComponent {...props} />;
}
