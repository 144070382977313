import * as React from 'react';
import * as styles from './LotteryInfo.scss';
import Button from '../../../../components/General/Button/Button';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';

class LotteryInfoSkeleton extends React.Component<{}> {

	public render () {

		return (
            <React.Fragment>

                <h2>
                    <SkeletonInline />
                </h2>

                <div className={styles.lotteryInfoBlock}>
                    <div className={styles.lotteryInfoWin}>
                        <SkeletonInline />
                    </div> 

                    <h4 className={styles.lotteryInfoBlockTitle}>
                        <SkeletonInline />
                    </h4>
                </div>

                <div className={styles.lotteryInfoHistoryBlock}>

                    <h4 className={styles.lotteryInfoBlockTitle}>
                    <SkeletonInline />
                    </h4>

                    <div className={`${styles.lotteryInfoWin} ${styles.lotteryInfoWinAmount}`}>
                        <SkeletonInline />
                    </div>

                    <div className={styles.lotteryInfoHistoryText}>
                        <SkeletonInline />
                    </div>

                    <div className={styles.lotteryInfoHistoryText}>
                        <SkeletonInline />
                    </div>

                    <div className={styles.lotteryInfoParticipateButton}>
                        <Button
                            theme="primary"
                            size="xxl"
                            fullWidth={true}
                            loading={true}
                        >
                            Jetzt teilnehmen...
                        </Button>
                    </div>

                </div>

                <h4 className={styles.lotteryInfoTitle}>
                    <SkeletonInline />
                </h4>

                <div className={styles.lotteryInfoDescription}>
                    <SkeletonInline />
                    <SkeletonInline />
                    <SkeletonInline />
                    <SkeletonInline />
                </div>

                <div className={styles.lotteryInfoParticipateButton}>
                    <Button
                        theme="primary"
                        size="xxl"
                        fullWidth={true}
                        loading={true}
                    >
                        Jetzt teilnehmen...
                    </Button>
                </div>
                
                <h4 className={styles.lotteryInfoTitle}><SkeletonInline /></h4>

                <div className={styles.lotteryInfoWinnersBlocks}>
                    <div className={styles.lotteryInfoWinnersBlock}>
                        <SkeletonInline />
                    </div>
                    <div className={styles.lotteryInfoWinnersBlock}>
                        <SkeletonInline />
                    </div>
                    <div className={styles.lotteryInfoWinnersBlock}>
                        <SkeletonInline />
                    </div>
                </div>

            </React.Fragment>
		);
    }
}

export default LotteryInfoSkeleton;
