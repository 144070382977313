import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from 'App/modules/Base/types/SlugType';
import ShoppingCenterList from '../../components/ShoppingCenterList/ShoppingCenterList';
import { IShoppingCenterType } from '../../types/IShoppingCenterType';

interface IShoppingCenterListContainerProps {
	queryKey?: string;

	title?: string;
	pushRouteMetadata?: boolean;
	page?: number;
	limit?: number;

	enablePagination?: boolean;

	vendorSlug?: SlugType;
	stateSlug?: SlugType;
	citySlug?: SlugType;

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class ShoppingCenterListContainerInner extends React.Component<IShoppingCenterListContainerProps & IWithDataProps,{}> {

	public render() {
		const { data, ...restProps } = this.props;

		return (
			<ShoppingCenterList 
				shoppingCenters={data as IShoppingCenterType[]} 
				{...restProps} 
			/>
		)
	}
}

export default function ShoppingCenterListContainer(theProps: IShoppingCenterListContainerProps) {
	const props = Object.assign({
			limit: 50,
		},
		theProps,
	);

	const RenderComponent = withData(
		ShoppingCenterListContainerInner,
		'shoppingCenterReducer',
		props.queryKey,
		{
			size: props.limit,
			page: props.page,
			pushRouteMetadata: props.pushRouteMetadata,

			store_vendor: props.vendorSlug,
			state: props.stateSlug,
			city: props.citySlug,
		},
	);

	return <RenderComponent {...props} />;
}
