import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	addShopWidgetEntries,
	setShopWidgetErrors, SHOP_WIDGET_FETCH_ENTRIES,
} from './shopWidgetActions';

/**
 * Fetch entry list
 *
 * @param {any} action
 */
function* fetchShopWidgets(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	let params = afCoreRequest.mergeParams({
		page: 0,
		size: 10,
	}, action.params);

	let theUrl = '/api/public/v1/widgets/stores';

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	      params             = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, params, 7);

	// add geolocation params
	if (action.params.orderBy && action.params.orderBy === 'distance') {
		theUrl = '/api/public/v1/widgets/stores/nearby';
	}

	if (params.leaflet) {
		theUrl =  `/api/public/v1/leaflets/${params.leaflet}/stores`;
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: theUrl,
				params,
			} as IRequestObject);
		});

		if (isServer()) {
			response.addMetaData('reloadOnClient', true);
		}

		yield put(addShopWidgetEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setShopWidgetErrors(action.queryKey, e));
	}
}

export function* shopWidgetSaga() {
	yield takeEvery(SHOP_WIDGET_FETCH_ENTRIES, fetchShopWidgets);
}
