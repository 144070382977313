import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import * as styles from './ContentFragment.scss';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';


class ContentFragmentSkeleton extends React.Component<{}, {}> {
	public static defaultProps = {};

	public render () {
		return (
			<PageWrapper>
				<H1>
					<SkeletonInline />
				</H1>

				<div className={styles.contentFragmentWrapper}>

					<SkeletonBlock /> <br />
					<SkeletonBlock /> <br />
					<SkeletonBlock /> <br />
					<SkeletonBlock /> <br />
					<SkeletonBlock /> <br />

				</div>

			</PageWrapper>
		);
	}
}

export default ContentFragmentSkeleton;
