/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IShoppingCenterType } from '../../types/IShoppingCenterType';

export const SHOPPING_CENTER_FETCH_ENTRIES = '@BasePipeline/shoppingCenterReducer_FETCH_ENTRIES';
export const SHOPPING_CENTER_FETCH_ENTRY   = '@BasePipeline/shoppingCenterReducer_FETCH_ENTRY';
export const SHOPPING_CENTER_ADD_ENTRIES = '@BasePipeline/shoppingCenterReducer_ADD_ENTRIES';
export const SHOPPING_CENTER_ADD_ENTRY = '@BasePipeline/shoppingCenterReducer_ADD_ENTRY';

export const SHOPPING_CENTER_SET_ERRORS   = '@BasePipeline/shoppingCenterReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchShoppingCenters (queryKey: string) {
	return {
		type: SHOPPING_CENTER_FETCH_ENTRIES,
		queryKey,
	};
}

export function addShoppingCenterEntries (queryKey: string, entries: IShoppingCenterType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOPPING_CENTER_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addShoppingCenterEntry (queryKey: string, entry: IShoppingCenterType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOPPING_CENTER_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setShoppingCenterErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOPPING_CENTER_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
