import * as React from 'react';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import PromotionsAggregatedByBrandsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../../Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import ShopListWidgetContainer from '../../../Vendor/containers/ShopListWidgetContainer/ShopListWidgetContainer';
import LeafletGridGroupedContainer from '../../containers/LeafletGridGrouped/LeafletGridGroupedContainer';
import { ILeafletGroupedType } from '../../types/ILeafletGroupedType';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import { IVendorType } from '../../../Vendor/types/IVendorType';
import VendorDetailHeader from '../../../Vendor/components/VendorDetailHeader/VendorDetailHeader';
import AdItem from '../../../../components/General/AdItem/AdItem';
import CurrentLeafletWidgetContainer from '../../containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';
import AppPromotionBanner from '../../../Base/AppPromotionBanner/AppPromotionBanner';

interface ILeafletsByVendorProps {
	vendor: IVendorType;
	vendorSlug: SlugType;
}

class LeafletsByVendor extends React.Component<ILeafletsByVendorProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { vendor, routeMetadata, params } = this.props;
		const { vendorSlug } = params;
		const vendorName = (routeMetadata.vendor?.title) ? routeMetadata.vendor.title : vendorSlug;

		const vendorObj = vendor || {
			name: vendorName,
			slug: routeMetadata?.vendor?.slug,
			description: '',
			industrySlugs: [],
		};

		const industrySlug = vendorObj.industrySlugs.length ? vendorObj.industrySlugs[0] : null;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403489}
					maxHeight={250}
					type="billboard"
				/>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>

						<VendorDetailHeader vendor={vendorObj} vendorSlug={vendorSlug} title={routeMetadata.pageTitle} />
						<AppPromotionBanner />
						<AppMainColBox boxed nested>
							<LeafletGridGroupedContainer
								limit={8}
								limitItemsPerGroup={6}
								enablePagination={true}
								onlyNearbyLeaflets={true}
								moreLabel={`Alle ${vendorName} Prospekte für %title% anzeigen`}
								moreRouteCallbk={this.routerCallbkPromotionListWidget}
								gridKey={routeMetadata.queryKey}
								groupTitle={`${vendorName} Prospekte für %title%`}
								groupTitlePlural={`${vendorName} Prospekte für %title%`}
								pushRouteMetadata={true}
								vendorSlug={vendorSlug}
								vendorName={vendorName}
								withCurrentLeafletVendor
								leafletDescription={vendorObj?.leafletDescription}
								contentBreaks={{
								
									1: (
										<AdItem
											mobileAdId={4403478}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403477}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</LeafletGridGroupedContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>

						<div>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey={`lv-${routeMetadata.queryKey}-product-groups-widget`}
								limit={10}
								title={`Beliebte Produktkategorien bei ${vendorName}`}
								routeCallbk={this.routerCallbkProductGroupListWidget}
								vendorSlug={vendorSlug}
							/>

							<PromotionsAggregatedByBrandsWidgetContainer
								widgetKey={`lv-${routeMetadata.queryKey}-brands-widget`}
								title={`Marken in Aktion bei ${vendorName}`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								vendorSlug={vendorSlug}
							/>

							<AdItem
								desktopAdId={4403488}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<ShopListWidgetContainer
								title={`${vendorName} Märkte in deiner Nähe`}
								widgetKey={`lv-${routeMetadata.queryKey}-shops-nearby-widget`}
								limit={5}
								vendorSlug={vendorSlug}
							/>

							<CurrentLeafletWidgetContainer
								title="Ähnliche Prospekte"
								widgetKey={`${routeMetadata.queryKey}-current-leaflet-widget`}
								limit={3}
								vendorSlug={vendorSlug}
								btnLabel={industrySlug ? "Weitere Prospekte" : null}
								btnRoute={industrySlug ? "LeafletsInIndustryRoute" : null}
								btnParams={industrySlug ? {
									industry: industrySlug,
								} : null}
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		const { vendorSlug, routeMetadata } = this.props;
		const vendorName = (routeMetadata.vendor?.title) ? routeMetadata.vendor.title : vendorSlug;

		return [
			{
				label: 'Prospekte',
				route: 'LeafletListingRoute'
			} as IBreadcrumbItemType,
			{
				label: `${vendorName}`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the vendor list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: ILeafletGroupedType) => {
		const { params } = this.props;
		const { vendorSlug } = params;


		return {
			name: 'LeafletsByVendorInStateRoute',
			params: {
				vendor: vendorSlug,
				state: item.slug,
			},
		};
	};

	/**
	 * Defines the route the brand list widget should be routed towards
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { params } = this.props;
		const { vendorSlug } = params;

		return {
			name: 'PromotionsFromBrandAndVendorRoute',
			params: {
				vendor: vendorSlug,
				brand: item.group.slug,
			},
		};
	};

	/**
	 * Defines the route the vendor list widget should be routed towards
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		const { params } = this.props;
		const { vendorSlug } = params;

		return {
			name: 'PromotionsInProductGroupAndVendorRoute',
			params: {
				vendor: vendorSlug,
				productGroup: item.group.slug,
			},
		};
	};
}

export default LeafletsByVendor;
