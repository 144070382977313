const SearchFilterUiStatusService = {
    /**
     * Contains an object map of open filters at any time
     */
    filterGroupsToggleStatus: {},

    /**
     * Toggles the filter group contents open and closed
     *
     * @param groupKey
     */
    getToggleGroupStatus: (searchTerm: string, groupKey: string, newStatus = null): boolean | undefined => {
        // if (!SearchFilterUiStatusService.filterGroupsToggleStatus[searchTerm] || !SearchFilterUiStatusService.filterGroupsToggleStatus[searchTerm][groupKey]) {
        //     return false;
        // }

        return SearchFilterUiStatusService.filterGroupsToggleStatus[searchTerm] && SearchFilterUiStatusService.filterGroupsToggleStatus[searchTerm][groupKey];
    },

    /**
     * Toggles the filter group contents open and closed
     *
     * @param groupKey
     */
    setToggleGroupStatus: (searchTerm: string, groupKey: string, newStatus = null) => {
        const filterGroupsToggleStatus = { ...SearchFilterUiStatusService.filterGroupsToggleStatus };

        // make sure we have a object for this search term
        if (!filterGroupsToggleStatus[searchTerm]) {
            filterGroupsToggleStatus[searchTerm] = {};
        }

        // set the status
        var theStatus = newStatus;

        // if the status is null -> just do the opposite of current status
        if (newStatus === null) {
            theStatus = !filterGroupsToggleStatus[searchTerm][groupKey];
        }

        // now set the new status in a immutable way
        filterGroupsToggleStatus[searchTerm] = {
            ...filterGroupsToggleStatus[searchTerm],
            [groupKey]: theStatus,
        };

        // now apply it
        SearchFilterUiStatusService.filterGroupsToggleStatus = filterGroupsToggleStatus;
    },
} as any;

export default SearchFilterUiStatusService;
