import React from 'react';
import styles from '../CarouselTeaser.scss';
import Icon from '../../Base/components/Icon/Icon';

interface ICarouselTeaserArrowPropsType {
    direction: string;
    clickHandler: () => void;
    hasNext: boolean;
    label: string;
}

class CarouselTeaserArrow extends React.PureComponent<ICarouselTeaserArrowPropsType> {
    public render() {
        const { direction, clickHandler, hasNext } = this.props;
        return (
            <button
                onClick={clickHandler}
                className={styles.carouselButton}
                style={{
                    marginLeft: direction == 'right' ? '0' : 12,
                    marginRight: direction == 'right' ? 12 : '0',
                    left: direction == 'right' ? 'auto' : 0,
                    right: direction == 'right' ? 0 : 'auto',
                }}
            >
                <Icon icon={direction === 'left' ? 'arrow-left' : 'arrow-right'} className={`${styles.carouselArrows}`} />
            </button>
        );
    }
}

export default CarouselTeaserArrow;
