import * as React from 'react';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import Currency from '../../../Base/components/Currency/Currency';

interface IProductPromotionInOtherShopsItemDetailsProps {
	promotion: IProductPromotionType,
}

class ProductPromotionInOtherShopsItemDetails extends React.Component<IProductPromotionInOtherShopsItemDetailsProps, {}> {
	public static defaultProps = {
		size: 'm',
	};

	public render () {
		const { promotion } = this.props;

		if (promotion.type === 'new_price' && promotion.newPrice) {
			// TRANSLATE
			return (
				<span>
				    nur <strong><Currency amount={promotion.newPrice} /></strong>
			    </span>
			);
		}

		if (promotion.type === 'product' && promotion.discountedPrice) {
			// TRANSLATE
			return (
				<span>
			        nur <strong><Currency amount={promotion.discountedPrice} /></strong>
					{( !promotion.originalPrice ) ? null :
					 <span> statt <Currency amount={promotion.originalPrice} /></span>
					}
		        </span>
			);
		}

		return null;
	}
}

export default ProductPromotionInOtherShopsItemDetails;
