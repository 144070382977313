import * as React from 'react';
import * as styles from './SearchPageFilterGroup.scss';
import Icon from '../../../Base/components/Icon/Icon';
import SearchPageFilterGroupCheckboxGroup from './FilterInputs/SearchPageFilterGroupCheckboxGroup';
import {
	SearchPageFilterItemMetadataType,
	SearchPageFilterValueType,
} from './FilterInputs/SearchPageFilterGroupCheckboxGroupItem';
import { isMobile } from '../../../../utils/responsiveUtils';
import Drawer from '../../../Base/components/Drawer/Drawer';
import { DrawerCloseMethod, DrawerOpenMethod } from '../../../Base/components/Drawer/DrawerItem';
import SearchFilterUiStatusService from '../../services/SearchFilterUiStatusService';

export type SearchPageFilterInputType = 'select' | 'multiselect';

interface ISearchPageFilterGroupPropType {
	searchId: string;
	metadataKey: string;
	metadataOptions: SearchPageFilterItemMetadataType[];
	label: string;
	values: SearchPageFilterValueType;
	type: SearchPageFilterInputType;
	defaultState?: 'open' | 'closed';
	onChangeValue: (changeObj: { [key: string]: SearchPageFilterValueType }) => void;
	closeDrawer: DrawerCloseMethod;
	openDrawer: DrawerOpenMethod;
	hideEmptyOptions?: boolean;
	showText?: boolean;
	showDistanceBadge?: boolean;
	showSeachField?: boolean;
}

interface ISearchPageFilterGroupStateType {
	isToggled: boolean;
}

class SearchPageFilterGroup extends React.Component<ISearchPageFilterGroupPropType, ISearchPageFilterGroupStateType> {
	constructor(props: ISearchPageFilterGroupPropType) {
		super(props);

		const savedSearchFilterUiState = SearchFilterUiStatusService.getToggleGroupStatus(props.searchId, props.metadataKey);
		
		// if is opened by default and has values -> open it (also apply extra rule for mobile devices, where we have filters in drawers)
		const isToggledByDefault = (savedSearchFilterUiState ?? (props.defaultState === 'open' || !!props.values)) && !isMobile();

		this.state = {
			isToggled: isToggledByDefault,
		};
	}

	public render() {
		const { label, defaultState, ...restProps } = this.props;
		const { metadataOptions, showDistanceBadge } = this.props;
		const { isToggled } = this.state;

		if (!metadataOptions || Object.keys(metadataOptions).length < 1) {
			return null;
		}

		const valueSummary = this.getValueSummary();
		const drawerIdname = this.getDrawerIdname();

		return (
			<div className={styles.GroupContainer} >
				<div className={styles.GroupWrapper}>

					<div className={styles.GroupLabel} onClick={() => (isToggled) ? this.closeGroup() : this.openGroup()}>
						<span className={styles.GroupTitle}>
							{label}
						</span>

						<span className={styles.GroupValueSummary}>
							{isToggled ? null :
								(isNaN(Number(valueSummary)) || valueSummary === null) ? (
									<span className={styles.TextValueSummary}>{valueSummary}</span>
								) : showDistanceBadge ? (
									<span className={`${styles.DistanceBadge}`}>
										{valueSummary}&nbsp;km
									</span>
								) : (
									<span className={styles.GroupValueSummaryBadge}>
										{valueSummary}
									</span>
								)
							}
						</span>

						<span className={styles.GroupToggle} onClick={() => (isToggled) ? this.closeGroup() : this.openGroup()}>
							{(isToggled) ?
								<Icon icon="arrow-down" />
								:
								<Icon icon="arrow-right" />
							}
						</span>
					</div>

					{(!isToggled && !isMobile()) ? null :
						<div className={styles.GroupContent}>
							<Drawer
								idname={drawerIdname}
								isOpen={isToggled}
								level={2}
							>
								{
									((closeDrawer: DrawerCloseMethod) => (
										<div className={styles.GroupContentInner}>
											<SearchPageFilterGroupCheckboxGroup
												{...restProps}
												closeDrawer={this.closeGroup}
											/>
										</div>
									))
								}
							</Drawer>
						</div>
					}
				</div>
			</div>
		);
	}

	/**
	 * Returns the drawer idname
	 */
	private getDrawerIdname = () => {
		const { metadataKey } = this.props;

		return `search-page-filter-group-${metadataKey}`;
	}

	/**
	 * Opens the groups content
	 */
	private openGroup = () => {
		const { openDrawer, searchId, metadataKey } = this.props;

		// in mobile version open the drawer instead of just toggling it
		if (isMobile()) {
			openDrawer(this.getDrawerIdname(), 2);
			return;
		}

		// in desktop version just toggle the group
		SearchFilterUiStatusService.setToggleGroupStatus(searchId, metadataKey, true);
		this.setState({
			isToggled: true,
		});
	}

	/**
	 * Closes the groups content
	 */
	private closeGroup = () => {
		const { closeDrawer, searchId, metadataKey } = this.props;

		// in mobile version close the drawer instead of just toggling it
		if (isMobile()) {
			closeDrawer(this.getDrawerIdname());
			return;
		}

		// in desktop version just toggle the group
		SearchFilterUiStatusService.setToggleGroupStatus(searchId, metadataKey, false);
		this.setState({
			isToggled: false,
		});
	}

	/**
	 * Returns the content for our value summary badge
	 */
	private getValueSummary = (): string | number | null => {
		const { values, type, metadataOptions, showText, metadataKey } = this.props;

		// Check if the metadataKey is 'distance' and make it a number
		if (metadataKey === 'distance') {
			const distanceOption = metadataOptions.find(option => option.slug === values);
			if (distanceOption) {
				const distanceValue = parseFloat(distanceOption.title);
				return isNaN(distanceValue) ? null : distanceValue;
			}
		}

		// if we have single result entries -> just return the value
		if (type === 'select') {
			// try to find the correct option
			for (const option of metadataOptions) {
				if (option.slug === values) {
					return option.title;
				}
			}
			return '';
		}

		// if we have multiselect -> if nothing selected show nothing, otherwise show the number of selected filters.
		// In special cases we can also show the text 
		if (type === 'multiselect') {
			if (!values || !Array.isArray(values) || values.length < 1) {
				return showText ? 'Keine Einschränkung ' : null;
			}
			else {
				return Object.keys(values).length;
			}
		}

		return '';
	}
}


export default SearchPageFilterGroup;


