import * as React from 'react';
import { connect } from 'react-redux';
import Alerts from '../../components/Alerts/Alerts';
import { closeAlert, markAlertAsShown } from '../../store/Alert/alertActions';
import { showLeafletDiscountModal } from '../../../Leaflet/store/Leaflet/leafletActions';


interface IAlertsContainerProps {
	alertReducer: any,
	onCloseAlert: (id: string) => void;
	onMarkAlertAsShown: (id: string) => void;
	showLeafletDiscountModal?: () => void;
}

class AlertsContainer extends React.Component<IAlertsContainerProps> {
	public render () {
		const { alertReducer, onCloseAlert, onMarkAlertAsShown, showLeafletDiscountModal } = this.props;
			
		return (
			<Alerts
				alerts={alertReducer.alerts}
				onCloseAlert={onCloseAlert}
				onMarkAlertAsShown={onMarkAlertAsShown}
				showLeafletDiscountModal={showLeafletDiscountModal}
			/>
		);
	}
}

function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		onCloseAlert: (id: string) => {
			dispatch(closeAlert(id));
		},
		onMarkAlertAsShown: (id: string) => {
			dispatch(markAlertAsShown(id));
		},
		showLeafletDiscountModal: () => {
			dispatch(showLeafletDiscountModal());
		},
	};
}

function mapStateToProps (state: any) {
	return {
		alertReducer: state.alertReducer,
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);
