import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	GET_SHOPPING_NOTE,
	SAVE_SHOPPING_NOTE,
	setShoppingNote,
	setShoppingNoteErrors,
	setShoppingNoteSavingState,
} from './shoppingNoteActions';

/**
 * fetch shoppingNotes
 */
function* fetchShoppingNote (action: any) {
	const afCoreRequest = new AfCoreRequest(`fetchShoppingNote`);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: '/api/public/v1/personal-list/note',
			} as IRequestObject);
		});

		yield put(setShoppingNote(
			response.getContent('note'),
		));
	} catch (e) {
		yield put(setShoppingNoteErrors('Konnte Notiz nicht laden. Bitte versuche es später erneut.'));
	}
}

/**
 * Add shoppingNotes
 */
function* saveShoppingNote (action: any) {
	const afCoreRequest = new AfCoreRequest(`saveShoppingNote`);

	yield put(setShoppingNoteSavingState('saving'));

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.putRequest({
				url: '/api/public/v1/personal-list/note',
				body: {
					note: action.note,
				}
			} as IRequestObject);
		});

		yield put(setShoppingNoteSavingState('saved'));

	} catch (e) {
		yield put(setShoppingNoteSavingState('error'));
		yield put(setShoppingNoteErrors('Konnte Notiz nicht speichern. Bitte versuche es später erneut.'));
	}
}


export function* shoppingNoteSaga () {
	yield takeEvery(SAVE_SHOPPING_NOTE, saveShoppingNote);
	yield takeEvery(GET_SHOPPING_NOTE, fetchShoppingNote);
}
