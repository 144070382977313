/**
 * Returns a value of all the productPromotion types we want to be fetched in this query
 *
 * @return {string}
 */
export function getPromotionTypeApiFilterString(theProps: any): string {
	const valueMapping = {
		fetchPriceProductPromotions: 'NEW_PRICE_PROMOTION',
		fetchAssortementProductPromotions: 'ASSORTMENT_PROMOTION',
		fetchProductPromotionGridGroupeds: 'PRODUCT_PROMOTION',
		fetchVoucherProductPromotions: 'VOUCHER_PROMOTION',
	};

	const retArr = [];

	for (const paramKey of Object.keys(valueMapping)) {
		if (theProps[paramKey]) {
			retArr.push(valueMapping[paramKey]);
		}
	}

	if (retArr.length < 1) {
		return;
	}

	return retArr.join('&promotion_type=');
}
