import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RouteMetadataService from '../../../../services/RouteMetadataService';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import {
    addPromotionworldGroupedEntries,
    PROMOTIONWORLD_GROUPED_FETCH_ENTRIES, setPromotionworldGroupedErrors,
} from './promotionworldGridGroupedActions';

/**
 * Fetch entry list
 */
function* fetchPromotionworldGrouped(action: any) {
    const afCoreRequest = new AfCoreRequest(action.queryKey);

    let theParams = action.params;
    let url = `/api/public/v1/promotion-worlds/${theParams.leafletSlug}/groups`;
    // get specific group
    if (action.params.groupSlug) {
        url = `/api/public/v1/promotion-worlds/${theParams.leafletSlug}/groups/${theParams.groupSlug}`;
    }
    
    try {
        const response = yield call((): Promise<RequestMiddlewarePipeline> => {
            return afCoreRequest.getRequest({
                url,
                params: theParams,
            } as IRequestObject);
        });

        const content = response.getContent('content');

        // put real content
        yield put(addPromotionworldGroupedEntries(
            action.queryKey,
            content,
            response.getMetaData(),
            action.options,
        ));

        const groupTotalItemsMapping = content.map(obj => ({
                [obj.title]: obj.promotionCount
            })).reduce((acc, obj) => Object.assign(acc, obj), {});

        // put route metadata
        RouteMetadataService.setMetadata(action.queryKey, {
            totalItemsCount: groupTotalItemsMapping
        } as IRouteMetadataType);
    } catch (e) {
        yield put(setPromotionworldGroupedErrors(action.queryKey, e));
    }
}

export function* promotionworldGroupedSaga() {
    yield takeEvery(PROMOTIONWORLD_GROUPED_FETCH_ENTRIES, fetchPromotionworldGrouped);
}
