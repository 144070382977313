import * as React from 'react';
import ResetPasswordService from '../../services/ResetPasswordService';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import InputField from '../../../../components/Layout/Form/InputField';
import Button from '../../../../components/General/Button/Button';
import AlertService from '../../../Base/services/AlertService';

interface IResetPasswordProps {
	email: string,
	passwordResetToken: string,
}

interface IResetPasswordStateType {
	password: string,
	loading: boolean,
	success: boolean,
}

class ResetPassword extends React.Component<IResetPasswordProps, IResetPasswordStateType> {

	constructor(props: IResetPasswordProps) {
		super(props);

		this.state = {
			password: '',
			loading: false,
			success: false,
		};
	}

	private onSubmit = () => {

		this.setState({ loading: true });

		const { password } = this.state;
		const { email, passwordResetToken } = this.props;

		if (password !== '' && (password.length < 8 || password.length > 64)) {
			this.setState({ loading: false }, () => {
				AlertService.error(
					'error-reset-password',
					'Das Passwort muss aus mindestens 8 Zeichen bestehen.',
				);
			});
			return
		}

		const data = {
			email,
			newPassword: password,
			passwordResetToken,
		};

		ResetPasswordService.sendNewPassword(data, (response: any) => {

			this.setState({
				loading: false,
				success: true,
			});

		}, (error: any) => {
			this.setState({ loading: false }, () => {
				AlertService.error(
					'error-' + error,
					error,
				);
			});
		});
	}

	public render() {
		const { loading, success } = this.state;

		if (success) {
			return (
				<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
					<FormWrapper>
						<H1 align="center" title="Passwort geändert">
							Dein Passwort wurde erfolgreich geändert<br />
							und du kannst dich nun mit deinem neuen Passwort anmelden.
						</H1>

						<Button
							theme="primary"
							size="xxl"
							fullWidth={true}
							route="LoginRoute"
						>
							Jetzt anmelden
						</Button>

					</FormWrapper>
				</PageWrapper>
			);
		}

		return (
			<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
				<H1 align="center">
					Neues Passwort erstellen
				</H1>

				<FormWrapper
					onSubmit={this.onSubmit}
				>

					<InputField
						title="Neues Passwort"
						name="password"
						placeholder="*****"
						type="password"
						onChange={this.onChangeInput}
						value={this.state.password}
						required={true}
						autoComplete="false"
					/>

					<p>&nbsp;</p>

					<Button
						theme="primary"
						size="xxl"
						fullWidth={true}
						type="submit"
						loading={loading}
					>
						Absenden
					</Button>

					<Button
						theme="default"
						size="xxl"
						fullWidth={true}
						loading={loading}
						route="LoginRoute"
					>
						Abbrechen
					</Button>

				</FormWrapper>
			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Passwort zurücksetzen',
			} as IBreadcrumbItemType,
		];
	}

	private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[name]: value
		});
	}
}

export default ResetPassword;
