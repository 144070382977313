import * as React from 'react';
import { ReactNode } from 'react';
import * as styles from './WidgetBox.scss';

interface IWidgetBoxContentProps {
  children: ReactNode,
}

class WidgetBoxContent extends React.Component<IWidgetBoxContentProps, {}> {
  public render () {
    const { children } = this.props;

    return (
      <div className={`${styles.widgetBoxContent} widgetBoxContent`}>
        {children}
      </div>
    );
  }
}

export default WidgetBoxContent;
