import * as React from 'react';
import AdItem from '../../../../components/General/AdItem/AdItem';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import NewsletterSignupComponent from '../../../Newsletter/components/Newsletter/NewsletterSignupComponent';
import ShopListWidgetContainer from '../../containers/ShopListWidgetContainer/ShopListWidgetContainer';
import VendorListGroupedContainer from '../../containers/VendorListGrouped/VendorListGroupedContainer';
import StaticWidgetContainer from '../../../Base/containers/StaticWidget/StaticWidgetContainer';
import { FavouriteVendors } from '../../../Base/data/staticWidgetData';

interface IVendorListingProps {
	title: string
}

class VendorListing extends React.Component<IVendorListingProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Händler Übersicht',
			} as IBreadcrumbItemType
		];
	}

	public render() {
		const { title } = this.props;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403495}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{title}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox boxed={true} nested={true}>

							<VendorListGroupedContainer
								queryKey={'vendor-listing-page'}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403482}
											maxHeight={250}
											maxWidth={320}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),

									3: (
										<AppMainColBox boxed={true}>
											<NewsletterSignupComponent />
										</AppMainColBox>
									),

									5: (
										<AdItem
											mobileAdId={4403481}
											maxHeight={250}
											maxWidth={320}
											type="content"
											title="Mobile Rectangle 2"
										/>
									),
								}}
							/>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<ShopListWidgetContainer
								widgetKey={`vendor-listing-shops-nearby`}
								title={`Märkte in deiner Nähe`}
								orderBy="distance"
								orderDir="desc"
								limit={10}
								style="card"
							/>

							<AdItem
								desktopAdId={4403494}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<StaticWidgetContainer
								title="Beliebte Händler"
								data={FavouriteVendors}
								widgetKey="favourite-vendors-vendor-listing"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}
}

export default VendorListing;
