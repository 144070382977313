import * as React from 'react';
import Link from '../../../Base/components/Link/Link';
import * as styles from './ShoppingCenterGrouped.scss';
import ShopListGroupedByStateGroup from './ShoppingCenterGroup';
import { IShoppingCenterGroupedType } from '../../types/IShoppingCenterGroupedType';


export interface IShoppingCenterGroupedProps {
	shoppingCenterGroups: IShoppingCenterGroupedType[],
	gridKey?: string,

	enablePagination?: boolean;

	titleLabel?: string,
	titleLabelPlural?: string,
	moreLabel?: string,
	moreRouteCallbk?: (state: IShoppingCenterGroupedType) => { name: string, params: {[key: string]: any} },

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class ShoppingCenterGrouped extends React.PureComponent<IShoppingCenterGroupedProps, {}> {
	public static defaultProps = {
		gridKey: 'default',
		contentBreaks: {},
	};

	// TRANSLATE
	public render () {
		const { shoppingCenterGroups, gridKey, moreLabel, moreRouteCallbk, contentBreaks } = this.props;

		return (
			<React.Fragment>
				{shoppingCenterGroups.map((shoppingCenterGroup, indx) => {
					let route = {
						name: '',
						params: {},
					};

					if (moreRouteCallbk) {
						route = moreRouteCallbk(shoppingCenterGroup);
					}

					return (
						<React.Fragment key={`shopping-center-grid-group-${gridKey}-${shoppingCenterGroup.group && shoppingCenterGroup.group.slug}`}>

							{ (!contentBreaks || !contentBreaks[indx]) ? null :
								<React.Fragment>
									{contentBreaks[indx]}
								</React.Fragment>
							}

							<div className={styles.shoppingCenterListGroupedByStateWrapper}>
								<h2 className={styles.shoppingCenterListGroupedByStateTitle}>
									{this.getTitleLabel(shoppingCenterGroup)}
								</h2>

								<ShopListGroupedByStateGroup
									state={shoppingCenterGroup.group}
									shoppingCenters={shoppingCenterGroup.items}
								/>

								{( !moreLabel ) ? null :
								 <div className={`${styles.shoppingCenterListGroupedByStateMoreItem}`}>
									 <Link
										 route={route.name}
										 params={route.params}
									 >
										 <a className={styles.shoppingCenterListGroupedByStateMore}>
											 {this.getMoreLabel(shoppingCenterGroup)}
										 </a>
									 </Link>
								 </div>
								}
							</div>
						</React.Fragment>
					);
				})
				}
			</React.Fragment>
		);
	}

	/**
	 * Returns the title label with replaced placeholders
	 *
	 * @param state
	 */
	private getTitleLabel(state: IShoppingCenterGroupedType): string {
		const { titleLabel, titleLabelPlural } = this.props;

		if (!titleLabel) {
			return '';
		}

		const totalItemsCount = state.totalItemsCount;

		let ret = (totalItemsCount === 1) ? titleLabel +'': titleLabelPlural +'';

		// replace a few things
		ret = ret.replace('%state%', state.group.name);
		ret = ret.replace('%count%', totalItemsCount +'');
		ret = ret.replace('%city%', state.group.name || `${state.group.zipCode} ${state.group.city.name}`);

		return ret;
	}

	/**
	 * Returns the more label with replaced placeholders
	 *
	 * @param state
	 */
	private getMoreLabel(state: IShoppingCenterGroupedType): string {
		const { moreLabel,  } = this.props;

		if (!moreLabel) {
			return '';
		}

		let ret = moreLabel;

		// replace a few things
		ret = ret.replace('%state%', state.group.name);
		ret = ret.replace('%city%', state.group.name || state.group.city.name);

		return ret;
	}
}

export default ShoppingCenterGrouped;
