import { GENERAL_SET_DATA } from "./generalActions";

export interface IGeneralStateType {
	readonly cookieTrustGroups?: { [key: string]: boolean };
	readonly tcString?: string;
}

const defaultGeneralState = {
	cookieTrustGroups: {},
	tcString: '',
};

export function generalReducer(
	state = defaultGeneralState as IGeneralStateType,
	action: any,
) {
	switch (action.type) {
		case GENERAL_SET_DATA:
			return Object.assign({}, state, action.newData);

		default:
			return state;
	}
}
