import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import LeafletsAggregatedByStatesWidget
	, { ILeafletsAggregatedByStatesWidgetProps } from '../../components/LeafletsAggregatedByStatesWidget/LeafletsAggregatedByStatesWidget';
import { ILeafletsAggregatedByStateType } from '../../types/ILeafletsAggregatedByStateType';
import DataStateService from '../../../../services/DataStateService';

interface ILeafletsAggregatedByStatesWidgetContainerProps {
	title: string;
	widgetKey: string;

	limit?: number;
	placeholderLimit?: number;
	page?: number;

	routeCallbk: (item: ILeafletsAggregatedByStateType, parentProps: ILeafletsAggregatedByStatesWidgetProps) => { name: string, params: {[key: string]: any} },

	style?: IWidgetStyleType;
	orderBy?: 'leaflets'
	orderDir?: 'desc';

	excludeSlug?: SlugType,
	vendorSlug?: SlugType,
}

class LeafletsAggregatedByStatesWidgetContainerInner extends React.Component<ILeafletsAggregatedByStatesWidgetContainerProps & IWithDataProps> {
  public render () {
	const { data, widgetKey, title, routeCallbk } = this.props;
	
	if (data.length < 1) {
		return null;
	}

    return (
		<LeafletsAggregatedByStatesWidget
			title={title}
			leaflets={data}
			widgetKey={widgetKey}
			routeCallbk={routeCallbk}
		/>
    );
  }

	/**
	 * Cleans up our entries and makes sure the excluded entry is not in there
	 *
	 * @param {ILeafletsAggregatedByStateType} entries
	 *
	 * @return {ILeafletsAggregatedByStateType}
	 * TODO: remove if not needed anymore

	private getEntriesWithExcluded(entries: ILeafletsAggregatedByStateType[]): ILeafletsAggregatedByStateType[] {
		const { limit, excludeSlug } = this.props;

		// now try to filter it out
		return entries.filter((entry: ILeafletsAggregatedByStateType, indx: number) => {
			if (entry.group.slug === excludeSlug) {
				return false;
			}

			// if we are over our limit -> also return false (this might be because, we don't have the excludee in our data set,
			// but to make sure we have the correct amount of data even after removing the excludee, we have added +1 to limit on fetch
			if (limit && indx > limit) {
				return false;
			}

			// otherwise return true and add it to our results
			return true;
		});
	}*/
}

export default function LeafletsAggregatedByStatesWidgetContainer (props: ILeafletsAggregatedByStatesWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;

	const RenderComponent = withData(
		LeafletsAggregatedByStatesWidgetContainerInner,
		'leafletsAggregatedByStatesWidgetReducer',
		`leaflets-aggregated-by-states-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			page: props.page,
			size: (props.excludeSlug) ? (limit + 1) : limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,
			exclude: props.excludeSlug,
			store_vendor: props.vendorSlug,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
