import * as React from 'react';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';
import * as styles from './VendorListItem.scss';

class VendorListItemSkeleton extends React.Component<{}> {
	public render () {
		return (
            <div className={styles.vendorItemWrapper}>
                <a className={styles.vendorItemContainer}>

                    <span className={styles.vendorItemImageContainer}>
                        <ProductImagePlaceholder />
                    </span>

                    <span className={styles.vendorItemInfoContainer}>

                        <span className={styles.vendorItemTextContainer}>

                            <div className={styles.vendorItemTitle}>
	                            <SkeletonInline />
                            </div>

                        </span>
                    </span>
                </a>


	            <span className={styles.vendorItemIconContainer} />
            </div>
		);
	}
}

export default VendorListItemSkeleton;
