import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addShoppingCenterAggregatedByStatesWidgetEntries,
	SHOPPING_CENTER_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES, setShoppingCenterAggregatedByStatesWidgetErrors,
} from './shoppingCenterAggregatedByStatesWidgetActions';

/**
 * Fetch entry list
 */
function* fetchShoppingCenterAggregatedByStatesWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const { params } = action;
	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: '/api/public/v1/widgets/shopping-center-for-state',
					params,
				} as IRequestObject
			);
		});

		const theContent = response.getContent('content');

		yield put(addShoppingCenterAggregatedByStatesWidgetEntries(
			action.queryKey,
			theContent,
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setShoppingCenterAggregatedByStatesWidgetErrors(action.queryKey, e));
	}
}


export function* shoppingCenterAggregatedByStatesWidgetSaga() {
	yield takeEvery(SHOPPING_CENTER_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES, fetchShoppingCenterAggregatedByStatesWidget);
}
