import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import SymbolGraphic from '../../../Base/components/SymbolGraphic/SymbolGraphic';
import SearchInput from '../../components/SearchInput/SearchInput';
import MostUsedQueriesContainer from '../../containers/MostUsedQueries/MostUsedQueriesContainer';
import * as styles from './SearchPage.scss';
import SearchPageGraphic from './SearchPageGraphic.svg';

interface ISearchPageNoSearchResultsPropType {
    searchTerm: string;
}

class SearchPageNoSearchResults extends React.Component<ISearchPageNoSearchResultsPropType & IWithDataProps, {}> {
    public render() {
        const { searchTerm } = this.props;

        return (
            <PageWrapper titleAlign="left">
                <div className={styles.NoResultsErrorGraphic}>
                    <SymbolGraphic src={SearchPageGraphic} alt="Keine Suchergebnisse für diese Filter" />
                </div>

                <div className={styles.NoResultsWrapper}>
                    <h1>Leider keine Ergebnisse für "{searchTerm}" gefunden</h1>

                    <SearchInput label="Neue Suche starten" placeholder="Suche" style="light" autoFocus={true} />

                    <MostUsedQueriesContainer title="Beliebte Suchbegriffe" />
                </div>
            </PageWrapper>
        );
    }
}

/**
 * @param theProps
 *
 * @constructor
 */
export default SearchPageNoSearchResults;
