import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import PromotionworldGridGroup from '../../components/ProductPromotionGridGrouped/PromotionworldGridGroup';
import PromotionworldGridGroupSkeleton from '../../components/ProductPromotionGridGrouped/PromotionworldGridGroupSkeleton';
import { IPromotionworldGridGroupPromotionType } from '../../types/IPromotionworldGridGroupType';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import { IPromotionworldType } from '../../types/IPromotionworldType';

interface IPromotionworldGridGroupContainerProps {
	gridKey?: string;
	initialPromotions: IPromotionworldGridGroupPromotionType[];

	promotionworld: IPromotionworldType;
	groupSlug: string;
	withImages?: boolean;

	moreLabel?: string;
	limit?: string;
	totalItems?: number;
	onPromotionClick: (promotion: IProductPromotionType) => void;
}

class PromotionworldGridGroupContainerInner extends React.Component<IPromotionworldGridGroupContainerProps & IWithDataProps, {}> {
	public static defaultProps = {};

	public render () {
		const { data, ...restProps } = this.props;

		return (
			<PromotionworldGridGroup
				promotions={data}
				{...restProps}
			/>
		);
	}
}

export default function PromotionworldGridGroupContainer (props: IPromotionworldGridGroupContainerProps) {
	const RenderComponent = withData(
		PromotionworldGridGroupContainerInner,
		'promotionworldGroupedReducer',
		props.gridKey,
		{
			leafletSlug: props.promotionworld.leaflet.slug,
			groupSlug: props.groupSlug,
			size: props.limit,
			withImages: props.withImages ?? true,
		},
		{
			fetchOnInit: false,
			showLoading: false,
			LoadingComponent: () => <PromotionworldGridGroupSkeleton />
		}
	);

	return <RenderComponent {...props} />;
}
