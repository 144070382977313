import * as React from 'react';
import * as styles from './InputField.scss';

interface IInputFieldProps {
    title: string,
    name: string,
    placeholder?: string,
    type?: string,
    onChange: (name: string, value: string) => void;
    value: string,
    required: boolean,
    disabled: boolean,
    autoComplete: string,
}

class InputField extends React.PureComponent<IInputFieldProps, {}> {

    public static defaultProps = {
        placeholder: "",
        type: "text",
        required: false,
        disabled: false,
        autoComplete: 'true',
    };

	public render () {
		const { title, name, placeholder, type, value, required, disabled, autoComplete } = this.props;

		return (
			<label className={styles.inputFieldWrapper}>
                <span className={styles.inputFieldTitle}>
                    {title}
                </span>

                <input 
                    className={styles.inputField}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    onChange={this.onChange}
                    value={value}
                    required={required}
                    disabled={disabled}
                    autoComplete={autoComplete}
                />

            </label>
		);
    }
    
    /**
	 * Handles onchange events
	 * @param e
	 */
	private onChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { onChange } = this.props;

		if (!onChange) {
			return;
		}

		onChange(e.currentTarget.name, this.trim(e.currentTarget.value));
    };
    
    /**
     * Trims input value
     * @param value 
     */
    private trim(value: string) {

        if (value.length === 1) {
            return value.replace(/^\s+|\s+$/g,"");
        }

        return value;
    }
}

export default InputField;
