import { DateTime } from 'luxon';
import Cookies from 'universal-cookie';

declare const process: any;

const CookieService = {
    /**
     * current cookies
     */
    cookies: { __nodata: true } as any,

    /**
     * current cookies
     */
    cookieLib: null as any,

    /**
     * Fetch cookies and set context
     * @param ssrReqCookieHeader
     */
    async init(ssrReqCookieHeader?: any) {
        this.cookieLib = new Cookies(ssrReqCookieHeader);
        this.cookies = this.cookieLib.getAll();
    },

    /**
     * Returns the cookie but refreshes the cookie service
     *
     * @param key
     * @param defaultValue
     *
     * @return {any}
     */
    getWithoutCache(key: string | null = null, defaultValue: any = null): any {
        this.init();
        return this.get(key, defaultValue);
    },

    /**
     * Returns the cookie we fetched
     *
     * @param key
     * @param defaultValue
     *
     * @return {any}
     */
    get(key: string | null = null, defaultValue: any = null): any {
        if (!this.cookies || this.cookies.__nodata) {
            this.init();
        }

        if (!key) {
            return this.cookies;
        }

        if (typeof this.cookies[key] === 'undefined') {
            return defaultValue;
        }

        return this.cookies[key];
    },

    /**
     * Set cookies
     *
     * @param key
     * @param value
     * @param expiresInMinutes
     */
    set(key: string, value: string, expiresInMinutes: number = 60) {
        const cookieOptions = this.getCookieOptions();
        cookieOptions.expires = DateTime.local()
            .plus({ minutes: expiresInMinutes })
            .toJSDate();

        this.cookieLib.set(key, value, cookieOptions);
        this.init();
    },

    /**
     * Delete a cookie
     *
     * @param key
     */
    delete(key: string) {
        this.cookieLib.remove(key, this.getCookieOptions());
        this.init();
    },

    /**
     * Delete a cookies array
     *
     * @param keys
     */
    deleteArr(keys: string[]) {
        for (let index = 0; index < keys.length; index++) {
            this.cookieLib.remove(keys[index], this.getCookieOptions());
        }
        this.init();
    },

    /**
     * Returns the cookie options we are going to use everywhere
     */
    getCookieOptions() {
        return {
            path: '/',
            secure: process.env.NODE_ENV === 'production',
            sameSite: 'Strict',
        };
    },
};

export default CookieService;
