import { logger } from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import rootReducer from '../../App/reducers';
import { isBrowser, isServer } from '../razzle/razzleUtils';
import { createBrowserHistory, createMemoryHistory } from 'history'
import * as createHistory from '../razzle/history';

declare const window: any;

const composeEnhancers =
	      process.env.NODE_ENV !== 'production' &&
	      typeof window !== 'undefined' &&
	      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

// export const history = (isServer()) ? { } as any : createBrowserHistory();
export const history = process.env.BUILD_TARGET === 'client' ?
                       createBrowserHistory() :
                       createMemoryHistory();

export const configureStore = (preloadedState: any) => {

	const sagaMiddleware   = createSagaMiddleware();
	const routerMiddleware = createRouterMiddleware(history);

	const middlewares = [ sagaMiddleware, routerMiddleware ];

	const store = createStore(
		rootReducer(history),
		preloadedState,
		composeEnhancers(
			applyMiddleware(...middlewares),
		),
	);

	store.runSaga = sagaMiddleware.run;
	store.close = () => store.dispatch(END);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../../App/reducers', () => {
			const nextRootReducer = require('../../App/reducers').default;
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
};
