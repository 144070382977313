import * as React from 'react';
import * as styles from './NearestShop.scss';


class NearestShopSkeleton extends React.Component<{}, {}> {

	public render () {
		return (
			<span className={styles.shopListItemWrapper}>
				Nächster Markt wird geladen...
			</span>
		);
	}
}

export default NearestShopSkeleton;
