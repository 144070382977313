import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './Checkbox.scss';

interface ICheckboxProps {
    children?: ReactChild | JSX.Element[] | string[];
    onChange: (name: string, value: boolean) => void;
    name: string;
    className?: string;
    style?: 'default' | 'contrast' | 'v3';
    checked: boolean,
    type: 'radio' | 'checkbox',
}

class Checkbox extends React.PureComponent<ICheckboxProps, {}> {
    public static defaultProps = {
        style: 'default',
        type: 'checkbox',
    }

    public render() {
        const { children, name, checked, style, type, className: customClassName } = this.props;
        const className = `${styles.checkboxBlock} ${styles[style + 'StyleCheckbox']} ${customClassName} ${(type === 'radio') ? styles.radiobuttonType : styles.checkboxType}`;

        return (
            <div className={className}>
                <input
                    type="checkbox"
                    name={name}
                    id={name}
                    onChange={this.onChange}
                    checked={checked}
                />

                <label htmlFor={name}>
                    <i></i>
                    <span>
                        {children}
                    </span>
                </label>
            </div>
        );
    }

    /**
     * Handles onchange events
     * @param e
     */
    private onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        onChange(e.currentTarget.name, e.currentTarget.checked);
    };
}

export default Checkbox;
