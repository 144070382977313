import * as React from 'react';
import { connect } from 'react-redux';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import ShoppingNote from '../../components/ShoppingNote/ShoppingNote';
import {
	getShoppingNote,
	saveShoppingNote,
	setShoppingNoteSavingState,
} from '../../store/ShoppingNote/shoppingNoteActions';
import { IShoppingNoteStateType } from '../../store/ShoppingNote/shoppingNoteReducer';

interface IShoppingNoteContainerProps {
	shoppingNoteReducer?: IShoppingNoteStateType,
	onSaveShoppingNote?: (note: string) => void;
	onFetchShoppingNote?: () => void;
}

class ShoppingNoteContainer extends React.Component<IShoppingNoteContainerProps & IWithDataProps> {
	public componentDidMount (): void {
		const { onFetchShoppingNote } = this.props;

		if (onFetchShoppingNote) {
			onFetchShoppingNote();
		}
	}

	public render() {
		const { shoppingNoteReducer, onSaveShoppingNote, onFetchShoppingNote, onSetShoppingNoteSavingState } = this.props;

		return (
			<ShoppingNote
				{...shoppingNoteReducer}
				onSaveShoppingNote={onSaveShoppingNote}
				onFetchShoppingNote={onFetchShoppingNote}
				onSetShoppingNoteSavingState={onSetShoppingNoteSavingState}
			/>
		);
	}
}


function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		onSaveShoppingNote: (note: string) => {
			dispatch(saveShoppingNote(note));
		},
		onFetchShoppingNote: () => {
			dispatch(getShoppingNote());
		},
		onSetShoppingNoteSavingState: (state: "saved" | "unsaved" | "saving" | "error") => {
			dispatch(setShoppingNoteSavingState(state));
		},
	};
}

function mapStateToProps (state: any) {
	return {
		shoppingNoteReducer: state.shoppingNoteReducer,
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(ShoppingNoteContainer);
