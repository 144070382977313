import * as React from 'react';
import { ReactNode, TouchEvent, MouseEvent } from 'react';
import { ILeafletViewerUIState } from '../../../Leaflet/store/LeafletViewer/leafletViewerReducer';
import Icon from '../Icon/Icon';
import * as styles from './Swiper.scss';

interface ISwiperProps {
	slides: ReactNode[];
	disabled?: boolean;
	onSlideChange?: (currentSlideIndex: number) => void,
	page2SlideMap: { [key: number]: number },
	updateUrl: (slideIndex: number) => void;
	slideStates: ILeafletViewerUIState[],
}

interface ISwiperState {
	currentSlide: number;
	dragStart: number;
	dragEnd: number;
	isDragging: boolean;
	isWheeling: boolean;
}

class Swiper extends React.PureComponent<ISwiperProps, ISwiperState> {
	private wheelTimeout: NodeJS.Timer;

	public state = {
		currentSlide: 0,
		dragStart: 0,
		dragEnd: 0,
		isDragging: false,
		isWheeling: false,
	};

	public componentDidMount() {
		document.addEventListener('keyup', this.handleKeyUp);
		window.addEventListener('wheel', this.handleWheel);
		window.addEventListener('hashchange', this.goToHashSlide);

		this.goToHashSlide();
	}

	public componentWillUnmount() {
		clearTimeout(this.wheelTimeout);
		window.removeEventListener('wheel', this.handleWheel);
		document.removeEventListener('keyup', this.handleKeyUp);
		window.removeEventListener('hashchange', this.goToHashSlide);
	}

	public render() {
		const { slides } = this.props;
		const { currentSlide, dragStart, dragEnd, isDragging } = this.state;

		const currentSlideOffset = this.getScrollOffset(currentSlide);
		const currentDragOffset = dragStart - dragEnd;
		const currentOffset = currentSlideOffset + currentDragOffset;

		const innerWrapperClassNames = [styles.InnerWrapper];
		if (isDragging && dragEnd - dragStart !== 0) {
			innerWrapperClassNames.push(styles.dragging);
		}

		return (
			<div
				className={styles.OuterWrapper}
				id="swiper-outer"
			>
				<div
					className={innerWrapperClassNames.join(' ')}
					style={{ transform: `translateX(-${currentOffset}px)` }}
					onTouchStart={this.handleTouchStart}
					onTouchMove={this.handleTouchMove}
					onTouchEnd={this.handleTouchEnd}
					onMouseDown={this.handleMouseDown}
					onMouseMove={this.handleMouseMove}
					onMouseUp={this.handleMouseUp}
					onMouseLeave={this.handleMouseLeave}
				>
					{
						slides.map((page: any, indx: number) => {
							return (
								<div className={styles.Item} key={`slide-${indx}`}>
									{page}
								</div>
							);
						})
					}
				</div>

				{(currentSlide <= 0) ? null :
					<button type="button" className={styles.NavArrowsPrev} onClick={this.goToPrev}>
						<Icon icon="arrow-left" />
					</button>
				}

				{(currentSlide >= (slides.length - 1)) ? null :
					<button type="button" className={styles.NavArrowsNext} onClick={this.goToNext}>
						<Icon icon="arrow-right" />
					</button>
				}

				<div className={styles.leafletMobilePagesCounterWrapper}>
					<button
						type="button"
						className={styles.leafletMobileNavArrow}
						onClick={this.goToPrev}
						disabled={currentSlide <= 0}
					>
						<Icon icon="arrow-left" />
					</button>
					<span className={styles.leafletMobilePagesCounter}>{`${Number(currentSlide) + 1} / ${slides.length}`}</span>
					<button
						type="button"
						className={styles.leafletMobileNavArrow}
						onClick={this.goToNext}
						disabled={currentSlide >= (slides.length - 1)}
					>
						<Icon icon="arrow-right" />
					</button>
				</div>
			</div>
		);
	}

	/**
	 * Navigates to next page
	 */
	public goToNext = () => {
		this.goToSlide(this.state.currentSlide + 1);
	};

	/**
	 * Navigates to prev page
	 */
	public goToPrev = () => {
		this.goToSlide(this.state.currentSlide - 1);
	};

	/**
	 * Navigate to a specific slide
	 *
	 * @param slideIndex
	 */
	public goToSlide(slideIndex: number) {
		const { slides, onSlideChange, updateUrl } = this.props;

		// make sure we are not going out of range
		const currentSlide = Math.max(Math.min(slideIndex, slides.length - 1), 0);

		this.setState({
			currentSlide,
		}, () => {
			if (onSlideChange) {
				onSlideChange(slideIndex);
			}
		});
	}

	/**
	 * Goes to the slide index specified in the URL's hash
	 */
	private goToHashSlide = () => {
		const pageNumber = parseInt(location.hash.substr(1), 10);

		if (isNaN(pageNumber)) {
			return;
		}

		this.goToSlide(
			this.convertPage2SlideIndex(pageNumber - 1)
		);
	}

	/**
	 * Takes the pagenumber and returns the correct slide index
	 * @param pageNumber
	 */
	private convertPage2SlideIndex(pageNumber: number) {
		const { page2SlideMap } = this.props;

		if (typeof page2SlideMap[pageNumber] === 'undefined') {
			return 0;
		}

		return page2SlideMap[pageNumber];
	}

	/**
	 * Calculates the offset that is used to display the current slide
	 *
	 * @param slideIndex
	 */
	public getScrollOffset(slideIndex: number): number {
		const swiperOuter = document.getElementById('swiper-outer');

		if (!swiperOuter) {
			return 0;
		}

		const swiperWidth = swiperOuter.offsetWidth;

		return (swiperWidth * slideIndex);
	}

	/**
	 * Navigates to the previous page when the left arrow key is pressed and to the next page for the right arrow key
	 *
	 * @param event
	 */
	private handleKeyUp = ({ key }: KeyboardEvent) => {
		switch (key) {
			case 'ArrowLeft':
				this.goToPrev();
				break;
			case 'ArrowRight':
				this.goToNext();
		}
	}

	/**
	 * What distance of the page needs to be swiped to trigger a page change
	 */
	private pageChangeThreshold = () => {
		return window.innerWidth * 0.10;
	}

	/**
	 * Navigates to the previous page when the mouse scrolls to the left and to the next page when the mouse scrolls to the right
	 *
	 * @param event
	 */
	public handleWheel = (event: WheelEvent) => {
		const { deltaX, deltaY } = event;

		if (Math.abs(deltaY) >= Math.abs(deltaX) || this.props.disabled) {
			return;
		}

		event.preventDefault();

		const { dragEnd: oldDragEnd } = this.state;
		const dragEnd = oldDragEnd - deltaX;

		this.setState({ isWheeling: true, dragStart: 0, dragEnd });

		clearTimeout(this.wheelTimeout);

		// Second condition makes sure that when the scroll movement fades out, the page transition is already performed.
		if (Math.abs(dragEnd) > window.innerWidth || Math.abs(deltaX) < 20) {
			this.endDragging(dragEnd);
		} else {
			this.wheelTimeout = setTimeout(() => this.endDragging(dragEnd), 50);
		}
	}

	/**
	 * Handles mouse event to start dragging
	 *
	 * @param event
	 */
	private handleMouseDown = ({ clientX }: MouseEvent) => this.startDragging(clientX);

	/**
	 * Handles touch event to start dragging
	 *
	 * @param event
	 */
	private handleTouchStart = ({ targetTouches }: TouchEvent) => {
		if (targetTouches.length === 1) {
			this.startDragging(targetTouches[0].clientX)
		} else {
			this.resetDragging();
		}
	}

	/**
	 * Starts tracking a horizontal dragging gesture
	 *
	 * @param dragStart
	 */
	private startDragging = (dragStart: number) => {
		if (this.props.disabled) {
			this.resetDragging();
			return;
		}

		this.setState({ isDragging: true, dragStart, dragEnd: dragStart });
	}

	/**
	 * Handles mouse movement event to track dragging
	 *
	 * @param event
	 */
	private handleMouseMove = ({ clientX }: MouseEvent) => this.trackDragging(clientX);

	/**
	 * Handles touch movement event to track dragging
	 *
	 * @param event
	 */
	private handleTouchMove = ({ changedTouches }: TouchEvent) => {
		if (changedTouches.length === 1) {
			this.trackDragging(changedTouches[0].clientX);
		} else {
			this.resetDragging();
		}
	}

	/**
	 * Updates the dragEnd position to the current pointer position, so the page can follow the motion.
	 *
	 * @param dragEnd
	 */
	private trackDragging = (dragEnd: number) => {
		if (this.props.disabled || !this.state.isDragging) {
			return;
		}

		this.setState({ dragEnd });
	}

	/**
	 * Handles mouse event to end dragging
	 *
	 * @param event
	 */
	private handleMouseUp = (event: MouseEvent) => {
		const wasSwipe = this.endDragging(event.clientX);

		if (wasSwipe) {
			// Don't trigger a link or button accidentally
			event.preventDefault();
		}
	}

	/**
	 * Handles mouse event when the mouse is moved outside of the Swiper area to end dragging
	 *
	 * @param event
	 */
	private handleMouseLeave = ({ clientX }: MouseEvent) => this.endDragging(clientX);

	/**
	 * Handles touch event to end dragging
	 *
	 * @param event
	 */
	private handleTouchEnd = (event: TouchEvent) => {
		const wasSwipe = this.endDragging(event.changedTouches[0].clientX);

		if (wasSwipe) {
			// Don't trigger a link or button accidentally
			event.preventDefault();
		}
	};

	/**
	 * Moves to the next or previous slide if the drag trip spanned more than a certain threshold.
	 *
	 * @param dragEnd
	 */
	private endDragging = (dragEnd: number) => {
		const { dragStart, isDragging, isWheeling } = this.state;

		if (this.props.disabled || !(isDragging || isWheeling)) {
			return false;
		}

		this.resetDragging();

		const dragTrip = dragStart - dragEnd;
		const dragDistance = Math.abs(dragTrip);


		if (dragDistance < this.pageChangeThreshold()) {
			return false;
		}

		dragTrip > 0 ? this.goToNext() : this.goToPrev();
		return true;
	}

	/**
	 * Resets dragging state to the initial value
	 */
	private resetDragging = () => this.setState({ dragStart: 0, dragEnd: 0, isDragging: false, isWheeling: false });
}

export default Swiper;
