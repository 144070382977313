import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import ShoppingCenterDetailContainer from '../App/modules/ShoppingCenter/container/ShoppingCenterDetailContainer/ShoppingCenterDetailContainer';
import {
	pluralizerPromotionsAndSpecials,
	withTitlePostfix,
	withClickbait,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class ShoppingCenterDetailRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await ShoppingCenterDetailRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403494]}
					mobileIds={[4403482, 4403481]}
					superbannerId={4403495}
					sitebarId={4403493}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<ShoppingCenterDetailContainer
							queryKey={`shoppingCenterDetail-${query.shoppingCenter}`}
							shoppingCenterSlug={`${query.shoppingCenter}`}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403493}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		ShoppingCenterDetailRoute,
		(props: any) => `sc-${props.match.params.shoppingCenter}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => 
				`${routeMetadata.shoppingCenter.title} - ${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')}  ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.shoppingCenter.title} » ${routeMetadata.totalItemsCount || ''} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`Aktuelle Aktionen & Angebote von ${routeMetadata.shoppingCenter.title} entdecken » Schnäppchen im Überblick ✓ Rabatt sichern ✓ online informieren`, `✓ Jetzt entdecken!`),
		} as IRouteMetadataSetterType,
	),
);

