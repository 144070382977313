import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addProductGroupWidgetEntries,
	setProductGroupWidgetErrors,
	VENDOR_WIDGET_FETCH_ENTRIES,
} from './productGroupWidgetActions';

/**
 * Fetch entry list
 */
function* fetchProductGroupWidgets(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = afCoreRequest.mergeParams({
		page: 0,
		size: 20,
	}, action.params);

	try {
		// TODO: put this back as soon as we have an endpoint to fetch without ference id
		// const content = response.get('content');
		const slug = (!params.parentSlug && !params.referenceSlug) ?  '' :  params.parentSlug;

		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: `/api/public/v1/product-groups/${slug}`,
					params,
				} as IRequestObject);
			},
		);

		const content = response.getContent();

		yield put(
			addProductGroupWidgetEntries(
				action.queryKey,
				content,
				response.getMetaData(),
			),
		);
	} catch (e) {
		yield put(setProductGroupWidgetErrors(action.queryKey, e));
	}
}

export function* productGroupWidgetSaga() {
	yield takeEvery(VENDOR_WIDGET_FETCH_ENTRIES, fetchProductGroupWidgets);
}
