import { DateTime } from 'luxon';
import BaseReducer from '../../../../boilerplate/redux/BaseReducer';

export default class LeafletBaseReducer extends BaseReducer {
	/**
	 * This method is used to transform entry data before putting it into the store
	 * @param entry
	 */
	transformEntry(entry: any) {
		return Object.assign(entry, {
			validFrom: DateTime.fromISO(entry.validFrom),
			validTo: DateTime.fromISO(entry.validTo),
			visibleFrom: DateTime.fromISO(entry.visibleFrom),
		});
	}
}

// TODO: make sure the leaflet viewer works as expected
// right now there is aproblem with saga not being called.
