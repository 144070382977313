import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsFromBrandAndCity
	from '../App/modules/Promotion/components/PromotionsFromBrandAndCity/PromotionsFromBrandAndCity';
import {
	pluralizerPromotionsAndSpecials,
	withTitlePostfix,
	withClickbait,
	maxDiscountInTitle,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class PromotionsFromBrandAndCityRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await PromotionsFromBrandAndCityRouteProps(state);

		return { reduxData };
	}

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<PromotionsFromBrandAndCity
							brandSlug={query.brand + ''}
							citySlug={query.city + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PromotionsFromBrandAndCityRoute,
		(props: any) => `pbc-${props.match.params.brand}-${props.match.params.city}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => 
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} in ${routeMetadata.city.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} in ${routeMetadata.city.title} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`Jetzt alle ${routeMetadata.brand.title} Angebote & Aktionen in ${routeMetadata.city.title} finden » Preise gegenüberstellen ✓ bares Geld sparen`, `✓ Den besten Deal schnappen!`),
		} as IRouteMetadataSetterType
	),
);
