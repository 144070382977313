import * as React from 'react';
import { IProductPromotionWithShopType } from '../../types/IProductPromotionWithShopType';
import * as styles from './ProductPromotionInOtherShops.scss';
import ProductPromotionInOtherShopsItem from './ProductPromotionInOtherShopsItem';

interface IProductPromotionInOtherShopsProps {
	title?: string,
	promotions: IProductPromotionWithShopType[],
	columns?: '1' | '2' | '3',
}

class ProductPromotionInOtherShops extends React.Component<IProductPromotionInOtherShopsProps, {}> {
	public static defaultProps = {
		columns: 2,
	};

	public render () {
		const { promotions, columns, title } = this.props;

		return (
			<div className={`${styles.Wrapper} ${styles[ 'Columns' + columns ]}`}>
				{ (!title) ? null :
				  <h2 className={styles.Title}>
					  {title}
				  </h2>
				}

				<div className={`${styles.InnerWrapper}`}>
					{promotions.map((promotionWithShop, index) => (
							<ProductPromotionInOtherShopsItem
								promotionWithShop={promotionWithShop}
								key={`product-with-shop-item-${promotionWithShop.promotion.slug}-${index}`}
							/>
						),
					)}
				</div>
			</div>
		);
	}
}

export default ProductPromotionInOtherShops;

/*
<div className={styles.Item} >
	<div className={styles.ItemInner}>

	</div>
</div>*/
