import * as React from 'react';
import * as styles from './LeafletAdPage.scss';
import AdItem from '../../../../components/General/AdItem/AdItem';
import { connect } from 'react-redux';
import { compose } from 'redux';
import LoadingIndicator from '../../../../components/Layout/LoadingIndicator/LoadingIndicator';
import Button from '../../../../components/General/Button/Button';
import { goToLeafletViewerSlide } from '../../store/LeafletViewer/leafletViewerActions';

interface ILeafletAdPagePropsType {
	leafletViewerUiState?: any;
	slideIndx: number;
	goToSlide: (newSlideIndx: number)=> void;
}

class LeafletAdPage extends React.Component<ILeafletAdPagePropsType, {}> {
	public render() {

		if (!this.isInLoadingRange()) {
			return (
				<div className={styles.AdPageWrapper}>
					<LoadingIndicator />
				</div>
			);
		}

		return (
			<div className={styles.AdPageWrapper}>
				<div>
					<AdItem
						mobileAdId={4403484}
						maxHeight={600}
						maxWidth={350}
						type="none"
					/>

					<Button
						theme="primary"
						size="xl"
						fullWidth={true}
						onClick={this.onSkipAd}
						customClassName={styles.AdPageWrapperButton}
					>
						Weiter im Prospekt
					</Button>
				</div>
			</div>
		);
  }

	/**
	 * Checks whether this ad page is currently in range of users viewport.
	 * If not, it will be hidden for performance reasons
	 */
	private isInLoadingRange() {
		const { leafletViewerUiState, slideIndx } = this.props;
		const leafletViewerUiStateSec = ( leafletViewerUiState ) ? leafletViewerUiState : { slideStates: {}, page2SlideMap: [] };

		const SLIDES_IN_RANGE_FOR_PRELOADING = 3;

		const theRealSlideIndx = parseInt(leafletViewerUiStateSec.page2SlideMap[slideIndx], 10);

		// if is placeholder -> use only a simple version of the page to improve performance
		const currentSlide = leafletViewerUiStateSec.currentSlide + 1;
		return (currentSlide >= (theRealSlideIndx - SLIDES_IN_RANGE_FOR_PRELOADING)) && (currentSlide <= (theRealSlideIndx + SLIDES_IN_RANGE_FOR_PRELOADING));
	}

	/**
	 * Skips an ad and goes to next slider
	 */
	private onSkipAd = () => {
		const { goToSlide, leafletViewerUiState } = this.props;

		const nextSlider = (leafletViewerUiState.naviDirection === 'backwards') ?
		             leafletViewerUiState.currentSlide - 1
		             :
		             leafletViewerUiState.currentSlide + 1;

		goToSlide(nextSlider)
	}

}

function mapStateToProps (state: any) {
	return {
		leafletViewerUiState: state.leafletViewer,
	};
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
		goToSlide: (newSlideIndx: number) => {
			dispatch(goToLeafletViewerSlide(newSlideIndx));
		},
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps
);

export default compose(withConnect)(LeafletAdPage);
