import * as React from 'react';
import Icon from '../../../Base/components/Icon/Icon';
import * as styles from './Distance.scss';


interface IDistanceProps {
	distanceInMeters: number,
	hideIcon?: boolean,
}


class Distance extends React.PureComponent<IDistanceProps, {}> {
	public render () {
        const { distanceInMeters, hideIcon } = this.props;

        if (typeof distanceInMeters === 'undefined' || distanceInMeters === null) {
        	return null;
        }

        return (
        	<span className={styles.DistanceWrapper}>
						{( hideIcon ) ? null :
							<Icon icon="map-marker" className={styles.DistanceIcon}/>
						}
						&nbsp;
		        {this.getHumanReadable()}
	        </span>
        );
    }

	/**
	 * Make sure to use the perfect unit to make it look good
	 */
	getHumanReadable() {
		const { distanceInMeters } = this.props;

		if (distanceInMeters < 800) {
			return `${this.roundDistance(distanceInMeters)} m`;
		}

		return `${this.roundDistance(distanceInMeters / 1000)} km`;
    }

	/**
	 * Make sure to have a nicely rounded value
	 *
	 * @param distance
	 */
	private roundDistance(distance: number): number {
	    if (distance < 3) {
		    return (Math.round((distance * 10)) / 10);
	    }

	    return Math.round(distance);
    }
}

export default Distance;
