/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IShopType } from 'App/modules/Vendor/types/IShopType';

export const SEARCH_SHOP_FETCH_ENTRIES = '@BasePipeline/searchShopReducer_FETCH_ENTRIES';
export const SEARCH_SHOP_FETCH_ENTRY   = '@BasePipeline/searchShopReducer_FETCH_ENTRY';
export const SEARCH_SHOP_ADD_ENTRIES = '@BasePipeline/searchShopReducer_ADD_ENTRIES';
export const SEARCH_SHOP_ADD_ENTRY = '@BasePipeline/searchShopReducer_ADD_ENTRY';

export const SEARCH_SHOP_SET_ERRORS   = '@BasePipeline/searchShopReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchsearchShops (queryKey: string) {
	return {
		type: SEARCH_SHOP_FETCH_ENTRIES,
		queryKey,
	};
}

export function addsearchShopEntries (queryKey: string, entries: IShopType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
	return {
		type: SEARCH_SHOP_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options,
	};
}

export function setsearchShopErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SEARCH_SHOP_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
