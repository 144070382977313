const ReduxService = {
	/**
	 * Contains the dispatch function in a cached state, so we can use it everywhere without having to connect to redux
	 */
	reduxDispatcherFunction: null,

	/**
	 * Sets the dispatcher function, so we can use it later
	 * @param dispatcher
	 */
	initDispatcher (dispatcher: any) {
		this.reduxDispatcherFunction = dispatcher;
	},

	/**
	 * Makes calling dispatch from anywhere in the application possible without having to connect to redux via hoc
	 *
	 * @param action
	 */
	dispatch (action: any) {
		if (!this.reduxDispatcherFunction) {
			console.error('Redux Service is not initalized');
			return;
		}

		this.reduxDispatcherFunction(action);
	},
};

export default ReduxService;
