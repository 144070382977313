import { IQueryMetaDataType } from "../../../../../boilerplate/redux/types/IQueryMetaDataType";
import { IApiErrorType } from "../../../../types/BaseTypes";

/* ### CONSTANTS ### */
export const CONFIRM_NEWSLETTERS_FETCH_ENTRIES = '@BasePipeline/confirmNewslettersReducer_FETCH_ENTRIES';
export const CONFIRM_NEWSLETTERS_ADD_ENTRIES = '@BasePipeline/confirmNewslettersReducer_ADD_ENTRIES';
export const CONFIRM_NEWSLETTERS_ADD_ENTRY = '@BasePipeline/confirmNewslettersReducer_ADD_ENTRY';

export const CONFIRM_NEWSLETTERS_SET_ERRORS   = '@BasePipeline/confirmNewslettersReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function addConfirmNewslettersEntries (queryKey: string, entries: [], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: CONFIRM_NEWSLETTERS_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setConfirmNewslettersErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: CONFIRM_NEWSLETTERS_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
