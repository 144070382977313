import * as React from 'react';
import { IShopType } from '../../types/IShopType';

class ShopLocationMapPin extends React.Component<any, {}> {
	public static defaultProps = {
	};

	public render () {
		const { shop } = this.props;

		return (
			<div
				style={{ background: 'red', borderRadius: '50%', width: 20, height: 20 }}
			/>
		);
	}
}

export default ShopLocationMapPin;
