import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addTagEntries, addTagEntry, setTagErrors, TAG_FETCH_ENTRY,
} from './tagActions';
import { TAG_FETCH_ENTRIES } from './tagActions';

/**
 * Fetch entry list
 */
function* fetchTags(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/widgets/tags',
				params: action.params,
			} as IRequestObject);
		});

		yield put(addTagEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setTagErrors(action.queryKey, e));
	}
}


/**
 * Fetch entry details
 */
function* fetchTag(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: `/api/public/v1/tags/${action.params.tagSlug}`,
				} as IRequestObject
			);
		});

		yield put(addTagEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setTagErrors(action.queryKey, e));
	}
}

export function* tagSaga() {
	yield takeEvery(TAG_FETCH_ENTRY, fetchTag);
	yield takeEvery(TAG_FETCH_ENTRIES, fetchTags);
}
