import * as React from 'react';
import * as styles from './InfopostFurtherLeafletsPage.scss';

interface IInfopostFurtherLeafletsPagePropsType {}

class InfopostFurtherLeafletsPage extends React.Component<IInfopostFurtherLeafletsPagePropsType, {}> {
	public render() {
		return (
			<div className={styles.InfopostFurtherLeafletsPageWrapper}>
				<div className={styles.InfopostFurtherLeafletsPageContent}>
					<a href="https://1aktionsfinder.qr1.at/9osucw" target="_blank" className={styles.InfopostFurtherLeafletsImage}>
						<img src={require('./LetzteProspektseite_A4.jpg')} alt="Weitere Prospekte" />
					</a>
				</div>
			</div>
		);
	}
}

export default InfopostFurtherLeafletsPage;