import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import { IShoppingCenterType } from '../../types/IShoppingCenterType';
import ShoppingCenterDetail from '../../components/ShoppingCenterDetail/ShoppingCenterDetail';


interface IShoppingCenterDetailProps {
	shoppingCenterSlug: string;
	queryKey: string;
}

class ShoppingCenterDetailContainerInner extends React.Component<IShoppingCenterDetailProps & IWithDataProps & IWithRouteMetadataType> {
	public render() {
		const { data, ...restProps } = this.props;

		return (
			<ShoppingCenterDetail
				data={data as IShoppingCenterType}
				{...restProps}
			/>
		);
	}
}

export default function ShoppingCenterDetailContainer(props: IShoppingCenterDetailProps) {
	const RenderComponent = withData(
		ShoppingCenterDetailContainerInner,
		'shoppingCenterReducer',
		`shoppingCenter-detail-${props.shoppingCenterSlug}`,
		{
			shoppingCenterSlug: props.shoppingCenterSlug,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'shoppingCenterSlug',
		}
	);

	return <RenderComponent {...props} />
}

