import { SlugType } from '../../Base/types/SlugType';
import { fetchBookmarks, resetBookmarks } from '../store/Bookmark/bookmarkActions';
import { IBookmarkEntryType } from '../store/Bookmark/bookmarkReducer';
import CookieService from '../../../services/CookieService';
import ReduxService from '../../../../boilerplate/redux/services/ReduxService';
import { BookmarkEntityType } from '../types/BookmarkEntityType';

const BookmarkService = {
	/**
	 * Contains the bookmarks grouped by entity type
	 */
	bookmarks: {
		vendor: {},
		promotion: {},
	} as IBookmarkEntryType,

	/**
	 * Initiates the user's bookmarks
	 */
	initBookmarks() {
		if (!CookieService.get('at')) {
			return;
		}

		ReduxService.dispatch(fetchBookmarks());
	},

	/**
	 * Sets the local bookmark value
	 * @param bookmarks
	 */
	syncBookmarks (bookmarks: IBookmarkEntryType) {
		// set it locally
		this.bookmarks = bookmarks;
	},

	/**
	 * Checks if a certain entity is bookmarked
	 *
	 * @param entity
	 * @param slug
	 */
	isMarked (entity: BookmarkEntityType, slug: SlugType) {
		return (this.bookmarks && this.bookmarks[entity] && this.bookmarks[entity][slug]);
	},
};

export default BookmarkService;
