import { refetchPromotionBookmarks, rerenderPromotionBookmarks } from '../modules/Bookmark/store/PromotionBookmark/promotionBookmarkActions';
import { refetchVendorBookmarks, rerenderVendorBookmarks } from '../modules/Bookmark/store/VendorBookmark/vendorBookmarkActions';

const bookmarkConfig = {
    entities: {
        vendor: {
            syncKey: 'favoriteVendors',
            apiUrls: {
                add: (slug: string) => `/api/public/v1/personal-list/vendor/${slug}`,
                remove: (slug: string) => `/api/public/v1/personal-list/vendor/${slug}`,
            },
            icons: {
                active: 'favorite-filled',
                inactive: 'favorite',
            },
            labels: {
                add: 'Zu Merkliste hinzufügen',
                remove: 'Von Merkliste entfernen',
            },
            route: 'VendorBookmarkListingRoute',
            rerenderAction: rerenderVendorBookmarks,
            refetchAction: refetchVendorBookmarks,
        },
        promotion: {
            syncKey: 'favoritePromotions',
            apiUrls: {
                add: (slug: string) => `/api/public/v1/personal-list/promotion/${slug}`,
                remove: (slug: string) => `/api/public/v1/personal-list/promotion/${slug}`,
            },
            icons: {
                active: 'shoppinglist-filled',
                inactive: 'shoppinglist-add',
            },
            labels: {
                add: 'Zu Einkaufsliste hinzufügen',
                remove: 'Von Einkaufliste entfernen',
            },
            route: 'PromotionBookmarkListingRoute',
            rerenderAction: rerenderPromotionBookmarks,
            refetchAction: refetchPromotionBookmarks,
        },
    },
};

export default bookmarkConfig;
