import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxNoResults from '../../../../components/Layout/WidgetBox/WidgetBoxNoResults';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { IdType } from '../../../Base/types/IdType';
import ProductPromotionListWidget from '../../components/ProductPromotionListWidget/ProductPromotionListWidget';
import { getPromotionTypeApiFilterString } from '../../utils/fetchTypeHelper';
import DataStateService from '../../../../services/DataStateService';

interface IProductPromotionListWidgetContainerProps {
	title: string;
	widgetKey: string;
	actionButtonLabel: string;
	actionButtonRouteCallbk?: () => { name: string, params: {[key: string]: any} },

	pushRouteMetadata?: boolean;

	limit?: number;
	placeholderLimit?: number;
	style?: IWidgetStyleType;
	orderBy?: 'relevance' | 'createdAt';
	orderDir?: 'asc' | 'desc';

	fetchPriceProductPromotion?: boolean,
	fetchAssortementProductPromotions?: boolean,
	fetchProductPromotionGridGroupeds?: boolean,
	fetchVoucherProductPromotions?: boolean,

	noGeolocation?: boolean; // whether the entries should be affected by user location

	productGroupSlug?: IdType; // filter by product group
	vendorSlug?: IdType; // filter by product group

	onlyNearbyPromotions?: boolean;
}

class ProductPromotionListWidgetContainerInner extends React.Component<IProductPromotionListWidgetContainerProps & IWithDataProps> {
	public render () {
		const { title, data, style, widgetKey, actionButtonLabel, actionButtonRouteCallbk } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<ProductPromotionListWidget
				title={title}
				productPromotions={data}
				style={style}
				widgetKey={widgetKey}
				actionButtonLabel={actionButtonLabel}
				actionButtonRouteCallbk={actionButtonRouteCallbk}
			/>
		);
	}
}

export default function ProductPromotionListWidgetContainer (props: IProductPromotionListWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;
	const widgetKey = props.pushRouteMetadata ? props.widgetKey : `productPromotion-widget-${props.widgetKey}-${DataStateService.getStateKey()}`;

	const promotionTypes = getPromotionTypeApiFilterString(props);

	const RenderComponent = withData(
		ProductPromotionListWidgetContainerInner,
		'productPromotionReducer',
		widgetKey,
		{
			size: limit,

			orderBy: props.orderBy,
			orderDir: props.orderDir,

			noGeolocation: props.noGeolocation,

			promotion_type: promotionTypes,
			onlyNearbyPromotions: props.onlyNearbyPromotions,

			pushRouteMetadata: props.pushRouteMetadata,

			product_group: props.productGroupSlug,
			store_vendor: props.vendorSlug,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={ (props.placeholderLimit) ? props.placeholderLimit : limit }
					widgetKey={props.widgetKey}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={ (props.placeholderLimit) ? props.placeholderLimit : limit }
					widgetKey={props.widgetKey}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
