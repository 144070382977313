import * as React from 'react';
import * as styles from './VendorListGrouped.scss';
import VendorListGroupSkeleton from './VendorListGroupSkeleton';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';

class VendorListGroupedSkeleton extends React.PureComponent<{}> {
    public static defaultProps = {
        gridKey: 'vendor-list-grouped-skeleton'
    };

    // TRANSLATE
    public render() {
        const fakeArray = Array(3).fill(' ');

        return (
            <div className={styles.vendorListGroupedWrapper}>

                {fakeArray.map((item: any, indx: number) => {
                    return (
                        <React.Fragment key={`vendor-list-grouped-skeleton` + indx}>
                            <h2 className={styles.vendorListGroupedTitle}><SkeletonInline width={50} /></h2>
                            <VendorListGroupSkeleton />
                        </React.Fragment>
                    )
                })
                }

            </div >
        );
    }

}

export default VendorListGroupedSkeleton;
