import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionsAggregatedByCitysWidget
	from '../../components/PromotionsAggregatedByCitysWidget/PromotionsAggregatedByCitysWidget';
import { IPromotionsAggregatedByCityType } from '../../types/IPromotionsAggregatedByCityType';
import { getPromotionTypeApiFilterString } from '../../utils/fetchTypeHelper';
import DataStateService from '../../../../services/DataStateService';

interface IPromotionsAggregatedByCitysWidgetContainerProps {
	title: string;
	widgetKey: string;

	promotionLabel?: string;
	promotionLabelPlural?: string;

	limit?: number;
	placeholderLimit?: number;
	page?: number;

	style?: IWidgetStyleType;
	orderBy?: 'promotionCount'
	orderDir?: 'desc';

	fetchPriceProductPromotion?: boolean,
	fetchAssortementProductPromotions?: boolean,
	fetchProductPromotionGridGroupeds?: boolean,
	fetchVoucherProductPromotions?: boolean,

	routeCallbk: (item: IPromotionsAggregatedByCityType) => { name: string, params: { [ key: string ]: any } },

	brandSlug?: SlugType,
	excludeSlug?: SlugType,
}

class PromotionsAggregatedByCitysWidgetContainerInner extends React.Component<IPromotionsAggregatedByCitysWidgetContainerProps & IWithDataProps> {
	public static defaultProps = {
		promotionLabel: 'Aktion',
		promotionLabelPlural: 'Aktionen',
	};

	public render () {
		const { data, widgetKey, title, promotionLabel, promotionLabelPlural, routeCallbk } = this.props;

		if (data.length < 1) {
			return null;
		}
		
		return (
			<PromotionsAggregatedByCitysWidget
				title={title}
				aggregatedEntries={this.getCitysRespectingExclude(data)}
				widgetKey={widgetKey}
				promotionLabel={promotionLabel}
				promotionLabelPlural={promotionLabelPlural}
				routeCallbk={routeCallbk}
			/>
		);
	}

	/**
	 * Makes sure to only return entries, we haven't excluded
	 *
	 * @param data
	 */
	private getCitysRespectingExclude (data: IPromotionsAggregatedByCityType[]): IPromotionsAggregatedByCityType[] {
		const { excludeSlug } = this.props;

		if (!excludeSlug) {
			return data;
		}

		return data.filter((vendorAggregated: IPromotionsAggregatedByCityType) => {
			return vendorAggregated.group.slug !== excludeSlug;
		});
	}
}

export default function PromotionsAggregatedByCitysWidgetContainer (props: IPromotionsAggregatedByCitysWidgetContainerProps) {
	let limit = ( props.limit ) ? props.limit : 5;
	if (props.excludeSlug) {
		limit++;
	}

	const promotionTypes = getPromotionTypeApiFilterString(props);

	const RenderComponent = withData(
		PromotionsAggregatedByCitysWidgetContainerInner,
		'promotionsAggregatedByCitysWidgetReducer',
		`promotions-aggregated-by-citys-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			page: props.page,
			size: limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			promotion_type: promotionTypes,

			brand: props.brandSlug,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={( props.placeholderLimit ) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={( props.placeholderLimit ) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		},
	);

	return <RenderComponent {...props} />;
}
