import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ShoppingCentersListingPage from '../App/modules/ShoppingCenter/components/ShoppingCentersListingPage/ShoppingCentersListingPage';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { withTitlePostfix, pluralizerShoppingCenters } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class ShoppingCenterListingRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await ShoppingCenterListingRouteProps(state);

		return { reduxData };
	}

	public render() {

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403494]}
					mobileIds={[4403482, 4403481]}
					superbannerId={4403495}
					sitebarId={4403493}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<ShoppingCentersListingPage
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403493}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		ShoppingCenterListingRoute,
		(props: any) => `sc-page`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${pluralizerShoppingCenters(routeMetadata.totalItemsCount)} und -straßen in Österreich`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${pluralizerShoppingCenters(routeMetadata.totalItemsCount)} in Österreich`),
			metaDescription: `Alle Einkaufszentren im Überblick bei Aktionsfinder » Hier klicken und über alle Einzelheiten informieren.`,
		} as IRouteMetadataSetterType,
	),
);
