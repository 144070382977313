import * as React from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import * as styles from './BreadcrumbItem.scss';
import { isBrowser } from '../../../../boilerplate/razzle/razzleUtils';
import { IBreadcrumbItemType } from '../../../types/IBreadcrumbItemType';
import { RouteComponentProps, withRouter } from 'react-router';
import appConfig from '../../../config/appConfig';

declare const window: any;

interface IBreadcrumbItemProps {
	item: IBreadcrumbItemType,
	position: number,
}

class BreadcrumbItem extends React.PureComponent<IBreadcrumbItemProps & RouteComponentProps, {}> {

	public trimText(label: string) {
		return label.length > 45 ? label.substr(0, 45) + '...' : label;
	}

	public render() {
		const {item, position, location } = this.props;

		if (!item) {
			return null;
		}

		if (item.route) {
			return (
				<li
					className={`${styles.Item} breadcrumb-item`}
					property="itemListElement"
					typeof="ListItem"
				>
					<Link
						route={item.route}
						params={item.routeParams}
					>
						<a
							className={`${styles.Label} breadcrumb-label`}
							property="item"
							typeof="WebPage"
						>
							<span property="name">{this.trimText(item.label)}</span>
						</a>
					</Link>
					<span className={`${styles.Divider} breadcrumb-divider`}>/</span>
					<meta property="position" content={position +''} />
				</li>
			);
		}

		let currentUrl = (isBrowser() && typeof window !== 'undefined' && window.location) ? window.location.href : location.pathname;
		if (item.href) currentUrl = item.href;

		return (
			<li
				property="itemListElement"
				typeof="ListItem"
				className={`${styles.Item} breadcrumb-item ${styles.CurrentItem}`}
			>
				<a
					className={`${styles.Label} breadcrumb-label`}
					property="item"
					typeof="WebPage"
					href={currentUrl + ''}
				>
					<span
						property="name"
					>
						{this.trimText(item.label)}
					</span>
				</a>
				<span className={`${styles.Divider} breadcrumb-divider`}>/</span>
				<meta property="position" content={position +''} />
			</li>
		);
	}
}


export default withRouter(BreadcrumbItem);
