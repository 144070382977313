import * as React from 'react';
import { ReactNode } from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import ProductPromotionItemSkeleton from '../ProductPromotionItem/ProductPromotionItemSkeleton';
import * as styles from './ProductPromotionGrid.scss';
import ProductPromotionItemHorizontalSkeleton from '../ProductPromotionItemHorizontal/ProductPromotionItemHorizontalSkeleton';

interface IPromotionGridSkeletonProps {
	size?: 's' | 'm' | 'l',
	gridKey?: string,
	title?: string,
	description?: string,
	subtitle?: string,
	moreLabel?: string,
	titleLevel?: number,
	columns?: '1' | '2' | '3' | '4' | '5',
	theme?: "default" | "horizontal";

	boxed?: boolean,

	limit?: number;
}

class ProductPromotionGridSkeleton extends React.Component<IPromotionGridSkeletonProps, {}> {
	public static defaultProps = {
		columns: 3,
		gridKey: 'lfgrid',
		size: 'm',
		theme: "default",
	};

	public render() {
		const { size, gridKey, columns, boxed, subtitle, moreLabel, limit, theme } = this.props;

		const theLimit = (limit) ? limit : +(columns || 2);
		const limitArr = Array.from(Array(theLimit).keys());

		return (
			<div className={styles.promotionOuter}>
				{this.getCorrectTitleTag()}
				{(subtitle) ? <div className={styles.SubTitle}><SkeletonInline /></div> : null}

				<AppMainColBox boxed={boxed} nested={true}>
					<div
						className={`${styles.promotion} ${styles['promotionColumns' + columns]}`}
					>
						{
							limitArr.map((indx) => (
								<div
									className={styles.promotionItem}
									key={`promotion-product-grid-${indx}`}
								>
									<div className={styles.promotionItemInner}>
									{ theme === 'horizontal' ? 
										<ProductPromotionItemHorizontalSkeleton key={`product-grid-item-${gridKey}-${indx}`} size={size} />
										: 
										<ProductPromotionItemSkeleton key={`product-grid-item-${gridKey}-${indx}`} size={size} />
									}
									</div>
								</div>
							))
						}

						{(!moreLabel) ? null :
							<div className={styles.promotionMoreItem}>
								<a href="#" className={styles.promotionMore}>
									<SkeletonInline />
								</a>
							</div>
						}
					</div>
				</AppMainColBox>
			</div>
		);
	}

	/**
	 * Returns the correct title tag, respecting the correct headline level and title content
	 *
	 * @return {string}
	 */
	private getCorrectTitleTag(): ReactNode | null {
		const { titleLevel, title, description } = this.props;

		if (!title) {
			return null;
		}

		if (titleLevel === 2) {
			return (
				<h2><SkeletonInline /></h2>
			);
		}

		if (titleLevel === 3) {
			return (
				<h3><SkeletonInline /></h3>
			);
		}

		if (titleLevel === 4) {
			return (
				<h4><SkeletonInline /></h4>
			);
		}

		return (
			<H1 title={title}>{
				(!description) ? null :
					<SkeletonBlock />
			}</H1>
		);
	}
}

export default ProductPromotionGridSkeleton;
