import { IWidgetStyleType } from 'App/types/IWidgetStyleType';
import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { SlugType } from '../../../Base/types/SlugType';
import CurrentLeafletWidget from '../../components/CurrentLeafletWidget/CurrentLeafletWidget';
import DataStateService from '../../../../services/DataStateService';
import { IPromotionLeafletType } from '../../types/IPromotionLeafletType';

interface ICurrentLeafletWidgetProps {
	title: string;
	widgetKey: string;

	btnLabel?: string;
	btnRoute?: string;
	btnParams?: { [key: string]: any };

	limit?: number;
	orderBy?: 'rank' | 'validFrom' | 'createdAt';
	orderDir?: 'asc' | 'desc';

	style?: IWidgetStyleType;

	vendorSlug?: SlugType;
	shopSlug?: SlugType;
	industrySlug?: SlugType;
	promotionSlug?: SlugType;

	leafletsInIndustry: boolean;
	onlyNearbyLeaflets?: boolean;
}

class CurrentLeafletWidgetContainerInner extends React.Component<ICurrentLeafletWidgetProps & IWithDataProps> {
	public render() {
		const { data, style, btnLabel, btnParams, btnRoute, widgetKey, title, promotionSlug } = this.props;

		if (!data || data.length < 1) {
			return null;
		}

		return (
			<CurrentLeafletWidget
				leaflets={(!promotionSlug) ? data as ILeafletWidgetType[] : []}
				promotionPageLeaflet={(promotionSlug) ? data[0] as IPromotionLeafletType : {}}
				style={style}
				btnLabel={btnLabel}
				btnParams={btnParams}
				btnRoute={btnRoute}
				widgetKey={widgetKey}
				title={title}
			/>
		);
	}
}


export default function CurrentLeafletWidgetContainer(props: ICurrentLeafletWidgetProps) {
	const limit = (props.limit) ? props.limit : 1;

	const RenderComponent = withData(
		CurrentLeafletWidgetContainerInner,
		'leafletWidgetReducer',
		`leaflet-widget-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			store_vendor: props.vendorSlug,
			store: props.shopSlug,
			industry: props.industrySlug,
			promotionSlug: props.promotionSlug,
			onlyNearbyLeaflets: (typeof props.onlyNearbyLeaflets == 'undefined') ? true : props.onlyNearbyLeaflets,
			leafletsInIndustry: props.leafletsInIndustry,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={limit}
					type="linkrow"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: true,
						special: true,
						extra: true,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => {

				if (errorProps.errors && errorProps.errors[0] && errorProps.errors[0].status && errorProps.errors[0].status === 404) {
					return null;
				}

				return (
					<WidgetBoxError
						errors={errorProps.errors}
						title={props.title}
						rows={limit}
						type="item"
						widgetKey={props.widgetKey}
						showValues={{
							title: true,
							subtitle: true,
							special: true,
							extra: true,
						}}
					/>
				)
			},
		},
	);

	return <RenderComponent {...props} />;
}
