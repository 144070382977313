import { call, put, takeEvery } from 'redux-saga/effects';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import {
	addVerifyMailEntries,
	VERIFY_MAIL_FETCH_ENTRIES, setVerifyMailErrors,
} from './verifyMailActions';


/**
 * Fetch entry list
 */
function* verifyMail(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.postRequest({
				url: '/api/public/v1/register/verify-mail',
				body: action.params,
			} as IRequestObject);
		});

		yield put(addVerifyMailEntries(
			action.queryKey,
			[],
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setVerifyMailErrors(action.queryKey, e));
	}
}

export function* verifyMailSaga() {
	yield takeEvery(VERIFY_MAIL_FETCH_ENTRIES, verifyMail);
}
