import * as React from 'react';
import { IShopType } from '../../types/IShopType';
import * as styles from './ShopDetailInfo.scss';
import OpeningHours from '../OpeningHours/OpeningHours';
import { DateTime } from 'luxon';
import { getClickoutGatewayUrl } from '../../../../utils/clickoutGatewayGenerator';

interface IShopProps {
	shop: IShopType;
	hideTitle?: boolean;
}

class ShopDetailInfo extends React.Component<IShopProps, {}> {
	public render() {
		const { shop, hideTitle } = this.props;

		if (!shop.legacyOpeningHours && !shop.phone && !shop.email && !shop.url) {
			return null;
		}

		const validExternalUrl = ( shop.vendor && shop.vendor.openingHoursURL ) ? shop.vendor.openingHoursURL.indexOf('http') : null ;
		const openingHoursUrl= ( validExternalUrl !== -1 ) ? shop.vendor.openingHoursURL : `https://${shop.vendor.openingHoursURL}`;
		const openingHoursReadableStep1 = openingHoursUrl && openingHoursUrl.split('//')[1];
		const openingHoursReadable = (openingHoursReadableStep1) ? openingHoursReadableStep1.split('/')[0] : '';

		const todayOpenningHours = this.getOpenningHours(shop);
		let isOpenLabelVisible = false;

		if (todayOpenningHours) {
			todayOpenningHours.forEach((period: any) => {
				if (period.open && period.close) {
					const timeNow = `${DateTime.local().toFormat('HH')}${DateTime.local().toFormat('mm')}`;
					if (timeNow >= period.open.time && timeNow < period.close.time) {
						isOpenLabelVisible = true;
					}
				}
			});
		}

		return (
			<div className={`${styles.shopDetailInfoWrapper}`}>
				{hideTitle ? null : <h2>Öffnungszeiten und Kontakt</h2>}

				<div className={`${styles.shopDetailInfoContainer}`}>
					{!shop.legacyOpeningHours && !shop.openingHoursPeriods ? null : (
						<div className={styles.shopDetailInfoItem}>
							{shop.openingHoursPeriods.length > 0 ? (
								<React.Fragment>
									{(!isOpenLabelVisible) ? null :
										<div className={`${styles.HeadingHighlighted}`}>
											Aktuell geöffnet
										</div>
									}
									<div className={styles.shopDetailInfoOpened}>
										<OpeningHours shop={shop} mode="all" />
									</div>
								</React.Fragment>
							) : (
								<div
									className={styles.shopDetailInfoHours}
									dangerouslySetInnerHTML={{ __html: shop.legacyOpeningHours }}
								/>
							)}
						</div>
					)}

					<div className={`${styles.shopDetailInfoItem} ${styles.shopSubDetails}`}>

						{!shop.phone ? null : (
							<div className={styles.shopInfoRow}>
								<div className={styles.shopInfoRowLabel}>Tel.:</div>
								<div className={styles.shopInfoRowValue}>
									<a href={`tel:${shop.phone}`}>{shop.phone}</a>
								</div>
							</div>
						)}


						{!shop.email ? null : (
							<div className={styles.shopInfoRow}>
								<div className={styles.shopInfoRowLabel}>E-Mail:</div>
								<div className={styles.shopInfoRowValue}>
									<a href={`mailto:${shop.email}`}>{shop.email}</a>
								</div>
							</div>
						)}

						{!openingHoursUrl ? null : (
							<div className={styles.shopInfoRow}>
								<div className={styles.shopInfoRowLabel}>Web:</div>
								<div className={styles.shopInfoRowValue}>
									<a href={getClickoutGatewayUrl(openingHoursUrl)} target="_blank" rel="nofollow noopener noreferrer">
										{openingHoursReadable}
									</a>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Gets oppenning hours by days in the shop
	 */
	private getOpenningHours(shop: IShopType) {

		const openingHoursPeriods = shop.openingHoursPeriods;

		// get current day of the week
		let todayNumeric = new Date().getUTCDay();
		if (todayNumeric === 0) {
			todayNumeric = 7;
		}

		// loop through all periods and get only the ones, that are interesting for today
		const periodsGroupedByDay: any = {};
		for (const period of openingHoursPeriods) {
			if (typeof periodsGroupedByDay[period.open.day] === 'undefined') {
				periodsGroupedByDay[period.open.day] = [];
			}

			periodsGroupedByDay[period.open.day].push(period)
		}

		for (const periodDay of Object.keys(periodsGroupedByDay)) {
			if (+periodDay === todayNumeric) {
				return periodsGroupedByDay[periodDay];
			}
		}
	}
}

export default ShopDetailInfo;
