import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './LoadingIndicator.scss';

interface ILoadingIndicatorProps {
    children?: ReactChild;
}

class LoadingIndicator extends React.PureComponent<ILoadingIndicatorProps, {}> {
    public render() {
        const { children } = this.props;

        return (
            <div className={styles.Wrapper}>
                <div className={styles.ldsRing}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>

                <div className={styles.LoadingText}>
                    {children ? <div>{children}</div> : <div>Lade...</div>}
                </div>
            </div>
        );
    }
}

export default LoadingIndicator;
