import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import * as productPromotionGroupedActions from './productPromotionGroupedActions';

export type ProductPromotionGroupedAction = ActionType<typeof productPromotionGroupedActions>;

export interface IProductPromotionGroupedStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IProductPromotionGroupedType };
	readonly queries: { [queryKey: string]: IProductPromotionGroupedType[] };
}

export function productPromotionGroupedReducer(
	state = baseInitialState as IProductPromotionGroupedStateType,
	action: ProductPromotionGroupedAction,
) {
	const Reducer = new BaseReducer('productPromotionGroupedReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
