import * as React from 'react';
import { ReactNode } from 'react';
import * as styles from './ModalList.scss';
import Scrollbar from 'react-scrollbars-custom';


interface IModalListProps {
	children: ReactNode | ReactNode[];
	title?: string;
}

class ModalList extends React.PureComponent<IModalListProps> {
  public render() {
  	const { children, title } = this.props;

    return (
	    <div className={styles.Wrapper}>

				<Scrollbar
					className={styles.Scrollbar}
					contentProps={{
						renderer: props => {
							const { elementRef, ...restProps } = props;
							return <div {...restProps} ref={elementRef} className={styles.ScrollbarContent} />;
						}
					}}
				>
					{( !title ) ? null :
						<div className={styles.Title}>{title}</div>
					}

					{children}
				</Scrollbar>

	    </div>
    );
  }
}

export default ModalList;
