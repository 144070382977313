import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './NoDataError.scss';
import NoDataErrorImage from './NoDataErrorImage.png';

interface INoDataErrorProps {
	title?: string,
	children?: ReactChild | JSX.Element[] | string[],
	nested?: boolean,
	size?: 's' | 'm' | 'l',
}

class NoDataError extends React.PureComponent<INoDataErrorProps, {}> {
	public static defaultProps = {
		title: 'Leider nichts hier',
		size: 'l',
	};

	public render () {
		const { children, title, nested, size } = this.props;

		let style = styles.noDataErrorWrapper;

		if (nested) {
			style += ` ${styles.nestedWrapper}`;
		}

		const imageClass = (styles[`imageSize${size}`]) ? styles[`imageSize${size}`] : null;

		return (
			<div className={style}>
				<div className={`${styles.image} ${imageClass}`}>
					<img src={NoDataErrorImage} alt="Keine Ergebnisse" />
				</div>

				<div className={styles.content}>
					{ (title) ? <h1>{title}</h1> : null }

					{ (children) ?
					    <div className={styles.contentMessage}>
						    {children}
					    </div>
					    :
					    <div className={styles.contentMessage}>
						    Für deine Anfrage wurden keine passenden Einträge gefunden.
						</div>
					}
				</div>
			</div>
		);
	}
}

export default NoDataError;
