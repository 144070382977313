const serverErrorMap = {
    201: 'Dieses Benutzerkonto ist bereits aktiviert.',
    400: 'Deine Eingabe ist leider ungültig.',
    401: 'Die eingegebene E-Mail Adresse ist ungültig.',
    403: 'Diese Aktion ist leider nicht erlaubt.',
    404: 'Die E-Mail Adresse konnte nicht gefunden werden.',
    500: 'Leider ist ein unerwarteter Fehler aufgetreten, bitte versuche es erneut.',
};

export default serverErrorMap;
