import { IQueryMetaDataType } from "../../../../../boilerplate/redux/types/IQueryMetaDataType";
import { IApiErrorType } from "../../../../types/BaseTypes";

/* ### CONSTANTS ### */
export const LOTTERY_INFO_FETCH_ENTRIES = '@BasePipeline/lotteryInfoReducer_FETCH_ENTRIES';
export const LOTTERY_INFO_ADD_ENTRIES = '@BasePipeline/lotteryInfoReducer_ADD_ENTRIES';
export const LOTTERY_INFO_ADD_ENTRY = '@BasePipeline/lotteryInfoReducer_ADD_ENTRY';

export const LOTTERY_INFO_SET_ERRORS   = '@BasePipeline/lotteryInfoReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function addLotteryInfoEntries (queryKey: string, entries: [], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LOTTERY_INFO_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setLotteryInfoErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LOTTERY_INFO_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
