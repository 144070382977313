import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import { ILeafletType } from '../../types/ILeafletType';
import FutherLeafletsPage from '../../components/LeafletViewer/FutherLeafletsPage';
import LoadingIndicator from '../../../../components/Layout/LoadingIndicator/LoadingIndicator';

interface IFutherLeafletsPageContainerProps {
	gridKey?: string;
	size?: 's' | 'm' | 'l';
	title?: string;
	titleLevel?: number;

	orderBy?: 'rank' | 'createdAt';
	orderDir?: 'asc' | 'desc';
	limit?: number;
	onlyNearbyLeaflets?: boolean;
	pushRouteMetadata?: boolean;
	includeAll?: boolean;

	excludeSlug?: SlugType;
	referenceSlug?: SlugType;
}

class FutherLeafletsPageContainerInner extends React.PureComponent<IFutherLeafletsPageContainerProps & IWithDataProps> {
	public render() {
		const { data, metadata, gridKey, fetchMore, children, ...restProps } = this.props;

		const leafletWithoutExcluded = this.getEntriesWithExcluded(data);

		return (
			<FutherLeafletsPage
				leaflets={leafletWithoutExcluded}
				{...restProps}
			/>
		);
	}

	/**
	 * Cleans up our entries and makes sure the excluded entry is not in there
	 *
	 * @param {ILeafletType} entries
	 *
	 * @return {ILeafletType}
	 */
	private getEntriesWithExcluded(entries: ILeafletType[]): ILeafletType[] {
		const { limit, excludeSlug } = this.props;

		if (!excludeSlug) {
			return entries;
		}

		// now try to filter it out
		return entries.filter((entry: ILeafletType, indx: number) => {
			if (entry.slug === excludeSlug) {
				return false;
			}

			// if we are over our limit -> also return false (this might be because, we don't have the excludee in our data set,
			// but to make sure we have the correct amount of data even after removing the excludee, we have added +1 to limit on fetch
			if (limit && indx > limit) {
				return false;
			}

			// otherwise return true and add it to our results
			return true;
		});
	}
}


export default function FutherLeafletsPageContainer(theProps: IFutherLeafletsPageContainerProps) {
	const props = Object.assign({
		limit: 12,
		orderBy: 'rank',
		orderDir: 'desc',
		onlyNearbyLeaflets: true,
	}, theProps) as IFutherLeafletsPageContainerProps;

	const RenderComponent = withData(
		FutherLeafletsPageContainerInner,
		'leafletReducer',
		props.gridKey,
		{
			size: props.limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			onlyNearbyLeaflets: props.onlyNearbyLeaflets,
			pushRouteMetadata: props.pushRouteMetadata,
			include_all: props.includeAll === false ? "false" : props.includeAll,
			reference: props.referenceSlug,
		},
		{
			LoadingComponent: () => (
				<div style={{ flex: 1 }}>
					<LoadingIndicator />
				</div>
			),
		}
	);

	return <RenderComponent {...props} />;
}


