import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionworldDetail from '../../components/PromotionworldDetail/PromotionworldDetail';
import { IPromotionworldType } from '../../types/IPromotionworldType';

interface IPromotionworldDetailProps {
	queryKey: string,
	leafletSlug: SlugType;
}

class PromotionworldDetailContainerInner extends React.Component<IPromotionworldDetailProps & IWithDataProps> {
	public render() {
		const { data, ...restProps } = this.props;

		return (
			<PromotionworldDetail
				promotionworld={data as IPromotionworldType}
				{...restProps}
			/>
		);
	}
}

export default function PromotionworldDetailContainer(props: IPromotionworldDetailProps) {
	const RenderComponent = withData(
		PromotionworldDetailContainerInner,
		'promotionworldReducer',
		props.queryKey,
		{
			leafletSlug: props.leafletSlug,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'leafletSlug',
		},
	);

	return <RenderComponent {...props} />
}

