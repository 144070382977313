import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import WidgetBoxActionButton from '../../../../components/Layout/WidgetBox/WidgetBoxActionButton';
import Percentage from '../../../Base/components/Percentage/Percentage';

interface IPromotionGridProps {
	productPromotions: IProductPromotionType[],
	widgetKey: string,
	title?: string,
	style?: string,
	actionButtonLabel?: string,
	actionButtonRouteCallbk?: () => { name: string, params: {[key: string]: any} },
}

class ProductPromotionListWidget extends React.Component<IPromotionGridProps, {}> {
	public static defaultProps = {
		widgetKey: 'default',
	};

	public render () {
		const { productPromotions, widgetKey, title, actionButtonLabel, actionButtonRouteCallbk } = this.props;

		let route;
		if (actionButtonRouteCallbk) {
			route = actionButtonRouteCallbk();
		}

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					<div>
						{productPromotions.map((item: IProductPromotionType) => {
							// console.log(item);
							
							return (
								<WidgetListItemLinkRow
									key={`${widgetKey}-${item.slug}`}
									route="PromotionDetailRoute"
									routeParams={{
										promotion: item.slug,
									}}
									title={item.fullDisplayName}
									// subtitle={(item.discountPercentage) ? <Percentage amount={item.discountPercentage} /> : ''}
								/>
							);
						})}
					</div>

					{( !route ) ? null :
					 <WidgetBoxActionButton
						 route={route.name}
						 params={route.params}
					 >
						 {actionButtonLabel}
					 </WidgetBoxActionButton>
					}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default ProductPromotionListWidget;
