import * as React from 'react';

import AccountInfoSkeleton from '../../components/AccountInfo/AccountInfoSkeleton';
import AccountInfo from '../../components/AccountInfo/AccountInfo';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { Redirect } from 'react-router';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import { connect } from 'react-redux';

interface IAccountInfoContainerProps {
	accountReducer: any;
}

class AccountInfoContainerInner extends React.Component<IAccountInfoContainerProps & IWithDataProps> {

	public render () {
		const { accountReducer } = this.props;

		if (isServer()) {
			return null;
		}

		if (!accountReducer.isLoggedIn) {
			return <Redirect to='/login' />
		}

		return (
			<AccountInfo
				data={accountReducer.accountInfo as IAccountInfoType}
			/>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		accountReducer: state.accountReducer,
	}
}

export default function AccountInfoContainer (theProps: IAccountInfoContainerProps) {
	const props = Object.assign({

	}, theProps);

	if (isServer()) {
		return <AccountInfoSkeleton />;
	}

	const RenderComponent = withData(
		connect(mapStateToProps, {})(AccountInfoContainerInner),
		'accountReducer',
		theProps.routeMetadata.queryKey,
		{

		},
		{
			LoadingComponent: () => <AccountInfoSkeleton />
		}
	);

	return <RenderComponent {...props} />;
}


