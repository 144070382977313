import { DateTime } from 'luxon';
import * as React from 'react';
import Button from '../../../../components/General/Button/Button';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import Datespan from '../../../Base/components/Datespan/Datespan';
import Link from '../../../Base/components/Link/Link';
import LeafletService from '../../services/LeafletService';
import { IPromotionLeafletType } from '../../types/IPromotionLeafletType';
import CurrentVendorLeafletStructuredData from '../CurrentVendorLeaflet/CurrentVendorLeafletStructuredData';
import LeafletImagePlaceholder from '../LeafletImagePlaceholder/LeafletImagePlaceholder';
import * as styles from './CurrentLeafletWidget.scss';
import LeafletRelativeValidDate from '../LeafletRelativeValidDate/LeafletRelativeValidDate';
import { onlyRelativeDateHoferHack } from '../../store/Leaflet/leafletActions';

interface ICurrentLeafletWidgetPropsType {
	widgetKey: string;
	title: string;

	btnLabel?: string;
	btnRoute?: string;
	btnParams?: { [ key: string ]: any };

	style: IWidgetStyleType;
	leaflets: ILeafletWidgetType[];
	promotionPageLeaflet: IPromotionLeafletType;
}

class CurrentLeafletWidget extends React.PureComponent<ICurrentLeafletWidgetPropsType> {
	public static defaultProps = {};

	public componentDidMount() {
	  const { leaflets, promotionPageLeaflet } = this.props;

	  const theLeaflets = [
		 ...leaflets,
		 promotionPageLeaflet
	  ];

	  theLeaflets.map((leaflet) => {
		  const expired = DateTime.local().startOf('day') > DateTime.fromISO(leaflet.validTo);

		  // don't track it if it is expired
		  if (expired || !leaflet?.id) {
			  return;
		  }

		  InternalTrackingService.batchTrack(`leaflet-impression-${leaflet.id}`, 'LEAFLET_IMPRESSION', {
				industry_slug: leaflet.vendorIndustrySlugs?.join(','),
				leafletId: leaflet.id,
				vendorId: leaflet.vendorId,
		  });
	  });
	}

	public render () {
		const { leaflets, promotionPageLeaflet, title, btnLabel, widgetKey, btnRoute, btnParams } = this.props;

		// if no promotion page and no leaflets, return null
		if (!promotionPageLeaflet.promotionPage && leaflets.length < 1) {
			return null;
		}

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>

					{ (!promotionPageLeaflet.promotionPage) ?  null :
						<Link
							key={`${widgetKey}-${promotionPageLeaflet.slug}`}
							href={`${LeafletService.generateLeafletHref(promotionPageLeaflet)}/#${(promotionPageLeaflet.promotionPage.pageNumber + 1)}`}
						>
							<a className={styles.leafletContainer}>
								<ImageEnhanced
									source={( promotionPageLeaflet.promotionPage && promotionPageLeaflet.promotionPage.imageUrl && promotionPageLeaflet.promotionPage.resolutions ) ? promotionPageLeaflet.promotionPage.resolutions : null}
									size={'m'}
									bgImage={true}
									className={styles.leafletItemImage}
								>
									<LeafletImagePlaceholder alt={promotionPageLeaflet.title} />
								</ImageEnhanced>

								<span className={styles.leafletInfoBlock}>

									<span className={styles.Title}>{promotionPageLeaflet.title}</span>

									<span className={styles.leafletInfoBlockDatespan}>
										{promotionPageLeaflet.onlyRelativeDate || onlyRelativeDateHoferHack(promotionPageLeaflet) ?
											<LeafletRelativeValidDate validFrom={promotionPageLeaflet.validFrom} validTo={promotionPageLeaflet.validTo} />
											:
											<Datespan from={promotionPageLeaflet.validFrom} to={promotionPageLeaflet.validTo} />
										}
									</span>
								</span>
							</a>
						</Link>
					}
					
					{ ( leaflets.map((leaflet: ILeafletWidgetType) => {
							return (
								<React.Fragment key={leaflet.slug}>
									<Link
										key={`${widgetKey}-${leaflet.slug}`}
										href={LeafletService.generateLeafletHref(leaflet)}
									>
										<a className={styles.leafletContainer}>
											<ImageEnhanced
												source={( leaflet.coverPage && leaflet.coverPage.resolutions ) ? leaflet.coverPage.resolutions : null}
												size={'m'}
												bgImage={true}
												className={styles.leafletItemImage}
											>
												<LeafletImagePlaceholder alt={leaflet.title} />
											</ImageEnhanced>

											<span className={styles.leafletInfoBlock}>
												<span className={styles.Title}>{leaflet.title}</span>

												<span className={styles.leafletInfoBlockDatespan}>
													{leaflet.onlyRelativeDate || onlyRelativeDateHoferHack(leaflet) ?
														<LeafletRelativeValidDate validFrom={leaflet.validFrom} validTo={leaflet.validTo} />
														:
														<Datespan from={leaflet.validFrom} to={leaflet.validTo} />
													}
												</span>
											</span>
										</a>
									</Link>
									
									<CurrentVendorLeafletStructuredData leaflet={leaflet} />
								</React.Fragment>
							);
						}) )
					}

					{( !btnLabel || !btnRoute ) ? null :
						 <Button
							 size="l"
							 uppercase={true}
							 fullWidth={true}
							 style="contrast"
							 route={btnRoute}
							 routeParams={btnParams}
							 customClassName={styles.leafletMore}
						 >
							 {btnLabel}
						 </Button>
					}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default CurrentLeafletWidget;
