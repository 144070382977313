import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as searchPromotionActions from './searchPromotionActions';

export type searchPromotionAction = ActionType<typeof searchPromotionActions>;

export interface IsearchPromotionStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: ITagType };
	readonly queries: { [queryKey: string]: ITagType[] };
}

export function searchPromotionReducer(
	state = baseInitialState as IsearchPromotionStateType,
	action: searchPromotionAction,
) {
	const Reducer = new BaseReducer('searchPromotionReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
