import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './Scroller.scss';
import Scrollbar from 'react-scrollbars-custom';

interface IScrollerProps {
  children: ReactChild | JSX.Element[] | string[];
  height: number;
}

interface IScrollerStates {
  scrolled: number;
}

class Scroller extends React.PureComponent<IScrollerProps, IScrollerStates> {

  private scrollbar: React.Ref;

  constructor (props: IScrollerProps) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  public componentDidMount () {
    window.addEventListener('wheel', this.handleScroll);
  }

  public componentWillUnmount () {
    window.removeEventListener('wheel', this.handleScroll);
  }

  public render () {
    const { height, children } = this.props;

    return (
      <Scrollbar
        className={styles.scrollbar}
        style={{ minHeight: height }}
        onUpdate={this.handleScroll}
        noScrollY={true}
        ref={ref => ( this.scrollbar = ref )}
        mobileNative
        contentProps={{
          renderer: props => {
            const { elementRef, renderer, ...restProps } = props;
            return <div {...restProps} ref={elementRef} className={styles.ScrollbarContent} />;
          }
        }}
      >
        {children}
      </Scrollbar>
    );
  }

  /**
   * Scrolling of horizontal scrollbar
   */
  public handleScroll = (event: WheelEvent) => {

    const { offsetY, deltaY } = event;

    if (event.preventDefault) {
      event.preventDefault();
    }

    if (this.state.scrolled > this.scrollbar.scrollValues.scrollWidth - this.scrollbar.scrollValues.clientWidth / 2) {
      return;
    }

    if (this.scrollbar.scrollValues.scrollLeft > 0) {
      this.setState({
        scrolled: this.scrollbar.scrollValues.scrollLeft
      });
    }

    if (typeof deltaY === 'number') {
      let scrolled = this.state.scrolled + deltaY;

      if (scrolled < 0) {
        scrolled = 0;
      }

      this.setState({ scrolled });
      this.scrollbar.scrollTo(scrolled, offsetY);
    }
  };
}

export default Scroller;
