import * as React from 'react';

interface ISymbolGraphicPropType extends React.ImgHTMLAttributes<any>{
  src: string;
  alt: string;
}

class SymbolGraphic extends React.Component<ISymbolGraphicPropType, {}> {
  public render () {
    return (
      <img {...this.props} alt={this.props.alt} />
    );
  }
}

export default SymbolGraphic;
