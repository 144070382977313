import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IBrandWidgetType } from '../../types/IBrandWidgetType';
import * as brandWidgetActions from './brandWidgetActions';

export type BrandWidgetAction = ActionType<typeof brandWidgetActions>;

export interface IBrandWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IBrandWidgetType };
	readonly queries: { [queryKey: string]: IBrandWidgetType[] };
}

export function brandWidgetReducer(
	state = baseInitialState as IBrandWidgetStateType,
	action: BrandWidgetAction,
) {
	const Reducer = new BaseReducer('brandWidgetReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
