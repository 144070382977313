import { DateTime } from 'luxon';
import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import appConfig from '../../../../config/appConfig';
import Currency from '../../../Base/components/Currency/Currency';
import HumanReadableDate from '../../../Base/components/HumanReadableDate/HumanReadableDate';
import Link from '../../../Base/components/Link/Link';
import Percentage from '../../../Base/components/Percentage/Percentage';
import { IImageResolutions } from '../../../Base/types/IImageResolutions';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import PromotionBookmark from '../PromotionBookmark/PromotionBookmark';
import { getMainProductImage } from '../PromotionImage/PromotionImage';
import * as styles from './ProductPromotionItem.scss';

interface IProductPromotionItemProps {
    promotion: IProductPromotionType;
    useLeafletRoute?: boolean;
    onClick?: () => void;
    onLoad?: () => void;
    // showBookmarkIcon?: boolean;
    directClickout?: boolean;
}

class ProductPromotionItem extends React.Component<IProductPromotionItemProps, {}> {
    public componentDidMount() {
        this.handleOnLoadEvent();
    }

    public render() {
        const { promotion, directClickout } = this.props;

        const validFrom = promotion.validFrom ? DateTime.fromISO(promotion.validFrom) : null;
        const validTo = promotion.validTo ? DateTime.fromISO(promotion.validTo) : null;

        const expired = DateTime.local().startOf('day') > validTo;

        const discountValue = this.getDiscountValue();

        const wrapperClassName = expired ? `${styles.ProductPromotionGridItemWrapper} ${styles.inactiveItem}` : styles.ProductPromotionGridItemWrapper;

        const promotionTitle = promotion.fullDisplayName ?? promotion.title;

        const PromotionItem = () => (
            <React.Fragment>
                <span className={styles.ProductPromotionGridItemWrapperInner}>
                    <span className={styles.ImageWrapper}>
                        <ImageEnhanced source={this.getResultImage(promotion)} bgImage={true} />
                    </span>

                    <span className={styles.Content}>
                        <span className={styles.Heading}>
                            {promotionTitle}
                            {/* // if title is too long -> show fade out */}
                            {promotionTitle?.length < 55 ? null : <span className={styles.HeadingFadeout} />}
                        </span>

                        <span className={styles.ActionBar}>
                            <span className={styles.ActionBarGrow} style={promotion.vendor && promotion.vendor.resolutions ? { display: 'flex' } : {}}>
                                {promotion.vendor && promotion.vendor.resolutions ? <ImageEnhanced source={promotion.vendor.resolutions} className={styles.productPromotionVendorItemImage} alt={promotion.vendor.name} size={'s'} /> : `${promotion.vendorName}`}

                                {promotion.vendor && !promotion.vendor.resolutions && promotion.validTo ? ' – ' : ''}

                                {!validFrom || !validTo ? null : promotion.openValidity ? (
                                    <HumanReadableDate from={validFrom} to={validTo} outputIfEnded="abgelaufen" openValidity={promotion.openValidity} />
                                ) : (
                                    <span>
                                        {validFrom.toFormat('dd.LL.')} - {validTo.toFormat('dd.LL.yy')}
                                    </span>
                                )}
                            </span>
                        </span>

                        <span className={styles.ActionBar}>
                            <span className={styles.ActionBarGrow}>
                                {!promotion.originalPrice ? (
                                    <span className={`${styles.Line} ${styles.OldPrice} `}></span>
                                ) : (
                                    <span className={`${styles.Line} ${styles.OldPrice} `}>
                                        <Currency amount={promotion.originalPrice} />
                                    </span>
                                )}

                                {!discountValue ? <span className={`${styles.Line} ${styles.Price} `}></span> : <span className={`${styles.Line} ${styles.Price} `}>{discountValue}</span>}
                            </span>
                            <span className={styles.ActionBarShrink}>
                                <PromotionBookmark />
                            </span>
                        </span>
                    </span>
                </span>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: this.getSchemaOrgJson() }} />
            </React.Fragment>
        );

        if (!directClickout || !promotion.clickoutUrl) {
            const internalRoute = {
                name: 'PromotionDetailRoute',
                params: {
                    promotion: promotion.slug,
                },
            };

            return (
                <Link {...this.getLinkParams()}>
                    <a className={wrapperClassName} onClick={this.handleOnClickEvent}>
                        <PromotionItem />
                    </a>
                </Link>
            );
        }

        return (
            <a href={promotion.clickoutUrl} target="_blank" className={wrapperClassName} onClick={this.handleOnClickEvent}>
                <PromotionItem />
            </a>
        );
    }

    /**
     * Returns the correct discount value based on the available discounts we have
     *
     * @return {string}
     */
    private getDiscountValue(): string | React.ReactNode {
        const { promotion } = this.props;

        if (promotion.discountedPrice) {
            return <Currency amount={promotion.discountedPrice} currency={promotion.currency} />;
        }

        if (promotion.newPrice) {
            return <Currency amount={promotion.newPrice} currency={promotion.currency} />;
        }

        if (promotion.discountAmount) {
            return <Currency amount={promotion.discountAmount} currency={promotion.currency} />;
        }

        if (promotion.discountPercentage) {
            return <Percentage amount={promotion.discountPercentage} />;
        }

        return '';
    }

    private getResultImage(promotion: IProductPromotionType): IImageResolutions {
        if (promotion.images && promotion.images[0]) {
            return promotion.images[0].resolutions;
        }

        if (promotion.product?.productImages[0]?.resolutions) {
            return promotion.product?.productImages[0]?.resolutions;
        }

        if (promotion.products && promotion.products[0]?.productImages[0]?.resolutions) {
            return promotion.products[0]?.productImages[0]?.resolutions;
        }

        return {
            type: 'ImageResolution',
            large: '/images/layout/productPromotionPlaceholder.png',
            medium: '/images/layout/productPromotionPlaceholder.png',
            small: '/images/layout/productPromotionPlaceholder.png',
        };
    }

    /**
     * Takes the promotion type and returns the correct route to link them to
     */
    private getLinkParams(): { [key: string]: unknown } {
        const { promotion, useLeafletRoute } = this.props;

        // TODO: Change the page value as soon as API is ready for it
        if (useLeafletRoute && promotion.leaflet) {
            return {
                href: `/l/${promotion.leaflet.slug}/#${(promotion.leaflet.coverPage?.pageNumber || 0) + 1}`,
            };
        }

        return {
            route: 'PromotionDetailRoute',
            params: { promotion: promotion.slug },
        };
    }

    /**
     * Returns the correct schema org data
     */
    private getSchemaOrgJson(): string {
        const { promotion } = this.props;

        const mainImage = getMainProductImage(promotion);
        const productGroup = promotion.productGroups && promotion.productGroups[0] ? promotion.productGroups[0].title : null;

        let lowPrice = promotion.discountedPrice ? promotion.discountedPrice : promotion.newPrice;
        if (!lowPrice && promotion.originalPrice) {
            lowPrice = promotion.originalPrice;
        }

        if (!lowPrice) {
            return '';
        }

        if (promotion.type === 'new_price' || promotion.type === 'product') {
            return JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Product',
                image: mainImage?.resolutions?.large,
                name: promotion.fullDisplayName ?? promotion.title,
                offers: {
                    '@type': 'AggregateOffer',
                    priceCurrency: promotion.currency ? promotion.currency.iso : 'EUR',
                    validFrom: promotion.validFrom,
                    priceValidUntil: promotion.validTo,
                    highPrice: promotion.originalPrice ? promotion.originalPrice : undefined,
                    category: productGroup,
                    lowPrice: lowPrice,
                    offers: [
                        {
                            '@type': 'Offer',
                            url: `${appConfig.mainDomain}/p/${promotion.slug}`,
                        },
                    ],
                },
            });
        }

        return '';
    }

    /**
     * Handles the onclick event on this promotion
     */
    private handleOnClickEvent = () => {
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }
    };

    /**
     * Handles the onload event on this promotion
     */
    private handleOnLoadEvent = () => {
        const { onLoad } = this.props;

        if (onLoad) {
            onLoad();
        }
    };
}

export default ProductPromotionItem;
