import * as React from 'react';
import * as styles from './Menu.scss';

interface IMenuCategoryProps {
    title?: string;
    children: React.ReactNode;
}


class MenuCategory extends React.Component<IMenuCategoryProps, {}> {

    public static defaultProps = {
        title: '',
    };

    public render () {

        const { title, children } = this.props;

        return (
        
            <React.Fragment>
                
                <h4 className={styles.menuGroupCategory}>
                    {title}
                </h4>

                <div className={styles.menuGroupItems}>
                    {children}
                </div>

            </React.Fragment> 
        );
    }
}

export default MenuCategory;
