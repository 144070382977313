import * as React from 'react';
import { ReactNode } from 'react';
import { connect } from 'react-redux'
import { fetchAccount } from '../../../modules/Account/store/Account/accountActions';
import Overlay from '../../../modules/Base/components/Overlay/Overlay';
import AlertsContainer from '../../../modules/Base/containers/Alerts/AlertsContainer';
import CurrentLocationWidgetContainer
	from '../../../modules/Base/containers/CurrentLocationWIdget/CurrentLocationWidgetContainer';
import ReduxService from '../../../../boilerplate/redux/services/ReduxService';
import { hideOverlay, hideLocationModal } from '../../../modules/Base/store/Modal/modalActions';
import { IGeolocationType } from '../../../modules/Base/types/IGeolocationType';
import BookmarkService from '../../../modules/Bookmark/services/BookmarkService';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import H1 from '../H1/H1';
import * as styles from './PageWrapper.scss';
import GeolocationService from '../../../modules/Base/services/GeolocationService';
import AlertService from '../../../modules/Base/services/AlertService';
import { isServer } from '../../../../boilerplate/razzle/razzleUtils';
import Modal from '../../../modules/Base/components/Modal/Modal';
import LocationInputModalContainer from '../../../modules/Base/containers/LocationInputModal/LocationInputModalContainer';
import LeafletItemStickerModal from '../../../modules/Leaflet/components/LeafletItemStickerModal/LeafletItemStickerModal';
import { hideLeafletDiscountModal } from '../../../modules/Leaflet/store/Leaflet/leafletActions';
import { IBreadcrumbItemType } from '../../../types/IBreadcrumbItemType';
import MobileAppBanner from '../MobileAppBanner/MobileAppBanner';
import PageHeading from './PageHeading';
import LayoutService from '../../../services/LayoutService';

interface IPageWrapperProps {
	children: ReactNode;
	title?: string;
	breadcrumbs?: IBreadcrumbItemType[];
	showLocationWidget?: boolean;
	showMobileBanner?: boolean;
	titleAlign?: 'center' | 'left' | 'right';
	setGeolocation?: (geolocation: IGeolocationType) => void,
	fluid?: boolean,
	noLayout?: boolean,
	geolocationReducer?: any,
	modalReducer?: any,
	leafletReducer?: any;
	hideOverlayFunc?: () => void;
	hideLocationModal?: () => void;
	hideLeafletDiscountModal?: () => void;
	nextGenDesign?: boolean;
	disableScrollToTop?: boolean;
}

class PageWrapper extends React.Component<IPageWrapperProps, {}> {
	public static defaultProps = {
		showLocationWidget: false,
		showMobileBanner: true,
		titleAlign: 'center',
	};

	public componentDidMount() {
		const { disableScrollToTop } = this.props;

		// Scrolling to the top
		if (!disableScrollToTop && !isServer()) {
			window.scrollTo(0, 0);
		}

		this.componentWillMountCalls();
	}

	public componentWillMountCalls() {
		ReduxService.dispatch(fetchAccount('account-data'));

		// // init bookmarks
		BookmarkService.initBookmarks();

		AlertService.locationAlert();
	}

	public render() {
		const { title, children, titleAlign, geolocationReducer, modalReducer, fluid, noLayout, breadcrumbs, hideLocationModal, hideLeafletDiscountModal, leafletReducer, nextGenDesign, showMobileBanner } = this.props;

		if (LayoutService.isAppShellDisabled()) {
			return children;
		}

		const LeafletModal = () => {
			return (
				<React.Fragment>
					{(!leafletReducer.showLeafletDiscountModal) ? null :
						<Modal
							content={LeafletItemStickerModal}
							contentData={leafletReducer.discountLeaflet}
							title={(leafletReducer.discountLeaflet.discountType === "STICKER") ? "Rabattsticker im Postkasten!" : (leafletReducer.discountLeaflet.discountType === "VOUCHER") ? "Rabattgutschein im Postkasten!" : ""}
							titleStyle="center"
							onCloseModal={hideLeafletDiscountModal}
						/>
					}
				</React.Fragment>
			)
		};

		if (!geolocationReducer.currentLocation && !geolocationReducer.isInitiated && !GeolocationService.isInitialized()) {
			return (
				<React.Fragment>
					<CurrentLocationWidgetContainer hide={true} />
				</React.Fragment>
			);
		}

		if (noLayout) {
			return (
				<React.Fragment>
					<CurrentLocationWidgetContainer hide={true} />

					{children}

					<AlertsContainer />
					<LeafletModal />
				</React.Fragment>
			);
		}


		return (
			<div className={`page-wrapper-outer`}>
				<div className={`${styles.pageWrapper} page-wrapper ${(fluid) ? styles.fluid : ''} ${(nextGenDesign) ? styles.DesignV3 : ''}`}>
					<div className={styles.pageWrapperHead}>

						{/* <div className={`${styles.pageWrapperInfoWidget} location-info`}>
							<div className={styles.pageWrapperInfoLocationWidget}>
								<CurrentLocationWidgetContainer hide={hideLocation} />
							</div>
						</div> */}

						<div className={styles.Breadcrumbs}>
							<Breadcrumbs items={breadcrumbs} />
						</div>

					</div>

					{!title ? null : (
						<React.Fragment>
							{(!styles.DesignV3) ?
								<H1 align={titleAlign} className={(nextGenDesign) ? styles.DesignV3Title : ''}>{title}</H1>
								:
								<PageHeading title={title} />
							}
						</React.Fragment>
					)}

					<div className={styles.pageWrapperContainer}>{children}</div>

				</div>

				{(!modalReducer.showOverlay) ? null :
					<Overlay onClick={null} />
				}

				<AlertsContainer />
				
				{(showMobileBanner && <MobileAppBanner />)}


				{(!modalReducer.showLocationModal) ? null :
					<Modal
						content={LocationInputModalContainer}
						title="Mein Standort"
						onCloseModal={hideLocationModal}
					/>
				}

				<LeafletModal />

			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		geolocationReducer: state.geolocationReducer,
		modalReducer: state.modalReducer,
		accountReducer: state.accountReducer,
		leafletReducer: state.leafletReducer,
	}
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
		hideOverlayFunc: () => {
			dispatch(hideOverlay());
		},
		hideLocationModal: () => {
			dispatch(hideLocationModal());
		},
		hideLeafletDiscountModal: () => {
			dispatch(hideLeafletDiscountModal());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);
