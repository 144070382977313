import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import ProductPromotionGridGroupedContainer from '../../containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import ProductPromotionListWidgetContainer from '../../containers/ProductPromotionListWidget/ProductPromotionListWidgetContainer';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import PromotionsAggregatedByBrandsWidgetContainer from '../../containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';
import MobileAppWidget from '../../../../components/Layout/MobileAppWidget/MobileAppWidget';
import * as styles from './PromotionsByVendor.scss';
import { IVendorType } from '../../../Vendor/types/IVendorType';
import PromotionsAggregatedByProductGroupsWidgetContainer from '../../containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';
import VendorDetailHeader from '../../../Vendor/components/VendorDetailHeader/VendorDetailHeader';
import AdItem from '../../../../components/General/AdItem/AdItem';
import CurrentLeafletWidgetContainer from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';

interface IPromotionsByVendorProps {
	vendor?: IVendorType;
	vendorSlug: SlugType;
}

class PromotionsByVendor extends React.Component<IPromotionsByVendorProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, vendor, vendorSlug } = this.props;

		const vendorName = (routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title) ? routeMetadata.vendor.title : vendorSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>

						<VendorDetailHeader vendor={vendor} title={routeMetadata.pageTitle} vendorSlug={vendorSlug} />

						<AppMainColBox boxed={true} nested={true}>

							<div className={styles.tagCloudWidgetContainer}>
								<PromotionsAggregatedByProductGroupsWidgetContainer
									title={`Beliebte Produktkategorien bei ${vendorName}`}
									widgetKey={`vendor-product-group-cloud-${vendorSlug}`}
									style="tagcloud"
									limit={10}
									vendorSlug={vendorSlug}
									routeCallbk={this.routerCallbkProductGroupListWidget}
								/>
							</div>

							<ProductPromotionGridGroupedContainer
								orderBy="createdAt"
								orderDir="desc"
								radius={100000}
								enablePagination={true}
								vendorSlug={vendorSlug}
								moreLabel={`Weitere %title% Aktionen bei ${vendorName}`}
								moreRouteCallbk={this.routerCallbkPromotionListWidget}
								moreRouteChangeble={false}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								onlyNearbyPromotions={false}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridGroupedContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<ProductPromotionListWidgetContainer
								widgetKey={`pv-${vendorSlug}-list-price`}
								title={`Beliebte Aktionen bei ${vendorName}`}
								fetchPriceProductPromotion={true}
								vendorSlug={vendorSlug}
								limit={10}
								// actionButtonLabel={`Alle Aktionen bei ${vendorName}`}
								// actionButtonRouteCallbk={this.routerCallbkAssortmentListWidget}
								orderBy="createdAt"
								orderDir="desc"
								onlyNearbyPromotions={true}
							/>

							<PromotionsAggregatedByBrandsWidgetContainer
								widgetKey={`pv-${vendorSlug}-brands`}
								title={`Marken in Aktion bei ${vendorName}`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								vendorSlug={vendorSlug}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<ProductPromotionListWidgetContainer
								widgetKey={`pv-${vendorSlug}-voucher-list`}
								title={`Gutscheine bei ${vendorName}`}
								actionButtonLabel={`Alle ${vendorName} Gutscheine`}
								actionButtonRouteCallbk={this.routerCallbkVoucherListWidget}
								limit={10}
								fetchVoucherProductPromotions={true}
								vendorSlug={vendorSlug}
								orderBy="createdAt"
								orderDir="desc"
								onlyNearbyPromotions={true}
							/>

							<ProductPromotionListWidgetContainer
								widgetKey={`pv-${vendorSlug}-list-assortments`}
								title={`Sortimentsaktionen bei ${vendorName}`}
								fetchAssortementProductPromotions={true}
								vendorSlug={vendorSlug}
								limit={10}
								actionButtonLabel={`Alle ${vendorName} Sortimentsaktionen`}
								actionButtonRouteCallbk={this.routerCallbkAssortmentListWidget}
								orderBy="createdAt"
								orderDir="desc"
								onlyNearbyPromotions={true}
							/>
							
							<CurrentLeafletWidgetContainer
								widgetKey={`promotions-vendor-leaflet-widget-${vendorSlug}`}
								title={`Der aktuelle ${vendorName} Prospekt`}
								btnLabel={`Alle ${vendorName} Prospekte`}
								vendorSlug={vendorSlug}
								limit={1}
								orderBy="validFrom"
								orderDir="asc"
								btnRoute="LeafletsByVendorRoute"
								btnParams={{
									vendor: vendorSlug
								}}
							/>

							<MobileAppWidget />

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Händler Übersicht',
				route: 'VendorListingRoute',
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.vendor.title : '',
				route: 'VendorDetailRoute',
				routeParams: {vendor: this.props.vendorSlug},
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.pageTitle : '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the promotion grid container should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: IProductPromotionGroupedType) => {
		const { vendorSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndVendorRoute',
			params: {
				vendor: vendorSlug,
				productGroup: item.group.slug,
			},
		};
	};

	/**
	 * Defines the route the voucher list widget should be routed towards
	 */
	private routerCallbkVoucherListWidget = () => {
		const { vendorSlug } = this.props;

		return {
			name: 'VouchersByVendorRoute',
			params: {
				vendor: vendorSlug,
			},
		};
	};

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkAssortmentListWidget = () => {
		const { vendorSlug } = this.props;

		return {
			name: 'AssortementPromotionsByVendorRoute',
			params: {
				vendor: vendorSlug,
			},
		};
	};

	/**
   * Defines the route the state list widget should be routed towards
	*/
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { vendorSlug } = this.props;

		return {
		name: 'PromotionsFromBrandAndVendorRoute',
		params: {
			brand: item.group.slug,
			vendor: vendorSlug
		}
		};
	};

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		const { vendorSlug } = this.props;

		return {
		name: 'PromotionsInProductGroupAndVendorRoute',
		params: {
			productGroup: item.group.slug,
			vendor: vendorSlug
		}
		};
	};
}

export default PromotionsByVendor;
