import * as React from 'react';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import InputField from '../../../../components/Layout/Form/InputField';
import SelectField from '../../../../components/Layout/Form/SelectField';
import Button from '../../../../components/General/Button/Button';
import Link from '../../../Base/components/Link/Link';
import LotteryService from '../../services/LotteryService';
import * as styles from './LotteryPayment.scss';
import Checkbox from '../../../../components/Layout/Checkbox/Checkbox';
import AlertService from '../../../Base/services/AlertService';
import { connect } from 'react-redux';
import Row from '../../../Base/components/Grid/Row';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PromotionsAggregatedByBrandsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../../Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import PromotionsAggregatedByProductGroupsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import LotteryImage from '../LotteryImage/LotteryImage';

interface ILotteryPaymentProps {
	accountReducer: any;
	data: IWinnerType;
	verificationToken: string;
}

interface ILotteryPaymentStates {
	salutation: string,
	firstName: string,
	lastName: string,
	email: string,
	phone: string,
	address: string,
	zipcode: string,
	district: string,
	terms: boolean,
	loading: boolean,
	success: boolean,
}

class LotteryPayment extends React.Component<ILotteryPaymentProps, ILotteryPaymentStates> {

	constructor(props: ILotteryPaymentProps) {
		super(props);

		this.state = {
			salutation: '',
			firstName: props.accountReducer.accountInfo.firstName,
			lastName: props.accountReducer.accountInfo.lastName,
			email: props.accountReducer.accountInfo.email,
			phone: '',
			address: '',
			zipcode: '',
			district: '',
			terms: false,
			loading: false,
			success: false,
		};
	}

	private onSubmit = () => {

		const { address, email, firstName, lastName, phone, salutation, zipcode, district } = this.state;

		const { verificationToken } = this.props;

		if (!this.checkFields()) {
			return;
		}

		this.setState({ loading: true });

		const addressString = `${address} ${zipcode} ${district}`

		const data = { address: addressString, email, firstName, lastName, phone, salutation, verificationToken };

		LotteryService.sendLotteryPaymentData(data, (response: any) => {

			this.setState({
				loading: false,
				success: true,
			});

		}, (error: any) => {

			this.setState({ loading: false });

			AlertService.error(
				'error-' + error,
				error,
			);
		});
	};

	public render() {

		const { salutation, firstName, lastName, email, phone, address, zipcode, district, terms, loading, success } = this.state;

		const { data } = this.props;

		// If there is Error 400 (if 48 hours are over)
		if (!data || !data.amount) {
			return (
				<PageWrapper hideLocation={true} fluid={true}>
					<FormWrapper>

						<H1 align="center" title="EINLÖSEFRIST LEIDER VERSTRICHEN">
							Leider hast du deinen Gewinn nicht rechtzeitig eingelöst und der Warengutschein ist in den Jackpot für die nächste Verlosung gewandert. Aber nicht traurig sein, du hast schon bei der nächsten Verlosung wieder eine Chance zu gewinnen!
                        </H1>

                        <Button
                            theme="primary"
                            size="xxl"
                            fullWidth={true}
                            route="HomeRoute"
                        >
                            Weiter zu den besten Aktionen...
                        </Button>

					</FormWrapper>
				</PageWrapper>
			)
		}

		if (success) {
			return (
				<PageWrapper hideLocation={true}>
					<FormWrapper>

						<H1 align="center" title="DEIN GEWINN IST AUF DEM WEG ZU DIR!">
							Vielen Dank! Du hast deinen Gewinn eingelöst und er wird von uns in Kürze auf dem Postweg an folgende Adresse versandt:
                        </H1>

						<div className={styles.lotteryPaymentAddressBlock}>
							<div>
								{salutation} {firstName} {lastName}
							</div>
							<div>
								{address}
							</div>
							<div>
								{zipcode} {district}
							</div>
						</div>

						<div className={styles.lotteryPaymentSuccessInfo}>
							<span>Wir wünschen dir auch weiterhin viel Glück bei unserem Gewinnspiel! Solltest du Fragen zu deinem Gewinn haben, wende dich bitte einfach per E-Mail an </span>
							<a href="mailto:office@aktionsfinder.at">office@aktionsfinder.at</a>.
						</div>

                        <Button
                            theme="primary"
                            size="xxl"
                            fullWidth={true}
                            route="HomeRoute"
                        >
                            Weiter zu den besten Aktionen
                        </Button>

					</FormWrapper>
				</PageWrapper>
			);
		}

		return (

			<PageWrapper hideLocation={true}>
				<H1 title="AKTIONSFINDER DER WOCHE" />

				<Row>
					<AppMainCol boxed={false}>

						<LotteryImage />

						<AppMainColBox boxed={true}>

							<h2>Herzliche Gratulation zu deinem Gewinn!</h2>

							<div className={styles.lotteryPaymentInfo}>
								<h4>
									Dein Gewinn:
                                </h4>

								<div className={styles.lotteryPaymentWin}>
									Warengutschein im Wert von {data.amount} €
								</div>
							</div>

							<h2 className={styles.lotteryPaymentTitle}>Gewinn jetzt abholen und Gutschein zusenden:</h2>

							<FormWrapper
								noStyles={true}
								onSubmit={this.onSubmit}
							>

								<SelectField
									title="Anrede"
									name="salutation"
									placeholder="Wählen..."
									onChange={this.onChangeInput}
									value={salutation}
									required={true}
								>
									<option value="Herr">Herr</option>
									<option value="Frau">Frau</option>
								</SelectField>

								<InputField
									title="Vorname"
									name="firstName"
									placeholder="Erforderlich"
									onChange={this.onChangeInput}
									value={firstName}
									required={true}
								/>

								<InputField
									title="Nachname"
									name="lastName"
									placeholder="Erforderlich"
									onChange={this.onChangeInput}
									value={lastName}
									required={true}
								/>

								<InputField
									title="E-Mail Adresse"
									name="email"
									placeholder="Erforderlich"
									type="email"
									onChange={this.onChangeInput}
									value={email}
									required={true}
								/>

								<InputField
									title="Telefonnummer"
									name="phone"
									placeholder="Erforderlich"
									type="tel"
									onChange={this.onChangeInput}
									value={phone}
									required={true}
								/>

								<div className={styles.lotteryPaymentFormDivider}>
									Warengutschein bitte an diese Adresse senden:
								</div>

								<InputField
									title="Straße, Nr."
									name="address"
									placeholder="Erforderlich"
									onChange={this.onChangeInput}
									value={address}
									required={true}
								/>

								<div className={styles.lotteryPaymentSplitedFields}>
									<InputField
										title="PLZ"
										name="zipcode"
										placeholder="Erforderlich"
										onChange={this.onChangeInput}
										value={zipcode}
										required={true}
									/>

									<InputField
										title="Ort"
										name="district"
										placeholder="Erforderlich"
										onChange={this.onChangeInput}
										value={district}
										required={true}
									/>
								</div>

								<Checkbox
									name="terms"
									onChange={this.onChangeInput}
									checked={terms}
								>
									<React.Fragment>
										<span>Hiermit akzeptiere ich die </span>
										<Link route="LotteryParticipationConditionsRoute">
											<a target="_blank">Teilnahmebedingungen</a>
										</Link>
										<span> des Gewinnspiels: „Aktionsfinder der Woche“. </span>
										<Link route="LotteryParticipationConditionsRoute">
											<a target="_blank">Nähere Informationen</a>
										</Link>
										<span> </span>
										<Link route="PrivacyInfoRoute">
											<a target="_blank">Informationen zum Datenschutz.</a>
										</Link>
									</React.Fragment>
								</Checkbox>

								<div className="center" style={{ textAlign: 'center' }}>
									<Button
										theme="primary"
										size="xxl"
										type="submit"
										loading={loading}
									>
										Gewinn jetzt einlösen
									</Button>
								</div>

								<p>&nbsp;</p>
								<p>&nbsp;</p>
								<p>&nbsp;</p>

							</FormWrapper>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>

						<div>

							<PromotionsAggregatedByBrandsWidgetContainer
								title={"Bier in Aktion kaufen"}
								limit={7}
								productGroupSlug="bier"
								widgetKey={`lottery-info-promotion-brands-widget`}
								routeCallbk={this.routerCallbkPromotionsAggregatedByBrandsWidget}
							/>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								title={`Weitere Angebote`}
								widgetKey={`lottery-info-brand-widget`}
								limit={7}
								routeCallbk={this.routerCallbkBrandListWidget}
								orderBy="promotionCount"
								orderDir="desc"
							/>

						</div>

					</AppSideCol>
				</Row>

			</PageWrapper>

		);
	}

	private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[name]: value,
		});
	};

	/**
	 * Validate fields and checks terms checkbox.
	 */
	private checkFields(): boolean {

		const { email, phone, terms } = this.state;

		const errors = [];

		if (email && email !== '' && !/\S+@\S+\.\S+/.test(email)) {
			errors.push('Nicht gültiges E-Mail-Feld.');
		}

		if (phone && !/^\d+$/.test(phone)) {
			errors.push('Should be only numbers in phone field.');
		}

		if (!terms) {
			errors.push('Bitte akzeptiere unsere Teilnahmebedingungen.');
		}

		if (errors.length > 0) {
			for (let i = 0; i < errors.length; i++) {
				AlertService.error(
					'error-' + errors[i],
					errors[i],
				);
			}
			return false;
		}

		return true
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkPromotionsAggregatedByBrandsWidget = (item: IPromotionsAggregatedByBrandType) => {
		return {
			name: 'BrandDetailRoute',
			params: {
				brand: item.group.slug,
			},
		};
	}

    /**
	 * Defines the route the brand list widget should be routed towards
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		return {
			href: item.group.detailUrlPath,
		}
	}
}

function mapStateToProps(state: any) {
	return {
		accountReducer: state.accountReducer,
	}
}

export default connect(mapStateToProps, {})(LotteryPayment);
