import * as React from 'react';
import { ReactNode } from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import * as styles from './WidgetBox.scss';

interface IWidgetBoxMoreLinkProps {
	route: string,
	params: any,
	children: ReactNode | string;
}

class WidgetBoxMoreLink extends React.Component<IWidgetBoxMoreLinkProps> {
	public render () {
		const { route, params, children } = this.props;

		return (
			<Link
				route={route}
				params={params}
			>
				<a className={styles.moreLink}>
					{children}
				</a>
			</Link>
		);
	}
}

export default WidgetBoxMoreLink;
