import { DateTime } from 'luxon';
import * as React from 'react';

interface IDatespanProps {
  from: DateTime | string;
  to: DateTime | string;
  format?: 'short' | 'medium';
}

class Datespan extends React.PureComponent<IDatespanProps> {
  public static defaultProps = {
    format: 'short',
  };

  // TODO: use javascript date localization
  private dayArr = [
    '',
    'Mo',
    'Di',
    'Mi',
    'Do',
    'Fr',
    'Sa',
    'So',
  ];

  public render() {
    const { from, to, ...restProps }= this.props;

    if (!from || !to) {
      return <div>dfasddf</div>;
    }

    return (
      <div {...restProps}>
        {this.getValidDate()}
      </div>
    );
  }

  /**
   * Returns a string with the date that this leaflet is valid between
   *
   * @return {string}
   */
  private getValidDate(): string {
    const { from, to, format } = this.props;

    let fromDateObj = from;
    let toDateObj = to;

    if ( typeof from === 'string') {
      fromDateObj = DateTime.fromISO(from);
    }

    if ( typeof to === 'string') {
      toDateObj = DateTime.fromISO(to);
    }

    if (format === 'short') {
      return `${fromDateObj.setLocale('de').toFormat('dd.LL')}. - ${toDateObj.setLocale('de').toFormat('dd.LL.yy')}`;
    }

    if (format === 'medium') {
      const startDayName = this.dayArr[fromDateObj.toFormat('E')];
      const endDayName = this.dayArr[toDateObj.toFormat('E')];

      return `${startDayName}, ${fromDateObj.setLocale('de').toFormat('dd.LL')}. - ${endDayName}, ${toDateObj.setLocale('de').toFormat('dd.LL.yyyy')}`;
    }

    return '';
  }
}

export default Datespan;
