import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addBrandGroupedEntries,
	BRAND_GROUPED_FETCH_ENTRIES, setBrandGroupedErrors,
} from './brandGroupedActions';

/**
 * Fetch entry list
 */
function* fetchBrandGroupeds(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/brands/grouped/',
				params: action.params,
			} as IRequestObject);
		});

		yield put(addBrandGroupedEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setBrandGroupedErrors(action.queryKey, e));
	}
}

export function* brandGroupedSaga() {
	yield takeEvery(BRAND_GROUPED_FETCH_ENTRIES, fetchBrandGroupeds);
}
