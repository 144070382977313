import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as leafletPageActions from './leafletPageActions';
import { ILeafletPageType } from '../../types/ILeafletPageType';

export type LeafletPageAction = ActionType<typeof leafletPageActions>;

export interface ILeafletPageStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: ILeafletPageType };
	readonly queries: { [queryKey: string]: ILeafletPageType[] };
}

export function leafletPageReducer(
	state = baseInitialState as ILeafletPageStateType,
	action: LeafletPageAction,
) {
	const Reducer = new BaseReducer('leafletPageReducer', 'id');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
