import * as React from 'react';
import { IGeolocationType } from '../../types/IGeolocationType';
import Icon from '../Icon/Icon';
import { connect } from 'react-redux';
import { showLocationModal } from '../../store/Modal/modalActions';

interface ICurrentLocationWidgetProps {
	currentLocation: IGeolocationType;
	children?: React.ReactNode;
	showLocationModal?: () => void;
}

class CurrentLocationWidget extends React.PureComponent<ICurrentLocationWidgetProps, {}> {

	public render () {
		const { currentLocation, children, showLocationModal } = this.props;

		return (
			<React.Fragment>
				{( currentLocation ) ?
					<button type="button" className="location-info-button" onClick={showLocationModal}>
						<Icon icon="map-marker" /> {currentLocation.label} {children}
					</button>
                 : null
				}
			</React.Fragment>
		);
	}
}


function mapStateToProps (state: any) {
	return {};
}


function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		showLocationModal: () => {
			dispatch(showLocationModal());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentLocationWidget);