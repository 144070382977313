import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import AssortementPromotionsByVendor from '../App/modules/Promotion/components/AssortementPromotionsByVendor/AssortementPromotionsByVendor';
import {
	withTitlePostfix,
	withClickbait,
	pluralizerAssortementPromotions,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';


class AssortementPromotionsByVendorRoute extends BaseRoute {

	public static async getInitialProps(state: any) {
		const reduxData = await AssortementPromotionsByVendorRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<AssortementPromotionsByVendor
							vendorSlug={query.vendor}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		AssortementPromotionsByVendorRoute,
		(props: any) => `apv-${props.match.params.vendor}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''}  ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')}  ${routeMetadata.vendor.title} ${pluralizerAssortementPromotions(routeMetadata.totalItemsCount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.vendor.title.toUpperCase()} Sortimentsaktionen`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle ${routeMetadata.vendor.title.toUpperCase()} Sortimentsaktionen » kein Schnäppchen verpassen ✓ Rabatt sichern ✓ bares Geld sparen`, `✓ Hier erfahren Sie mehr!`),
		} as IRouteMetadataSetterType,
	),
);
