import * as React from 'react';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import ProductPromotionDiscountBarSkeleton from '../ProductPromotionDiscountBar/ProductPromotionDiscountBarSkeleton';
import * as styles from './ProductPromotionItemHorizontal.scss';
import PromotionImage from '../PromotionImage/PromotionImage';
import { IProductPromotionType } from '../../types/IProductPromotionType';

interface IProductPromotionItemHorizontalSkeletonProps {
	size: 's' | 'm' | 'l',
}

class ProductPromotionItemHorizontalSkeleton extends React.PureComponent<IProductPromotionItemHorizontalSkeletonProps, {}> {
	public static defaultProps = {
		size: 'm',
	};

	public render () {
		return (
			<span className={styles.productPromotionItem}>
                <PromotionImage promotion={{} as IProductPromotionType} className={styles.productPromotionItemImage} />

                <span className={styles.productPromotionItemContent}>
                    <span className={styles.productPromotionItemLabel}>
                        <SkeletonInline />
                    </span>

                    <ProductPromotionDiscountBarSkeleton />

                    <span className={styles.productPromotionValidTime}>
                        <SkeletonInline width={80}/>
                    </span>
                </span>

            </span>
		);
	}
}

export default ProductPromotionItemHorizontalSkeleton;
