import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionsAggregatedByProductGroupsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IPromotionsInShopAndProductGroupProps {
	shopSlug: SlugType;
	productGroupSlug: SlugType;
}

class PromotionsInShopAndProductGroup extends React.Component<IPromotionsInShopAndProductGroupProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {
		const { routeMetadata, productGroupSlug, shopSlug } = this.props;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridContainer
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								shopSlug={shopSlug}
								productGroupSlug={productGroupSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								onlyNearbyPromotions={false}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey={`ppcs-${shopSlug}-${productGroupSlug}-widget`}
								title={`Weitere Produktkategorien mit Aktionen`}
								shopSlug={shopSlug}
								exclude={productGroupSlug}
								placeholderLimit={5}
								routeCallbk={this.routerCallbkProductGroupListWidget}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, productGroupSlug } = this.props;

		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: routeMetadata ? routeMetadata.productGroup.title : '',
				route: 'ProductGroupDetailRoute',
				routeParams: {productGroup: productGroupSlug},
			} as IBreadcrumbItemType,
			{
				// Cuts page title to get shop address
				label: routeMetadata.pageTitle.slice(routeMetadata.pageTitle.indexOf('bei') + 3),
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {

		const { shopSlug } = this.props;

		return {
			name: 'PromotionsInShopAndProductGroupRoute',
			params: {
				productGroup: item.group.slug,
				shop: shopSlug,
			},
		};
	};
}

export default PromotionsInShopAndProductGroup;
