import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import DataStateService from '../../../../services/DataStateService';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import Button from '../../../../components/General/Button/Button';
import { getClickoutGatewayUrl } from '../../../../utils/clickoutGatewayGenerator';
import { IPromotionLeafletType } from '../../../Leaflet/types/IPromotionLeafletType';
import * as styles from './ProductPromotionDetailAction.scss';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import DataLayerService from '../../../Base/services/DataLayerService';

interface IProductPromotionDetailActionButtonsProps {
    promotion: IProductPromotionType;
    promotionSlug: SlugType;
}

class ProductPromotionDetailActionButtonsContainerInner extends React.Component<IProductPromotionDetailActionButtonsProps & IWithDataProps> {

    private getLeafletUrl() {
        const { data } = this.props;
        const promotionPageLeaflet: IPromotionLeafletType = (data && data[0]) ? data[0] : null;

        if (!promotionPageLeaflet || !promotionPageLeaflet.slug) {
            return null;
        }

        return `/l/${promotionPageLeaflet.slug}/#${((promotionPageLeaflet.promotionPage?.pageNumber || 0) + 1)}`;
    }

    public render() {
        const { promotionSlug, promotion, data } = this.props;

        const leafletUrl = this.getLeafletUrl();

        return (
            <>
                {(!leafletUrl) ? null : (
                    <Button
                        theme="primary"
                        size="l"
                        href={leafletUrl}
                    >
                        Im Prospekt ansehen
                    </Button>
                )}

                {!promotion.clickoutUrl ? null : (
                    <span className={(leafletUrl) ? styles.SecondActionButton : null}>
                        <Button
                            theme="default"
                            size="l"
                            href={getClickoutGatewayUrl(promotion.clickoutUrl)}
                            target="_blank"
                            onClick={this.onTrackClickout}
                            rel="nofollow noopener noreferrer"
                        >
                            Zum Angebot
                        </Button>
                    </span>
                )
                }
            </>
        );
    }

    /**
     * Tracks a click on the clickout url
     */
    private onTrackClickout = () => {
        const { promotion } = this.props;

        let promotionEventType = '';
        switch (promotion.type) {
            case 'new_price':
                promotionEventType = 'CLICKOUT_PRICE_PROMOTION';
                break;
            case 'product':
                promotionEventType = 'CLICKOUT_PRODUCT_PROMOTION';
                break;
            case 'assortment':
                promotionEventType = 'CLICKOUT_ASSORTMENT_PROMOTION';
                break;
            case 'voucher':
                promotionEventType = 'CLICKOUT_VOUCHER_PROMOTION';
                break;
        }

        if (promotionEventType) {
            const productCategories = (promotion?.product?.productGroups?.map((productGroup) => productGroup.slug) || []).join(',');
            const brandSlugs = (promotion?.brandSlugs || promotion?.brands?.map((brand) => brand.slug) || []).join(',');

            InternalTrackingService.track(promotionEventType, {
                promotionId: promotion.id,
                brand_slug:brandSlugs,
                product_category: productCategories,
                targetUrl: promotion.clickoutUrl,
            });

            const trackingParams = {
                promotionId: promotion.id,
                promotionTitle: promotion.fullDisplayName,
            } as { [key: string]: number | string };

            // add product
            if (promotion.product) {
                trackingParams.productId = promotion.product.id;
                trackingParams.productTitle = promotion.product.title;

                if (promotion.product.brand) {
                    trackingParams.brandId = promotion.product.brand.id;
                    trackingParams.brandTitle = promotion.product.brand.name;
                }
            }

            if (promotion.vendor) {
                trackingParams.vendorId = promotion.vendor.id;
                trackingParams.vendorTitle = promotion.vendor.name;
            }

            DataLayerService.push(promotionEventType, {
                ...trackingParams,
                brandSlug: brandSlugs,
                productCategory: productCategories,
            });
        }
    };
}


export default function ProductPromotionDetailActionButtonsContainer(props: IProductPromotionDetailActionButtonsProps) {
    const RenderComponent = withData(
        ProductPromotionDetailActionButtonsContainerInner,
        'leafletWidgetReducer',
        `promotion-detail-action-buttons-${props.promotion.slug}-${DataStateService.getStateKey()}`,
        {
            size: 1,
            promotionSlug: props.promotion.slug,
            onlyNearbyLeaflets: true,
        },
        {
            LoadingComponent: () => (
                <ProductPromotionDetailActionButtonsContainerInner
                    data={null}
                    promotionSlug={props.promotionSlug}
                    promotion={props.promotion}
                />
            ),
            ErrorComponent: (errorProps: any) => (
                <ProductPromotionDetailActionButtonsContainerInner
                    data={false}
                    promotionSlug={props.promotionSlug}
                    promotion={props.promotion}
                />
            ),
        },
    );

    return <RenderComponent {...props} />;
}
