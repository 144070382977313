import * as React from 'react';
import Icon from '../Icon/Icon';
import * as styles from './Alert.scss';
import CurrentLocationWidgetContainer from '../../containers/CurrentLocationWIdget/CurrentLocationWidgetContainer';
import { Animated } from 'react-animated-css';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';

interface IAlertProps {
	alert: IAlertType;
	onCloseAlert: (id: string) => void;
	onMarkAlertAsShown: (id: string) => void;
	showLeafletDiscountModal?: () => void;
}

class Alert extends React.PureComponent<IAlertProps, {}> {

	private closeTimeout: any;

	public componentDidMount (): void {
		const { alert, onMarkAlertAsShown } = this.props;

		this.closeTimeout = setTimeout(() => {
			this.onCloseAlert();
		}, ( ((alert && alert.timeoutInSeconds) ? alert.timeoutInSeconds : 5) * 1000 ));

		onMarkAlertAsShown(alert.id);
	}

	public componentWillUnmount (): void {
		this.clearTimeout();
	}

	public render () {
		const { alert } = this.props;

		const className = this.getLevelClassName();

		if (alert.shown) {
			return null;
		}

		if (isServer()) {
			return null;
		}

		return (
			<Animated
				animationIn="bounceInUp"
				animationOut="bounceOutUp"
				animationInDuration={1400}
				isVisible={true}
				animationOutDelay={350}
			>
				<div className={`${styles.alert} ${className} ${(alert.message || alert.id === 'location') ? '' : styles.alertSlim}`} onClick={this.handleClick}>
					<div className={styles.alertInner}>

						<Animated
							animationIn="bounceInLeft"
							animationOut="bounceOutDown"
							animationInDuration={2000}
							isVisible={true}
							animationInDelay={300}
						>

							<React.Fragment>

								<div className={styles.alertText}>

									<div className={styles.alertHeadline}>{alert.title}</div>

									{( !alert.message ) ? null :
										<div className={styles.alertContent}>
											{alert.message}
										</div>
									}

									{/*// TODO @Oleksandr: remove this from this alert and create a custom one, if this is used here*/}
									{( alert.id === 'location' ) ?
										<span className={styles.alertContent}>
											<CurrentLocationWidgetContainer>
												<a>&nbsp;ändern?</a>
											</CurrentLocationWidgetContainer>
										</span>
										: null
									}

								</div>

							</React.Fragment>

						</Animated>


						<Animated
							animationIn="fadeInRightBig"
							animationOut="fadeOutRightBig"
							animationInDuration={2000}
							animationOutDuration={1700}
							isVisible={true}
							animationInDelay={700}
						>
							<button type="button" className={styles.closeIcon} onClick={this.onCloseAlert}>
								<Icon icon="close" />
							</button>
						</Animated>

						{( !alert.level ) ? null :
							<React.Fragment>
								{( alert.icon ) ?
							   <span className={styles.alertIcon}>
								   <Icon icon={alert.icon} />
							   </span>
                 :
								 <img src={require(`./Affe-Msg-${alert.level}.png`)} />
								}
							</React.Fragment>
						}
					</div>

				</div>
				
			</Animated>
		);
	}

	/**
	 * Clear timeout function
	 */
	private clearTimeout = (): void => {
		if (this.closeTimeout) {
			clearTimeout(this.closeTimeout);
		}
	};

	/**
	 * Clicking on alert
	 */
	private handleClick = (e: Event) => {
		const { alert, showLeafletDiscountModal } = this.props;

		this.clearTimeout();

		this.onCloseAlert(e);

		// TODO @Oleksandr: Refactor this please to make sure the alert component is completly free from custom code.
		// Add a "onClick" method to the alert entries, and check here if it has "onClick" -> then trigger it (if you have questions let Simon know)
		if (alert.id.includes('discount-alert') && showLeafletDiscountModal) {
			showLeafletDiscountModal();
		}
	}

	/**
	 * Returns the correct level class for this alert
	 */
	private getLevelClassName () {
		const { alert } = this.props;

		switch (alert.level) {
			case 'error':
				return styles.alertError;
			case 'info':
				return styles.alertInfo;
			default:
				return styles.alertSuccess;
		}
	}

	/**
	 * Closes an alert on click
	 */
	private onCloseAlert = (e: Event | null = null) => {
		const { alert, onCloseAlert } = this.props;

		if (e) {
			e.stopPropagation();
		}

		onCloseAlert(alert.id);

		return false;
	};
}

export default Alert;
