/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { ILeafletType } from 'App/modules/Leaflet/types/ILeafletType';

export const MOST_USED_QUERIES_FETCH_ENTRIES = '@BasePipeline/mostUsedQueriesReducer_FETCH_ENTRIES';
export const MOST_USED_QUERIES_FETCH_ENTRY   = '@BasePipeline/mostUsedQueriesReducer_FETCH_ENTRY';
export const MOST_USED_QUERIES_ADD_ENTRIES = '@BasePipeline/mostUsedQueriesReducer_ADD_ENTRIES';
export const MOST_USED_QUERIES_ADD_ENTRY = '@BasePipeline/mostUsedQueriesReducer_ADD_ENTRY';

export const MOST_USED_QUERIES_SET_ERRORS   = '@BasePipeline/mostUsedQueriesReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchmostUsedQueriess (queryKey: string) {
	return {
		type: MOST_USED_QUERIES_FETCH_ENTRIES,
		queryKey,
	};
}

export function addmostUsedQueriesEntries (queryKey: string, entries: ILeafletType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: MOST_USED_QUERIES_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setmostUsedQueriesErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: MOST_USED_QUERIES_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
