import React from 'react';
import * as styles from './VendorAggregatedByCity.scss';

interface IVendorDataType {
    label: string,
    slug: string,
    count: number
}
interface IVendorAggregatedByCityPropTypes {
    data: IVendorDataType[];
    vendorSlug: string
}

const VendorAggregatedByCity = ({ data, vendorSlug }: IVendorAggregatedByCityPropTypes):any => {
    
    const cityList = data.map((city, index) => {

        return(
                <div 
                    key={city.slug + city.count}
                    className={`${styles.cityItemBlock}`}
                >
                    <a href={`/svst/${vendorSlug}/${city.slug}/`} className={`${styles.cityItem}`}>
                        {/* Wien ({city}) Märkte */}
                        {city.label} ({city.count} Märkte)
                    </a >

                </div>
        )
    });

    //TODO @Simon , pls add logic for displaying the correct post code instead of the hardcoded one.
    return (
        <React.Fragment>
            {cityList}
        </React.Fragment>
    )
}

export default VendorAggregatedByCity