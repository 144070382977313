import * as React from 'react';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import Button from '../../../../components/General/Button/Button';
import InfoScreenWrapper from '../../../../components/Layout/InfoScreenWrapper/InfoScreenWrapper';

interface IConfirmNewslettersProps {
	account: any;
}

class ConfirmNewsletters extends React.Component<IConfirmNewslettersProps> {

	public render () {
		const { account } = this.props;

		return (
			<PageWrapper hideLocation={true} fluid={true}>
				<InfoScreenWrapper>
					<H1 align="center"> 
						Newsletter Opt-In erfolgreich bestätigt
					</H1>

					<Button
						theme="primary"
						size="xxl"
						fullWidth={true}
						route={( account.isLoggedIn ) ? 'HomeRoute' : 'LoginRoute'}
					>
						{( account.isLoggedIn ) ? 'Zur Startseite' : 'Zum Login'}
					</Button>
					
				</InfoScreenWrapper>
			</PageWrapper>
		);
	}
}

export default ConfirmNewsletters;