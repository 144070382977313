import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionsAggregatedByBrandsWidget
	from '../../components/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidget';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';
import { getPromotionTypeApiFilterString } from '../../utils/fetchTypeHelper';
import DataStateService from '../../../../services/DataStateService';

interface IPromotionsAggregatedByBrandsWidgetContainerProps {
	title: string;
	widgetKey: string;

	promotionLabel?: string;
	promotionLabelPlural?: string;

	limit?: number;
	placeholderLimit?: number;
	page?: number;

	style?: IWidgetStyleType;
	orderBy?: 'promotionCount' | 'createdAt' | 'name';
	orderDir?: 'desc' | 'asc';

	fetchPriceProductPromotion?: boolean,
	fetchAssortementProductPromotions?: boolean,
	fetchProductPromotionGridGroupeds?: boolean,
	fetchVoucherProductPromotions?: boolean,

	routeCallbk: (item: IPromotionsAggregatedByBrandType) => { name: string, params: { [ key: string ]: any } },

	initialLetter?: string; // a letter to show only brands with this letter
	onlyNearby?: boolean;

	vendorSlug?: SlugType,
	shopSlug?: SlugType,
	referenceSlug?: SlugType,
	productGroupSlug?: SlugType,
	excludeSlug?: SlugType,
	brandSlug?: SlugType,
	stateSlug?: SlugType,
}

class PromotionsAggregatedByBrandsWidgetContainerInner extends React.Component<IPromotionsAggregatedByBrandsWidgetContainerProps & IWithDataProps> {
	public static defaultProps = {
		promotionLabel: 'Aktion',
		promotionLabelPlural: 'Aktionen',
	};

	public render () {
		const { data, widgetKey, title, promotionLabel, promotionLabelPlural, routeCallbk } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<PromotionsAggregatedByBrandsWidget
				title={title}
				aggregatedEntries={data}
				widgetKey={widgetKey}
				promotionLabel={promotionLabel}
				promotionLabelPlural={promotionLabelPlural}
				routeCallbk={routeCallbk}
			/>
		);
	}
}

export default function PromotionsAggregatedByBrandsWidgetContainer (props: IPromotionsAggregatedByBrandsWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;

	const promotionTypes = getPromotionTypeApiFilterString(props);

	const RenderComponent = withData(
		PromotionsAggregatedByBrandsWidgetContainerInner,
		'promotionsAggregatedByBrandsWidgetReducer',
		`promotions-aggregated-by-brands-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			page: props.page,
			size: props.limit,
			sort: `${props.orderBy},${props.orderDir}`,

			promotion_type: promotionTypes,

			initial: props.initialLetter,

			store_vendor: props.vendorSlug,
			store: props.shopSlug,
			product_group: props.productGroupSlug,
			reference: props.referenceSlug,
			exclude: props.excludeSlug,
			brand: props.brandSlug,
			state: props.stateSlug,
			nearby_only: props.onlyNearby,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={( props.placeholderLimit ) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={( props.placeholderLimit ) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		},
	);

	return <RenderComponent {...props} />;
}
