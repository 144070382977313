import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IVendorType } from '../../types/IVendorType';
import VendorListGrouped from '../../components/VendorListGrouped/VendorListGrouped';
import VendorListGroupedSkeleton from '../../components/VendorListGrouped/VendorListGroupedSkeleton';
import Pagination from '../../../../components/Layout/Pagination/Pagination';


interface IVendorListGroupedContainerProps extends IWithDataProps {
	queryKey?: string;
	limit?: number;
	orderBy?: 'name';
	orderDir?: 'asc' | 'desc';
	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class VendorListGroupedContainerInner extends React.Component<IVendorListGroupedContainerProps> {
	public render() {
		const { data, fetchMore, metadata, queryKey, ...restProps } = this.props;

		return (
			<Pagination
				fetchMore={fetchMore}
				metadata={metadata}
				queryKey={queryKey}
				LoaderComponent={
					<VendorListGroupedSkeleton />
				}
			>
				<VendorListGrouped
					vendors={data as IVendorType[]}
					{...restProps}
				/>
			</Pagination>
		);
	}
}

export default function VendorListGroupedContainer(props: IVendorListGroupedContainerProps) {
	const limit = (props.limit) ? props.limit : 20;
	const orderBy = (props.orderBy) ? props.orderBy : 'name';
	const orderDir = (props.orderDir) ? props.orderDir : 'asc';

	const RenderComponent = withData(
		VendorListGroupedContainerInner,
		'vendorReducer',
		`vendorListing-${props.queryKey}`,
		{
			size: limit,
			sort: `${orderBy},${orderDir}`,
		},
		{
			LoadingComponent: () => (<VendorListGroupedSkeleton />)
		}
	);

	return <RenderComponent {...props} />;
}
