import React, { Component } from 'react';
import * as styles from './PromotionBookmark.scss';
import Icon from '../../../Base/components/Icon/Icon';
import { withRouter, RouterProps } from 'react-router';

class PromotionBookmark extends Component<RouterProps> {
    render() {
        return (
            <button className={styles.promotionBookmark} onClick={this.onBookmarkClick}>
                <Icon icon="shoppinglist-add" />
            </button>
        );
    }

    private onBookmarkClick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.props.history.push('/app/');
    }
}

export default withRouter(PromotionBookmark);
