/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IVendorType } from '../../../Vendor/types/IVendorType';

export const VENDOR_BOOKMARK_FETCH_ENTRIES = '@BasePipeline/vendorBookmarkReducer_FETCH_ENTRIES';
export const VENDOR_BOOKMARK_ADD_ENTRIES = '@BasePipeline/vendorBookmarkReducer_ADD_ENTRIES';
export const VENDOR_BOOKMARK_ADD_ENTRY = '@BasePipeline/vendorBookmarkReducer_ADD_ENTRY';
export const VENDOR_BOOKMARK_RERENDER = '@BasePipeline/vendorBookmarkReducer_RERENDER';

export const VENDOR_BOOKMARK_SET_ERRORS   = '@BasePipeline/vendorBookmarkReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function rerenderVendorBookmarks () {
	return {
		type: VENDOR_BOOKMARK_RERENDER,
	};
}

export function refetchVendorBookmarks () {
	return {
		type: VENDOR_BOOKMARK_FETCH_ENTRIES,
		queryKey: 'vendorBookmarkListing',
		options: {
			forceRefetch: true,
			queryMergeMode: 'replace',
		},
	};
}

export function fetchVendorBookmarks (queryKey: string) {
	return {
		type: VENDOR_BOOKMARK_FETCH_ENTRIES,
		queryKey: 'vendorBookmarkListing',
	};
}

export function addVendorBookmarkEntries (queryKey: string, entries: IVendorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_BOOKMARK_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setVendorBookmarkErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_BOOKMARK_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
