import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import ThemeworldListWidgetContainer
	from '../../../Themeworld/containers/ThemeworldListWidget/ThemeworldListWidgetContainer';
import { IThemeworldWidgetType } from '../../../Themeworld/types/IThemeworldWidget';
import ProductPromotionGridGroupedContainer
	from '../../containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import PromotionsAggregatedByStatesWidgetContainer
	from '../../containers/PromotionsAggregatedByStatesWidget/PromotionsAggregatedByStatesWidgetContainer';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import { IPromotionsAggregatedByStateType } from '../../types/IPromotionsAggregatedByStateType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IVouchersInStateProps {
	stateSlug: SlugType;
}

class VouchersInState extends React.Component<IVouchersInStateProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {
		const { routeMetadata, stateSlug } = this.props;

		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridGroupedContainer
								enablePagination={true}
								stateSlug={stateSlug}
								groupTitle={`%count% %title%-Gutschein`}
								groupTitlePlural={`%count% %title%-Gutscheine`}
								moreLabel={`Alle %title% Gutscheine im Bundesland ${stateName}`}
								moreRouteCallbk={this.routerCallbkPromotionListWidget}
								gridKey={routeMetadata.queryKey}
								fetchVoucherProductPromotions={true}
								pushRouteMetadata={true}
								groupBy="vendor"
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridGroupedContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByStatesWidgetContainer
								widgetKey={`${routeMetadata.queryKey}-state-widget`}
								title="Gutscheine in anderen Bundesländern"
								routeCallbk={this.routerCallbkStateListWidget}
								fetchVoucherProductPromotions={true}
								promotionLabel="Gutschein"
								promotionLabelPlural="Gutscheine"
							/>

							
							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>


							<ThemeworldListWidgetContainer
								title="Themenwelten"
								widgetKey={`themeworlds-widget`}
								routeCallbk={this.routerCallbkThemeworldListWidget}
								btnLabel="Alle Themenwelten"
								btnRoute="ThemeworldListingRoute"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Gutscheine',
				route: 'VoucherListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.state.title: '',
			} as IBreadcrumbItemType,
		];
	}


	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: IProductPromotionGroupedType) => {
		const { stateSlug } = this.props;

		return {
			name: 'VouchersByVendorInStateRoute',
			params: {
				state: stateSlug,
				vendor: item.group.slug,
			},
		};
	};

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkStateListWidget = (item: IPromotionsAggregatedByStateType) => {
		return {
			name: 'VouchersInStateRoute',
			params: {
				state: item.group.slug,
			},
		};
	};

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkThemeworldListWidget = (item: IThemeworldWidgetType) => {
		return {
			name: 'ThemeworldDetailRoute',
			params: {
				themeworld: item.slug,
			},
		};
	}
}

export default VouchersInState;
