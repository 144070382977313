import * as React from 'react';
import Checkbox from '../../../../components/Layout/Checkbox/Checkbox';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import * as styles from './FilterTagsListing.scss';

export interface IFilterTagsListingSkeletonProps {
    title: string;
}

class FilterTagsListingSkeleton extends React.Component<IFilterTagsListingSkeletonProps, {}> {
	public static defaultProps = {};

	public render() {
        const limitArr = Array.from(Array(2).keys());

        const { title } = this.props;

		return (
            <div className={styles.filterTagsContainer}>
                <div className={styles.title}>{ title }</div>
                <div className={styles.filterTagsWrapper}>
                    {
                        limitArr.map((item, idx) => (
                            <Checkbox
                                    key={`filter-tag-skeleton-${idx}`}
                                    style="v3"
                                    name={`field-tag-skeleton-${idx}`}
                                    checked={false}
                                    type="radio"
                                    onChange={(k: string, checked: boolean) => null}
                                >
                                <SkeletonInline />
                            </Checkbox>
                        ))
                    }
                </div>
            </div>
		);
	}
}

export default FilterTagsListingSkeleton;
