import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import MobileAppWidget from '../../../../components/Layout/MobileAppWidget/MobileAppWidget';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer
	from '../../containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import PromotionsAggregatedByStatesWidgetContainer
	from '../../containers/PromotionsAggregatedByStatesWidget/PromotionsAggregatedByStatesWidgetContainer';
import { IPromotionsAggregatedByStateType } from '../../types/IPromotionsAggregatedByStateType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IPromotionsInProductGroupAndCityProps {
	citySlug: SlugType;
	productGroupSlug: SlugType;
}

class PromotionsInProductGroupAndCity extends React.Component<IPromotionsInProductGroupAndCityProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, productGroupSlug, citySlug } = this.props;

		const cityName = (routeMetadata && routeMetadata.city && routeMetadata.city.title) ? routeMetadata.city.title : citySlug;
		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		if (!routeMetadata) {
			return;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<section>
								<ProductPromotionGridContainer
									limit={20}
									enablePagination={true}
									orderBy="createdAt"
									orderDir="desc"
									citySlug={citySlug}
									productGroupSlug={productGroupSlug}
									gridKey={routeMetadata.queryKey}
									pushRouteMetadata={true}
									onlyNearbyPromotions={false}
									contentBreaks={{
										2: (
											<AdItem
												mobileAdId={4403480}
												maxHeight={250}
												type="content"
												title="Mobile Rectangle 1"
											/>
										),
										6: (
											<AdItem
												mobileAdId={4403479}
												maxHeight={250}
												type="content"
												title="Mobile Rectangle 2"
											/>
										)
									}}
								>
									<NoDataError />
								</ProductPromotionGridContainer>
							</section>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey={`${routeMetadata.queryKey}-product-group-widget`}
								title={`Weitere Produktkategorien in ${cityName}`}
								citySlug={citySlug}
								limit={100}
								exclude={productGroupSlug}
								placeholderLimit={5}
								routeCallbk={this.routerCallbkProductGroupListWidget}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<PromotionsAggregatedByStatesWidgetContainer
								title={`${productGroupName} Aktionen in den Bundesländer`}
								widgetKey={`${routeMetadata.queryKey}-state-widget`}
								productGroupSlug={productGroupSlug}
								routeCallbk={this.routerCallbkStateListWidget}
							/>

							<MobileAppWidget />

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.productGroup.title : '',
				route: 'ProductGroupDetailRoute',
				routeParams: {productGroup: this.props.productGroupSlug},
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.city.title : '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the brand list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkStateListWidget = (item: IPromotionsAggregatedByStateType) => {
		const { productGroupSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndStateRoute',
			params: {
				productGroup: productGroupSlug,
				state: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {  // TODO: remove this text) => {
		const { citySlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndCityRoute',
			params: {
				city: citySlug,
				productGroup: item.group.slug, // TODO: remove this comment
			},
		};
	}
}

export default PromotionsInProductGroupAndCity;
