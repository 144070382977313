import * as React from 'react';
import { ReactNode } from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import NearestShopWidget from '../../components/NearestShopWidget/NearestShopWidget';
import { IShopType } from '../../types/IShopType';
import DataStateService from '../../../../services/DataStateService';
import NearestShopWidgetContainerSkeleton from './NearestShopWidgetContainerSkeleton';

interface INearestShopWidgetContainerProps {
	title?: string;
	widgetKey: string;
	limit?: number;

	lng?: number;
	lat?: number;
	radius?: number;

	childComponentRenderer: (nearestShop: IShopType) => ReactNode, // a method returning a react component we can use inside this one, that get's the full nearest shop parameter as well

	setNearestShop?: (nearestShop: IShopType) => void,

	promotionSlug?: SlugType; // ID of vendor
	vendorSlug?: SlugType; // ID of vendor
	children?: ReactNode;
}

class NearestShopWidgetContainerInner extends React.Component<INearestShopWidgetContainerProps & IWithDataProps> {
	public render() {
		const { title, data, widgetKey, childComponentRenderer, children } = this.props;

		if (!data || !data[0]) {
			return children;
		}

		return (
			<React.Fragment>
				<NearestShopWidget
					title={title}
					shop={data[0]}
					widgetKey={widgetKey}
				/>
				<React.Fragment>
					{children}
					{(childComponentRenderer) ? <React.Fragment>{childComponentRenderer(data[0])}</React.Fragment> : null}
				</React.Fragment>
			</React.Fragment>
		);
	}
}

export default function NearestShopWidgetContainer(props: INearestShopWidgetContainerProps) {
	const limit = (props.limit) ? props.limit : 1;

	const radius = (props.radius) ? props.radius : 50000;

	const RenderComponent = withData(
		NearestShopWidgetContainerInner,
		'nearestShopReducer',
		`nearest-shop-widget-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			lng: props.lng,
			lat: props.lat,
			radius,
			size: limit,

			promotion: props.promotionSlug,
			store_vendor: props.vendorSlug,
		},
		{
			LoadingComponent: () => <NearestShopWidgetContainerSkeleton />
		}
	);

	return <RenderComponent {...props} />;
}
