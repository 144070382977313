import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as promotionworldActions from './promotionworldActions';
import { IPromotionworldType } from '../../types/IPromotionworldType';

export type PromotionworldAction = ActionType<typeof promotionworldActions>;


export interface IPromotionworldStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IPromotionworldType };
	readonly queries: { [queryKey: string]: IPromotionworldType[] };
}

export function promotionworldReducer(
	state = baseInitialState as IPromotionworldStateType,
	action: PromotionworldAction,
) {
	const Reducer = new BaseReducer('promotionworldReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTIONentryMap_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
