import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionsAggregatedByProductGroupsWidgetSkeleton
	from '../../components/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregateByProductGroupsWidgetSkeleton';
import PromotionsAggregatedByProductGroupsWidget
	from '../../components/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidget';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';
import { getPromotionTypeApiFilterString } from '../../utils/fetchTypeHelper';
import DataStateService from '../../../../services/DataStateService';

interface IPromotionsAggregatedByProductGroupsWidgetContainerProps {
	title: string;
	widgetKey: string;
	radius?: number;

	promotionLabel?: string;
	promotionLabelPlural?: string;

	limit?: number;
	placeholderLimit?: number;
	page?: number;

	style?: IWidgetStyleType;
	orderBy?: 'promotionCount'
	orderDir?: 'desc';

	onlyNearby?: boolean;

	fetchPriceProductPromotion?: boolean,
	fetchAssortementProductPromotions?: boolean,
	fetchProductPromotionGridGroupeds?: boolean,
	fetchVoucherProductPromotions?: boolean,

	routeCallbk: (item: IPromotionsAggregatedByProductGroupType) => { href?: string, name?: string, params?: { [key: string]: any } },

	moreLabel?: string,
	moreRoute?: string;

	vendorSlug?: SlugType,
	shopSlug?: SlugType,
	shoppingCenterSlug?: SlugType,
	stateSlug?: SlugType,
	referenceSlug?: SlugType,
	citySlug?: SlugType,
	parentSlug?: SlugType,
	exclude?: SlugType,
}

class PromotionsAggregatedByProductGroupsWidgetContainerInner extends React.Component<IPromotionsAggregatedByProductGroupsWidgetContainerProps & IWithDataProps> {
	public static defaultProps = {
		promotionLabel: 'Aktion',
		promotionLabelPlural: 'Aktionen',
	};

	public render() {
		const { data, widgetKey, title, promotionLabel, style, promotionLabelPlural, routeCallbk, moreLabel, moreRoute } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<PromotionsAggregatedByProductGroupsWidget
				title={title}
				aggregatedEntries={data as IPromotionsAggregatedByProductGroupType[]}
				widgetKey={widgetKey}
				promotionLabel={promotionLabel}
				promotionLabelPlural={promotionLabelPlural}
				style={style}
				routeCallbk={routeCallbk}
				moreRoute={moreRoute}
				moreLabel={moreLabel}
			/>
		);
	}
}

export default function PromotionsAggregatedByProductGroupsWidgetContainer(theProps: IPromotionsAggregatedByProductGroupsWidgetContainerProps) {

	const props = Object.assign({
		limit: 5,
		radius: 50000,
	}, theProps,
	);

	const promotionTypes = getPromotionTypeApiFilterString(props);
	const RenderComponent = withData(
		PromotionsAggregatedByProductGroupsWidgetContainerInner,
		'promotionsAggregatedByProductGroupsWidgetReducer',
		`promotions-aggregated-by-product-groups-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			page: props.page,
			size: props.limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,
			radius: props.radius,

			nearby_only: props.onlyNearby,

			promotion_type: promotionTypes,

			store_vendor: props.vendorSlug,
			store: props.shopSlug,
			state: props.stateSlug,
			city: props.citySlug,
			shopping_center: props.shoppingCenterSlug,
			exclude: props.exclude,
		},
		{
			LoadingComponent: PromotionsAggregatedByProductGroupsWidgetSkeleton,

			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : props.limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		},
	);

	return <RenderComponent {...props} />;
}
