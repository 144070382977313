import * as React from 'react';
import * as styles from './PromotionworldGridGroup.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import PromotionworldGridItemSkeleton from '../PromotionworldGridItem/PromotionworldGridItemSkeleton';

interface IPromotionworldGridGroupSkeletonProps {}

class PromotionworldGridGroupSkeleton extends React.Component<IPromotionworldGridGroupSkeletonProps, {}> {
	public static defaultProps = { };

	public render () {
		const items = Array.from(Array(4).keys());

		return (
			<div>
				<div className={styles.promotionworldGroupWrapper}>
					<div className={`${styles.promotionworldGroup}`}>
						{items.map((item) => (
						<div className={`${styles.promotionworldGroupItem}`} key={`product-grid-item-${item}`}>
							<div className={styles.promotionworldGroupItemInner}>
							<PromotionworldGridItemSkeleton />
							</div>
						</div>
						)
						)}
					</div>
				</div>
	
				<div className={`${styles.promotionworldGridGroupedMoreItem}`}>
					<button className={styles.promotionworldGridGroupedMore}>
					<SkeletonInline />
					</button>
			  	</div>
		  	</div>
		);
	}
}

export default PromotionworldGridGroupSkeleton;
