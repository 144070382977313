import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import DataStateService from '../../../../services/DataStateService';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import ShopListWidget from '../../components/ShopListWidget/ShopListWidget';
import ShopListWidgetLeaflet from '../../components/ShopListWidgetLeaflet/ShopListWidgetLeaflet';
import ShopListWidgetLeafletSkeleton from '../../components/ShopListWidgetLeaflet/ShopListWidgetLeafletSkeleton';

interface IShopListWidgetContainerProps {
	title?: string;
	widgetKey: string;
	limit?: number;
	style?: IWidgetStyleType;
	orderBy?: 'relevance' | 'distance';
	orderDir?: 'asc' | 'desc';
	widgetType?: "default" | "leaflet"

	lng?: number;
	lat?: number;
	radius?: number;

	children?: React.ReactChild;

	exclude?: SlugType; // ID to exclude from this
	excludeVendorSlug?: SlugType;
	shopping_center_id?: SlugType; // ID of shopping center
	vendorSlug?: SlugType; // ID of vendor
	shopSlug?: SlugType; // ID of shop
	brandSlug?: SlugType; // ID of brand
	productGroupSlug?: SlugType; // ID of product_group
	citySlug?: SlugType; // ID of vendor
	leafletSlug?: SlugType;
}

class ShopListWidgetContainerInner extends React.Component<IShopListWidgetContainerProps & IWithDataProps> {
	public render() {
		const { title, data, style, widgetKey, widgetType, children } = this.props;

		const dataWithExcluded = this.getEntriesWithExcluded(data);

		if (dataWithExcluded.length < 1) {
			return null;
		}

		if (widgetType === "leaflet") {

			if (dataWithExcluded.length > 0 && children) {
				return children;
			} else if (dataWithExcluded.length === 0) {
				return null;
			}

			return (
				<ShopListWidgetLeaflet
					shops={dataWithExcluded}
					widgetKey={widgetKey}
				/>
			);
		}

		return (
			<ShopListWidget
				title={title}
				shops={dataWithExcluded}
				style={style}
				widgetKey={widgetKey}
			/>
		);
	}

	/**
	 * Cleans up our entries and makes sure the excluded entry is not in there
	 *
	 * @param {IShopWidgetType} entries
	 *
	 * @return {IShopWidgetType}
	 */
	private getEntriesWithExcluded(entries: []) {
		const { limit, exclude } = this.props;

		// now try to filter it out
		return entries.filter((entry: any, indx: number) => {

			if (exclude && entry.group.slug === exclude) {
				return false;
			}

			// if we are over our limit -> also return false (this might be because, we don't have the excludee in our data set,
			// but to make sure we have the correct amount of data even after removing the excludee, we have added +1 to limit on fetch
			if (limit && indx > limit) {
				return false;
			}

			// otherwise return true and add it to our results
			return true;
		});
	}
}

export default function ShopListWidgetContainer(props: IShopListWidgetContainerProps) {
	let limit = (props.limit) ? props.limit : 5;
	const orderBy = (props.orderBy) ? props.orderBy : 'distance';

	if (props.exclude) {
		limit++;
	}

	const RenderComponent = withData(
		ShopListWidgetContainerInner,
		'shopWidgetReducer',
		`shop-list-widget-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			lng: props.lng,
			lat: props.lat,
			radius: props.radius,
			size: limit,
			orderBy,
			orderDir: props.orderDir,
			exclude: props.exclude,
			exclude_vendor: props.excludeVendorSlug,
			store: props.shopSlug,
			store_vendor: props.vendorSlug,
			shopping_center: props.shopping_center_id,
			product_group: props.productGroupSlug,
			brand: props.brandSlug,
			city: props.citySlug,
			leaflet: props.leafletSlug,
		},
		{
			LoadingComponent: () => {

				if (props.widgetType === "leaflet") {
					return null;
					return <ShopListWidgetLeafletSkeleton />
				}
				return (
					<WidgetBoxSkeleton
						rows={limit}
						type="item"
						widgetKey={props.widgetKey}
						showValues={{
							title: true,
							subtitle: true,
							special: true,
							extra: true,
						}}
					/>
				)
			},
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: true,
						special: true,
						extra: true,
					}}
				/>
			),
		},
	);

	return <RenderComponent {...props} />;
}
