import * as React from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import * as styles from '../Menu/DropDown.scss';

interface IDropDownItemProps {
    title?: string;
    routeLink?: string;
    routeHref?: string;
    onClick?: (value?: any) => void;
    onCloseMenu?: () => void;
    children?: React.ReactNode;
    visible: boolean;
    href?: string;
    isCategory?: boolean; // Shows the link to another menu
    categoryData?: {};
    onlyMobile?: boolean;
}

class DropDownItem extends React.Component<IDropDownItemProps, {}> {
    public static defaultProps = {
        isCategory: false,
        title: '',
        routeLink: 'HomeRoute',
        visible: true,
        onlyMobile: false,
    };

    public render() {
        const { title, routeLink, onClick, children, isCategory, visible, onlyMobile, href, routeHref } = this.props;

        let style = styles.menuGroupItem;

        if (isCategory) {
            style += ` ${styles.menuGroupItemCategory}`;
        }

        if (onlyMobile) {
            style += ` ${styles.menuGroupItemMobile}`;
        }

        if (!visible) {
            return null;
        }

        if (onClick) {
            return (
                <a className={style} onClick={this.handleClick}>
                    <span className={styles.menuGroupItemTitle}>
                        {title}

                        {children}
                    </span>
                </a>
            );
        }

        if (href) {
            return (
                <a className={style} href={href} target="_blank" rel="nofollow noopener noreferrer" onClick={this.linkHandleClick}>
                    <span className={styles.menuGroupItemTitle}>
                        {title}
                        {children}
                    </span>
                </a>
            );
        }

        const linkProps = {};

        if (routeLink) {
            linkProps.route = routeLink;
        }

        if (routeHref) {
            linkProps.href = routeHref;
        }

        return (
            <Link {...linkProps} activeClassName={'active'}>
                <a className={style} onClick={this.handleClick}>
                    <span className={styles.menuGroupItemTitle}>
                        {title}

                        {children}
                    </span>
                </a>
            </Link>
        );
    }

    /**
     * Handles a click on item
     */
    private linkHandleClick = (event?: any) => {
        // if (event) {
        // 	event.preventDefault();
        // }

        this.handleClick(event);
    };

    /**
     * Handles a click on item
     */
    private handleClick = (event?: any) => {
        const { onClick, categoryData, onCloseMenu } = this.props;

        if (onClick) {
            onClick(categoryData);
        }

        if (onCloseMenu) {
            onCloseMenu();
        }
    };
}

export default DropDownItem;
