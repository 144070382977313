import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as mixedSearchActions from './mixedSearchActions';
import { SearchMetaDataType } from './mixedSearchActions';
import MixedSearchBaseReducer from './MixedSearchBaseReducer';

export type mixedSearchAction = ActionType<typeof mixedSearchActions> & { searchMetaData: SearchMetaDataType, searchTerm: string };

export interface ImixedSearchStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: any };
	readonly queries: { [queryKey: string]: any[] };
	readonly searchMetaData: SearchMetaDataType;
	readonly currentSearchTerm: string;
}

function addOrUpdateFilterCounts(currentSearchMetaData: SearchMetaDataType, currentSearchTerm: string, action: mixedSearchAction): SearchMetaDataType {
	// if we don't have any search meta data -> just use the new ones without a change
	if (!currentSearchMetaData || currentSearchTerm !== action.searchTerm) {
		return action.searchMetaData;
	}

	// loop through current keys and just replace the count
	const newSearchMetaData = {};
	for (const key of Object.keys(currentSearchMetaData)) {
		const prevFilterGroupData = currentSearchMetaData[key];
		const nextFilterGroupData = (action.searchMetaData && action.searchMetaData[key]) ? action.searchMetaData[key] : {};

		newSearchMetaData[key] = [];

		// generate a map of all new count values (we need a way to associate slug with count value) - we put an object in
		// to make sure we can use it later for other stuff as well, if we need to update something apart from the count value
		const newGroupItemObjectMap = {};
		for (let i = 0; i < nextFilterGroupData.length; i++) {
			newGroupItemObjectMap[nextFilterGroupData[i].slug] = {
				count: nextFilterGroupData[i].count,
			};
		}

		// now loop through the children and make sure to add everything
		for (let i = 0; i < prevFilterGroupData.length; i++) {
			const newGroupItemDataObject = newGroupItemObjectMap[prevFilterGroupData[i].slug];

			newSearchMetaData[key].push({
				...prevFilterGroupData[i],
				count: ( newGroupItemDataObject?.count ) ? newGroupItemDataObject.count : 0,
			});
		}

	}

	return newSearchMetaData;
}

export function mixedSearchReducer(
	state = baseInitialState as ImixedSearchStateType,
	action: mixedSearchAction,
) {
	const Reducer = new MixedSearchBaseReducer('mixedSearchReducer', 'slug');

	return Reducer.extend(state, action, undefined, (state: ImixedSearchStateType, action: any, baseReducerContext?: BaseReducer | null) => {
		if (action.type === '@BasePipeline/mixedSearchReducer_ADD_ENTRIES') {
			const { searchMetaData } = action;

			const oldEntryArr = state.queries[action.queryKey] || [];
			const newEntryArr = oldEntryArr.concat(action.entries);

			const newSearchMetaData = addOrUpdateFilterCounts(state.searchMetaData, state.currentSearchTerm, action);

			return { ...state, searchMetaData: newSearchMetaData, searchResults: newEntryArr, currentSearchTerm: action.searchTerm } as ImixedSearchStateType;
		}

		return state;
	});
}
