import * as React from 'react';
import Icon from '../../../../modules/Base/components/Icon/Icon';
import * as styles from './ProductPromotionDetailAction.scss';


interface IProductPromotionDetailActionBookmarkProps {
	isActive: boolean;
}


class ProductPromotionDetailActionBookmark extends React.Component<IProductPromotionDetailActionBookmarkProps, {}> {

	public render () {
        const { isActive } = this.props;

        if (isActive) {
	        return (
		        <React.Fragment>
			        <Icon icon="shoppinglist-filled" className={styles.ActionIcon} />
			        <span>Von Einkaufsliste entfernen</span>
		        </React.Fragment>
	        );
        }

		return (
			<React.Fragment>
				<Icon icon="shoppinglist-add" className={styles.ActionIcon} />
				<span>Auf meine Einkaufsliste</span>
			</React.Fragment>
		);
    }
}

export default ProductPromotionDetailActionBookmark;
