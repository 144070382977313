import * as React from 'react';
import LeafletGridSkeleton from '../../../../../modules/Leaflet/components/LeafletGrid/LeafletGridSkeleton';
import NewsletterSignupComponent from '../../../../../modules/Newsletter/components/Newsletter/NewsletterSignupComponent';
import ContentBox from '../../../../Layout/PageWrapper/ContentBox';
import AppPromotionBanner from '../../../../../modules/Base/AppPromotionBanner/AppPromotionBanner';
import MixedEntityGridSkeleton from '../../../../../modules/MixedEntity/components/MixedEntityGrid/MixedEntityGridSkeleton';
import * as styles from './HomepageGrid.scss';
import SkeletonBlock from '../../../../Layout/Skeleton/SkeletonBlock';

interface IHomepageGridSkeletonProps {
	title?: string;
}
class HomepageGridSkeleton extends React.Component<IHomepageGridSkeletonProps, {}> {

	public render() {
		return (
			<div className={styles.homepageGridWrapper}>

				<SkeletonBlock style={{paddingBottom: '48%'}} />

                <ContentBox
					nested={true}
                >
                    <LeafletGridSkeleton
						title="Aktuelle Prospekte"
                    	gridKey="homepage-currentLeaflets-skeleton"
                        columns="5"
						limit={5}
                        boxed={true}
                        nested={true}
                        moreLabel="Alle Prospekte"
                        moreRoute="LeafletListingRoute"
						mobileScrollable={true}
                    />
                </ContentBox>

				<ContentBox>
					<AppPromotionBanner />
				</ContentBox>

				<MixedEntityGridSkeleton
					limit={5}
					columns='5'
					mobileScrollable={true}
					title='Tipp des Tages ...'
				/>

				<MixedEntityGridSkeleton
					limit={5}
					columns='5'
					mobileScrollable={true}
					title='Top ... Aktionen'
				/>

				<ContentBox
					nested={true}
                >
                    <LeafletGridSkeleton
						title="Alle ... Prospekte"
                    	gridKey="homepage-leafletsByIndustry-skeleton"
                        columns="5"
						limit={5}
                        boxed={true}
                        nested={true}
                        moreLabel="Alle ... Prospekte"
                        moreRoute="LeafletsInIndustryRoute"
						mobileScrollable={true}
                    />
                </ContentBox>

	            <ContentBox>
                    <NewsletterSignupComponent fullWidth={true} />
	            </ContentBox>

				{/* TODO: Finish the Skeleton if necessary */}
            </div>
		);
	}
}

export default HomepageGridSkeleton;


