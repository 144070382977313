import * as React from 'react';
import Icon from '../../../Base/components/Icon/Icon';
import { IShoppingNoteStateType } from '../../store/ShoppingNote/shoppingNoteReducer';
import * as styles from './ShoppingNote.scss';

export interface IShoppingNoteProps extends IShoppingNoteStateType {
	onSaveShoppingNote: (note: string) => void;
	onFetchShoppingNote: () => void;
	state?: 'saved' | 'unsaved' | 'saving' | 'error' | null;
	error?: string;
	loaded?: boolean;
}

export interface IShoppingNoteState {
	noteValue: string,
}

class ShoppingNote extends React.Component<IShoppingNoteProps, IShoppingNoteState> {
	public constructor (props: IShoppingNoteProps) {
		super(props);

		this.state = {
			noteValue: props.note,
		};
	}

	public componentDidUpdate (prevProps: Readonly<IShoppingNoteProps>): void {
		if (prevProps.note !== this.props.note) {
			this.setState({
				noteValue: this.props.note,
			});
		}
	}

	public render () {
		const { loaded } = this.props;
		const { noteValue } = this.state;

		if (!loaded) {
			return (
				<div className={styles.NoteWrapper}>
					<textarea
						placeholder="..."
						className={styles.NoteFormField}
					/>
						<span className={styles.NoteSavingState}>
							<span className={styles.NoteSavingStateUnsaved}>
								Lade... <Icon icon="grid" />
							</span>
						</span>
				</div>
			);
		}

		return (
			<div className={styles.NoteWrapper}>
				<textarea
					onChange={this.changeTextinput}
					placeholder="Notizen hinzufügen ..."
					className={styles.NoteFormField}
					onBlur={this.saveNote}
					value={noteValue}
				/>

				<span className={styles.NoteSavingState}>
					{this.getSavingState()}
				</span>
			</div>
		);
	}

	/**
	 * Returns the correct saving state
	 */
	private getSavingState () {
		const { state, error } = this.props;

		if (state === 'saved') {
			return (
				<span className={styles.NoteSavingStateSaved}>
					Gespeichert <Icon icon="done" />
				</span>
			);
		}

		if (state === 'unsaved') {
			return (
				<span className={styles.NoteSavingStateUnsaved}>
					Nicht gespeichert <Icon icon="thumbnails" />
				</span>
			);
		}

		if (state === 'saving') {
			return (
				<span className={styles.NoteSavingStateSaving}>
					Speichert <Icon icon="spinner" />
				</span>
			);
		}

		if (state === 'error') {
			return (
				<span className={styles.NoteSavingStateError}>
					{error} <Icon icon="alert" />
				</span>
			);
		}

		return null;
	}

	/**
	 * Updates the text area input while typing
	 *
	 * @param event
	 */
	private changeTextinput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { onSetShoppingNoteSavingState} = this.props;

		onSetShoppingNoteSavingState('unsaved');

		this.setState({
			noteValue: event.target.value,
		});
	};

	/**
	 * Saves the note to api
	 */
	private saveNote = () => {
		const { onSetShoppingNoteSavingState, note } = this.props;
		const { noteValue } = this.state;

		if (note === noteValue) {
			onSetShoppingNoteSavingState(null);
			return;
		}

		this.props.onSaveShoppingNote(this.state.noteValue);
		setTimeout(() => {
			onSetShoppingNoteSavingState(null);
		}, 6000);
	};
}

export default ShoppingNote;
