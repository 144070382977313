import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import ProductPromotionGridSkeleton from '../../../Promotion/components/ProductPromotionGrid/ProductPromotionGridSkeleton';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import * as styles from './BrandDetailPage.scss';


class BrandDetailPageSkeleton extends React.PureComponent<{}> {
    public static defaultProps = {};

    // TRANSLATE
    public render() {

        return (
            <PageWrapper>
                <div className={styles.breadcrumbSkeleton}>
                    <SkeletonInline width={80} />
                </div>
                <Row>
                    <AppMainCol boxed={false}>
                        <AppMainColBox boxed={true}>
                            <Row>
                                <React.Fragment>
                                    <div className={styles.titleWrapper}>
                                        <H1 className={styles.heading}>
                                            <SkeletonInline width={160} />
                                        </H1>
                                    </div>
                                    <figure>

                                        <div className={styles.brandLogoWrapper}>
                                            <SkeletonInline />
                                        </div>


                                    </figure>
                                </React.Fragment>
                            </Row>
                        
                            <ProductPromotionGridSkeleton />

                        </AppMainColBox>
                    </AppMainCol>

                    <AppSideCol boxed={true}>
                        <div>

                            <WidgetBoxSkeleton
                                rows={5}
                                type="item"
                                widgetKey={'brand-detail-skeleton-widget1'}
                                showValues={{
                                    title: true,
                                    subtitle: false,
                                    special: false,
                                    extra: false,
                                }}
                            />

                            <WidgetBoxSkeleton
                                rows={5}
                                type="item"
                                widgetKey={'brand-detail-skeleton-widget2'}
                                showValues={{
                                    title: true,
                                    subtitle: false,
                                    special: false,
                                    extra: false,
                                }}
                            />

                        </div>
                    </AppSideCol>
                </Row>

            </PageWrapper>
        );
    }

}

export default BrandDetailPageSkeleton;
