import * as React from 'react';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Swiper from '../../../Base/components/Swiper/Swiper';
import ZoomableView, { zoomEvent, zoomEventTarget } from '../../../Base/components/ZoomableView/ZoomableView';
import { IdType } from '../../../Base/types/IdType';
import {
	goToLeafletViewerPage,
	goToLeafletViewerSlide,
	initLeafletViewerSlides,
} from '../../store/LeafletViewer/leafletViewerActions';
import { ILeafletViewerStoreType, ILeafletViewerUIState } from '../../store/LeafletViewer/leafletViewerReducer';
import { ILeafletPageType } from '../../types/ILeafletPageType';
import { ILeafletType } from '../../types/ILeafletType';
import LeafletViewerPage from './LeafletViewerPage';
import * as styles from './LeafletViewerPageWrapper.scss';
import LeafletAdPage from './LeafletAdPage';
import AdItem from '../../../../components/General/AdItem/AdItem';
import { LeafletViewerType } from '../../hocs/withLeaflet';

interface ILeafletViewerPageWrapperPropsType {
	leaflet: ILeafletType;
	pages: ILeafletPageType[];
	leafletViewerType: LeafletViewerType;
	initCurrentSlide: number,
	initSlides?: (leafletId: IdType, slideStates: ILeafletViewerUIState[], currentSlide: number) => void,
	leafletViewerUiState: ILeafletViewerStoreType,
	goToSlide?: (newSlideIndx: number) => void,
	goToPage?: (pageNumber: number) => void,
}

interface ILeafletViewerPageWrapperState {
	page2SlideMap: { [pageNumber: number]: number };
	slideArray: ReactNode[] | null;
	currentSlide: number,
	isSwipingDisabled: boolean,
	isZooming: boolean,
}

export interface ILeafletViewerPageDimensions {
	pageWidth: number,
	pageHeight: number,
	wrapperWidth: number,
	wrapperHeight: number,
	imageWidth: number,
	imageHeight: number,
	orientation: 'landscape' | 'portrait',
}

interface ILeafletViewerPageDimensionMap {
	singlePageView?: ILeafletViewerPageDimensions;
	doublePageView?: ILeafletViewerPageDimensions;
}

class LeafletViewerPageWrapper extends React.PureComponent<ILeafletViewerPageWrapperPropsType, ILeafletViewerPageWrapperState> {
	public static defaultProps = {
		initCurrentSlide: 0,
	};

	/**
	 * Slides distance to the border
	 */
	private static marginX = 11;
	private static marginY = 11;

	/**
	 * The min width that the device must have to be able to show double page views
	 *
	 * @param {number}
	 */
	private static minSizeForDoublePageView = 1400;

	public state = {
		slideArray: null,
		page2SlideMap: {},
		currentSlide: 0,
		isSwipingDisabled: false,
		isZooming: false,
	};

	/**
	 * We usually want 2 pages next to each other (desktop view), but on mobile devices we try to only create on
	 *
	 * @param {boolean}
	 */
	private forceSinglePage = true;


	private pageDimensions: ILeafletViewerPageDimensionMap = {};

	/**
	 * Reference to the swiper
	 */
	private swiperRef: any;

	/**
	 * Reference to the timeout function which determines when a zooming action is considered ended
	 */
	private zoomTimeoutRef: any;

	public constructor(props: ILeafletViewerPageWrapperPropsType) {
		super(props);

		zoomEventTarget.removeEventListener('zoom', this.handleZoom);
		window.removeEventListener('resize', this.handleResize);
	}

	public componentDidMount() {
		this.setAvailableCanvas();

		zoomEventTarget.addEventListener('zoom', this.handleZoom);
		window.addEventListener('resize', this.handleResize);
	}

	public componentWillUnmount(): void {
		if (this.zoomTimeoutRef) {
			clearTimeout(this.zoomTimeoutRef);
		}

		zoomEventTarget.removeEventListener('zoom', this.handleZoom);
		window.removeEventListener('resize', this.handleResize);
	}

	public componentDidUpdate(prevProps: ILeafletViewerPageWrapperPropsType) {
		const nextUiState = (this.props.leafletViewerUiState) ? this.props.leafletViewerUiState : {} as ILeafletViewerStoreType;
		const currentUiState = (prevProps.leafletViewerUiState) ? prevProps.leafletViewerUiState : {} as ILeafletViewerStoreType;

		if (currentUiState.currentSlide !== nextUiState.currentSlide && this.swiperRef) {
			this.swiperRef.goToSlide(nextUiState.currentSlide);
		}
	}

	public render() {
		const { slideArray, isSwipingDisabled, isZooming } = this.state;
		const { leafletViewerUiState, leaflet, leafletViewerType } = this.props;

		const pageWrapperClasses = [styles.pageWrapper];
		if (isZooming) {
			pageWrapperClasses.push(styles.zooming);
		}

		if (!slideArray) {
			// TODO: replace with nicer looking skeleton screens
			return (
				<div className={styles.contentWrapper}>
					<div className={styles.leafetWrapper}>
						<div className={pageWrapperClasses.join(' ')} id="leaflet-viewer-wrapper">
							&nbsp;
						</div>
					</div>

					<div className={styles.sidebarWrapper}>
						{(!leaflet.showAds || leaflet.promotionWorld || leafletViewerType === LeafletViewerType.INFOPOST) ? null :
							<div style={{ height: 10 }}>&nbsp;</div>
						}
					</div>
				</div>
			);
		}

		return (
			<div className={styles.contentWrapper}>
				<div className={`${styles.leafetWrapper} ${leafletViewerType === LeafletViewerType.INFOPOST ? styles.infopostLeafetWrapper : ''}`}>
					<div className={pageWrapperClasses.join(' ')} id="leaflet-viewer-wrapper">
						<Swiper
							ref={(ref: any) => {
								this.swiperRef = ref;
							}}
							page2SlideMap={leafletViewerUiState.page2SlideMap}
							slides={slideArray}
							slideStates={leafletViewerUiState.slideStates}
							onSlideChange={this.onSlideChange}
							disabled={isSwipingDisabled}
							updateUrl={this.updateUrlOnNavigation}
						/>
					</div>
				</div>

				<div className={styles.sidebarWrapper}>
					{(!leaflet.showAds || leaflet.promotionWorld || leafletViewerType === LeafletViewerType.INFOPOST) ? null :
						<AdItem
							desktopAdId={4403496}
							maxHeight={600}
							maxWidth={300}
							type="side"
							title="Skyscraper/Sitebar"
							key={`leaflet-${leaflet.slug}-SiteboardAd${Math.floor(leafletViewerUiState.pagesShown / 5)}`}
						/>
					}
				</div>

			</div>
		);
	}

	private onSlideChange = (slideIndex: number) => {
		const { goToSlide } = this.props;

		zoomEventTarget.dispatchEvent(zoomEvent('requestZoom', 1));

		if (goToSlide) {
			goToSlide(slideIndex);
		}
	};

	/**
	 * Inserts current slides in our global leafletViewerReducer
	 *
	 * @param page2SlideMap
	 */
	private initSlides(page2SlideMap: { [pageNumber: number]: number }) {
		const { leaflet, initSlides, initCurrentSlide } = this.props;

		// reformat the page2slide map to our ui state object
		const uiStateArray: ILeafletViewerUIState[] = [];
		for (const page of Object.keys(page2SlideMap)) {
			const slideIndx = page2SlideMap[page];

			if (!uiStateArray[slideIndx]) {
				uiStateArray[slideIndx] = {
					pagesOnSlide: [],
				};
			}

			uiStateArray[slideIndx].pagesOnSlide.push(page);
		}

		if (initSlides) {
			initSlides(leaflet.id, uiStateArray, initCurrentSlide);
		}
	}

	/**
	 * Generates the slides by combining our pages either on one or two pages
	 *
	 * @param pageDimensionMap
	 */
	private generateSlides(pageDimensionMap: ILeafletViewerPageDimensionMap) {
		const { leaflet, pages: responsePages, leafletViewerType } = this.props;

		// Extending received pages with a new page
		// only add the last page if we are not in Infopost mode
		var pages: ILeafletPageType[];

		pages = [...responsePages, {
			title: 'LAST_PAGE'
		}] as ILeafletPageType[];

		const totalPages = pages.length;

		let slideArray: JSX.Element[] = [];
		let page2SlideMap = {};

		for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
			const page = pages[pageNumber];

			// if this page is already in there -> don't add it again (happens if we have double pages and the second page added the third page already)
			if (page2SlideMap[pageNumber]) {
				continue;
			}

			// if we want to add a double page (2 pages on the same slide)
			if (!this.forceSinglePage // if we only want to display one page
				&& pageNumber !== 0 // if it is not the first page
				&& pageDimensionMap.doublePageView
				&& pages[(pageNumber + 1)] // and if there is at least one page after that
			) {
				const slideIndx = slideArray.length;

				// add the pages to slide
				slideArray.push(
					<div
						className={styles.leafletViewerSlide}
					>
						<ZoomableView
							className={styles.leafletPageBox}
							width={(pageDimensionMap.doublePageView.pageWidth * 2)}
							height={pageDimensionMap.doublePageView.pageHeight}
						>
							<LeafletViewerPage
								key={`leaflet-viewer-${leaflet.slug}-${pageNumber}`}
								leaflet={leaflet}
								page={page}
								pageNumber={pageNumber}
								pageDimensions={pageDimensionMap.doublePageView}
								slideIndx={slideIndx}
								isDoublePage={true}
								leafletViewerType={leafletViewerType}
							/>

							<LeafletViewerPage
								key={`leaflet-viewer-${leaflet.slug}-${(pageNumber + 1)}`}
								leaflet={leaflet}
								page={pages[(pageNumber + 1)]}
								pageNumber={(pageNumber + 1)}
								pageDimensions={pageDimensionMap.doublePageView}
								slideIndx={slideIndx}
								isDoublePage={true}
								leafletViewerType={leafletViewerType}
							/>
						</ZoomableView>
					</div>
				);

				// add the page to page map
				page2SlideMap[pageNumber] = slideIndx;
				page2SlideMap[(pageNumber + 1)] = slideIndx;
			}
			// else if only have one page per slide
			else if (pageDimensionMap.singlePageView) {
				let slideIndx = slideArray.length;

				slideArray.push(
					<div
						className={styles.leafletViewerSlide}
					>
						<ZoomableView
							className={styles.leafletPageBox}
							width={pageDimensionMap.singlePageView.pageWidth}
							height={pageDimensionMap.singlePageView.pageHeight}
						>
							<LeafletViewerPage
								key={`leaflet-viewer-${leaflet.slug}-${pageNumber}`}
								leaflet={leaflet}
								page={page}
								pageNumber={pageNumber}
								pageDimensions={pageDimensionMap.singlePageView}
								slideIndx={slideIndx}
								isDoublePage={false}
								leafletViewerType={leafletViewerType}
							/>
						</ZoomableView>
					</div>
				);

				// add the page to page map
				page2SlideMap[pageNumber] = slideIndx;
			}
		}

		// on single page view -> add ad pages before every "x"th page
		if (this.forceSinglePage
			|| !pageDimensionMap.doublePageView
		) {
			if (pageDimensionMap.singlePageView) {
				const modifiedPages = this.addAdPagesInBetween(slideArray, page2SlideMap);
				slideArray = modifiedPages.slideArray;
				page2SlideMap = modifiedPages.page2SlideMap;
			}
		}

		// insert these slides in leaflet viewer reducer
		this.initSlides(page2SlideMap);

		// now set these arrays
		this.setState({
			slideArray,
			page2SlideMap,
		});
	}

	/**
	 * Adds an ad page before every "x"th page
	 *
	 * @param slideArray
	 * @param page2SlideMap
	 */
	private addAdPagesInBetween(slideArray: any[], page2SlideMap: any) {
		const { leaflet, leafletViewerType } = this.props;

		// if we don't want to show ads for this vendor -> quit here
		// leaflet.showAds = true;
		if (leaflet.showAds != true || leaflet.promotionWorld || leafletViewerType === LeafletViewerType.INFOPOST) {
			return {
				page2SlideMap,
				slideArray,
			};
		}

		const ADD_AD_PAGE_AFTER_EVERY_X_PAGE = 10;
		const MIN_PAGES_MORE_TO_SHOW_AD_PAGE = 2;

		// loop through all possible insertation places and add the ad page
		const skipPageSlideMappings = {};
		let additionalAddedIndx = 0;
		for (let i = 10; i <= (slideArray.length - MIN_PAGES_MORE_TO_SHOW_AD_PAGE); i = i + ADD_AD_PAGE_AFTER_EVERY_X_PAGE) {
			const deleteIndx = i + additionalAddedIndx; // additionalAddedIndex takes into account, that when we keep adding stuff, the other pages are pushed back by 1 every time
			slideArray.splice(deleteIndx - 1, 0,
				<div
					className={styles.leafletViewerSlide}
				>
					<LeafletAdPage
						slideIndx={i}
					/>
				</div>
			);

			// add the page to page map
			skipPageSlideMappings[deleteIndx - 2 - additionalAddedIndx] = 1;
			additionalAddedIndx++;
		}

		// regenerate page2SlideMap and leave gaps, where our ads are
		const newPage2SlideMap = {};
		let slideIndx = 0;
		for (const pageNumber of Object.keys(page2SlideMap)) {
			newPage2SlideMap[pageNumber] = slideIndx;

			// if we want to skid this slide (because it's an ad slide) -> increment slideIndx without change
			if (skipPageSlideMappings[(pageNumber)]) {
				newPage2SlideMap[`a-${slideIndx}`] = slideIndx;
				slideIndx++;
			}

			slideIndx++;
		}

		return {
			page2SlideMap: newPage2SlideMap,
			slideArray,
		};
	}

	/**
	 * Is called everytime the ZoomableView is zoomed. This will happen numerous times during a zooming operation.
	 * The component doesn't notify us when zooming has ended, so we assume it ended if this handler hasn't been called
	 * for 1s.
	 *
	 *  @param event
	 */
	private handleZoom = ({ detail: scale }: CustomEvent) => {
		this.setState({ isSwipingDisabled: scale !== 1, isZooming: true });

		clearTimeout(this.zoomTimeoutRef);
		this.zoomTimeoutRef = setTimeout(() => this.setState({ isZooming: false }), 1000);
	}

	/**
	 * Fits elements to page when the page resizes
	 */
	private handleResize = () => {
		this.setAvailableCanvas();
	}


	/**
	 * We usually want 2 pages next to each other (desktop view), but on mobile devices we try to only create on.
	 * Check here if we are on such a device
	 *
	 * @param wrapperWidth
	 */
	private checkIfWeNeedToForceSinglePage(wrapperWidth: number) {
		if (wrapperWidth > LeafletViewerPageWrapper.minSizeForDoublePageView) {
			this.forceSinglePage = false;
			return;
		}
		this.forceSinglePage = true;
	}

	/**
	 * Determines the available space for our page to make sure we fit it in perfectly
	 */
	private setAvailableCanvas() {
		const wrapperDiv = document.getElementById('leaflet-viewer-wrapper');

		if (!wrapperDiv) {
			return;
		}

		const width = wrapperDiv.offsetWidth;
		const height = wrapperDiv.offsetHeight;

		// check if we need to force 1 page
		this.checkIfWeNeedToForceSinglePage(width);

		// now make sure we have the correct image size
		this.getImageSize(width, height);
	}

	/**
	 * Loads a small version of the cover to get the page dimensions and will
	 * use the image ratio to define the perfect fit for our image
	 *
	 * @param wrapperWidth
	 * @param wrapperHeight
	 */
	private getImageSize(wrapperWidth: number, wrapperHeight: number) {
		const { leaflet } = this.props;

		if (!leaflet || !leaflet.coverPage || !leaflet.coverPage.resolutions || !leaflet.coverPage.resolutions.small) {
			console.warn('No coverpage or no resolution provided for this leaflet');
			return;
		}

		// get the coverpage leaflet
		const coverPageImage = leaflet.coverPage.resolutions.small;

		// preload the image
		const coverThumb = new Image();

		coverThumb.onload = () => {
			this.pageDimensions.singlePageView = this.setPerfectSize(wrapperWidth, wrapperHeight, coverThumb.width, coverThumb.height);

			// if we we don't force single views, also calculate double view widths
			if (!this.forceSinglePage) {
				this.pageDimensions.doublePageView = this.setPerfectSize((wrapperWidth * 2), wrapperHeight, coverThumb.width, coverThumb.height);
			}

			// now generate the slides
			this.generateSlides(this.pageDimensions);
		};

		coverThumb.src = coverPageImage; // this must be done AFTER setting onload
	}

	/**
	 * Calculates the perfect size for this image to fit in and sets it
	 *
	 * @param wrapperWidth
	 * @param wrapperHeight
	 * @param imageWidth
	 * @param imageHeight
	 */
	private setPerfectSize(wrapperWidth: number, wrapperHeight: number, imageWidth: number, imageHeight: number): ILeafletViewerPageDimensions {
		const imageRatio = imageWidth / imageHeight;
		const wrapperRatio = wrapperWidth / wrapperHeight;

		if (imageRatio < (wrapperRatio)) {
			const pageWidth = (wrapperHeight * imageRatio) * 0.9;

			this.forceSinglePage = (wrapperWidth <= (pageWidth * 2));

			return {
				pageWidth: pageWidth,
				pageHeight: wrapperHeight * 0.9,
				wrapperWidth,
				wrapperHeight,
				imageHeight,
				imageWidth,
				orientation: 'portrait',
			} as ILeafletViewerPageDimensions;
		}

		const pageWidth = (wrapperWidth / imageRatio) * 0.9;

		this.forceSinglePage = (wrapperWidth <= (pageWidth * 2));

		return {
			pageHeight: pageWidth,
			pageWidth: wrapperWidth * 0.9,
			wrapperWidth,
			wrapperHeight,
			imageHeight,
			imageWidth,
			orientation: 'landscape',
		} as ILeafletViewerPageDimensions;
	}
}

function mapStateToProps(state: any) {
	return {
		leafletViewerUiState: state.leafletViewer,
	};
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
		initSlides: (leafletId: IdType, slideStates: ILeafletViewerUIState[], currentSlide = 0) => {
			dispatch(initLeafletViewerSlides(leafletId, slideStates, currentSlide));
		},
		goToSlide: (newSlideIndx: number) => {
			dispatch(goToLeafletViewerSlide(newSlideIndx));
		},
		goToPage: (pageNumber: number) => {
			dispatch(goToLeafletViewerPage(pageNumber));
		},
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(LeafletViewerPageWrapper);
