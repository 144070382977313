import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ContentFragmentContainer from '../App/modules/ContentFragment/containers/ContentFragment/ContentFragmentContainer';
import BaseRoute from './_BaseRoute';

class LotteryParticipationConditionsRoute extends BaseRoute {
	public render () {

		return (
			<div>
				<Header />
				<MainNavigation />

				<ContentFragmentContainer
					fragmentKey="afwebpwa_lottery_terms"
					pageTitle="Teilnahmebedingungen zum Gewinnspiel"
				/>

				<Footer />
			</div>
		);
	}
}

export default withRouter(LotteryParticipationConditionsRoute);
