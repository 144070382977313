import * as React from 'react';
import * as styles from './DeleteAccount.scss';
import Button from '../../../../components/General/Button/Button';
import AlertService from '../../../Base/services/AlertService';
import AccountService from '../../services/AccountService';
import Checkbox from '../../../../components/Layout/Checkbox/Checkbox';
import { accountLogout } from '../../store/Account/accountActions';
import { connect } from 'react-redux';
import { withRouter, RouterProps } from 'react-router'; // TODO: fix navigation to react-router


interface IDeleteAccountState {
	loading: boolean,
	removeNewsletters: boolean,
}

interface IDeleteAccountProps {
	onCloseModal: () => void;
}


class DeleteAccount extends React.Component<IDeleteAccountProps & RouterProps, IDeleteAccountState> {

	constructor (props: IDeleteAccountProps) {
		super(props);

		this.state = {
			loading: false,
			removeNewsletters: false,
		};
	}

	private onDelete = () => {

		const { removeNewsletters } = this.state;

		this.setState({ loading: true });

		AccountService.deleteAccount({removeNewsletters}, (response: any) => {

			this.setState({ loading: false });
			
			this.props.accountLogout();

			setTimeout(() => {

				this.props.history.push('/');
				
				setTimeout(() => {
					AlertService.add(
						'deleteUser',
						'Das Benutzerkonto wurde gelöscht',
					);
				}, 500); 

			}, 100);

		}, ( error: any ) => {

			this.setState({ loading: false });
			
			AlertService.error(
				'error-'+ error,
				error,
			);
        });
	};


	public render () {

		const { onCloseModal } = this.props;

		const { loading } = this.state;

		return (
			<div className={styles.deleteAccountWrapper}>

				<img src={require('./DeleteAccountLogo.svg')} alt="Benutzerkonto löschen"/>

				<h4>Du möchtest uns wirklich verlassen?</h4>


				{/* TODO: commented because it exists in Invision, but not exist in acceptance criteries */}
				{/* <div className={styles.deleteAccountInfo}>
					Um das Löschen zu bestätigen gib bitte deine E-Mail Adresse ein: 
				</div> */}

				{/* <input
					type="email"
					name="search"
					placeholder="email@example.com"
					autoComplete="off"
					className={styles.deleteAccountInput}
					onChange={this.onChangeInput}
					value={this.state.email}
				/> */}

				
				<div className={styles.deleteAccountCheckbox}>
					<Checkbox
						name="removeNewsletters"
						onChange={this.onChangeInput}
						checked={this.state.removeNewsletters}
					>
						Alle Newsletter stornieren
					</Checkbox>
				</div>

				<Button
        			theme="primary"
					size="xl"
					fullWidth={true}
					type="submit"
					loading={loading}
					onClick={this.onDelete}
				>
					Unwiederbringlich löschen
				</Button>

				<Button
        			theme="default"
					size="xl"
					fullWidth={true}
					loading={loading}
					onClick={onCloseModal}
				>
					Abbrechen
				</Button>

			</div>
		);
	}

	private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[ name ]: value,
		});
	};

}

function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		accountLogout: () => {
			dispatch(accountLogout());
		},
	};
}

function mapStateToProps (state: any) {
	return {
		accountReducer: state.accountReducer,
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteAccount));
