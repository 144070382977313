import * as React from 'react';
import { RouteComponentProps, RouterProps, withRouter } from 'react-router';
import DataLayerService from '../App/modules/Base/services/DataLayerService';
import HistoryService from '../App/services/HistoryService';

declare const adition: any;

class BaseRoute<RouteComponentPropTypes> extends React.Component<RouteComponentPropTypes & RouteComponentProps> {
  public componentDidMount(): void {
    const { history, routeMetadata } = this.props;

    if (history && history.location && history.location.pathname) {
      DataLayerService.push('pageviewCustomEvent', {
          'pagePath': history.location.pathname,
          'pageTitle': (routeMetadata && routeMetadata.metaTitle) ? routeMetadata.metaTitle : history.location.pathname,
      });

      HistoryService.push(history.location.pathname);
    }



   /* setTimeout(() => {
      adition.srq.push(function(api) {
        api.load().completeRendering();
      });
    }, 1000);*/
  }

  public render() {
    return (
      <div />
    );
  }
}

export default BaseRoute;
