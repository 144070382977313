import * as React from 'react';
import { ReactChild } from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import * as styles from './TagItem.scss';

interface ITagItemProps {
	children: ReactChild | JSX.Element[] | string[],
	route: string,
	params?: { [key: string]: any },
	onClick: (data: any) => void;
	queryParams?: { [ key: string ]: any };
}

class TagItem extends React.PureComponent<ITagItemProps, {}> {
	public render () {
		const { children, route, params, onClick, queryParams } = this.props;

		return (
			<Link route={route} params={params} queryParams={queryParams}>
				<a className={styles.TagItem} onClick={onClick}>
					{children}
				</a>
			</Link>
		);
	}
}

export default TagItem;
