import { IQueryMetaDataType } from './types/IQueryMetaDataType';
import { IBaseReducerState } from './types/IBaseReducerState';
import { isServer } from '../razzle/razzleUtils';


export default class ReducerManipulator {
	/**
	 * Defines the default state for every query Metadata
	 * @type {IQueryMetaDataType}
	 */
	public static defaultQueryMetadata: IQueryMetaDataType = {
		count: 0,
		done: false,
		errors: undefined,
		hasMore: false,
		lastFetch: undefined,
		lastOffset: 0,
		loading: undefined,
		backgroundLoading: false,
		success: false,
		requestOrigin: null,
		totalItemsCount: 0,
		maxItemDiscount: 0,
		totalCount: 0,
		fullItemsCount: 0,
		refetching: false,
	};

	/**
	 * The primary key used in this reducer operations
	 * @type {string}
	 */
	private primaryKey = 'id';

	/**
	 * Sets the primary key used in reducer operations
	 * @param keyFieldName
	 */
	public setPrimaryKey (keyFieldName: string) {
		this.primaryKey = keyFieldName;

		return this;
	}

	/**
	 * Updates query meta data of a specific query by merging the current data with changeObj
	 *
	 * @param {IBaseReducerState} state
	 * @param {string} queryKey
	 * @param {object} changeObj
	 * @param {boolean} forceReplace defines whether the values should be reset first
	 *
	 * @return {IBaseReducerState}
	 */
	public changeQueryMetadata (state: IBaseReducerState, queryKey: string, changeObj = {}, forceReplace = false) {
		const currentQueryMetadata = ( state.queryMetadata[ queryKey ] && forceReplace === false ) ? state.queryMetadata[ queryKey ] : {};
		const updatedMetadata      = Object.assign({}, ReducerManipulator.defaultQueryMetadata, currentQueryMetadata, changeObj);

		const newMetaData = Object.assign({}, state.queryMetadata, { [ queryKey ]: updatedMetadata });


		return Object.assign({}, state, { queryMetadata: newMetaData });
	}

	/**
	 * Resets and then updates query meta data of a specific query by merging the current data with changeObj
	 *
	 * @param {IBaseReducerState} state
	 * @param {string} queryKey
	 * @param {object} changeObj
	 * @param {boolean} forceReplace
	 *
	 * @return {IBaseReducerState}
	 */
	public resetQueryMetadata (state: IBaseReducerState, queryKey: string, changeObj = {}, forceReplace: boolean = true) {
		return this.changeQueryMetadata(state, queryKey, changeObj, forceReplace);
	}

	/**
	 * Adds or updates an entry in our entry map
	 *
	 * @param {IBaseReducerState} state
	 * @param {string|number} entryKey
	 * @param {object|array} result
	 * @param {boolean} forceReplace
	 *
	 * @return {IBaseReducerState}
	 */
	public addOrUpdateEntry (state: IBaseReducerState, entryKey: string | number, result, forceReplace = false) {
		const currentEntry = ( state.entryMap[ entryKey ] && forceReplace === false ) ? state.entryMap[ entryKey ] : {};
		const updatedEntry = Object.assign({}, currentEntry, result);

		state.entryMap = Object.assign({}, state.entryMap, { [ entryKey ]: updatedEntry });

		return state;
	}

	/**
	 * Adds or updates an entry in our entry map
	 *
	 * @param {IBaseReducerState} state
	 * @param {any[]} entryArr
	 * @param {IFetchOptionType} options
	 * @param {(entry: any) => any} transformMethod
	 *
	 * @return {IBaseReducerState}
	 */
	public addOrUpdateMultipleEntries (state: IBaseReducerState, entryArr: any[], options: IFetchOptionType = {}, transformMethod: (entry: any) => any) {
		const addEntryMap = {};

		for (const entryRaw of Array.from(entryArr)) {
                                                         const entry = transformMethod(entryRaw);

                                                         const entryKey = entry[this.primaryKey];

                                                         const currentEntry = state.entryMap[entryKey] && options.entryMapMergeMode === false ? state.entryMap[entryKey] : null;

                                                         if (currentEntry) {
                                                             addEntryMap[entryKey] = Object.assign({}, currentEntry, entry);
                                                         } else {
                                                             addEntryMap[entryKey] = entry;
                                                         }
                                                     }

		// merge with current entry map
		state.entryMap = Object.assign({}, state.entryMap, addEntryMap);

		return state;
	}

	/**
	 * Add the result of a fetch or operation to the queries map, to make sure we keep the exact same structure and order as we received
	 *
	 * @param {IBaseReducerState} state
	 * @param {string|number} queryKey
	 * @param {object|array} result
	 * @param {IFetchOptionType} options
	 *
	 * @return {IBaseReducerState}
	 */
	public addQueryData (state: IBaseReducerState, queryKey: string, result: any, options: IFetchOptionType = {}) {
		// if we are replacing the query content -> we don't care about queryResultType, so just set it
		if (options.queryMergeMode === 'replace' || typeof state.queries[ queryKey ] === 'undefined') {
			const queries = Object.assign({}, state.queries, { [queryKey]: result });
			return Object.assign({}, state, { queries });
		}


		// Otherwise perform the operation depending on the queryResultType

		// If we only have a simple object as result (i.e: fetch of a single entry)
		if (options.queryResultType === 'object') {
			state.queries[ queryKey ] = Object.assign({}, state.queries[ queryKey ], result);
			return Object.assign({}, state);
		}

		// if we have an array query result string
		if (options.queryResultType === 'list' || !options.queryResultType) {
			if (options.queryMergeMode === 'add') {
				const newQueryData = state.queries[ queryKey ].map((entry: any) => entry);

				for (const entry of result) {
					newQueryData.push(entry);
				}

				const queries = Object.assign({}, state.queries, { [ queryKey ]: newQueryData });
				return Object.assign({}, state, { queries });
			}

			return state;
		}


		// if we have a list of key and value object query result string
		if (options.queryResultType === 'map') {
			if (options.queryMergeMode === 'add') {
				state.queries[ queryKey ] = Object.assign({}, state.queries[ queryKey ], result);
			}

			return state;
		}


		return state;
	}

	/**
	 * Returns the correct metadata we can gather from this request
	 *
	 * @param {IBaseReducerState} state
	 * @param {string|number} entryKey
	 * @param {object|array} result
	 * @param {IFetchOptionType} options
	 *
	 * @return {IBaseReducerState}
	 */
	// TODO: put this in the reducer and make the metadata check for every single element
	public getFetchQueryMetadata (state: IBaseReducerState, queryKey: string, result, options: IFetchOptionType = {}) {
		// TODO implement this
	}

}
