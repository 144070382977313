import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';
import * as promotionsAggregatedByProductGroupsWidgetActions from './promotionsAggregatedByProductGroupsWidgetActions';

export type PromotionsAggregatedByProductGroupsWidgetAction = ActionType<typeof promotionsAggregatedByProductGroupsWidgetActions>;

export interface IPromotionsAggregatedByProductGroupsWidgetProductGroupType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IPromotionsAggregatedByProductGroupType };
	readonly queries: { [queryKey: string]: IPromotionsAggregatedByProductGroupType[] };
}

export function promotionsAggregatedByProductGroupsWidgetReducer(
	state = baseInitialState as IPromotionsAggregatedByProductGroupsWidgetProductGroupType,
	action: PromotionsAggregatedByProductGroupsWidgetAction,
) {
	const Reducer = new BaseReducer('promotionsAggregatedByProductGroupsWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
