import * as React from 'react';
import Icon from '../../../Base/components/Icon/Icon';
import { ILeafletType } from '../../types/ILeafletType';
import * as styles from './LeafletInfopostFooter.scss';
import LeafletMenu from './LeafletMenu';
import { ILeafletPageType } from '../../types/ILeafletPageType';
import Button from '../../../../components/General/Button/Button';
import { LeafletViewerType } from '../../hocs/withLeaflet';
import { isLeafletTypePremium } from '../../../../utils/leafletTypeChecker';
import Link from '../../../Base/components/Link/Link';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import DataLayerService from '../../../Base/services/DataLayerService';

interface ILeafletInfopostFooterPropsType {
    leaflet: ILeafletType;
    pages: ILeafletPageType[];
    showPagesMenu: boolean;
    showBookmarkMenu: boolean;
    leafletViewerType: LeafletViewerType;
    pageCounter: string;
}

interface ILeafletInfopostFooterStatesType {
    showMenu: string;
}

class LeafletInfopostFooter extends React.Component<ILeafletInfopostFooterPropsType, ILeafletInfopostFooterStatesType> {
    constructor(props: ILeafletInfopostFooterPropsType) {
        super(props);
        this.state = {
            showMenu: '',
        };
    }

    public render() {
        const { leaflet, pages, showPagesMenu, showBookmarkMenu, pageCounter } = this.props;

        const { showMenu } = this.state;

        const openVendorWebsite = () => {
            window.open(leaflet.vendor?.websiteURL, '_blank');
            this.trackExternalClickOut(leaflet.vendor?.websiteURL);
        };

        const showWebsiteButtonUrl = isLeafletTypePremium(leaflet.rwvPublishRangeType) && leaflet.vendor?.websiteURL;

        return (
            <React.Fragment>
                <div className={`${styles.leafletFooterContainer} ${showMenu ? styles.leafletFooterExpanded : ''}`}>
                  
                    <div className={`${styles.leafletFooterBar} ${styles.legal}`}>
                        <Link route="ImprintRoute">
                            <a>
                                Impressum
                            </a>
                        </Link>

                        <Link route="PrivacyInfoRoute">
                            <a>
                                Datenschutz
                            </a>
                        </Link>
                    </div>

                    <div className={`${styles.leafletFooterBar} ${styles.copyright}`}>
                        © Österreichische Post AG, Rochusplatz 1, 1030 Wien
                    </div>

                    <div className={`${styles.leafletFooterWrapper} ${showWebsiteButtonUrl ? styles.premiumLeaflet : ''}`}>
                        {!showWebsiteButtonUrl ? null : (
                            <Button theme="primary" uppercase={false} customClassName={styles.leafletFooterToShopButton} href={leaflet.vendor?.websiteURL} target='_blank' fontSize={14}>
                                <span style={{ display: 'inline-block', fontSize: '1.4em', transform: 'translateY(0.1em)' }}><Icon icon="cart" className={styles.leafletFooterToShopIcon} title="Zum Online Shop" /></span>
                                &nbsp;&nbsp;
                                <span>Zum Online Shop</span>
                            </Button>
                        )}

                        {!showPagesMenu ? null : (
                            <div className={styles.leafletFooterPagesWrapper} onClick={() => this.toggleMenu('pages')}>
                                <Icon icon="thumbnails" className={`${styles.leafletFooterIcon} ${showMenu === 'pages' ? styles.active : ''}`} title="Seitenübersicht" />
                                <span className={styles.leafletFooterPagesCount}>{pageCounter}</span>
                            </div>
                        )}

                        {!showBookmarkMenu ? null : <Icon icon="shoppinglist-add" className={`${styles.leafletFooterIcon} ${showMenu === 'bookmarks' ? styles.active : ''}`} onClick={() => this.toggleMenu('bookmarks')} />}
                    </div>
                </div>

                <LeafletMenu openMenuItem={showMenu} onCloseMenu={this.toggleMenu} pages={pages} leaflet={leaflet} />
            </React.Fragment>
        );
    }

    /**
	 * Track click out
	 */
	private trackExternalClickOut = (clickoutUrl?: string) => {
		const { leaflet, leafletViewerType } = this.props;

		if (clickoutUrl) {
			InternalTrackingService.track('CLICKOUT_LEAFLET', {
				leafletId: leaflet.id,
				targetUrl: clickoutUrl,
                leafletPageId: localStorage.getItem('leafletPagesLastOpenedPage') ?? '',
                industry_slug: leaflet.vendorIndustrySlugs?.join(','),
				infopost: leafletViewerType === LeafletViewerType.INFOPOST
			});

			DataLayerService.push('CLICKOUT_LEAFLET', {
				leafletId: leaflet.id,
				leafletTitle: leaflet.title,
				vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
				vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
                industrySlug: leaflet.vendorIndustrySlugs?.join(','),
				targetUrl: clickoutUrl,
			});

			return;
		}
	}

    /**
     * Toggles the input footer visibility
     */
    private toggleMenu = (name: string) => {
        let action = name;

        if (name === this.state.showMenu) {
            action = '';
        }

        this.setState({
            showMenu: action,
        });
    };
}

export default LeafletInfopostFooter;
