import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import VouchersInProductGroup from '../App/modules/Promotion/components/VouchersInProductGroup/VouchersInProductGroup';
import { pluralizerVouchers, withTitlePostfix, withClickbait, currentEntryWord } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class VouchersInProductGroupRoute extends BaseRoute {

	public static async getInitialProps(state: any) {
		const reduxData = await VouchersInProductGroupRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<VouchersInProductGroup
							productGroupSlug={query.productGroup + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		VouchersInProductGroupRoute,
		(props: any) => `vopc-${props.match.params.productGroup}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${pluralizerVouchers(routeMetadata.totalItemsCount)} für ${routeMetadata.productGroup.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${routeMetadata.productGroup.title} ${pluralizerVouchers(routeMetadata.totalItemsCount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Gutscheine für ${routeMetadata.productGroup.title} » bei Aktionsfinder informieren ✓ alle Schnäppchen entdecken ✓ Rabatt sichern`, `✓ Den besten Deal schnappen!`),
		} as IRouteMetadataSetterType,
	),
);
