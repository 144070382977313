/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IBrandType } from '../../types/IBrandType';

export const BRAND_GROUPED_FETCH_ENTRIES = '@BasePipeline/brandGroupedReducer_FETCH_ENTRIES';
export const BRAND_GROUPED_FETCH_ENTRY   = '@BasePipeline/brandGroupedReducer_FETCH_ENTRY';
export const BRAND_GROUPED_ADD_ENTRIES = '@BasePipeline/brandGroupedReducer_ADD_ENTRIES';
export const BRAND_GROUPED_ADD_ENTRY = '@BasePipeline/brandGroupedReducer_ADD_ENTRY';

export const BRAND_GROUPED_SET_ERRORS   = '@BasePipeline/brandGroupedReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchBrandGroupeds (queryKey: string) {
	return {
		type: BRAND_GROUPED_FETCH_ENTRIES,
		queryKey,
	};
}

export function addBrandGroupedEntries (queryKey: string, entries: IBrandType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_GROUPED_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addBrandGroupedEntry (queryKey: string, entry: IBrandType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_GROUPED_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setBrandGroupedErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_GROUPED_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
