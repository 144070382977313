import * as React from 'react';
import * as styles from './SectionTitle.scss';
import Link from '../../../modules/Base/components/Link/Link';
import Icon from '../../../modules/Base/components/Icon/Icon';
import H1 from '../H1/H1';

interface ISectionTitleProps {
	title: string;
	titleLevel?: number;
	route?: string;
	routeLabel?: string;
	routeParams?: { [key: string]: any };
	href?: string;
	boxed?: boolean;
	customClassName?: string;
}

class SectionTitle extends React.Component<ISectionTitleProps, {}> {

	public static defaultProps = {
		routeLabel: 'Alle anzeigen',
		titleLevel: 2,
	};

	public render() {
		const { title, boxed, routeLabel, route, routeParams, customClassName, href } = this.props;

		return (
			<div className={`${styles.sectionTitleWrapper} ${boxed ? styles.sectionTitleWrapperBoxed : ''} ${customClassName ?? ''}`}>
				<div className={styles.sectionTitleHeadline}>
					{this.getCorrectTitleTag(title)}
				</div>

				{!route ? null :
					<Link
						route={route}
						params={routeParams}
						href={href}
					>
						<a className={styles.sectionTitleShowAll}>
							{routeLabel}
							<Icon icon="arrow-right" className={styles.sectionTitleShowAllIcon} />
						</a>
					</Link>
				}
			</div>
		);
	}

	/**
	 * Returns the correct title tag, respecting the correct headline level and title content
	 *
	 * @return {string}
	 */
	private getCorrectTitleTag(title: string): React.ReactNode | string {
		const { titleLevel } = this.props;
	  
		if (!title) {
		  return null;
		}
	  
		switch (titleLevel) {
		  case 2:
			return <h2>{title}</h2>;
		  case 3:
			return <h3>{title}</h3>;
		  case 4:
			return <h4>{title}</h4>;
		  default:
			return <H1>{title}</H1>;
		}
	}
	  
}

export default SectionTitle;
