/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const LOTTERY_LIST_FETCH_ENTRIES = '@BasePipeline/lotteryListReducer_FETCH_ENTRIES';
export const LOTTERY_LIST_FETCH_ENTRY   = '@BasePipeline/lotteryListReducer_FETCH_ENTRY';
export const LOTTERY_LIST_ADD_ENTRIES = '@BasePipeline/lotteryListReducer_ADD_ENTRIES';
export const LOTTERY_LIST_ADD_ENTRY = '@BasePipeline/lotteryListReducer_ADD_ENTRY';

export const LOTTERY_LIST_SET_ERRORS   = '@BasePipeline/lotteryListReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchLotteryLists (queryKey: string) {
	return {
		type: LOTTERY_LIST_FETCH_ENTRIES,
		queryKey,
	};
}

export function addLotteryListEntries (queryKey: string, entries: ILotteryType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LOTTERY_LIST_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addLotteryListEntry (queryKey: string, entry: ILotteryType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LOTTERY_LIST_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setLotteryListErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LOTTERY_LIST_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
