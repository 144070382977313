import { DateTime } from 'luxon';
import * as React from 'react';
import { getHumanReadableStringFromTimespan } from './humanReadableDateHelper';

interface IHumanReadableDateProps {
  from: DateTime | string;
  to: DateTime | string;
  outputIfEnded?: string;
  postfixIfNotEnded?: string;
  outputIfTooFarInTheFuture?: (days: number, fromDate: DateTime) => string;
  type: 'short' | 'long';
  className?: string,

  /*
  * If provided the date will be shown without an end date.
  */
  openValidity?: boolean;
}

class HumanReadableDate extends React.PureComponent<IHumanReadableDateProps> {
  public static defaultProps = {
    type: 'long',
    postfixIfNotEnded: ''
  };

  public render () {
    const { from, to, outputIfEnded, postfixIfNotEnded, outputIfTooFarInTheFuture, openValidity, type, className, ...restProps } = this.props;

    const string = getHumanReadableStringFromTimespan(
      from,
      to,
      {
        outputIfEnded,
        outputIfTooFarInTheFuture,
        postfixIfNotEnded,
        openValidity,
      },
      type
    );

    if (string === '') {
      return null;
    }

    return (
      <span {...restProps} className={( !className ) ? '' : className}>
        {string}
      </span>
    );
  }
}

export default HumanReadableDate;
