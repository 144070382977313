import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import ProductPromotionGridGroupedContainer
	from '../../containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer from '../../containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IPromotionsInStateProps {
	stateSlug: SlugType;
}

class PromotionsInState extends React.Component<IPromotionsInStateProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, stateSlug } = this.props;

		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;

		if (!routeMetadata) {
			return null;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<section>
								<ProductPromotionGridGroupedContainer
									orderBy="createdAt"
									orderDir="desc"
									enablePagination={true}
									stateSlug={stateSlug}
									moreLabel={`Alle %title% Aktionen in ${stateName}`}
									moreRouteCallbk={this.routerCallbkPromotionListWidget}
									gridKey={routeMetadata.queryKey}
									pushRouteMetadata={true}
									onlyNearbyPromotions={false}
									groupBy="vendor"
									contentBreaks={{
										1: (
											<AdItem
												mobileAdId={4403480}
												maxHeight={250}
												type="content"
												title="Mobile Rectangle 1"
											/>
										),
										3: (
											<AdItem
												mobileAdId={4403479}
												maxHeight={250}
												type="content"
												title="Mobile Rectangle 2"
											/>
										)
									}}
								>
									<NoDataError />
								</ProductPromotionGridGroupedContainer>
							</section>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								title={`Beliebte Produktkategorien in ${stateName}`}
								widgetKey={`pst-${stateSlug}-brand-widget`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								stateSlug={stateSlug}
								orderBy="promotionCount"
								orderDir="desc"
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.state.title : '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: IProductPromotionGroupedType) => {
		const { stateSlug } = this.props;

		return {
			name: 'PromotionsByVendorInStateRoute',
			params: {
				vendor: item.group.slug,
				state: stateSlug,
			},
		};
	};

	/**
	 * Defines the route the brand list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		const { stateSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndStateRoute',
			params: {
				productGroup: item.group.slug,
				state: stateSlug
			},
		};
	}
}

export default PromotionsInState;
