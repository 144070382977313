import * as React from 'react';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import SearchSuggestionService from '../../services/SearchSuggestionService';
import Row from '../../../Base/components/Grid/Row';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import SearchPageFilters from './SearchPageFilters';
import {
	UrlFilterConsumer,
	UrlFilterFilterData,
} from '../UrlFilterProvider/UrlFilterProvider';
import { SearchMetaDataType } from '../../store/mixedSearch/mixedSearchActions';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import * as styles from './SearchPage.scss';
import LoadingIndicator from '../../../../components/Layout/LoadingIndicator/LoadingIndicator';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import SearchPageFiltersSkeleton from './SearchPageFiltersSkeleton';
import SearchPageNoSearchResults from './SearchPageNoSearchResults';
import SearchPageNoAdvancedFilterResults from './SearchPageNoAdvancedFilterResults';
import AppSideColMobileDrawer from '../../../../components/Layout/PageGrid/AppSideColMobileDrawer';
import DrawerProvider from '../../../Base/components/Drawer/DrawerProvider';
import PageHeading from '../../../../components/Layout/PageWrapper/PageHeading';
import SearchPageFilterSummary from './SearchPageFilterSummary';
import { getNonStandardFilterLength } from '../../utils/getFilterValues';
import { isMobile } from '../../../../utils/responsiveUtils';
import { IMixedEntityType } from '../../../MixedEntity/types/IMixedEntityType';
import MixedEntityGridSkeleton from '../../../MixedEntity/components/MixedEntityGrid/MixedEntityGridSkeleton';
import MixedEntityGrid from '../../../MixedEntity/components/MixedEntityGrid/MixedEntityGrid';

interface ISearchPageProps {
	searchTerm: string;
	results: IMixedEntityType[];
	searchMetaData?: SearchMetaDataType;
	metadata: IQueryMetaDataType;
	fetchMore: () => void;
	filterData: UrlFilterFilterData;
}

class SearchPage extends React.Component<ISearchPageProps, {}> {
	public componentDidMount(): void {
		SearchSuggestionService.addToSearchHistory(this.props.searchTerm);
	}

	public componentDidUpdate(prevProps: Readonly<ISearchPageProps>): void {
		if (prevProps.searchTerm !== this.props.searchTerm) {
			SearchSuggestionService.addToSearchHistory(this.props.searchTerm);
		}
	}

	public render() {
		const { fetchMore, metadata, results, searchTerm, searchMetaData, filterData } = this.props;

		const searchFilterDrawerIdname = 'search-page-filters';

		if (!metadata || (metadata.loading && !results)) {
			return (
				<PageWrapper
					// breadcrumbs={this.getBreadcrumbs()}
					title={this.generateTitle()}
					titleAlign="left"
					disableScrollToTop={true}
				>
					<Row>
						<AppMainCol
							boxed={true}
							onTop={true}
							className={styles.MainColWrapper}
						>
							<MixedEntityGridSkeleton />
						</AppMainCol>

						{(isMobile()) ? null :
							<AppSideCol
								background="body"
							>
								<SearchPageFilterSummary
									filterData={filterData}
									drawerIdname={searchFilterDrawerIdname}
								/>

								<SearchPageFiltersSkeleton />
							</AppSideCol>
						}
					</Row>
				</PageWrapper>
			);
		}

		const noResults = (!metadata?.loading && metadata?.totalItemsCount < 1);

		if (noResults) {
			// if noResults is caused by too many other filters -> show different error message
			if (this.otherFiltersThanStandardFiltersPresent()) {
				return (
					<PageWrapper
						// breadcrumbs={this.getBreadcrumbs()}
						title={this.generateTitle()}
						titleAlign="left"
						disableScrollToTop={true}
					>
						<UrlFilterConsumer>
							{
								({ filterData, applyFilters, resetFilters }) => (
									<Row>
										<AppMainCol
											boxed={true}
											onTop={true}
											className={styles.MainColWrapper}
										>
											<SearchPageNoAdvancedFilterResults
												resetFilters={resetFilters}
												searchTerm={searchTerm}
											/>
										</AppMainCol>

										<AppSideColMobileDrawer
											background="body"
											isOpen={false}
											idname={searchFilterDrawerIdname}
										>
											{
												(() => (
													<AppSideCol boxed={true} onTop={true}>
														<SearchPageFilters
															filterData={filterData}
															applyFilters={applyFilters}
															resetFilters={resetFilters}
															searchMetaData={searchMetaData}
														/>
													</AppSideCol>
												))
											}
										</AppSideColMobileDrawer>
									</Row>
								)
							}
						</UrlFilterConsumer>
					</PageWrapper>
				);
			}

			// otherwise tell the user to search for something else
			return (
				<SearchPageNoSearchResults searchTerm={searchTerm} />
			);
		}

		return (
			<DrawerProvider>
				<PageWrapper
					// breadcrumbs={this.getBreadcrumbs()}
					titleAlign="left"
					nextGenDesign={true}
					disableScrollToTop={true}
				>
					<UrlFilterConsumer>
						{
							({ filterData, applyFilters, resetFilters }) => (
								<React.Fragment>
									<PageHeading
										title={this.generateTitle()}
									>
										<SearchPageFilterSummary
											filterData={filterData}
											drawerIdname={searchFilterDrawerIdname}
										/>
									</PageHeading>

									<Row>
										<AppMainCol
											boxed={true}
											onTop={true}
											className={styles.MainColWrapper}
										>
											{(metadata.loading && !metadata.backgroundLoading) ?
												<div className={styles.LoadingOverlay}><LoadingIndicator /></div>
												: null}
											<Pagination
												metadata={metadata}
												fetchMore={fetchMore}
												LoaderComponent={this.getLoaderComponent(metadata)}
											>
												<MixedEntityGrid items={results} />
											</Pagination>

										</AppMainCol>

										<AppSideColMobileDrawer
											background="body"
											isOpen={false}
											idname={searchFilterDrawerIdname}
										>
											{
												(() => (
													<AppSideCol boxed={true} onTop={true}>
														<SearchPageFilters
															filterData={filterData}
															applyFilters={applyFilters}
															resetFilters={resetFilters}
															searchMetaData={searchMetaData}
														/>
													</AppSideCol>
												))
											}
										</AppSideColMobileDrawer>
									</Row>
								</React.Fragment>
							)
						}
					</UrlFilterConsumer>
				</PageWrapper>
			</DrawerProvider>
		);
	}

	/**
	 * Checks wheter we are currently querying for more than a search word and the default filters we always have
	 * This will let us know whether the user used too many filters and may have no results, because of that
	 */
	private otherFiltersThanStandardFiltersPresent(): boolean {
		const { filterData } = this.props;

		// if we have more than 1 element in otherFilters -> we have more than main filters -> return true
		return (getNonStandardFilterLength(filterData) > 0);
	}

	/**
	 * Returns a component for showing our endless scroll skeletons
	 */
	private getLoaderComponent(metadata) {
		// if is not loading in background -> don't show
		if (!metadata.loading || !metadata.backgroundLoading) {
			return null;
		}

		return (
			<MixedEntityGridSkeleton />
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: "Ergebnisse für: '" + this.props.searchTerm + "'",
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Generates the main title of page
	 */
	private generateTitle = () => {
		const { searchTerm, metadata } = this.props;

		if (!metadata || (metadata.loading && metadata?.totalItemsCount < 1)) {
			return `Lade Ergebnisse für ${searchTerm}`;
		}

		return `${metadata.totalItemsCount} ${((metadata.totalItemsCount === 1) ? 'Ergebnis' : 'Ergebnisse')} für ${searchTerm}`
	}

}


export default SearchPage;


