import * as React from 'react';
import * as styles from '../VendorList/VendorListItem.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';

class VendorListSkeletonItem extends React.PureComponent {
    public render() {
        const vendor = ''
        return (
            <div className={styles.vendorItemWrapper}>
                <a className={styles.vendorItemContainer}>

                    <span className={styles.vendorItemImageContainer}>
                        <SkeletonBlock style={{ width: 50, height: 21, marginTop: 5 }} />
                    </span>

                    <span className={styles.vendorItemInfoContainer}>

                        <span className={styles.vendorItemTextContainer}>

                            <SkeletonInline width={Math.floor((Math.random() * 10) + 4) * (14)} />

                        </span>
                    </span>
                </a>


                <span className={styles.vendorItemIconContainer}>
                    <SkeletonInline width={10} />
                </span>
            </div>
        );
    }
}

export default VendorListSkeletonItem;
