import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import ProductPromotionGridSkeleton
	from '../../../Promotion/components/ProductPromotionGrid/ProductPromotionGridSkeleton';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import PromotionBookmarkListingPage from '../../components/PromotionBookmarkListingPage/PromotionBookmarkListingPage';
import BookmarkService from '../../services/BookmarkService';

interface IPromotionBookmarkListingPageContainerProps {
	data?: IProductPromotionType[],
	limit?: number;
}

class PromotionBookmarkListingPageContainerInner extends React.Component<IPromotionBookmarkListingPageContainerProps & IWithDataProps> {
	public render() {
		const { data, metadata, fetchMore, ...restProps } = this.props;
		
		return (
			<Pagination
				LoaderComponent={<ProductPromotionGridSkeleton limit={((metadata && metadata.totalItemsCount || 2) - data.length)} />}
				fetchMore={fetchMore}
				metadata={metadata}
				forceShow={true}
			>
				<PromotionBookmarkListingPage
					bookmarks={this.getOnlyBookmarkedEntries(data)}
					{...restProps}
				/>
			</Pagination>
		);
	}

	/**
	 * Make sure only bookmarked promotions are shown.
	 * This is helpful if the user removes an item from bookmarks, to make sure it disappears right away.
	 *
	 * @param entries
	 */
	private getOnlyBookmarkedEntries = (entries: IProductPromotionType[]) => {
			return entries.filter((entry: IProductPromotionType) => {
				return BookmarkService.isMarked('promotion', entry.slug);
			});
	}
}

export default function PromotionBookmarkListingPageContainer (props: IPromotionBookmarkListingPageContainerProps) {
	const limit = ( props.limit ) ? props.limit : 16;

	const RenderComponent = withData(
		PromotionBookmarkListingPageContainerInner,
		'promotionBookmarkReducer',
		`promotionBookmarkListing`,
		{
			size: limit,
		},
		{
			additionalStateProperties: ['updateQuery'],
			LoadingComponent: () => (
				<ProductPromotionGridSkeleton limit={16} />
			),
		}
	);

	return <RenderComponent {...props} />
}
