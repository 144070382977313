import * as React from 'react';
import { ReactNode } from 'react';
import { withRouter } from 'react-router';
import * as layoutStyles from '../Layout.scss';
import * as styles from './SubNavigationWrapper.scss';

declare const process: any;

interface ISubNavigationWrapperProps {
    children?: ReactNode | ReactNode[];
    submenuId: string;
    currentSubmenu: string;
    className?: string;
}

// Mostly commented out for now due to scrolling issues in the sub-navigation bar
// It is still connected, but it is not used
class SubNavigationWrapper extends React.Component<ISubNavigationWrapperProps> {
    private showNavigationBar = true;

    public render() {
        const { children, className } = this.props;

        return (
            <React.Fragment>
                <div id="subnavigation" className={`${styles.SubNavigationWrapper} ${className}`}>
                    <div id="subnavigation-container" className={`${layoutStyles.containerFluid} ${styles.SubNavigationContainer}`}>
                        <div className={styles.SubNavigationInner}>{children}</div>
                    </div>
                </div>
                <div className={`${styles.SubNavigationSpacer} subnavigation-spacer`} />
            </React.Fragment>
        );
    }
}

export default withRouter(SubNavigationWrapper);
