import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import LeafletsByVendorInState from '../App/modules/Leaflet/components/LeafletsByVendorInState/LeafletsByVendorInState';
import { withClickbait, withTitlePostfix, pluralizerLeaflets, currentEntryWord } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';


class LeafletsByVendorInStateRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await LeafletsByVendorInStateRouteProps(state);

		return { reduxData };
	}

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403488]}
					mobileIds={[4403478, 4403477]}
					superbannerId={4403489}
					sitebarId={4403487}
				/>

				<Header />

				<MainNavigation />

				<FlexWrapper>
					<div>
						<LeafletsByVendorInState
							vendorSlug={query.vendor + ''}
							stateSlug={query.state + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403487}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		LeafletsByVendorInStateRoute,
		(props: any) => `lvst-${props.match.params.vendor}-${props.match.params.state}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => 
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${routeMetadata.vendor.title} ${pluralizerLeaflets(routeMetadata.totalItemsCount)} für ${routeMetadata.state.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.vendor.title.toUpperCase()} ${pluralizerLeaflets(routeMetadata.totalItemsCount, true)} » ${routeMetadata.state.title}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`${routeMetadata.state.title} Aktionen & Angebote für ${routeMetadata.vendor.title.toUpperCase()} » alle Schnäppchen auf einen Blick ✓ Preise vergleichen ✓ Rabatt sichern`, '✓ Mehr erfahren!'),
		} as IRouteMetadataSetterType
	)
);
