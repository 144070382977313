import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionsAggregatedByVendorsWidget
	from '../../components/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidget';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';
import { getPromotionTypeApiFilterString } from '../../utils/fetchTypeHelper';
import DataStateService from '../../../../services/DataStateService';

interface IPromotionsAggregatedByVendorsWidgetContainerProps {
	title: string;
	widgetKey: string;

	promotionLabel?: string;
	promotionLabelPlural?: string;

	limit?: number;
	placeholderLimit?: number;
	page?: number;

	type?: 'promotions' | 'leaflets';

	style?: IWidgetStyleType;
	orderBy?: 'promotionCount' | 'createdAt';
	orderDir?: 'desc';

	fetchPriceProductPromotion?: boolean,
	fetchAssortementProductPromotions?: boolean,
	fetchProductPromotionGridGroupeds?: boolean,
	fetchVoucherProductPromotions?: boolean,

	routeCallbk: (item: IPromotionsAggregatedByVendorType) => { name: string, params: { [ key: string ]: any } },

	brandSlug?: SlugType,
	excludeSlug?: SlugType,
	vendorSlug?: SlugType,
	stateSlug?: SlugType,
	productGroupSlug?: SlugType,
	industrySlug?: SlugType,
}

class PromotionsAggregatedByVendorsWidgetContainerInner extends React.Component<IPromotionsAggregatedByVendorsWidgetContainerProps & IWithDataProps> {
	public static defaultProps = {
		promotionLabel: 'Aktion',
		promotionLabelPlural: 'Aktionen',
	};

	public render () {
		const { data, widgetKey, title, promotionLabel, promotionLabelPlural, routeCallbk } = this.props;

		if (this.getVendorsRespectingExclude(data).length < 1) {
			return null;
		}

		return (
			<PromotionsAggregatedByVendorsWidget
				title={title}
				aggregatedEntries={this.getVendorsRespectingExclude(data)}
				widgetKey={widgetKey}
				promotionLabel={promotionLabel}
				promotionLabelPlural={promotionLabelPlural}
				routeCallbk={routeCallbk}
			/>
		);
	}

	/**
	 * Makes sure to only return entries, we haven't excluded
	 *
	 * @param data
	 */
	private getVendorsRespectingExclude (data: IPromotionsAggregatedByVendorType[]): IPromotionsAggregatedByVendorType[] {
		const { excludeSlug } = this.props;

		if (!excludeSlug) {
			return data;
		}

		return data.filter((vendorAggregated: IPromotionsAggregatedByVendorType) => {
			if (vendorAggregated.group.slug === excludeSlug) {
				return false;
			}

			return true;
		});
	}
}

export default function PromotionsAggregatedByVendorsWidgetContainer (props: IPromotionsAggregatedByVendorsWidgetContainerProps) {
	let limit = ( props.limit ) ? props.limit : 5;
	if (props.excludeSlug) {
		limit++;
	}

	const promotionTypes = getPromotionTypeApiFilterString(props);

	const RenderComponent = withData(
		PromotionsAggregatedByVendorsWidgetContainerInner,
		'promotionsAggregatedByVendorsWidgetReducer',
		`promotions-aggregated-by-vendors-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			page: props.page,
			size: limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			promotion_type: promotionTypes,

			entityType: props.type,

			brand: props.brandSlug,
			vendor: props.vendorSlug,
			state: props.stateSlug,
			product_group: props.productGroupSlug,
			industry: props.industrySlug,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={( props.placeholderLimit ) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={( props.placeholderLimit ) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		},
	);

	return <RenderComponent {...props} />;
}
