export const FavouriteVendors = [
    { title: 'Merkur', link: '/v/merkur/' },
    { title: 'Penny', link: '/v/penny/' },
    { title: 'Billa', link: '/v/billa/' },
    { title: 'Hofer', link: '/v/hofer/' },
    { title: 'Spar', link: '/v/spar/' },
    { title: 'Interspar', link: '/v/interspar/' },
    { title: 'Zgonc', link: '/v/zgonc/' },
    { title: 'ATU', link: '/v/a-t-u/' },
    { title: 'Dänisches Bettenlager', link: '/v/daenisches-bettenlager/' },
    { title: 'Conrad', link: '/v/conrad/' },
    { title: 'Bipa', link: '/v/bipa/' },
    { title: 'XXXLutz', link: '/v/xxxlutz/' },
];

export const FavouritePromotions = [
    { title: 'Bier Aktionen', link: '/ppcdest/lebensmittel/getraenke/alkoholische-getraenke/bier/' },
    { title: 'Red Bull Aktionen', link: '/b/red-bull/' },
    { title: 'Süßigkeiten Aktionen', link: '/ppcdest/lebensmittel/suesswaren-knabbereien/' },
    { title: 'Coca Cola Aktionen', link: '/b/coca-cola/' },
    { title: 'Pampers Aktion', link: '/ppcb/spielzeug-baby/pampers/' },
    { title: 'Kaffee Aktionen', link: '/ppcdest/lebensmittel/kaffee-tee-kakao/' },
    { title: 'Laptop Angebote', link: '/ppcdest/elektro-elektronik/computer/notebooks-tablets/' },
    { title: 'Getränke Aktionen', link: '/ppcdest/lebensmittel/getraenke/' },
    { title: 'Dänisches Bettenlager', link: '/v/daenisches-bettenlager/' },
    { title: 'Matratzen Aktionen', link: '/ppcdest/wohnen-leben/schlafen/matratzen/' },
    { title: 'Persil Aktionen', link: '/b/persil/' },
    { title: 'Aperol Angebot', link: '/b/aperol/' },
];

export const FavouritePromotionsByVendor = [
    { title: 'Merkur Angebote', link: '/pv/merkur/' },
    { title: 'Billa Angebote', link: '/pv/billa/' },
    { title: 'Lidl Angebote', link: '/pv/lidl/' },
    { title: 'Spar Angebote', link: '/pv/spar/' },
    { title: 'Metro Angebote', link: '/pv/metro/' },
    { title: 'Hofer Angebote', link: '/pv/hofer/' },
    { title: 'Interspar Angebote', link: '/pv/interspar/' },
    { title: 'Penny Angebote', link: '/pv/penny/' },
    { title: 'Sports Direct Angebote', link: '/pv/sportsdirect/' },
    { title: 'ATU Angebote', link: '/pv/a-t-u/' },
];

export const FavouriteLeafletsByVendor = [
    { title: 'Billa Prospekt', link: '/lv/billa/' },
    { title: 'Bipa Prospekt', link: '/lv/bipa/' },
    { title: 'Hofer Prospekt', link: '/lv/hofer/' },
    { title: 'Lidl Prospekt', link: '/lv/lidl/' },
    { title: 'Möbelix Prospekt', link: '/lv/moebelix/' },
    { title: 'Merkur Prospekt', link: '/lv/merkur/' },
    { title: 'Müller Prospekt', link: '/lv/mueller/' },
    { title: 'ATU Prospekt', link: '/lv/a-t-u/' },
    { title: 'Interspar Prospekt', link: '/lv/interspar/' },
    { title: 'Media Markt Prospekt', link: '/lv/media-markt/' },
];
