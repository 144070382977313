export const isLeafletTypeStandard = (type: string):Boolean => {
    return type === 'BASIC';
}

export const isLeafletTypeBasis = (type: string):Boolean => {
    return type === 'BASIC';
}

export const isLeafletTypePremium = (type: string):Boolean => {
    return type === 'PREMIUM';
}