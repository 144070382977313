import * as React from 'react';
import TagItem from '../../../../components/Layout/TagItem/TagItem';

interface IMostUsedQueryProps {
	query: string;
}

class MostUsedQuery extends React.Component<IMostUsedQueryProps, {}> {

	public render () {
		const { query } = this.props;

		return (
			<TagItem
				route="SearchRoute"
				queryParams={{
					q: query,
				}}
				onClick={this.setHeaderSearchField}
			>
				{query}
			</TagItem>
		);
	}

	/**
	 * Sets the new searchterm into the header search field
	 */
	private setHeaderSearchField = () => {
		const inputDom = document.getElementById('mainHeaderSearchInput');
		if (inputDom) {
			inputDom.value = this.props.query;
		}
	}
}

export default MostUsedQuery;
