import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IVendorBookmarkType } from '../../types/IVendorBookmarkType';
import * as vendorBookmarkActions from './vendorBookmarkActions';
import { VENDOR_BOOKMARK_RERENDER } from './vendorBookmarkActions';

export type VendorBookmarkAction = ActionType<typeof vendorBookmarkActions>;

export interface IVendorBookmarkStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IVendorBookmarkType };
	readonly queries: { [queryKey: string]: IVendorBookmarkType[] };
	updateQuery: number,
}

const initialState = baseInitialState as IVendorBookmarkStateType;
initialState.updateQuery = 0;

export function vendorBookmarkReducer(
	state = initialState as IVendorBookmarkStateType,
	action: VendorBookmarkAction,
) {
	const Reducer = new BaseReducer('vendorBookmarkReducer', 'slug');

	return Reducer.extend(state, action, (innerState: IVendorBookmarkStateType, innerAction: any) => {
		switch (innerAction.type) {
			case VENDOR_BOOKMARK_RERENDER:
				return Object.assign({}, innerState, {
					updateQuery: (innerState.updateQuery + 1)
				});

			default:
				return innerState;
		}
	});
}
