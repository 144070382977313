import * as React from 'react';
import * as styles from './LeafletItemStickerModal.scss';
import Button from '../../../../components/General/Button/Button';
import { ILeafletType } from '../../types/ILeafletType';

interface ILeafletItemStickerModalPropsType {
    onCloseModal?:() => void;
    data: ILeafletType;
}

const LeafletItemStickerModal = (props: ILeafletItemStickerModalPropsType) => {

    const { onCloseModal, data } = props;
    
    return (
        <div className={styles.leafletItemStickerModalWrapper}>

            <img src={require('./Banner-Flugblatt2.png')} alt="Benutzerkonto löschen"/>

            <div className={styles.leafletItemStickerModalHeader}>
                {(data.discountType === "STICKER") ? 
                        "Nur in deinem Postkasten findest du bei diesem Prospekt zusätzliche Rabattsticker!" 
                    : (data.discountType === "VOUCHER") ? 
                        "Nur in deinem Postkasten findest du bei diesem Prospekt zusätzliche Rabattgutscheine!" 
                    : null
                }
                
            </div>

            <div className={styles.leafletItemStickerModalText}>
                Sag ja zum Flugblatt und spare noch mehr bei deinem nächsten Einkauf!
            </div>

            <Button
                theme="primary"
                size="xl"
                fullWidth={true}
                href="https://news.post.at/surveyview/?p=zaab0029f84152904cc46817da00dee4c"
                target="_blank"
            >
                Aufkleber gratis bestellen
            </Button>

            <div className={styles.leafletItemStickerModalCloseButton}>
                <Button
                    theme="default"
                    size="xl"
                    fullWidth={true}
                    onClick={onCloseModal}
                >
                    Schliessen
                </Button>
            </div>
           
        </div>
    );

}

export default LeafletItemStickerModal;
