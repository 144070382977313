import { DateTime } from 'luxon';
import * as React from 'react';
import { IShopType } from '../../types/IShopType';
import OpeningHourLine from './OpeningHourLine';
import * as styles from './OpeningHourLine.scss';



interface IOpeningHoursProps {
	shop: IShopType;
	mode?: 'next' | 'current' | 'all';
	opennedLabel?: string;
}
class OpeningHours extends React.PureComponent<IOpeningHoursProps, {}> {

	/**
	 * Converts the currentPeriod object into a human readable string
	 *
	 * @param currentPeriod
	 * @param dayAdder
	 *
	 * @return {string}
	 */
	private static convertPeriodToHumanReadableString (currentPeriod: any, dayAdder: number): string | undefined {
		// if this is not open yet
		const currentTime   = DateTime.local();
		let openTimeObject  = currentTime.plus({ days: dayAdder }).set({
			hour: +currentPeriod.open.time.substr(0, 2),
			minute: +currentPeriod.open.time.substr(2, 3),
		});

		const closeTimeObject  = openTimeObject.set({
			hour: +currentPeriod.close.time.substr(0, 2),
			minute: +currentPeriod.close.time.substr(2, 3),
		});

		// if is not open yet
		if (currentTime < openTimeObject) {
			// TRANSLATE
			if (currentTime.hasSame(openTimeObject, 'day')) {
				return `Öffnet um <span>${openTimeObject.toLocaleString(DateTime.TIME_24_SIMPLE)} Uhr</span>`;
			} else if (currentTime.plus({ day: 1 }).hasSame(openTimeObject, 'day')) {
				return `Öffnet morgen um <span>${openTimeObject.toLocaleString(DateTime.TIME_24_SIMPLE)} Uhr</span>`;
			} else {
				return `Öffnet <span>${openTimeObject.toFormat('EEEE')}</span> um <span>${openTimeObject.toLocaleString(DateTime.TIME_24_SIMPLE)} Uhr</span>`;
			}
		}

		// if is still open
		if (currentTime < closeTimeObject) {
			// TRANSLATE
			return `Geöffnet bis <span>${closeTimeObject.toLocaleString(DateTime.TIME_24_SIMPLE)} Uhr</span>`;
		}

		// TRANSLATE
		return;
	}

	public render () {
		const { mode } = this.props;
		const { shop } = this.props;

		if (!shop.openingHoursPeriods || shop.openingHoursPeriods.length < 1) {
			return null;
		}

		if (mode === 'next') {
			const humanReadableString = this.getNextOpeningHours();
			return (
				<span dangerouslySetInnerHTML={{ __html: humanReadableString }} />
			);
		}

		if (mode === 'current') {
			return this.getOpeningHours(true);
		}

		return this.getOpeningHours(false);
	}

	/**
	 * Checks all opening hour periods and only returns the one we are interested in today
	 */
	private getNextOpeningHours () {
		const { shop } = this.props;
		const openingHoursPeriods = shop.openingHoursPeriods;

		if (!openingHoursPeriods) {
			return;
		}


		// define today
		let todayNumeric = new Date().getUTCDay();
		if (todayNumeric === 0) {
			todayNumeric = 7;
		}

		// create a mapped array of opening hours
		const openingHourMap: any = {};
		for (const period of openingHoursPeriods) {
			openingHourMap[period.open.day] = period;
		}

		// walk through at least 8 days to make sure we capture a full week of days
		for (let dayAdder = 0; dayAdder < 9; dayAdder++) {
			let currentDayNumeric = (todayNumeric + dayAdder) % 7;
			if (currentDayNumeric === 0) {
				currentDayNumeric = 7;
			}

			if (openingHourMap[currentDayNumeric]/* && (currentDayNumeric >= todayNumeric)*/) {
				const openingInfo = OpeningHours.convertPeriodToHumanReadableString(openingHourMap[currentDayNumeric], dayAdder);
				if (openingInfo) {
					return openingInfo;
				}
			}
		}

		return;
	}

	/**
	 * Checks all opening hour periods and only returns the one we are interested in today
	 */
	private getOpeningHours(onlyToday = true) {
		const { shop, opennedLabel } = this.props;
		const openingHoursPeriods = shop.openingHoursPeriods;
		const retArr = [];

		// get current day of the week
		let todayNumeric = new Date().getUTCDay();
		if (todayNumeric === 0) {
			todayNumeric = 7;
		}

		// loop through all periods and get only the ones, that are interesting for today
		const periodsGroupedByDay: any = {};
		for (const period of openingHoursPeriods) {
			if (typeof periodsGroupedByDay[period.open.day] === 'undefined') {
				periodsGroupedByDay[period.open.day] = [];
			}

			periodsGroupedByDay[period.open.day].push(period)
		}

		for (const periodDay of Object.keys(periodsGroupedByDay)) {

			if (+periodDay === todayNumeric) {
				retArr.push(
					<OpeningHourLine
						key={`openinghour-${periodDay}`}
						periods={periodsGroupedByDay[periodDay]}
						dayNumeric={+periodDay}
						highlight={true}
						opennedLabel={opennedLabel}
					/>
				);
			} else if (!onlyToday) {
				retArr.push(
					<OpeningHourLine
						periods={periodsGroupedByDay[periodDay]}
						dayNumeric={+periodDay}
						key={`openinghour-${periodDay}`}
						highlight={false}
					/>
				);
			}
		}

		if (retArr.length < 1 && onlyToday) {
			retArr.push(
				<OpeningHourLine
					periods={null}
					dayNumeric={todayNumeric}
					key={`openinghour-${todayNumeric}`}
					highlight={true}
					opennedLabel={opennedLabel}
				/>
			);
		}

		return (
			<div>
				{retArr}
				<span className={styles.SubHeading}>Öffnungszeiten können zu den Felertagen abweichen </span>
			</div>
		);
	}
}

export default OpeningHours;
