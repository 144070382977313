import * as React from 'react';
import { DateTime } from 'luxon';
import * as styles from './MixedEntityItem.scss';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Link from '../../../Base/components/Link/Link';
import { IMixedEntityType } from '../../types/IMixedEntityType';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import LeafletRelativeValidDate from '../../../Leaflet/components/LeafletRelativeValidDate/LeafletRelativeValidDate';
import { onlyRelativeDateHoferHack } from '../../../Leaflet/store/Leaflet/leafletActions';

interface IMixedEntityLeafletItemProps {
	data: IMixedEntityType;
	columns?: '1' | '2' | '3' | '4' | '5';
	onItemClick?: (entity: IMixedEntityType) => void;
	onItemLoad?: (entity: IMixedEntityType) => void;
}

class MixedEntityLeafletItem extends React.Component<IMixedEntityLeafletItemProps, {}> {
	public componentDidMount() {
	  const { data: leaflet, onItemLoad } = this.props;
	  const expired = DateTime.local().startOf('day') > DateTime.fromISO(leaflet.validTo);
  
	  // don't track it if it is expired
	  if (expired || !leaflet?.id) {
		return;
	  }
  
	  InternalTrackingService.batchTrack(`leaflet-impression-${leaflet.id}`, 'LEAFLET_IMPRESSION', {
		leafletId: leaflet.id,
		vendorId: leaflet.vendorId ?? '',
	  });

	  if (onItemLoad) {
		onItemLoad(leaflet);
	  }
	}

	public render () {
		const { data, columns, onItemClick } = this.props;

		const validFrom = (data.validFrom) ? DateTime.fromISO(data.validFrom) : null;
		const validTo = (data.validTo) ? DateTime.fromISO(data.validTo) : null;

		return (
			<Link
				route="LeafletDetailRoute"
				params={{
					leaflet: data.slug
				}}
			>
				<a
					onClick={this.handleOnClickEvent}
					className={`${styles.MixedEntityItemWrapper} ${styles['MixedEntityItemWrapperColumns' + columns]}`}
				>
					<span
						className={styles.MixedEntityItemWrapperInner}
					>
						<span className={styles.ImageWrapper}>
							<ImageEnhanced
								className={styles.Image}
								source={data.image}
							/>
						</span>

						<span className={styles.Content}>
							<span className={styles.ActionBar}>
								<span className={styles.ActionBarGrow}>
									<span className={styles.Heading}>
										{data.title}
										{/* // if title is too long -> show fade out */}
										{ (data.title?.length < 55) ? null :
											<span className={styles.HeadingFadeout} />
										}
									</span>

									{( !data.validFrom || !data.validTo ) ? null :
										<span className={styles.Block}>
											{data.onlyRelativeDate || onlyRelativeDateHoferHack(data) ?
												<LeafletRelativeValidDate validFrom={validFrom} validTo={validTo} />
												:
												<span>{validFrom.setLocale('de').toFormat('dd.LL')} - {validTo.setLocale('de').toFormat('dd.LL.yyyy')}</span>
											}
									 </span>
									}
								</span>
								<span className={styles.ActionBarShrink}>
									{/*<BookmarkIconContainer
										entity="promotion"
										slug={data.slug}
									/>*/}
								</span>
							</span>
						</span>
					</span>
				</a>
			</Link>
		);
	}

	private handleOnClickEvent = () => {
        const { onItemClick, data } = this.props;

        if (onItemClick) {
            onItemClick(data);
        }
    };
}


export default MixedEntityLeafletItem;


