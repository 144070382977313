import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as themeworldActions from './themeworldActions';

export type ThemeworldAction = ActionType<typeof themeworldActions>;

export interface IThemeworldStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IThemeworldType };
	readonly queries: { [queryKey: string]: IThemeworldType[] };
}

export function themeworldReducer(
	state = baseInitialState as IThemeworldStateType,
	action: ThemeworldAction,
) {
	const Reducer = new BaseReducer('themeworldReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTIONentryMap_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
