import { IWithRouteMetadataType } from 'App/types/IWithRouteMetadataType';
import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionsAggregatedByProductGroupsWidgetContainer 	from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import withShoppingCenter from '../../../ShoppingCenter/hocs/withShoppingCenter';
import PromotionsInShoppingCenterAndProductGroupShopWidget from './PromotionsInShoppingCenterAndProductGroupShopWidget';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IPromotionsInShoppingCenterAndProductGroupProps {
	shoppingCenterSlug: SlugType;
	productGroupSlug: SlugType;
}

class PromotionsInShoppingCenterAndProductGroup extends React.Component<IPromotionsInShoppingCenterAndProductGroupProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {
		const { routeMetadata, productGroupSlug, shoppingCenterSlug } = this.props;

		const shoppingCenterName = (routeMetadata && routeMetadata.shoppingCenter && routeMetadata.shoppingCenter.title) ? routeMetadata.shoppingCenter.title : shoppingCenterSlug;
		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		if (!routeMetadata) {
			return null;
		}
		
		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridContainer
								limit={12}
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								shoppingCenterSlug={shoppingCenterSlug}
								productGroupSlug={productGroupSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								onlyNearbyPromotions={false}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							{withShoppingCenter(
								PromotionsInShoppingCenterAndProductGroupShopWidget,
								shoppingCenterSlug,
								{}
							)}

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>


							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey={`ppcsc-${shoppingCenterSlug}-${productGroupSlug}-product-group-widget`}
								title={`Weitere Produktkategorien  mit Aktionen`}
								shoppingCenterSlug={shoppingCenterSlug}
								exclude={productGroupSlug}
								placeholderLimit={5}
								limit={50}
								routeCallbk={this.routerCallbkProductGroupListWidget}
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Einkaufszentren',
				route: 'ShoppingCenterListingRoute',
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.shoppingCenter.title : '',
				route: 'ShoppingCenterDetailRoute',
				routeParams: {shoppingCenter: this.props.shoppingCenterSlug},
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.pageTitle : '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		
		const { shoppingCenterSlug } = this.props;

		return {
			name: 'PromotionsInShoppingCenterAndProductGroupRoute',
			params: {
				productGroup: item.group.slug,
				shoppingCenter: shoppingCenterSlug,
			},
		};
	}
}

export default PromotionsInShoppingCenterAndProductGroup;
