import { isServer } from '../../boilerplate/razzle/razzleUtils';
import queryString from 'query-string';

const PROMOTIONS_ROUTE_LIST = ['/aktionen/', '/gutscheine/', '/gewinnen/', '/tw/'];

const LayoutService = {
    /**
     * Let's us know whether it was checked or not
     */
    isAppShellStateChecked: false,

    /**
     * Shows if normal app shell/layout is enabled. As default true
     */
    isAppShellEnabled: true,

    /**
     * A function which returns true if we need to remove App shell for InAppWebview
     */
    isAppShellDisabled(): boolean {
        if (!this.isAppShellStateChecked) {
            // We can't check query string on server :)
            if (isServer()) {
                return false;
            }

            // parsing query string
            const query = queryString.parse(window.location.search);

            // if "noAppShell" is true in query, we say that app shell is not enabled
            if (query['noAppShell'] === 'true') {
                this.isAppShellEnabled = false;
            }

            // setting up that item has been checked once not to avoid unnecessary calls
            this.isAppShellStateChecked = true;
        }

        return !this.isAppShellEnabled;
    },

    /**
     * Returns true if we are on promotions route so top navigation takes some space
     */
    isPromotionsRoute(path: string): boolean {
        return PROMOTIONS_ROUTE_LIST.includes(path);
    },
};

export default LayoutService;
