import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IPromotionBookmarkType } from '../../types/IPromotionBookmarkType';
import * as promotionBookmarkActions from './promotionBookmarkActions';
import { PROMOTION_BOOKMARK_RERENDER } from './promotionBookmarkActions';

export type PromotionBookmarkAction = ActionType<typeof promotionBookmarkActions>;

export interface IPromotionBookmarkStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IPromotionBookmarkType };
	readonly queries: { [queryKey: string]: IPromotionBookmarkType[] };
	updateQuery: number,
}

const initialState = baseInitialState as IPromotionBookmarkStateType;
initialState.updateQuery = 0;

export function promotionBookmarkReducer(
	state = initialState as IPromotionBookmarkStateType,
	action: PromotionBookmarkAction,
) {
	const Reducer = new BaseReducer('promotionBookmarkReducer', 'slug');

	return Reducer.extend(state, action, (innerState: IPromotionBookmarkStateType, innerAction) => {
		switch (innerAction.type) {
			case PROMOTION_BOOKMARK_RERENDER:
				return Object.assign({}, innerState, {
					updateQuery: (innerState.updateQuery + 1)
				});

			default:
				return innerState;
		}
	});
}
