import * as React from 'react';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import NewsletterSignupComponent from '../../../Newsletter/components/Newsletter/NewsletterSignupComponent';
import ProductPromotionDetailHeaderSkeleton from '../ProductPromotionDetailHeader/ProductPromotionDetailHeaderSkeleton';
import ProductPromotionGridSkeleton from '../ProductPromotionGrid/ProductPromotionGridSkeleton';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import ProductPromotionDetailAction from '../ProductPromotionDetailAction/ProductPromotionDetailAction';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import NearestShopWidgetContainerSkeleton from '../../../Vendor/containers/NearestShopWidget/NearestShopWidgetContainerSkeleton';
import SkeletonBlock from '../../../../../App/components/Layout/Skeleton/SkeletonBlock';
import ProductPromotionInOtherShopsContainerSkeleton
	from '../../containers/ProductPromotionInOtherShops/ProductPromotionInOtherShopsContainerSkeleton';


class ProductPromotionDetailSkeleton extends React.PureComponent<{}> {

	public render() {

		return (
			<PageWrapper>
				<div>&nbsp;</div>
				<AppMainCol boxed={true} onTop={true}>

					<Row>

						<AppMainColBox boxed={true} nested={true}>

							<ProductPromotionDetailHeaderSkeleton />

							<ProductPromotionDetailAction promotion={{} as IProductPromotionType} />

							<NearestShopWidgetContainerSkeleton
								radius={50000}
								widgetKey={`promotion-skeleton-detail-nearest-shop`}

							>
								<React.Fragment>

									<ProductPromotionInOtherShopsContainerSkeleton
										widgetKey={`promotions-in-other-shops-skeleton`}
									/>

								</React.Fragment>
								<SkeletonBlock style={{ marginTop: 10, marginBottom: 10 }} />
							</NearestShopWidgetContainerSkeleton>


							<ProductPromotionGridSkeleton limit={4} />

							<p>&nbsp;</p>

							<NewsletterSignupComponent />

						</AppMainColBox>


						<AppSideCol boxed={false}>

							<WidgetBoxSkeleton
								type="linkrow"
							/>

						</AppSideCol>

					</Row>
				</AppMainCol>
			</PageWrapper>
		);
	}



}

export default ProductPromotionDetailSkeleton;