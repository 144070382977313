import * as React from 'react';
import AdItem from '../../../../components/General/AdItem/AdItem';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import MixedEntityGrid from '../../../MixedEntity/components/MixedEntityGrid/MixedEntityGrid';
import { IMixedEntityType } from '../../../MixedEntity/types/IMixedEntityType';
import ThemeworldListWidgetContainer from '../../../Themeworld/containers/ThemeworldListWidget/ThemeworldListWidgetContainer';
import { IThemeworldWidgetType } from '../../../Themeworld/types/IThemeworldWidget';
import TagCloudWidgetContainer from '../../containers/TagCloudWidget/TagCloudWidgetContainer';

interface ITagDetailProps {
    mixedEntities: IMixedEntityType[];
}

class TagDetail extends React.Component<ITagDetailProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {
		const { data, routeMetadata, tagSlug } = this.props;

		if (!routeMetadata) {
			return;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1 title={routeMetadata.pageTitle}>
					<div dangerouslySetInnerHTML={{ __html: 'Top Deals' }} />
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>

						<AppMainColBox boxed={true} nested={true}>
                            {data != undefined && data != null && data.length > 0 ? <MixedEntityGrid
                                items={data}
                            >
                                <NoDataError />
							</MixedEntityGrid> : null}
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<TagCloudWidgetContainer
								title="Beliebte Themen"
								limit={10}
								widgetKey={`t-${tagSlug}-tag-cloud`}
								routeCallbk={this.routerCallbkTagCloudWidget}
								orderBy="promotionCount"
								orderDir="desc"
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<ThemeworldListWidgetContainer
								title="Themenwelten"
								limit={10}
								widgetKey={`t-${tagSlug}-themeworlds-widget`}
								routeCallbk={this.routerCallbkThemeworldListWidget}
								btnLabel="Alle Themenwelten"
								btnRoute="ThemeworldListingRoute"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {

		return [
			{
				label: 'Top Deals'
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkTagCloudWidget (item: ITagWidgetItemType) {
		return {
			name: 'TagDetailRoute',
			params: {
				tag: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 */
	private routerCallbkThemeworldListWidget = (item: IThemeworldWidgetType) => {
		return {
			name: 'ThemeworldDetailRoute',
			params: {
				themeworld: item.slug,
			},
		};
	}
}

export default TagDetail;
