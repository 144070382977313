import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import CurrentLeafletWidgetContainer
	from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';
import TagCloudWidgetContainer from '../../../Tag/containers/TagCloudWidget/TagCloudWidgetContainer';
import ProductPromotionGridGroupedContainer
	from '../../containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IPromotionsInIndustryProps {
	industrySlug: SlugType;
}

class PromotionsInIndustry extends React.Component<IPromotionsInIndustryProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, industrySlug } = this.props;

		const industryName = (routeMetadata && routeMetadata.industry && routeMetadata.industry.title) ? routeMetadata.industry.title : industrySlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<ProductPromotionGridGroupedContainer
								enablePagination={true}
								industrySlug={industrySlug}
								moreLabel="Weitere %title% Aktionen"
								moreRouteCallbk={this.routerCallbkPromotionListWidget}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								limit={5}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridGroupedContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							<TagCloudWidgetContainer
								title="Beliebte Themen"
								industrySlug={industrySlug}
								widgetKey={`${routeMetadata.queryKey}-tag-widget`}
								routeCallbk={this.routerCallbkTagListWidget}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<CurrentLeafletWidgetContainer
								title={`${industryName} Prospekte`}
								widgetKey={`${routeMetadata.queryKey}-current-leaflet-widget`}
								limit={3}
								industrySlug={industrySlug}
								btnLabel={`Alle ${industryName} Prospekte`}
								btnRoute="LeafletsInIndustryRoute"
								btnParams={{
									industry: industrySlug,
								}}
							/>
						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.pageTitle: '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: IProductPromotionGroupedType) => {
		const { industrySlug } = this.props;

		return {
			name: 'PromotionsInProductCategoryAndIndustryRoute',
			params: {
				industry: industrySlug,
				productGroup: item.group.slug, // TODO: remove this comment
			},
		};
	};

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkTagListWidget = (item: ITagWidgetItemType) => {
		return {
			name: 'TagDetailRoute',
			params: {
				tag: item.group.slug,
			},
		};
	}
}

export default PromotionsInIndustry;
