import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';

interface IPromotionsAggregatedByProductGroupsWidgetProps {
	aggregatedEntries: IPromotionsAggregatedByProductGroupType[];
	title: string;
	widgetKey?: string;
	promotionLabel?: string;
	promotionLabelPlural?: string;
	style?: IWidgetStyleType;

	routeCallbk: (item: IPromotionsAggregatedByProductGroupType) => { href?: string, name?: string, params?: { [key: string]: any } },

	moreLabel?: string,
	moreRoute?: string;
}

class PromotionsAggregatedByProductGroupsWidget extends React.Component<IPromotionsAggregatedByProductGroupsWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'promotion-list-widget',
		promotionLabel: 'Aktion',
		promotionLabelPlural: 'Aktionen',
	};

	public render() {
		const { aggregatedEntries, title, widgetKey, promotionLabel, promotionLabelPlural, routeCallbk, style, moreLabel, moreRoute } = this.props;

		const moreRouteParams = {
			route: moreRoute,
		}

		return (
			<WidgetBox title={title} style={style}>
				<WidgetBoxContent>
					{aggregatedEntries.map((aggregatedEntry: IPromotionsAggregatedByProductGroupType) => {
						const route = routeCallbk(aggregatedEntry);

						const routeParams = (route.href) ? {
							href: route.href,
						} : {
								route: route.name,
								routeParams: route.params,
							};

						return (
							<WidgetListItemLinkRow
								title={aggregatedEntry.group.title}
								// subtitle={`${aggregatedEntry.totalItemsCount} ${(aggregatedEntry.totalItemsCount === 1) ? promotionLabel : promotionLabelPlural}`} // disabled because the numbers didn't match from core
								key={`${widgetKey}-${aggregatedEntry.group.detailUrlPath}`}
								{...routeParams}
								style={style}
							/>
						);
					}
					)}

					{/* {( !moreLabel ) ? null :
						<WidgetListItemLinkRow
							title={moreLabel}
							key={`${widgetKey}-${moreLabel}`}
							{...moreRouteParams}
							style={style}
						/>
					} */}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default PromotionsAggregatedByProductGroupsWidget;
