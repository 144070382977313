import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { IAlertType } from '../../types/IAlertType';
import Alert from './Alert';
import * as styles from './Alerts.scss';

interface IAlertsProps {
	alerts: IAlertType[];
	onCloseAlert: (id: string) => void;
	onMarkAlertAsShown: (id: string) => void;
	showLeafletDiscountModal?: () => void;
}

class Alerts extends React.PureComponent<IAlertsProps> {
	public render() {
		const { alerts, onCloseAlert, onMarkAlertAsShown, showLeafletDiscountModal } = this.props;

		return (
			<TransitionGroup className={styles.alertWrapper}>
				{ alerts.map((alert: IAlertType) => {
					
					return (
						<CSSTransition
							key={`alert-${alert.id}`}
							timeout={{
								enter: 3000,
								exit: 0,
							}}
							classNames="alert-animation"
						>
							<Alert
								alert={alert}
								onCloseAlert={onCloseAlert}
								onMarkAlertAsShown={onMarkAlertAsShown}
								showLeafletDiscountModal={showLeafletDiscountModal}
							/>
						</CSSTransition>
					);
				})
				}
			</TransitionGroup>
		);
	}
}

export default Alerts;
