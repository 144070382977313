import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import ShopListGroupedByStateContainer from '../../containers/ShopListGroupedByState/ShopListGroupedByStateContainer';
import CurrentLeafletWidgetContainer from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';
import ProductPromotionListWidgetContainer from '../../../Promotion/containers/ProductPromotionListWidget/ProductPromotionListWidgetContainer';
import { IShopGroupedByStateType } from '../../types/IShopGroupedByStateType';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IShopsByVendorAndStateProps {
	stateSlug: SlugType;
	vendorSlug: SlugType;
}

class ShopsByVendorAndState extends React.Component<IShopsByVendorAndStateProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, vendorSlug, stateSlug } = this.props;

		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;
		const vendorName = (routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title) ? routeMetadata.vendor.title : vendorSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<div>
					<AdItem
						desktopAdId={4403495}
						maxHeight={250}
						type="billboard"
					/>
				</div>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true}>

							<ShopListGroupedByStateContainer
								vendorSlug={vendorSlug}
								stateSlug={stateSlug}
								widgetKey={`svst-${stateSlug}-${vendorSlug}`}
								titleLabel={`%city%: %count% ${vendorName} Markt`}
								titleLabelPlural={`%city%: %count% ${vendorName} Märkte`}
								moreLabel={`Alle ${vendorName} Märkte in %city%`}
								moreRouteCallbk={this.routerCallbkShopListGroupedByStateContainer}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403482}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403481}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
								}}
							/>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<ProductPromotionListWidgetContainer
								widgetKey={routeMetadata.queryKey}
								title={`Aktuelle ${vendorName} Aktionen`}
								actionButtonLabel={`Alle aktuellen ${vendorName} Aktionen`}
								actionButtonRouteCallbk={this.routerCallbkPromotionListWidget}
								limit={10}
								orderBy="createdAt"
								orderDir="desc"
								fetchPriceProductPromotion={true}
								vendorSlug={vendorSlug}
								pushRouteMetadata={true}
							/>

							<AdItem
								desktopAdId={4403494}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<CurrentLeafletWidgetContainer
								widgetKey={`svst-widget-${vendorSlug}`}
								title={`Die aktuellen ${vendorName} Prospekte`}
								btnLabel={`Alle ${vendorName} Prospekte`}
								vendorSlug={vendorSlug}
								limit={3}
								orderBy="createdAt"
								orderDir="asc"
								btnRoute="LeafletsByVendorRoute"
								btnParams={{
									vendor: vendorSlug,
								}}
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, vendorSlug, stateSlug } = this.props;

		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;
		const vendorName = (routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title) ? routeMetadata.vendor.title : vendorSlug;

		return [
			{
				label: 'Händler Übersicht',
				route: 'VendorListingRoute',
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.vendor.title : '',
				route: 'VendorDetailRoute',
				routeParams: {vendor: this.props.vendorSlug},
			} as IBreadcrumbItemType,
			{
				label: `${vendorName} Märkte in ${stateName}`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = () => {
		const { stateSlug, vendorSlug } = this.props;

		return {
			name: 'PromotionsByVendorRoute',
			params: {
				vendor: vendorSlug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkShopListGroupedByStateContainer = (item: IShopGroupedByStateType) => {
		const { stateSlug, vendorSlug } = this.props;

		return {
			name: 'ShopsByVendorAndCityRoute',
			params: {
				vendor: vendorSlug,
				city: item.group.slug || item.group.city.slug
			},
		};
	}
}

export default ShopsByVendorAndState;
