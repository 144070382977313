import * as React from 'react';
import * as styles from './RedirectContent.scss';
import RedirectContentImage from './RedirectContentImage.png';
import LoadingIndicatorSmall from '../LoadingIndicator/LoadingIndicatorSmall';
import Button from '../../General/Button/Button';

interface IRedirectContentProps {
	error: boolean;
	targetUrl: string;
}

class RedirectContent extends React.PureComponent<IRedirectContentProps, {}> {
    public render() {
		const { error, targetUrl } = this.props;

        return (
            <div className={styles.RedirectContent}>

				{/* <img src={RedirectContentImage} alt="Du wirst weitergeleitet" /> */}

	             <div>
					{/* <h1>Du verwendest eine alte Version der Aktionsfinder App</h1> */}
					<div><Button href={targetUrl} theme="primary" size="xl">Zum Angebot</Button></div>
	             </div>



            </div>
        );
    }
}

export default RedirectContent;
