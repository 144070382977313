import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionInOtherShopsSkeleton from '../../components/ProductPromotionInOtherShops/ProductPromotionInOtherShopsSkeleton';


export default class ProductPromotionInOtherShopsInnerContainer extends React.PureComponent {
    public render() {


        return (
            <ProductPromotionInOtherShopsSkeleton />
        );
    }
}
