import * as React from 'react';
import withData from '../../../../boilerplate/redux/hocs/withData';
import AdConfig from './AdConfig';
import { IWithDataProps } from '../../../../boilerplate/redux/types/IWithDataProps';
import { Helmet } from 'react-helmet-async';

interface IAdConfigContainerProps {
  checkType?: string;
  slug: string;
  desktopIds?: number[];
  mobileIds?: number[];
  superbannerId?: number;
  sitebarId?: number;
  adFarmRoute?: string;
}

class AdConfigContainerInner extends React.Component<IAdConfigContainerProps & IWithDataProps> {

  public static defaultProps = {
    checkType: 'vendor',
  };

	public render() {

    const { checkType } = this.props;

    // If the vendor is a AF client, we don't show an ad
    if (this.props[checkType] && this.props[checkType].showAds === false) {
      return (
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: `var adition = {};`
          }]}
        />
      );
    }
    
		return (
			<AdConfig	{...this.props} />
		);
	}
}


export default function AdConfigContainer(theProps: IAdConfigContainerProps) {

  const props = Object.assign({
    checkType: 'vendor'
  }, theProps);
  
  const { checkType } = props;

	const RenderComponent = withData(
		AdConfigContainerInner,
    `${checkType}Reducer`,
		`clientCheck-${checkType}-${props.slug}`,
		{
      [checkType]: props.slug,
      checkClient: true,
    },
    {
      isSingleEntry: true,
			primaryKeyName: checkType,
      dataKey: checkType,

      LoadingComponent: () => null,
      ErrorComponent: () => null,
    }

	);

	return <RenderComponent {...props} />;
}

