import * as React from 'react';
import { ComponentType } from 'react';
import withData from '../../../../boilerplate/redux/hocs/withData';
import { SlugType } from '../../Base/types/SlugType';

export default function withVendor (WrapperComponent: ComponentType<any>, vendorSlug: SlugType, props: any = null) {
	const params = {
		vendor: vendorSlug,
		vendorSlug: vendorSlug,
	};

	const RenderComponent = withData(
		WrapperComponent,
		'vendorReducer',
		`vendor-hoc-${vendorSlug}`,
		params,
		{
			isSingleEntry: true,
			primaryKeyName: 'vendor',
			dataKey: 'vendor',
			LoadingComponent: () => {
				return (
					<WrapperComponent {...props} params={params} />
				)
			}
		}
	);

	return (
		<RenderComponent {...props} />
	);
}
