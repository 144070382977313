import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import AccountChangeContainer from '../App/modules/Account/containers/AccountChange/AccountChangeContainer';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import { withRouter } from 'react-router';
import BaseRoute from './_BaseRoute';

class AccountChangePasswordRoute extends BaseRoute {
	public render () {

		return (
			<div>
				<Header />
				<MainNavigation />

				<AccountChangeContainer
					type="password"
				/>

				<Footer fluid={true} />
			</div>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		AccountChangePasswordRoute,
		`accountChangePassword`,
		{
			metaTitle: `Mein Passwort ändern | Aktionsfinder`,
		} as IRouteMetadataSetterType
	)
);
