import { ActionType } from 'typesafe-actions';
import BaseReducer, { baseInitialState } from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IPromotionsAggregatedByCityType } from '../../types/IPromotionsAggregatedByCityType';
import * as promotionsAggregatedByCitysWidgetActions from './promotionsAggregatedByCitysWidgetActions';

export type PromotionsAggregatedByCitysWidgetAction = ActionType<typeof promotionsAggregatedByCitysWidgetActions>;

export interface IPromotionsAggregatedByCitysWidgetCityType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IPromotionsAggregatedByCityType };
	readonly queries: { [queryKey: string]: IPromotionsAggregatedByCityType[] };
}

export function promotionsAggregatedByCitysWidgetReducer(
	state = baseInitialState as IPromotionsAggregatedByCitysWidgetCityType,
	action: PromotionsAggregatedByCitysWidgetAction,
) {
	const Reducer = new BaseReducer('promotionsAggregatedByCitysWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
