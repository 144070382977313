import * as React from 'react';
import AppSideCol, { IAppSideColProps } from './AppSideCol';
import Drawer, { DrawerCloseMethod } from '../../../modules/Base/components/Drawer/Drawer';
import { ReactComponentElement } from 'react';
import Col from '../../../modules/Base/components/Grid/Col';
import { isMobile } from '../../../utils/responsiveUtils';

interface IAppSideColMobileDrawerProps extends Omit<IAppSideColProps, 'children'> {
	children: (closeDrawer: DrawerCloseMethod) => ReactComponentElement<any>;
	drawerProps?: { [key: string]: any };
	idname: string;
	isOpen: boolean;
};

class AppSideColMobileDrawer extends React.Component<IAppSideColMobileDrawerProps, {}> {
	public render() {
		const { children, isOpen, drawerProps, idname, ...restProps } = this.props;

		// if we are in mobile version, remove style from appsidecols, because they will be provided by drawer
		if (isMobile()) {
			restProps.background = 'nostyle';
			restProps.colWrapper = false;
		}

		return (
			<Col size={3} width="33%" className="sideCol">
				<Drawer {...drawerProps} isOpen={isOpen} idname={idname}>
					{
						((closeDrawer: DrawerCloseMethod) =>
							<AppSideCol {...restProps}>
								<React.Fragment>
									{ children(closeDrawer) }
								</React.Fragment>
							</AppSideCol>
						)
					}
				</Drawer>
			</Col>
		);
	}
}

export default AppSideColMobileDrawer;
