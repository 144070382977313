import * as React from 'react';
import * as styles from './HeaderSearchGroup.scss';
import { ReactNode } from 'react';

interface IHeaderSearchGroupProps {
	children: ReactNode | string;
}

class HeaderSearchGroup extends React.Component<IHeaderSearchGroupProps> {
	public render() {
		const { children } = this.props;

		return (
			<legend
				className={styles.groupLegend}
			>

				{children}

			</legend>
		);
	}
}

export default HeaderSearchGroup;
