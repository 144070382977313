const serverErrorMap = {
    201: 'Diese E-Mail Adresse ist bereits in Verwendung.',
    401: 'Falsche E-Mail Adresse oder Passwort.',
    400: 'Fehlerhafte Eingabe',
    404: 'Benutzerkonto konnte nicht gefunden werden.',
    409: 'Diese E-Mail Adresse ist bereits in Verwendung.',
    500: 'Leider ist ein unerwarteter Fehler aufgetreten, bitte versuche es erneut.',
};

export default serverErrorMap;
