import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import AdItem from '../../../../components/General/AdItem/AdItem';
import * as styles from './LotteryListingPage.scss';
import LotteryListContainer from '../../containers/LotteryList/LotteryListContainer';
import MobileAppBanner from '../../../../components/Layout/MobileAppBanner/MobileAppBanner';


class LotteryListingPage extends React.Component<{}> {
	public static defaultProps = {};

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Gewinnen',
			} as IBreadcrumbItemType,
		];
	}

	public render () {

		return (
			<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()}>

				<div>
					<AdItem
						desktopAdId={4493475}
						maxHeight={250}
						type="billboard"
					/>
				</div>

				<H1 title="Gewinnspiele" />

				<Row>
					<AppMainCol boxed={false}>

						{/* <AdItem
							mobileAdId={4493486}
							maxHeight={250}
							type="content"
							title="Mobile Rectangle 1"
							className={styles.adItemWrapper}
						/> */}

                        <LotteryListContainer />

					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<AdItem
								desktopAdId={4493474}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

                            <MobileAppBanner />

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
    }

}

export default LotteryListingPage;
