/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IProductGroupWidgetType } from '../../types/IProductGroupWidgetType';

export const VENDOR_WIDGET_FETCH_ENTRIES = '@BasePipeline/productGroupWidgetReducer_FETCH_ENTRIES';
export const VENDOR_WIDGET_FETCH_ENTRY   = '@BasePipeline/productGroupWidgetReducer_FETCH_ENTRY';
export const VENDOR_WIDGET_ADD_ENTRIES = '@BasePipeline/productGroupWidgetReducer_ADD_ENTRIES';
export const VENDOR_WIDGET_ADD_ENTRY = '@BasePipeline/productGroupWidgetReducer_ADD_ENTRY';

export const VENDOR_WIDGET_SET_ERRORS   = '@BasePipeline/productGroupWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchProductGroupWidgets (queryKey: string) {
	return {
		type: VENDOR_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addProductGroupWidgetEntries (queryKey: string, entries: IProductGroupWidgetType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addProductGroupWidgetEntry (queryKey: string, entry: IProductGroupWidgetType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_WIDGET_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setProductGroupWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
