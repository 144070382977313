import * as React from 'react';
import * as styles from './SearchPageFilters.scss';
import SearchPageFilterGroup from './SearchPageFilterGroup';
import {
	SearchPageFilterItemMetadataType,
	SearchPageFilterValueType,
} from './FilterInputs/SearchPageFilterGroupCheckboxGroupItem';
import {
	UrlFilterApplyFilterType,
	UrlFilterFilterData,
	UrlFilterResetFilterType,
} from '../UrlFilterProvider/UrlFilterProvider';
import { SearchMetaDataType } from '../../store/mixedSearch/mixedSearchActions';
import DrawerContentOutset from '../../../Base/components/Drawer/DrawerContentOutset';
import { DrawerConfigType, DrawerConsumer } from '../../../Base/components/Drawer/DrawerProvider';
import { isMobile } from '../../../../utils/responsiveUtils';

interface ISearchPageFiltersPropType {
	applyFilters: UrlFilterApplyFilterType;
	resetFilters: UrlFilterResetFilterType;
	filterData: { [key: string]: SearchPageFilterValueType };
	searchMetaData: SearchMetaDataType;
}

interface ISearchPageFiltersStateType {
	changeValues?: { [key: string]: SearchPageFilterValueType };
}

interface ISearchFilterConfigType {
	options?: SearchPageFilterItemMetadataType[];
	converter?: 'single';
	requestKey?: string;
	requestKeyPosition?: 'body' | 'query';
}

export const searchFilterConfig: { [key: string]: ISearchFilterConfigType } = {
	q: {
		requestKey: 'query',
	},
	sort: {
		options: [
			{ slug: 'score,desc', title: 'Relevanz', count: -1 },
			{ slug: 'price,asc', title: 'Preis', count: -1 },
			{ slug: 'discount,desc', title: 'Ersparnis', count: -1 },
			{ slug: 'title,asc', title: 'Titel', count: -1 },
			{ slug: 'distance,asc', title: 'Entfernung', count: -1 },
		],
		requestKey: 'sort',
		requestKeyPosition: 'query',
	},
	distance: {
		options: [
			{ slug: '2000', title: '2km', count: -1 },
			{ slug: '10000', title: '10km', count: -1 },
			{ slug: '30000', title: '30km', count: -1 },
			{ slug: '50000', title: '50km', count: -1 },
			{ slug: '100000', title: '100km', count: -1 },
		],
		requestKey: 'radius',
	},
	brand: { requestKey: 'filterBrand' },
	city: { requestKey: 'filterCity' },
	industry: { requestKey: 'filterIndustry' },
	productGroup: { requestKey: 'filterProductGroup' },
	state: { requestKey: 'filterState' },
	type: { requestKey: 'filterType' },
	unit: { requestKey: 'filterUnit' },
	vendor: { requestKey: 'filterVendor' },
};

class SearchPageFilters extends React.Component<ISearchPageFiltersPropType, ISearchPageFiltersStateType> {
	constructor(props: ISearchPageFiltersPropType) {
		super(props);

		this.state = {};
	}
	public render() {
		const { filterData, searchMetaData } = this.props;
		const { changeValues } = this.state;

		const searchId = ` ${filterData.q}`;

		const currentlyActiveValues = filterData || {};

		const currentValues = (changeValues) ? {
			...currentlyActiveValues,
			...changeValues,
		} : currentlyActiveValues;

		return (
			<div className={styles.FilterWrapper}>
				<h2 className="h2SmallStyle">
					<button
						type="button"
						className={`${styles.TitleActionButton} hidden-xs`}
						onClick={this.resetFilters}
					>
						Alle zurücksetzen
					</button>

					<button
						type="button"
						className={`${styles.TitleActionButton} visible-xs`}
						onClick={this.applyFilters}
					>
						Fertig
					</button>

					Filter
				</h2>


				<div className={styles.FilterGroup}>
					<DrawerConsumer >
						{
							({ closeDrawer, openDrawer }) => (
								<DrawerContentOutset>

									{/*### SORT ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="sort"
										metadataOptions={searchFilterConfig.sort.options}
										label="Sortierung"
										values={currentValues['sort']}
										type="select"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
										defaultState="open"
									/>

									{/*### TYPE ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="type"
										metadataOptions={searchMetaData.optionType}
										label="Typ"
										values={currentValues['type']}
										type="multiselect"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
										defaultState="open"
									/>

									{/*### VENDOR ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="vendor"
										metadataOptions={searchMetaData.optionVendor}
										label="Händler"
										values={currentValues['vendor']}
										type="multiselect"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
										defaultState="open"
										showText
										showSeachField
									/>

									{/*### PRODUCT GROUP ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="productGroup"
										metadataOptions={searchMetaData.optionProductGroup}
										label="Produktkategorien"
										values={currentValues['productGroup']}
										type="multiselect"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
										defaultState="open"
										showSeachField
									/>

									{/*### BRAND ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="brand"
										metadataOptions={searchMetaData.optionBrand}
										label="Marken"
										values={currentValues['brand']}
										type="multiselect"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
										defaultState="open"
										showSeachField
									/>

									{/*### INDUSTRIES ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="industry"
										metadataOptions={searchMetaData.optionIndustry}
										label="Branchen"
										values={currentValues['industry']}
										type="multiselect"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
									/>

									{/*### UNIT ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="unit"
										metadataOptions={searchMetaData.optionUnit}
										label="Verpackung"
										values={currentValues['unit']}
										type="multiselect"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
									/>

									{/*### CITY ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="city"
										metadataOptions={searchMetaData.optionCity}
										label="Stadt"
										values={currentValues['city']}
										type="multiselect"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
										hideEmptyOptions={true}
									/>

									{/*### STATE ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="state"
										metadataOptions={searchMetaData.optionState}
										label="Bundesland"
										values={currentValues['state']}
										type="multiselect"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
										hideEmptyOptions={true}
									/>

									{/*### DISTANCE ###*/}
									<SearchPageFilterGroup
										searchId={searchId}
										metadataKey="distance"
										metadataOptions={searchFilterConfig.distance.options}
										label="Umkreis"
										values={currentValues['distance']}
										type="select"
										onChangeValue={this.setFilter}
										closeDrawer={closeDrawer}
										openDrawer={openDrawer}
										defaultState="open"
										showDistanceBadge
									/>

								</DrawerContentOutset>
							)
						}
					</DrawerConsumer>
				</div>

				<button
					type="button"
					className={`${styles.BottomActionButton} visible-xs`}
					onClick={this.resetFilters}
				>
					Filter zurücksetzen
				</button>

			</div>
		);
	}

	/**
	 * Applies filters to do a search
	 */
	private applyFilters = () => {
		const { applyFilters, filterData } = this.props;
		const { changeValues } = this.state;
		const currentlyActiveValues = filterData || {};

		if (!changeValues) {
			applyFilters(filterData, 'now');
			return;
		}

		const changeObj = (changeValues) ? {
			...currentlyActiveValues,
			...changeValues,
		} : currentlyActiveValues;

		applyFilters(changeObj, 'now');
	}

	/**
	 * Set a filter value and shows it immediately
	 * This is used for single choice selections, since waiting is not really needed here
	 *
	 * @param changeObj
	 */
	private setFilter = (changeObj: { [key: string]: any }) => {
		const { applyFilters } = this.props;
		const { changeValues } = this.state;
		let hasChanges = false;

		// If we are not on mobile version, we are going to submit the changes right away
		if (!isMobile()) {
			applyFilters(changeObj, 'now');
		}

		// otherwise (on mobile device) -> just save values in state for now and only submit on button click
		// check if the new value is different from the current value before updating the state
		const newStateObj = (changeValues) ? { ...changeValues } : {};
		for (const key of Object.keys(changeObj)) {
			if (changeObj[key] !== newStateObj[key]) {
				newStateObj[key] = changeObj[key];
				hasChanges = true;
			  }
		}

		this.setState({
			changeValues: newStateObj,
		});
	}


	/**
	 * Resets the filters for this search
	 */
	private resetFilters = () => {
		const { resetFilters } = this.props;
		resetFilters();
	}
}


export default SearchPageFilters;


