import * as React from 'react';
import * as styles from './SearchPageFilterSummary.scss';
import { DrawerConsumer } from '../../../Base/components/Drawer/DrawerProvider';
import { UrlFilterFilterData } from '../UrlFilterProvider/UrlFilterProvider';
import { isMobile } from '../../../../utils/responsiveUtils';
import Icon from '../../../Base/components/Icon/Icon';
import { getNonStandardFilterLength } from '../../utils/getFilterValues';

interface ISearchPageFilterSummaryPropType {
	drawerIdname: string;
	filterData: UrlFilterFilterData;
}

class SearchPageFilterSummary extends React.Component<ISearchPageFilterSummaryPropType, {}> {
	public render () {
		const { drawerIdname, filterData } = this.props;

		if (!isMobile()) {
			return null;
		}

		const filterDataCount = getNonStandardFilterLength(filterData);

		return (
			<DrawerConsumer>
				{
					({ openDrawer }) => (
						<button type="button" onClick={() => openDrawer(drawerIdname)} className={styles.FilterButton}>
							<span className={styles.FilterButtonLabel}>
								Filter
							</span>

							{ (filterDataCount < 1) ? null : <span className={styles.FilterCountBadge}>{filterDataCount}</span> }

							<span className={styles.FilterArrowIcon}>
								<Icon icon="arrow-right" />
							</span>
						</button>
					)
				}
			</DrawerConsumer>
		);
	}
}

export default SearchPageFilterSummary;


