import * as React from 'react';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import TipBox from '../../../../components/Layout/TipBox/TipBox';
import VendorList from '../../../Vendor/components/VendorList/VendorList';
import { IVendorType } from '../../../Vendor/types/IVendorType';
import * as styles from './VendorBookmarkListingPage.scss';


interface IVendorBookmarkListingPageProps {
    bookmarks: IVendorType[],
}

class VendorBookmarkListingPage extends React.Component<IVendorBookmarkListingPageProps, {}> {
	public static defaultProps = {};

	public render () {
		const { bookmarks } = this.props;

		return (
			<div className={styles.Wrapper}>
				<TipBox>
					Hier findest du deine Lieblingshändler und behältst neue Aktionen im Blick.
				</TipBox>

				<VendorList
	                vendors={bookmarks}
	            >
	                <NoDataError />
				</VendorList>
			</div>
		);
	}
}

export default VendorBookmarkListingPage;
