import * as React from 'react';
import { ReactNode } from 'react';
import * as styles from './InfoScreenWrapper.scss';

interface IInfoScreenWrapperPropTypes {
	children?: ReactNode | ReactNode[] | string[],
}

class InfoScreenWrapper extends React.PureComponent<IInfoScreenWrapperPropTypes, {}> {
	public render () {
		const { children } = this.props;

		return (
			<div className={styles.InfoScreenWrapper}>
				{children}
			</div>
		);
	}
}

export default InfoScreenWrapper;
