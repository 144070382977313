import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addLeafletPageEntries,
	LEAFLET_PAGE_FETCH_ENTRIES, setLeafletPageErrors,
} from './leafletPageActions';

/**
 * Fetch entry list
 */
function* fetchLeafletPage(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = Object.assign({
		size: 600,
		page: 0,
	}, action.params);

	// if we get a single leafletSlug -> use this
	const theUrl = `/api/public/v1/leaflets/${params.leaflet}/pages`;
	
	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: theUrl,
					params,
				} as IRequestObject
			);
		});
		
		const theContent = response.getContent('content');

		yield put(addLeafletPageEntries(
			action.queryKey,
			theContent,
			response.getMetaData(),
		));
	} catch (e) {
		yield put(setLeafletPageErrors(action.queryKey, e));
	}
}


export function* leafletPageSaga() {
	yield takeEvery(LEAFLET_PAGE_FETCH_ENTRIES, fetchLeafletPage);
}
