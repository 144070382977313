import * as React from 'react';
import DrawerItem, { DrawerChildrenType, DrawerCloseMethod, DrawerOpenMethod } from './DrawerItem';

export type AddDrawerMethod = (drawerConfig: DrawerConfigType) => void;
export type RemoveDrawerMethod = (idname?: string) => void;

export type DrawerConfigType = {
    idname: string;
    position: 'right'; // TODO: add other positions as well
    content: DrawerChildrenType;
    isOpen: boolean;
    onCloseDrawer: DrawerCloseMethod;
    onOpenDrawer: DrawerOpenMethod;
    level?: number;
};

interface IDrawerProviderPropType {
    children: React.ReactNode | React.ReactNodeArray;
}

export interface DrawerConsumerProps {
    drawers: DrawerConfigType[];
    addDrawer: AddDrawerMethod;
    removeDrawer: RemoveDrawerMethod;
    openDrawer: DrawerOpenMethod;
    closeDrawer: DrawerCloseMethod;
}

const DrawerProviderContext = React.createContext({
    drawers: [],
    addDrawer: () => {},
    removeDrawer: () => {},
    openDrawer: () => {},
    closeDrawer: () => {},
} as DrawerConsumerProps);

export const DrawerConsumer = DrawerProviderContext.Consumer;

class DrawerProvider extends React.Component<
    IDrawerProviderPropType,
    DrawerConsumerProps
> {
    constructor(props: IDrawerProviderPropType) {
        super(props);

        this.state = {
            drawers: [] as DrawerConfigType[],
            addDrawer: this.addDrawer,
            removeDrawer: this.removeDrawer,
            openDrawer: this.openDrawer,
            closeDrawer: this.closeDrawer,
        };
    }

    public shouldComponentUpdate (nextProps: Readonly<IDrawerProviderPropType>, nextState: Readonly<DrawerConsumerProps>, nextContext: any): boolean {
        // if there was a change in drawer state data
        if (nextState.drawers !== this.state.drawers) {
            return true;
        }

        // if there was a change in props
        if (nextProps !== this.props) {
            return true;
        }

        // otherwise don't update
        return false;
    }

    public render() {
        const { children } = this.props;
        const { drawers } = this.state;

        const hightestVisibleLevel = this.getHighestVisibleLevel();

        return (
            <DrawerProviderContext.Provider value={this.state}>
                {children}

                {drawers.map((drawer) => {
                    if (drawer.isOpen) {
                        return (
                            <DrawerItem
                                key={drawer.idname}
                                {...drawer}
                                children={drawer.content}
                                onCloseDrawer={this.closeDrawer}
                                onOpenDrawer={this.openDrawer}
                                isHighestLevel={((drawer.level || 1) >= hightestVisibleLevel)}
                            />
                        );
                    }

                    return null;
                })}
            </DrawerProviderContext.Provider>
        );
    }

    /**
     * Returns the highest visible level currently open.
     * This can be used to make sure we only show the most important elements, when we have more than 1 drawer open at the same time.
     */
    private getHighestVisibleLevel = (): number => {
        const { drawers } = this.state;
        let highestVisibleLevel = 0;

        for (const drawer of drawers) {
            if (drawer.isOpen && drawer.level && drawer.level > highestVisibleLevel) {
                highestVisibleLevel = drawer.level;
            }
        }

        return highestVisibleLevel;
    }

    /**
     * Removes a new drawer from our drawer catalog
     *
     * @param idname
     */
    private removeDrawer = (idname?: string) => {
        const { drawers } = this.state;

        const newDrawers = drawers.filter(drawer => {
            return !(drawer.idname === idname);
        });

        this.setState({
            drawers: newDrawers,
        });
    };

    /**
     * Adds a new drawer to our drawer catalog
     *
     * @param drawerConfig
     */
    private addDrawer = (drawerConfig: DrawerConfigType) => {
        const { drawers } = this.state;

        // if no idname given -> don't add it
        if (!drawerConfig.idname) {
            return;
        }

        // make sure we don't add duplicates
        for (const drawer of drawers) {
            if (drawer.idname === drawerConfig.idname) {
                return;
            }
        }

        // add the drawer
        drawers.push(drawerConfig);

        this.setState({
            drawers: drawers.slice(),
        });
    };

    /**
     * Updates a specific drawer based on the idname
     *
     * @param idname
     * @param changeObj
     */
    private changeDrawer = (
        idname: string,
        changeObj: { [key: string]: any },
    ) => {
        const { drawers } = this.state;

        const newDrawers = drawers.map(drawer => {
            if (drawer.idname === idname) {
                return {
                    ...drawer,
                    ...changeObj
                };
            }


            return drawer;
        });

        this.setState({
            drawers: newDrawers,
        });
    };

    /**
     * Closes a specific drawer
     *
     * @param idname
     */
    private closeDrawer = (idname?: string): void => {
        this.changeDrawer(idname || 'default', {
            isOpen: false,
        });
    };

    /**
     * Opens a specific drawer
     *
     * @param idname
     * @param level
     */
    private openDrawer = (idname?: string, level?: number): void => {
        const changeObj = {
            isOpen: true,
        } as {[key: string]: any};

        if (level) {
            changeObj.level = level;
        }

        this.changeDrawer(idname || 'default', changeObj);
    };
}

export default DrawerProvider;
