import * as React from 'react';
import { Router } from '../../../../routes';
import Icon from '../../../Base/components/Icon/Icon';
import * as styles from './SearchInput.scss';
import { withRouter, RouterProps } from 'react-router';

interface ISearchInputProps {
	label?: string;
	placeholder?: string;
	style?: 'light' | 'default';
	autoFocus?: boolean;
}

// TRANSLATE
class SearchInput extends React.Component<ISearchInputProps & RouterProps> {
	public static defaultProps = {
		style: 'default',
	};

	constructor(props: any) {
		super(props);

		this.state = {
			searchTerm: '',
		};
	}

	public render () {
		const { label, placeholder, style, autoFocus } = this.props;
		const { searchTerm } = this.state;

		return (
			<div>
				{( !label ) ? null :
				 <div className={styles.searchInputTitle}>{label}</div>
				}

				<form onSubmit={this.submitSearch}>
					<div className={styles.searchInputWrapper}>
							<label
								htmlFor="searchPageInput"
								className={styles.searchInputIcon}
							>
								<Icon icon="search" />
							</label>

							<input
								type="text"
								name="search"
								placeholder={placeholder}
								autoComplete="off"
								autoFocus={autoFocus}
								className={`${styles.searchInputField} ${(style === 'light') ? styles.styleLight : ''}`}
								onChange={this.onChange}
								value={searchTerm}
							/>
					</div>
				</form>
			</div>
		);
	}

	/**
	 * Handles onchange events
	 * @param e
	 */
	private onChange = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			searchTerm: e.currentTarget.value,
		});
	};

	/**
	 * Sets the new searchterm into the header search field
	 *
	 * @param searchTerm
	 */
	private setHeaderSearchField(searchTerm: string) {
		const inputDom = document.getElementById('mainHeaderSearchInput');
		if (inputDom) {
			inputDom.value = searchTerm;

			// dispatch event to make sure the state gets updated
			const event = new Event('change');
			inputDom.dispatchEvent(event);
		}
	}

	/**
	 * Navigate to search page on submit
	 *
	 * @param event
	 */
	private submitSearch = (event: any) => {
		const { searchTerm } = this.state;

		if (event && event.preventDefault) {
			event.preventDefault();
		}

		// navigate to results page
		this.props.history.push({
			pathname: '/suche/',
			  search: `?q=${searchTerm}`,
		});

		// Router.pushRoute('SearchRoute', {
		// 	q: searchTerm,
		// });

		this.setHeaderSearchField(searchTerm);
	};
}


export default withRouter(SearchInput);
