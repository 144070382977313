import * as React from 'react';
import CookieTrustService from '../../../../services/CookieTrustService';
import { ExternalTracking, ILeafletType } from '../../types/ILeafletType';
import * as styles from './LeafletExternalTracker.scss';

interface ILeafletExternalTrackerPropsType {
    event: 'OPEN' | 'CLICKOUT_URL' | 'VIEW_PAGE';
    leaflet: ILeafletType;
    pageId?: number;
    clickoutUrl?: string;
}

class LeafletExternalTracker extends React.PureComponent<ILeafletExternalTrackerPropsType, {}> {
    public static defaultProps = {};

    public render() {
                        const { leaflet, event, pageId } = this.props;

                        // if we don't have marketing cookies enabled -> don't show
                        if (!CookieTrustService.isActive('S0006')) {
                            return null;
                        }

                        // if no events found -> nothing to do
                        if (!leaflet.externalTracking || leaflet.externalTracking.length < 1) {
                            return null;
                        }

                        // get only external tracking urls, that should be rendered and have not been called yet
                        const matchingEvents = leaflet.externalTracking.filter((trackingEvent: ExternalTracking) => {
                            return trackingEvent.event === event;
                        });

                        // if no matching events found -> nothing to do
                        if (matchingEvents.length < 1) {
                            return null;
                        }

                        return (
                            <>
                                {matchingEvents.map((trackingEvent: ExternalTracking) => {
                                    const trackingUrl = this.replacePlaceholders(trackingEvent.url);

                                    return <img src={trackingUrl} alt="t1" width={1} height={1} key={`et-${trackingUrl}`} className={styles.externalTrackingImage} />;
                                })}
                            </>
                        );
                    }
 
    /**
     * Replaces placeholders like <leafletId> and <page> in the url string
     * 
     * @param url
     */
    private replacePlaceholders(url: string): string {
        const { leaflet, pageId, clickoutUrl } = this.props;

        const replaceObj = {
            '${GDPR}': 1,
            '${GDPR_CONSENT_755}': CookieTrustService.getTcString(),
            '<leafletId>': leaflet.id,
            '<page>': pageId || '0',
            '<clickoutUrl>': clickoutUrl || '-',
        };

        let retUrl = url;
        for (const replacement of Object.keys(replaceObj)) {
            retUrl = retUrl.replace(replacement, replaceObj[replacement]);
        }

        return retUrl;
    }
}

export default LeafletExternalTracker;
