/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';

export const PROMOTIONS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_FETCH_ENTRIES = '@BasePipeline/promotionsAggregatedByProductGroupsWidgetReducer_FETCH_ENTRIES';
export const PROMOTIONS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_ADD_ENTRIES = '@BasePipeline/promotionsAggregatedByProductGroupsWidgetReducer_ADD_ENTRIES';

export const PROMOTIONS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_SET_ERRORS   = '@BasePipeline/promotionsAggregatedByProductGroupsWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchPromotionsAggregatedByProductGroupsWidgets (queryKey: string) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addPromotionsAggregatedByProductGroupsWidgetEntries (queryKey: string, entries: IPromotionsAggregatedByProductGroupType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {

	return {
		type: PROMOTIONS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setPromotionsAggregatedByProductGroupsWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
