import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import LotteryInfoPage from '../App/modules/Lottery/components/LotteryInfoPage/LotteryInfoPage';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import BaseRoute from './_BaseRoute';
import AdItem from '../App/components/General/AdItem/AdItem';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdConfig from '../App/components/General/AdConfig/AdConfig';


class LotteryInfoRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await LotteryInfoRouteProps(state);

		return { reduxData };
	}

	public render () {

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4493474]}
					mobileIds={[4493486]}
					superbannerId={4493475}
					sitebarId={4493473}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<LotteryInfoPage />

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493473}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		LotteryInfoRoute,
		`lotteryInfo`,
		{
			metaTitle: `Aktionsfinder der Woche » Mitspielen und gewinnen`,
			metaDescription: `Mitspielen und gewinnen – Aktionsfinder der Woche » Informieren Sie sich hier über alle Einzelheiten!`,
		} as IRouteMetadataSetterType
	)
);
