/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IShopType } from '../../types/IShopType';

export const SHOP_FETCH_ENTRIES = '@BasePipeline/shopReducer_FETCH_ENTRIES';
export const SHOP_FETCH_ENTRY   = '@BasePipeline/shopReducer_FETCH_ENTRY';
export const SHOP_ADD_ENTRIES = '@BasePipeline/shopReducer_ADD_ENTRIES';
export const SHOP_ADD_ENTRY = '@BasePipeline/shopReducer_ADD_ENTRY';

export const SHOP_SET_ERRORS   = '@BasePipeline/shopReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchShops (queryKey: string) {
	return {
		type: SHOP_FETCH_ENTRIES,
		queryKey,
	};
}

export function addShopEntries (queryKey: string, entries: IShopType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOP_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addShopEntry (queryKey: string, entry: IShopType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOP_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setShopErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOP_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
