import * as React from 'react';
import { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import HeartMulticolored from '../../../../../public/Icons/multicolor/af-heart-filled.svg';
import LayoutService from '../../../services/LayoutService';
import * as layoutStyles from '../Layout.scss';
import DropDownItem from '../Menu/DropDownItem';
import * as styles from './MainNavigation.scss';
import NavigationItem from './NavigationItem';
import SubNavigationGroup from './SubNavigationGroup';
import SubNavigationWrapper from './SubNavigationWrapper';

interface IMainNavigationProps {
    router?: any;
    children?: ReactNode | ReactNode[];
    hideOnMobile?: boolean;
}

interface IMainNavigationState {
    currentSubmenu: string;
}

const DEFAULT_SUBMENU_ID = 'sparen';

class MainNavigation extends React.Component<IMainNavigationProps & RouteComponentProps, IMainNavigationState> {
    /**
     * Contains the timeout when user moves away from the main navigation item of a group
     */
    private submenuOutTimeout: any;

    public static defaultProps = {};

    /**
     * Contains meta info about the last change submenu action and whether it was a click or mouseover
     * (clicks should not automatically disappear)
     */
    private changeWasMadeWithClick: boolean;

    constructor(props: IMainNavigationProps) {
        super(props);

        this.state = {
            currentSubmenu: this.getDefaultSubmenuId(props),
        };
    }

    public render() {
        if (LayoutService.isAppShellDisabled()) {
            return null;
        }

        const { currentSubmenu } = this.state;
        const { children, hideOnMobile, location } = this.props;

        // These are subitems for "Aktionen" in the main navigation menu
        const dropDownAktionen = (
            <>
                <DropDownItem title="Aktionen" routeLink="PromotionListingRoute" />
                <DropDownItem title="Gutscheine" routeLink="VoucherListingRoute" />
                <DropDownItem title="Gewinnspiele" routeLink="LotteryListingRoute" />
                <DropDownItem title="Themenwelten" routeLink="ThemeworldListingRoute" />
            </>
        );

        return (
            <React.Fragment>
                <nav id="main-navigation" className={`${styles.mainNavigationWrapper} ${hideOnMobile ? styles.hideNavigationOnMobile : ''}`}>
                    <div className={`${layoutStyles.container} ${styles.mainNavigationContainer}`}>
                        <div className={styles.MainNavigationBlock}>{!LayoutService.isPromotionsRoute(location.pathname) ? null : dropDownAktionen}</div>
                    </div>
                </nav>

                <SubNavigationWrapper className={styles.navigationWrapper}>
                    <SubNavigationGroup submenuId="sparen" currentSubmenu={currentSubmenu}>
                        <NavigationItem submenuId="sparen" label="Stöbern" icon="logo-icon" route="HomeRoute" type="subnavigation" exact={true} />
                        <NavigationItem label="Prospekte" icon="leaflet" route="LeafletListingRoute" type="subnavigation" />
                        <NavigationItem label="Favoriten" customIcon={HeartMulticolored} route="AppExclusiveRoute" type="subnavigation" />
                        <NavigationItem label="Aktionen" icon="promotions" type="subnavigation" dropDownItems={dropDownAktionen} route="PromotionListingRoute" />
                        <NavigationItem label="Märkte" icon="vendor" route="VendorListingRoute" type="subnavigation" />
                    </SubNavigationGroup>

                    {!children ? null : (
                        <SubNavigationGroup submenuId="children" currentSubmenu={currentSubmenu}>
                            {children}
                        </SubNavigationGroup>
                    )}
                </SubNavigationWrapper>
            </React.Fragment>
        );
    }

    /**
     * Selects the current submenu in the navigation item component
     *
     * @param submenuId
     * @param disableLeaveTimeout
     */
    private selectSubmenu = (submenuId: string, disableLeaveTimeout: boolean = false) => {
        if (this.submenuOutTimeout) {
            clearTimeout(this.submenuOutTimeout);
        }

        this.changeWasMadeWithClick = disableLeaveTimeout;

        this.setState({
            currentSubmenu: submenuId,
        });
    };

    /**
     * Selects the current submenu in the navigation item component
     */
    private resetSubmenu = () => {
        if (this.submenuOutTimeout) {
            clearTimeout(this.submenuOutTimeout);
        }

        // if last set action was a click -> we don't want to go back automatically
        if (this.changeWasMadeWithClick) {
            return;
        }

        this.submenuOutTimeout = setTimeout(() => {
            this.setState({
                currentSubmenu: this.getDefaultSubmenuId(this.props),
            });
        }, 600);
    };

    /**
     * Returns the current default submenuId
     */
    private getDefaultSubmenuId(props: IMainNavigationProps) {
        const { children } = props;

        if (!children) {
            return DEFAULT_SUBMENU_ID;
        }

        return 'children';
    }
}

export default withRouter(MainNavigation);
