import * as React from 'react';
import * as styles from './Drawer.scss';
import { ReactComponentElement } from 'react';
import Overlay from '../Overlay/Overlay';
import { DrawerConfigType } from './DrawerProvider';

export type DrawerCloseMethod = (idname?: string) => void;
export type DrawerOpenMethod = (idname?: string, level?: number) => void;

export type DrawerChildrenType = (
    closeDrawer?: DrawerCloseMethod,
) => ReactComponentElement<any>;

interface IDrawerPropType extends Omit<DrawerConfigType, 'content'> {
    children: DrawerChildrenType;
    isHighestLevel?: boolean;
}

interface IDrawerStateType {
    isOpen?: boolean;
    isRendered: boolean;
}

class DrawerItem extends React.PureComponent<
    IDrawerPropType,
    IDrawerStateType
> {
    public static defaultProps = {
        position: 'right',
    };

    private animationTimer?: ReturnType<typeof setTimeout>;

    public constructor(props: IDrawerPropType) {
        super(props);

        if (!props.idname) {
            console.warn(
                'You need to pass a unique idname to the DrawerItem component props! None given right now',
            );
        }

        this.state = {
            isOpen: false,
            isRendered: false,
        };
    }

    public componentWillUnmount() {
        if (this.animationTimer) {
            clearTimeout(this.animationTimer);
        }
    }

    public componentDidMount() {
        if (this.props.isOpen) {
            this.changeToggleState(this.props.isOpen);
        }
    }

    public componentDidUpdate(
        prevProps: Readonly<IDrawerPropType>,
        prevState: Readonly<IDrawerStateType>,
        snapshot?: any,
    ) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.changeToggleState(this.props.isOpen);
        }
    }

    public render() {
        const { children, level, isHighestLevel } = this.props;
        const { isRendered, isOpen } = this.state;

        if (!isOpen) {
            return null;
        }

        // add open class if it is supposed to be rendered
        let ClassAddon = isRendered ? styles.DrawerOpen : null;

        // add current level styling
        ClassAddon += ` ${styles[`DrawerLevel${level}`]}`;

        // if we are not the highest level right now -> add style
        if (!isHighestLevel) {
            ClassAddon += ` ${styles.DrawerNotOnHighestLevel}`;
        }

        return (
            <React.Fragment>
                 <Overlay
                   onClick={this.closeDrawer}
                   className={`${styles.DrawerOverlay} ${ClassAddon}`}
                 >
                     <div className={`${styles.DrawerHandle} ${ClassAddon}`} />
                 </Overlay>

                <div className={`${styles.DrawerWrapper} ${ClassAddon}`}>
                    <div className={styles.DrawerContent}>
                        {children(this.closeDrawer)}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    /**
     * Closes the drawer window
     */
    private changeToggleState = (newOpenState: boolean) => {
        if (newOpenState) {
            this.openDrawer();
            return;
        }

        this.closeDrawer();
    };

    /**
     * Opens the drawer window
     */
    public openDrawer = () => {
        const { onOpenDrawer, idname } = this.props;

        this.setState(
            {
                isOpen: true,
                isRendered: false,
            },
            () => {
                // if already have a change subscribed
                if (this.animationTimer) {
                    clearTimeout(this.animationTimer);
                }

                // defer the changing on "in animation" state to after the animation was done
                this.animationTimer = setTimeout(() => {
                    this.setState(
                        {
                            isRendered: true,
                        },
                        () => {
                            if (onOpenDrawer) {
                                onOpenDrawer(idname);
                            }
                        },
                    );
                }, 1);
            },
        );
    };

    /**
     * Closes the drawer window
     */
    public closeDrawer = () => {
        const { onCloseDrawer, idname } = this.props;

        this.setState(
            {
                isRendered: false,
            },
            () => {
                // if already have a change subscribed
                if (this.animationTimer) {
                    clearTimeout(this.animationTimer);
                }

                // defer the changing on "in animation" state to after the animation was done
                this.animationTimer = setTimeout(() => {
                    this.setState(
                        {
                            isOpen: false,
                        },
                        () => {
                            if (onCloseDrawer) {
                                onCloseDrawer(idname);
                            }
                        },
                    );
                }, 600);
            },
        );
    };
}

export default DrawerItem;
