import { isServer } from '../../boilerplate/razzle/razzleUtils';
import ReduxService from '../../boilerplate/redux/services/ReduxService';
import { setGeneral } from '../modules/Base/store/General/generalActions';

declare const window: any;

/**
 * CookieTrustService Implementation for OneTrust to check active cookie groups
 *
 * Onetrust Cookie Consent Groups:
 * ------------
 * ID C0001 - Strictly Necessary Cookies
 * ID C0002 - Performance Cookies
 * ID C0004 - Targeting Cookies
 * ID S0006 - Cookies from US Providers
 *
 * Not used:
 * ID C0003 - Functional Cookies
 * ID C0005 - Social Media Cookies
 * ------------
 */
const CookieTrustService = {
    /**
     * A cached version of the active groups
     */
    cachedActiveGroupMap: null,

    /**
     * A cached version of the tcf String (contains an encrypted version of all allowed privacy categories)
     */
    tcString: null,

    /**
     * Let's us know whether it was initated successfully or not
     */
    initiated: false,

    /**
     * Initiates OneTrust change event if groups change
     */
    init: () => {
        if (isServer()) {
            return;
        }

        setTimeout(() => {
            CookieTrustService.setListeners();
        }, 1000);

        // this is to make sure it is initiated ( if the one above did not have the onetrust event ready)
        setTimeout(() => {
            CookieTrustService.setListeners();
            CookieTrustService.getTcString(true);
        }, 4000);

        // Run this in an interval (every 0.1s) just in case we are still waiting for consent
        var cnt = 0;
        var consentSetInterval = setInterval(function() {
            cnt += 1;
            // Bail if we have not gotten a consent response after 60 seconds.
            if (cnt === 600) clearInterval(consentSetInterval);
            if (typeof window.__tcfapi !== 'undefined') {
                // Check if window.__tcfapi has been set
                clearInterval(consentSetInterval);
                window.__tcfapi('addEventListener', 2, function(tcData, listenerSuccess) {
                    if (listenerSuccess) {
                        if (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete') {
                            if (!tcData.gdprApplies) {
                                CookieTrustService.getTcString(true);
                            } else {
                                CookieTrustService.getTcString(true);
                            }
                        }
                    }
                });
            }
            cnt++;
        }, 100);
    },

    /**
     *
     */
    setListeners: () => {
        if (CookieTrustService.initiated) {
            return;
        }

        if (isServer()) {
            return;
        }

        if (window?.OneTrust && window?.__tcfapi) {
            CookieTrustService.initiated = true;

            window?.OneTrust?.OnConsentChanged(e => {
                const activeGroupMap = CookieTrustService.cacheActiveGroups();
                ReduxService.dispatch(
                    setGeneral({
                        cookieTrustGroups: activeGroupMap,
                    }),
                );
            });

            CookieTrustService.cacheActiveGroups();
            CookieTrustService.getTcString();
        } else {
            if (!window?.OneTrust) {
                // console.log('Sdk not ready yet - onetrust');
            }
            if (!window?.__tcfapi) {
                // console.log('Sdk not ready yet - tcfapi');
            }
        }
    },

    /**
     * Caches active groups, to make sure we don't have to do all the extractions again and again
     */
    cacheActiveGroups: () => {
        // if is server -> default to false
        if (isServer()) {
            return {};
        }

        // strip away pre and postfix
        const OnetrustActiveGroupsRaw = window.OnetrustActiveGroups?.substring(1, window.OnetrustActiveGroups?.length - 1) + '';

        // convert string to array
        const OnetrustActiveGroupsArr = OnetrustActiveGroupsRaw.split(',');

        // make sure to turn array into map, for easier access to elements
        const ActiveGroupMap = {} as { [key: string]: boolean };
        OnetrustActiveGroupsArr.some((activeGroup: string) => {
            ActiveGroupMap[activeGroup] = true;
        });

        CookieTrustService.cachedActiveGroupMap = ActiveGroupMap;

        return ActiveGroupMap;
    },

    /**
     * Checks if one or more groups are active
     */
    isActive: (lookupGroupsParam: string | string[]) => {
        let cachedActiveGroups = CookieTrustService.cachedActiveGroupMap;

        // make sure we have cached values to work with
        if (!CookieTrustService.cachedActiveGroupMap) {
            cachedActiveGroups = CookieTrustService.cacheActiveGroups();
        }

        // make sure that groups is always in array format
        const lookupGroups = typeof lookupGroupsParam === 'string' ? [lookupGroupsParam] : lookupGroupsParam;

        // walk through lookup groups and if one does not match -> stop and return false (all groups must be active to have this working)
        for (const lookupGroup of lookupGroups) {
            if (!cachedActiveGroups[lookupGroup]) {
                return false;
            }
        }

        return true;
    },

    /**
     * Returns the cached tc string
     */
    getTcString(force = false): string {
        if (!CookieTrustService.tcString || force) {
            if (typeof window !== 'undefined' && typeof window.__tcfapi !== 'undefined') {
                window.__tcfapi('getTCData', 2, function(tcData, success) {
                    CookieTrustService.tcString = tcData.tcString;

                    if (!success) {
                        console.log('Trust error: ', tcData);
                    }

                    ReduxService.dispatch(
                        setGeneral({
                            tcString: CookieTrustService.tcString,
                        }),
                    );
                });
            }
        }

        return CookieTrustService.tcString;
    },
};

export default CookieTrustService;
