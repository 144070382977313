import appConfig from './config/appConfig';
import { isServer } from '../boilerplate/razzle/razzleUtils';

export const routes = {
    HomeRoute: '/', // #27
    widgets: '/widgets/', // #27
    VendorDetailRoute: '/v/:vendor/', // #27
    ShopDetailRoute: '/s/:shop/', // #62
    LeafletListingRoute: '/prospekte/', // #56
    ProductGroupDetailRoute: '/ppc/:productGroup/', // #20
    ProductGroupDetailRoute1: '/ppcdest/:productGroup/', // #20
    BrandDetailRoute: '/b/:brand/', // #67
    PromotionListingRoute: '/aktionen/', // #19
    PromotionDetailRoute: '/p/:promotion/', // #64
    PromotionsInProductGroupAndVendorRoute: '/ppcv/:productGroup/:vendor/', // #33
    PromotionsInProductGroupAndCityRoute: '/ppcc/:productGroup/:city/', // #36
    VoucherListingRoute: '/gutscheine/', // #57  //
    PromotionsInIndustryRoute: '/pi/:industry/', // #32
    PromotionsFromBrandAndVendorRoute: '/pbv/:brand/:vendor/',
    VouchersInStateRoute: '/vost/:state/', // #28
    LeafletsInStateRoute: '/lst/:state/', // #23
    LeafletsByVendorRoute: '/lv/:vendor/', // #24  //
    CurrentVendorLeaflet: '/cvl/:vendor/',
    AssortementPromotionsInProductGroupRoute: '/appc/:productGroup/', // #43  //
    AssortementPromotionsByVendorRoute: '/apv/:vendor/', // #44
    VouchersByVendorRoute: '/vov/:vendor/', // #46
    VouchersInProductGroupRoute: '/vopc/:productGroup/', // #47
    PromotionsInProductCategoryAndIndustryRoute: '/ppci/:productGroup/:industry/', // #48  //
    ShopsByVendorAndStateRoute: '/svst/:vendor/:state/', // #29  //
    ShopsByVendorAndCityRoute: '/svc/:vendor/:city/', // #35
    LeafletsInProductGroupRoute: '/lpc/:productGroup/', // #49  //
    PromotionsByVendorRoute: '/pv/:vendor/', // #22  //
    PromotionsInStateRoute: '/pst/:state/', // #31  //
    PromotionsInShopByBrandRoute: '/pbs/:brand/:shop/', // #51

    PromotionsFromProductGroupAndBrandRoute: '/ppcb/:productGroup/:brand/', // #40
    PromotionsFromBrandAndCityRoute: '/pbc/:brand/:city/', // #39
    LeafletsByVendorInStateRoute: '/lvst/:vendor/:state/', // #61
    PromotionsInProductGroupAndStateRoute: '/ppcst/:productGroup/:state/', // #21
    SearchRoute: '/suche/', // #11
    LeafletDetailRoute: '/l/:leaflet/', // #68
    ThemeworldDetailRoute: '/tw/:themeworld/', // #69
    ShoppingCenterDetailRoute: '/sc/:shoppingCenter/', // #26
    VendorListingRoute: '/v/', // #45
    TagDetailRoute: '/t/:tag/', // #71
    PromotionsInShopAndProductGroupRoute: '/ppcs/:productGroup/:shop/', // #53
    PromotionsInShoppingCenterAndProductGroupRoute: '/ppcsc/:productGroup/:shoppingCenter/', // #54

    PromotionsByVendorInStateRoute: '/pvst/:vendor/:state/', // #52
    VouchersByVendorInStateRoute: '/vovst/:vendor/:state/', // #60
    PromotionsByBrandInStateRoute: '/pbst/:brand/:state/', // #73
    ThemeworldListingRoute: '/tw/', // #42
    BrandListingRoute: '/b/', // #50
    BrandsWithFirstLetterRoute: '/bin/:letter/', // #74
    LeafletsInIndustryRoute: '/li/:industry/', // #
    FeedbackRoute: '/feedback/', // #13
    ImprintRoute: '/impressum/', // #12
    TermsAndConditionsRoute: '/nutzungsbedingungen/', // #12
    PrivacyInfoRouteOld: '/rechtliche-hinweise/', // #16
    PrivacyInfoRoute: '/datenschutz/', // #16

    ShoppingCenterListingRoute: '/sc/', // #25
    ShoppingCentersInStateRoute: '/scst/:state/', // #55

    RegistrationRoute: '/registrieren/', // #8
    VerifyMailRoute: '/mein-benutzerkonto/bestaetigen/',
    ForgottenPasswordRoute: '/passwort-vergessen/',
    ResetPasswordRoute: '/passwort-zuruecksetzen/',

    LoginRoute: '/login/', // #9
    LogoutRoute: '/logout/', // #9

    AccountInfoRoute: '/mein-benutzerkonto/meine-daten/', //#1

    AccountChangeDataRoute: '/mein-benutzerkonto/daten-aendern/',
    AccountChangeEmailRoute: '/mein-benutzerkonto/email-aendern/',
    AccountChangePasswordRoute: '/mein-benutzerkonto/passwort-aendern/',

    ConfirmNewslettersRoute: '/newsletter/bestaetigung/',

    // 'CashbackRoute': '/cashback/',

    LotteryInfoRoute: '/aktionsfinder-der-woche/',
    LotteryRegistrationRoute: '/aktionsfinder-der-woche/mitspielen/',
    ConfirmLotteryRegistrationRoute: '/aktionsfinder-der-woche/anmeldung-bestaetigt/',
    LotteryPaymentRoute: '/aktionsfinder-der-woche/gewinneinloesung/',
    LotteryParticipationConditionsRoute: '/aktionsfinder-der-woche/teilnahmebedingungen/',

    LotteryListingRoute: '/gewinnen/',

    PromotionBookmarkListingRoute: '/einkaufsliste/',
    VendorBookmarkListingRoute: '/merkliste/',
    AppExclusiveRoute: '/app/',
    NotFoundRoute: '/404-not-found/',
};

export const Router = {
    pushRoute: (route, params) => {
        if (appConfig.isDev) {
            console.log('GOING TO ROUTE: ' + route, params);
        }

        if (isServer()) {
            console.warn('Not following route ' + route + ' because I am on the server!');
            return;
        }

        const newUrl = window.location.protocol + '//' + window.location.host + this.getRouteUrl(route, params);

        // DOES NOT WORK YET. MAYBE FIND A WAY TO MAKE IT WORK OR Use connected-react-routers push method with getRouteUrl
        // window.history.pushState({ path: newUrl },'', newUrl);
    },

    getRouteUrl: (route, params) => {
        if (appConfig.isDev) {
            // console.log('GETTING ROUTE URL: '+ route, params);
        }

        let queryString = '';
        for (const key of Object.keys(params)) {
            queryString += '&' + key + '=' + encodeURIComponent(params[key]);
        }

        let newUrl = routes[route];

        if (queryString) {
            newUrl += '?' + queryString.substring(1);
        }

        return newUrl;
    },
};

export default routes;
