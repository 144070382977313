import { isServer } from '../../boilerplate/razzle/razzleUtils';

type LastEntryExcludingMethodType = (path: string) => boolean;

const HistoryService = {
    /**
     * current cookies
     */
    history: [] as string[],

    /**
     * Returns the cookie options we are going to use everywhere
     */
    push(url) {
        if (isServer()) {
            return;
        }

        this.history.push(url);
    },

    /**
     * Returns the length of our history
     */
    length(): number {
        return this.history.length;
    },

    /**
     * Returns the length of our history
     */
    last(excluding: null | LastEntryExcludingMethodType = null): number | null {
        const allEntries = excluding ? this.history.filter(excluding) : this.history;

        // if nothing to return -> return null
        if (allEntries.length <= 1) {
            return null;
        }

        return allEntries[allEntries.length - 2];
    },
};

export default HistoryService;
