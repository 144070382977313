import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	addsearchShopEntries,
	SEARCH_SHOP_FETCH_ENTRIES, setsearchShopErrors,
} from './searchShopActions';

/**
 * Fetch entry list
 */
function* fetchsearchShops(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/search',
				params,
			} as IRequestObject);
		});

		if (isServer()) {
			response.addMetaData('reloadOnClient', true);
		}

		yield put(addsearchShopEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setsearchShopErrors(action.queryKey, e));
	}
}

export function* searchShopSaga() {
	yield takeEvery(SEARCH_SHOP_FETCH_ENTRIES, fetchsearchShops);
}
