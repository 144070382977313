import { DateTime } from 'luxon';

// TODO: translate
const humanReadableStrings = {
	onlyToday: 'nur heute',
	onlyTodayLeft: 'nur noch heute',
	onlyTomorrowLeft: 'nur noch bis morgen',
	only2DaysLeft: 'nur noch bis übermorgen',
	today: 'ab heute',
	tomorrow: 'ab morgen',
	dayAfterTomorrow: 'ab übermorgen',
	inXDays: (days: number): string => {
		return `in ${days} Tagen`;
	},
	onlyXDaysLeft: (days: number): string => {
		return `nur noch ${days} Tage`;
	},
	toed: 'abgelaufen',
	tooFarInTheFuture: (days: number, fromDate: DateTime): string => {
		return `ab ${fromDate.toFormat('dd.LL.yyyy')}`;
	},
	toNormalDate: (toDate: DateTime): string => {
		return `bis ${toDate.toFormat('dd.LL.yyyy')}`;
	},

	// additional formats for open validity
	todayOpenValidity: 'ab heute verfügbar',
	fromOpenValidityValidDate: (fromDate: DateTime): string => {
		return `verfügbar seit ${fromDate.toFormat('dd.LL')}`;
	},
	fromOpenValidityValidSinceDate: (toDate: DateTime): string => {
		return `verfügbar ab ${toDate.toFormat('dd.LL')}`;
	},
};

const shortReadableStrings = {
	onlyToday: 'nur heute',
	onlyTodayLeft: 'nur heute',
	onlyTomorrowLeft: 'bis morgen',
	only2DaysLeft: 'bis übermorgen',
	today: 'ab heute',
	tomorrow: 'ab morgen',
	dayAfterTomorrow: 'ab übermorgen',
	inXDays: (days: number): string => {
		return `in ${days} Tagen`;
	},
	onlyXDaysLeft: (days: number): string => {
		return `noch ${days} Tage`;
	},
	toed: 'abgelaufen',
	tooFarInTheFuture: (days: number, fromDate: DateTime): string => {
		return `ab ${fromDate.toFormat('dd.LL')}`;
	},
	toNormalDate: (toDate: DateTime): string => {
		return `bis ${toDate.toFormat('dd.LL')}`;
	},

	// additional formats for open validity
	todayOpenValidity: 'ab heute verfügbar',
	fromOpenValidityValidDate: (fromDate: DateTime): string => {
		return `seit ${fromDate.toFormat('dd.LL')}`;
	},
	fromOpenValidityValidSinceDate: (toDate: DateTime): string => {
		return `ab ${toDate.toFormat('dd.LL')}`;
	},
};

interface IHumanReadableDateOptions {
	outputIfEnded?: string;
	postfixIfNotEnded?: string;
	outputIfTooFarInTheFuture?: (days: number, fromDate: DateTime) => string;
	openValidity?: boolean;
}

/**
 * Converts from and to dates to the best fitting human readable string
 *
 * @param {DateTime | string} fromParam
 * @param {DateTime | string} toParam
 * @param {IHumanReadableDateOptions} opts
 *
 * @return {string}
 */
export function getHumanReadableStringFromTimespan(fromParam: DateTime | string, toParam: DateTime | string, opts: IHumanReadableDateOptions = {}, type: string = 'long'): string {
	let readableStrings = humanReadableStrings;

	if (type === 'short') {
		readableStrings = shortReadableStrings;
	}

	const options = Object.assign(
		{
			maxDisplayDays: 4,
			outputIfEnded: typeof opts.outputIfEnded !== 'undefined' ? opts.outputIfEnded : readableStrings.toed,
			postfixIfNotEnded: opts.postfixIfNotEnded ? opts.postfixIfNotEnded : '',
			outputIfTooFarInTheFuture: typeof opts.outputIfTooFarInTheFuture !== 'undefined' && opts.outputIfTooFarInTheFuture !== 'undefined' ? opts.outputIfTooFarInTheFuture : readableStrings.tooFarInTheFuture,
		},
		opts,
	);

	if (!options.outputIfTooFarInTheFuture) {
		options.outputIfTooFarInTheFuture = readableStrings.tooFarInTheFuture;
	}

	const from = typeof fromParam === 'string' ? DateTime.fromISO(fromParam) : fromParam;
	const to = typeof toParam === 'string' ? DateTime.fromISO(toParam) : toParam;

	const today = DateTime.local().startOf('day');
	const fromDiff = from.startOf('day').diff(today, 'days').days;
	const toDiff = to.startOf('day').diff(today, 'days').days;

	// alternative version for openValidity
	if (options['openValidity'] == true && toDiff >= 0) {
		if (fromDiff > 0) {
			return readableStrings.fromOpenValidityValidSinceDate(from);
		} else if (fromDiff == 0) {
			return readableStrings['todayOpenValidity'];
		} else if (fromDiff < 0) {
			return readableStrings.fromOpenValidityValidDate(from);
		}

		return '';
	}

	// if run out already
	if (toDiff < 0) {
		return options.outputIfEnded;
	}

	// if only today
	if (fromDiff >= 0 && fromDiff <= 0 && toDiff < 1) {
		return readableStrings.onlyToday + options.postfixIfNotEnded;
	}

	// if in the future
	if (fromDiff > 0) {
		if (fromDiff < 1) {
			return readableStrings.today;
		} else if (fromDiff < 2) {
			return readableStrings.tomorrow;
		} else if (fromDiff <= 3) {
			return readableStrings.dayAfterTomorrow;
		} else if (fromDiff <= options.maxDisplayDays) {
			return readableStrings.inXDays(fromDiff);
		}

		return options.outputIfTooFarInTheFuture(fromDiff, from) + options.postfixIfNotEnded;
	}

	// if tos soon
	if (toDiff < 1) {
		return readableStrings.onlyTodayLeft + options.postfixIfNotEnded;
	} else if (toDiff < 2) {
		return readableStrings.onlyTomorrowLeft + options.postfixIfNotEnded;
	} else if (toDiff < 3) {
		return readableStrings.only2DaysLeft + options.postfixIfNotEnded;
	} else if (toDiff < options.maxDisplayDays) {
		return readableStrings.onlyXDaysLeft(toDiff) + options.postfixIfNotEnded;
	}

	if (type === 'short') {
		return '';
	}

	return readableStrings.toNormalDate(to) + options.postfixIfNotEnded;
}
