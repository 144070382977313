import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import { IWithRouteMetadataType } from "../../../../types/IWithRouteMetadataType";
import ShoppingCenterListContainer from '../../container/ShoppingCenterListContainer/ShoppingCenterListContainer';
import ShoppingCenterAggregatedByStatesWidgetContainer from "../../container/ShoppingCenterAggregatedByStatesWidget/ShoppingCenterAggregatedByStatesWidgetContainer";
import { IShoppingCenterAggregatedByStateType } from '../../types/IShoppingCenterAggregateByStateType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IShoppingCentersInStateProps {
	stateSlug: SlugType;
}

class ShoppingCentersInState extends React.Component <IShoppingCentersInStateProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};


	public render() {
		const { routeMetadata, stateSlug } = this.props;

		const stateName = routeMetadata && routeMetadata.state && routeMetadata.state.title ? routeMetadata.state.title : stateSlug;

		if (!routeMetadata) {
			return;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403495}
					maxHeight={250}
					type="billboard"
				/>

				<H1>{routeMetadata.pageTitle}</H1>

				<Row>
					<AppMainCol boxed={true} onTop={true}>
						<AppMainColBox nested={true} boxed={true}>

							<ShoppingCenterListContainer
								stateSlug={stateSlug}
								pushRouteMetadata={true}
								queryKey={routeMetadata.queryKey}
								contentBreaks={{
									5: (
										<AdItem
											mobileAdId={4403482}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									10: (
										<AdItem
											mobileAdId={4403481}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							/>
	
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							<ShoppingCenterAggregatedByStatesWidgetContainer
								title="Einkaufszentren in anderen Bundesländern"
								widgetKey={`scst-${stateSlug}-shopping-center-states-widget`}
								limit={30}
								placeholderLimit={9}
								routeCallbk={ShoppingCentersInState.routerCallbkStateListWidget}
								excludeSlug={stateSlug}
							/>

							<AdItem
								desktopAdId={4403494}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>
						</div>
					</AppSideCol>
				</Row>
			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Einkaufszentren',
				route: 'ShoppingCenterListingRoute',
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.state.title: '',
			} as IBreadcrumbItemType,
		];
	}

	private static routerCallbkStateListWidget(item: IShoppingCenterAggregatedByStateType) {
		return {
			name: 'ShoppingCentersInStateRoute',
			params: {
				state: item.federalState.slug,
			},
		};
	}
}

export default ShoppingCentersInState;
