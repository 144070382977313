import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import AlertService from '../../../Base/services/AlertService';
import {
	BOOKMARK_ADD_ENTRY,
	BOOKMARK_FETCH_ENTRIES,
	BOOKMARK_REMOVE_ENTRY,
	setBookmarkErrors, syncLocalBookmarks,
} from './bookmarkActions';
import bookmarkConfig from '../../../../config/bookmarkConfig';
import { rerenderPromotionBookmarks } from '../PromotionBookmark/promotionBookmarkActions';

/**
 * fetch bookmarks
 */
function* fetchBookmarkEntries (action: any) {
	const afCoreRequest = new AfCoreRequest(`bookmark-${action.type}-${action.entity}-${action.slug}`);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: '/api/public/v1/personal-list/favorite-lists',
			} as IRequestObject);
		});

		yield put(syncLocalBookmarks(
			response.getContent(),
		));
	} catch (e) {
		console.log('Couldn\'t fetch bookmarks: ', e);
	}
}

/**
 * Add bookmarks
 */
function* addBookmarkEntry (action: any) {
	const afCoreRequest = new AfCoreRequest(`bookmark-${action.type}-${action.entity}-${action.slug}`);

	// get the correct url
	const bookmarkProfiles = bookmarkConfig.entities;
	if (!bookmarkProfiles[action.entity] || !bookmarkProfiles[action.entity].apiUrls) {
		return;
	}

	try {
		yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.postRequest({
				url: bookmarkProfiles[action.entity].apiUrls.add(action.slug),
			} as IRequestObject);
		});

		yield put(bookmarkProfiles[action.entity].refetchAction());

		AlertService.success('bookmark-save-success', 'Erfolgreich hinzugefügt');
	} catch (e) {
		AlertService.error('bookmark-error', 'Fehler', 'Konnte nicht hinzugefügt werde. Bitte versuche es später erneut.');
		yield put(setBookmarkErrors());
	}
}

/**
 * Remove bookmarks
 */
function* removeBookmarkEntry (action: any) {
	const afCoreRequest = new AfCoreRequest(`bookmark-${action.type}-${action.entity}-${action.slug}`);

	// get the correct url
	const bookmarkProfiles = bookmarkConfig.entities;
	if (!bookmarkProfiles[action.entity] || !bookmarkProfiles[action.entity].apiUrls) {
		return;
	}

	try {
		yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.deleteRequest({
				url: bookmarkProfiles[action.entity].apiUrls.remove(action.slug),
			} as IRequestObject);
		});

		yield put(bookmarkProfiles[action.entity].rerenderAction());

		AlertService.success('bookmark-save-success', 'Erfolgreich entfernt');
	} catch (e) {
		AlertService.error('bookmark-error', 'Fehler', 'Konnte nicht entfernt werden. Bitte versuche es später erneut.');
		yield put(setBookmarkErrors());
	}
}


export function* bookmarkSaga () {
	yield takeEvery(BOOKMARK_ADD_ENTRY, addBookmarkEntry);
	yield takeEvery(BOOKMARK_REMOVE_ENTRY, removeBookmarkEntry);
	yield takeEvery(BOOKMARK_FETCH_ENTRIES, fetchBookmarkEntries);
}
