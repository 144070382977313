import BaseHttpRequest from '../../../boilerplate/redux/BaseHttpRequest';
import appConfig from '../../config/appConfig';
import CookieService from '../../services/CookieService';
import { IApiErrorType } from '../../types/BaseTypes';
import { isBrowser } from '../../../boilerplate/razzle/razzleUtils';

export default class AfCoreRequest extends BaseHttpRequest {
	/**
	 * Defines the base Url of this http requestor
	 */
	public baseUrl = appConfig.apiUrl;

	/**
	 * Returns the error message from an error object
	 *
	 * @param errorObj
	 *
	 * @return {IApiErrorType[]}
	 */
	public getErrorMessage(error:  any): IApiErrorType[] {
		const errorArr = [];

		if (error.body && error.body.message) {
			errorArr.push({
				key: error.body.message,
				payload: error.body.payload,
				errors: error.body.errors,
				status: error.status,
			} as IApiErrorType);
		}

		if (errorArr.length < 1) {
			// TRANSLATE
			return [
				{
					key: 'An error occured',
					payload: {},
					status: error.status,
				} as IApiErrorType,
			];
		}

		return errorArr;
	}

	/**
	 * Parses requestObject and adds additional url params
	 *
	 * @param {IRequestObject} requestObject
	 *
	 * @return { [key: string]: string | number | boolean  } | undefined
	 * @protected
	 */
	protected getAdditionalParams(requestObject: IRequestObject): { [key: string]: string | number | boolean } {
		return {};
	}

	/**
	 * Parses requestObject and adds headers based on the values in there or adds additional ones
	 *
	 * @param {IRequestObject} requestObject
	 *
	 * @return { [key: string]: string | number | boolean  } | undefined
	 * @protected
	 */
	protected getHeaders(requestObject: IRequestObject): { [key: string]: string | number | boolean  } | undefined {
		const headers = (requestObject.headers) ?  requestObject.headers : {};

		// add headers for the application
		// headers['X-Client-Token'] = (isBrowser() && window.screen.width < 550) ? appConfig.apiClientTokenMobile : appConfig.apiClientToken;
		headers['X-Client-Token'] = (isBrowser() && window.screen.width < 550) ? 'bW9iaWxlOmNhYmIzYjNjOTJlMzdkMDliYjc0MDM4MThhMmI1MGU4ZDk0M2YzNTU=' : appConfig.apiClientToken;
		headers['Ocp-Apim-Subscription-Key'] = appConfig.azureApiSubscriptionKey;
		headers['Content-type'] = 'application/json';

		// add default headers
		headers['Accept-Language'] = 'de';
		headers['Accept-Currency'] = 'EUR';

		// add authentication
		if (CookieService.get('at')) {
			headers.Authorization = `Bearer ${CookieService.get('at') + ''}`;
		}

		return headers;
	}
}
