import * as React from 'react';
import { IBrandType } from '../../types/IBrandType';
import * as styles from './BrandListItem.scss';
import Link from '../../../Base/components/Link/Link';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';

interface IBrandListItemProps {
	brand: IBrandType,
	widgetKey?: string,
}

class BrandListItem extends React.Component<IBrandListItemProps, {}> {
	public static defaultProps = {
		widgetKey: 'default',
	};

	public render () {
		const { brand, widgetKey } = this.props;

		return (
			<div className={styles.brandItemWrapper}>

				<Link
					route="BrandDetailRoute"
					params={{
						brand: brand.slug,
					}}
					key={`brand-list-item-${widgetKey}-${brand.slug}`}
				>
					<a className={styles.brandListItem}>

						<div className={styles.brandItemImageContainer}>
							{( brand.resolutions ) ?
							<ImageEnhanced
								source={brand.resolutions}
								className={styles.brandItemImage}
								alt={brand.name}
                                size={'s'}
                                bgImage={true}
							/>
										:
							<ProductImagePlaceholder
								alt={brand.name}
							/>
							}
						</div>

						<div className={styles.brandTextContainer}>
							<span className={styles.brandListItemTitle}>
								{brand.name}
							</span>
						</div>

					</a>
				</Link>
			</div>
		);
	}
}

export default BrandListItem;
