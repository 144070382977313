import * as React from 'react';
import * as styles from './BrandListItem.scss';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';

interface IBrandListItemSkeletonProps {
	widgetKey?: string,
}

class BrandListItemSkeleton extends React.Component<IBrandListItemSkeletonProps, {}> {
	public static defaultProps = {
		widgetKey: 'default',
	};

	public render () {

		return (
			<div className={styles.brandItemWrapper}>
				<a className={styles.brandListItem}>

					<div className={styles.brandItemImageContainer}>

						<ProductImagePlaceholder />

					</div>

					<div className={styles.brandTextContainer}>
						<span className={styles.brandListItemTitle}>
							<SkeletonInline />
						</span>
					</div>

				</a>
			</div>
		);
	}
}

export default BrandListItemSkeleton;
