import * as React from 'react';
import * as styles from './ShoppingCenterList.scss';
import { IShoppingCenterType } from '../../types/IShoppingCenterType';
import { IQueryMetaDataType } from 'boilerplate/redux/types/IQueryMetaDataType';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import ShoppingCenterListItem from '../ShoppingCenterListItem/ShoppingCenterListItem';

export interface IShopListProps {
	shoppingCenters: IShoppingCenterType[];
	gridKey?: string;
	metadata?: IQueryMetaDataType;
	title?: string;
	enablePagination?: boolean;
	boxed?: boolean;

	routeCallbk?: (shopGroup: IShoppingCenterType) => { name: string, params: { [key: string]: any } };
	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class ShoppingCenterListComponent extends React.PureComponent<IShopListProps, {}> {
	public static defaultProps = {
		gridKey: 'shoppingCenters-list',
		boxed: false,
	};

	// TRANSLATE
	public render() {

		const { shoppingCenters, title, gridKey, boxed, contentBreaks } = this.props;

		return (
			<AppMainColBox boxed={boxed} nested={true}>

				<div>
					{(!title) ? null :
						<h2>{title}</h2>
					}

					{shoppingCenters.map((shoppingCenter: IShoppingCenterType, indx: number) => {
						return (
							<React.Fragment key={`shoppingCenters-grid-list-${gridKey}-${shoppingCenter.slug}`}>

								{ (!contentBreaks || !contentBreaks[indx]) ? null :
									<React.Fragment>
										{contentBreaks[indx]}
									</React.Fragment>
								}

								<div className={styles.shoppingCenterListWrapper} >
									<ShoppingCenterListItem shoppingCenter={shoppingCenter} />
								</div>
							</React.Fragment>
						);
					})}
				</div>
			</AppMainColBox>
		);
	}

}

export default ShoppingCenterListComponent;
