import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import LeafletGridContainer from '../../containers/LeafletGrid/LeafletGridContainer';
import PromotionsAggregatedByVendorsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidgetContainer';
import { IPromotionsAggregatedByVendorType } from '../../../Promotion/types/IPromotionsAggregatedByVendorType';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface ILeafletsInIndustryProps {
	industrySlug: SlugType;
}

class LeafletsInIndustry extends React.Component<ILeafletsInIndustryProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, industrySlug } = this.props;

		const industryName = (routeMetadata && routeMetadata.industry && routeMetadata.industry.title) ? routeMetadata.industry.title : industrySlug;

		if (!routeMetadata) {
			return;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403489}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<LeafletGridContainer
								limit={30}
								orderBy="createdAt"
								orderDir="desc"
								enablePagination={true}
								industrySlug={industrySlug}
								onlyNearbyLeaflets={true}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								contentBreaks={{
									6: (
										<AdItem
											mobileAdId={4403478}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
	
									12: (
										<AdItem
											mobileAdId={4403477}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</LeafletGridContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							
							<PromotionsAggregatedByVendorsWidgetContainer
								widgetKey={`li-${industrySlug}-vendor-widget`}
								title={`Beliebte ${industryName} Händler`}
								limit={10}
								type="leaflets"
								industrySlug={industrySlug}
								routeCallbk={this.routerCallbkVendorListWidget}
								orderBy="promotionCount"
								orderDir="desc"
							/>

							<AdItem
								desktopAdId={4403488}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, industrySlug } = this.props;

		const industryName = (routeMetadata && routeMetadata.industry && routeMetadata.industry.title) ? routeMetadata.industry.title : industrySlug;

		return [
			{
				label: 'Prospekte',
				route: 'LeafletListingRoute'
			} as IBreadcrumbItemType,
			{
				label: `${industryName} Prospekte`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the vendors list widget should be routed towards
	 */
	private routerCallbkVendorListWidget = (item: IPromotionsAggregatedByVendorType) => {

		return {
			name: 'LeafletsByVendorRoute',
			params: {
				vendor: item.group.slug,
			},
		};
	};
}

export default LeafletsInIndustry;
