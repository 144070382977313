import * as React from 'react';
import { IApiErrorType } from '../../../types/BaseTypes';
import * as styles from './WidgetBoxError.scss';
import WidgetBoxSkeleton, { IWidgetBoxSkeletonProps } from './WidgetBoxSkeleton';

interface IWidgetBoxErrorProps {
  errors: IApiErrorType[],
  showReloadBtn?: IApiErrorType[],
}

class WidgetBoxError extends React.Component<IWidgetBoxErrorProps & IWidgetBoxSkeletonProps> {
  public render () {
    const { errors, ...restProps } = this.props;

    return (
      <div className={styles.widgetBoxError}>
        <WidgetBoxSkeleton {...restProps} />

        <div className={styles.widgetBoxErrorOverlay}>
	        <div className={styles.widgetBoxErrorMessage}>
		        Konnte nicht geladen werden
	        </div>
        </div>
      </div>
    );
  }
}

export default WidgetBoxError;
