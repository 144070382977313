import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetBoxMoreLink from '../../../../components/Layout/WidgetBox/WidgetBoxMoreLink';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { IThemeworldWidgetType } from '../../types/IThemeworldWidget';
import * as styles from './ThemeworldListWidget.scss';

interface IThemeworldListWidgetProps {
	themeworlds: IThemeworldWidgetType[];
	title: string;
	widgetKey: string;
	style: IWidgetStyleType;

	routeCallbk: (item: IThemeworldWidgetType) => { name: string, params: {[key: string]: any} },

	btnLabel?: string;
	btnRoute?: string;
	btnParams?: { [ key: string ]: any };
}

class ThemeworldListWidget extends React.Component<IThemeworldListWidgetProps, {}> {
	public render() {
		const { themeworlds, title, widgetKey, routeCallbk, btnParams, btnRoute, btnLabel } = this.props;

		// TRANSLATE
		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					<div className={styles.themeworldList}>
						{themeworlds.map((themeworld: IThemeworldWidgetType) => {
							const route = routeCallbk(themeworld);

							return (
								<WidgetListItemLinkRow
									key={`${widgetKey}-themeworldlistwidget-${themeworld.slug}`}
									route={route.name}
									routeParams={route.params}
									className={styles.themeworldListItem}
									title={themeworld.title}
								/>
							);
						})}
					</div>

					{( !btnLabel || !btnRoute ) ? null :
					 <WidgetBoxMoreLink
						 route={btnRoute}
						 params={btnParams}
					 >
						 {btnLabel}
					 </WidgetBoxMoreLink>
					}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default ThemeworldListWidget;
