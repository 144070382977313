import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	addNearestShopEntries,
	NEAREST_SHOP_FETCH_ENTRIES, setNearestShopErrors,
} from './nearestShopActions';

/**
 * Fetch entry list
 */
function* fetchNearestShops(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	let url = `/api/public/v1/promotions/${action.params.promotion}/stores`;
	if (action.params.store_vendor) {
		url = `/api/public/v1/vendors/${action.params.store_vendor}/closest-store`;
	}

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params, 7);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url,
				params,
			} as IRequestObject);
		});

		const content = (action.params.store_vendor) ? response.getContent() : response.getContent('content');

		if (isServer()) {
			response.addMetaData('reloadOnClient', true);
		}

		yield put(addNearestShopEntries(
			action.queryKey,
			(content) ? content : [],
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setNearestShopErrors(action.queryKey, e));
	}
}

export function* nearestShopSaga() {
	yield takeEvery(NEAREST_SHOP_FETCH_ENTRIES, fetchNearestShops);
}
