import { call, put, takeEvery } from 'redux-saga/effects';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import {
	addLotteryInfoEntries,
	LOTTERY_INFO_FETCH_ENTRIES, setLotteryInfoErrors,
} from './lotteryInfoActions';


/**
 * Fetch entry list
 */
function* lotteryInfo(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: '/api/public/v1/lottery/recent-winners',
				params: action.params,
			} as IRequestObject);
			
		});

		const content = {
			winnersHistory: {},
			nextLottery: {},
		};

		content.winnersHistory = response.getContent('content');
		content.nextLottery = response.getContent('nextLottery'),

		yield put(addLotteryInfoEntries(
			action.queryKey,
			content,
			response.getMetaData(),
			action.options,
		));

	} catch (e) {
		yield put(setLotteryInfoErrors(action.queryKey, e));
	}
}

export function* lotteryInfoSaga() {
	yield takeEvery(LOTTERY_INFO_FETCH_ENTRIES, lotteryInfo);
}
