import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItem from '../../../../components/Layout/WidgetBox/WidgetListItem';
import { IProductGroupType } from '../../types/IProductGroupType';

type moreRouteCallbkResultType = { name: string, params: {[key: string]: any} } | string;

interface IProductGroupListWidgetProps {
	productGroups: IProductGroupType[];
	title: string;
	widgetKey?: string;

	routeCallbk: (item: IProductGroupType) => moreRouteCallbkResultType;
}

class ProductGroupListWidget extends React.Component<IProductGroupListWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'productGroup-list-widget',
	};

	public render() {
		const { productGroups, title, widgetKey, routeCallbk } = this.props;

		// TRANSLATE
		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					{productGroups.sort(this.sortAlphabetically).map((productGroup: IProductGroupType) => {
						const route = routeCallbk(productGroup);

						const routeProps = (typeof route === 'string') ? { href: route } : {
							route: route.name,
							params: route.params,
						};

						return (
							<WidgetListItem
								key={`${widgetKey}-productGrouplistwidg-${productGroup.slug}`}
								title={productGroup.title}
								{...routeProps}
							/>
						);
					})}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}

	
	/**
	 * Function which sorts brands list alphabetically by slug value
	 */
	private sortAlphabetically(a: IProductGroupType, b: IProductGroupType) {
		if (a.title.toLowerCase() < b.title.toLowerCase())
		  return -1;
		if (a.title.toLowerCase() > b.title.toLowerCase())
		  return 1;
		return 0;
	}
}

export default ProductGroupListWidget;
