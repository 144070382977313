/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const LEAFLET_GROUPED_FETCH_ENTRIES = '@BasePipeline/leafletGroupedReducer_FETCH_ENTRIES';
export const LEAFLET_GROUPED_FETCH_ENTRY   = '@BasePipeline/leafletGroupedReducer_FETCH_ENTRY';
export const LEAFLET_GROUPED_ADD_ENTRIES = '@BasePipeline/leafletGroupedReducer_ADD_ENTRIES';
export const LEAFLET_GROUPED_ADD_ENTRY = '@BasePipeline/leafletGroupedReducer_ADD_ENTRY';

export const LEAFLET_GROUPED_SET_ERRORS   = '@BasePipeline/leafletGroupedReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchLeafletGrouped (queryKey: string) {
	return {
		type: LEAFLET_GROUPED_FETCH_ENTRIES,
		queryKey,
	};
}

export function addLeafletGroupedEntries (queryKey: string, entries: ILeafletGroupedType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLET_GROUPED_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addLeafletGroupedEntry (queryKey: string, entry: ILeafletGroupedType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLET_GROUPED_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setLeafletGroupedErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLET_GROUPED_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
