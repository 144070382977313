import * as React from 'react';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import Row from '../../../Base/components/Grid/Row';
import DataLayerService from '../../../Base/services/DataLayerService';
import CurrentLeafletWidgetContainer
    from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';
import NewsletterSignupComponent from '../../../Newsletter/components/Newsletter/NewsletterSignupComponent';
import NearestShopWidgetContainer from '../../../Vendor/containers/NearestShopWidget/NearestShopWidgetContainer';
import { IShopType } from '../../../Vendor/types/IShopType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import ProductPromotionInOtherShopsContainer
    from '../../containers/ProductPromotionInOtherShops/ProductPromotionInOtherShopsContainer';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import { IProductType } from '../../types/IProductType';
import ProductPromotionDetailAction from '../ProductPromotionDetailAction/ProductPromotionDetailAction';
import ProductPromotionDetailHeader from '../ProductPromotionDetailHeader/ProductPromotionDetailHeader';
import { DateTime } from 'luxon';
import * as styles from './ProductPromotionDetail.scss';
import { SlugType } from '../../../Base/types/SlugType';
import { IVendorType } from '../../../Vendor/types/IVendorType';
import ProductPromotionDetailHeaderSkeleton from '../ProductPromotionDetailHeader/ProductPromotionDetailHeaderSkeleton';
import NearestShopWidgetContainerSkeleton
    from '../../../Vendor/containers/NearestShopWidget/NearestShopWidgetContainerSkeleton';
import ProductPromotionInOtherShopsContainerSkeleton
    from '../../containers/ProductPromotionInOtherShops/ProductPromotionInOtherShopsContainerSkeleton';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';
import AdItem from '../../../../components/General/AdItem/AdItem';
import ProductPromotionStatusWidgetContainer from '../../containers/ProductPromotionStatusWidget/ProductPromotionStatusWidgetContainer';


interface IProductPromotionDetailProps {
    promotion: IProductPromotionType | null;
    gridKey?: string;
    promotionSlug?: SlugType;
}

const tracked: { [promotionId: string]: boolean } = {};

class ProductPromotionDetail extends React.Component<IProductPromotionDetailProps, {}> {
    public componentDidMount() {
        this.trackPromotionView();
    }

    public componentDidUpdate(prevProps: IProductPromotionDetailProps) {
        const prevPromotion = (prevProps.promotion) ? prevProps.promotion : {};
        const currentPromotion = (this.props.promotion) ? this.props.promotion : {};

        if (prevPromotion.id !== currentPromotion.id) {
            this.trackPromotionView();
        }
    }

    public render() {
        const { promotion, promotionSlug } = this.props;

        if (!promotion) {
            return null;
        }
        
        const productData = (promotion && promotion.product) ? promotion.product : {} as IProductType;

        const description = (promotion && promotion.description) ? promotion.description : ((productData.description) ? productData.description : null);
        const productGroup = (productData.productGroups && productData.productGroups[0] && productData.productGroups[0]) ?
            productData.productGroups[0] :
            (promotion && promotion.productGroups && promotion.productGroups[0] && promotion.productGroups[0]) ? promotion.productGroups[0] : null;

        const vendor = (promotion && promotion.vendor) ? promotion.vendor : {} as IVendorType;

        const validTo = ( typeof promotion.validTo === 'string' ) ? DateTime.fromISO(promotion.validTo) : promotion.validTo;
		const expired = DateTime.local().startOf('day') > validTo;
        
        return (
            <PageWrapper breadcrumbs={this.getBreadcrumbs()}>

                <AppMainCol boxed={true} onTop={true}>

                    <Row>

                        <AppMainColBox boxed={true} nested={true}>

                            {(promotion && productData) ?
                                <React.Fragment>

                                    {expired && productGroup && vendor ? (
                                        <ProductPromotionStatusWidgetContainer
                                            productGroup={productGroup}
                                            vendor={vendor}
                                            widgetKey={`${promotion.slug}-expired-status`}
                                        />
                                        ) : null
                                    }

                                    <ProductPromotionDetailHeader
                                        promotion={promotion}
                                        productData={productData}
                                        promotionExpired={expired}
                                    />

                                    <div className={styles.promotionDetailMobileAction}>
                                        <ProductPromotionDetailAction
                                            promotion={promotion}
                                            productData={productData}
                                            promotionExpired={expired}
                                        />
                                    </div>

                                    {expired ? null :
                                        <NearestShopWidgetContainer
                                            promotionSlug={promotionSlug}
                                            widgetKey={`promotion-${promotionSlug}-detail-nearest-shop`}
                                            childComponentRenderer={this.renderNearestShopAwareComponents}
                                            radius={50000}
                                        >
                                            <React.Fragment>

                                                {(!description) ? null :
                                                    <div
                                                        className={styles.Description}
                                                        dangerouslySetInnerHTML={{ __html: description }}
                                                    />
                                                }

                                                <AdItem
                                                    mobileAdId={4403480}
                                                    maxHeight={250}
                                                    type="content"
                                                    title="Mobile Rectangle 1"
                                                />

                                                {/* // TRANSLATE */}
                                                {(promotion.type !== 'product' && promotion.type !== 'new_price') ? null :
                                                    <ProductPromotionInOtherShopsContainer
                                                        title="Auch hier in Aktion"
                                                        referenceSlug={promotionSlug}
                                                        widgetKey={`promotions-in-other-shops-${promotionSlug}`}
                                                    />
                                                }
                                            </React.Fragment>
                                        </NearestShopWidgetContainer>
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <ProductPromotionDetailHeaderSkeleton />

                                    <ProductPromotionDetailAction promotion={{} as IProductPromotionType} />

                                    <NearestShopWidgetContainerSkeleton
                                        radius={50000}
                                        widgetKey={`promotion-skeleton-detail-nearest-shop`}
                                    >
                                        <React.Fragment>

                                            <ProductPromotionInOtherShopsContainerSkeleton
                                                widgetKey={`promotions-in-other-shops-skeleton`}
                                            />

                                        </React.Fragment>
                                        <SkeletonBlock style={{ marginTop: 10, marginBottom: 10 }} />
                                    </NearestShopWidgetContainerSkeleton>
                                </React.Fragment>
                            }

                            {expired ? null :
                                <React.Fragment>
                                    {(!promotion || promotion.type !== 'voucher') ? null :
                                        <div className={styles.MorePromotionsWrapper}>
                                            <ProductPromotionGridContainer
                                                limit={4}
                                                title={`Weitere Gutscheine`}
                                                titleLevel={2}
                                                moreLabel={`Weitere Gutscheine`}
                                                gridKey={`more-vouchers-promotion-detail-${promotionSlug}`}
                                                fetchVoucherProductPromotions={true}
                                                excludeSlug={promotionSlug}
                                                moreRoute="VoucherListingRoute"
                                                enablePagination={false}
                                                useLeafletRoute={false}
                                            />
                                        </div>
                                    }

                                    {/*// TRANSLATE*/}
                                    {(!promotion || !productGroup) ? null :
                                        <div className={styles.MorePromotionsWrapper}>
                                            <ProductPromotionGridContainer
                                                limit={4}
                                                title={`Andere ${productGroup.title} Aktionen bei ${vendor.name}`}
                                                titleLevel={2}
                                                moreLabel={`Noch mehr ${productGroup.title} Aktionen in diesem ${vendor.name} Markt`}
                                                gridKey={`productgroup-products-promotion-detail-${promotionSlug}`}
                                                productGroupSlug={productGroup.slug}
                                                vendorSlug={vendor.slug}
                                                excludeSlug={promotionSlug}
                                                moreRoute="PromotionsInProductGroupAndVendorRoute"
                                                enablePagination={false}
                                                moreRouteParams={{
                                                    productGroup: productGroup.slug,
                                                    vendor: vendor.slug,
                                                }}
                                            />
                                        </div>
                                    }

                                    {/*// TRANSLATE*/}
                                    {(!promotion || promotion.type !== 'assortment' || !productGroup) ? null :
                                        <div className={styles.MorePromotionsWrapper}>
                                            <ProductPromotionGridContainer
                                                limit={4}
                                                title={`Ähnliche Aktionen`}
                                                titleLevel={2}
                                                moreLabel={`Noch mehr ${productGroup.title} Aktionen`}
                                                gridKey={`other-propmotions-promotion-detail-${promotionSlug}`}
                                                productGroupSlug={productGroup.slug}
                                                enablePagination={false}
                                                fetchAssortementProductPromotions={true}
                                                excludeSlug={promotionSlug}
                                                moreRoute="AssortementPromotionsInProductGroupRoute"
                                                moreRouteParams={{
                                                    productGroup: productGroup.slug,
                                                }}
                                            />
                                        </div>
                                    }

                                    <p>&nbsp;</p>

                                    <NewsletterSignupComponent />
                                </React.Fragment>
                            }
                            <p>&nbsp;</p>

                            <script
                                type="application/ld+json" dangerouslySetInnerHTML={{ __html: this.getSchemaOrgJson() }}
                            />
                        </AppMainColBox>


                        <AppSideCol boxed={false}>
                            {expired ? null :
                                <div>
                                    <CurrentLeafletWidgetContainer
                                        widgetKey={`promotion-detail-found-in-leaflet-${promotionSlug}`}
                                        title={`Hier gefunden`}
                                        promotionSlug={promotionSlug}
                                    />

                                    <AdItem
                                        desktopAdId={4403491}
                                        maxHeight={250}
                                        maxWidth={300}
                                        type="content"
                                        title="Medium Rectangle 1"
                                    />
                                </div>
                            }
                        </AppSideCol>

                    </Row>
                </AppMainCol>
            </PageWrapper>
        );
    }

    /**
     * Returns the breadcrumb path for this page
     */
    private getBreadcrumbs() {
        const { promotion } = this.props;

        return [
            {
                label: 'Aktionen',
                route: 'PromotionListingRoute',
            } as IBreadcrumbItemType,
            {
                label: (promotion && promotion.fullDisplayName) ? promotion.fullDisplayName : 'Aktion',
            } as IBreadcrumbItemType,
        ];
    }

    /**
     * A method that returns a view component that get the "nearestShop" context
     *
     * @param nearestShop
     */
    private renderNearestShopAwareComponents = (nearestShop: IShopType) => {
        const { promotion, promotionSlug } = this.props;

        if (!promotion) {
            return null;
        }

        const productData = (promotion.product) ? promotion.product : {} as IProductType;

        const brand = (promotion.brands && promotion.brands[0]) ? promotion.brands[0] : ((productData.brand) ? productData.brand : null);

        return (
            <React.Fragment>

                {/*// TRANSLATE*/}
                {(!brand || promotion.type === 'voucher') ? null :
                    <div className={styles.MorePromotionsWrapper}>
                        <ProductPromotionGridContainer
                            limit={4}
                            title={`Weitere ${brand.name} Aktionen`}
                            titleLevel={2}
                            // moreLabel={`Mehr ${brand.name} Aktionen in diesem ${promotion.vendor.name} Markt`}
                            gridKey={`more-brand-products-promotion-detail-${promotionSlug}`}
                            brandSlug={brand.slug}
                            excludeSlug={promotionSlug}
                            // moreRoute="PromotionsInShopByBrandRoute"
                            enablePagination={false}
                        // moreRouteParams={{
                        // 	brand: brand.slug,
                        // 	shop: nearestShop.slug,
                        // }}
                        />
                    </div>
                }

            </React.Fragment>
        );
    };

    /**
     * Track view
     */
    private trackPromotionView = () => {
        const { promotion } = this.props;

        if (!promotion || tracked[promotion.id]) {
            return;
        }
        tracked[promotion.id] = true;

        let promotionEventType = '';
        switch (promotion.type) {
            case 'new_price':
                promotionEventType = 'PRICE_PROMOTION_VIEW';
                break;
            case 'product':
                promotionEventType = 'PRODUCT_PROMOTION_VIEW';
                break;
            case 'assortment':
                promotionEventType = 'ASSORTMENT_PROMOTION_VIEW';
                break;
            case 'voucher':
                promotionEventType = 'VOUCHER_PROMOTION_VIEW';
                break;
        }

        const trackingParams = {
            promotionId: promotion.id,
            promotionTitle: promotion.fullDisplayName,
        } as { [key: string]: number | string };

        // add product
        if (promotion.product) {
            trackingParams.productId = promotion.product.id;
            trackingParams.productTitle = promotion.product.title;

            if (promotion.product.brand) {
                trackingParams.brandId = promotion.product.brand.id;
                trackingParams.brandTitle = promotion.product.brand.name;
            }
        }

        if (promotion.vendor) {
            trackingParams.vendorId = promotion.vendor.id;
            trackingParams.vendorTitle = promotion.vendor.name;
        }

        const productCategories = (promotion?.product?.productGroups?.map((productGroup) => productGroup.slug) || []).join(',');
        const brandSlugs = (promotion?.brandSlugs || promotion?.brands?.map((brand) => brand.slug) || []).join(',');

	    if (promotionEventType) {
            InternalTrackingService.track(promotionEventType, {
                promotionId: promotion.id,
                brand_slug: brandSlugs,
                product_category: productCategories,
            });

            DataLayerService.push(promotionEventType, {
                ...trackingParams,
                brandSlug: brandSlugs,
                productCategory: productCategories,
            });
        }
    };

    /**
     * Returns the correct schema org data
     */
    private getSchemaOrgJson(): string {
        const { promotion } = this.props;

        try {

            if (!promotion) {
                return '';
            }

            const productGroup = (promotion.productGroups && promotion.productGroups[0]) ? promotion.productGroups[0].title : null;
            const brand = (promotion.product && promotion.product.brand) ? promotion.product.brand.name : null;

            let lowPrice = (promotion.discountedPrice) ? promotion.discountedPrice : promotion.newPrice;
            if (!lowPrice && promotion.originalPrice) {
                lowPrice = promotion.originalPrice;
            }

            if (!lowPrice) {
                return '';
            }

            if (promotion.type === 'new_price'
                || promotion.type === 'product') {
                return JSON.stringify(
                    {
                        '@context': 'http://schema.org',
                        '@type': 'Product',
                        'image': promotion.mainImageWithout?.resolutions.large,
                        'description': promotion.description,
                        'name': promotion.fullDisplayName,
                        'brand': brand,
                        'offers': {
                            '@type': 'AggregateOffer',
                            'priceCurrency': (promotion.currency) ? promotion.currency.iso : 'EUR',
                            'validFrom': promotion.validFrom,
                            'priceValidUntil': promotion.validTo,
                            "highPrice": (promotion.originalPrice) ? promotion.originalPrice : undefined,
                            'category': productGroup,
                            'lowPrice': lowPrice,
                            'offers': [
                                {
                                    '@type': 'Offer',
                                    'url': `/p/${promotion.slug}`,
                                },
                            ],
                        },
                    },
                );
            }

            return '';

        } catch (e) {
            return '';
        }
    }
}

export default ProductPromotionDetail;
