import React from 'react';
import * as styles from './VendorAggregatedByCityWrapper.scss';
import VendorAggregatedByCity from './VendorAggregatedByCity';
interface IVendorDataType {
    label: string;
    slug: string;
    count: number;
}

interface VendorAggregatedByCityPropTypes {
    data: IVendorDataType[];
    vendor: string;
}


const VendorAggregatedByCityWrapper = (props:VendorAggregatedByCityPropTypes) => {

    const { vendor } = props;

    return(
        <div className={`${styles.wrapperPostCodes}`}>
            <VendorAggregatedByCity {...props} vendorSlug={vendor} />
        </div>
    )
}

export default VendorAggregatedByCityWrapper