/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IPromotionsAggregatedByStateType } from '../../types/IPromotionsAggregatedByStateType';

export const PROMOTIONS_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES = '@BasePipeline/promotionsAggregatedByStatesWidgetReducer_FETCH_ENTRIES';
export const PROMOTIONS_AGGREGATED_BY_STATES_WIDGET_ADD_ENTRIES = '@BasePipeline/promotionsAggregatedByStatesWidgetReducer_ADD_ENTRIES';

export const PROMOTIONS_AGGREGATED_BY_STATES_WIDGET_SET_ERRORS   = '@BasePipeline/promotionsAggregatedByStatesWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchPromotionsAggregatedByStatesWidgets (queryKey: string) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addPromotionsAggregatedByStatesWidgetEntries (queryKey: string, entries: IPromotionsAggregatedByStateType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {

	return {
		type: PROMOTIONS_AGGREGATED_BY_STATES_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setPromotionsAggregatedByStatesWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_STATES_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
