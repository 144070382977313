/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IProductPromotionWithShopType } from '../../types/IProductPromotionWithShopType';

export const PRODUCT_PROMOTION_IN_OTHER_SHOP_FETCH_ENTRIES = '@BasePipeline/productPromotionInOtherShopReducer_FETCH_ENTRIES';
export const PRODUCT_PROMOTION_IN_OTHER_SHOP_ADD_ENTRIES = '@BasePipeline/productPromotionInOtherShopReducer_ADD_ENTRIES';
export const PRODUCT_PROMOTION_IN_OTHER_SHOP_ADD_ENTRY = '@BasePipeline/productPromotionInOtherShopReducer_ADD_ENTRY';

export const PRODUCT_PROMOTION_IN_OTHER_SHOP_SET_ERRORS   = '@BasePipeline/productPromotionInOtherShopReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function addPromotionEntries (queryKey: string, entries: IProductPromotionWithShopType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_IN_OTHER_SHOP_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setPromotionErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_IN_OTHER_SHOP_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
