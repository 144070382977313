import * as React from 'react';
import Link from '../../../Base/components/Link/Link';
import * as styles from './ThemeworldCards.scss';
import ContentBox from '../../../../components/Layout/PageWrapper/ContentBox';
import ThemeworldCardItem from '../ThemeworldCardItem/ThemeworldCardItem';
import { IThemeworldType } from '../../types/IThemeworldType';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';

interface IThemeworldCardsProps {
	themeworlds: IThemeworldType[];
	title?: string;
	widgetKey?: string;
	moreLabel?: string;
	moreRoute?: string;
    columns?: '2' | '3';
	firstCardFullWidth?: boolean;
	boxed?: boolean;
	mobileScrollable?: boolean;
}

class ThemeworldCards extends React.Component<IThemeworldCardsProps, {}> {
	public static defaultProps = {
		widgetKey: 'themeworlds-card-widget',
		columns: '2',
		firstCardFullWidth: true,
	};

	public render() {
        const { themeworlds, title, widgetKey, moreLabel, moreRoute, columns, firstCardFullWidth, boxed, mobileScrollable } = this.props;
        
        let className = `${styles.themeworldCardItemsContainer} ${styles['themeworldCardItemsContainerColumns-'+ columns]}`;
        
        if (firstCardFullWidth) {
            className += ` ${styles.themeworldCardFirstCardFullWidth}`
		}
		
		if (boxed) {
            className += ` ${styles.themeworldCardBoxed}`
        }

		if (mobileScrollable) {
			className += ` ${styles.themeworldCardsScrollable}`
		}

		return (
			<ContentBox nested={true}>
				<div>

					{(!title) ? null :
						<SectionTitle
							title={title}
							route={moreRoute}
							boxed={true}
						/>
					}

                    <div className={className}>
                        {themeworlds.map((themeworld: IThemeworldType, themeworldIndex) => {

                            return (
                                <ThemeworldCardItem
                                    themeworld={themeworld}
                                    key={`${widgetKey}-${themeworld.slug}`}
                                    columns={columns}
                                    firstCardFullWidth={firstCardFullWidth}
                                    themeworldIndex={themeworldIndex}
                                />
                            );
                        })}
                    </div>


					{( !moreLabel ) ? null :
						<div className={styles.themeworldCardMoreItem}>
							<Link route={moreRoute}>
								<a href="#" className={styles.themeworldCardMore}>
                                    <span className={styles.themeworldCardMoreLabel}>
										{moreLabel}
									</span>
								</a>
							</Link>
						 </div>
						}
				</div>
			</ContentBox>
		);
	}

}

export default ThemeworldCards;
