import * as React from 'react';
import { ReactNode } from 'react';
import { IWidgetStyleType } from '../../../types/IWidgetStyleType';
import * as styles from './WidgetBox.scss';
import SkeletonInline from '../Skeleton/SkeletonInline';



interface IWidgetListItemLinkRowProps {
    children?: ReactNode,
    showArrow?: boolean,
    title?: string,
    subtitle?: string | ReactNode,
    href?: string,
    route?: string,
    routeParams?: any,
    style?: IWidgetStyleType,
}

class WidgetListItemLinkRow extends React.Component<IWidgetListItemLinkRowProps> {
    public static defaultProps = {
        showArrow: true,
    };



    public render() {
        const className = `${styles.widgetListItemLinkOnelineRow}`;


        return (
            <React.Fragment>
                <div className={styles.headingSkeleton}>
                    <SkeletonInline width={180} />
                </div>
                <div className={className}>
                    <SkeletonInline width={170} />
                </div>
                <div className={className}>
                    <SkeletonInline width={150} />
                </div>
                <div className={className}>
                    <SkeletonInline width={120} />
                </div>
                <div className={className}>
                    <SkeletonInline width={170} />
                </div>
            </React.Fragment>
        );
    }

}

export default WidgetListItemLinkRow;
