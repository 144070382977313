import * as React from 'react';
import { IVendorType } from '../../types/IVendorType';
import * as styles from './VendorListGroup.scss';
import VendorListSkeletonItem from './VendorListSkeletonItem'

interface IVendorProps {
  vendors: IVendorType[],
  gridKey?: string,
}

class VendorListGroupSkeleton extends React.PureComponent<IVendorProps, {}> {
  public static defaultProps = {
    gridKey: 'vendor-list-skeleton'
  };


  public render() {

    const fakeArray = Array(5).fill(' ');
    let gridKey = 'vendor-list-skeleton';

    return (
      <div className={styles.vendorListGroupWrapper}>
        {fakeArray.map((vendor, index) => {
          return (
            <div className={styles.vendorListGroupItemWrapper} key={`${gridKey}-${index}`}>
              <VendorListSkeletonItem />
            </div>
          )
        })}
      </div>
    );
  }
}

export default VendorListGroupSkeleton;
