import * as React from 'react';
import * as styles from '../AppExclusive.scss';
import Button from '../../../../../components/General/Button/Button';
import InternalTrackingService from '../../../../../services/InternalTrackingService';
import DataLayerService from '../../../services/DataLayerService';

interface IMobileAppBannerProps {
    icon: string;
    title: string;
    image: string;
    buttonText: string;
    buttonLink: string;
}

class AppExclusiveMobileBanner extends React.PureComponent<IMobileAppBannerProps> {

    public render() {
        const { icon, title, image, buttonText, buttonLink } = this.props;

        return (
            <div className={styles.mobileAppBannerWrapper}>
                <div className={styles.mobileAppBannerIconWrapper}>
                    <img
                        src={icon}
                        alt="Installiere die App"
                        className={styles.mobileAppBannerIcon}
                    />
                </div>
                <div className={styles.mobileAppBannerInfo}>
                    <span className={styles.mobileAppBannerTitle}>
                        {title}
                    </span>
                    <img src={image} alt='Rating' className={styles.appPreview} />
                </div>

                <div className={styles.mobileAppBannerButton}>
                    <Button
                        theme="primary"
                        size="l"
                        href={buttonLink}
                        target="_blank"
                        uppercase={false}
                        onClick={this.trackAppEvent}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * Track App click event
     */
	private trackAppEvent = () => {
        const { buttonLink: linkHref, buttonText } = this.props;

        InternalTrackingService.track('PWA_TO_APP', {
            'targetUrl': linkHref,
            'pageTitle': document.title,
        });

        DataLayerService.push('PWA_TO_APP', {
			'targetUrl': linkHref,
            'pageTitle': document.title,
            'pagePath': window.location.pathname,
            'buttonText': buttonText,
        });
    }
}

export default AppExclusiveMobileBanner;