import * as React from 'react';
import * as styles from './CurrentVendorLeaflet.scss';
import LeafletImagePlaceholder from '../LeafletImagePlaceholder/LeafletImagePlaceholder';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';


class CurrentVendorLeafletSkeleton extends React.Component<{}> {

	public render() {
		return (
			<div className={styles.vendorDetailCurrentLeaflet}>
				<div className={styles.leadingLeafletWrapper}>

					<div className={styles.currentLeaflet}>

						<span className={`${styles.leafletItem} ${styles.leafletItemSkeleton}`}>
							
							<LeafletImagePlaceholder activatedPlaceholder={true} alt="Placeholder" />
								
							<span className={`${styles.leafletItemContent} CurrentVendorLeafletSkeletonItemContent`}>
								<span className={`${styles.leafletItemLabel}`}>
									<span className={`${styles.leafletTitle}`}>
										<SkeletonInline />
									</span>
								</span>
								<span className={`${styles.leafletItemValid}`}>
									<SkeletonInline />
								</span>
							</span>

						</span>
					</div>

					<div className={`${styles.childrenWrapper}`}>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

export default CurrentVendorLeafletSkeleton;
