import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IShopWidgetType } from '../../types/IShopWidgetType';
import * as shopWidgetActions from './shopWidgetActions';

export type ShopWidgetAction = ActionType<typeof shopWidgetActions>;

export interface IShopWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IShopWidgetType };
	readonly queries: { [queryKey: string]: IShopWidgetType[] };
}

export function shopWidgetReducer(
	state = baseInitialState as IShopWidgetStateType,
	action: ShopWidgetAction,
) {
	const Reducer = new BaseReducer('shopWidgetReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}
