import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionListingPage from '../App/modules/Promotion/components/PromotionListingPage/PromotionListingPage';
import { withTitlePostfix, pluralizerPromotionsAndSpecials, currentEntryWord } from '../App/utils/routeMetadataUtils';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import BaseRoute from './_BaseRoute';


class PromotionListingRoute extends BaseRoute {
	public render() {
		const props = this.props;
		
		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				
				<MainNavigation />

				<FlexWrapper>
					<div>
						<PromotionListingPage
							{...props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PromotionListingRoute,
		`aktionen`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} in deiner Nähe`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} Aktionen & Top-Angebote in Österreich`),
			metaDescription: `Aktuelle Aktionen, Angebote, Preise, Gutscheine & Sortimentsaktionen » Schnäppchen finden ✓ Preise vergleichen ✓ Geld sparen ✓ Mehr erfahren!`,
		} as IRouteMetadataSetterType
	),
);
