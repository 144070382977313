import * as React from 'react';
import { ComponentType } from 'react';
import withData from '../../../../boilerplate/redux/hocs/withData';
import { SlugType } from '../../Base/types/SlugType';

export default function withShop (WrapperComponent: ComponentType<any>, shopSlug: SlugType, props: any = null, LoadingComponent: ComponentType<any>) {

	const RenderComponent = withData(
		WrapperComponent,
		'shopReducer',
		`shop-hoc-${shopSlug}`,
		{
			shop: shopSlug,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'shop',
			dataKey: 'shop',

			LoadingComponent: () => {

				if (!LoadingComponent) {
					return null;
				}

				return (
					<LoadingComponent 
						productGroupSlug={props.match.params.productGroup}
						shopSlug={props.match.params.shop}
						{...props}
					/>
				);
			}
		},


	
	);

	return (
		<RenderComponent {...props} />
	);
}
