import { act } from 'react-dom/test-utils';
import ReducerManipulator from './ReducerManipulator';
import { IBaseReducerState } from './types/IBaseReducerState';
import { isBrowser, isServer } from '../razzle/razzleUtils';

export const baseInitialState = {
	/**
	 * {object} containing all entries loaded during the lifetime of the application
	 */
	entryMap: {},
	/**
	 * {object} containing all stripped down queries, with entry ids in the order we received
	 */
	queries: {},
	/**
	 * {object} containing meta data of queries like loading state and errors
	 */
	queryMetadata: {},
	/**
	 * {object} containg meta information about this reducer
	 */
	meta: {},
};

class BaseReducer {
	/**
	 * Holds the unique reducer key that identifies the reducer extending this base
	 * @var {string}
	 */
	protected reducerKey: string;

	/**
	 * Holds the models primary key that identifies each specific entry
	 * @var {string}
	 */
	protected primaryKey: string;

	/**
	 * Holds an instance of ReducerManipulator setup correctly
	 * @var {ReducerManipulator}
	 */
	private dataManipulator: ReducerManipulator;

	/**
	 * Initializes this baseReducer with most import global parameters
	 *
	 * @param {string} reducerKey the unique reducer key that identifies the reducer extending this base
	 * @param {string} primaryKey primary key that identifies each specific entry
	 */
	constructor(reducerKey: string, primaryKey: string = 'id') {
		this.reducerKey = reducerKey;
		this.primaryKey = primaryKey;

		this.dataManipulator = new ReducerManipulator();
		this.dataManipulator.setPrimaryKey(primaryKey);
	}

	/**
	 * Initiates fetching of a query expecting multiple entries as a result
	 *
	 * @param {IBaseReducerState} state
	 * @param {object} action
	 * @param {object} additionalMetadata
	 *
	 * @return {IBaseReducerState}
	 */
	public initFetchQuery(state: IBaseReducerState, action: any, additionalMetadata: {[key: string]: any} = {}): IBaseReducerState {
		// if request was made on server side -> add this info
		if (isServer()) {
			additionalMetadata.requestOrigin = 'server';
		}

		if (isBrowser() && action.options && action.options.reloadOnClient) {
			return state;
		}

		// if we are adding something to current data, use the data we already have and just set loading state
		if (action.options && action.options.forceRefetch) {// set meta data to loading stated
			return this.dataManipulator.changeQueryMetadata(
				state,
				action.queryKey,
				Object.assign(
					ReducerManipulator.defaultQueryMetadata,
					{
						loading: true,
						refetching: true,
					},
					additionalMetadata,
				)
			);
		}

		// if we are adding something to current data, use the data we already have and just set loading state
		if (action.options && action.options.queryMergeMode === 'add') {// set meta data to loading stated
			return this.dataManipulator.changeQueryMetadata(
				state,
				action.queryKey,
				Object.assign(
					{
						loading: true,
						backgroundLoading: true,
					},
					additionalMetadata,
				)
			);
		}

		// set meta data to loading stated
		return this.dataManipulator.resetQueryMetadata(
			state,
			action.queryKey,
			Object.assign(
		        {
					loading: true,
				},
				additionalMetadata,
			),
		);
	}

	/**
	 * Mirrors a failed request in our reducer to make sure the containers have knowledge about it
	 *
	 * @param {IBaseReducerState} state
	 * @param {object} action
	 *
	 * @return {IBaseReducerState}
	 */
	public setRequestErrors(state: IBaseReducerState, action: any): IBaseReducerState {
		// set meta data to loading stated
		return this.dataManipulator.changeQueryMetadata(
			state,
			action.queryKey,
			{
				done: true,
				errors: action.errors,
				loading: false,
				success: false,
			},
		);
	}

	/**
	 * Takes the result we got from fetch or operation and adds it to our reducer data
	 *
	 * @param {IBaseReducerState} state
	 * @param {object} action
	 * @param {boolean} multipleEntries
	 *
	 * @return {IBaseReducerState}
	 */
	public addResultToReducer(state: IBaseReducerState, action: any, multipleEntries = true) {
		let newState = state;

		// add entries to entry map
		if (multipleEntries) {
			newState = this.dataManipulator.addOrUpdateMultipleEntries(
				state,
				action.entries,
				action.options,
				this.transformEntry
			);

			// add entries to query map
			newState = this.dataManipulator.addQueryData(
				newState,
				action.queryKey,
				action.entries,
				action.options,
			);
		} else {
			newState = this.dataManipulator.addOrUpdateMultipleEntries(
				state,
				[action.entry],
				action.metaData,
				this.transformEntry
			);

			// add entry to query map
			newState = this.dataManipulator.addQueryData(
				newState,
				action.queryKey,
				action.entry,
				action.options,
			);
		}

		// set metadata
		const newMetaData = Object.assign({
			loading: false,
			done: true,
			error: null,
			success: true,
		}, action.metaData);

		newState = this.dataManipulator.resetQueryMetadata(
			newState,
			action.queryKey,
			newMetaData
		);

		return newState;
	}

	/**
	 * Tries to handle the given action, if a base reduction is requested
	 *
	 * @param {IBaseReducerState} state
	 * @param {object} action
	 *    {
	 *      queryKey: {string|number},
	 *      result: {array|object},
	 *      options: {FetchOptionType}
	 *      params: {object}
	 *    }
	 * @param {(state: IBaseReducerState, action: any) => IBaseReducerState} otherReducers
	 *
	 * @param overwriteReducers
	 * @return {IBaseReducerState}
	 */
	public extend(
		state: IBaseReducerState,
		action: any,
		otherReducers?: (state: IBaseReducerState, action: any, baseReducerContext?: BaseReducer | null) => IBaseReducerState,
		overwriteReducers?: (state: IBaseReducerState, action: any, baseReducerContext?: BaseReducer | null) => IBaseReducerState
	) {

		let workingState = state;
		if (overwriteReducers) {
			workingState = overwriteReducers(workingState, action, this);
		}

		switch (action.type) {
			case `@BasePipeline/${this.reducerKey}_FETCH_ENTRIES`:
				return this.initFetchQuery(workingState, action);

			case `@BasePipeline/${this.reducerKey}_FETCH_ENTRY`:
				return this.initFetchQuery(workingState, action);

			case `@BasePipeline/${this.reducerKey}_ADD_ENTRIES`:
				return this.addResultToReducer(workingState, action);

			case `@BasePipeline/${this.reducerKey}_ADD_ENTRY`:
				return this.addResultToReducer(workingState, action, false);

			case `@BasePipeline/${this.reducerKey}_SET_ERRORS`:
				return this.setRequestErrors(workingState, action);

			default:
				if (otherReducers) {
					return otherReducers(workingState, action, this);
				}

				return workingState;
		}
	}

	/**
	 * This method is used to transform entry data before putting it into the store
	 * @param entry
	 */
	protected transformEntry(entry: any) {
		return entry;
	}
}

export default BaseReducer;
