/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IPromotionsAggregatedByCityType } from '../../types/IPromotionsAggregatedByCityType';

export const PROMOTIONS_AGGREGATED_BY_CITYS_WIDGET_FETCH_ENTRIES = '@BasePipeline/promotionsAggregatedByCitysWidgetReducer_FETCH_ENTRIES';
export const PROMOTIONS_AGGREGATED_BY_CITYS_WIDGET_ADD_ENTRIES = '@BasePipeline/promotionsAggregatedByCitysWidgetReducer_ADD_ENTRIES';

export const PROMOTIONS_AGGREGATED_BY_CITYS_WIDGET_SET_ERRORS   = '@BasePipeline/promotionsAggregatedByCitysWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchPromotionsAggregatedByCitysWidgets (queryKey: string) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_CITYS_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addPromotionsAggregatedByCitysWidgetEntries (queryKey: string, entries: IPromotionsAggregatedByCityType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {

	return {
		type: PROMOTIONS_AGGREGATED_BY_CITYS_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setPromotionsAggregatedByCitysWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_CITYS_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
