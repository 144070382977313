import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItem from '../../../../components/Layout/WidgetBox/WidgetListItem';
import Icon from '../../../Base/components/Icon/Icon';
import { IVendorType } from '../../types/IVendorType';
import VendorListItem from '../VendorList/VendorListItem';
import * as styles from './VendorListWidget.scss';

interface IVendorListWidgetProps {
	vendors: IVendorType[];
	title: string;
	widgetKey?: string;
	routeCallbk: (item: IVendorType) => { name: string, params: {[key: string]: any} },
}

class VendorListWidget extends React.Component<IVendorListWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'vendor-list-widget',
	};
	public render() {
		const { vendors, title, widgetKey, routeCallbk } = this.props;

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					{vendors.map((item: IVendorType) => {
						const route = routeCallbk(item);

						return (
							<div className={styles.vendorListWidgetItemWrapper} key={`${widgetKey}-${item.slug}`}>
								<VendorListItem
									vendor={item}
									sideItem={
										<Icon icon="arrow-right" className={styles.vendorListWidgetItemLinkRowArrow} />
									}
									route={route.name}
									routeParams={route.params}
								/>
						</div>
						);
					})}
					{/* {vendors.map((item: IVendorType) => (
						<WidgetListItem
							key={`${widgetKey}-vendorlistwidg-${item.slug}`}
							title={item.name}
							image={item.resolutions}
							route="VendorDetailRoute"
							params={{ vendor: item.slug }}
						/>
					))} */}
				</WidgetBoxContent>
			</WidgetBox>
		)
	}
};		

export default VendorListWidget;
