import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import { IImageType, IProductType } from '../../types/IProductType';
import ProductImagePlaceholder from '../ProductImagePlaceholder/ProductImagePlaceholder';
import * as styles from './PromotionImage.scss';

interface IPromotionImageProps {
	className?: string,
	promotion: IProductPromotionType
	alt?: string,
	size?: 's' | 'm' | 'l';
}

interface IPromotionImageStateType {
	smallerImage: boolean,
}

class PromotionImage extends React.PureComponent<IPromotionImageProps, IPromotionImageStateType> {
	private imgLoad?: HTMLImageElement;

	public static defaultProps = {
		size: 'm',
	};

	private imageRef: HTMLImageElement;

	constructor(props: IPromotionImageProps) {
		super(props);

		this.state = {
			smallerImage: false,
		};
	}

	public componentWillUnmount() {
		// cancel image load on unmount
		if (!this.imgLoad) {
			return;
		}
		this.imgLoad.onload = function () { };
		delete this.imgLoad;
	}

	public componentDidMount(): void {
		this.getCorrectStyleImplementation();
	}


	// TODO: remove this as soon as legacy images are gone and we don't need this anymore - performance reasons) START
	/**
	 * Checks image height and makes sure smaller legacy images are not scale du upwards, which would result in a pixelated image
	 * 
	 */
	private getCorrectStyleImplementation() {
		const { promotion, size } = this.props;

		const imageResolutions = getMainProductImage(promotion);

		const sizeMapping = {
			s: 'small',
			m: 'medium',
			large: 'large',
		};

		if (!imageResolutions || !imageResolutions.resolutions || !imageResolutions.resolutions[sizeMapping[size]] || imageResolutions.isPlaceholder) {
			return;
		}

		const imageUrlPre = imageResolutions.resolutions[sizeMapping[size]];
		const imageUrl = imageUrlPre.substring(0, imageUrlPre.lastIndexOf('.')) + '.webp';

		this.imgLoad = new Image();
		this.imgLoad.onload = () => {
			const { naturalWidth: imageWidth, naturalHeight: imageHeight } = this.imgLoad;

			if (imageWidth < 100 && imageHeight < 100 && this.state.smallerImage === false) {
				this.setState({
					smallerImage: true,
				});
			}
		}
		this.imgLoad.src = imageUrl;
	}
	// TODO: remove this as soon as legacy images are gone and we don't need this anymore - performance reasons) END


	public render() {
		const { promotion, alt, className, size } = this.props;

		const mainImage = getMainProductImage(promotion);
		const theClassName = `${className} ${styles.imageInside}`;

		const style = {};

		if (this.state.smallerImage) {
			style.backgroundSize = 'auto';
		}

		return (
			<span className={styles.image}>
				<ImageEnhanced
					className={theClassName}
					source={(mainImage) ? mainImage.resolutions : null}
					size={size}
					bgImage={true}
					style={style}
				>
					<ProductImagePlaceholder
						alt={(alt) ? alt : (mainImage && mainImage.title) ? mainImage.title : promotion.title}
					/>
				</ImageEnhanced>
			</span>
		);
	}
}



/**
 * Returns the correct image url (thumb or original)
 *
 * @return {string}
 */
export function getCorrectPromotionImageUrl(promotion: IProductPromotionType | null, size: 'small' | 'medium' | 'large') {
	// if we got a display image with images
	if (promotion?.displayImage?.image?.[size]) {
		return promotion.displayImage.image[size];
	}

	// if we got a display image with url
	if (promotion?.displayImage?.imageUrl) {
		return promotion.displayImage.imageUrl.replace('.jpeg', `.${size}.jpeg`).replace('.png', `.${size}.png`);
	}

	return null;
}

/**
 * Returns the main product image for this promotion
 *
 * @returns {IImageType} | undefined
 */
export function getMainProductImage(promotion, withFallback = true): IImageType | undefined {

	const imageUrl = getCorrectPromotionImageUrl(promotion, 'small');

	if (imageUrl) {
		return {
			id: imageUrl,
			title: '',
			altText: promotion.title,
			copyright: '',
			isImageLoading: false,
			isPlaceholder: false,
			source: '',
			resolutions: {
				type: 'ImageResolution',
				large: getCorrectPromotionImageUrl(promotion, 'large'),
				medium: getCorrectPromotionImageUrl(promotion, 'medium'),
				small: imageUrl,
			},
		};
	}


	// TODO DELETE after image impelemntation of displayImage is finished START
	if (promotion.images && promotion.images[0]) {
		return promotion.images[0];
	}

	const productData = (promotion.product) ? promotion.product : (promotion.products && promotion.products[0]) ? promotion.products[0] : {} as IProductType;

	if (productData.productImages && productData.productImages[0]) {
		return productData.productImages[0];
	}
	// TODO DELETE after image impelemntation of displayImage is finished END


	if (withFallback) {
		return {
			id: 'ph',
			title: '',
			altText: '',
			copyright: '',
			isImageLoading: false,
			isPlaceholder: true,
			source: '',
			resolutions: {
				type: 'ImageResolution',
				large: '/images/layout/productPromotionPlaceholder.png',
				medium: '/images/layout/productPromotionPlaceholder.png',
				small: '/images/layout/productPromotionPlaceholder.png',
			},
		};
	}

	return null;
}


export default PromotionImage;
