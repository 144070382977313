import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import LeafletsByVendor from '../App/modules/Leaflet/components/LeafletsByVendor/LeafletsByVendor';
import { withClickbait, withTitlePostfix } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import withVendor from '../App/modules/Vendor/hocs/withVendor';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';


class LeafletsByVendorRoute extends BaseRoute {
	public static async getInitialProps(state: any) {
		const reduxData = await LeafletsByVendorRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403488]}
					mobileIds={[4403478, 4403477]}
					superbannerId={4403489}
					sitebarId={4403487}
				/>

				<Header />

				<MainNavigation />

				<FlexWrapper>
					<div>
						{withVendor(
							LeafletsByVendor,
							query.vendor,
							this.props
						)}

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403487}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}


export default withRouter(withRouteMetadata(
	LeafletsByVendorRoute,
	(props: any) => {
		return `lv-${props.match?.params?.vendor}`
	},
	{
		pageTitle: (routeMetadata: IRouteMetadataType) => { return `${routeMetadata.vendor?.title} Flugblatt`; },
		metaTitle: (routeMetadata: IRouteMetadataType) => withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.vendor?.title} Flugblatt- & Prospekt-Angebote`),
		metaDescription: (routeMetadata: IRouteMetadataType) => {
			let casus = `${routeMetadata.vendor?.title} Flugblätter & Prospekte`;

			if (routeMetadata.totalItemsCount == 1) {
				casus = `Aktuelles ${routeMetadata.vendor?.title} Flugblatt & Prospekt`;
			}

			else if (routeMetadata.totalItemsCount > 1) {
				casus = `Alle ${routeMetadata.totalItemsCount || ''} ${routeMetadata.vendor?.title} Flugblätter & Prospekte`;
			}


			return withClickbait(`${casus} im Überblick » bei Aktionsfinder informieren ⭐ Preise vergleichen ⭐ Schnäppchen finden `, '⭐ Mehr erfahren!');
		}
	} as IRouteMetadataSetterType
));
