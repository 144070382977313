import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import PromotionsAggregatedByStatesWidgetContainer
	from '../../containers/PromotionsAggregatedByStatesWidget/PromotionsAggregatedByStatesWidgetContainer';
import { IPromotionsAggregatedByStateType } from '../../types/IPromotionsAggregatedByStateType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import { IWithRouteMetadataType } from 'App/types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IVouchersByVendorInStateProps {
    stateSlug: SlugType;
    vendorSlug: SlugType;
}

class VouchersByVendorInState extends React.Component<IVouchersByVendorInStateProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {
		const { routeMetadata, stateSlug, vendorSlug } = this.props;

        const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;
        const vendorName = (routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title) ? routeMetadata.vendor.title : vendorSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
                            <ProductPromotionGridContainer
                                stateSlug={stateSlug}
                                vendorSlug={vendorSlug}
                                enablePagination={true}
                                fetchVoucherProductPromotions={true}
                                gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								orderBy="createdAt"
								orderDir="desc"
								onlyNearbyPromotions={false}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
                            >
                                <NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByStatesWidgetContainer
								widgetKey={`vovst-${vendorSlug}-${stateSlug}-vendor-state-widget`}
								title={`${vendorName} Gutscheine in den Bundesländern`}
								routeCallbk={this.routerCallbkStateListWidget}
								fetchVoucherProductPromotions={true}
								promotionLabel="Gutschein"
								promotionLabelPlural="Gutscheine"
								vendorSlug={vendorSlug}
								excludeSlug={stateSlug}
								orderBy="promotionCount"
								orderDir="desc"
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>


						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, stateSlug, vendorSlug } = this.props;

		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;
		const vendorName = (routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title) ? routeMetadata.vendor.title : vendorSlug;

		return [
			{
				label: 'Gutscheine',
				route: 'VoucherListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.vendor.title : '',
				route: 'VendorDetailRoute',
				routeParams: {vendor: this.props.vendorSlug}
			} as IBreadcrumbItemType,
			{
				label: `${vendorName} Gutscheine in ${stateName}`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkStateListWidget = (item: IPromotionsAggregatedByStateType) => {

        const { vendorSlug } = this.props;
        
		return {
			name: 'VouchersByVendorInStateRoute',
			params: {
                state: item.group.slug,
                vendor: vendorSlug
			},
		};
	};
}

export default VouchersByVendorInState;
