import * as React from 'react';
import { ComponentType } from 'react';
import withData from '../../../../boilerplate/redux/hocs/withData';
import { SlugType } from '../../Base/types/SlugType';

export default function withShoppingCenter (WrapperComponent: ComponentType<any>, shoppingCenterSlug: SlugType, props: any) {
	const RenderComponent = withData(
		WrapperComponent,
		'shoppingCenterReducer',
		`shoppingCenter-detail-${shoppingCenterSlug}`,
		{
			shoppingCenterSlug,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'shoppingCenterSlug',
			dataKey: 'shoppingCenter',
		}
	);
	
	return (
		<RenderComponent {...props} />
	);
}
