import { IRequestMiddlewareObject } from '../types/IRequestMiddlewareObject';
import BaseMutator from './BaseMutator';

class PagingMetadataMutator extends BaseMutator {
	/**
	 * Checks as soon as we got the data, whether we have more pages and sets some other paging related meta infos
	 *
	 * @param {IRequestMiddlewareObject} requestMiddlewareObject
	 *
	 * @return {IRequestMiddlewareObject}
	 */
	public static setResponse (requestMiddlewareObject: IRequestMiddlewareObject): IRequestMiddlewareObject {
		let response = (requestMiddlewareObject.response && requestMiddlewareObject.response.body) ? requestMiddlewareObject.response.body : {};

		if (requestMiddlewareObject.request?.options?.metaDataPath) {
			for (const key of requestMiddlewareObject.request.options.metaDataPath) {
				if (response[key]) {
					response = response[key];
				}
			}
		}

		if (response && response.pageable) {
			const body = response;

			const currentPageNumber = body.pageable.pageNumber;
			const totalPages        = body.totalPages;

			requestMiddlewareObject.metaData.hasMore    = ( currentPageNumber < (totalPages - 1));
			requestMiddlewareObject.metaData.totalItemsCount = (body.totalGroupMembersCount || body.totalGroupMembersCount === 0) ? body.totalGroupMembersCount : body.totalElements;
			requestMiddlewareObject.metaData.count      = body.numberOfElements;
			requestMiddlewareObject.metaData.lastOffset    = currentPageNumber;
			requestMiddlewareObject.metaData.totalPages    = body.totalPages;
		}

		return requestMiddlewareObject;
	}
}

export default PagingMetadataMutator;
