import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import ProductGroupListWidget from '../../components/ProductGroupListWidget/ProductGroupListWidget';
import { IProductGroupType } from '../../types/IProductGroupType';
import { SlugType } from '../../../../modules/Base/types/SlugType';
import DataStateService from '../../../../services/DataStateService';

interface IProductGroupListWidgetContainerProps {
	title: string;
	widgetKey: string;

	limit?: number;
	placeholderLimit?: number;
	page?: number;
	orderBy?: 'relevance';
	orderDir?: 'asc' | 'desc';

	type?: 'similar';

	style?: IWidgetStyleType;

	routeCallbk: (item: IProductGroupType) => { name: string, params: {[key: string]: any} },

	parentSlug?: SlugType; // slug of parent category
	referenceSlug?: SlugType; // slug of related category
	citySlug?: SlugType;
	shoppingCenterSlug?: SlugType;
	productGroupSlug?: SlugType
}

class ProductGroupListWidgetContainerInner extends React.Component<IProductGroupListWidgetContainerProps & IWithDataProps> {
  public render () {
    const { data, widgetKey, title, routeCallbk, parentSlug } = this.props;
		
		if (data.length < 1) {
			return null;
		}
	
    return (
      <ProductGroupListWidget
        title={title}
        productGroups={data as IProductGroupType[]}
        widgetKey={widgetKey}
        routeCallbk={routeCallbk}
      />
    );
  }
}

export default function ProductGroupListWidgetContainer (props: IProductGroupListWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 30;

	const RenderComponent = withData(
		ProductGroupListWidgetContainerInner,
		'productGroupWidgetReducer',
		`productGroup-list-widget-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			page: props.page,
			size: props.limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			parent: props.parentSlug,
			reference: props.referenceSlug,
			city: props.citySlug,
			shopping_center: props.shoppingCenterSlug,
			product_group: props.productGroupSlug,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={ (props.placeholderLimit) ? props.placeholderLimit : limit }
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={ (props.placeholderLimit) ? props.placeholderLimit : limit }
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
