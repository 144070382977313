import * as React from 'react';
import Button from '../../../../components/General/Button/Button';
import appConfig from '../../../../config/appConfig';
import DataLayerService from '../../../../modules/Base/services/DataLayerService';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import { getClickoutGatewayUrl } from '../../../../utils/clickoutGatewayGenerator';
import * as styles from './NewsletterSignupComponent.scss';

interface INewsletterSignupComponentProps {
    fullWidth?: boolean;
}

/**
 * This component represents a newsletter signup block. Clicking on this block redirects to the newsletter signup page.
 * */
class NewsletterSignupComponent extends React.Component<INewsletterSignupComponentProps> {
    constructor(props: INewsletterSignupComponentProps) {
        super(props);
        this.state = {
            fullWidth: false,
        };
    }

    public render() {
        const { fullWidth } = this.props;

        return (
            <a className={`${styles.newsletterBlock} ${fullWidth ? styles.newsletterFullWidthBlock : ''}`} href={getClickoutGatewayUrl(appConfig.newsletterSignupUrl)} target="_blank" onClick={this.trackClickOut}>
                <img src={require('./assets/Leseaffe.svg')} alt="Aktionsfinder" />

                <span className={` ${styles.newsletterText}`}>
                    <span className={` ${styles.newsletterTitle}`}>
                        Newsletter <br /> Abonnieren
                    </span>
                    <span className={`${styles.newsletterSubTitle}`}>... und bei Schnäppchen abkassieren!</span>
                </span>
                <span className={`${styles.newsletterControls}`}>
                    <Button size="1" uppercase={false} fullWidth={false} theme="flat" fontSize={14} type="button">
                        Jetzt anmelden
                    </Button>
                </span>
            </a>
        );
    }

    /**
     * Track clickout
     */
    private trackClickOut = () => {
        InternalTrackingService.track('NEWSLETTER_CLICK', {
            targetUrl: getClickoutGatewayUrl(appConfig.newsletterSignupUrl),
            pageTitle: document.title,
        });

        DataLayerService.push('NEWSLETTER_CLICK', {
            targetUrl: getClickoutGatewayUrl(appConfig.newsletterSignupUrl),
            pageTitle: document.title,
        });
    };
}

export default NewsletterSignupComponent;
