import * as React from 'react';
import { connect } from 'react-redux';
import { SlugType } from '../../../Base/types/SlugType';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import LeafletViewer from '../../components/LeafletViewer/LeafletViewer';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import { withClickbait, withTitlePostfix } from '../../../../utils/routeMetadataUtils';
import withRouteMetadata from '../../../../hocs/withRouteMetadata';
import { RouterProps } from 'react-router';
import { setLeafletDiscountData } from '../../store/Leaflet/leafletActions';
import { ILeafletType } from '../../types/ILeafletType';
import { Helmet } from 'react-helmet-async';
import AdConfig from '../../../../components/General/AdConfig/AdConfig';
import { LeafletViewerType } from '../../hocs/withLeaflet';
import appConfig from '../../../../config/appConfig';
import GeolocationService from '../../../Base/services/GeolocationService';


interface ILeafletDetailProps extends IWithDataProps {
	slug: SlugType,
	queryKey: string;

	page?: number;
	limit?: number;
	orderBy?: 'relevance';
	orderDir?: 'asc' | 'desc';

	setLeafletDiscountData?: (leaflet: ILeafletType) => void;
	leaflet?: ILeafletType;
	leafletViewerType?: LeafletViewerType;
}

class LeafletDetailContainerInner extends React.Component<ILeafletDetailProps & RouterProps> {
	public render () {
		const { leaflet, setLeafletDiscountData } = this.props;

		const leafletViewerType: LeafletViewerType = this.props.params?.leafletViewerType;
		const currentLocation = GeolocationService.getCurrentLocation();

		return (
			<React.Fragment>
				{( leaflet?.showAds === false || leafletViewerType === LeafletViewerType.INFOPOST ) ?
				 <Helmet
					 script={[ {
						 type: 'text/javascript',
						 innerHTML: `var adition = {};`
					 } ]}
				 />
         :
				 <AdConfig
					 mobileIds={[ 4403484 ]}
					 sitebarId={4403496}
					 profile={this.buildProfile()}
				 />
				}
				
				<Helmet encodeSpecialCharacters={false}>
					<title>{leaflet?.title} | Aktionsfinder</title>
					<meta name="title" content={`${leaflet?.title} | Aktionsfinder`} />
					<meta name="description" content={`Alle Aktionen & Angebote im Prospekt » bei Aktionsfinder informieren ✓ Preise vergleichen ✓ bares Geld sparen ✓ Den besten Deal schnappen!`} />
					{/* <meta name="og:image" content={leaflet?.coverPage?.resolutions?.medium || routeMetadataConfig.ogImage} /> */}
					<meta name="og:image" content={`${appConfig.apiUrl}/api/public/v1/vendors/${leaflet?.vendorSlug}/leaflet-teaser-image.png?lat=${currentLocation.lat}&lng=${currentLocation.lng}`} />
				</Helmet>

				<LeafletViewer
					leaflet={leaflet}
					setLeafletDiscountData={setLeafletDiscountData}
					leafletViewerType={leafletViewerType}
				/>
			</React.Fragment>
		);
	}
	  
	private buildProfile(): { [key: string]: string } {
		const { leaflet } = this.props;
		
		if (!leaflet) {
			return {};
		}
	
		const industrySlugs = (leaflet.vendor?.industrySlugs || leaflet.vendorIndustrySlugs) ?? [];

		return {
		  vendorName: leaflet.vendorName,
		  industrySlugs: industrySlugs.join(','),
		};
	}
}


function generateMetaTitle(routeMetadata: IRouteMetadataType, props: any) {
	let titleString = `${props.leaflet.title}`;

	return withTitlePostfix(titleString);
}

function generateMetaDescription(routeMetadata: IRouteMetadataType, props: any) {

	let titleString = `Alle Aktionen & Angebote im ${props.leaflet.title} » bei Aktionsfinder informieren ✓ Preise vergleichen ✓ bares Geld sparen`;

	return withClickbait(titleString, `✓ Den besten Deal schnappen!`);
}

function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		setLeafletDiscountData: (leaflet: ILeafletType) => {
			dispatch(setLeafletDiscountData(leaflet));
		},
	};
}

function mapStateToProps (state: any) {
	return {
		alertReducer: state.alertReducer,
	};
}


export default withRouteMetadata(
	connect(mapStateToProps, mapDispatchToProps)(LeafletDetailContainerInner),
	(theProps: any) => `l-${theProps.params.leaflet}`,
	{
		metaTitle: generateMetaTitle,
		metaDescription: generateMetaDescription,
	} as IRouteMetadataSetterType,
);
