import * as React from 'react';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import InputField from '../../../../components/Layout/Form/InputField';
import Button from '../../../../components/General/Button/Button';
import Link from '../../../Base/components/Link/Link';
import LotteryService from '../../services/LotteryService';
import Checkbox from '../../../../components/Layout/Checkbox/Checkbox';
import AlertService from '../../../Base/services/AlertService';
import Row from '../../../Base/components/Grid/Row';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PromotionsAggregatedByBrandsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../../Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import PromotionsAggregatedByProductGroupsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import LotteryImage from '../LotteryImage/LotteryImage';
import * as styles from './LotteryRegistration.scss';
import { connect } from 'react-redux';
import AdItem from '../../../../components/General/AdItem/AdItem';
import Recaptcha from '../../../../components/General/Recaptcha/Recaptcha';

interface ILotteryRegistrationProps {
	accountReducer: any;
}

interface ILotteryRegistrationStates {
	firstName: string,
	lastName: string,
	email: string,
	terms: boolean,
	loading: boolean,
	success: boolean,
	securityToken: string,
}


class LotteryRegistration extends React.Component<ILotteryRegistrationProps, ILotteryRegistrationStates> {

	constructor(props: ILotteryRegistrationProps) {
		super(props);

		this.state = {
			firstName: props.accountReducer.accountInfo.firstName,
			lastName: props.accountReducer.accountInfo.lastName,
			email: props.accountReducer.accountInfo.email,
			terms: false,
			loading: false,
			success: false,
			securityToken: '',
		};
	}

	private onSubmit = () => {

		const { firstName, lastName, email, securityToken } = this.state;

		if (!this.checkFields()) {
			return;
		}

		this.setState({ loading: true });

		const data = { firstName, lastName, email, securityToken };

		LotteryService.sendLotteryRegistrationData(data as NewAccountData, (response: any) => {

			this.setState({
				loading: false,
				success: true,
				securityToken: ''
			});

		}, (error: any) => {

			this.setState({ loading: false, securityToken: '' });

			AlertService.error(
				'error-' + error,
				error,
			);
		});
	};

	/**
	 * Returns the breadcrumb path for this page
	 */
	public getBreadcrumbs() {
		return [
			{
				label: 'Aktionsfinder der Woche',
				route: 'LotteryInfoRoute'
			} as IBreadcrumbItemType,
			{
				label: 'Anmeldung',
			} as IBreadcrumbItemType,
		];
	}

	private validateRecaptcha = (securityToken: string) => {
		this.setState({
			securityToken,
		})
	};

	public render() {

		const { loading, success, securityToken } = this.state;

		if (success) {
			return (
				<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
					<FormWrapper>

						<H1 align="center" title="DANKE FÜR DEINE ANMELDUNG!">
							Wir haben dir eine E-Mail zur Bestätigung deiner Teilnahme an unserem Gewinnspiel zugesandt. Bitte klicke auf den Link in dieser E-Mail um deine Teilnahme zu bestätigen. Solltest du die E-Mail nicht erhalten, prüfe bitte auch deinen Spam-Ordner.
                        </H1>

                        <Button
	                        theme="primary"
                            size="xxl"
                            fullWidth={true}
                            route="HomeRoute"
                        >
                            Weiter zu den besten Aktionen
                        </Button>

					</FormWrapper>
				</PageWrapper>
			);
		}

		return (
			<Recaptcha onVerify={this.validateRecaptcha} enable={false} securityToken={securityToken}>
				<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()}>
					<H1 title="ANMELDUNG ZUM AKTIONSFINDER DER WOCHE GEWINNSPIEL" />

					<Row>
						<AppMainCol boxed={false}>

							<LotteryImage />

							<AppMainColBox boxed={true}>

								<div className={styles.lotteryRegistrationWrapper}>

									<h2>Jetzt anmelden</h2>

									<p>Um am Gewinnspiel "Aktionsfinder der Woche" teilzunehmen, fülle bitte das nachfolgende Formular aus und klicke auf "Jetzt anmelden". Nachdem du das Formular abgeschickt hast, erhältst du eine E-Mail mit einem Link über den du die Teilnahme noch bestätigen musst.</p>

									<p>&nbsp;</p>

									<div className={styles.lotteryRegistrationContainer}>
										<FormWrapper
											onSubmit={this.onSubmit}
											noStyles={true}
										>

											<InputField
												title="Vorname"
												name="firstName"
												placeholder="Erforderlich"
												onChange={this.onChangeInput}
												value={this.state.firstName}
												required={true}
											/>

											<InputField
												title="Nachname"
												name="lastName"
												placeholder="Erforderlich"
												onChange={this.onChangeInput}
												value={this.state.lastName}
												required={true}
											/>

											<InputField
												title="E-Mail"
												name="email"
												placeholder="Erforderlich"
												type="email"
												onChange={this.onChangeInput}
												value={this.state.email}
												required={true}
											/>

											<Checkbox
												name="terms"
												onChange={this.onChangeInput}
												checked={this.state.terms}
											>
												<React.Fragment>
													<span>Hiermit akzeptiere ich die </span>
													<Link route="LotteryParticipationConditionsRoute">
														<a target="_blank">Teilnahmebedingungen</a>
													</Link>
													<span> des Gewinnspiels: „Aktionsfinder der Woche“. </span>
													<Link route="LotteryParticipationConditionsRoute">
														<a target="_blank">Nähere Informationen</a>
													</Link>
												</React.Fragment>
											</Checkbox>

											<Button
												theme="primary"
												size="xxl"
												type="submit"
												loading={loading}
												customClassName={styles.submitButton}
											>
												Jetzt anmelden
											</Button>

										</FormWrapper>
									</div>
								</div>

							</AppMainColBox>
						</AppMainCol>

						<AppSideCol boxed={true} onTop={true}>
							<div>
								<PromotionsAggregatedByBrandsWidgetContainer
									title={"Bier in Aktion kaufen"}
									limit={7}
									productGroupSlug="bier"
									widgetKey={`lottery-info-promotion-brands-widget`}
									routeCallbk={this.routerCallbkPromotionsAggregatedByBrandsWidget}
								/>

								<AdItem
									desktopAdId={4493474}
									maxHeight={250}
									maxWidth={300}
									type="content"
									title="Medium Rectangle 1"
								/>

								<PromotionsAggregatedByProductGroupsWidgetContainer
									title={`Weitere Angebote`}
									widgetKey={`lottery-info-brand-widget`}
									limit={7}
									routeCallbk={this.routerCallbkBrandListWidget}
									orderBy="promotionCount"
									orderDir="desc"
								/>
							</div>
						</AppSideCol>
					</Row>

				</PageWrapper>
			</Recaptcha>
		);
	}

	private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[name]: value,
		});
	};

	/**
	 * Validate fields and checks terms checkbox.
	 */
	private checkFields(): boolean {

		const { email, terms } = this.state;

		const errors = [];

		if (email && email !== '' && !/\S+@\S+\.\S+/.test(email)) {
			errors.push('Nicht gültiges E-Mail-Feld.');
		}

		if (!terms) {
			errors.push('Bitte akzeptiere unsere Teilnahmebedingungen.');
		}

		if (errors.length > 0) {
			for (let i = 0; i < errors.length; i++) {
				AlertService.error(
					'error-' + errors[i],
					errors[i],
				);
			}
			return false;
		}

		return true
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkPromotionsAggregatedByBrandsWidget = (item: IPromotionsAggregatedByBrandType) => {
		return {
			name: 'BrandDetailRoute',
			params: {
				brand: item.group.slug,
			},
		};
	}

    /**
	 * Defines the route the brand list widget should be routed towards
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByProductGroupType) => {

		return {
			href: item.group.detailUrlPath,
		}
	}
}

function mapStateToProps(state: any) {
	return {
		accountReducer: state.accountReducer,
	}
}

export default connect(mapStateToProps, {})(LotteryRegistration);
