/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IShopType } from '../../types/IShopType';

export const NEAREST_SHOP_FETCH_ENTRIES = '@BasePipeline/nearestShopReducer_FETCH_ENTRIES';
export const NEAREST_SHOP_ADD_ENTRIES = '@BasePipeline/nearestShopReducer_ADD_ENTRIES';

export const NEAREST_SHOP_SET_ERRORS   = '@BasePipeline/nearestShopReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchNearestShops (queryKey: string) {
	return {
		type: NEAREST_SHOP_FETCH_ENTRIES,
		queryKey,
	};
}

export function addNearestShopEntries (queryKey: string, entries: IShopType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: NEAREST_SHOP_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setNearestShopErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: NEAREST_SHOP_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
