import * as React from 'react';
import { IBrandType } from '../../types/IBrandType';
import * as styles from './BrandListGroup.scss';
import BrandListItem from '../BrandListItem/BrandListItem';


interface IBrandProps {
  brands: IBrandType[],
  gridKey?: string,
}

class BrandListGroup extends React.Component<IBrandProps, {}> {
  public static defaultProps = {
    gridKey: 'brand-list'
  };

  // TRANSLATE

  public render () {
    const { brands, gridKey } = this.props;

    return (
      <div className={styles.brandListGroupWrapper}>
        {brands.map(brand => {
          return (
            <div className={styles.brandListGroupItemWrapper} key={`${gridKey}-${brand.slug}`}>
              <BrandListItem
                brand={brand}
              />
            </div>
          )
        })}
      </div>
    );
  }
}

export default BrandListGroup;
