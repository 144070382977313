import { DateTime } from 'luxon';
import BaseReducer from '../../../../boilerplate/redux/BaseReducer';
import { IImageType, IProductType } from '../types/IProductType';
import { IProductPromotionType } from '../types/IProductPromotionType';
import { getMainProductImage } from '../components/PromotionImage/PromotionImage';

export default class ProductPromotionBaseReducer extends BaseReducer {
    /**
     * This method is used to transform entry data before putting it into the store
     * @param entry
     */
    public transformEntry(entry: IProductPromotionType) {
        const image = getMainProductImage(entry);
        const imageWOFallback = getMainProductImage(entry, false);

        return Object.assign(entry, {
            validFrom: DateTime.fromISO(entry.validFrom),
            validTo: DateTime.fromISO(entry.validTo),
            visibleFrom: DateTime.fromISO(entry.visibleFrom),
            mainImage: image ? image.resolutions.large : null,
            mainImageWithout: imageWOFallback,
        });
    }
}
