/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import { IProductPromotionType } from '../../types/IProductPromotionType';

export const PRODUCT_PROMOTION_GROUPED_FETCH_ENTRIES = '@BasePipeline/productPromotionGroupedReducer_FETCH_ENTRIES';
export const PRODUCT_PROMOTION_GROUPED_FETCH_ENTRY   = '@BasePipeline/productPromotionGroupedReducer_FETCH_ENTRY';
export const PRODUCT_PROMOTION_GROUPED_ADD_ENTRIES = '@BasePipeline/productPromotionGroupedReducer_ADD_ENTRIES';
export const PRODUCT_PROMOTION_GROUPED_ADD_ENTRY = '@BasePipeline/productPromotionGroupedReducer_ADD_ENTRY';

export const PRODUCT_PROMOTION_GROUPED_SET_ERRORS   = '@BasePipeline/productPromotionGroupedReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchProductPromotionGrouped (queryKey: string) {
	return {
		type: PRODUCT_PROMOTION_GROUPED_FETCH_ENTRIES,
		queryKey,
	};
}

export function addProductPromotionGroupedEntries (queryKey: string, entries: IProductPromotionGroupedType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_GROUPED_ADD_ENTRIES,
		queryKey,
		entries: entries.map(transformProductPromotionGroup),
		metaData,
		options
	};
}

export function addProductPromotionGroupedEntry (queryKey: string, entry: IProductPromotionGroupedType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_GROUPED_ADD_ENTRY,
		queryKey,
		entry: transformProductPromotionGroup(entry),
		metaData,
	};
}

export function setProductPromotionGroupedErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_GROUPED_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}



/* #### Global leaflet transformations #### */

function transformProductPromotionGroup(productPromotionGroup: IProductPromotionGroupedType): IProductPromotionGroupedType {
	return {
		...productPromotionGroup,
		items: productPromotionGroup.items.map((productPromotion: IProductPromotionType) => {
			return {
				...productPromotion,
			};
		}),
	};
}