import * as React from 'react';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import Button from '../../../../components/General/Button/Button';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';
import Icon from '../../../Base/components/Icon/Icon';
import Link from '../../../Base/components/Link/Link';
import { ILeafletType } from '../../types/ILeafletType';
import LeafletItem from '../LeafletItem/LeafletItem';
import * as styles from './LeafletGrid.scss';

interface ILeafletGridProps {
    leaflets: ILeafletType[];
    metadata?: IQueryMetaDataType;
    size?: 's' | 'm' | 'l';
    gridKey?: string;
    title?: string;
    titleLevel?: number;

    limit?: number;

    columns?: '1' | '2' | '3' | '4' | '5';
    contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing

    boxed?: boolean;
    nested?: boolean;

    fetchMore?: (queryKey: string) => void;
    moreLabel?: string;
    moreRoute?: string;
    moreRouteParams?: { [key: string]: any };
    children: any;
    enablePagination?: boolean;
    mobileScrollable?: boolean;
}

class LeafletGrid extends React.Component<ILeafletGridProps, {}> {
    public static defaultProps = {
        limit: 4,
        columns: 5,
        gridKey: 'lfgrid',
        size: 'm',
        moreRouteParams: {},
        metadata: {},
        contentBreaks: {},
        enablePagination: true,
        children: null,
    };

    public render() {
        const { limit, leaflets, size, gridKey, moreLabel, columns, boxed, nested, moreRoute, children, moreRouteParams, contentBreaks, mobileScrollable, ...restProps } = this.props;

        const title = this.getTitle();

        if (leaflets.length < 1) {
            if (children) {
                return <div>{children}</div>;
            } else {
                return null;
            }
        }

        return (
            <div className={`${styles.leafletGroupOuter}`}>
                <AppMainColBox boxed={boxed} nested={nested}>
                    <React.Fragment>
                        {!title ? null : <SectionTitle title={title} route={moreRoute} routeParams={moreRouteParams} />}

                        <div className={`${styles.leafletGroup} ${styles['leafletGroupColumns' + columns]} ${mobileScrollable ? styles.leafletGroupScrollable : ''}`}>
                            {leaflets.map((leaflet, indx) => (
                                <React.Fragment key={`promotion-leaflet-grid-${leaflet.slug}`}>
                                    {!contentBreaks || !contentBreaks[indx] ? null : (
                                        <div
                                            className={styles.contentBreak}
                                            style={
                                                {
                                                    /* background: 'red', minHeight: 300,  width: '100%'*/
                                                }
                                            }
                                            key={`content-break-${indx}`}
                                        >
                                            <React.Fragment>{contentBreaks[indx]}</React.Fragment>
                                        </div>
                                    )}
                                    <div className={`${styles.leafletGroupItem}`}>
                                        <div className={`${styles.leafletGroupItemInner}`} test-id={`leaflet-grid-item-${gridKey}-${leaflet.slug}`}>
                                            <LeafletItem key={`leaflet-grid-item-${gridKey}-${leaflet.slug}`} leaflet={leaflet} size={size} {...restProps} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}

                            {!mobileScrollable ? null : (
                                <div className={styles.leafletGroupMoreItemMobile}>
                                    <Link route={moreRoute} params={moreRouteParams}>
                                        <a className={styles.leafletGroupMoreMobile}>
                                            <img src={require('./Aeffchen_haengend.svg')} />

                                            <Button size={'m'} theme="primary" fullWidth={true} customClassName={styles.leafletGroupMoreButtonMobile}>
                                                Alle anzeigen
                                            </Button>
                                        </a>
                                    </Link>
                                </div>
                            )}
                        </div>

                        {!moreLabel ? null : (
                            <div className={styles.leafletGroupMoreItem}>
                                <Link route={moreRoute} params={moreRouteParams}>
                                    <a className={styles.leafletGroupMore}>
                                        <span className={styles.leafletGroupMoreIcon}>
                                            <Icon icon="arrow-right" />
                                        </span>
                                        <span className={styles.leafletGroupMoreLabel}>{moreLabel}</span>
                                    </a>
                                </Link>
                            </div>
                        )}
                    </React.Fragment>
                </AppMainColBox>
            </div>
        );
    }

    /**
     * Returns a string containing the url.
     * Will also replace some placeholders with real values
     */
    private getTitle(): string {
        const { title, metadata } = this.props;

        if (!title) {
            return '';
        }

        let retTitle = title;

        // replace placeholders
        retTitle = retTitle.replace('%total%', (metadata ? metadata.totalCount : '') + '');

        return retTitle;
    }
}

export default LeafletGrid;
