/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const LEAFLETS_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES = '@BasePipeline/leafletsAggregatedByStatesWidgetReducer_FETCH_ENTRIES';
export const LEAFLETS_AGGREGATED_BY_STATES_WIDGET_ADD_ENTRIES = '@BasePipeline/leafletsAggregatedByStatesWidgetReducer_ADD_ENTRIES';

export const LEAFLETS_AGGREGATED_BY_STATES_WIDGET_SET_ERRORS   = '@BasePipeline/leafletsAggregatedByStatesWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchLeafletsAggregatedByStatesWidgets (queryKey: string) {
	return {
		type: LEAFLETS_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addLeafletsAggregatedByStatesWidgetEntries (queryKey: string, entries: ILeafletsAggregatedByStateType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {

	return {
		type: LEAFLETS_AGGREGATED_BY_STATES_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setLeafletsAggregatedByStatesWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLETS_AGGREGATED_BY_STATES_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
