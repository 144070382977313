import {
    LEAFLET_VIEWER_GO_TO_PAGE,
    LEAFLET_VIEWER_GO_TO_SLIDE,
    LEAFLET_VIEWER_INIT_SLIDES,
} from './leafletViewerActions';
import { IdType } from '../../../Base/types/IdType';

export interface ILeafletViewerUIState {
	pagesOnSlide: IdType[],
	isCurrentSlide?: boolean,
	isPlaceholder?: boolean,
	zoomLevel?: number,
	zoomCenterX?: number,
	zoomCenterY?: number,
}

type SlideStatesType = { [indx: number]: ILeafletViewerUIState };

export interface ILeafletViewerStoreType {
	readonly slideStates: SlideStatesType;
	readonly currentSlide: number;
	readonly naviDirection?: 'forwards' | 'backwards';
	readonly pagesShown: number;
	readonly currentLeaflet: IdType | null,
	readonly page2SlideMap: {[key: number]: number},
	readonly lastPage: number,
}

const initialState = {
	slideStates: {},
	currentSlide: 0,
	naviDirection: 'forwards',
	pagesShown: 0,
	currentLeaflet: null,
	page2SlideMap: {},
	lastPage: 0,
};

const uiStateDefault = {
	isCurrentSlide: false,
	isPlaceholder: true,
	zoomLevel: 1,
	zoomCenterX: 0,
	zoomCenterY: 0,
};

export function leafletViewer (
	state = initialState as ILeafletViewerStoreType,
	action: any,
) {
	switch (action.type) {
		case LEAFLET_VIEWER_INIT_SLIDES:
			const page2SlideGenerator = generatePageMap(action.slideStates);

			return Object.assign({}, state, {
				slideStates: setCurrentSlideState(action.slideStates, action.currentSlide),
				currentSlide: action.currentSlide,
				currentLeaflet: action.currentLeaflet,
				...page2SlideGenerator,
			});

    case LEAFLET_VIEWER_GO_TO_SLIDE:
		let newIndx = action.slideIndx;
		const slideStateKeys = Object.keys(state.slideStates);
		const lastSlideIndx = parseInt(slideStateKeys[slideStateKeys.length - 1], 10);

		// make sure the page is within range
		if (newIndx > lastSlideIndx) {
					newIndx = lastSlideIndx - 1;
		} else if (newIndx < 0) {
					newIndx = 0;
		}


		let naviDirection = state.naviDirection;
		if (newIndx !== state.currentSlide) {
			naviDirection = ((newIndx > state.currentSlide) ? 'forwards' : 'backwards');
		}

		let pagesShown = state.pagesShown;
		if (newIndx !== state.currentSlide) {
			pagesShown += 1;
		}

        updateUrlOnNavigation(newIndx, state.slideStates);

        return {
	        ...state,
	        slideStates: setCurrentSlideState(state.slideStates, newIndx),
	        currentSlide: newIndx,
			naviDirection,
			pagesShown,
        };

        case LEAFLET_VIEWER_GO_TO_PAGE:
            let newPage = action.pageNumber;

            // make sure the page is within range
	        if (newPage > (state.lastPage + 1)) {
		        newPage = (state.lastPage + 1);
	        } else if (newPage < 0) {
                newPage = 0;
            }

            // get the correct slide index
	        const newSlideIndx = (state.page2SlideMap[newPage]) ? state.page2SlideMap[newPage] : 0;

	        updateUrlOnPageNavigation(newSlideIndx, state.slideStates);

            return Object.assign({}, state, {
                slideStates: setCurrentSlideState(state.slideStates, newSlideIndx),
                currentSlide: newSlideIndx,
            });

		default:
			return state;
	}
}

const PRELOAD_NEIGHBOURS = 2;



/**
 * Updates url to make sure we got the correct one
 * @param slideIndex
 * @param slideStates
 */
function updateUrlOnNavigation(slideIndex: number, slideStates: SlideStatesType) {
	// get correct slide index to the the pagenumber we got from hash

	if (slideStates[slideIndex] && slideStates[slideIndex].pagesOnSlide && slideStates[slideIndex].pagesOnSlide[0]) {
		const pageNumber = parseInt(slideStates[slideIndex].pagesOnSlide[0], 10);
		const currentPageHash = `#${((pageNumber * 1) + 1)}`;

		if (currentPageHash !== window.location.hash && history && history.replaceState) {
			history.replaceState(undefined, undefined, currentPageHash);
			// window.location.hash = currentSlideHash;
		}
	}
}

/**
 * Updates url to make sure we got the correct one
 * @param pageNumber
 * @param slideStates
 */
function updateUrlOnPageNavigation(pageNumber: number, slideStates: SlideStatesType) {
	const currentPageHash = `#${((pageNumber * 1) + 1)}`;
	if (currentPageHash !== window.location.hash && history && history.replaceState) {
		history.replaceState(undefined, undefined, currentPageHash);
	}
}

/**
 * Generates a map of pagenumbers 2 slide indices and also makes sure we always now how many pages our leaflet has
 * @param slideStates
 */
function generatePageMap(slideStates: SlideStatesType) {
	const page2SlideMap = {};
	let lastPage = 0;

    for (const slideIndx of Object.keys(slideStates)) {
    	const pageNumbers = slideStates[slideIndx].pagesOnSlide;

    	for (const pageNumberStr of pageNumbers) {
    		const pageNumber = parseInt(pageNumberStr, 10);

            page2SlideMap[pageNumber] = slideIndx;

    		if (lastPage < pageNumber) {
    			lastPage = pageNumber;
		    }
	    }
    }

    return {
        page2SlideMap,
	    lastPage,
    };
}

/**
 * Takes an array of slides and the current slide index and sets current slide + placeholders
 *
 * @param slideStates
 * @param currentSlideIndx
 */
function setCurrentSlideState(slideStates: SlideStatesType, currentSlideIndx: number): SlideStatesType {
	const currentSlideStates = {};

	for (const slideIndx of Object.keys(slideStates)) {
		const slideState = slideStates[slideIndx];

		// define the correct state
		const changeObj = {
			// pagesOnSlide: [],
			isCurrentSlide: false,
			isPlaceholder: true,
		};

		// if is within preload distance -> set to non neighbours
		if (+slideIndx >= (currentSlideIndx - PRELOAD_NEIGHBOURS) && +slideIndx <= (currentSlideIndx + PRELOAD_NEIGHBOURS)) {
			changeObj.isPlaceholder = false;
		}

		if (+slideIndx === +currentSlideIndx) {
			changeObj.isCurrentSlide = true;
		}

		currentSlideStates[slideIndx] = Object.assign({}, uiStateDefault, slideState, changeObj);
	}

	return currentSlideStates;
}
