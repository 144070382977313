/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { ICarouselTeaser } from '../../types/CarouselTeaser/ICarouselTeaser';

export const CAROUSEL_FETCH_ENTRIES = '@BasePipeline/carouselReducer_FETCH_ENTRIES';
export const CAROUSEL_ADD_ENTRIES = '@BasePipeline/carouselReducer_ADD_ENTRIES';
export const CAROUSEL_SET_ERRORS = '@BasePipeline/carouselReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchCarouselTeasers(queryKey: string) {
    return {
        type: CAROUSEL_FETCH_ENTRIES,
        queryKey,
    };
}

export function addCarouselTeasers(queryKey: string, entries: ICarouselTeaser[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: CAROUSEL_ADD_ENTRIES,
        queryKey,
        entries,
        metaData,
        options,
    };
}

export function setCarouselTeasersErrors(queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: CAROUSEL_SET_ERRORS,
        queryKey,
        errors,
        metaData,
    };
}
