import { IGeolocationType } from '../modules/Base/types/IGeolocationType';

const geolocationConfig = {
    /**
     * The default postal code if nothing else could be determined
     * @type string|number
     */
    defaultPostalcode: 1010,

    /**
     * The default city name if nothing else could be determined
     * @type string
     */
    defaultCity: 'Wien',

    /**
     * The default location, that will be used if no other location can be determined
     */
    defaultLocation: {
        label: 'Aktueller Standort',
        lat: 48.208176,
        lng: 16.373819,
    } as IGeolocationType,

    /**
     * The fallback location, when location is outside Austria
     */
    fallbackLocation: {
        label: 'Wien',
        lat: 48.208176,
        lng: 16.373819,
        manualSelection: true,
    } as IGeolocationType,

    /**
     * A list of location suggestions, that will be displayed in the location selector
     */
    defaultLocationSuggestions: [
        {
            label: 'Wien',
            lat: 48.208176,
            lng: 16.373819,
            manualSelection: true,
            zipCode: '1010, Österreich',
        } as IGeolocationType,
        {
            label: 'Salzburg',
            lat: 47.80949,
            lng: 13.05501,
            manualSelection: true,
            zipCode: '5020, Österreich',
        } as IGeolocationType,
        {
            label: 'Linz',
            lat: 48.306938,
            lng: 14.28583,
            manualSelection: true,
            zipCode: '4020, Österreich',
        } as IGeolocationType,
        {
            label: 'Graz',
            lat: 47.070713,
            lng: 15.439504,
            manualSelection: true,
            zipCode: '8010, Österreich',
        } as IGeolocationType,
        {
            label: 'Innsbruck',
            lat: 47.262691,
            lng: 11.3947,
            manualSelection: true,
            zipCode: '6020, Österreich',
        } as IGeolocationType,
        {
            label: 'Eisenstadt',
            lat: 47.845879,
            lng: 16.517969,
            manualSelection: true,
            zipCode: '7000, Österreich',
        } as IGeolocationType,
        {
            label: 'Bregenz',
            lat: 47.50304,
            lng: 9.74707,
            manualSelection: true,
            zipCode: '6900, Österreich',
        } as IGeolocationType,
        {
            label: 'St. Pölten',
            lat: 48.203529,
            lng: 15.63817,
            manualSelection: true,
            zipCode: '3100, Österreich',
        } as IGeolocationType,
        {
            label: 'Klagenfurt',
            lat: 46.636459,
            lng: 14.312225,
            manualSelection: true,
            zipCode: '9020, Österreich',
        } as IGeolocationType,
    ],
};

export default geolocationConfig;
