import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import LeafletGridContainer from '../../containers/LeafletGrid/LeafletGridContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import { IWithRouteMetadataType } from 'App/types/IWithRouteMetadataType';
import LeafletsAggregatedByProductGroupsWidgetContainer
	from '../../containers/LeafletsAggregatedByProductGroupsWidget/LeafletsAggregatedByProductGroupsWidgetContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface ILeafletsInProductGroupProps {
	productGroupSlug: SlugType;
}

class LeafletsInProductGroup extends React.Component<ILeafletsInProductGroupProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, productGroupSlug } = this.props;

		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403489}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<section>
								<LeafletGridContainer
									enablePagination={true}
									productGroupSlug={productGroupSlug}
									gridKey={routeMetadata.queryKey}
									pushRouteMetadata={true}
									onlyNearbyLeaflets={true}
									contentBreaks={{
										6: (
											<AdItem
												mobileAdId={4403478}
												maxHeight={250}
												type="content"
												title="Mobile Rectangle 1"
											/>
										),
		
										12: (
											<AdItem
												mobileAdId={4403477}
												maxHeight={250}
												type="content"
												title="Mobile Rectangle 2"
											/>
										)
									}}
								>
									<NoDataError />
								</LeafletGridContainer>
							</section>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<LeafletsAggregatedByProductGroupsWidgetContainer
								widgetKey={`lpc-${productGroupSlug}-product-group-widget`}
								title={`Verfeinere deine Auswahl`}
								parentProductGroupSlug={productGroupSlug}
								excludeSlug={productGroupSlug}
								limit={100}
								placeholderLimit={5}
								routeCallbk={this.routerCallbkProductGroupListWidget}
							/>
								
							<AdItem
								desktopAdId={4403488}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {

		return [
			{
				label: 'Prospekte',
				route: 'LeafletListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.pageTitle: '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		const { productGroupSlug } = this.props;

		return {
			name: 'LeafletsInProductGroupRoute',
			params: {
				productGroup: item.group.slug,
			},
		};
	}
}

export default LeafletsInProductGroup;
