import * as React from 'react';
import Link from '../../../Base/components/Link/Link';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import ProductPromotionGroup from './ProductPromotionGridGroup';
import * as styles from './ProductPromotionGridGrouped.scss';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import ProductPromotionGridGroupedSkeleton from './ProductPromotionGridGroupedSkeleton';
import Icon from '../../../Base/components/Icon/Icon';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';

// type moreRouteCallbkResultType = { name: string, params: { [key: string]: any } } | string;

export interface IProductPromotionGridGroupedProps {
	products: IProductPromotionGroupedType[],
	size?: 's' | 'm' | 'l',
	gridKey?: string,
	columns?: '1' | '2' | '3',

	enablePagination?: boolean;

	groupTitle?: string,
	groupTitlePlural?: string,

	moreLabel?: string,
	moreRouteCallbk?: (productGroup: IProductPromotionGroupedType) => { name: string, params: { [key: string]: any } } | string,
	moreRouteChangeble?: boolean;

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing

	children?: React.ReactNode;  // if this is given, this will be shown if the array doesn't return any results

	metadata?: IQueryMetaDataType,
	fetchMore?: (queryKey: string) => void;
	queryKey?: string;

	useLeafletRoute?: boolean;
}

class ProductPromotionGridGrouped extends React.PureComponent<IProductPromotionGridGroupedProps, {}> {
	public static defaultProps = {
		columns: 3,
		gridKey: 'default',
		size: 'm',
		contentBreaks: {},
		moreRouteChangeble: true,
	};

	// TRANSLATE
	public render() {
		const {
			products,
			size,
			gridKey,
			moreLabel,
			moreRouteCallbk,
			columns,
			contentBreaks,
			children,
			fetchMore,
			metadata,
			queryKey,
			enablePagination,
			moreRouteChangeble,
			useLeafletRoute,
		} = this.props;

		if (!products || products.length < 1) {
			if (children) {
				return (
					<div>
						{children}
					</div>
				);
			} else {
				return null;
			}
		}

		return (
			<Pagination
				fetchMore={fetchMore}
				metadata={metadata}
				queryKey={queryKey}
				enablePagination={enablePagination}
				LoaderComponent={
					<ProductPromotionGridGroupedSkeleton
						limit={2}
						limitItemsPerGroup={6}
					/>
				}
			>
				<React.Fragment>
					{products.map((productGroup, indx) => {
						let route: any = {
							name: '',
							params: {},
						};
						if (moreRouteCallbk) {
							const moreRouteLink = moreRouteCallbk(productGroup);
							route = moreRouteLink ? moreRouteLink : route;
						}

						const productGroupNormalized = this.normalizeGroupObject(productGroup);
						const isNotRealGroup = (productGroup.slug === 'fake' || !productGroup.slug);

						const groupTitle = this.getGroupTitle(productGroupNormalized, isNotRealGroup);
						
						const routeProps = (typeof route === 'string') ? { href: route } : {
							route: route.name,
							params: route.params,
						};

						return (
							<React.Fragment key={`product-grid-group-${gridKey}-${productGroupNormalized.slug}`}>

								{(!contentBreaks || !contentBreaks[indx]) ? null :
									<React.Fragment>
										{contentBreaks[indx]}
									</React.Fragment>
								}
								<div className={styles.productPromotionGridGroupWrapper}>
								
									<SectionTitle
										title={groupTitle}
										route={routeProps.route}
										routeParams={routeProps.params}
										href={( !moreRouteChangeble ) ? null : productGroup.group.detailUrlPath}
									/>
						
									<ProductPromotionGroup
										productGroup={productGroup}
										promotions={(productGroupNormalized.promotions || productGroupNormalized.items)}
										size={(!isNotRealGroup) ? size : undefined}
										columns={columns}
										useLeafletRoute={useLeafletRoute}
									/>

									{(!moreLabel || isNotRealGroup) ? null :
										<div className={`${styles.productPromotionGridGroupedMoreItem}`}>
											<Link
												{...routeProps}
												href={( !moreRouteChangeble ) ? null : productGroup.group.detailUrlPath}
											>
												<a className={styles.productPromotionGridGroupedMore}>
													{this.getMoreLabel(productGroupNormalized)}
												</a>
											</Link>
										</div>
									}
								</div>
							</React.Fragment>

						);
					})
					}
				</React.Fragment>
			</Pagination>
		);
	}

	/**
	 * Normalize the group object, because api always sends different data structures for similar data
	 *
	 * @param productGroup
	 */
	private normalizeGroupObject(productGroup: IProductPromotionGroupedType): IProductPromotionGroupedType {
		if (productGroup.group) {
			productGroup.title = (productGroup.group.name || productGroup.group.title);
			productGroup.slug = productGroup.group.slug;
		}

		productGroup.totalItemsCount = ((productGroup.totalItemsCount) ? productGroup.totalItemsCount : productGroup.promotionsCount) || '';

		return productGroup;
	}

	/**
	 * Returns the group title with replaced placeholders
	 *
	 * @param productGroupNormalized
	 * @param isNotRealGroup
	 */
	private getGroupTitle(productGroupNormalized: IProductPromotionGroupedType, isNotRealGroup: boolean): string {
		const { groupTitle, groupTitlePlural } = this.props;

		const currentProductGroupTitle = (isNotRealGroup) ? 'weitere' : productGroupNormalized.title;

		if (!groupTitle) {
			const numerus = (productGroupNormalized.totalItemsCount === 1) ? 'Aktion' : 'Aktionen';
			return `${productGroupNormalized.totalItemsCount} ${currentProductGroupTitle} ${numerus} ${(productGroupNormalized.maxDiscountPercentage > 1) ? `bis -${Math.round(productGroupNormalized.maxDiscountPercentage)}%` : ''}`;
		}

		let ret = (productGroupNormalized.totalItemsCount === 1) ? groupTitle : ((groupTitlePlural) ? groupTitlePlural : groupTitle);

		// replace a few things
		ret = ret.replace('%title%', currentProductGroupTitle);
		ret = ret.replace('%count%', productGroupNormalized.totalItemsCount + '');
		ret = ret.replace('%city%', productGroupNormalized.group && productGroupNormalized.group.name);
		ret = ret.replace('%maxDiscount%', (productGroupNormalized.maxDiscountPercentage > 1) ? `bis -${Math.round(productGroupNormalized.maxDiscountPercentage)}%` : '');

		return ret;
	}

	/**
	 * Returns the more label with replaced placeholders
	 *
	 * @param productGroup
	 */
	private getMoreLabel(productGroup: IProductPromotionGroupedType): string {
		const { moreLabel, } = this.props;

		if (!moreLabel) {
			return '';
		}

		let ret = moreLabel;

		// replace a few things
		ret = ret.replace('%title%', productGroup.title);
		ret = ret.replace('%city%', productGroup.group && productGroup.group.name);

		return ret;
	}
}

export default ProductPromotionGridGrouped;
