import * as React from 'react';
import { ReactNode } from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import * as styles from './Button.scss';

interface IButtonProps {
	href?: string;
	target?: string;
	onClick?: (e: IReactMouseButtonEvent) => void;
	theme?: 'primary' | 'default' | 'contrast' | 'transparent' | 'flat';
	size?: 's' | 'm' | 'l' | 'l2' | 'xl' | 'xxl';
	uppercase?: boolean;
	children: ReactNode;
	fullWidth?: boolean;
	route?: string;
	routeParams?: {[key: string]: any};
	fontSize?: number;
	type?: string;
	loading?: boolean;
	customClassName?: string,
	style?: { [key: string]: number | string };
}

class Button extends React.Component<IButtonProps & React.HTMLProps<HTMLButtonElement>, {}> {
	public static defaultProps = {
		size: 'm',
		theme: 'default',
		uppercase: false,
		type: 'button',
	};

	public render() {
		const { onClick, children, href, target, route, routeParams, fontSize, type, loading, style = {} } = this.props;

		const className = this.generateClassName();

		if (href) {
			return (
				<a className={className} href={href} target={target}
					onClick={onClick} 
					style={style}>
					{children}
				</a>
			);
		}

		if (route) {
			return (
				<Link
					route={route}
					params={routeParams}
					style={style}
					onClick={onClick} 
				>
					<a className={className}>
						{children}
					</a>
				</Link>
			);
		}

		return (
			<button 
				type={type}
				className={className} 
				onClick={onClick} 
				style={fontSize ? { ...style, fontSize } : style}
				disabled={loading}
				aria-label={typeof children === "string" ? children  : ""}
			>
				{children} 


				{(!loading) ? null :
					<div className={styles.ldsRing}>
						<div />
						<div />
						<div />
						<div />
					</div>
				}
			</button>
		);
	}

	/**
	 * Creates the button class name based on the props we received
	 * @returns {string}
	 */
	private generateClassName(): string {
		const { uppercase, theme, size, fullWidth, loading, customClassName } = this.props;

		let className = `${styles.button} ${styles[theme + 'StyleButton']} ${
			styles[size + 'SizeButton']
		}`;

		if (customClassName) {
			className += ` ${customClassName}`;
		}

		if (uppercase) {
			className += ` ${styles.buttonUppercase}`;
		}

		if (fullWidth) {
			className += ` ${styles.buttonFullWidth}`;
		}

		if (loading) {
			className += ` ${styles.buttonLoading}`;
		}

		return className;
	}
}

export default Button;
