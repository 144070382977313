import * as React from 'react';
import * as styles from './SelectField.scss';
import Icon from '../../../modules/Base/components/Icon/Icon';

interface ISelectFieldProps {
    title: string,
    name: string,
    placeholder?: string,
    onChange: (name: string, value: string) => void;
    value: string,
    required: boolean,
    disabled: boolean,
    children: React.ReactNode
}

class SelectField extends React.PureComponent<ISelectFieldProps, {}> {

    public static defaultProps = {
        placeholder: "",
        required: false,
        disabled: false,
    };

	public render () {
		const { title, name, placeholder, value, required, disabled, children } = this.props;

		return (
			<div className={styles.selectFieldWrapper}>
                <span className={styles.selectFieldTitle}>
                    {title}
                </span>

                <Icon icon="arrow-down" className={styles.selectFieldIcon}/>

                <select 
                    className={styles.selectField}
                    name={name}
                    placeholder={placeholder}
                    onChange={this.onChange}
                    value={value}
                    required={required}
                    disabled={disabled}
                >
                    <option disabled value="" selected>{placeholder}</option>

                    {children}

                </select>

            </div>
		);
    }
    
    /**
	 * Handles onchange events
	 * @param e
	 */
	private onChange = (e: React.FormEvent<HTMLSelectElement>) => {
		const { onChange } = this.props;

		if (!onChange) {
			return;
		}
  
		onChange(e.currentTarget.name, this.trim(e.currentTarget.value));
    };
    
    /**
     * Trims select value
     * @param value 
     */
    private trim(value: string) {
        return value.replace(/^\s+|\s+$/g,"");
    }
}

export default SelectField;
