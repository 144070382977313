const serverErrorMap = {
    201: 'Dieses Benutzerkonto ist bereits vorhanden.',
    401: 'E-Mail Adresse oder Passwort ungültig.',
    400: 'Dein Passwort muss mindestens aus 8 Zeichen bestehen und eine Zahl enthalten',
    403: 'Diese Aktion ist leider nicht erlaubt.',
    409: 'Diese E-Mail Adresse ist bereits vergeben.',
    404: 'Konnte nicht gefunden werden',
    424: 'Bei deiner Anmeldung ist ein Fehler aufgetreten. Bitte versuche es erneut oder wende dich an office@aktionsfinder.at um Hilfe zu erhalten.',
    500: 'Leider ist ein unerwarteter Fehler aufgetreten, bitte versuche es erneut.'
};

export default serverErrorMap;
