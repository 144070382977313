// TODO: TRANSLATE

/**
 * Takes the number of results and will return either the singular or plural form of label
 *
 * @param count
 * @param wordSingular
 * @param wordPlural
 */
export function currentEntryWord(count: number | undefined, wordSingular: string | null = null, wordPlural: string | null = null): string {
	if (count == 1) {
		if (wordSingular) {
			return wordSingular;
		}

		return 'aktueller';
	}

	if (count > 1) {
		if (wordPlural) {
			return wordPlural;
		}

		return 'aktuelle';
	}

	return '';
}

/**
 * Takes the number of results and will return either the singular or plural form of label
 *
 * @param count
 */
export function pluralizerPromotionsAndSpecials(count: number | undefined): string {
	if (count === 1) {
		return 'Aktion';
	}

	return 'Aktionen & Angebote';
}

/**
 * Takes the number of results and will return either the singular or plural form of label
 *
 * @param count
 */
export function pluralizerVouchers(count: number | undefined): string {
	if (count === 1) {
		return 'Gutschein';
	}

	return 'Gutscheine';
}

/**
 * Takes the number of results and will return either the singular or plural form of label
 *
 * @param count
 */
export function pluralizerLeaflets(count: number | undefined, extended = false): string {
	if (count === 1) {
		return 'Prospekt';
	}

	if (extended) {
		return 'Flugblätter & Prospekte';
	}

	return 'Prospekte';
}

/**
 * Takes the number of results and will return either the singular or plural form of label
 *
 * @param count
 */
export function pluralizerShops(count: number | undefined): string {
	if (count === 1) {
		return 'Markt';
	}

	return 'Märkte';
}

/**
 * Takes the number of results and will return either the singular or plural form of label
 *
 * @param count
 */
export function pluralizerShoppingCenters(count: number | undefined): string {
	if (count === 1) {
		return 'Einkaufszentrum';
	}

	return 'Einkaufszentren';
}

/**
 * Takes the number of results and will return either the singular or plural form of label
 *
 * @param count
 */
export function pluralizerAssortementPromotions(count: number | undefined): string {
	if (count === 1) {
		return 'Sortimentsaktion';
	}

	return 'Sortimentsaktionen';
}

/**
 * If we have a maxdiscount over a specific threshold, we will return it otherwise nothing
 *
 * @param maxItemDiscount
 */
export function maxDiscountInTitle(maxItemDiscount: number | undefined, extended = false): string {
	if (maxItemDiscount && maxItemDiscount > 3) {

		if (!extended) {
			return ` bis -${Math.round(maxItemDiscount)}%`;
		}

		return ` bis zu -${Math.round(maxItemDiscount)}%`;
	}

	return '';
}

/**
 * Adds the postfix in metatitle only if we have less than 165 chars
 *
 * @param title
 */
export function withTitlePostfix(title: string): string {
	if (title.length > 65) {
		return title;
	}

	return `${title} | Aktionsfinder`;
}

/**
 * Adds the clickbait in metadescription only if we have less than 165 chars
 *
 * @param metaDescription
 * @param clickbait
 */
export function withClickbait(metaDescription: string, clickbait: string = ''): string {
	if ((metaDescription.length + clickbait.length) > 165) {
		return metaDescription;
	}

	return `${metaDescription} ${clickbait}`;
}
