import { call, put, takeEvery } from 'redux-saga/effects';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import { addPromotionsStatusEntry, PRODUCT_PROMOTION_STATUS_FETCH_ENTRIES, setPromotionErrors, PRODUCT_PROMOTION_STATUS_FETCH_ENTRY } from './productPromotionStatusActions';

/**
 * Fetch entry list
 */
function* fetchPromotionStatus(action: any) {
    const afCoreRequest = new AfCoreRequest(action.queryKey);

    try {
        const response = yield call(
            (): Promise<RequestMiddlewarePipeline> => {
                return afCoreRequest.postRequest({
                    url: `/api/public/v1/status/promotion-detail`,
                    params: action.params,
                    body: {
                        productGroupSlug: action.params.productGroupSlug,
                        vendorSlug: action.params.vendorSlug,
                    },
                } as IRequestObject);
            },
        );

        if (isServer()) {
            response.addMetaData('reloadOnClient', true);
        }

        yield put(addPromotionsStatusEntry(action.queryKey, response.getContent(), response.getMetaData(), action.options));
    } catch (e) {
        yield put(setPromotionErrors(action.queryKey, e));
    }
}

export function* productPromotionStatusSaga() {
    yield takeEvery(PRODUCT_PROMOTION_STATUS_FETCH_ENTRIES, fetchPromotionStatus);
    yield takeEvery(PRODUCT_PROMOTION_STATUS_FETCH_ENTRY, fetchPromotionStatus);
}
