/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const LEAFLET_PAGE_FETCH_ENTRIES = '@BasePipeline/leafletPageReducer_FETCH_ENTRIES';
export const LEAFLET_PAGE_FETCH_ENTRY   = '@BasePipeline/leafletPageReducer_FETCH_ENTRY';
export const LEAFLET_PAGE_ADD_ENTRIES = '@BasePipeline/leafletPageReducer_ADD_ENTRIES';
export const LEAFLET_PAGE_ADD_ENTRY = '@BasePipeline/leafletPageReducer_ADD_ENTRY';

export const LEAFLET_PAGE_SET_ERRORS   = '@BasePipeline/leafletPageReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchLeafletPages (queryKey: string) {
	return {
		type: LEAFLET_PAGE_FETCH_ENTRIES,
		queryKey,
	};
}

export function addLeafletPageEntries (queryKey: string, entries: ILeafletPageType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {

	return {
		type: LEAFLET_PAGE_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options,
	};
}

export function addLeafletPageEntry (queryKey: string, entry: ILeafletPageType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLET_PAGE_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
		options,
	};
}

export function setLeafletPageErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLET_PAGE_SET_ERRORS,
		queryKey,
		errors,
		metaData,
		options,
	};
}
