import * as React from 'react';
import { SearchPageFilterValueType } from '../SearchPage/FilterInputs/SearchPageFilterGroupCheckboxGroupItem';

export type UrlFilterFilterData = { [key: string]: string | string[] };
export type UrlFilterNavigateType = (params: UrlFilterFilterData) => void;
export type UrlFilterApplyFilterType = (newFilters: SearchFilterContentObjectType, urlNavigationBehavior: 'now' | 'defer' | 'none' = 'now') => void;
export type UrlFilterResetFilterType = () => void;

interface ISearchPageContainerPropType {
	queryParams?: { [key: string]: string };
	defaultFilterData?: { [key: string]: string };
	wrappedComponent: React.ComponentType;
	navigate: UrlFilterNavigateType;
	deferChangeTime?: number; // how long should we wait before navigating to new url in milliseconds
	enableSearchCounter?: boolean;
}

interface ISearchPageContainerStateType {
	inDeferedState?: boolean;
	filterData?: { [key: string]: SearchPageFilterValueType };
	applyFilters?: (newFilters: SearchFilterContentObjectType) => void;
	resetFilters?: () => void;
	searchCount: number;
}

type SearchFilterContentObjectType = { [key: string]: string | string[] };

const UrlFilterContext = React.createContext({
	inDeferedState: false,
	filterData: {},
	applyFilters: () => {},
	resetFilters: () => {},
});

export const UrlFilterConsumer = UrlFilterContext.Consumer;

class UrlFilterProvider extends React.Component<ISearchPageContainerPropType, ISearchPageContainerStateType> {
	static defaultProps = {
		deferChangeTime: 2000,
	};

	private deferFilterNavigationTimeout: NodeJS.Timeout;

	constructor (props: ISearchPageContainerPropType) {
		super(props);

		this.state = {
			filterData: (props.queryParams) ? this.queryString2FilterObject(props.queryParams) : {},
			applyFilters: this.applyFilters,
			resetFilters: this.resetFilters,
			searchCount: 0,
		};
	}

	componentWillUnmount (): void {
		if (this.deferFilterNavigationTimeout) {
			clearTimeout(this.deferFilterNavigationTimeout);
		}
	}

	componentDidUpdate (prevProps: ISearchPageContainerPropType): void {
		const { queryParams } = this.props;

		if (queryParams !== prevProps.queryParams) {
			this.setState({
				filterData: (queryParams) ? this.queryString2FilterObject(queryParams) : {},
			});
		}
	}

	public render () {
		const { wrappedComponent: WrappedComponent, ...restProps } = this.props;
		const { filterData, searchCount } = this.state;

		return  (
			<UrlFilterContext.Provider value={this.state}>
				<WrappedComponent
					{...restProps}
					applyFilters={this.applyFilters}
					filterData={filterData}
					searchCount={searchCount}
				/>
			</UrlFilterContext.Provider>
		)
	}

	/**
	 * Converts the filter object into a query string we can pass
	 *
	 * @param filterObject
	 */
	private static filterObject2FlatQuery(filterObject: SearchFilterContentObjectType): { [key: string]: string } {
		// generate query params string
		let queryString = {};

		for (const key of Object.keys(filterObject)) {
			const val = filterObject[key];

			if (Array.isArray(val)) {
				if (val !== '-' && val.length > 0) {
					queryString[ key ] = `-${val.join(',')}`;
				}
			} else {
				if (typeof val !== 'undefined') {
					queryString[ key ] = `${val}`;
				}
			}
		}

		return queryString;
	}

	/**
	 * Applies new filters by generating a query string we can use and redirecting to that
	 *
	 * @param newFilters
	 */
	private navigateToUrl = (newFilters: SearchFilterContentObjectType) => {
		const { navigate } = this.props;
		const flatQueryObj = UrlFilterProvider.filterObject2FlatQuery(newFilters);

		if (!navigate) {
			console.error('No navigate method provided to UrlFilterProvider. Please make sure to pass a routing method.');
			return;
		}

		navigate(flatQueryObj);
	}

	/**
	 * Resets all filters to be empty
	 */
	private resetFilters = () => {
		const { defaultFilterData } = this.props;
		// update in state
		this.setState({
			filterData: { ...defaultFilterData },
		}, () => {
					this.navigateToUrl({ });
		});
	}

	/**
	 * Applies new filters by generating a query string we can use and redirecting to that
	 *
	 * @param newFilters
	 * @param urlNavigationBehavior describes the behavior of url change for this. Now will trigger rigth away,
	 *        defer will wait a bit (to make sure the user can select other options as well) and "none" will never change url
	 */
	private applyFilters = (newFilters: SearchFilterContentObjectType, urlNavigationBehavior: 'now' | 'defer' | 'none' = 'now') => {
		const { deferChangeTime, enableSearchCounter } = this.props;
		const { filterData, searchCount } = this.state;

		for (const key of Object.keys(newFilters)) {
			filterData[key] = newFilters[key];
		}

		// if we still have an active deferer out there -> cancel it. We will create a new one anyway
		if (this.deferFilterNavigationTimeout) {
			clearTimeout(this.deferFilterNavigationTimeout);
		}

		// update in state
		this.setState({
			filterData,
			inDeferedState: (urlNavigationBehavior === 'defer'),
			searchCount: enableSearchCounter ? (searchCount + 1) : searchCount,
		}, () => {
			if (urlNavigationBehavior !== 'none') {
				this.deferFilterNavigationTimeout = setTimeout(() => {
					this.navigateToUrl(filterData);

					if (urlNavigationBehavior === 'defer') {
						this.setState({
							inDeferedState: false,
						});
					}
				}, ( ( urlNavigationBehavior === 'now' ) ? 0 : deferChangeTime ));
			}
		});
	}

	/**
	 * Takes a query string and converts it into our internal working filter object
	 *
	 * @param queryParams
	 */
	private queryString2FilterObject(queryParams: {[key: string]: string}): SearchFilterContentObjectType {
		const { defaultFilterData } = this.props;
		const filterObject = {...defaultFilterData};

		for (const key of Object.keys(queryParams)) {
			const val = queryParams[key];

			if (val.substring(0, 1) === '-') {
				filterObject[key] = val.substring(1).split(',');
			} else {
				filterObject[key] = val;
			}
		}

		return filterObject;
	}
}

export default UrlFilterProvider;


