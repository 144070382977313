import * as React from 'react';
import { connect } from 'react-redux';
import Link from '../../../modules/Base/components/Link/Link';
import Button from '../../General/Button/Button';
import HeaderSearch from '../../General/HeaderSearch/HeaderSearch';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import * as layoutStyles from '../Layout.scss';
import * as styles from './Header.scss';
import AccountService from '../../../modules/Account/services/AccountService';
import LayoutService from '../../../services/LayoutService';
import Icon from '../../../modules/Base/components/Icon/Icon';
import { showLocationModal } from '../../../modules/Base/store/Modal/modalActions';

interface IHeaderProps {
    geolocationReducer?: any;
    showLocationModal?: () => void;
}

class Header extends React.Component<IHeaderProps, {}> {
    constructor(props) {
        super(props);
    }

    public render() {
        if (LayoutService.isAppShellDisabled()) {
            return null;
        }

        const { geolocationReducer, showLocationModal } = this.props;

        const loggedButton = Header.checkIfLoggedIn();
        const currentLocation = geolocationReducer.currentLocation ? geolocationReducer.currentLocation : null;

        return (
            <React.Fragment>
                <div className={styles.headerSpacer} />

                <header className={`${styles.header}`} id="main-header">
                    <div className={styles.headerPadd}>
                        <div className={layoutStyles.containerFluid}>
                            <div className={styles.headerInner}>
                                <div className={styles.headerBrand}>
                                    <Link route="HomeRoute">
                                        <a title="Zur Startseite" href="HomeRoute">
                                            <img src={require('./logo-l.svg')} itemProp="logo" alt="Aktionsfinder Logo" className={styles.desktopLogo} />

                                            <img src={require('./logo-xs.png')} alt="Aktionsfinder Logo" className={styles.mobileLogo} />
                                        </a>
                                    </Link>
                                </div>

                                <div className={styles.headerSearchPlaceholder} />

                                <span className={styles.menuLocationLabel} onClick={showLocationModal}>
                                    {currentLocation.label ? currentLocation.label : 'Aktueller Standort'}
                                </span>

                                <Icon icon="map-marker" className={styles.headerLocationIcon} onClick={showLocationModal} />

                                {/* Currently disenabled */}
                                {/* <Link route="AppExclusiveRoute">
									<a href='AppExclusiveRoute'>
										<div className={styles.headerShoppingIcon}>
											<Icon icon='shoppinglist-add' />
										</span>
									</a>
								</Link> */}

                                {/* Enable when notifications are implemented */}
                                {/* <div className={styles.headerBookmarkIcon}>
									<NavigationBookmarkIconContainer  icon='notification' />
								</div> */}

                                <div className={styles.headerSigninButton}>{loggedButton}</div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className={`${styles.headerBurgerMenu}`} id="burgerMenu">
                    <BurgerMenu />
                </div>

                <div className={`${styles.headerSearchContainer}`} id="searchBar">
                    <HeaderSearch />
                </div>
            </React.Fragment>
        );
    }

    /**
     * Checks if logged in and generate button (Registration or Logout)
     */
    private static checkIfLoggedIn() {
        const logged = AccountService.check();

        if (logged) {
            return (
                <Button route="LogoutRoute" theme="flat" size="m" uppercase={false}>
                    Abmelden
                </Button>
            );
        }

        return (
            <Button route="LoginRoute" theme="flat" size="m" uppercase={false}>
                Anmelden
            </Button>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        accountReducer: state.accountReducer,
        geolocationReducer: state.geolocationReducer,
    };
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
    return {
        showLocationModal: () => {
            dispatch(showLocationModal());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
