import * as React from 'react';
import * as styles from './PromotionworldGridGroup.scss';
import PromotionworldGridItem from '../PromotionworldGridItem/PromotionworldGridItem';
import PromotionworldTeaserItem from '../PromotionworldTeaserItem/PromotionworldTeaserItem';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IPromotionworldGridGroupPromotionType } from '../../types/IPromotionworldGridGroupType';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import { IPromotionworldType } from '../../types/IPromotionworldType';


interface IPromotionworldGridGroupedProps {
  promotionworld: IPromotionworldType;
  initialPromotions: IPromotionworldGridGroupPromotionType[];
  promotions: IPromotionworldGridGroupPromotionType[];
  gridKey: string;
  moreLabel: string;
  totalItems?: number;

  fetch: () => void;
  metadata: IQueryMetaDataType,
	fetchMore: (queryKey: string) => void;
  onPromotionClick: (promotion: IProductPromotionType) => void;
}

class PromotionworldGridGroup extends React.Component<IPromotionworldGridGroupedProps, {}> {
  public static defaultProps = {
    totalItems: 2,
  };

  public render () {
    const { initialPromotions, promotions, gridKey, moreLabel, totalItems, onPromotionClick, promotionworld } = this.props;

    const promotionsCombined = this.combinePromotions(initialPromotions, (promotions || []));
    
    return (
      <div>
        <div className={styles.promotionworldGroupWrapper}>
          <div className={`${styles.promotionworldGroup}`}>
            {promotionsCombined.map((product) =>  (
                product.promotion ? 
                  <div className={`${styles.promotionworldGroupItem}`} key={`product-grid-item-${gridKey}-${product.id}`}>
                    <div className={styles.promotionworldGroupItemInner}>
                      <PromotionworldGridItem
                        promotion={product.promotion}
                        onPromotionClick={onPromotionClick}
                        directLinkClickouts={promotionworld.leaflet.directLinkClickouts}
                      />
                    </div>
                  </div>
                  :
                  <div className={
                    `${styles.promotionworldGroupItem} ${product.image.size === 'IMAGE_2x1' ? styles.promotionworldGroupTeaserItem : styles.promotionworldGroupSmallTeaserItem}`}
                    key={`product-grid-item-${gridKey}-${product.id}-teaser`}
                  >
                      <PromotionworldTeaserItem
                        promotionImage={product.image}
                        onPromotionClick={onPromotionClick}
                        directLinkClickouts={promotionworld.leaflet.directLinkClickouts}
                      />
                  </div>
              ))}
                  
          </div>
        </div>

        {(!moreLabel || totalItems === promotionsCombined.length ) ? null :
          <div className={`${styles.promotionworldGridGroupedMoreItem}`}>
            <button className={styles.promotionworldGridGroupedMore} onClick={() => this.fetchMorePromotions()}>
              {moreLabel}
            </button>
          </div>
        }
      </div>
    );
  }

  /**
   * COmbines promotions which come from initial props and from API after fetching more
   */
  private combinePromotions = (arr1: IPromotionworldGridGroupPromotionType[], arr2: IPromotionworldGridGroupPromotionType[]): IPromotionworldGridGroupPromotionType[] => {
    const combinedArray = [];
  
    // loop through first array and add unique objects to combined array
    for (let obj of arr1) {
      if (!combinedArray.some((item: IPromotionworldGridGroupPromotionType) => item.id === obj.id)) {
        combinedArray.push(obj);
      }
    }
  
    // loop through second array and add unique objects to combined array
    for (let obj of arr2) {
      if (!combinedArray.some((item: IPromotionworldGridGroupPromotionType) => item.id === obj.id)) {
        combinedArray.push(obj);
      }
    }

    return combinedArray;
  }
  
  private fetchMorePromotions = () => {
    const { promotions, fetch, fetchMore, gridKey } = this.props;

    if (!promotions) {
      fetch();
    } else {
      fetchMore(gridKey);
    }
  }
}

export default PromotionworldGridGroup;
