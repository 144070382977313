import * as React from 'react';
import * as styles from './BrandListGrouped.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import BrandListGroupSkeleton from './BrandListGroupSkeleton';

interface IBrandProps {
  gridKey?: string,
}


class BrandListGroupedSkeleton extends React.Component<IBrandProps, {}> {
  public static defaultProps = {
    gridKey: 'brand-list'
  };

  // TRANSLATE

  public render () {
    const { gridKey } = this.props;

    return ( 
        <div className={styles.brandListGroupedWrapper}>
          {[1,2,3,4,5,6,7].map((item) => {

            return (
              <React.Fragment key={`vendor-list-${gridKey}`}>
                <h2 className={styles.brandListGroupedTitle}>
                  <SkeletonInline />
                </h2>

                <BrandListGroupSkeleton  />

                <div className={`${styles.brandListGroupedMoreItem}`}>
                  <a className={styles.brandListGroupedMore}>
                    <SkeletonInline />
                  </a>
                </div>

              </React.Fragment>
             )
          })} 
        </div>
    );
  }
}

export default BrandListGroupedSkeleton;
