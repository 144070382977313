import * as React from 'react';
import { ICurrencyType } from '../../../Promotion/types/IProductType';

interface IDatespanProps {
  amount: number;
  currency?: ICurrencyType;
}

class Currency extends React.PureComponent<IDatespanProps> {

  public render() {
    const { amount, currency }= this.props;

    const theAmountString = formatCurrency(amount, currency);

    return (
        <span>{theAmountString}</span>
    );
  }
}

/**
 * Takes an amount and returns a localized currency string
 *
 * @param amount
 * @param currency
 */
export function formatCurrency(amount: number, currency: ICurrencyType): string {
	const currencyIso = (currency && currency.iso) ? currency.iso : 'EUR';
	return new Intl.NumberFormat("de-DE", {style: "currency", currency: currencyIso }).format(amount);
}

export default Currency;
