import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import * as styles from './SearchPage.scss';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import SearchInput from '../../components/SearchInput/SearchInput';
import MostUsedQueriesContainer from '../../containers/MostUsedQueries/MostUsedQueriesContainer';

interface ISearchPageNoSearchTermPropType {
}

class SearchPageNoSearchTerm extends React.Component<ISearchPageNoSearchTermPropType & IWithDataProps, {}> {
	public render () {
		return (
			<PageWrapper
				title={`Bitte gib einen Suchbegriff ein und starte eine neue Suche`}
				titleAlign="left"
			>
				<div className={styles.NoResultsWrapper}>
					{/* Input container */}
					<SearchInput
						label="Neue Suche starten"
						placeholder="Suche"
						style="light"
						autoFocus={true}
					/>

					{/* Container for empty search results */}
					<MostUsedQueriesContainer
						title="Beliebte Suchbegriffe"
					/>
				</div>
			</PageWrapper>
		);
	}
}

/**
 * @param theProps
 *
 * @constructor
 */
export default SearchPageNoSearchTerm;


