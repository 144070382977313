import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as promotionsAggregatedByStatesWidgetActions from './promotionsAggregatedByStatesWidgetActions';
import { IPromotionsAggregatedByStateType } from '../../types/IPromotionsAggregatedByStateType';

export type PromotionsAggregatedByStatesWidgetAction = ActionType<typeof promotionsAggregatedByStatesWidgetActions>;

export interface IPromotionsAggregatedByStatesWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IPromotionsAggregatedByStateType };
	readonly queries: { [queryKey: string]: IPromotionsAggregatedByStateType[] };
}

export function promotionsAggregatedByStatesWidgetReducer(
	state = baseInitialState as IPromotionsAggregatedByStatesWidgetStateType,
	action: PromotionsAggregatedByStatesWidgetAction,
) {
	const Reducer = new BaseReducer('promotionsAggregatedByStatesWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
