import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './Col.scss';

interface IColProps {
  children: ReactChild,
  size: number,
  className?: string,
  width?: string|number,
}

class Col extends React.Component<IColProps, {}> {
  public render () {
    const { children, size, width, className } = this.props;

    return (
      <div className={`${styles.col} ${className}`} style={{ flexGrow: size, minWidth: width }}>
        {children}
      </div>
    );
  }
}

export default Col;
