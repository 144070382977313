import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import AccountChangeData from '../../components/AccountChangeData/AccountChangeData';
import AccountChangeEmail from '../../components/AccountChangeEmail/AccountChangeEmail';
import AccountChangePassword from '../../components/AccountChangePassword/AccountChangePassword';
import LoadingIndicator from '../../../../components/Layout/LoadingIndicator/LoadingIndicator';
import { Redirect } from 'react-router'; // TODO: fix navigation to react-router
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';

interface IAccountChangeContainerProps {
	type: 'data' | 'email' | 'password',
}

class AccountChangeContainerInner extends React.Component<IAccountChangeContainerProps & IWithDataProps> {
	public static defaultProps = {

	};

	public render () {
		const { data, type } = this.props;

		if (data.length === 0) {
			return <Redirect to='/login' />
		}

		if ( type === 'data' ) {
			return (
				<AccountChangeData 
					data={data as IAccountInfoType}
					{...this.props}
				/>
			);
		}

		if ( type === 'email' ) {
			return (
				<AccountChangeEmail
					data={data as IAccountInfoType}
					{...this.props}
				/>
			);
		}

		if ( type === 'password' ) {
			return (
				<AccountChangePassword
					data={data as IAccountInfoType}
					{...this.props}
				/>
			);
		}
	}
}

export default function AccountChangeContainer (theProps: IAccountChangeContainerProps) {
	const props = Object.assign({

	}, theProps);

	if (isServer()) {
		return (
			<LoadingIndicator>
				Laden ...
			</LoadingIndicator>
		);
	}

	const RenderComponent = withData(
		AccountChangeContainerInner,
		'accountReducer',
		`accountInfo-${props.type}`,
		{

		},
		{
			LoadingComponent: (errorProps: any) => (
		        <LoadingIndicator>
					Laden ...
		        </LoadingIndicator>
	        ),
		}
	);

	return <RenderComponent {...props} />;
}


