import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import LoadingIndicator from '../../../../components/Layout/LoadingIndicator/LoadingIndicator';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import { isLeafletTypePremium } from '../../../../utils/leafletTypeChecker';
import DataLayerService from '../../../Base/services/DataLayerService';
import { IdType } from '../../../Base/types/IdType';
import FutherLeafletsPageContainer from '../../containers/LeafletDetail/FutherLeafletsPageContainer';
import { LeafletViewerType } from '../../hocs/withLeaflet';
import { ILeafletViewerStoreType, ILeafletViewerUIState } from '../../store/LeafletViewer/leafletViewerReducer';
import { ILeafletPageRegionType } from '../../types/ILeafletPageRegionType';
import { ILeafletPageType } from '../../types/ILeafletPageType';
import { ILeafletType } from '../../types/ILeafletType';
import InfopostFurtherLeafletsPage from './InfopostFurtherLeafletsPage';
import LeafletExternalTracker from './LeafletExternalTracker';
import LeafletPageRegion from './LeafletPageRegion';
import * as styles from './LeafletViewerPage.scss';
import { ILeafletViewerPageDimensions } from './LeafletViewerPageWrapper';

interface ILeafletViewerPagePropsType {
	leaflet: ILeafletType;
	page: ILeafletPageType;
	pageNumber: number;
	pageDimensions: ILeafletViewerPageDimensions;
	isDoublePage: boolean;
	slideIndx: number;
	leafletViewerUiState?: ILeafletViewerStoreType;
	leafletViewerType: LeafletViewerType;
}

class LeafletViewerPage extends React.Component<ILeafletViewerPagePropsType, {}> {

	private alreadyTrackedPages: { [pageId: string]: boolean } = {};

	/**
	 * Make sure this component renders performant
	 * @param nextProps
	 */
	public shouldComponentUpdate(nextProps: ILeafletViewerPagePropsType) {
		const currentUiState = this.getSlideStateForThisPage(this.props);
		const nextUiState = this.getSlideStateForThisPage(nextProps);

		// only change if an important ui state change has happened
		if (currentUiState.isPlaceholder === nextUiState.isPlaceholder
			&& currentUiState.isCurrentSlide === nextUiState.isCurrentSlide) {
			return false;
		}

		return true;
	}

	public componentDidMount() {
		const currentUiState = this.getSlideStateForThisPage();
		const nextUiState = this.getSlideStateForThisPage(this.props);

		this.trackPageView(currentUiState, nextUiState);
	}

	public componentDidUpdate(prevProps: ILeafletViewerPagePropsType) {
		const currentUiState = this.getSlideStateForThisPage(prevProps);
		const nextUiState = this.getSlideStateForThisPage(this.props);

		this.trackPageView(currentUiState, nextUiState);
	}

	public render() {
		const { leaflet, page, pageDimensions, pageNumber, leafletViewerType } = this.props;

		if (page.title === 'LAST_PAGE') {

			if (leafletViewerType === LeafletViewerType.INFOPOST) {
				return <InfopostFurtherLeafletsPage />;
			}

			return (
				<FutherLeafletsPageContainer
					onlyNearbyLeaflets={true}
					limit={4}
					excludeSlug={leaflet.slug}
					referenceSlug={leaflet.slug}
				/>
			);
		}

		const PageState = Object.assign({}, this.getSlideStateForThisPage(this.props));

		// if is placeholder -> use only a simple version of the page to improve performance
		if (PageState.isPlaceholder) {
			return (
				<LoadingIndicator />
			);
		}

		return (
			<div className={styles.page}>
				<div
					className={`${styles.pageInner} ${(pageDimensions.orientation === 'landscape') ? styles.pageInnerLandscape : ''}`}
				>

					<ImageEnhanced
						source={page.resolutions}
						size={'lx'}
						alt={leaflet.title}
						// sourceOriginal={page.imageUrl}
						className={styles.leafletViewerCoverPageImage}
					/>

					{	// Showing regions for default leaflet view or for Premium Infopost leaflet
						leafletViewerType === LeafletViewerType.DEFAULT ||
						(leafletViewerType === LeafletViewerType.INFOPOST && isLeafletTypePremium(leaflet.rwvPublishRangeType)) ?

							page.regions.map((region: ILeafletPageRegionType, indx: number) => {
								return (
									<LeafletPageRegion
										key={`leaflet-region-${region.id}`}
										region={region}
										leaflet={leaflet}
										pageNumber={pageNumber}
										page={page}
										flashTime={(1000 + (indx * 100))}
										leafletViewerType={leafletViewerType}
									/>
								);
							}) : null
					}

					{
						PageState?.isCurrentSlide && PageState?.pagesOnSlide?.map((pageId: IdType) => (
							<LeafletExternalTracker
								key={`et-vp-${leaflet.id}-${pageId}`}
								event="VIEW_PAGE"
								leaflet={leaflet}
								pageId={pageNumber}
							/>
						))
					}
				</div>
			</div>
		);
	}

	/**
	 * Retuns the current ui state for the slide this page is on
	 *
	 * @return {ILeafletViewerUIState}
	 */
	private getSlideStateForThisPage(props: ILeafletViewerPagePropsType | null = null): ILeafletViewerUIState {
		if (!props) {
			return {} as ILeafletViewerUIState;
		}

		const slideIndx = props.slideIndx;
		const leafletViewerUiStateProp = Object.assign({}, props.leafletViewerUiState);

		const leafletViewerUiState = (leafletViewerUiStateProp) ? leafletViewerUiStateProp : { slideStates: {} };

		if (!leafletViewerUiState.slideStates || !leafletViewerUiState.slideStates[slideIndx]) {
			return { isCurrentSlide: false } as ILeafletViewerUIState;
		}

		return leafletViewerUiState.slideStates[slideIndx];
	}

	/**
	 * Tracks opening of this leaflet page
	 *
	 * @param currentUiState
	 * @param nextUiState
	 */
	private trackPageView(currentUiState: ILeafletViewerUIState, nextUiState: ILeafletViewerUIState) {
		const { leaflet, page, pageNumber, leafletViewerType } = this.props;

		// if it is last page -> don't track
		if (page.title === 'LAST_PAGE') {
			return;
		}

		// if page changes from not being current page to being current page, track leaflet opening
		if (currentUiState.isCurrentSlide === nextUiState.isCurrentSlide
			|| !nextUiState.isCurrentSlide
		) {
			return;
		}

		// Saving last opened page id to send it later for tracking
		localStorage.setItem('leafletPagesLastOpenedPage', page.id);

		if (this.alreadyTrackedPages[page.id]) {
			return;
		}

		this.alreadyTrackedPages[page.id] = true;

		InternalTrackingService.track('LEAFLET_PAGE_VIEW', {
			leafletId: leaflet.id,
			vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
			leafletPageId: page.id,
			infopost: leafletViewerType === LeafletViewerType.INFOPOST
		});

		// DataLayerService.push('LEAFLET_PAGE_VIEW', {
		// 	leafletId: leaflet.id,
		// 	leafletTitle: leaflet.title,
		// 	vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
		// 	vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
		// 	pageNumber,
		// });

		this.updatePageReadCounterTracking();
	}

	/**
	 * Updates page read counter, to make sure we can deliver the number of pages swiped, on leaflet close
	 */
	private updatePageReadCounterTracking() {
		// if not in browser -> then we can't access localstorage
		if (!isBrowser()) {
			return;
		}

		const pagesReadCounter = parseInt(localStorage.getItem('leafletPagesReadCounter'), 10) || 0;

		// save the updated count
		localStorage.setItem('leafletPagesReadCounter', (pagesReadCounter + 1) + '');
	}
}

function mapStateToProps(state: any) {
	return {
		leafletViewerUiState: state.leafletViewer,
	};
}

const withConnect = connect(
	mapStateToProps,
);

export default compose(withConnect)(LeafletViewerPage);
