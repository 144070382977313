import * as React from 'react';
import { connect } from 'react-redux';
import AccessDeniedRoute from '../../pages/AccessDeniedRoute';
import { ReactNode } from 'react';

function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
	};
}

function mapStateToProps (state: any) {
	return {
		accountReducer: state.accountReducer,
	};
}


function withPermission(
	WrappedComponent: React.ComponentType | any,
	title: string,
	content: string | ReactNode | ReactNode[],
) {
	return connect(mapStateToProps, mapDispatchToProps)(class extends React.Component<any> {
		public static getInitialProps = WrappedComponent.getInitialProps;

		public render() {
			const { accountReducer } = this.props;

			if (!accountReducer.isLoggedIn) {
				return (
					<AccessDeniedRoute
						title={title}
						content={content}
					/>
				);
			}

			return (
				<WrappedComponent {...this.props} isLoggedIn={accountReducer.isLoggedIn} />
			);
		}
	});
		
}

export default withPermission;
