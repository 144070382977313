import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsByVendorInState from '../App/modules/Promotion/components/PromotionsByVendorInState/PromotionsByVendorInState';
import {
	withTitlePostfix,
	withClickbait,
	pluralizerPromotionsAndSpecials,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class PromotionsByVendorInStateRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await PromotionsByVendorInStateRouteProps(state);

		return { reduxData };
	}

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<PromotionsByVendorInState
							vendorSlug={query.vendor}
							stateSlug={query.state}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PromotionsByVendorInStateRoute,
		(props: any) => `pvst-${props.match.params.vendor}-${props.match.params.state}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => 
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} Aktionen bei ${routeMetadata.vendor.title} in ${routeMetadata.state.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.vendor.title.toUpperCase()} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} » ${routeMetadata.state.title}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`${routeMetadata.state.title}-Aktionen & Angebote für ${routeMetadata.vendor.title.toUpperCase()} » alle Schnäppchen auf einen Blick ✓ Preise vergleichen ✓ Rabatt sichern`, `✓ Den besten Deal schnappen!`),
		} as IRouteMetadataSetterType,
	),
);
