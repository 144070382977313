import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ContentFragmentContainer from '../App/modules/ContentFragment/containers/ContentFragment/ContentFragmentContainer';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import BaseRoute from './_BaseRoute';


class ImprintRoute extends BaseRoute {
	public render () {

		return (
			<div>
				<Header />
				<MainNavigation />

				<ContentFragmentContainer
					fragmentKey="app_imprint"
					pageTitle="Impressum"
				/>

				<Footer />
			</div>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		ImprintRoute,
		`imprint`,
		{
			metaTitle: `Impressum & Kontakt | Aktionsfinder`,
			metaDescription: `Nehmen Sie Kontakt zu uns auf » via E-Mail, Telefon, Fax oder schriftlich ✓ Kontaktmöglichkeiten im Überblick ✓ Jetzt alle Informationen entdecken!`,
		} as IRouteMetadataSetterType
	)
);