const serverErrorMap = {
    201: 'Deine Anmeldung zum Gewinnspiel ist bereits bestätigt.',
    400: 'Die angegebene E-Mail Adresse ist ungültig.',
    401: 'Die angegebene E-Mail Adresse ist ungültig.',
    403: 'Diese Aktion ist leider nicht erlaubt.',
    404: 'Konnte nicht gefunden werden.',
    500: 'Leider ist ein unerwarteter Fehler aufgetreten, bitte versuche es erneut.'
};

export default serverErrorMap;
