import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsByBrandInState
	from '../App/modules/Promotion/components/PromotionsByBrandInState/PromotionsByBrandInState';
import { currentEntryWord, pluralizerPromotionsAndSpecials } from '../App/utils/routeMetadataUtils';
import { withClickbait, withTitlePostfix } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import AdItem from '../App/components/General/AdItem/AdItem';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';


class PromotionsByBrandInStateRoute extends BaseRoute {
	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<PromotionsByBrandInState
							brandSlug={query.brand + ''}
							stateSlug={query.state + ''}
							{...this.props}
						/>
						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PromotionsByBrandInStateRoute,
		(props: any) => `pbst-${props.match.params.brand}-${props.match.params.state}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => `${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} in ${routeMetadata.state.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => withTitlePostfix(`${routeMetadata.brand.title} » ${routeMetadata.state.title} Angebote & Aktionen`),
			metaDescription: (routeMetadata: IRouteMetadataType) => withClickbait(`${routeMetadata.brand.title} Angebote » ${routeMetadata.state.title}-Aktionen entdecken ✓ Preise gegenüberstellen ✓ bares Geld sparen ✓`, `Den besten Deal schnappen!`),
		} as IRouteMetadataSetterType,
	),
);
