import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsFromBrandAndVendor
	from '../App/modules/Promotion/components/PromotionsFromBrandAndVendor/PromotionsFromBrandAndVendor';
import {
	maxDiscountInTitle,
	pluralizerPromotionsAndSpecials,
	withTitlePostfix,
	withClickbait,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';


class PromotionsFromBrandAndVendorRoute extends BaseRoute {
	public static async getInitialProps(state: any) {
		const reduxData = await PromotionsFromBrandAndVendorRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<PromotionsFromBrandAndVendor 
							brandSlug={query.brand}
							vendorSlug={query.vendor}
							{...this.props}
						/>
						{/* {withVendor(
							PromotionsFromBrandAndVendor,
							query.vendor,
							{
								...this.props,
								brandSlug: query.brand,
								vendorSlug: query.vendor,
							}
						)} */}
						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PromotionsFromBrandAndVendorRoute,
		(props: any) => `pbv-${props.match.params.brand}-${props.match.params.vendor}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} bei ${routeMetadata.vendor.title} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} bei ${routeMetadata.vendor.title.toUpperCase()} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle aktuellen ${routeMetadata.brand.title} Aktionen & Angebote entdecken » bei Aktionsfinder informieren ✓ kein Schnäppchen verpassen`, `✓ Fündig werden!`),
		} as IRouteMetadataSetterType,
	),
);


