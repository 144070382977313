import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import ThemeworldListing from '../../components/ThemeworldListing/ThemeworldListing';
import DataStateService from '../../../../services/DataStateService';

interface IThemeworldListingProps {
	queryKey?: string;
	orderBy?: 'relevance' | 'createdAt' | 'published';
	orderDir?: 'asc' | 'desc';
}

class ThemeworldListingContainerInner extends React.Component<IThemeworldListingProps & IWithDataProps> {
	public render() {
		const { data } = this.props;

		return (
			<ThemeworldListing
				themeworlds={data as IThemeworldType[]}
			/>
		);
	}
}

export default function ThemeworldListingContainer(theProps: IThemeworldListingProps) {
	const props = Object.assign({
		orderBy: 'rank',
		orderDir: 'desc',
	}, theProps);

	const RenderComponent = withData(
		ThemeworldListingContainerInner,
		'themeworldReducer',
		`themeworld-listing-page-${DataStateService.getStateKey()}`,
		{
			sort: `${props.orderBy},${props.orderDir}`,
		},
		{
			primaryKeyName: 'themeworldSlug',
			LoadingComponent: () => <ThemeworldListing />
		}
	);

	return <RenderComponent {...props} />
}

