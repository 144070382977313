import * as React from 'react';
import * as styles from './PromotionworldDetail.scss';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import { IPromotionworldType } from '../../types/IPromotionworldType';
import Icon from '../../../Base/components/Icon/Icon';
import { ILeafletType } from '../../../Leaflet/types/ILeafletType';
import Link from '../../../Base/components/Link/Link';

interface IPromotionworldDetailProps {
	data: ILeafletType[];
	promotionworld: IPromotionworldType;
}

class PromotionworldDetailFurtherLeaflets extends React.Component<IPromotionworldDetailProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	constructor(props: IPromotionworldDetailProps) {
		super(props);
	}

	// TRANSLATE
	public render() {
		const { promotionworld, data } = this.props;

		const currentItemIdx = data.findIndex(item => item.slug === promotionworld.leaflet.slug);
		
		if (currentItemIdx === -1) {
			return null;
		}

		return (
			<React.Fragment>
				{(currentItemIdx === 0) ? null :
					<Link
						route="LeafletDetailRoute"
						params={{
							leaflet: data[currentItemIdx - 1].slug
						}}
					>
						<a type="button" className={styles.NavArrowsPrev}>
							<Icon icon="arrow-left" />
						</a>
					</Link>
				}

				{!data[currentItemIdx + 1] ? null :
					<Link
						route="LeafletDetailRoute"
						params={{
							leaflet: data[currentItemIdx + 1].slug
						}}
					>
						<a type="button" className={styles.NavArrowsNext}>
							<Icon icon="arrow-right" />
						</a>
					</Link>
				}
			</React.Fragment>
		)
	}
}

export default PromotionworldDetailFurtherLeaflets;
