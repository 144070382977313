import * as React from 'react';
import { ILeafletGroupedType } from '../../types/ILeafletGroupedType';
import LeafletItem from '../LeafletItem/LeafletItem';
import * as styles from './LeafletGridGroup.scss';

interface ILeafletGridGroupedProps {
  leaflets: ILeafletType[],
  leafletGroup: ILeafletGroupedType,
  size?: 's' | 'm' | 'l',
  gridKey?: string,
  moreLabel?: string,
  columns?: '1' | '2' | '3',
}

class LeafletGridGroup extends React.Component<ILeafletGridGroupedProps, {}> {
  public static defaultProps = {
    columns: 3,
    gridKey: 'default',
    size: 'm',
  };

  public render () {
    const { leaflets, size, gridKey,columns } = this.props;

    return (
      <div className={`${styles.GroupWrapper} ${styles[ 'GroupColumns' + columns ]}`}>
	      <div className={`${styles.Group}`}>
	        {leaflets.map((leaflet) => (
	          <div className={styles.GroupItem} key={`leaflet-grid-item-${gridKey}-${leaflet.slug}`}>
	            <div className={styles.GroupItemInner}>
	              <LeafletItem
	                leaflet={leaflet}
	                size={size}
	              />
	             </div>
	          </div>
	          )
	        )}
	        </div>
        </div>
    );
  }
}

export default LeafletGridGroup;
