import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addLeafletsAggregatedByProductGroupsWidgetEntries,
	LEAFLETS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_FETCH_ENTRIES, setLeafletsAggregatedByProductGroupsWidgetErrors,
} from './leafletsAggregatedByProductGroupsWidgetActions';
import GeolocationService from '../../../Base/services/GeolocationService';

/**
 * Fetch entry list
 */
function* fetchLeafletsAggregatedByProductGroupsWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	// add geolocation params
	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

	try {
		let response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: '/api/public/v1/widgets/product-groups-for-leaflets/nearby',
					params,
				} as IRequestObject
			);
		});


		// If no data child kategories => delete parent group prop => get all kategories
		if (response.getContent('content').length === 0) {
			delete params.parent_product_group;

			response = yield call((): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest(
					{
						url: '/api/public/v1/widgets/product-groups-for-leaflets/nearby',
						params,
					} as IRequestObject
				);
			});
		}

		// If no data child kategories => change title
		if (action.params.parent_product_group) {
			response.getMetaData().title = 'Andere Produktkategorien';
		}

		yield put(addLeafletsAggregatedByProductGroupsWidgetEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));

	} catch (e) {
		yield put(setLeafletsAggregatedByProductGroupsWidgetErrors(action.queryKey, e));
	}
}


export function* leafletsAggregatedByProductGroupsWidgetSaga() {
	yield takeEvery(LEAFLETS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_FETCH_ENTRIES, fetchLeafletsAggregatedByProductGroupsWidget);
}
