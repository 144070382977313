import * as React from 'react';
import appConfig from '../../../config/appConfig';
import { IBreadcrumbItemType } from '../../../types/IBreadcrumbItemType';
import BreadcrumbItem from './BreadcrumbItem';
import * as styles from './Breadcrumbs.scss';

interface IBreadcrumbsProps {
	items?: IBreadcrumbItemType[],
}

class Breadcrumbs extends React.PureComponent<IBreadcrumbsProps, {}> {

	public static getDerivedStateFromProps(nextProps: IBreadcrumbsProps) {

		if (nextProps.items) {
			return {
				allBreadcrumbs: [{
					href: `https://${appConfig.mainDomain}`,
					label: 'Home',
				} as IBreadcrumbItemType, ...nextProps.items]
			}
		} else {
			return null
		}


	}

	constructor(props) {
		super(props);
		this.state = {
			allBreadcrumbs: []
		}
	}


	public componentDidMount(): void {
		if (this.props.items) {
			this.setState({
				allBreadcrumbs: [{
					href: `https://${appConfig.mainDomain}`,
					label: 'Home',
				} as IBreadcrumbItemType, ...this.props.items]
			})
		}
	}


	public render() {
		const { allBreadcrumbs } = this.state;

		if (!allBreadcrumbs || allBreadcrumbs.length < 1) {
			return null;
		}

		return (
			<ol className={`${styles.BreadcrumbWrapper} breadcrumb-wrapper`} vocab="http://schema.org/" typeof="BreadcrumbList">
				{
					allBreadcrumbs.map((breadcrumbItem: IBreadcrumbItemType, position: number) => {
						return (
							<BreadcrumbItem
								key={`breadcrumb-${breadcrumbItem.label}-${position}`}
								item={breadcrumbItem}
								position={(position + 1)}
							/>
						);
					})
				}
			</ol>
		);
	}

	/*private getCorporateBreadcrumbs() {
    const { allBreadcrumbs } = this.state;

		const items = [];
		for (const breadcrumb of allBreadcrumbs) {
			items.push(
        {
          "@type": "ListItem",
          "position": 1,
          "item":
            {
              "@id": "https://example.com/dresses",
              "name": this.trimText(item.label),
            }
        }
			);
		}

		return {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": items,
    };
	}*/
}

export default Breadcrumbs;
