import * as React from 'react';
import Link from '../../../Base/components/Link/Link';
import { ILeafletGroupedType } from '../../types/ILeafletGroupedType';
import LeafletGroup from './LeafletGridGroup';
import * as styles from './LeafletGridGrouped.scss';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { SlugType } from '../../../Base/types/SlugType';
import CurrentVendorLeaflet from '../CurrentVendorLeaflet/CurrentVendorLeaflet';
import { ILeafletType } from '../../types/ILeafletType';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { DateTime } from 'luxon';

export interface ILeafletGridGroupedProps {
	leafletGroups: ILeafletGroupedType[];
	size?: 's' | 'm' | 'l';
	gridKey?: string;
	columns?: '1' | '2' | '3';

	groupTitle?: string;
	groupTitlePlural?: string;

	moreLabel?: string;
	moreRouteCallbk?: (leafletGroup: ILeafletGroupedType) => { name: string; params: { [key: string]: any } };
	vendorSlug?: SlugType;
	vendorName?: SlugType;
	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
	children?: React.ReactNode; // if this is given, this will be shown if the array doesn't return any results
	metadata?: IQueryMetaDataType;
	withCurrentLeafletVendor?: boolean;
	leafletDescription?: string;
}

class LeafletGridGrouped extends React.Component<ILeafletGridGroupedProps, {}> {
	public static defaultProps = {
		columns: 2,
		gridKey: 'default',
		size: 'm',
		contentBreaks: {}
	};

	// TRANSLATE
	public render() {
		const {
			leafletGroups,
			size,
			gridKey,
			moreLabel,
			moreRouteCallbk,
			columns,
			contentBreaks,
			groupTitle,
			children,
			metadata,
			vendorSlug,
			vendorName,
			withCurrentLeafletVendor,
			leafletDescription,
		} = this.props;

		if ((!leafletGroups || leafletGroups.length < 1) && metadata.totalItemsCount === 0) {
			if (children) {
				return <div>{children}</div>;
			} else {
				return null;
			}
		}

		return (
			<div>
				{leafletGroups.map((leafletGroup, indx) => {
					if (leafletGroup.leaflets.length === 0) {
						return null;
					}

					let route = {
						name: '',
						params: {}
					};

					const normalizedGroup = this.normalizeLeafletGroup(leafletGroup);
					const slug = leafletGroup.slug;

					if (moreRouteCallbk) {
						route = moreRouteCallbk(normalizedGroup);
					}

					let currentVendorComponent = null;

					if (withCurrentLeafletVendor && indx === 0) {
						const leaflet = leafletGroup.leaflets[0];
						const expired = DateTime.local().startOf('day') > DateTime.fromISO(leaflet.validTo);

						currentVendorComponent = (
							<div className={styles.currentVendorLeafletContainer}>
								<CurrentVendorLeaflet
									leaflet={leaflet as ILeafletType}
									title={(!expired) ? `Der aktuelle ${vendorName.toUpperCase()} Prospekt` : `Derzeit leider kein aktueller ${vendorName.toUpperCase()} Prospekt`}
									centered={false}
								>
									<div className={`${styles.actionWrapper}`}>
				
										<WidgetListItemLinkRow
											href={`/pv/${vendorSlug}`}
											title={`Aktuelle ${vendorName.toUpperCase()} Aktionen`}
										/>

										<WidgetListItemLinkRow
											href={`/v/${vendorSlug}`}
											title={`${vendorName.toUpperCase()} Filialen & Öffnungszeiten`}
										/>
										
										{(!leafletDescription) ? null :
											<div className={styles.descriptionWrapper}>
												<div dangerouslySetInnerHTML={{ __html: leafletDescription }} />
											</div>
										}
									</div> 
								</CurrentVendorLeaflet>
							</div>
						);
					}

					return (
						<React.Fragment key={`leaflet-grid-group-${gridKey}-${slug}`}>
							{currentVendorComponent}
							{!contentBreaks || !contentBreaks[indx] ? null : <React.Fragment>{contentBreaks[indx]}</React.Fragment>}
							<div className={styles.GridGroupWrapper}>
								{!groupTitle ? null : (
									<h2 className={styles.GroupGroupedTitle}>{this.getGroupTitle(normalizedGroup)}</h2>
								)}

								<LeafletGroup
									leafletGroup={leafletGroup}
									leaflets={leafletGroup.leaflets}
									size={size}
									columns={columns}
									gridKey={`${gridKey}-${slug}`}
								/>

								{!moreLabel ? null : (
									<div className={`${styles.GridGroupedMoreItem}`}>
										<Link route={route.name} params={route.params}>
											<a className={styles.GridGroupedMore}>{this.getMoreLabel(normalizedGroup)}</a>
										</Link>
									</div>
								)}
							</div>
						</React.Fragment>
					);
				})}
			</div>
		);
	}

	/**
	 * Normalizes important information that could be nested in differnt formats within the group,
	 * but should always be in the same format
	 *
	 * @param leafletGroup
	 */
	private normalizeLeafletGroup(leafletGroup: ILeafletGroupedType): ILeafletGroupedType {
		if (leafletGroup.vendor) {
			leafletGroup.title = leafletGroup.vendor.name;
			leafletGroup.slug = leafletGroup.vendor.slug;
		} else if (leafletGroup.federalState) {
			leafletGroup.title = leafletGroup.federalState.name;
			leafletGroup.slug = leafletGroup.federalState.slug;
		}

		return leafletGroup;
	}

	/**
	 * Returns the more label with replaced placeholders
	 *
	 * @param leafletGroup
	 */
	private getMoreLabel(leafletGroup: ILeafletGroupedType): string {
		const { moreLabel } = this.props;

		if (!moreLabel) {
			return '';
		}

		let ret = moreLabel;

		// replace a few things
		ret = ret.replace('%title%', leafletGroup.title);

		return ret;
	}

	/**
	 * Returns the group title with replaced placeholders
	 *
	 * @param leafletGroup
	 */
	private getGroupTitle(leafletGroup: ILeafletGroupedType): string {
		const { groupTitle } = this.props;

		if (!groupTitle) {
			return '';
		}

		let ret = groupTitle;

		// replace a few things
		ret = ret.replace('%title%', leafletGroup.title);

		return ret;
	}
}

export default LeafletGridGrouped;
