import ReduxService from '../../boilerplate/redux/services/ReduxService';
import { setRouteMetadata } from '../modules/Base/store/RouteMetadata/routeMetadataActions';
import { IRouteMetadataType } from '../modules/Base/types/IRouteMetadataType';

const RouteMetadataService = {
	/**
	 * Sets new metadata on a global level
	 *
	 * @param queryKey
	 * @param metaData
	 */
	setMetadata (queryKey: string, metaData: IRouteMetadataType) {
		ReduxService.dispatch(setRouteMetadata(queryKey, metaData));
	},
};

export default RouteMetadataService;
