import * as React from 'react';
import { ComponentType } from 'react';
import withData from '../../../../boilerplate/redux/hocs/withData';
import { SlugType } from '../../Base/types/SlugType';

export default function withTag(WrapperComponent: ComponentType<any>, tagSlug: SlugType, props: any) {
	
    const queryKey = 'priceHitsKey';

    const searchRequest = {
        body: {
            filterTags: ['Preishits']
        }
    }

    const RenderComponent = withData(
		WrapperComponent,
		'mixedEntityReducer',
        queryKey,
        {
            size: props.limit,
            page: props.page,

            searchRequest,
        },
        {
            showLoading: false,
            additionalStateProperties: ['searchMetaData', 'searchResults'],
        },
	);

	return (
		<RenderComponent {...props} />
	);
}
