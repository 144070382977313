import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import ProductGroupDetailPageContainer
  from '../App/modules/Category/containers/ProductGroupDetailPage/ProductGroupDetailPageContainer';
import {
  maxDiscountInTitle,
  pluralizerPromotionsAndSpecials,
  withClickbait,
  withTitlePostfix
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import PageWrapper from '../App/components/Layout/PageWrapper/PageWrapper';
import { IBreadcrumbItemType } from '../App/types/IBreadcrumbItemType';

class ProductGroupDetailDestRoute extends BaseRoute {
  public render () {
    const query = ( this.props.match.params ) ? this.props.match.params : {};

    return (
      <div>
        <Header />
        <MainNavigation />


        <PageWrapper>
            <ProductGroupDetailPageContainer
              queryKey={`productGroupDetail${query.productGroup}`}
              productGroup={query.productGroup + ''}
              forceDestinationPage={true}
              {...this.props}
            />
        </PageWrapper>

        <Footer />
      </div>
    );
  }
}

export default ProductGroupDetailDestRoute;