import * as React from 'react';
import * as styles from './AdItem.scss';
import { isServer } from '../../../../boilerplate/razzle/razzleUtils';
import appConfig from '../../../config/appConfig';
import CookieTrustService from '../../../services/CookieTrustService';
import { connect } from 'react-redux';

declare const adition: any;

interface IAdItemProps {
  desktopAdId?: number;
  mobileAdId?: number;
  maxWidth?: number;
  maxHeight?: number;
  type: 'side' | 'content' | 'billboard' | 'top' | 'none';
  title?: string;
  tcString?: string;
  className?: string;
}

interface IAdItemStates {
  loaded: boolean;
  reduced: boolean;
}

class AdItem extends React.Component<IAdItemProps, IAdItemStates> {

  public static defaultProps = {
    type: 'content'
  };

  private winWidth?: number;
  private winHeight?: number;

  private adItemElement?: HTMLDivElement;

  private loadedTimeout: any;

  public componentDidMount () {

    if (isServer()) {
      return;
    }

    if (!this.isAdVisible()) {
      return;
    }

    this.initAdItem(this.props);
  }

  componentDidUpdate(prevProps: IAdItemProps) {
    if (prevProps.tcString === this.props.tcString) {
      return;
    }

    this.initAdItem(this.props);
  }

  private initAdItem(props: IAdItemProps) {
    const { tcString, desktopAdId, mobileAdId } = props;
    
    // if cookie compliance is false -> don't render anything
    if (!CookieTrustService.isActive('C0004')) {
      return;
    }
    
    if (!tcString) {
      return;
    }
    
    this.loadedTimeout = setTimeout(() => {
      let adId: number = 0;

      if (desktopAdId && this.winWidth && this.winWidth >= 890) {
        adId = desktopAdId;
      } else if (mobileAdId && this.winWidth && this.winWidth < 890) {
        adId = mobileAdId;
      }

      if (!adId || typeof adition === 'undefined') {
        return;
      }

      adition?.srq?.push((api: any) => {
        if (document.getElementById(`renderSlot_${adId}`)) {
          api.renderSlot(`renderSlot_${adId}`);
        } else if (appConfig.isDev) {
          console.warn(`AD RENDER SLOT renderSlot_${adId} not found`, this.props);
        }
      });
    }, 4000);
  }

  public componentWillUnmount(): void {
    if (this.loadedTimeout) {
      clearTimeout(this.loadedTimeout);
    }
  }

  public render () {
    const { desktopAdId, type, mobileAdId, className, tcString, } = this.props;

    if (isServer()) {
      return null;
    }

    if (!this.winWidth) {
      this.winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    if (!this.winHeight) {
      this.winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }


    const showAd = this.isAdVisible();
    if (!showAd) {
      return null;
    }
    
    if (!tcString) {
      return null;
    }

    return (
      <div
        className={`${styles.adItemBlock} ${styles[ type + 'AdItem' ]} ${type}AdItem ${className}`}
      >
        {( !desktopAdId || this.winWidth < 890 ) ? null :
          <div id={`renderSlot_${desktopAdId}`} className={styles.desktopAd}></div>
        }

        {( !mobileAdId || this.winWidth >= 890 ) ? null :
          <div id={`renderSlot_${mobileAdId}`} className={styles.mobileAd}></div>
        }
      </div>
    );
  }

  /**
   * Checks whether this ad should be visible or not
   */
  private isAdVisible() {
    if (isServer()) {
      return false;
    }

    if (this.props.type === 'side' && this.winHeight < 663) {
      return false;
    }

    return true;
  }
}

function mapStateToProps(state: any) {
  return {
      tcString: state.generalReducer?.tcString,
  };
}

export default connect(mapStateToProps)(AdItem);
