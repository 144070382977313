import * as React from 'react';
import * as style from './BurgerMenu.scss';
import Menu from '../Menu/Menu';
import { isServer } from '../../../../boilerplate/razzle/razzleUtils';

interface IBurgerMenuProps {
}

interface IBurgerMenuState {
  toggled?: boolean,
  showMenuModal?: boolean,
}

class BurgerMenu extends React.Component<IBurgerMenuProps, IBurgerMenuState> {
  public static defaultProps = {
    toggled: false,
    showMenuModal: false
  };

  constructor(props: IBurgerMenuProps){
    super(props);
    this.state = {
      toggled: false,
      showMenuModal: false
    };
  }

  componentWillUnmount() {
    if (!isServer() && document) {
      document.getElementById('root').style.position = "static";
    }
  }

  public render () {
    const { toggled, showMenuModal } = this.state;

    return (

      <React.Fragment>
        <button
					aria-label="Öffne das Menu"
          type="button"
          className={`${style.burgerMenuWrapper} ${(toggled) ? style.isActive : null}`}
          onClick={this.toggleButton}
        >
          <span className={style.burgerMenu} />
        </button>


        { (!showMenuModal) ? null:
          <Menu
            onCloseMenu={this.toggleButton}
          />
        }

      </React.Fragment>
    );
  }

  /**
   * Toggles the button
   */
  private toggleButton = () => {
    if (!isServer() && document && !this.state.toggled) {
      document.getElementById('root').style.position = "fixed";
    } else {
      document.getElementById('root').style.position = "static";
    }
    
    this.setState({
      toggled: !this.state.toggled,
      showMenuModal: !this.state.showMenuModal,
    });

  }
}


export default BurgerMenu;