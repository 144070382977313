import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import ThemeworldListWidget from '../../components/ThemeworldListWidget/ThemeworldListWidget';
import { IThemeworldWidgetType } from '../../types/IThemeworldWidget';

interface IThemeworldListWidgetContainerProps {
	title: string;
	widgetKey: string;
	limit?: number;
	style?: IWidgetStyleType;
	orderBy?: 'rank' | 'createdAt' | 'title';
	orderDir?: 'asc' | 'desc';

	city?: SlugType; // the city in which we are looking
	vendorSlug?: SlugType; // ID of vendor
	shopSlug?: SlugType; // ID of store
	productGroupSlug?: SlugType; // beloging to product group
	brandSlug?: SlugType[]; // ID of brand
	excludeSlug?: SlugType;

	routeCallbk: (item: IThemeworldWidgetType) => { name: string, params: {[key: string]: any} },

	btnLabel?: string;
	btnRoute?: string;
	btnParams?: { [ key: string ]: any };
}

class ThemeworldListWidgetContainerInner extends React.Component<IThemeworldListWidgetContainerProps & IWithDataProps> {
	public render () {
		const { title, data, style, widgetKey, routeCallbk, btnLabel, btnRoute, btnParams } = this.props;

		if (this.getThemeworldRespectingExclude(data).length < 1) {
			return null;
		}

		return (
			<ThemeworldListWidget
				title={title}
				themeworlds={this.getThemeworldRespectingExclude(data)}
				style={style}
				widgetKey={widgetKey}
				routeCallbk={routeCallbk}
				btnLabel={btnLabel}
				btnParams={btnParams}
				btnRoute={btnRoute}
			/>
		);
	}

	/**
	 * Makes sure to only return entries, we haven't excluded
	 *
	 * @param data
	 */
	private getThemeworldRespectingExclude (data: IThemeworldWidgetType[]): IThemeworldWidgetType[] {
		const { excludeSlug } = this.props;

		if (!excludeSlug) {
			return data;
		}

		return data.filter((themeworld: IThemeworldWidgetType) => {
			return themeworld.slug !== excludeSlug;
		});
	}
}

export default function ThemeworldListWidgetContainer (props: IThemeworldListWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;
	const orderBy = ( props.orderBy ) ? props.orderBy : 'rank';
	const orderDir = ( props.orderDir ) ? props.orderDir : 'desc';

	const RenderComponent = withData(
		ThemeworldListWidgetContainerInner,
		'themeworldWidgetReducer',
		`themeworld-cloud-widget-${props.widgetKey}`,
		{
			size: (props.excludeSlug) ? (limit + 1) : limit,
			store_vendor: props.vendorSlug,
			store: props.shopSlug,
			product_group: props.productGroupSlug,
			brand: props.brandSlug,
			sort: `${orderBy},${orderDir}`,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={limit}
					widgetKey={props.widgetKey}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={limit}
					widgetKey={props.widgetKey}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
