import * as React from 'react';
import { withRouter } from 'react-router';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import BaseRoute from './_BaseRoute';
import Redirect from '../App/modules/Base/components/Link/Redirect';


class PrivacyInfoRouteOld extends BaseRoute {
	public render () {
		return (
			<Redirect route="PrivacyInfoRoute" />
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PrivacyInfoRouteOld,
		`privacyInfo`,
		{
			metaTitle: `Datenschutz | Aktionsfinder`,
			metaDescription: `Erfahren Sie alles über die Datenschutzbestimmungen der Aktionsfinder GmbH » Hier klicken und über alle Einzelheiten informieren.`,
		} as IRouteMetadataSetterType
	)
);
