/* ### CONSTANTS ### */

import { IQueryMetaDataType } from "../../../../../boilerplate/redux/types/IQueryMetaDataType";
import { IApiErrorType } from "../../../../types/BaseTypes";

export const ACCOUNT__FETCH_ENTRIES = '@BasePipeline/accountReducer_FETCH_ENTRIES';
export const ACCOUNT__ADD_ENTRIES = '@BasePipeline/accountReducer_ADD_ENTRIES';
export const ACCOUNT__ADD_ENTRY = '@BasePipeline/accountReducer_ADD_ENTRY';

export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';

export const ACCOUNT__SET_ERRORS   = '@BasePipeline/accountReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchAccount (queryKey: string) {
	return {
		type: ACCOUNT__FETCH_ENTRIES,
		queryKey,
	};
}

export function addAccountEntries (queryKey: string, entries: [], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: ACCOUNT__ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addAccountEntry (queryKey: string, entry: {}, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: ACCOUNT__ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setAccountErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: ACCOUNT__SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}

export function accountLogout () {
	return {
		type: ACCOUNT_LOGOUT,
	};
}

export function setAccountData (account: IAccountLoginType) {
	return {
		type: SET_ACCOUNT_DATA,
		account,
	};
}
