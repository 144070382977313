import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import GenericErrorMap from '../../../../components/Layout/GenericErrorMap/GenericErrorMap';
import serverErrorMap from '../../components/ConfirmLotteryRegistration/serverErrorMap';
import LotteryInfo from '../../components/LotteryInfo/LotteryInfo';
import LotteryInfoSkeleton from '../../components/LotteryInfo/LotteryInfoSkeleton';

interface ILotteryInfoContainerProps {
	limit?: number,
}

class LotteryInfoContainerInner extends React.Component<ILotteryInfoContainerProps & IWithDataProps> {

	public render () {

		const { data } = this.props;

		return (
			<LotteryInfo
				winnersHistory={data.winnersHistory as IWinnerType[]}
				nextLottery={data.nextLottery as IWinnerType}
			/>
		)
	}
}

export default function LotteryInfoContainer (theProps: ILotteryInfoContainerProps) {
	const props = Object.assign({
		size: theProps.limit || 6
	}, theProps);

	const RenderComponent = withData(
		LotteryInfoContainerInner,
		'lotteryInfoReducer',
		`lotteryInfoContainer`,
		{

        },
        {
	        ErrorComponent: (errorProps: any) => (
		        <GenericErrorMap error={errorProps} serverErrorMap={serverErrorMap}/>
	        ),
	        LoadingComponent: (errorProps: any) => (
		        <LotteryInfoSkeleton />
	        ),
        }
        
	);

	return <RenderComponent {...props} />;
}


