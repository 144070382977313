import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import {
	pluralizerPromotionsAndSpecials,
	withTitlePostfix,
	withClickbait,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import PromotionsInShopByBrand from '../App/modules/Promotion/components/PromotionsInShopByBrand/PromotionsInShopByBrand';
import withShop from '../App/modules/Vendor/hocs/withShop';
import BaseRoute from './_BaseRoute';


class PromotionsInShopByBrandRoute extends BaseRoute {

	public static async getInitialProps(state: any) {
		const reduxData = await PromotionsInShopByBrandRouteProps(state);

		return { reduxData };
	}


	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<div>
				<Header />
				<MainNavigation />

				<PromotionsInShopByBrand
					brandSlug={`${query.brand}`}
					shopSlug={`${query.shop}`}
					{...this.props}
				/>

				<Footer />
			</div>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PromotionsInShopByBrandRoute,
		(props: any) => `pbs-${props.match.params.brand}-${props.match.params.shop}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => {
                return `${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} bei ${routeMetadata.shop.title}`
            },
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.brand.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} bei ${routeMetadata.shop.title}`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Jetzt bei ${routeMetadata.shop.title} in Aktion: ${routeMetadata.brand.title} » Schnäppchen entdecken ✓ Rabatt sichern ✓ Geld sparen`, `✓ Jetzt mehr erfahren!`),
		} as IRouteMetadataSetterType,
	),
);
