import * as React from 'react';
import * as styles from './ShopListWidgetLeaflet.scss';
import { IShopWidgetType } from '../../types/IShopWidgetType';
import Distance from '../Distance/Distance';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Icon from '../../../Base/components/Icon/Icon';
import Link from '../../../Base/components/Link/Link';
import Scroller from '../../../../components/Layout/Scroller/Scroller';

interface IShopListWidgetLeafletProps {
	shops: IShopWidgetType[],
	widgetKey?: string,
}

class ShopListWidgetLeaflet extends React.Component<IShopListWidgetLeafletProps, {}> {
	public static defaultProps = {
		widgetKey: 'shop-list-widget',
	};

	public render () {
		const { shops, widgetKey } = this.props;

		return (
			<div className={styles.shopListWidgetContainer}>
				<Scroller height={140}>

					{ shops.map((shopWidgetItem: IShopWidgetType) => {
							const theShop: any = (shopWidgetItem.group) ? shopWidgetItem.group : shopWidgetItem;

							return ( 
								<div 
									className={styles.shopContainer}
									key={`${widgetKey}-${theShop.slug}`}
								>
									<Link 
										route="ShopDetailRoute"
										params={{shop: theShop.slug}}
									>
										<a>
											<span className={styles.shopWrapper}>
												{( !theShop.vendor.resolutions ) ? null :
													<ImageEnhanced
														source={theShop.vendor.resolutions}
														className={styles.shopContainerVendorItemImage}
														alt={theShop.vendor.name}
														size={'s'}
														bgImage={true}
													/> 
												}
												
												<span className={styles.shopInfoWrapper}>

													<span className={styles.shopTitle}>{theShop.title}</span>

													<span className={styles.shopAdditionalInfo}>
														{theShop.address}, {theShop.zipCode} {theShop.federalState && theShop.federalState.name}
													</span>

													<span className={styles.shopAdditionalInfo}>
														Details und Öffnungszeiten
														<Icon icon="arrow-right" />
													</span>
												</span>

												<span className={styles.shopDistanceWrapper}>
													<Distance distanceInMeters={theShop.distance} />
												</span>

											</span>
										</a>
									</Link>
								</div>
							);
						})
					}
				</Scroller>
			</div>
		);
	}
}

export default ShopListWidgetLeaflet;
