/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const BRAND_FETCH_ENTRIES = '@BasePipeline/brandReducer_FETCH_ENTRIES';
export const BRAND_FETCH_ENTRY   = '@BasePipeline/brandReducer_FETCH_ENTRY';
export const BRAND_ADD_ENTRIES = '@BasePipeline/brandReducer_ADD_ENTRIES';
export const BRAND_ADD_ENTRY = '@BasePipeline/brandReducer_ADD_ENTRY';

export const BRAND_SET_ERRORS   = '@BasePipeline/brandReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchBrands (queryKey: string) {
	return {
		type: BRAND_FETCH_ENTRIES,
		queryKey,
	};
}

export function addBrandEntries (queryKey: string, entries: IBrandType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addBrandEntry (queryKey: string, entry: IBrandType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setBrandErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: BRAND_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
