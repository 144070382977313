import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import PromotionsAggregatedByProductGroupsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import LeafletGridGroupedContainer from '../../containers/LeafletGridGrouped/LeafletGridGroupedContainer';
import { ILeafletGroupedType } from '../../types/ILeafletGroupedType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface ILeafletsInStateProps {
	stateSlug: SlugType;
}

class LeafletsInState extends React.Component<ILeafletsInStateProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, stateSlug } = this.props;

		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;

		if (!routeMetadata) {
			return
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403489}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<LeafletGridGroupedContainer
								limit={6}
								enablePagination={true}
								stateSlug={stateSlug}
								onlyNearbyLeaflets={false}
								moreLabel={`Alle %title% Prospekte in ${stateName} anzeigen`}
								moreRouteCallbk={this.routerCallbkPromotionListWidget}
								gridKey={routeMetadata.queryKey}
								groupTitle={`%title% Prospekte in ${stateName}`}
								groupTitlePlural={`%title% Prospekte in ${stateName}`}
								pushRouteMetadata={true}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403478}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
	
									3: (
										<AdItem
											mobileAdId={4403477}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							/>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey={`lst-${routeMetadata.queryKey}-productGroups-widget`}
								limit={10}
								title={`Beliebte Produktkategorien in ${stateName}`}
								stateSlug={stateSlug}
								routeCallbk={this.routerCallbkProductGroupListWidget}
							/>

							<AdItem
								desktopAdId={4403488}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>
						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Prospekte',
				route: 'LeafletListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.state.title : '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: ILeafletGroupedType) => {
		const { stateSlug } = this.props;

		return {
			name: 'LeafletsByVendorInStateRoute',
			params: {
				state: stateSlug,
				vendor: item.slug,
			},
		};
	};

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		const { stateSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndStateRoute',
			params: {
				state: stateSlug,
				productGroup: item.group.slug,
			},
		};
	};
}

export default LeafletsInState;
