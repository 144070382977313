import * as React from 'react';
import * as styles from './ShopList.scss';
import { IShopType } from '../../types/IShopType';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import ShopListItem from '../ShopListItem/ShopListItem';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import ShopListSkeleton from './ShopListSkeleton';
import ShopListItemLarge from '../ShopListItemLarge/ShopListItemLarge';
import Distance from '../Distance/Distance';
import Icon from '../../../../modules/Base/components/Icon/Icon';
import Link from '../../../Base/components/Link/Link';
import { DateTime } from 'luxon';

export interface IShopListProps {
	shops: IShopType[];
	gridKey?: string;
	metadata?: IQueryMetaDataType;
	title?: string;
	enablePagination?: boolean;
	boxed?: boolean;
	fetchMore?: (queryKey: string) => void;
	queryKey?: string;
	routeCallbk?: (shopGroup: IShopType) => { name: string; params: { [key: string]: any } };
	design?: 'small' | 'large';

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class ShopList extends React.PureComponent<IShopListProps, {}> {
	public static defaultProps = {
		gridKey: 'shop-list',
		boxed: false
	};

	// TRANSLATE
	public render() {
		const { shops, design, title, gridKey, boxed, fetchMore, metadata, queryKey, enablePagination, contentBreaks } = this.props;

		let shownBreakingContent = false;

		/**
		 * Shows breaking content
		 * Function made for te cases when items length is less than position of breaking content
		 */
		const showBreakingContent = (indx: number) => {

			if (contentBreaks && contentBreaks[indx]) {
				return (
					<React.Fragment>
						{contentBreaks[indx]}
					</React.Fragment>
				);
			}
		
			if (shownBreakingContent || !contentBreaks || (metadata.done && metadata.count !== metadata.totalItemsCount) || shops[indx + 1]) {
				return null;
			}

			shownBreakingContent = true;
			return Object.keys(contentBreaks).map(index => (
				<React.Fragment key={`shoplist-break-${index}`}>
						{contentBreaks[index]}
					</React.Fragment>
				)
			);
			
		}

		return (
			<AppMainColBox boxed={boxed} nested={true}>
				<div>
					{!title ? null : <h3>{title}</h3>}

					<Pagination
						LoaderComponent={<ShopListSkeleton limit={6} />}
						fetchMore={fetchMore}
						metadata={metadata}
						queryKey={queryKey}
						enablePagination={enablePagination}
					>
						{shops.map((shop: IShopType, indx: number) => {
							
							let isOpenIconVisible = false;
							const todayOpenningHours = this.getOpenningHours(shop);

							if (todayOpenningHours) {
								todayOpenningHours.forEach((period: any) => {
									if (period.open && period.close) {
										const timeNow = `${DateTime.local().toFormat('HH')}${DateTime.local().toFormat('mm')}`;
										if (timeNow >= period.open.time && timeNow < period.close.time) {
											isOpenIconVisible = true;
										}
									}
								});
							}
							
							return (
								<React.Fragment key={`shops-grid-list-${gridKey}-${shop.slug}`}>
									<div className={styles.shopListWrapper}>
										{design === 'large' ? 
											<div className={styles.shopDetailsContainer}>
												<div className={styles.shopInfoWrapper}>
													<Link href={`/s/${shop.slug}/`}>
														<a className={styles.shopDetailsInfoWrapper}>
															<span className={styles.shopDetailsWrapper}>
																<span className={`${styles.shopHeading} ${isOpenIconVisible ? styles.shopOpen : null}`}>

																	{/* Legacy icon */}
																	{/* {isOpenIconVisible ? <Icon className={styles.openIcon} icon='opensign' /> : null} */}
																	{shop.title}

																</span>
																<span className={styles.shopAddress}>{shop.address}, {shop.zipCode} {shop.city} </span>
															</span>
															<span className={styles.shopDistance}>
																<Distance distanceInMeters={shop.distance} />
															</span>
														</a>
													</Link>
													{!shop.legacyOpeningHours && shop.openingHoursPeriods.length <= 0 ? 
												
														null
														:
														<ShopListItemLarge shop={shop} />
													}
												</div> 
											</div>
										: 
											<ShopListItem shop={shop} />
										}
									</div>

									{showBreakingContent(indx)}
	
								</React.Fragment>
							);
						})}
					</Pagination>
				</div>
			</AppMainColBox>
		);
	}

	/**
	 * Gets oppenning hours by days in the shop
	 */
	private getOpenningHours(shop: IShopType) {

		const openingHoursPeriods = shop.openingHoursPeriods;

		// get current day of the week
		let todayNumeric = new Date().getUTCDay();
		if (todayNumeric === 0) {
			todayNumeric = 7;
		}

		// loop through all periods and get only the ones, that are interesting for today
		const periodsGroupedByDay: any = {};
		for (const period of openingHoursPeriods) {
			if (typeof periodsGroupedByDay[period.open.day] === 'undefined') {
				periodsGroupedByDay[period.open.day] = [];
			}

			periodsGroupedByDay[period.open.day].push(period)
		}
		
		for (const periodDay of Object.keys(periodsGroupedByDay)) {
			if (+periodDay === todayNumeric) {
				return periodsGroupedByDay[periodDay];
			}
		}
	}
}

export default ShopList;
