import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import PromotionsAggregatedByCitysWidgetContainer
	from '../../containers/PromotionsAggregatedByCitysWidget/PromotionsAggregatedByCitysWidgetContainer';
import { IPromotionsAggregatedByCityType } from '../../types/IPromotionsAggregatedByCityType';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IPromotionsFromBrandAndCityProps {
	citySlug: SlugType;
	brandSlug: SlugType;
}

class PromotionsFromBrandAndCity extends React.Component<IPromotionsFromBrandAndCityProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, brandSlug, citySlug } = this.props;

		const cityName = (routeMetadata && routeMetadata.city && routeMetadata.city.title) ? routeMetadata.city.title : citySlug;
		const brandName = (routeMetadata && routeMetadata.brand && routeMetadata.brand.title) ? routeMetadata.brand.title : brandSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<ProductPromotionGridContainer
								limit={20}
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								citySlug={citySlug}
								brandSlug={brandSlug}
								gridKey={routeMetadata.queryKey}
								onlyNearbyPromotions={false}
								pushRouteMetadata={true}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByCitysWidgetContainer
								widgetKey={`pbc-${routeMetadata.queryKey}-cities`}
								title={`Weitere Orte mit ${brandName} in Aktion`}
								limit={10}
								brandSlug={brandSlug}
								excludeSlug={citySlug}
								routeCallbk={this.routerCallbkCityListWidget}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.brand.title : '',
				route: 'BrandDetailRoute',
				routeParams: {brand: this.props.brandSlug},
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.city.title : '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkCityListWidget = (item: IPromotionsAggregatedByCityType) => {
		const { brandSlug } = this.props;

		return {
			name: 'PromotionsFromBrandAndCityRoute',
			params: {
				city: item.group.slug,
				brand: brandSlug,
			},
		};
	}
}

export default PromotionsFromBrandAndCity;
