import { RouteProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import LotteryPaymentContainer from '../App/modules/Lottery/containers/LotteryPayment/LotteryPaymentContainer';
import queryString from 'query-string';
import BaseRoute from './_BaseRoute';


class LotteryPaymentRoute extends BaseRoute {
	public render () {
		const query = (this.props.location) ? queryString.parse(this.props.location.search) : {};

		return (
			<div>
				<Header />
				<MainNavigation />

				<LotteryPaymentContainer
					email={query.mail + ''}
					verificationToken={query.token + ''}
				/>

				<Footer />
			</div>
		);
	}
}

export default withRouter(LotteryPaymentRoute);