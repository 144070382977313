import * as React from 'react';
import Link from './Link';

interface ILinkProps {
    to?: string;
    route?: string;
    params?: { [key: string]: any };
    queryParams?: { [key: string]: any };
    exact?: boolean; // When true, the active class/style will only be applied if the location is matched exactly.
    strict?: boolean; // When true, the trailing slash on a location's pathname will be taken into consideration when determining if the location matches the current URL. See the <Route strict> documentation for more information.
}

class Redirect extends React.PureComponent<ILinkProps & React.HTMLProps<HTMLButtonElement>> {
    public render() {
        const { to, ref, ...restProps } = this.props;

        return (
            <Link
                href={this.props.to}
                {...restProps}
                isRedirect={true}
            >
                .
            </Link>
        )
    }
}

export default Redirect;