import { call, put,  takeEvery } from 'redux-saga/effects';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import {
	addContentFragmentEntries,
	CONTENT_FRAGMENT_FETCH_ENTRIES, setContentFragmentErrors,
} from './contentFragmentActions';


/**
 * Fetch entry list
 */
function* fetchContentFragments(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	let params = action.params;

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: `/api/public/v1/content-fragments/${params.key}`,
				// params,
			} as IRequestObject);
		});

		yield put(addContentFragmentEntries(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setContentFragmentErrors(action.queryKey, e));
	}
}

export function* contentFragmentSaga() {
	yield takeEvery(CONTENT_FRAGMENT_FETCH_ENTRIES, fetchContentFragments);
}
