import * as React from 'react';
import * as styles from './PromotionworldFooterMenu.scss';
import Overlay from '../../../Base/components/Overlay/Overlay';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ShopListWidgetContainer from '../../../Vendor/containers/ShopListWidgetContainer/ShopListWidgetContainer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Animated } from 'react-animated-css';
import { IPromotionworldType } from '../../types/IPromotionworldType';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import { IFilterChip } from '../../../Tag/types/IFilterChip';

interface IPromotionworldFooterMenuPropsType {
    openMenuItem: string;
    promotionworld: IPromotionworldType;
    onCloseMenu: (item: any) => void;
    pageQueryKey: string;
    onActiveTagChange: (item: IFilterChip | null) => void;
    routeMetadata?: IRouteMetadataType;
}

class PromotionworldFooterMenu extends React.Component<IPromotionworldFooterMenuPropsType, {}> {
	public static defaultProps = {
      openMenuItem: '',
    };

	public render() {
		const { openMenuItem, promotionworld, routeMetadata } = this.props;

        // Sum of all groups promotions count values
        const sum = Object.values(routeMetadata?.totalItemsCount ?? {}).reduce((acc, val) => acc + val, 0);

		return (
            <React.Fragment>
                <TransitionGroup className={styles.promotionworldFooterMenuWrapper}>
                    <CSSTransition 
                        timeout={{
                            enter: 3000,
                            exit: 1000,
                        }}
                    >
                        <Animated
                            animationIn="slideInUp"
                            animationOut="slideOutDown"
                            isVisible={openMenuItem !== ''}
                            className={styles.promotionworldFooterMenuAnimation}
                            animationInDuration={600}
                            animationOutDuration={600}
                        >
                            <div className={styles.promotionworldFooterMenuContainer}>
                              {( !openMenuItem ) ? null :
                                <div className={styles.promotionworldFooterMenuClose} onClick={this.onCloseMenu}>
                                    SCHLIESSEN
                                </div>
                              }
                                <div className={styles.promotionworldFooterMenuInnerContainer}>

                                    {(openMenuItem === "overview") ? 
                                        <div className={styles.promotionworldFooterMenuOverviewWrapper}>

                                            <div className={styles.promotionworldFooterMenuOverviewTitle}>
                                                Übersicht: {sum ?? 'xx'} Artikel
                                            </div>

                                            <button className={styles.promotionworldFooterMenuOverviewItem} onClick={() => this.selectTag(null)}>
                                                <span className={styles.promotionworldFooterMenuOverviewItemTitle}>Alle</span>
                                                <span className={styles.promotionworldFooterMenuOverviewItemCount}>{sum ?? 'xx'}</span>
                                            </button>

                                            {promotionworld.promotionWorldGroups.map((tag, idx: number) => (
                                                <button key={`${tag.id}-${idx}`} className={styles.promotionworldFooterMenuOverviewItem} onClick={() => this.selectTag(tag)}>
                                                    <span className={styles.promotionworldFooterMenuOverviewItemTitle}>{tag.title}</span>
                                                    <span className={styles.promotionworldFooterMenuOverviewItemCount}>
                                                        {(routeMetadata?.totalItemsCount && routeMetadata?.totalItemsCount[tag.title]) ?? 'xx'}
                                                    </span>
                                                </button>
                                            ))}
                                        </div>
                                    : null }
                                    
                                    {(openMenuItem === "shops") ? 
                                        <React.Fragment>

                                            <span className={styles.promotionworldFooterMenuTitle}>
                                                In deiner Nähe
                                            </span>

                                            <ShopListWidgetContainer
                                                widgetKey={`leaflet-page-${promotionworld.leaflet.slug}-shops-nearby`}
                                                orderBy="distance"
                                                orderDir="desc"
                                                limit={10}
                                                widgetType="leaflet"
                                                leafletSlug={promotionworld.leaflet.slug}
                                            />

                                        </React.Fragment>
                                    : null}
                                </div>
                            </div>
                        </Animated>
                    </CSSTransition>
                </TransitionGroup>

                {(openMenuItem === '') ? null :
                    <Overlay onClick={this.onCloseMenu} />
                }

            </React.Fragment>
		);
    }
    
    /**
	 * Closes menu
	 */
	private onCloseMenu = () => {
        const { onCloseMenu } = this.props;
        
        onCloseMenu("")
    };

	private selectTag = (tag: IFilterChip) => {
        const { onActiveTagChange } = this.props;
        
        onActiveTagChange(tag);
        this.onCloseMenu();
    };
}

function mapStateToProps(state: any, props: IPromotionworldFooterMenuPropsType) {
	return {
		routeMetadata: state.routeMetadataReducer[props.pageQueryKey],
	};
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return { };
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);


export default compose(withConnect)(PromotionworldFooterMenu);
