import * as React from 'react';
import * as styles from './MixedEntityItem.scss';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Distance from '../../../Vendor/components/Distance/Distance';
import Link from '../../../Base/components/Link/Link';
import { IMixedEntityType } from '../../types/IMixedEntityType';

interface IMixedEntityVendorItemProps {
	data: IMixedEntityType;
	columns?: '1' | '2' | '3' | '4' | '5';
}

class MixedEntityVendorItem extends React.Component<IMixedEntityVendorItemProps, {}> {
	public render () {
		const { data, columns } = this.props;

		return (
			<Link
				route="VendorDetailRoute"
				params={{
					vendor: data.slug,
				}}
			>
				<span
					className={`${styles.MixedEntityItemWrapper} ${styles['MixedEntityItemWrapperColumns' + columns]}`}
				>
					<span
						className={styles.MixedEntityItemWrapperInner}
					>
						<span className={styles.ImageWrapper}>
							<ImageEnhanced
								 source={data.image}
								 bgImage={true}
							/>
						</span>

						<span className={styles.Content}>
							<span className={styles.Heading}>
								{data.title}
								{/* // if title is too long -> show fade out */}
								{ (data.title?.length < 55) ? null :
									<span className={styles.HeadingFadeout} />
								}
							</span>

							<span className={styles.ActionBar}>
								<span className={styles.ActionBarShrink}>
									<Distance distanceInMeters={data.distance} />
								</span>
							</span>
						</span>
					</span>
				</span>
			</Link>
		);
	}

}


export default MixedEntityVendorItem;


