import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IShopType } from '../../types/IShopType';
import { SlugType } from '../../../Base/types/SlugType';
import ShopList from '../../components/ShopList/ShopList';
import DataStateService from '../../../../services/DataStateService';
import ShopListSkeleton from '../../components/ShopList/ShopListSkeleton';

interface IShopListContainerProps {
	gridKey?: string;

	title?: string;
	pushRouteMetadata?: boolean;
	page?: number;
	limit?: number;

	orderBy?: 'relevance';
	orderDir?: 'desc';
	enablePagination?: boolean;

	vendorSlug?: SlugType;
	stateSlug?: SlugType;
	citySlug?: SlugType;
	design?: 'small' | 'large';

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class ShopListContainerInner extends React.Component<IShopListContainerProps & IWithDataProps, {}> {
	public static defaultProps = {
		orderBy: 'relevance',
		orderDir: 'desc',
	};

	public render () {
		const { data, ...restProps } = this.props;

		return (
			<ShopList
				shops={data as IShopType[]}
				{...restProps}
			/>
		);
	}
}

export default function ShopListContainer (theProps: IShopListContainerProps) {
	const props = Object.assign({
		limit: 50,
		enablePagination: false,
	}, theProps);

	const RenderComponent = withData(
		ShopListContainerInner,
		'shopReducer',
		`shop-reducer-${props.gridKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			page: props.page,
			pushRouteMetadata: props.pushRouteMetadata,

			store_vendor: props.vendorSlug,
			state: props.stateSlug,
			city: props.citySlug,
		},
		{
			LoadingComponent: () => (
				<ShopListSkeleton limit={10} design={props.design} />
			),
		}
	);

	return <RenderComponent {...props} />;
}


