import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import ThemeworldListWidgetContainer from '../../../Themeworld/containers/ThemeworldListWidget/ThemeworldListWidgetContainer';
import { IThemeworldWidgetType } from 'App/modules/Themeworld/types/IThemeworldWidget';
import BrandListGroupedContainer from '../../containers/BrandListGrouped/BrandListGroupedContainer';
import { IWithRouteMetadataType } from 'App/types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IBrandListingProps {
	title: string;
}

class BrandListing extends React.Component<IBrandListingProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { title } = this.props;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403495}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{title}
				</H1>

				<Row>
					<AppMainCol boxed={true} onTop={true} >
						<AppMainColBox boxed={true} nested={true}>
							<BrandListGroupedContainer
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403482}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403481}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							/>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							<ThemeworldListWidgetContainer
								widgetKey="brand-listing-themeworld-widget"
								title="Themenwelten"
								routeCallbk={this.routerCallbkThemeworldListWidget}
								limit={10}
								orderBy="title"
								orderDir="asc"
								btnRoute="ThemeworldListingRoute"
								btnLabel="Alle Themewelten"
							/>

							<AdItem
								desktopAdId={4403494}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {

		return [
			{
				label: 'Marken Übersicht',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the brand list widget should be routed towards
	 */
	private routerCallbkThemeworldListWidget = (item: IThemeworldWidgetType) => {
		return {
			name: 'ThemeworldDetailRoute',
			params: {
				themeworld: item.slug,
			},
		};
	}
}

export default BrandListing;
