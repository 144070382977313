import GeolocationService from '../modules/Base/services/GeolocationService';

const DataStateService = {

	/**
	 * Contains a combination of location data, to make refetching in the stores easier
	 *
	 * @param {string}
	 */
	currentStateKey: 'DEFAULTLOCATIONKEY',

	/**
	 * Refreshes the state key with most current data
	 */
	refreshStateKey(overwrites: { geolocationKey?: string } = {}) {
		const geolocationPart = (overwrites.geolocationKey) ? overwrites.geolocationKey : GeolocationService.getCurrentLocationKey();

		this.currentStateKey = `${geolocationPart}`;
	},

	/**
	 * Returns the state key
	 */
	getStateKey() {
		return this.currentStateKey;
	}

};

export default DataStateService;
