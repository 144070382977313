import * as React from 'react';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import AdItem from '../App/components/General/AdItem/AdItem';
import HomePage from '../App/components/General/Homepage/components/HomePage/HomePage';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { RouterProps, withRouter } from 'react-router';
import BaseRoute from './_BaseRoute';

class HomeRoute extends BaseRoute {
	public render () {

		const props = this.props;

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403461, 4403499]}
					mobileIds={[4403459, 4403458]}
					// superbannerId={4403485}
					sitebarId={4403460}
				/>

				<Header />
				
				<MainNavigation />

				<FlexWrapper>
					<div>
						<HomePage {...props} />

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403460}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		HomeRoute,
		'homepage',
		{
			pageTitle: 'Aktuelle Aktionen und Prospekte in Österreich',
			metaTitle: 'Aktionsfinder » Alle Angebote, Aktionen & Prospekte finden!',
			metaDescription: 'Entdecken Sie alle aktuellen Angebote ✓ Aktionen ✓ Gutscheine ✓ Rabatte ✓ Flugblätter & Prospekte ✓ für ganz Österreich » Preise vergleichen & Geld sparen!',
		} as RouterProps,
	),
);
