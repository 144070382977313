import * as React from 'react';
import { withRouter } from 'react-router';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import NotFound from '../App/modules/Base/components/NotFound/NotFound';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import AdItem from '../App/components/General/AdItem/AdItem';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import { Helmet } from 'react-helmet-async';

class NotFoundRoute extends BaseRoute {

	public render () {

		return (

			<React.Fragment>
				<Helmet>
					<link rel="stylesheet" href="https://endppostafassetsprod.azureedge.net/2.0.213/aficons/style.css" />
				</Helmet>

				<AdConfig
					mobileIds={[4493492]}
					sitebarId={4493481}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<NotFound />

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493481}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(NotFoundRoute);