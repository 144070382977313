import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import PromotionsAggregatedByBrandsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../../Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import PromotionsAggregatedByProductGroupsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import LotteryImage from '../LotteryImage/LotteryImage';
import LotteryInfoContainer from '../../containers/LotteryInfo/LotteryInfoContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';
import * as styles from './LotteryInfoPage.scss';


class LotteryInfoPage extends React.Component<{}> {
	public static defaultProps = {};

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionsfinder der Woche',
			} as IBreadcrumbItemType,
		];
	}

	public render () {

		return (
			<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()}>

				<div>
					<AdItem
						desktopAdId={4493475}
						maxHeight={250}
						type="billboard"
					/>
				</div>

				<H1 title="Aktionsfinder der Woche" />

				<Row>
					<AppMainCol boxed={false}>
         
                        <LotteryImage />

						<AdItem
							mobileAdId={4493486}
							maxHeight={250}
							type="content"
							title="Mobile Rectangle 1"
							className={styles.adItemWrapper}
						/>

						<AppMainColBox boxed={true}>

                            <LotteryInfoContainer />

						</AppMainColBox>
                        
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

                            <PromotionsAggregatedByBrandsWidgetContainer
                                title={"Bier in Aktion kaufen"}
                                limit={7}
                                productGroupSlug="bier"
                                widgetKey={`lottery-info-promotion-brands-widget`}
                                routeCallbk={this.routerCallbkPromotionsAggregatedByBrandsWidget}
                            />

							<AdItem
								desktopAdId={4493474}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

                            <PromotionsAggregatedByProductGroupsWidgetContainer
                                title={`Weitere Angebote`}
                                widgetKey={`lottery-info-brand-widget`}
                                limit={7}
                                routeCallbk={this.routerCallbkBrandListWidget}
                                orderBy="promotionCount"
                                orderDir="desc"
                            />

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
    }
    
    /**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkPromotionsAggregatedByBrandsWidget = (item: IPromotionsAggregatedByBrandType) => {
		return {
			name: 'BrandDetailRoute',
			params: {
				brand: item.group.slug,
			},
		};
    }
    
    /**
	 * Defines the route the brand list widget should be routed towards
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		return {
			href: item.group.detailUrlPath,
		};
	}

}

export default LotteryInfoPage;
