import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IVendorWidgetType } from '../../types/IVendorWidgetType';
import * as vendorWidgetActions from './vendorWidgetActions';

export type VendorWidgetAction = ActionType<typeof vendorWidgetActions>;

export interface IVendorWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IVendorWidgetType };
	readonly queries: { [queryKey: string]: IVendorWidgetType[] };
}

export function vendorWidgetReducer(
	state = baseInitialState as IVendorWidgetStateType,
	action: VendorWidgetAction,
) {
	const Reducer = new BaseReducer('vendorWidgetReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}
