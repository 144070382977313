import * as React from 'react';
import * as styles from './NearestShopWidget.scss';
import SkeletonInline from '../../../../../App/components/Layout/Skeleton/SkeletonInline';

interface INearestShopWidgetSkeletonProps {
    size?: 's' | 'l',
}


class NearestShopWidgetSkeleton extends React.PureComponent<INearestShopWidgetSkeletonProps, {}> {

    public static defaultProps = {
        size: 'l',
    };

    public render() {
        const { size } = this.props;

        return (
            <div className={`${styles.Container} ${styles[size + 'ContainerSize']}`} >
                <div className={styles.Store}>
                    <div className={styles.Image}>
                        <SkeletonInline />
                    </div>


                    <div>
                        <div className={styles.ShopName}>
                            <div className={styles.skeletonDivider}>
                                <SkeletonInline width={80} />
                            </div>
                            <div className={styles.skeletonDivider}>
                                <SkeletonInline width={120} />
                            </div>
                            <div className={styles.skeletonDivider}>
                                <SkeletonInline width={100} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Place}>

                    <span className={styles.skeletonSpanDivider}>
                        <SkeletonInline />
                    </span>
                    <span>
                        <SkeletonInline />
                    </span>

                </div>
            </div>
        )
    }

}
export default NearestShopWidgetSkeleton;
