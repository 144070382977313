import { withRouter, RouterProps } from 'react-router'; // TODO: fix navigation to react-router
import * as React from 'react';
import Button from '../../../../components/General/Button/Button';
import Checkbox from '../../../../components/Layout/Checkbox/Checkbox';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import InputField from '../../../../components/Layout/Form/InputField';
import H1 from '../../../../components/Layout/H1/H1';
import Link from '../../../Base/components/Link/Link';
import AlertService from '../../../Base/services/AlertService';
import LoginService from '../../services/LoginService';
import * as styles from './Login.scss';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Recaptcha from '../../../../components/General/Recaptcha/Recaptcha';

interface ILoginProps {
	setAccountData: (account: IAccountLoginType) => void,
}

interface ILoginStates {
	email: string,
	password: string,
	loading: boolean,
	stayLoggedIn: boolean,
	securityToken: string,
}

class Login extends React.Component<ILoginProps & RouterProps, ILoginStates> {

	constructor(props: ILoginProps) {
		super(props);

		this.state = {
			email: '',
			password: '',
			loading: false,
			stayLoggedIn: false,
			securityToken: '',
		};
	}

	private onSubmit = () => {
		const { email, password, stayLoggedIn, securityToken } = this.state;

		if (!this.checkFields()) {
			return;
		}

		this.setState({ loading: true });

		const data = {
			username: email,
			password,
			grant_type: 'password',
			securityToken,
		};

		LoginService.sendLoginData(data, stayLoggedIn, (response: IAccountLoginType) => {
			if (response && response.access_token) {
				this.props.setAccountData(response);

				this.props.history.push('/');

				setTimeout(() => {
					AlertService.add(
						'login-success',
						'Willkommen zurück',
						'Du wurdest erfolgreich angemeldet'
					);
				}, 500);

			}

			this.setState({ loading: false, securityToken: '' });
		}, (error: any) => {

			this.setState({ loading: false, securityToken: '' });

			AlertService.error(
				'error-' + error,
				error,
			);

		});
	};

	private validateRecaptcha = (securityToken: string) => {	
		this.setState({
			securityToken,
		})
	};

	public render() {
		const { loading, securityToken } = this.state;

		return (
			<Recaptcha onVerify={this.validateRecaptcha} enable={false} securityToken={securityToken}>
				<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
					<H1 align="center" className={styles.loginTitle}>
						Willkommen zurück!
					</H1>

					<FormWrapper
						onSubmit={this.onSubmit}
					>

						<InputField
							title="E-Mail"
							name="email"
							placeholder="email@example.com"
							type="email"
							onChange={this.onChangeInput}
							value={this.state.email}
							required={true}
						/>

						<InputField
							title="Passwort"
							name="password"
							placeholder="*****"
							type="password"
							onChange={this.onChangeInput}
							value={this.state.password}
							required={true}
						/>

						<div className={styles.loginControls}>
							<Checkbox
								name="stayLoggedIn"
								onChange={this.onChangeInput}
								checked={this.state.stayLoggedIn}
							>
								Eingeloggt bleiben?
							</Checkbox>

							<Link route="ForgottenPasswordRoute">
								<a className={styles.forgottenPassword}>
									Passwort vergessen?
								</a>
							</Link>
						</div>

						<Button
	                theme="primary"
							size="xxl"
							fullWidth={true}
							type="submit"
							loading={loading}
						>
							Anmelden
						</Button>

						<Button
	                theme="default"
							size="xxl"
							fullWidth={true}
							loading={loading}
							route="RegistrationRoute"
						>
							Kostenlos registrieren
						</Button>

					</FormWrapper>
				</PageWrapper>
			</Recaptcha>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Login',
			} as IBreadcrumbItemType,
		];
	}

	private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[name]: value,
		});
	};

	/**
	 * Validate fields and checks terms checkbox.
	 */
	private checkFields(): boolean {

		const { email, password } = this.state;

		const errors = [];

		if (email && email !== '' && !/\S+@\S+\.\S+/.test(email)) {
			errors.push('E-mail Adresse ungültig');
		}

		// if (password !== '' && password.length < 8) {
		// 	errors.push('Das Passwort muss mindestens 8 Zeichen lang sein');
		// }

		if (errors.length > 0) {
			for (let i = 0; i < errors.length; i++) {
				AlertService.error(
					'error-' + errors[i],
					errors[i],
				);
			}
			return false;
		}

		return true;
	}

}


export default withRouter(Login);
