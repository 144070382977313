import * as React from 'react';
import { Link as RouterLink, NavLink, Redirect as RouterRedirect } from 'react-router-dom';
import { Children, ReactNode } from 'react';
import routes from '../../../../routes';
import appConfig from '../../../../config/appConfig';

interface ILinkProps {
	href?: string;
	route?: string;
	isRedirect?: boolean;
	params?: { [ key: string ]: any };
	queryParams?: { [ key: string ]: any };
	children: ReactNode;
	activeClassName?: string,
	absolute?: boolean;
	exact?: boolean; // When true, the active class/style will only be applied if the location is matched exactly.
	strict?: boolean; // When true, the trailing slash on a location's pathname will be taken into consideration when determining if the location matches the current URL. See the <Route strict> documentation for more information.
	isActive?: (match: any, location: any) => boolean; // A function to add extra logic for determining whether the link is active. This should be used if you want to do more than verify that the link's pathname matches the current URL's pathname.
}

class Link extends React.PureComponent<ILinkProps & React.HTMLProps<HTMLButtonElement>> {
	public render () {
		const { activeClassName, exact, isRedirect } = this.props;
		let sublink = this.props.children;

		if (typeof sublink === 'string') {
			sublink = <a>{sublink}</a>;
		}

		const child = Children.only(sublink);
		const { children, ...restChildProps } = child.props;

		const theLink = this.makeSureUrlIsComplete(this.getCorrectLink());

		if (activeClassName) {
			return (
				<NavLink
					to={theLink}
					{...restChildProps}
					activeClassName={activeClassName}
					exact={exact}
				>
					{children}
				</NavLink>
			);
		}

		if (isRedirect) {
			return (
				<RouterRedirect
					to={theLink} />
			);
		}

		return (
			<RouterLink
				to={theLink}
				{...restChildProps}
			>
				{children}
			</RouterLink>
		);
	}

	/**
	 * Checks wheter we want an absolute or relative url and adds the domain if necessary
	 *
	 * @param url
	 *
	 * @return string
	 */
	private makeSureUrlIsComplete(url: string): string {
		const { absolute } = this.props;

		if (absolute) {
			return `${appConfig.mainDomain}${url}`;
		}

		return url;
	}

	/**
	 * Returns the correct link string based on either href or route + params
	 */
	private getCorrectLink(): string {
		const { href, params, route, children, queryParams } = this.props;

		if (href) {
			return href;
		}

		if (!route) {
			console.warn(`You either have to provide a valid route or href to every Link element. In this case Link with content ${children} is invalid`);
			return '';
		}

		const chosenRoute = routes[route];

		if (!chosenRoute) {
			console.warn(`Route ${route} could not be found in routes.ts`);
			return '';
		}

		let url = chosenRoute;

		if (params) {
			for (const key of Object.keys(params)) {
				url = url.replace(':'+ key, params[key]);
			}
		}

		// now add all the unused path params
		let queryParamString = '';
		if (queryParams) {
			for (const key of Object.keys(queryParams)) {
				queryParamString += `&${key}=${queryParams[ key ]}`;
			}
		}

		// if we got at least one queryParam -> add the query param chain
		if (queryParamString) {
			url = url +'?'+ queryParamString.substring(1);
		}

		return url;
	}
}

export default Link;
