import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import { addLeafletWidgetEntries, addLeafletWidgetEntry, LEAFLET_WIDGET_FETCH_ENTRIES, setLeafletWidgetErrors } from './leafletWidgetActions';
import { IRequestObject } from '../../../../../boilerplate/redux/types/IRequestObject';
import GeolocationService from '../../../Base/services/GeolocationService';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';

/**
 * Fetch entry list
 */
function* fetchLeafletWidget(action: any) {
    const afCoreRequest = new AfCoreRequest(action.queryKey);

    let params = action.params;

    const geolocationReducer = yield select((state: any) => state.geolocationReducer);
    params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, params, 4);
    // if we get a single leafletSlug -> use this
    let theUrl = `/api/public/v1/widgets/leaflets`;

    if (params.leafletsInIndustry && params.store_vendor) {
        theUrl = `/api/public/v1/leaflets/vendor/${params.store_vendor}/industry`;
        params.leafletsInIndustry = null;
    } else if (params.promotionSlug) {
        theUrl = `/api/public/v1/leaflets/nearby-promotion/${params.promotionSlug}`;
    } else if (params.store_vendor && params.onlyNearbyLeaflets) {
        theUrl = `/api/public/v1/widgets/leaflets`;
    } else if (params.store_vendor || params.brand) {
        theUrl = '/api/public/v1/leaflets/';

        if (params.getNearestLeaflet) {
            theUrl = `/api/public/v1/leaflets/${params.store_vendor}/nearest-mutation`;
        }

        if (params.onlyNearbyLeaflets) {
            theUrl = '/api/public/v1/leaflets/nearby';
        }
    }

    try {
        const response = yield call(
            (): Promise<RequestMiddlewarePipeline> => {
                return afCoreRequest.getRequest({
                    url: theUrl,
                    params,
                } as IRequestObject);
            },
        );

        let theContent = params.promotionSlug ? [] : response.getContent('content');
        const cont = response.getContent();

        if (isServer()) {
            response.addMetaData('reloadOnClient', true);
        }

        if (params.getNearestLeaflet) {
            theContent = cont;
            yield put(addLeafletWidgetEntry(action.queryKey, theContent, response.getMetaData(), action.options));
        } else if (params.promotionSlug && cont && cont.id) {
            theContent.push(cont);
            yield put(addLeafletWidgetEntries(action.queryKey, theContent, response.getMetaData(), action.options));
        } else {
            yield put(addLeafletWidgetEntries(action.queryKey, theContent, response.getMetaData(), action.options));
        }
    } catch (e) {
        yield put(setLeafletWidgetErrors(action.queryKey, e));
    }
}

export function* leafletWidgetSaga() {
    yield takeEvery(LEAFLET_WIDGET_FETCH_ENTRIES, fetchLeafletWidget);
}
