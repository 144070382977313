import * as React from 'react';
import { IProductPromotionWithShopType } from '../../types/IProductPromotionWithShopType';
import * as styles from './ProductPromotionInOtherShops.scss';
import SkeletonInline from '../../../../../App/components/Layout/Skeleton/SkeletonInline';


interface IProductPromotionInOtherShopsProps {
    title?: string,
    promotions: IProductPromotionWithShopType[],
    columns?: '1' | '2' | '3',
}

class ProductPromotionInOtherShopsSkeleton extends React.PureComponent<IProductPromotionInOtherShopsProps, {}> {
    public static defaultProps = {
        columns: 2,
    };

    public render() {
        const columns = '2'

        return (
            <div className={`${styles.Wrapper} ${styles['Columns' + columns]}`}>

                <h2 className={styles.Title}>
                    <SkeletonInline />
                </h2>

            </div>
        );
    }
}

export default ProductPromotionInOtherShopsSkeleton;
