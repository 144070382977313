import { ActionType } from 'typesafe-actions';
// import { IHomepageType } from '../../types/IHomepageType';
import * as homepageActions from './homepageActions';
import BaseReducer, { baseInitialState} from '../../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../../boilerplate/redux/types/IBaseStateType';

export type HomepageGridAction = ActionType<typeof homepageActions>;

export interface IHomepageStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: {} };
	readonly queries: { [queryKey: string]: [] };
}

export function homepageReducer(
	state = baseInitialState as IHomepageStateType,
	action: HomepageGridAction,
) {
	const Reducer = new BaseReducer('homepageReducer');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}

