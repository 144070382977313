import * as React from 'react';
import Overlay from '../Overlay/Overlay';
import * as styles from './Modal.scss';
import ModalTitle from './ModalTitle';

interface IModalProps {
	content: any;
	size?: 's' | 'm' | 'l';
	title?: string;
	titleStyle: 'center' | 'responsive';
	onCloseModal: () => void;
	contentData?: any;
}

class Modal extends React.PureComponent<IModalProps> {

	public static defaultProps = {
		titleStyle: 'responsive',
		size: 's',
	};

  public render() {
  	const { onCloseModal, title, titleStyle, contentData, size } = this.props;
		const ContentComponent = this.props.content;

    return (
    	<React.Fragment>
				<Overlay onClick={onCloseModal} style={{zIndex: 1100}}/>

		    <div className={styles.ModalOuter}>
			    <div className={`${styles.Modal} ${styles[size + 'StyleModal']}`}>

				    { (!onCloseModal) ? null :
				      <button
					      type="button"
					      onClick={onCloseModal}
					      className={styles.CloseButtonMobile}
				      >
				        Abbrechen
				      </button>
						}
						
				    <div className={styles.ModalContent}>
					    
							<ModalTitle 
								onClose={onCloseModal}
								titleStyle={titleStyle}
							>
								{title}
							</ModalTitle>

					    <ContentComponent
								onCloseModal={onCloseModal}
								data={contentData}
					    />
				    </div>
			    </div>
		    </div>
	    </React.Fragment>
    );
  }
}

export default Modal;
