
const credentialConfig = {
  /**
   * The google maps api key
   * @type string
   */
  googleMapsApiKey: '',

  /**
   * Maxmind credentials
   */
  maxmind: {
      accountId: '53311',
      licenseKey: 'SPJjg3VDPejb',
  },

};

export default credentialConfig;
