import * as React from 'react';
import * as styles from './BlankPixel.scss';

export default class BlankPixel extends React.PureComponent {
	public render() {
		return (
			<img
				className={styles.BlankPixel}
				src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
				alt="Pixel"
			/>
		);
	}
}
