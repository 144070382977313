import { DateTime } from 'luxon';
import * as React from 'react';
import * as styles from './OpeningHourLine.scss';


interface IOpeningHourLineProps {
	periods: any[];
	dayNumeric: number;
	highlight?: boolean;
	opennedLabel?: string;
}

class OpeningHourLine extends React.PureComponent<IOpeningHourLineProps, {}> {

	/**
	 * Returns a readable time string from periods
	 * @param period
	 */
	private static getTimeString(period: any): string {
		return period.time.substr(0, 2) +':'+ period.time.substr(2, 3);
	}

	public render () {
		const { dayNumeric, periods, highlight, opennedLabel } = this.props;

		const currentTime = DateTime.local();
		const dayObject   = currentTime.set({ weekday: dayNumeric });

		const isToday = currentTime.weekday === dayNumeric;

		const lineStyle = (isToday || highlight) ? styles.LineHighlighted : '';
		
		// TRANSLATE
		return (
			<div className={styles.LineWrapper}>
				<span className={`${styles.LineDayName} ${lineStyle}`}>
					{(isToday) ? 'Heute' : dayObject.toFormat('EEE')}
				</span>
				<div className={styles.LineTimeWrapper}>
					{
						(!periods) ? 
								<span
									key={`opening-hour-${dayObject.toFormat('EEE')}`}
									className={`${styles.LineHours} ${lineStyle}`}
								>
									geschlossen
								</span>
						:
							periods.map((period: any, index: number) => {

								let showOpennedLabel = false;

								// Checking if shop is not closed today
								if ((isToday || highlight) && opennedLabel) {
						
									if (period.open && period.close) {
										const timeNow = `${DateTime.local().toFormat('HH')}${DateTime.local().toFormat('mm')}`;
										if (timeNow >= period.open.time && timeNow < period.close.time) {
											showOpennedLabel = true;
										}
									}
								}

								return (
									<span
										key={`opening-hour-${dayObject.toFormat('EEE')}-${index}`}
										className={`${isToday ? styles.LineHighlighted : styles.LineHours} ${lineStyle}`}
									>
										{OpeningHourLine.getTimeString(period.open)}  -  {OpeningHourLine.getTimeString(period.close)}
										{!showOpennedLabel ? null :
											<span className={styles.LineHighlighted}> ({opennedLabel})</span>
										}
									</span>
								);
							})
					}

				</div>
			</div>
		);
	}
}


export default OpeningHourLine;
