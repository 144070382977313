import * as React from 'react';
import Checkbox from '../../../../components/Layout/Checkbox/Checkbox';
import * as styles from './FilterTagsListing.scss';

export interface IFilterTagsListingProps {
    title: string;
	tags: ITagType[];
    activeTag: ITagType;
    onActiveTagChange: (item: ITagType) => void;
}

class FilterTagsListing extends React.Component<IFilterTagsListingProps, {}> {
	public static defaultProps = {};

	public render() {
		const { title, tags, activeTag, onActiveTagChange } = this.props;

		return (
            <div className={styles.filterTagsContainer}>
                <div className={styles.title}>{ title }</div>
                <div className={styles.filterTagsWrapper}>
                    {
                        tags.map((tag: ITagType) => (
                            <Checkbox
                                key={`filter-tag-${tag.id}`}
                                style="v3"
                                name={`field-tag-${tag.id}`}
                                checked={activeTag?.id === tag.id}
                                type="radio"
                                onChange={(k: string, checked: boolean) => onActiveTagChange(tag)}
                            >
                                {tag.title}
                            </Checkbox>
                        ))
                    }
                </div>
            </div>
		);
	}
}

export default FilterTagsListing;
