import { ActionType } from 'typesafe-actions';
import BaseReducer, { baseInitialState } from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IProductPromotionWithShopType } from '../../types/IProductPromotionWithShopType';
import * as productPromotionStatusActions from './productPromotionStatusActions';

export type ProductPromotionStatusGridAction = ActionType<typeof productPromotionStatusActions>;

export interface IPromotionStatusType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IProductPromotionWithShopType };
	readonly queries: { [queryKey: string]: IProductPromotionWithShopType[] };
}

export function productPromotionStatusReducer(
	state = baseInitialState as IPromotionStatusType,
	action: ProductPromotionStatusGridAction,
) {
	const Reducer = new BaseReducer('productPromotionStatusReducer', 'slug');

    return Reducer.extend(state, action, (innerState, innerAction) => {
        switch (innerAction.type) {
            default:
                return innerState;
        }
    });
}
