import * as React from 'react';
import { connect } from 'react-redux';
import CurrentLocationWidget from '../../components/CurrentLocationWidget/CurrentLocationWidget';
import GeolocationService from '../../services/GeolocationService';
import { setGeolocation } from '../../store/Geolocation/geolocationActions';
import { IGeolocationType } from '../../types/IGeolocationType';

interface ICurrentLocationWidgetContainerProps {
	setGeolocation?: (geolocation: IGeolocationType, expiry: ExpiryType) => void,
	geolocationReducer?: any,
	hide?: boolean;
	children?: React.ReactNode;
}

type ExpiryType = 'short' | 'medium' | 'long' | null;

class CurrentLocationWidgetContainer extends React.Component<ICurrentLocationWidgetContainerProps, {}> {
	public render () {
		const {  geolocationReducer, hide, children } = this.props;

		if (hide) {
			return null;
		}

		const currentLocation = ( geolocationReducer.currentLocation ) ? geolocationReducer.currentLocation : null;

		return (
			<CurrentLocationWidget
				currentLocation={currentLocation}
				children={children}
			/>
		);
	}

	/**
	 * Initiates the user's gelocation
	 */
	// private initGeolocation () {
	// 	const setGeolocationAction = this.props.setGeolocation;
	// 	const { geolocationReducer } = this.props;
	//
	// 	// if already initiated -> return
	// 	if (geolocationReducer.isInitiated) {
	// 		return;
	// 	}
	//
	// 	GeolocationService.initGeolocation((geolocation: IGeolocationType, expiry: ExpiryType) => {
	// 		if (setGeolocationAction) {
	// 			setGeolocationAction(geolocation, expiry);
	// 		}
	// 	});
	// }
}

function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		setGeolocation: (geolocation: IGeolocationType, expiry: ExpiryType) => {
			dispatch(setGeolocation(geolocation, expiry));
		},
	};
}

function mapStateToProps (state: any) {
	return {
		geolocationReducer: state.geolocationReducer,
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(CurrentLocationWidgetContainer);
