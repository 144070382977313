/* ### CONSTANTS ### */

import { IQueryMetaDataType } from "../../../../../../boilerplate/redux/types/IQueryMetaDataType";
import { IApiErrorType } from "../../../../../../App/types/BaseTypes";


export const HOMEPAGE_FETCH_ENTRIES = '@BasePipeline/homepageReducer_FETCH_ENTRIES';
export const HOMEPAGE_ADD_ENTRIES = '@BasePipeline/homepageReducer_ADD_ENTRIES';
export const HOMEPAGE_ADD_ENTRY = '@BasePipeline/homepageReducer_ADD_ENTRY';

export const HOMEPAGE_SET_ERRORS   = '@BasePipeline/homepageReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function addHomepageEntries (queryKey: string, entries: [], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: HOMEPAGE_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addHomepageEntry (queryKey: string, entry: {}, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: HOMEPAGE_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setHomepageErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: HOMEPAGE_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
