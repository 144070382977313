import * as React from 'react';
import * as styles from './DataBlock.scss';
import Link from '../../../Base/components/Link/Link';

interface IDataBlockProps {
    title: string,
    linkRoute: string,
    linkTitle: string,
    dataString?: string,
    dataObject?: object, //Should be dataString or dataObject defined to show data
    borderBottom?: boolean
}

class DataBlock extends React.Component<IDataBlockProps, {}> {

    public static defaultProps = {
        linkTitle: 'ändern',
        linkRoute: 'HomeRoute',
        borderBottom: false,
	};

	public render () {

        const { title, linkRoute, linkTitle, dataString, dataObject, borderBottom } = this.props;

        let style = styles.dataBlockData;

        if ( dataString ) {
            style += ` ${styles.dataBlockDataString}`
        }

        if ( dataObject ) {
            style += ` ${styles.dataBlockDataObject}`
        }

		return (
			<div className={`${styles.dataBlockWrapper} ${borderBottom && styles.dataBlockBorderBottom}`}>

                <div className={style}>

                    <div className={styles.dataBlockTitle}>
                        {title}
                    </div>

                    {( !dataString ) ? null :
                        <div className={styles.dataBlockDataString}>
                            {dataString}
                        </div>
                    }

                    {( !dataObject ) ? null :
                        <div>

                            { Object.keys(dataObject).map((key, i) => {
                                return (
                                    <div key={i} className={styles.dataBlockDataObject}>
                                        <div className={styles.dataBlockDataObjectTitle}>{key}</div>
                                        <div className={styles.dataBlockDataObjectInfo}>{dataObject[key]}</div>
                                    </div>
                                )
                            }) 
                            }
                        </div>
                    }

                </div>

                <Link route={linkRoute}>
                    <a className={styles.dataBlockLink}>
                        {linkTitle}
                    </a>  
                </Link>

            </div>
		);
    }
}

export default DataBlock;
