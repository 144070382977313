import { RouteProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ResetPassword from '../App/modules/Account/components/ResetPassword/ResetPassword';
import queryString from 'query-string';
import BaseRoute from './_BaseRoute';


class ResetPasswordRoute extends BaseRoute {
	public render () {
		const query = (this.props.location) ? queryString.parse(this.props.location.search) : {};

		return (
			<div>
				<Header />
				<MainNavigation />
			
          <ResetPassword
						email={query.mail + ''}
						passwordResetToken={query.token + ''}
          />

				<Footer />
			</div>
		);
	}
}

export default withRouter(ResetPasswordRoute);

