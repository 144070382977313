import * as React from 'react';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import AccountService from '../../services/AccountService';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import AlertService from '../../../Base/services/AlertService';
import InputField from '../../../../components/Layout/Form/InputField';
import Button from '../../../../components/General/Button/Button';
import { withRouter, RouterProps } from 'react-router';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';


interface IAccountChangePasswordStates {
    oldPassword: string,
    newPassword: string,
	repPassword: string,
	loading: boolean,
}

class AccountChangePassword extends React.Component<RouterProps, IAccountChangePasswordStates> {

	constructor (props: IAccountChangePasswordProps) {
		super(props);

		this.state = {
            oldPassword: '',
            newPassword: '',
            repPassword: '',
            loading: false,
		};
    }
    
    private onSubmit = () => {

        if (!this.checkFields()) {
			return;
		}

        this.setState({ loading: true });

        const { oldPassword, newPassword } = this.state;

        const data = {oldPassword, password: newPassword};

        AccountService.changePassword(data, (response: any) => {

            this.setState({ loading: false });

            if (response) {

                this.props.history.push('/mein-benutzerkonto/meine-daten/');

                setTimeout(() => { 
					AlertService.add(
						'change-data-success',
					    'Dein Passwort wurde erfolgreich geändert.',
					);
				}, 500);
            }

        },( error: any ) => {

            this.setState({ loading: false });

            AlertService.error(
				'error-'+ error,
				error,
			);
        });
    }

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {

		return [
			{
				label: 'Mein Benutzerkonto',
				route: 'AccountInfoRoute',
			} as IBreadcrumbItemType,
			{
				label: 'Passwort Ändern',
			} as IBreadcrumbItemType,
		];
	}

	public render () {

        const { loading, oldPassword, newPassword, repPassword } = this.state;

		return (
			<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
				<H1 align="center">
                    Passwort ändern
				</H1>

                <FormWrapper
                    onSubmit={this.onSubmit}
                >

                    <InputField
                        title="Altes Passwort"
                        name="oldPassword"
                        placeholder="*****"
                        type="password"
                        onChange={this.onChangeInput}
                        value={oldPassword}
                        required={true}
                        autoComplete="false"
                    />

                    <InputField
                        title="Neues Passwort"
                        name="newPassword"
                        placeholder="*****"
                        type="password"
                        onChange={this.onChangeInput}
                        value={newPassword}
                        required={true}
                        autoComplete="false"
                    />

                    <InputField
                        title="Neues Passwort bestätigen"
                        name="repPassword"
                        placeholder="*****"
                        type="password"
                        onChange={this.onChangeInput}
                        value={repPassword}
                        required={true}
                        autoComplete="false"
                    />

                    <p>&nbsp;</p>

                    <Button
	                    theme="primary"
                        size="xxl"
                        fullWidth={true}
                        type="submit"
                        loading={loading}
                    >
                        Änderungen speichern
                    </Button>

                    <Button
	                    theme="default"
                        size="xxl"
                        fullWidth={true}
                        loading={loading}
                        route="AccountInfoRoute"
                    >
                        Abbrechen
                    </Button>

                </FormWrapper>
			</PageWrapper>
		);
    }
    
    private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[name]: value
        });
    }

    /**
	 * Validate fields and checks terms checkbox.
	 */
	private checkFields (): boolean {

		const { newPassword, repPassword } = this.state;

		const errors = [];

		if (newPassword !== '' && newPassword.length < 8) {
			errors.push('Das Passwort muss aus mindestens 8 Zeichen bestehen.');
        }
        
        if (newPassword !== repPassword) {
            errors.push('Bitte bestätige das eingegebene Passwort');
        }

		if (errors.length > 0) {
			for (let i = 0; i < errors.length; i++) {
				AlertService.error(
					'error-'+ errors[i],
					errors[i],
				);
			}
			return false;
		}

		return true;
	}
}

export default withRouter(AccountChangePassword);
