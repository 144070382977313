/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const THEMEWORLD_WIDGET_FETCH_ENTRIES = '@BasePipeline/themeworldWidgetReducer_FETCH_ENTRIES';
export const THEMEWORLD_WIDGET_FETCH_ENTRY   = '@BasePipeline/themeworldWidgetReducer_FETCH_ENTRY';
export const THEMEWORLD_WIDGET_ADD_ENTRIES = '@BasePipeline/themeworldWidgetReducer_ADD_ENTRIES';
export const THEMEWORLD_WIDGET_ADD_ENTRY = '@BasePipeline/themeworldWidgetReducer_ADD_ENTRY';

export const THEMEWORLD_WIDGET_SET_ERRORS   = '@BasePipeline/themeworldWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchThemeworldWidgets (queryKey: string) {
	return {
		type: THEMEWORLD_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addThemeworldWidgetEntries (queryKey: string, entries: IThemeworldWidgetType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: THEMEWORLD_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addThemeworldWidgetEntry (queryKey: string, entry: IThemeworldWidgetType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: THEMEWORLD_WIDGET_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setThemeworldWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: THEMEWORLD_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
