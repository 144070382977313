import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addShopGroupedByStateEntries,
	setShopGroupedByStateErrors, SHOP_GROUPED_BY_STATE_FETCH_ENTRIES,
} from './shopGroupedByStateActions';
import GeolocationService from '../../../Base/services/GeolocationService';

/**
 * Fetch entry list
 *
 * @param {any} action
 */
function* fetchShopGroupedByStates(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const paramsWithoutCoords = afCoreRequest.mergeParams({
		page: 0,
		size: 30,
	}, action.params);

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, paramsWithoutCoords, 7);

	let theUrl = `/api/public/v1/vendors/${params.store_vendor}/stores-grouped`;

	if (params.state) {
		theUrl = `/api/public/v1/federal-states/${params.state}/vendor/${params.store_vendor}`;
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: theUrl,
				params,
			} as IRequestObject);
		});

		if (isServer()) {
			response.addMetaData('reloadOnClient', true);
		}

		yield put(addShopGroupedByStateEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setShopGroupedByStateErrors(action.queryKey, e));
	}
}

export function* shopGroupedByStateSaga() {
	yield takeEvery(SHOP_GROUPED_BY_STATE_FETCH_ENTRIES, fetchShopGroupedByStates);
}
