import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	addsearchPromotionEntries,
	SEARCH_PROMOTION_FETCH_ENTRIES, setsearchPromotionErrors,
} from './searchPromotionActions';

/**
 * Fetch entry list
 */
function* fetchsearchPromotions(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/search',
				params,
			} as IRequestObject);
		});

		yield put(addsearchPromotionEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setsearchPromotionErrors(action.queryKey, e));
	}
}

export function* searchPromotionSaga() {
	yield takeEvery(SEARCH_PROMOTION_FETCH_ENTRIES, fetchsearchPromotions);
}
