import * as React from 'react';
import * as styles from './AppPromotionBanner.scss';
import AppPromotionQuoteCard from './AppPromotionQuoteCard';
import Button from '../../../components/General/Button/Button';
import InternalTrackingService from '../../../services/InternalTrackingService';
import DataLayerService from '../services/DataLayerService';

class AppPromotionBanner extends React.Component<{}> {
    public render() {
        const href = 'https://1aktionsfinder.qr1.at/9osucw';

        return (
            <a href={href} target="_blank" className={styles.promotionContainer} onClick={() => this.trackAppEvent(href)}>
                <img src={require('../components/AppExclusive/assets/Shape.svg')} alt="Shape" className={styles.shape} />
                <img src={require('../components/AppExclusive/assets/iphone.png')} alt="iphone" className={styles.phone} />

                <span className={styles.contentContainer}>
                    <span className={styles.contentWrapper}>
                        <img src={require('../components/AppExclusive/assets/title.svg')} alt="Die App für Prospekte & Aktionen in Österreich" className={styles.title} />

                        <span className={styles.ratingApp}>
                            <img src={require('../components/AppExclusive/assets/RatingWhite.svg')} alt="Rating" className={styles.ratings} />

                            <div className={styles.mobileAppBannerButton}>
                                <Button target="_blank" customClassName={styles.mobileAppButton} size={'l2'} theme="primary" >
                                    Zur App
                                </Button>
                            </div>
                        </span>
                    </span>

                    <div className={styles.quoteContainer}>
                        <AppPromotionQuoteCard imageSrc={require('../components/AppExclusive/assets/Rating.svg')} name="Lara B." comment="Super easy zu nutzen. Sehr praktisch und hilfreich in diesen teuren Zeiten. 😄" />
                        <AppPromotionQuoteCard imageSrc={require('../components/AppExclusive/assets/Rating.svg')} name="Uwe S." comment="Funktioniert super und ich hab alles was ich brauch!" />
                    </div>
                </span>
            </a>
        );
    }

    /**
     * Track App click event
     */
    private trackAppEvent = (href: string) => {
        InternalTrackingService.track('PWA_TO_APP', {
            'targetUrl': href,
            'pageTitle': document.title,
        });

        DataLayerService.push('PWA_TO_APP', {
            'targetUrl': href,
            'pageTitle': document.title,
            'pagePath': window.location.pathname,
            'buttonText': 'Zur App',
        });
    };
}

export default AppPromotionBanner;
