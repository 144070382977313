import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import LeafletsInProductGroup from '../App/modules/Leaflet/components/LeafletsInProductGroup/LeafletsInProductGroup';
import {
	pluralizerLeaflets,
	pluralizerPromotionsAndSpecials,
	maxDiscountInTitle,
	withTitlePostfix,
	withClickbait,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class LeafletsInProductGroupRoute extends BaseRoute {

	public static async getInitialProps(state: any) {
		const reduxData = await LeafletsInProductGroupRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403488]}
					mobileIds={[4403478, 4403477]}
					superbannerId={4403489}
					sitebarId={4403487}
				/>

				<Header />

				<MainNavigation />

				<FlexWrapper>
					<div>
						<LeafletsInProductGroup
							productGroupSlug={query.productGroup}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403487}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

// TODO: ADD "BIS"
export default withRouter(
	withRouteMetadata(
		LeafletsInProductGroupRoute,
		(props: any) => `lpc-${props.match.params.productGroup}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${pluralizerLeaflets(routeMetadata.totalItemsCount)} mit ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${pluralizerLeaflets(routeMetadata.totalItemsCount, true)} mit ${routeMetadata.productGroup.title} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle Flugblätter & Prospekte mit aktuellen ${routeMetadata.productGroup.title} Aktionen & Angeboten » Preise vergleichen ✓ bares Geld sparen`, `✓ Den besten Deal schnappen!`),
		} as IRouteMetadataSetterType
	)
);
