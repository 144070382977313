import * as React from 'react';
import { withRouter } from 'react-router';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import AdItem from '../App/components/General/AdItem/AdItem';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import TagDetail from '../App/modules/Tag/components/TagDetail/TagDetail';
import withTag from '../App/modules/Tag/hocs/withTag';
import {
	currentEntryWord,
	maxDiscountInTitle,
	pluralizerPromotionsAndSpecials,
	withClickbait,
	withTitlePostfix
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';


class TagDetailRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await TagDetailRouteProps(state);

		return { reduxData };
	}

	shouldComponentUpdate (nextProps) {
		if ((nextProps.routeMetadata && nextProps.routeMetadata.pageTitle) !== (this.props.routeMetadata && this.props.routeMetadata.pageTitle)) {
			return true;
		}
		if ((nextProps.routeMetadata && nextProps.routeMetadata.queryKey) === (this.props.routeMetadata && this.props.routeMetadata.queryKey)) {
			return false;
		}
		return true;
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};
		
		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						{
							withTag(
								TagDetail,
								query.tag + '',
								Object.assign({}, this.props, {
									tagSlug: query.tag,
								})
							)
						}

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		TagDetailRoute,
		(props: any) => `t-${props.match.params.tag}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.tag.title.toLowerCase() === 'preishits' ? "Deals" : routeMetadata.tag.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.tag.title.toLowerCase() === 'preishits' ? "Deals" : routeMetadata.tag.title} Aktionen & Angebote ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Aktuelle Aktionen für ${routeMetadata.tag.title} » bei Aktionsfinder informieren ✓ Preiskracher ermitteln ✓ bares Geld sparen`, `✓ Hier mehr erfahren!`),
		} as IRouteMetadataSetterType
	)
);
