import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { ILotteryListType } from '../../types/ILotteryListType';
import * as lotteryListActions from './lotteryListActions';

export type LotteryListAction = ActionType<typeof lotteryListActions>;

export interface ILotteryListStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: ILotteryListType };
	readonly queries: { [queryKey: string]: ILotteryListType[] };
}

export function lotteryListReducer(
	state = baseInitialState as ILotteryListStateType,
	action: LotteryListAction,
) {
	const Reducer = new BaseReducer('lotteryListReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
