import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addPromotionsAggregatedByCitysWidgetEntries,
	PROMOTIONS_AGGREGATED_BY_CITYS_WIDGET_FETCH_ENTRIES, setPromotionsAggregatedByCitysWidgetErrors,
} from './promotionsAggregatedByCitysWidgetActions';

/**
 * Fetch entry list
 */
function* fetchPromotionsAggregatedByCitysWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const { params } = action;

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: '/api/public/v1/widgets/cities',
					params,
				} as IRequestObject
			);
		});

		const theContent = response.getContent('content');

		yield put(addPromotionsAggregatedByCitysWidgetEntries(
			action.queryKey,
			theContent,
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionsAggregatedByCitysWidgetErrors(action.queryKey, e));
	}
}


export function* promotionsAggregatedByCitysWidgetSaga() {
	yield takeEvery(PROMOTIONS_AGGREGATED_BY_CITYS_WIDGET_FETCH_ENTRIES, fetchPromotionsAggregatedByCitysWidget);
}
