import * as React from 'react';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import * as styles from './ProductPromotionDiscountBar.scss';
import Currency from '../../../Base/components/Currency/Currency';
import { ReactNode } from 'react';
import Percentage from '../../../Base/components/Percentage/Percentage';

interface IProductPromotionDiscountBarProps {
	promotion: IProductPromotionType;
	size: 's' | 'l';
}

// TRANSLATE
class ProductPromotionDiscountBar extends React.Component<IProductPromotionDiscountBarProps, {}> {
	public static defaultProps = {
		size: 's',
	};

	public render () {
		const { promotion, size } = this.props;
		const type                = promotion.type;

		let classname = `${styles.productPromotionDiscount}`;

		if ( size === 'l' ) {
			classname += ` ${styles.productPromotionDiscountLarge}`;
		}

		if (type === 'voucher' || type === 'assortment') {
			const discountValueAssortment = this.getDiscountValue();
			return (
				<span className={classname}>
					{( !discountValueAssortment ) ? null :
						<span className={`${styles.productPromotionDiscountValue} ${styles.productPromotionDiscountVoucher}`}>
							{discountValueAssortment}
						</span>
					}
					<span className={styles.productPromotionDiscountType}>
			        	{( type === 'voucher' ) ? 'Gutschein' : 'Sortimentsaktion'}
			      </span>
			    </span>
			);
		}

		if (type === 'product' && promotion.discountedPrice) {

			if ( size === 'l' ) {
				classname += ` ${styles.productPromotionDiscountPrice}`;
			}

			return (
				<span className={classname}>
					<span className={styles.productPromotionDiscountValue}>
						{size === 'l' ? null : <span>nur </span>}
						<Currency amount={promotion.discountedPrice} currency={promotion.currency} />
					</span>
					{( !promotion.discountedPrice ) ? null :
						<span className={styles.productPromotionDiscountType}>
							{size === 'l' ? null : <span>statt </span>}
							<Currency amount={promotion.originalPrice || 0} currency={promotion.currency} />
						</span>
					}
			    </span>
			);
		}

		return (
			<span className={classname}>
				<span className={styles.productPromotionDiscountValue}>
					{this.getDiscountValue()}
				</span>
				<span className={styles.productPromotionDiscountType}>
					{( promotion.discountAmount ) ? (
						<Currency amount={promotion.discountAmount} currency={promotion.currency} /> ) 
						: 'Aktion'
					}
				</span>
		    </span>
		);
	}

	/**
	 * Returns the correct discount value based on the available discounts we have
	 *
	 * @return {string}
	 */
	private getDiscountValue (): string | ReactNode {
		const { promotion } = this.props;

		// if (promotion.discountedPrice) {
		// 	return (
		// 		<Currency
		// 			amount={promotion.discountedPrice}
		// 			currency={promotion.currency}
		// 		/>
		// 	);
		// }

		if (promotion.newPrice) {
			return (
				<Currency
					amount={promotion.newPrice}
					currency={promotion.currency}
				/>
			);
		}

		if (promotion.discountAmount) {
			return (
				<Currency
					amount={promotion.discountAmount}
					currency={promotion.currency}
				/>
			);
		}

		if (promotion.discountPercentage) {
			return <Percentage amount={promotion.discountPercentage} />;
		}

		return '';
	}
}

export default ProductPromotionDiscountBar;
