import serializeJavascript from "serialize-javascript";
import InternalTrackingService from "../../../services/InternalTrackingService";
import { ILeafletPageType } from "../../Leaflet/types/ILeafletPageType";
import { ILeafletType } from "../../Leaflet/types/ILeafletType";
import { IProductPromotionType } from "../types/IProductPromotionType";
import DataLayerService from "../../Base/services/DataLayerService";
import { LeafletViewerType } from "../../Leaflet/hocs/withLeaflet";

/**
 * Tracks the promotion either as a specific promotion clickout (if promotion given) and falls back to a leaflet clickout
 * 
 * @param promotion 
 * @param clickoutUrl 
 * @param leaflet 
 * @param page 
 * @param leafletViewerType 
 * @param pageNumber 
 * 
 * @returns 
 */
export function trackPromotionClickout(promotion: IProductPromotionType, clickoutUrl?: string | null, leaflet?: ILeafletType | null, page?: ILeafletPageType | null, leafletViewerType?: LeafletViewerType | null, pageNumber?: number | null,) {
    let promotionEventType;

    // when promotion is given and also has a type, track the promotion event clickout
    if (promotion && promotion.type != null) {

        switch (promotion.type) {
            case 'new_price': promotionEventType = 'CLICKOUT_PRICE_PROMOTION'; break;
            case 'product': promotionEventType = 'CLICKOUT_PRODUCT_PROMOTION'; break;
            case 'assortment': promotionEventType = 'CLICKOUT_ASSORTMENT_PROMOTION'; break;
            case 'voucher': promotionEventType = 'CLICKOUT_VOUCHER_PROMOTION'; break;
        }

        if (promotionEventType) {
            const productCategories = (promotion?.product?.productGroups?.map((productGroup) => productGroup.slug) || []).join(',');
            const brandSlugs = (promotion?.brandSlugs || promotion?.brands?.map((brand) => brand.slug) || []).join(',');

            InternalTrackingService.track(promotionEventType, {
                leafletId: leaflet?.id ?? '',
                leafletPageId: page?.id ?? '',
                promotionId: promotion.id,
                targetUrl: clickoutUrl ?? '',
                leafletSlug: leaflet?.slug ?? '',
                brand_slug: brandSlugs,
                product_category: productCategories,
            });

            DataLayerService.push(promotionEventType, {
                leafletId: leaflet?.id ?? '',
                leafletPageId: page?.id ?? '',
                promotionId: promotion.id,
                targetUrl: clickoutUrl,
                leafletSlug: leaflet?.slug ?? '',
                brandSlug: brandSlugs,
                productCategory: productCategories,
            });
        }

        return;
    }
    // when promotion type is undefined, track the leaflet clickout 
    else if (clickoutUrl) {
        InternalTrackingService.track('CLICKOUT_LEAFLET', {
            leafletId: leaflet?.id ?? '',
            leafletPageId: page?.id ?? '',
            targetUrl: clickoutUrl,
            industry_slug: leaflet?.vendorIndustrySlugs?.join(',') ?? '',
            infopost: leafletViewerType === LeafletViewerType.INFOPOST
        });

        DataLayerService.push('CLICKOUT_LEAFLET', {
            leafletId: leaflet?.id ?? '',
            leafletTitle: leaflet?.title ?? '',
            vendorId: (leaflet?.vendor) ? leaflet?.vendor.id : (leaflet?.vendorId || '') ?? '',
            vendorTitle: (leaflet?.vendor) ? leaflet?.vendor.name : (leaflet?.vendorName || '') ?? '',
            pageNumber: pageNumber,
            industrySlug: leaflet?.vendorIndustrySlugs?.join(',') ?? '',
            targetUrl: clickoutUrl,
        });

        return;
    }
}