import * as React from 'react';
import { ReactNode } from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import * as styles from './WidgetBoxActionButton.scss';

interface IWidgetBoxActionButtonProps {
  children: ReactNode,
	route: string,
	params: {[key: string]: any},
}

class WidgetBoxActionButton extends React.Component<IWidgetBoxActionButtonProps, {}> {
  public render () {
    const { children, route, params } = this.props;

    return (
    	<Link
		    route={route}
	        params={params}
	    >
	      <a className={`${styles.widgetBoxActionButton} widgetBoxActionButton`}>
	        {children}
	      </a>
	    </Link>
    );
  }
}

export default WidgetBoxActionButton;
