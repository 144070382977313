import { call, put, takeEvery } from 'redux-saga/effects';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import {
	addLotteryPaymentEntries,
	LOTTERY_PAYMENT_FETCH_ENTRIES, setLotteryPaymentErrors,
} from './lotteryPaymentActions';


/**
 * Fetch entry list
 */
function* lotteryPayment(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: '/api/public/v1/lottery/verify-winner-token',
				params: action.params,
			} as IRequestObject);
		});

		yield put(addLotteryPaymentEntries(
			action.queryKey,
			response.getContent(''),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {

		if (e && e[0] && e[0].status && e[0].status === 400) {
			yield put(addLotteryPaymentEntries(
				action.queryKey,
				[],
				{},
				action.options,
			));
			return;
		}

		yield put(setLotteryPaymentErrors(action.queryKey, e));
	}
}

export function* lotteryPaymentSaga() {
	yield takeEvery(LOTTERY_PAYMENT_FETCH_ENTRIES, lotteryPayment);
}
