import { IRouteMetadataType } from 'App/modules/Base/types/IRouteMetadataType';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RouteMetadataService from '../../../../services/RouteMetadataService';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	addShopEntries, addShopEntry,
	setShopErrors, SHOP_FETCH_ENTRIES, SHOP_FETCH_ENTRY,
} from './shopActions';

/**
 * Fetch entry list
 */
function* fetchShops(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params, 7);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: '/api/public/v1/stores/',
				params,
			} as IRequestObject);
		});

		const content = response.getContent('content');

		// put route metadata
		if (action.params.pushRouteMetadata) {
			const totalActiveElements = response.getContent('totalActiveElements');

			RouteMetadataService.setMetadata(action.queryKey, {
				totalItemsCount: typeof totalActiveElements !== "undefined" ? totalActiveElements : response.getContent('totalElements'),
				fullItemsCount: response.getContent('totalElements'),
			} as IRouteMetadataType);
		}

		if (isServer()) {
			response.addMetaData('reloadOnClient', true);
		}

		yield put(addShopEntries(
			action.queryKey,
			content,
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setShopErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry list
 */
function* fetchShop(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params, 7);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: `/api/public/v1/stores/${action.params.shop}`,
					params,
				} as IRequestObject
			);
		});

		if (isServer()) {
			response.addMetaData('reloadOnClient', true);
		}

		yield put(addShopEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setShopErrors(action.queryKey, e));
	}
}

export function* shopSaga() {
	yield takeEvery(SHOP_FETCH_ENTRY, fetchShop);
	yield takeEvery(SHOP_FETCH_ENTRIES, fetchShops);
}
