import * as React from 'react';
import * as styles from './RatingCard.scss';

interface IAppExclusiveCardItemProps {
  imageSrc?: string;
  name: string;
  comment: string;
}

class RatingCard extends React.Component<IAppExclusiveCardItemProps, {}> {

  public render() {
    const { comment, imageSrc, name } = this.props;

    return (
      <span className={styles.RatingCardContainer}>
        <span className={styles.RatingInfo}>
          <img src={imageSrc} alt='Bewertung' className={styles.CardStars} />
          <div className={styles.CardName}>{name}</div>
        </span>
        <div className={styles.CardComment}>{comment}</div>
      </span>
    );
  }

}

export default RatingCard;
