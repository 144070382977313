import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import PromotionsAggregatedByVendorsWidgetContainer
	from '../../containers/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidgetContainer';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IAssortementPromotionsByVendorProps {
	vendorSlug: SlugType;
}


class AssortementPromotionsByVendor extends React.Component<IAssortementPromotionsByVendorProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {

		const { routeMetadata, vendorSlug } = this.props;

		const vendorName = ( routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title ) ? routeMetadata.vendor.title : vendorSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>

						<AppMainColBox nested={true} boxed={true}>

							<ProductPromotionGridContainer
								limit={20}
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								vendorSlug={vendorSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								fetchAssortementProductPromotions={true}
								onlyNearbyPromotions={false}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							<PromotionsAggregatedByVendorsWidgetContainer
								widgetKey={`apv-${vendorSlug}-vendor-widget`}
								title={`Sortimentsaktionen bei anderen Händler`}
								limit={5}
								vendorSlug={vendorSlug}
								routeCallbk={this.routerCallbkVendorListWidget}
								fetchAssortementProductPromotions={true}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, vendorSlug } = this.props;
		const vendorName                    = ( routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title ) ? routeMetadata.vendor.title : vendorSlug;

		return [
			{
				label: 'Händler',
				route: 'VendorListingRoute',
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.vendor.title : '',
				route: 'PromotionsByVendorRoute',
				routeParams: { vendor: this.props.vendorSlug },
			} as IBreadcrumbItemType,
			{
				label: `${vendorName} Sortimentsaktionen`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkVendorListWidget = (item: IPromotionsAggregatedByVendorType) => {

		return {
			name: 'AssortementPromotionsByVendorRoute',
			params: {
				vendor: item.group.slug,
			},
		};
	};
}

export default AssortementPromotionsByVendor;
