import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import LeafletsInIndustry from '../App/modules/Leaflet/components/LeafletsInIndustry/LeafletsInIndustry';
import { withTitlePostfix, withClickbait, pluralizerLeaflets, currentEntryWord } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class LeafletsInIndustryRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await LeafletsInIndustryRouteProps(state);

		return { reduxData };
	}
	
	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403488]}
					mobileIds={[4403478, 4403477]}
					superbannerId={4403489}
					sitebarId={4403487}
				/>

				<Header />

				<MainNavigation />

				<FlexWrapper>
					<div>
						<LeafletsInIndustry
							industrySlug={query.industry + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403487}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		LeafletsInIndustryRoute,
		(props: any) => `li-${props.match.params.industry}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => 
				`${routeMetadata.industry.title} - ${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${pluralizerLeaflets(routeMetadata.totalItemsCount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.industry.title} » ${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)}  ${pluralizerLeaflets(routeMetadata.totalItemsCount, true)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${pluralizerLeaflets(routeMetadata.totalItemsCount, true)} der Kategorie ${routeMetadata.industry.title} durchstöbern » bei Aktionsfinder informieren ✓ Preise vergleichen ✓ bares Geld sparen`, `✓ Jetzt fündig werden!`),
		} as IRouteMetadataSetterType
	)
);
