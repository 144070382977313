import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import BrandList from '../../components/BrandList/BrandList';
import BrandListSkeleton from '../../components/BrandList/BrandListSkeleton';
import Pagination from '../../../../components/Layout/Pagination/Pagination';

interface IBrandListContainerProps {
	title?: string;
	queryKey?: string;
	limit?: number;
	orderBy?: 'name';
	orderDir?: 'asc' | 'desc';

	initialLetter?: string; // a letter to show only brands with this letter

	city?: string; // the city in which we are looking
	manufacturerSlug?: SlugType; // brands by manfacturer
	productGroupSlug?: SlugType; // beloging to product group
	shopSlug?: SlugType; // beloging to speicif cstore
	vendorSlug?: SlugType; // beloging to speicif cstore
	exclude?: SlugType[]; // ids to exclude from this list

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class BrandListContainerInner extends React.Component<IBrandListContainerProps & IWithDataProps> {
	public render () {
		const { data, fetchMore, metadata, queryKey, ...restProps } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<Pagination
				fetchMore={this.props.fetchMore}
				metadata={this.props.metadata}
				queryKey={this.props.queryKey} 
				LoaderComponent={<BrandListSkeleton limit={((this.props.metadata && this.props.metadata.totalItemsCount) - data.length)} />}
			>
				<BrandList
					brands={data}
					{...restProps}
				/>
			</Pagination>
		);
	}
}

export default function BrandListContainer (props: IBrandListContainerProps) {
	const limit = ( props.limit ) ? props.limit : 20;
	const sort = ( props.orderBy && props.orderDir ) ? `${props.orderBy} ${props.orderDir}` : 'name,asc'

	const RenderComponent = withData(
		BrandListContainerInner,
		'brandReducer',
		`brand-list-${props.initialLetter}`,
		{
			size: limit,
			initial: props.initialLetter,
			manufacturer: props.manufacturerSlug,
			product_group: props.productGroupSlug,
			store: props.shopSlug,
			store_vendor: props.vendorSlug,
			sort: sort,
			exclude: props.exclude,
			city: props.city,
		}, 
		{
			LoadingComponent: () => (
				<BrandListSkeleton />
			),
		}
	);

	return <RenderComponent {...props} />
}