import { RouteProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ConfirmNewslettersContainer from '../App/modules/Newsletter/containers/ConfirmNewsletters/ConfirmNewslettersContainer';
import queryString from 'query-string';
import BaseRoute from './_BaseRoute';


class ConfirmNewslettersRoute extends BaseRoute {
	public render () {
		const query = (this.props.location) ? queryString.parse(this.props.location.search) : {};

		return (
			<div>
				<Header />
				<MainNavigation />
			
                <ConfirmNewslettersContainer
					mail={query.mail + ''}
					token={query.token + ''}
                />

				<Footer fluid={true}/>
			</div>
		);
	}
}

export default withRouter(ConfirmNewslettersRoute);

