import { withRouter, RouterProps } from 'react-router';
import { createRef } from 'react';
import * as React from 'react';
import queryString from 'query-string';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { hideOverlay, showOverlay } from '../../../modules/Base/store/Modal/modalActions';
import SearchSuggestionService from '../../../modules/Search/services/SearchSuggestionService';
import * as styles from './HeaderSearch.scss';
import HeaderSearchGroup from './HeaderSearchGroup';
import HeaderSearchItem from './HeaderSearchItem';
import Icon from '../../../modules/Base/components/Icon/Icon';
import DataLayerService from '../../../modules/Base/services/DataLayerService';

interface IHeaderSearchProps {
	value?: string;
	showOverlayFunc?: () => void;
	hideOverlayFunc?: () => void;
	className?: string;
	router?: any;
}

interface IHeaderSearchSuggestionGroupType {
	title: string;
	suggestions: string[];
}

interface IHeaderSearchState {
	value?: string;
	suggestions?: IHeaderSearchSuggestionGroupType[],
	isFocused?: boolean,
	selectedSuggestion?: string,
}


const getSuggestionValue = (suggestion: string) => {
	return suggestion;
}

/**
 * Renders a single suggestion item
 *
 * @param suggestion
 */
const renderSuggestion = (suggestion: string) => (
	<HeaderSearchItem key={`header-search-item-${suggestion}`} result={suggestion} />
);

/**
 * Renders the group title
 * @param group
 */
const renderSectionTitle = (group: IHeaderSearchSuggestionGroupType) => (
	<HeaderSearchGroup>
		{group.title}
	</HeaderSearchGroup>
);

/**
 * Used to get the suggestion items in a group
 * @param group
 */
const getSectionSuggestions = (group: IHeaderSearchSuggestionGroupType) => {
	return (group.suggestions) || [];
};


// TRANSLATE
class HeaderSearch extends React.Component<IHeaderSearchProps & RouterProps, IHeaderSearchState> {

	private searchFieldRef = createRef<HTMLInputElement>();

	private isUnmounted = true;

	private ignoreBlur = false;

	private currentValue = '';
	private lastValue = '';

	constructor(props: any) {
		super(props);

		const searchTerm = this.getSearchtermFromUrl(props);

		this.currentValue = searchTerm;
		this.lastValue = searchTerm;
		this.state = {
			value: searchTerm,
			suggestions: [],
			isFocused: false,
		};
	}

	public shouldComponentUpdate(nextProps: Readonly<IHeaderSearchProps & RouterProps>, nextState: Readonly<IHeaderSearchState>, nextContext: any): boolean {
		const prevSearchTerm = this.getSearchtermFromUrl(this.props);
		const nextSearchTerm = this.getSearchtermFromUrl(nextProps);

		// if the main props changed -> update
		if (prevSearchTerm !== nextSearchTerm) {
			return true;
		}

		// if important state values have changed -> update
		if (
			nextState.isFocused !== this.state.isFocused
			|| nextState.value !== this.state.value
			|| nextState.suggestions !== this.state.suggestions
		) {
			return true;
		}

		// otherwise no need to update
		return false;
	}

	public componentDidMount(): void {
		this.isUnmounted = false;
	}

	public componentWillUnmount(): void {
		// Disable the search overlay background
		// const { hideOverlayFunc } = this.props;
		//
		// if (hideOverlayFunc) {
		// 	hideOverlayFunc();
		// }
		this.isUnmounted = true;
	}

	public componentDidUpdate(prevProps: Readonly<IHeaderSearchProps & RouterProps>): void {
		const prevSearchTerm = this.getSearchtermFromUrl(prevProps);
		const nextSearchTerm = this.getSearchtermFromUrl(this.props);

		if (this.lastValue !== nextSearchTerm || prevSearchTerm !== nextSearchTerm) {
			if (!this.isUnmounted) {
				this.setState({
					value: nextSearchTerm,
				}, () => {
					this.lastValue = nextSearchTerm;
				});
			}
		}
	}

	public render() {
		const { className, router } = this.props;
		const { value, suggestions, isFocused } = this.state;
		const hasInputValue = value && value.trim().length > 0;
		const inputClassName = `${styles.headerSearchInput} ${hasInputValue ? styles.searchInputWithValue : ''}`;

		// Autosuggest will pass through all these props to the input.
		const inputProps = {
			value,
			onChange: this.onChange,
			className: inputClassName,
			id: 'mainHeaderSearchInput',
			name: 'search',
			placeholder: (!isFocused) ? 'Suchen ...' : '',
			onFocus: this.onFocus,
			onBlur: this.onBlur,
		};

		return (
			<div className={`${className} ${styles.headerSearchWrapper} ${(isFocused) ? styles.headerSearchWrapperFocused + ' searchfield-is-focused' : ''}`}>
				<header>
					<div className={styles.headerSearchInputWrapper}>
						<button
							type="button"
							className={styles.mobileCancelBtn}
							onClick={this.blurInput}
						>
							<Icon icon='arrow-back' />
						</button>

						<form onSubmit={this.submitSearch}>
							<Autosuggest
								suggestions={suggestions}
								onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
								onSuggestionsClearRequested={this.onSuggestionsClearRequested}
								getSuggestionValue={getSuggestionValue}
								renderSuggestion={renderSuggestion}
								renderSectionTitle={renderSectionTitle}
								inputProps={inputProps}
								multiSection={true}
								getSectionSuggestions={getSectionSuggestions}
								alwaysRenderSuggestions={isFocused}
								onSuggestionSelected={this.submitSearch}
								ref={autosuggest => {
									if (autosuggest !== null) {
										this.searchFieldRef = autosuggest.input
									}
								}}
							/>
						</form>

					</div>

					<label
						htmlFor="mainHeaderSearchInput"
						className={`${styles.headerSearchIcon} ${hasInputValue ? styles.headerSearchIconWithValue : ''}`}
					>
						<Icon icon='search' />
					</label>

					{/* Show the close icon only if there is a value */}
					{(!value) ? null :
						<span
							onClick={this.clearSearchField}
							className={styles.headerCloseIcon}
						>
							<Icon icon='close' />
						</span>
					}
				</header>
			</div>
		);
	}

	/**
	 * Takes a props object and returns the nested query param for search term
	 * @param props
	 */
	private getSearchtermFromUrl(props: IHeaderSearchProps & RouterProps): string {
		const query = (this.props.location) ? queryString.parse(this.props.location.search) : {};

		if (!query.q) {
			return '';
		}

		return query.q;
	}

	/**
	 * Sets the focus state
	 */
	private onFocus = () => {
		const { value } = this.state;
		document.getElementById('searchBar').style.zIndex = "1100";
		document.getElementById('searchBar').style.width = "100%";


		this.setState({
			isFocused: true,
		});

		if (!value || value.length < 1) {
			SearchSuggestionService.getSearchSuggestionsWithoutTerm((suggestions: any) => {
				this.setState({
					suggestions,
				});
			});
		}
	};

	private wait(timeout) {
		return new Promise(resolve => {
			setTimeout(resolve, timeout);
		});
	}

	/**
	 * Unsets the focus state
	 */
	private onBlur = async () => {
		this.clearSearchField();
		// defer the blur, to make sure we can also do other things in between
		await this.wait(100);
		

		// if we were told to ignore one blur, then just stop and reset the ignore blur state
		if (this.ignoreBlur) {
			this.ignoreBlur = false;
			return;
		}

		

		const { hideOverlayFunc } = this.props;

		document.getElementById('searchBar').style.zIndex = "220";
		document.getElementById('searchBar').style.width = "25%";

		if (!this.isUnmounted) {
			setTimeout(() => {
				
				this.setState({
					isFocused: false,
					suggestions: [],
				});
			}, 100);
		}	
		if (hideOverlayFunc) {
			hideOverlayFunc();
		}
	};

	/**
	 * Changes value on typing
	 *
	 * @param event
	 * @param newValue
	 */
	private onChange = (event: any, { newValue, ...otherProps }: any) => {
		this.currentValue = newValue;
		if (!this.isUnmounted) {
			this.setState({
				value: newValue,
				...otherProps,
			});
		}
	};

	/**
	 * Navigate to search page on submit
	 *
	 * @param event
	 */
	private submitSearch = (event: any) => {
		const { value } = this.state;
		const { hideOverlayFunc } = this.props;


		const searchTerm = (this.currentValue) ? this.currentValue : value;

		if (event && event.preventDefault) {
			event.preventDefault();
		}

		if (!searchTerm) {
			this.blurInput();
			if (hideOverlayFunc) {
				hideOverlayFunc();
			}
			return;
		}

		if (!this.isUnmounted) {

			this.onTrackClickout(searchTerm);

			this.setState({
				isFocused: false,
			}, () => {
				// blur the input
				this.blurInput();

				// navigate to results page
				this.props.history.push({
					pathname: '/suche/',
					search: `?q=${encodeURIComponent(searchTerm)}`,
				});

				// Router.pushRoute('SearchRoute', {
				// 	q: searchTerm,
				// });
			});
		}

		if (hideOverlayFunc) {
			hideOverlayFunc();
		}
	};

	/**
	 * Removes the input field content
	 */
	private clearSearchField = () => {
		this.setState({
			value: '', // clears the input value
		});

		this.blurInput(); // blurs the input to remove focus

		const inputDom = document.getElementById('mainHeaderSearchInput');
		if (inputDom) {
			// inputDom.focus();
			inputDom.blur();
			document.getElementById('searchBar').style.width = "25%";
		}
	}

	/**
	 * Removes the input field focus
	 */
	private blurInput() {
		const inputDom = document.getElementById('mainHeaderSearchInput');
		if (inputDom) {
			inputDom.blur();
		}
	}

	/**
	 * Autosuggest will call this function every time you need to update suggestions.
	 *
	 * @param { value: string } arr
	 */
	private onSuggestionsFetchRequested = ({ value }: any) => {
		SearchSuggestionService.getSuggestions(value, (suggestions: any) => {
			if (!this.isUnmounted) {
				this.setState({
					suggestions,
				});
			}
		});
	};

	/**
	 * Clears suggestions
	 */
	private onSuggestionsClearRequested = () => {
		if (!this.isUnmounted) {
			this.setState({
				suggestions: [],
			});
		}
	};

	/**
	 * Tracks a click on the clickout url
	 */
	private onTrackClickout = (searchterm: string): void => {

		// InternalTrackingService.track("FULLTEXT_SEARCH", {
		// 	searchterm
		// });

		DataLayerService.push("FULLTEXT_SEARCH", {
			searchterm
		});
	};
}

function mapStateToProps(state: any) {
	return {
		router: state.router,
	};
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
		showOverlayFunc: () => {
			dispatch(showOverlay());
		},
		hideOverlayFunc: () => {
			dispatch(hideOverlay());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderSearch));
