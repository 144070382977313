import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import { ReactNode, ReactNodeArray } from 'react';
import Button from '../../../../components/General/Button/Button';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import credentialConfig from '../../../../config/credentialConfig';
import * as styles from './ShopLocationMapWidget.scss';
import ShopLocationMapPin from './ShopLocationMapPin';

interface IShopLocationMapWidgetProps {
	title: string,
	center: {
		lat: number,
		lng: number,
	},
	zoom: number,

	children?: ReactNode | ReactNodeArray,
}

class ShopLocationMapWidget extends React.Component<IShopLocationMapWidgetProps, {}> {
	public static defaultProps = {
		center: {
			lat: 48.210033,
			lng: 16.363449, // replace with dynamic
		},
		zoom: 16,
	};

	public render () {
		const { title, center, zoom, children } = this.props;

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>

					{( !children ) ? null :
						<div className={styles.mapDescription}>
							{children}
						</div>
					}

					{ (!center?.lat || !center?.lng) ? null :
						<>
							<div className={styles.mapWrapper}>
								<GoogleMapReact
									bootstrapURLKeys={{ key: credentialConfig.googleMapsApiKey }}
									defaultCenter={center}
									defaultZoom={zoom}
								>
									<ShopLocationMapPin
										lat={center.lat}
										lng={center.lng}
									/>
								</GoogleMapReact>
							</div>

							<Button
								href={this.getGoogleNavigationLink()}
								rel="nofollow noopener noreferrer"
								size="l"
								target="_blank"
								uppercase={true}
								fullWidth={true}
								theme="contrast"
								customClassName={styles.mapRouteBtn}
							>
								Route berechnen
							</Button>
						</>
					}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}

	/**
	 * Returns a link that points to googles navigation service
	 *
	 * @return {string}
	 */
	private getGoogleNavigationLink(): string {
		const { center, zoom } = this.props;

		return `https://maps.google.com/maps?&q=${center.lat},${center.lng}&hl=de&t=h&z=${zoom}&t=m`;
	}
}

export default ShopLocationMapWidget;
