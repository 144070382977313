import * as React from 'react';
import { IVendorType } from '../../types/IVendorType';
import * as styles from './VendorListGroup.scss';
import VendorListItem from '../VendorList/VendorListItem';

interface IVendorProps {
  vendors: IVendorType[],
  gridKey?: string,
}

class VendorListGroup extends React.Component<IVendorProps, {}> {
  public static defaultProps = {
    gridKey: 'vendor-list'
  };

  // TRANSLATE
  public render() {
    const { vendors, gridKey } = this.props;

    return (
      <div className={styles.vendorListGroupWrapper}>
        {vendors.map(vendor => {
          return (
            <div className={styles.vendorListGroupItemWrapper} key={`${gridKey}-${vendor.slug}`}>
              <VendorListItem
                vendor={vendor}
              />
            </div>
          )
        })}
      </div>
    );
  }
}

export default VendorListGroup;
