import * as React from 'react';
import * as styles from './ShoppingCenterListItem.scss';
import Link from '../../../Base/components/Link/Link';
import Icon from '../../../Base/components/Icon/Icon';
import Distance from '../../../Vendor/components/Distance/Distance';
import { IShoppingCenterType } from '../../types/IShoppingCenterType';

interface IShoppingCenterListItemProps {
	shoppingCenter: IShoppingCenterType;
	widgetKey?: string;
}

class ShoppingCenterListItem extends React.Component<IShoppingCenterListItemProps, {}> {
	public static defaultProps = {
		widgetKey: 'default',
	};

	public render() {
		const { shoppingCenter, widgetKey } = this.props;

		return (
			<Link
				route="ShoppingCenterDetailRoute"
				params={{
					shoppingCenter: shoppingCenter.slug,
				}}
				key={`shoppingCenter-list-item-${widgetKey}-${shoppingCenter.slug}`}
			>
				<a className={styles.shoppingCenterListItem}>
					<div className={styles.shoppingCenterTextContainer}>
						<span className={styles.shoppingCenterListItemTitle}>
							{shoppingCenter.title}
						</span>

						<span className={styles.shoppingCenterListItemAddress}>
							{shoppingCenter.address}, {shoppingCenter.zipCode} {shoppingCenter.city.name}
						</span>
					</div>

					{!shoppingCenter.distance ? null : (
						<div className={styles.shoppingCenterDistanceContainer}>
							<Icon
								icon="direction-filled"
								className={styles.shoppingCenterListDistanceIcon}
							/>
							&nbsp;
							<span className={styles.shoppingCenterListItemDistance}>
								<Distance
									distanceInMeters={shoppingCenter.distance}
									hideIcon={true}
								/>
							</span>
						</div>
					)}
				</a>
			</Link>
		);
	}
}

export default ShoppingCenterListItem;
