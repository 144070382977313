import * as React from 'react';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import { IProductType } from '../../types/IProductType';
import * as styles from './ProductPromotionDetailAction.scss';
import HumanReadableDate from '../../../Base/components/HumanReadableDate/HumanReadableDate';
import ProductPromotionDetailActionButtonsContainer from './ProductPromotionDetailActionButtons';


interface IProductPromotionDetailActionProps {
    promotion: IProductPromotionType;
    productData: IProductType;
    promotionExpired?: boolean;
}

class ProductPromotionDetailAction extends React.Component<
    IProductPromotionDetailActionProps,
    {}
> {
    public render() {
        const { promotion, promotionExpired } = this.props;
        
        return (
            <>
                <div className={styles.Container}>
                    {(!promotion || !promotion.slug) ? null :
                        <ProductPromotionDetailActionButtonsContainer
                            promotion={promotion}
                            promotionSlug={promotion.slug}
                        />
                    }

                    {/* <a href="#" className={styles.ActionLink} onClick={this.notImplementedYet}>
                        <Icon icon="alarm-ios" className={styles.ActionIcon} />
                        <span>Aktionsalarm aktivieren</span>
                    </a> */}
                </div>

                <div className={styles.SubtitleWrapper}>
                    {( promotion.minimalAcceptance && promotion.minimalAcceptance > 1 ) ?
                            <div className={styles.MinimalAcceptance}>
                                Mindestabnahme: {promotion.minimalAcceptance} {this.getPackageQuantityUnit()}
                            </div>
                        : null
                    }

                    {!promotion.validFrom || !promotion.validTo ? null :
                        <div className={`${styles.Subtitle} ${promotionExpired ? styles.expiredPromotion : null}`}>
                            { (promotion.type === 'voucher') ?
                            <span>Gutschein</span>
                                                            :
                            <span>Aktion</span>
                            }
                            &nbsp;
                            <HumanReadableDate
                                from={promotion.validFrom}
                                to={promotion.validTo}
                                outputIfEnded="bereits abgelaufen"
                                postfixIfNotEnded=" gültig"
                                openValidity={promotion.openValidity}
                            /> 
                        </div>
                    }
                </div>
            </>
        );
    }

    /**
	 * Returns the package quantity unit for this product
	 *
	 * @return {string}
	 */
	private getPackageQuantityUnit(): string {
		const { productData, promotion } = this.props;

		if (!productData.packageQuantityUnit || !productData.packageQuantityUnit.name) {
			return 'Stück'; // TRANSLATE
		}

		if (promotion.minimalAcceptance && promotion.minimalAcceptance > 0) {
			return productData.packageQuantityUnit.pluralName;
		}

		return productData.packageQuantityUnit.name;
    }
}
export default ProductPromotionDetailAction;
