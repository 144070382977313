import * as React from 'react';
import ProductPromotionDetailContainer from '../ProductPromotionDetail/ProductPromotionDetailContainer';
import { withRouter, RouterProps } from 'react-router';
import withPromotion from '../../hocs/withPromotion';

interface IProductPromotionDetailPageContainerProps {
    promotion: string;
}

class ProductPromotionDetailPageContainer extends React.Component<IProductPromotionDetailPageContainerProps, RouterProps> {

	public render () {
		const promotion = this.props.promotion;

		return (
			<ProductPromotionDetailContainer
				promotion={promotion}
				promotionSlug={promotion}
				{...this.props}
			/>
		);
	}
}

export default withRouter((props: any) => {
	return (
		<React.Fragment>
			{withPromotion(
				ProductPromotionDetailPageContainer,
				props.promotion,
				props
			)}
		</React.Fragment>
	);
});
