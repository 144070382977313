import * as React from 'react';
import { ComponentType } from 'react';
import withData from '../../../../boilerplate/redux/hocs/withData';

export default function withLeafletList(WrapperComponent: ComponentType<any>, props: any) {
	const RenderComponent = withData(
		WrapperComponent,
		'leafletReducer',
		props.gridKey,
		{
			size: props.limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			onlyNearbyLeaflets: props.onlyNearbyLeaflets,
			include_all: "false",
			reference: props.referenceSlug,
		}
	);

	return <RenderComponent {...props} />;
}
