/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const THEMEWORLD_TAGS_FETCH_ENTRIES = '@BasePipeline/themeworldTagsReducer_FETCH_ENTRIES';
export const THEMEWORLD_TAGS_FETCH_ENTRY   = '@BasePipeline/themeworldTagsReducer_FETCH_ENTRY';
export const THEMEWORLD_TAGS_ADD_ENTRIES = '@BasePipeline/themeworldTagsReducer_ADD_ENTRIES';
export const THEMEWORLD_TAGS_ADD_ENTRY = '@BasePipeline/themeworldTagsReducer_ADD_ENTRY';

export const THEMEWORLD_TAGS_SET_ERRORS   = '@BasePipeline/themeworldTagsReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchThemeworldTags (queryKey: string) {
	return {
		type: THEMEWORLD_TAGS_FETCH_ENTRIES,
		queryKey,
	};
}

export function addThemeworldTagsEntries (queryKey: string, entries: IThemeworldTagType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: THEMEWORLD_TAGS_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setThemeworldTagsErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: THEMEWORLD_TAGS_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
