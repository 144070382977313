import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';
import * as styles from './LotteryListItem.scss';
import { ILotteryType } from '../../types/ILotteryType';
import { getClickoutGatewayUrl } from '../../../../utils/clickoutGatewayGenerator';

interface ILotteryListItemProps {
    lottery: ILotteryType;
    columns?: '2' | '3';
}

class LotteryListItem extends React.Component<ILotteryListItemProps> {
    public static defaultProps = {};

    public render() {
        const { lottery, columns } = this.props;

        let className = `${styles.lotteryListItem} ${
            styles['lotteryListItemColumns-' + columns]
        }`;

        return (
            <a
                className={className}
                href={getClickoutGatewayUrl(lottery.targetUrl)}
            >
                <span className={styles.lotteryListItemImageContainer}>
                    {!lottery.resolutions ? (
                        <ProductImagePlaceholder alt={lottery.title} />
                    ) : (
                        <ImageEnhanced
                            source={lottery.resolutions}
                            sourceOriginal={lottery.imageUrl}
                            size={'m'}
                            className={styles.lotteryListItemImage}
                            alt={lottery.title}
                         />
                    )}
                </span>

                <span className={styles.lotteryListItemTextContainer}>
                    {!lottery.title ? null : (
                        <span className={styles.lotteryListItemTitle}>
                            {lottery.title}
                        </span>
                    )}

                    {!lottery.description ? null : (
                        <span className={styles.lotteryListItemContent}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: lottery.description,
                                }}
                            />
                        </span>
                    )}
                </span>
            </a>
        );
    }
}

export default LotteryListItem;
