import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';
import PromotionsAggregatedByBrandsWidgetContainer from '../../containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';


interface IPromotionsInShopByBrandProps {
	shopSlug: SlugType,
	brandSlug: SlugType,
}

class PromotionsInShopByBrand extends React.Component<IPromotionsInShopByBrandProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {
		const { routeMetadata, brandSlug, shopSlug } = this.props;

		const shopName         = ( routeMetadata && routeMetadata.shop && routeMetadata.shop.title ) ? routeMetadata.shop.title : shopSlug;
		const brandName = ( routeMetadata && routeMetadata.brand && routeMetadata.brand.title ) ? routeMetadata.brand.title : brandSlug;

		if (!routeMetadata) {
			return;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridContainer
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								shopSlug={shopSlug}
								brandSlug={brandSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								onlyNearbyPromotions={false}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							{/* <PromotionsAggregatedByBrandsWidgetContainer
								title={`Weitere Marken in Aktion bei `}
								widgetKey={`pbs-${shopSlug}-${brandSlug}-aggregated-brands-widget`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								// ven={productGroup.slug}
							/> */}
						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByProductGroupType) => {

		const { shopSlug } = this.props;

		return {
			name: 'PromotionsInShopByBrandRoute',
			params: {
				productGroup: item.group.slug,
				shop: shopSlug,
			},
		};
	};

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {

		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute',
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.brand.title : '',
				route: 'BrandDetailRoute',
				routeParams: {brand: this.props.routeMetadata ? this.props.routeMetadata.brandSlug: ''}
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.pageTitle : '',
			} as IBreadcrumbItemType,
		];
	}
}

export default PromotionsInShopByBrand;