import * as React from 'react';
import Link from '../../../../modules/Base/components/Link/Link';
import Icon from '../../../Base/components/Icon/Icon';
import { IdType } from '../../../Base/types/IdType';
import { IProductGroupType } from '../../../Category/types/IProductGroupType';
import { IVendorType } from '../../../Vendor/types/IVendorType';
import * as styles from './ProductPromotionStatusWidget.scss';


interface IProductPromotionStatusWidgetProps {
	data: IProductPromotionStatusType;
	productGroup: IProductGroupType;
	vendor: IVendorType;
}

class ProductPromotionStatusWidget extends React.PureComponent<IProductPromotionStatusWidgetProps, {}> {
	public render () {
        const { data, productGroup, vendor } = this.props;

        if (!data.hasActiveLeafletForVendor && !data.hasActivePromotionsForVendor && !data.hasActivePromotionsForProductGroup) {
            return null;
        }

		return (
            <div className={styles.promotionStatusContainer}>
                <div className={styles.promotionStatusTitle}>
                    Aktion ist leider abgelaufen
                </div>

                {!data.hasActivePromotionsForProductGroup ? null :
                    <Link 
                        route="ProductGroupDetailRoute"
                        params={{
                            productGroup: productGroup.slug
                        }}
                    >
                        <a href="#" className={styles.promotionStatusItem}>
                            <Icon icon="arrow-right" className={styles.promotionStatusRowArrow} />
                            <span className={styles.promotionStatusItemText}>Aktuelle {productGroup.title} Aktionen</span>
                        </a>
                    </Link>
                }

                {!data.hasActivePromotionsForVendor ? null :
                    <Link 
                        route="VendorDetailRoute"
                        params={{
                            vendor: vendor.slug
                        }}
                    >
                        <a href="#" className={styles.promotionStatusItem}>
                            <Icon icon="arrow-right" className={styles.promotionStatusRowArrow} />
                            <span className={styles.promotionStatusItemText}>Aktuelle {vendor.name} Aktionen & Angebote</span>
                        </a>
                    </Link>
                }

                {!data.hasActiveLeafletForVendor ? null :
                    <Link 
                        route="CurrentVendorLeaflet"
                        params={{
                            vendor: vendor.slug
                        }}
                    >
                        <a href="#" className={styles.promotionStatusItem}>
                            <Icon icon="arrow-right" className={styles.promotionStatusRowArrow} />
                            <span className={styles.promotionStatusItemText}>Aktuelles {vendor.name} Prospekt</span>
                        </a>
                    </Link>
                }
            </div>
        );
	}
}

export default ProductPromotionStatusWidget;
