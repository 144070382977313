import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import UrlFilterProvider, { UrlFilterApplyFilterType, UrlFilterFilterData } from '../../components/UrlFilterProvider/UrlFilterProvider';
import { Router } from '../../../../routes';
import SearchPageContainer from './SearchPageContainer';
import SearchPageNoSearchTerm from '../../components/SearchPage/SearchPageNoSearchTerm';

interface ISearchPageContainerPropType {
	searchTerm: string;
	filterData?: UrlFilterFilterData;
	applyFilters: UrlFilterApplyFilterType;
	queryParams: { [key: string]: string };
}

class SearchPageContainerOuter extends React.Component<ISearchPageContainerPropType & IWithDataProps, {}> {
	public render () {
		const { queryParams, searchTerm } = this.props;

		if (!searchTerm || typeof searchTerm === 'undefined' || searchTerm === 'undefined') {
			return (
				<SearchPageNoSearchTerm />
			);
		}

		return  (
			<React.Fragment>
				<UrlFilterProvider
					wrappedComponent={SearchPageContainer}
					queryParams={queryParams}
					searchTerm={searchTerm}
					defaultFilterData={{
						sort: 'score,desc',
						distance: '50000',
					}}
					navigate={this.navigateToFilter}
					deferChangeTime={1000}
					enableSearchCounter={true}
				/>
			</React.Fragment>
		);
	}

	/**
	 * Processes the filter application by navigating to a new route
	 *
	 * @param flatQueryObj
	 */
	private navigateToFilter = (flatQueryObj: UrlFilterFilterData) => {
		const { searchTerm, push: routerPush } = this.props;

		routerPush(
			Router.getRouteUrl('SearchRoute', {
				...flatQueryObj,
				q: searchTerm,
			})
		);
	}
}

/**
 * @param theProps
 *
 * @constructor
 */
export default connect(null, { push })(SearchPageContainerOuter);


