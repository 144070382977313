/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const SEARCH_PROMOTION_FETCH_ENTRIES = '@BasePipeline/searchPromotionReducer_FETCH_ENTRIES';
export const SEARCH_PROMOTION_FETCH_ENTRY   = '@BasePipeline/searchPromotionReducer_FETCH_ENTRY';
export const SEARCH_PROMOTION_ADD_ENTRIES = '@BasePipeline/searchPromotionReducer_ADD_ENTRIES';
export const SEARCH_PROMOTION_ADD_ENTRY = '@BasePipeline/searchPromotionReducer_ADD_ENTRY';

export const SEARCH_PROMOTION_SET_ERRORS   = '@BasePipeline/searchPromotionReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchsearchPromotions (queryKey: string) {
	return {
		type: SEARCH_PROMOTION_FETCH_ENTRIES,
		queryKey,
	};
}

export function addsearchPromotionEntries (queryKey: string, entries: IPromotionType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SEARCH_PROMOTION_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setsearchPromotionErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SEARCH_PROMOTION_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
