/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IProductGroupType } from '../../types/IProductGroupType';

export const VENDOR_FETCH_ENTRIES = '@BasePipeline/productGroupReducer_FETCH_ENTRIES';
export const VENDOR_FETCH_ENTRY   = '@BasePipeline/productGroupReducer_FETCH_ENTRY';
export const VENDOR_ADD_ENTRIES = '@BasePipeline/productGroupReducer_ADD_ENTRIES';
export const VENDOR_ADD_ENTRY = '@BasePipeline/productGroupReducer_ADD_ENTRY';

export const VENDOR_SET_ERRORS   = '@BasePipeline/productGroupReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchProductGroups (queryKey: string) {
	return {
		type: VENDOR_FETCH_ENTRIES,
		queryKey,
	};
}

export function addProductGroupEntries (queryKey: string, entries: IProductGroupType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addProductGroupEntry (queryKey: string, entry: IProductGroupType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
		options,
	};
}

export function setProductGroupErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_SET_ERRORS,
		queryKey,
		errors,
		metaData,
		options,
	};
}
