import appConfig from '../../App/config/appConfig';

const routeMetadataConfig = {
    /**
     * Default title for meta tags
     */
    // TODO: get real default values from aktionsfinder
    pageTitle: 'Aktionsfinder',
    metaTitle: 'Aktionsfinder',
    metaDescription: 'Aktionsfinder. Finde was du willst',
    ogImage: appConfig.mainDomain + '/images/ogimage.png',
};

export default routeMetadataConfig;
