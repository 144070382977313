import * as React from 'react';
import { IVendorType } from '../../types/IVendorType';
import * as styles from './VendorListGrouped.scss';
import VendorListGroup from './VendorListGroup';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';

interface IVendorProps {
    vendors: IVendorType[],
    gridKey?: string
    metadata?: IQueryMetaDataType,
    fetchMore?: (queryKey: string) => void;
    queryKey?: string;
    contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

interface IGroupedVendorsProps {
    group: string,
    vendors: IVendorType[],
    contentBreaks: {},
}

class VendorListGrouped extends React.Component<IVendorProps, {}> {
    public static defaultProps = {
        gridKey: 'vendor-list-grouped'
    };

    // TRANSLATE
    public render() {
        const { vendors, gridKey, fetchMore, metadata, queryKey, contentBreaks } = this.props;

        return (
            <div className={styles.vendorListGroupedWrapper}>

                <Pagination
                    fetchMore={fetchMore}
                    metadata={metadata}
                    queryKey={queryKey}
                >
                    {this.groupVendors(vendors).map((item: IGroupedVendorsProps, indx: number) => {
                        return (
                            <React.Fragment key={`${gridKey}-${queryKey}-${indx}`}>

                                {(!contentBreaks || !contentBreaks[indx]) ? null :
                                    <React.Fragment>
                                        {contentBreaks[indx]}
                                    </React.Fragment>
                                }

                                <h2 className={styles.vendorListGroupedTitle}>{item.group}</h2>

                                <VendorListGroup
                                    vendors={item.vendors}
                                />

                            </React.Fragment>
                        )
                    })
                    }
                </Pagination>
            </div>
        );
    }

    /**
     * Group vendors by first letters of names.
     * Number vendors are in "0-9" group.
     */
    private groupVendors(vendors: IVendorType[]) {

        const data = vendors.reduce((prev, current) => {

            const group = current.name[0].toUpperCase()

            if (!isNaN(Number(group)) && !prev["0-9"]) {
                prev["0-9"] = { group: "0-9", vendors: [current] }
            } else if (!isNaN(Number(group)) && prev["0-9"]) {
                prev["0-9"].vendors.push(current)
            } else if (!prev[group]) {
                prev[group] = { group, vendors: [current] };
            } else {
                prev[group].vendors.push(current)
            }

            return prev;
        }, {});

        const result = Object.values(data);

        return result;
    }

}

export default VendorListGrouped;
