import { DateTime } from 'luxon';
import bookmarkConfig from '../../../../config/bookmarkConfig';
import CookieService from '../../../../services/CookieService';
import BookmarkService from '../../services/BookmarkService';
import { IBookmarkType } from '../../types/IBookmarkType';
import {
	BOOKMARK_ADD_ENTRY,
	BOOKMARK_REMOVE_ENTRY, BOOKMARK_RESET,
	BOOKMARK_SET_ERRORS,
	BOOKMARK_SYNC_LOCAL_ENTRIES,
} from './bookmarkActions';

export interface IBookmarkEntryType {
	[entityKey: string]: {
		[bookmarkSlug: string]: IBookmarkType,
	}
}

export interface IBookmarkStateType {
	readonly entryMap: IBookmarkEntryType;
	totalBookmarks: number;
}

const initialState = {
	entryMap: {
	},
	totalBookmarks: 0,
} as IBookmarkStateType;


let lastBookmarkState = initialState as IBookmarkStateType;


function getBookmarkStats(entryMap: IBookmarkEntryType) {
	let totalBookmarks = 0;

	for (const entityKey of Object.keys(entryMap)) {
		const entity = entryMap[entityKey];

		totalBookmarks += Object.keys(entity).length;
	}

	return {
		totalBookmarks,
	};
}

export function bookmarkReducer(
	state = initialState,
	action: any,
) {
	
	switch (action.type) {
		case BOOKMARK_ADD_ENTRY:
			const bookmarkMap = (state.entryMap[action.entity]) ? state.entryMap[action.entity] : {};
			lastBookmarkState = Object.assign({}, state);

			if (bookmarkMap[action.slug]) {
				return state;
			}

			const newBookmarkMap = Object.assign({}, bookmarkMap, { [action.slug]: { slug: action.slug, createdAt: DateTime.local() }, });
			const newEntryMap = Object.assign({}, state.entryMap, { [action.entity]: newBookmarkMap });

			// get stats
			const refreshedBookmarkStats = getBookmarkStats(newEntryMap);

			const newState = Object.assign({}, state, { entryMap: newEntryMap, ...refreshedBookmarkStats });

			BookmarkService.syncBookmarks(newState.entryMap);

			return newState;



		case BOOKMARK_REMOVE_ENTRY:
			const bookmarkMapRmv = (state.entryMap[action.entity]) ? state.entryMap[action.entity] : {};
			lastBookmarkState = Object.assign({}, state);

			if (!bookmarkMapRmv[action.slug]) {
				return state;
			}

			const newBookmarkMapRmv = Object.assign({}, bookmarkMapRmv);
			delete newBookmarkMapRmv[action.slug];

			const newEntryMapRmv = Object.assign({}, state.entryMap, { [action.entity]: newBookmarkMapRmv });

			// get stats
			const refreshedBookmarkStatsRmv = getBookmarkStats(newEntryMapRmv);

			const newStateRmv = Object.assign({}, state, { entryMap: newEntryMapRmv, ...refreshedBookmarkStatsRmv });

			BookmarkService.syncBookmarks(newStateRmv.entryMap);

			return newStateRmv;



		case BOOKMARK_RESET:
			BookmarkService.syncBookmarks({});
			return Object.assign({}, initialState);

		case BOOKMARK_SET_ERRORS:
			return lastBookmarkState;



		case BOOKMARK_SYNC_LOCAL_ENTRIES:
			const newEntryMapSync = {} as IBookmarkEntryType;

			const bookmarkProfiles = bookmarkConfig.entities;
			for (const entity of Object.keys(bookmarkProfiles)) {
				const syncKey = bookmarkProfiles[entity].syncKey;

				newEntryMapSync[entity] = {};
				for (const bookmark of action.remoteBookmarks[syncKey]) {
					newEntryMapSync[entity][bookmark.slug] = bookmark;
				}
			}

			// get stats
			const refreshedBookmarkStatsSync = getBookmarkStats(newEntryMapSync);

			const newStateSync = Object.assign({}, state, { entryMap: newEntryMapSync, ...refreshedBookmarkStatsSync });

			BookmarkService.syncBookmarks(newStateSync.entryMap);

			return newStateSync;



		default:
			return state;
	}
}
