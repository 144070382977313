import * as React from 'react';
import appConfig from '../../../../../config/appConfig';
import { IWithRouteMetadataType } from '../../../../../types/IWithRouteMetadataType';
import PageWrapper from '../../../../Layout/PageWrapper/PageWrapper';
import HomepageContainer from '../../containers/Homepage/HomepageContainer';
import * as styles from './HomePage.scss';

class HomePage extends React.Component<IWithRouteMetadataType, {}> {

    public render() {
        const { routeMetadata } = this.props;

        if (!routeMetadata) {
            return null;
        }

        return (
            <PageWrapper>
              
              <div className={`${styles.HeroContainer} home-hero-container1`} />

                {/* <AdItem
                    desktopAdId={4403485}
                    maxHeight={250}
                    type="billboard"
                /> */}

              <div className={`${styles.HeroContainer} home-hero-container2`} />

                <HomepageContainer
                    title={routeMetadata.pageTitle}
                    gridKey={`${routeMetadata.queryKey}`}
                />

                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: this.getCorporateRich(),
                    }}
                />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: this.getSitelinksRich(),
                    }}
                />

            </PageWrapper>
        );
    }

    private getSitelinksRich() {
        return JSON.stringify(
            {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "url": appConfig.mainDomain,
                  "potentialAction": {
                      "@type": "SearchAction",
                      "target": `${appConfig.mainDomain}/suche/?q={search_term_string}`,
                      "query-input": "required name=search_term_string"
                  }
              });
    }

    private getCorporateRich() {
        return JSON.stringify(
        {
            '@context': 'http://schema.org',
            'address': {
                '@type': 'PostalAddress',
                'addressLocality': 'Wien',
                'postalCode': '1030',
                'streetAddress': 'Rochusplatz 1',
            },
            '@type': 'Organization',
            'url': 'https://www.aktionsfinder.at',
            'logo': {
                '@context': 'http://schema.org',
                '@type': 'ImageObject',
                'url': `${appConfig.mainDomain}/logo.png`,
                'width': '512',
            },
            'email': 'kundenservice@post.at',
            'faxNumber': '(0) 577 67 - 22071 ',
            'name': 'Aktionsfinder',
            'telephone': '(0) 577 67 0',
            'sameAs': [
                'https://www.facebook.com/Aktionsfinder/',
            ],
        });
    }

}

export default HomePage;
