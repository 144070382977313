import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import { ILeafletsAggregatedByProductGroupType } from '../../types/ILeafletsAggregatedByProductGroupType';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';

interface ILeafletsAggregatedByProductGroupsWidgetProps {
	aggregatedEntries: ILeafletsAggregatedByProductGroupType[];
	title: string;
	widgetKey?: string;
	style?: IWidgetStyleType;

	routeCallbk: (item: ILeafletsAggregatedByProductGroupType) => { name: string, params: {[key: string]: any} },
}

class LeafletsAggregatedByProductGroupsWidget extends React.Component<ILeafletsAggregatedByProductGroupsWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'leaflet-list-widget',
	};

	public render() {
		const { aggregatedEntries, title, widgetKey, routeCallbk, style } = this.props;

		return (
			<WidgetBox title={title} style={style}>
				<WidgetBoxContent>
					{ aggregatedEntries.map((aggregatedEntry: ILeafletsAggregatedByProductGroupType) => {
							const route = routeCallbk(aggregatedEntry);

							return (
								<WidgetListItemLinkRow
									title={aggregatedEntry.group.title}
									subtitle={`${aggregatedEntry.totalItemsCount} ${ (aggregatedEntry.totalItemsCount === 1) ? 'Prospekt' : 'Prospekte'}`}
									key={`${widgetKey}-${aggregatedEntry.group.slug}`}
									route={route.name}
									routeParams={route.params}
									style={style}
								/>
							);
						}
					)}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default LeafletsAggregatedByProductGroupsWidget;
