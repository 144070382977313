import * as React from 'react';
import * as styles from './BrandList.scss';
import { IBrandType } from '../../types/IBrandType';
import { IQueryMetaDataType } from 'boilerplate/redux/types/IQueryMetaDataType';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import BrandListItem from '../BrandListItem/BrandListItem';

export interface IBrandListProps {
	brands: IBrandType[],
	gridKey?: string,
	boxed?: boolean;

	metadata?: IQueryMetaDataType,
	fetchMore?: (queryKey: string) => void;
	queryKey?: string;
	
	routeCallbk?: (brandGroup: IBrandType) => { name: string, params: {[key: string]: any} };

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class BrandList extends React.PureComponent<IBrandListProps, {}> {
	public static defaultProps = {
		gridKey: 'brand-list',
		boxed: false,
	};

	// TRANSLATE
	public render () {
		const { brands, gridKey, boxed, contentBreaks } = this.props;

		return (
			<AppMainColBox boxed={boxed} nested={true}>

				<div>
					{brands.sort(this.sortAlphabetically).map((brand: IBrandType, indx: number) => {
						return (
							<React.Fragment key={`brands-grid-list-${gridKey}-${brand.slug}`}>

								{(!contentBreaks || !contentBreaks[indx]) ? null :
									<React.Fragment>
										{contentBreaks[indx]}
									</React.Fragment>
								}

								<div className={styles.brandListWrapper}>
									<BrandListItem brand={brand}/>
								</div>
							</React.Fragment>
						);
					})}
				</div>

			</AppMainColBox>
		);
	}

	/**
	 * Function which sorts brands list alphabetically by slug value
	 */
	private sortAlphabetically(a: IBrandType, b: IBrandType) {
		if (a.name.toLowerCase() < b.name.toLowerCase())
		  return -1;
		if (a.name.toLowerCase() > b.name.toLowerCase())
		  return 1;
		return 0;
	}
}

export default BrandList;
