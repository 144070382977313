import * as React from 'react';
import { IProductGroupType } from '../../types/IProductGroupType';
import MenuCategory from '../../../../components/Layout/Menu/MenuCategory';
import MenuItem from '../../../../components/Layout/Menu/MenuItem';
import MenuGoBack from '../../../../components/Layout/Menu/MenuGoBack';
import SwipeableViews from 'react-swipeable-views';

interface IProductGroupInMenuProps {
    productGroups: IProductGroupType[];
    onCloseMenu: () => void;
    toMainMenu: () => void;
    title: string;
}

interface IProductGroupInMenuState {
    index: number;
    navigatedProductGroups: IProductGroupType[];
}

class ProductGroupInMenu extends React.Component<IProductGroupInMenuProps, IProductGroupInMenuState> {

    constructor(props: IProductGroupInMenuProps) {
        super(props);
        this.state = {
            index: 0,
            navigatedProductGroups: [],
        };
    }

	public render() {
        const { productGroups, toMainMenu, title, onCloseMenu } = this.props;
        
        const { index, navigatedProductGroups } = this.state;

		return (
            <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex} animateHeight={true}>
            
                <div>
                    <MenuGoBack toMainMenu={toMainMenu}/> 

                    <MenuCategory title={title}>
                        {productGroups.map((productGroup: IProductGroupType, indx: number) => {
                            if (productGroup.childProductGroups.length === 0) {
                                return (
                                    <MenuItem
	                                    key={`menu-productgroup-${productGroup.title}-${indx}`}
                                        title={productGroup.title}
                                        routeHref={productGroup.detailUrlPath}
	                                    onCloseMenu={onCloseMenu}
                                    />
                                )
                            }

                            return (
                                <MenuItem
	                                key={`menu-productgroup-${productGroup.title}-${indx}`}
                                    title={productGroup.title}
                                    categoryData={productGroup}
	                                href={productGroup.detailUrlPath}
	                                onClick={this.slideMenuToNext}
                                />
                            )
                        })}
                    </MenuCategory>
                </div>

                {navigatedProductGroups.map((productGroup: IProductGroupType) => {
                        return ( 
                            this.MenuSubcategory(productGroup, onCloseMenu)
                        )
                    })
                }

            </SwipeableViews>

		);
    }

    /**
	 * Returns to main menu
	 */
    private goBackInMenu = () => {
        const navigatedProductGroups = this.state.navigatedProductGroups;
        navigatedProductGroups.splice(-1,1);

        this.setState({
            index: this.state.index - 1,
            navigatedProductGroups,
        });
    }
    
     /**
	 * Handles change of item by clicking
	 */
    private slideMenuToNext = (productGroup: IProductGroupType) => {
        this.setState(prevState => ({
            index: this.state.index + 1,
            navigatedProductGroups:  [...prevState.navigatedProductGroups, productGroup],
        }));
    };

    /**
	 * Handles change of tab by swiping
	 */
    private handleChangeIndex = (index: number) => {
        this.setState({ index });
    };

    /**
     * Renders subcategory menu items
     */
    private MenuSubcategory = (productGroup: IProductGroupType, onCloseMenu: () => void) => {
        const { navigatedProductGroups } = this.state;

        const lastProductGroupNavigation = (navigatedProductGroups.length > 0) ? navigatedProductGroups[navigatedProductGroups.length - 1] : {} as IProductGroupType;

        return (
            <div key={productGroup.slug}>

                <MenuGoBack 
                    toMainMenu={this.goBackInMenu}
                    title="Zurück"
                />

                {lastProductGroupNavigation ? <MenuCategory title={lastProductGroupNavigation.title}>

                    {( lastProductGroupNavigation.childProductGroups ) ? lastProductGroupNavigation.childProductGroups.map((productGroup: IProductGroupType, indx: number) => {

                        if (productGroup.childProductGroups.length === 0) {
                            return (
                                <MenuItem
                                    key={`menu-productgroup-${productGroup.title}-${indx}`}
                                    title={productGroup.title}
                                    routeHref={productGroup.detailUrlPath}
                                    onCloseMenu={onCloseMenu}
                                />
                            )
                        }

                        return (
                            <MenuItem
	                            key={`menu-productgroup-${productGroup.title}-${indx}`}
                                title={productGroup.title}
                                onClick={this.slideMenuToNext}
	                            href={productGroup.detailUrlPath}
                                categoryData={productGroup}
                            />
                        )
                    }) 
                    : null}

                </MenuCategory> 
                : null }

            </div>
        )
    }
}

export default ProductGroupInMenu;
