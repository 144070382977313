import { ActionType } from 'typesafe-actions';
import BaseReducer, { baseInitialState } from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as carouselActions from './carouselActions';
import { ICarouselTeaser } from '../../types/CarouselTeaser/ICarouselTeaser';

export type CarouselAction = ActionType<typeof carouselActions>;

export interface ICarouselStateType extends IBaseStateType {
    readonly entryMap: { [queryKey: string]: ICarouselTeaser };
    readonly queries: { [queryKey: string]: ICarouselTeaser[] };
}

export function carouselReducer(state = baseInitialState as ICarouselStateType, action: CarouselAction) {
    const Reducer = new BaseReducer('carouselReducer', 'slug');

    return Reducer.extend(state, action, (innerState, innerAction) => {
        switch (innerAction.type) {
            case 'OTHER_ACTION_TYPE':
                return Object.assign({}, innerState, {
                    data: null,
                    done: false,
                    error: null,
                    loading: true,
                });

            default:
                return innerState;
        }
    });
}
