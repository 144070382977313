import * as React from 'react';
import * as styles from './LotteryInfo.scss';
import Button from '../../../../components/General/Button/Button';
import { DateTime } from 'luxon';
import Currency from '../../../Base/components/Currency/Currency';

interface ILotteryInfoProps {
  winnersHistory: IWinnerType[];
  nextLottery: IWinnerType;
}

class LotteryInfo extends React.Component<ILotteryInfoProps, {}> {
  public static defaultProps = {};

  public render() {
    const { winnersHistory, nextLottery } = this.props;

    const lastWinner = winnersHistory && winnersHistory[ 0 ];

    const history = winnersHistory && winnersHistory.slice(1);

    const weekDay = DateTime.local().weekday;

    if (lastWinner.claimed || (weekDay !== 1 && weekDay !== 2)) {
       history.unshift(lastWinner);
    }

    return (
      <div className={styles.lotteryInfoWrapper}>

        <h2>Aktionsfinder der Woche ist:</h2>

        <div className={styles.lotteryInfoBlock}>
          <div className={styles.lotteryInfoWin}>
            {this.RenderInfoWin(lastWinner, weekDay)}
          </div>

          <span className={styles.lotteryInfoBlockInfo}>
                        {this.RenderInfoBlockInfo(lastWinner, weekDay)}
                    </span>

        </div>

        <div className={styles.lotteryInfoHistoryBlock}>

                    <span className={styles.lotteryInfoBlockInfo}>
                        Die nächste Verlosung eines
                    </span>

          <div className={`${styles.lotteryInfoWin} ${styles.lotteryInfoWinAmount}`}>
            <span>{nextLottery.amount} €</span> Warengutscheins für den Einzelhandel
          </div>

          <div className={styles.lotteryInfoHistoryText}>
            findet am kommenden
          </div>

          <div className={styles.lotteryInfoHistoryText}>
            Montag, {DateTime.fromISO(nextLottery.day).setLocale('de').toFormat('DD')} statt.
          </div>

          <div className={styles.lotteryInfoParticipateButton}>
            <Button
              theme="primary"
              size="xxl"
              fullWidth={true}
              route="LotteryRegistrationRoute"
            >
              Jetzt teilnehmen...
            </Button>
          </div>

        </div>

        <h3 className={styles.lotteryInfoTitle}>Damit du mit etwas Glück einen Warengutschein für den Einzelhandel
                                                gewinnen kannst, melde dich bitte unter "Jetzt teilnehmen" zu unserem
                                                Gewinnspiel an.</h3>

        <div className={styles.lotteryInfoDescription}>
          Wöchentlich am Montag ziehen wir unter allen Teilnehmern den glücklichen Gewinner. Der Gewinner wird
          automatisch per E-Mail benachrichtigt und hat 48 Stunden Zeit den Gewinn über einen Link einzulösen. Wird der
          Gewinn nicht eingelöst, erhöht sich der Gewinnspiel-Jackpot für die nächste Verlosung um den nicht eingelösten
          Betrag.
        </div>

        <div className={styles.lotteryInfoParticipateButton}>
          <Button
            theme="primary"
            size="xxl"
            fullWidth={true}
            route="LotteryRegistrationRoute"
          >
            Jetzt teilnehmen...
          </Button>
        </div>

        <h3 className={styles.lotteryInfoTitle}>Die letzten fünf Gewinner</h3>

        <div className={styles.lotteryInfoWinnersBlocks}>
          {history.map((winner: IWinnerType, index: number) => {
            return (
              <div className={styles.lotteryInfoWinnersBlock} key={index}>
                <div className={styles.lotteryInfoWinnersTime}>
                  Mo, {DateTime.fromISO(winner.day).setLocale('de').toFormat('DD')}
                </div>
                <div>
                  {winner.fullName}
                </div>
                <div>
                  {( !winner.amount ) ?
                   'Nicht eingelöst' :
                   <Currency amount={winner.amount} />
                  }
                </div>
              </div>
            );
          })
          }
        </div>

        <p>&nbsp;</p>
        <p>&nbsp;</p>

      </div>

    );
  }

  /**
   * Renders a info text
   */
  private RenderInfoBlockInfo = (lastWinner: IWinnerType, weekDay: number) => {

    if (lastWinner.claimed) {
      return (
        <span>
                    Wir gratulieren dir herzlich zum Gewinn eines <Currency amount={lastWinner.amount} /> Warengutscheins! Der Gutschein ist bereits auf dem Weg zu dir!
                </span>
      );
    }

    // If today is monday or tuesday
    if (weekDay === 1 || weekDay === 2) {
      return (
        <span>
                    Gratuliere! Du hast bei unserem Gewinnspiel gewonnen und wurdest von uns per E-Mail benachrichtigt. Löse deinen Gewinn über den Link in der E-Mail bis Dienstag Abend ein und hole dir den Wertgutschein!
                </span>
      );
    }

    return (
      <span>
                Diese Woche wurde der Gewinn nicht eingelöst und der Gewinnspiel-Jackpot erhöht sich um 50,00 €!
            </span>
    );
  };

  /**
   * Renders winner name or info text
   */
  private RenderInfoWin = (lastWinner: IWinnerType, weekDay: number) => {

    // If today is monday or tuesday or
    if (weekDay !== 1 && weekDay !== 2 && !lastWinner.claimed) {
      return 'Gewinn wurde leider nicht eingelöst';
    }

    return lastWinner.fullName;
  };
}

export default LotteryInfo;