import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import ProductPromotionGridGroupedContainer
	from '../../containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import PromotionsAggregatedByBrandsWidgetContainer from '../../containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import MobileAppWidget from '../../../../components/Layout/MobileAppWidget/MobileAppWidget';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IPromotionsInProductGroupAndStateProps {
	productGroupSlug: SlugType;
	stateSlug: SlugType;
}

class PromotionsInProductGroupAndState extends React.Component<IPromotionsInProductGroupAndStateProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {
		const { routeMetadata, productGroupSlug, stateSlug } = this.props;

		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;
		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
						
							<ProductPromotionGridGroupedContainer
								enablePagination={true}
								productGroupSlug={productGroupSlug}
								stateSlug={stateSlug}
								limitItemsPerGroup={6}
								limit={3}
								groupBy="city"
								groupTitle={`%city% - %count% ${productGroupName} Aktion %maxDiscount%`}
								groupTitlePlural={`%city% - %count% ${productGroupName} Aktionen %maxDiscount%`}
								orderBy="createdAt"
								orderDir="desc"
								moreLabel={`Weitere ${productGroupName} Aktionen in %city%`}
								moreRouteCallbk={this.routerCallbkPromotionListWidget}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								onlyNearbyPromotions={false}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridGroupedContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByBrandsWidgetContainer
								title={`Beliebte ${productGroupName} Marken in ${stateName}`}
								widgetKey={`ppcst-brand-widget-${productGroupSlug}-${stateSlug}`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								productGroupSlug={productGroupSlug}
								stateSlug={stateSlug}
								orderBy="promotionCount"
								orderDir="desc"
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<MobileAppWidget />

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, productGroupSlug, stateSlug } = this.props;

		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;
		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;

		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: `${productGroupName} Aktionen in ${stateName}`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: IProductPromotionGroupedType) => {
        const { productGroupSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndCityRoute',
			params: {
                productGroup: productGroupSlug,
                city: item.group.slug,
			},
		};
	};

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {

		return {
			name: 'BrandDetailRoute',
			params: {
				brand: item.group.slug,
			},
		};
	}
}

export default PromotionsInProductGroupAndState;
