import { withRouter } from 'react-router';
import * as React from 'react';
import BaseRoute from './_BaseRoute';
import withData from '../boilerplate/redux/hocs/withData';
import Redirect from '../App/modules/Base/components/Link/Redirect';
import { isServer } from '../boilerplate/razzle/razzleUtils';
import { ILeafletType } from '../App/modules/Leaflet/types/ILeafletType';
import LeafletService from '../App/modules/Leaflet/services/LeafletService';

interface ICurrentVendorLeafletRoutePropTypes {
    data: ILeafletType;
    params: { [key: string]: string };
}

class CurrentVendorLeafletRoute extends BaseRoute<ICurrentVendorLeafletRoutePropTypes> {
    public componentDidMount() {
    }

    public render() {
        const { data, params } = this.props;

        if (isServer()) {
            return null;
        }
        
        // if no leaflets found -> redirect to vendor page
        if (!data || !data.slug) {
            return (
                <Redirect
                    route="LeafletsByVendorRoute"
                    params={{
                        vendor: params.vendorSlug,
                    }}
                />
            );
        }

        // otherwise redirect to the correct leaflet
        return (
            <Redirect { ...LeafletService.generateLeafletRoute(data) } />
        );
    }
}

export default withRouter((props) => {
    const vendorSlug = props.match?.params?.vendor;

    const Comp = withData(
        CurrentVendorLeafletRoute,
        'leafletWidgetReducer',
        `current-vendor-leaflet-route-${vendorSlug}`,
        {
            store_vendor: vendorSlug,
            getNearestLeaflet: true,
        },
        {
            ErrorComponent: () => <CurrentVendorLeafletRoute {...props} data={{} as ILeafletType} params={{ vendorSlug: vendorSlug }} />
        }
    );

    return (
        <Comp {...props} />
    )
});