/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IPromotionworldType } from '../../types/IPromotionworldType';

export const PROMOTIONWORLD_FETCH_ENTRIES = '@BasePipeline/promotionworldReducer_FETCH_ENTRIES';
export const PROMOTIONWORLD_FETCH_ENTRY   = '@BasePipeline/promotionworldReducer_FETCH_ENTRY';
export const PROMOTIONWORLD_ADD_ENTRIES = '@BasePipeline/promotionworldReducer_ADD_ENTRIES';
export const PROMOTIONWORLD_ADD_ENTRY = '@BasePipeline/promotionworldReducer_ADD_ENTRY';

export const PROMOTIONWORLD_SET_ERRORS   = '@BasePipeline/promotionworldReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchPromotionworlds (queryKey: string) {
	return {
		type: PROMOTIONWORLD_FETCH_ENTRIES,
		queryKey,
	};
}

export function addPromotionworldEntries (queryKey: string, entries: IPromotionworldType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONWORLD_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addPromotionworldEntry (queryKey: string, entry: IPromotionworldType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONWORLD_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setPromotionworldErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONWORLD_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
