import * as React from 'react';
import { ReactNode, ReactNodeArray } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import appConfig from '../../../config/appConfig';

interface IRecaptchaProps {
	enable?: boolean;
	onVerify: (token: string) => void;
	children: ReactNode | ReactNodeArray;
	securityToken: string;
}

class Recaptcha extends React.PureComponent<IRecaptchaProps, {}> {
	public render() {
		const { enable, children, onVerify, securityToken } = this.props;

		if (!enable) {
			return (
				<div>
					{children}
				</div>
			);
		}

		return (
			<GoogleReCaptchaProvider
				reCaptchaKey={appConfig.recaptchaV3SiteKey}
				language="de"
			>
				{children}

				{securityToken === '' ? <GoogleReCaptcha onVerify={onVerify} /> : null}
			</GoogleReCaptchaProvider>
		);
	}
}

export default Recaptcha;
