import * as React from 'react';
import { withRouter } from 'react-router';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import { Helmet } from 'react-helmet-async';
import AppExclusive from '../App/modules/Base/components/AppExclusive/AppExclusive';

class AppExclusiveRoute extends BaseRoute {

	public render () {

		return (

			<React.Fragment>
				<Helmet>
					<link rel="stylesheet" href="https://endppostafassetsprod.azureedge.net/2.0.213/aficons/style.css" />
				</Helmet>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<AppExclusive />

						<Footer />
					</div>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(AppExclusiveRoute);