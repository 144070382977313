import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import { IRequestObject } from '../../../../../boilerplate/redux/types/IRequestObject';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	MIXED_SEARCH_FETCH_ENTRIES,
	addmixedSearchEntries,
	setmixedSearchErrors,
} from './mixedSearchActions';

/**
 * Fetch entry list
 */
function* fetchmixedSearchs(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const  { searchRequest, uniqueQueryIdentifier, ...getParams } = action.params;

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, getParams);

	const searchBody = {...searchRequest.body};
	const theParams = {...params, ...searchRequest.params};

	// add additional data to body, if we have it
	searchBody.lat = params.lat;
	searchBody.lng = params.lng;
	if (params.radius) { searchBody.radius = params.radius; }

	if (!searchBody.query) {
		return;
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.postRequest({
				url: '/api/public/v2/search',
				params: theParams,
				body: searchBody,
				options: {
					metaDataPath: ['content', 'searchResult'],
				}
			} as IRequestObject);
		});

		const { searchResult: results, ...searchMetaData } = response.getContent();
		const { content: searchResult, ...metaData } = results;


		yield put(addmixedSearchEntries(
			action.queryKey,
			searchBody.query,
			searchResult,
			response.getMetaData(),
			searchMetaData,
			action.options,
		));
	} catch (e) {
		yield put(setmixedSearchErrors(action.queryKey, e));
	}
}

export function* mixedSearchSaga() {
	yield takeEvery(MIXED_SEARCH_FETCH_ENTRIES, fetchmixedSearchs);
}
