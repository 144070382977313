import * as React from 'react';
import * as styles from '../AppExclusive.scss';
import H1 from '../../../../../components/Layout/H1/H1';
import RatingCard from './RatingCard/RatingCard';

class AppExclusiveRatings extends React.PureComponent {

    public render() {

        return (
            <div className={styles.ratingWrapper}>
                <H1>
                    Bewertungen
                </H1>
                <div className={styles.ratingCardWrapper}>
                    <span>
                        <RatingCard imageSrc={require('../assets/Rating.svg')} name='Lara B.' comment='Super easy zu nutzen. Sehr praktisch und hilfreich in diesen teuren Zeiten. 😄' />
                    </span>
                    <span>
                        <RatingCard imageSrc={require('../assets/Rating.svg')} name='Uwe S.' comment='Funktioniert super und ich hab alles was ich brauch!' />
                    </span>
                    <span>
                        <RatingCard imageSrc={require('../assets/Rating.svg')} name='Phil Re' comment='Super zum Angebote stöbern' />
                    </span>
                </div>
            </div>
        );
    }
}

export default AppExclusiveRatings;