import * as React from 'react';
import * as styles from './ProductPromotionItem.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';

interface IProductPromotionItemSkeletonProps {}

class ProductPromotionItemSkeleton extends React.Component<IProductPromotionItemSkeletonProps, {}> {
	public render () {

		return (
			<div className={styles.ProductPromotionGridItemWrapper}>
				<span className={styles.ProductPromotionGridItemWrapperInner}>
					<span className={styles.ImageWrapper}>
						<SkeletonBlock style={{height: '100%'}} />
					</span>

					<span className={styles.Content}>
						<span className={styles.Heading}>
							<SkeletonInline />
						</span>

						<span className={styles.ActionBar}>
							<span className={styles.ActionBarGrow} style={{display: 'flex'}}>
								<SkeletonInline />
							</span>
						</span>

						<span className={styles.ActionBar}>
							<span className={styles.ActionBarGrow}>
								
								<span className={`${styles.Line} ${styles.OldPrice} `}>
									<SkeletonInline />
								</span>
								<span className={`${styles.Line} ${styles.Price} `}><SkeletonInline /></span>
							</span>
							<span className={styles.ActionBarShrink}>
								<SkeletonInline />
							</span>
						</span>
					</span>
				</span>
			</div>
		);
	}
}

export default ProductPromotionItemSkeleton;


