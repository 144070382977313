import * as React from 'react';
import * as styles from './PromotionworldGridGrouped.scss';
import { IPromotionworldGridGroupType } from '../../types/IPromotionworldGridGroupType';
import PromotionworldGridGroupContainer from '../../containers/PromotionworldGridGroup/PromotionworldGridGroupContainer';
import { IFilterChip } from '../../../Tag/types/IFilterChip';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import { IPromotionworldType } from '../../types/IPromotionworldType';

export interface IPromotionworldGridGroupedProps {
	groups: IPromotionworldGridGroupType[],
	enablePagination?: boolean;
	moreLabel?: string,
	queryKey?: string;
	promotionworld: IPromotionworldType;
	activeTag?: IFilterChip;
	onPromotionClick: (promotion: IProductPromotionType) => void;
}

class PromotionworldGridGrouped extends React.PureComponent<IPromotionworldGridGroupedProps, {}> {
	public static defaultProps = {
		gridKey: 'default',
	};

	// TRANSLATE
	public render() {
		const {
			groups,
			moreLabel,
			promotionworld,
			queryKey,
			activeTag,
			onPromotionClick,
		} = this.props;
		
		const groupsSorted: IFilterChip[] = [...groups];

		// Moving the group with selected tag to the top of an array
		if (activeTag) {
			const index = groupsSorted.findIndex(group => group.title === activeTag.title);

			if (index > -1) {
				const [item] = groupsSorted.splice(index, 1);
				groupsSorted.unshift(item);
			}
		}

		return (
			<React.Fragment>
				{groupsSorted.map((productGroup: IPromotionworldGridGroupType, indx: number) =>  {
					if (productGroup.promotionCount === 0) {
						return null;
					}
					
					return (
						<div className={styles.promotionworldGridGroupWrapper} key={`product-grid-group-${queryKey}-${productGroup.id}`} id={`promotionworld-group-${productGroup.id}`}>
							<h2 className={styles.promotionworldGroupGroupedTitle}>
								{productGroup.title}
							</h2>
							<PromotionworldGridGroupContainer
								gridKey={`promotionworld-${promotionworld.leaflet.slug}-group-${productGroup.title}`}
								initialPromotions={productGroup.promotionWorldGroupPromotions}
								groupSlug={productGroup.id}
								promotionworld={promotionworld}
								moreLabel={moreLabel}
								totalItems={productGroup.promotionCount}
								limit="24"
								onPromotionClick={onPromotionClick}
							/>
						</div>
					)
				})}
				
				{/* TODO: Remove mock later and move the logic up */}
				{/* <div className={styles.promotionworldGridGroupWrapper} key={`product-grid-group-teaser`}>
					<h2 className={styles.promotionworldGroupGroupedTitle}>
						Image teaser mock group
					</h2>

					<PromotionworldGridGroup
						initialPromotions={[{id: 1},{id: 2}]}
						type='teaser'
					/>
				</div> */}
			</React.Fragment>
		);
	}
}

export default PromotionworldGridGrouped;
