import * as React from 'react';
import * as styles from './PromotionworldGridItem.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';

interface IPromotionworldGridItemSkeletonProps {}

class PromotionworldGridItemSkeleton extends React.Component<IPromotionworldGridItemSkeletonProps, {}> {
	public render () {

		return (
			<span className={styles.PromotionworldGridItemWrapper}>
				<span className={styles.PromotionworldGridItemWrapperInner} >
					<span className={styles.ImageWrapper}>
						<SkeletonBlock style={{height: '100%'}} />
					</span>

					<span className={styles.Content}>
						<span className={styles.Heading}>
							<SkeletonInline />
						</span>

						<span className={styles.ActionBar}>
							<span className={styles.ActionBarGrow}>
								<span className={`${styles.Line} ${styles.OldPrice} `}>
									<SkeletonInline />
								</span>

								<span className={`${styles.Line} ${styles.Price} `}>
									<SkeletonInline />
								</span>
							</span>
						</span>
					</span>
				</span>
			</span>
		);
	}
}

export default PromotionworldGridItemSkeleton;


