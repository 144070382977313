/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IShoppingCenterAggregatedByStateType } from "../../types/IShoppingCenterAggregateByStateType";
export const SHOPPING_CENTER_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES = '@BasePipeline/shoppingCenterAggregatedByStatesWidgetReducer_FETCH_ENTRIES';
export const SHOPPING_CENTER_AGGREGATED_BY_STATES_WIDGET_ADD_ENTRIES = '@BasePipeline/shoppingCenterAggregatedByStatesWidgetReducer_ADD_ENTRIES';
export const SHOPPING_CENTER_AGGREGATED_BY_STATES_WIDGET_SET_ERRORS = '@BasePipeline/shoppingcCenterAggregatedByStatesWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchShoppingCenterAggregatedByStatesWidgets(queryKey: string) {
	return {
		type: SHOPPING_CENTER_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addShoppingCenterAggregatedByStatesWidgetEntries(queryKey: string, entries: IShoppingCenterAggregatedByStateType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {

	return {
		type: SHOPPING_CENTER_AGGREGATED_BY_STATES_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setShoppingCenterAggregatedByStatesWidgetErrors(queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
	return {
		type: SHOPPING_CENTER_AGGREGATED_BY_STATES_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
