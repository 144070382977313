import * as React from 'react';
import { ReactNode } from 'react';
import Icon from '../../../Base/components/Icon/Icon';
import * as styles from './ModalListItem.scss';

interface IModalListItemProps {
	children: ReactNode | ReactNode[];
	onClick?: (item: any) => void;
	onClickItem?: any;
	active?: boolean;
	showIcon?: boolean;
	icon?: string;
}

class ModalListItem extends React.PureComponent<IModalListItemProps> {
	public render() {
		const { children, active, showIcon, icon } = this.props;

		return (
			<a
				href="#"
				onClick={this.onClick}
				className={`${styles.Link} ${(active) ? styles.LinkActive : ''}`}
			>
				<span className={styles.LinkWrapper}>

					{/* Icon is shown, if set it to true. Otherwise it will be false by default */}
					{showIcon && icon ? <Icon
						icon={icon}
						className={styles.ListItemIconName}
					/> : null}

					<span className={styles.LinkInner}>
						{children}
					</span>
					
				</span>
			</a>
		);
	}

	/**
	 * Handles the onclick event
	 */
	private onClick = () => {
		const { onClick, onClickItem } = this.props;

		if (!onClick) {
			return;
		}

		onClick(onClickItem);
	};
}

export default ModalListItem;
