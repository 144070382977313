import * as React from 'react';
import AdItem from '../../../../components/General/AdItem/AdItem';
import H1 from '../../../../components/Layout/H1/H1';
import MobileAppWidget from '../../../../components/Layout/MobileAppWidget/MobileAppWidget';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import NewsletterSignupComponent from '../../../Newsletter/components/Newsletter/NewsletterSignupComponent';
import TagCloudWidgetContainer from '../../../Tag/containers/TagCloudWidget/TagCloudWidgetContainer';
import ProductPromotionGridGroupedContainer
	from '../../containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import PromotionsAggregatedByBrandsWidgetContainer
	from '../../containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer
	from '../../containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import PromotionsAggregatedByStatesWidgetContainer
	from '../../containers/PromotionsAggregatedByStatesWidget/PromotionsAggregatedByStatesWidgetContainer';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';
import { IPromotionsAggregatedByStateType } from '../../types/IPromotionsAggregatedByStateType';
import ProductGroupListWidgetContainer from '../../../Category/containers/ProductGroupListWidgetContainer/ProductGroupListWidgetContainer';
import { IProductGroupType } from '../../../Category/types/IProductGroupType';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import StaticWidgetContainer from '../../../Base/containers/StaticWidget/StaticWidgetContainer';
import { FavouritePromotions, FavouritePromotionsByVendor } from '../../../Base/data/staticWidgetData';

class PromotionListingPage extends React.Component<IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	/**
	 * Defines the route the tag list widget should be routed towards
	 *
	 * @param productGroup
	 */
	private static routerCallbkPromotionGridMoreLink(productGroup: IProductPromotionGroupedType) {
		if (productGroup.detailUrlPath) {
			return productGroup.detailUrlPath;
		}

		return {
			name: 'ProductGroupDetailRoute',
			params: {
				productGroup: productGroup.group.detailUrlPath,
			},
		};
	}

	/**
	 * Defines the route the tag list widget should be routed towards
	 *
	 * @param item
	 */
	private static routerCallbkTagCloudWidget(item: ITagWidgetItemType) {
		return {
			name: 'TagDetailRoute',
			params: {
				tag: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 * @param item
	 */
	private static routerCallbkProductGroupListWidget(item: IPromotionsAggregatedByProductGroupType) {
		return {
			href: item.group.detailUrlPath,
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private static routerCallbkStateListWidget(item: IPromotionsAggregatedByStateType) {
		return {
			name: 'PromotionsInStateRoute',
			params: {
				state: item.group.slug,
			},
		};
	}



	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private static routerCallbkBrandListWidget(item: IPromotionsAggregatedByBrandType) {
		return {
			name: 'BrandDetailRoute',
			params: {
				brand: item.group.slug,
			},
		};
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Aktionen',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 */
	private routerCallbkProductGroupListWidget = (item: IProductGroupType) => {
		if (item.detailUrlPath) {
			return item.detailUrlPath;
		}

		return {
			name: 'ProductGroupDetailRoute',
			params: {
				productGroup: item.slug,
			},
		};
	}

	public render() {
		const { routeMetadata } = this.props;

		if (!routeMetadata) {
			return null;
		}

		return (

			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				{/* <AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/> */}

				<H1>{routeMetadata.pageTitle}</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox boxed={true} nested={true}>

							<ProductPromotionGridGroupedContainer
								limit={5}
								enablePagination={true}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),

									2: (
										<NewsletterSignupComponent />
									),
									4: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									),
								}}
								moreLabel="Weitere %title% Aktionen"
								moreRouteCallbk={PromotionListingPage.routerCallbkPromotionGridMoreLink}
								pushRouteMetadata={true}
								gridKey={routeMetadata.queryKey}
							>
								<NoDataError />
							</ProductPromotionGridGroupedContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<ProductGroupListWidgetContainer
								title="Verfeinere deine Auswahl"
								widgetKey={`category-widget-posting-listing`}
								routeCallbk={this.routerCallbkProductGroupListWidget}
							/>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey="promotion-aggregated-productGroups-widget"
								limit={10}
								title="Beliebte Produktkategorien"
								routeCallbk={PromotionListingPage.routerCallbkProductGroupListWidget}
								onlyNearby={true}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<TagCloudWidgetContainer
								title="Beliebte Themen"
								widgetKey={`specials-tag-cloud`}
								routeCallbk={PromotionListingPage.routerCallbkTagCloudWidget}
							/>

							<PromotionsAggregatedByBrandsWidgetContainer
								widgetKey="promotion-aggregated-brands-widget"
								limit={10}
								title="Marken in Aktion"
								routeCallbk={PromotionListingPage.routerCallbkBrandListWidget}
							/>

							{/* <PromotionsAggregatedByStatesWidgetContainer
								widgetKey="promotions-aggregated-states-widget"
								title="Aktionen nach Bundesland"
								routeCallbk={PromotionListingPage.routerCallbkStateListWidget}
							/> */}

							<StaticWidgetContainer
								title="Beliebte Aktionen"
								data={FavouritePromotions}
								widgetKey="favourite-promotions-promotion-listing"
							/>

							<StaticWidgetContainer
								title="Beliebte Händler-Aktionen"
								data={FavouritePromotionsByVendor}
								widgetKey="favourite-promotions-by-vendor-promotion-listing"
							/>

							<MobileAppWidget />

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}
}

export default PromotionListingPage;
