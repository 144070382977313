import geolocationConfig from '../../../config/geolocationConfig';
import CookieService from '../../../services/CookieService';
import { IGeolocationType } from '../types/IGeolocationType';

declare const geoip2: any;

let currentGeolocation = {} as IGeolocationType;

let isInitialized = false;

const COOKIE_EXPIRY_SHORT = 60 * 2;
const COOKIE_EXPIRY_MEDIUM = 60 * 24 * 3;
const COOKIE_EXPIRY_LONG = 60 * 24 * 100;
export default class GeolocationService {
    /**
     * Initialized the geolocation and returns a promise
     */
    public static async initGeolocationAsync(): Promise<IGeolocationType> {
        return new Promise((resolve, reject) => {
            GeolocationService.initGeolocation(
                (geolocation: IGeolocationType) => {
                    resolve(geolocation);
                },
                (error: any) => {
                    reject(error);
                },
            );
        });
    }

    /**
     * Initialized the geolocation and returns a promise
     */
    public static initFromSSR(currentLocation: IGeolocationType) {
        currentGeolocation = currentLocation;
        isInitialized = true;
    }

    /**
     * Returns whether Geolocation is inited or not
     */
    public static isInitialized(): boolean {
        return isInitialized;
    }

    /**
     * Tries to get the user geolocation by first using browser position, and if this fails it will use maxdatas web service
     */
    public static initGeolocation(successCallbk: any, errorCallbk: any = null) {
        const savedLocation = CookieService.get('loc');

        // if we have a location saved -> used that
        if (savedLocation) {
            currentGeolocation = savedLocation;
            isInitialized = true;

            successCallbk(GeolocationService.checkBoundaries(currentGeolocation), null);
            return;
        }

        if (typeof navigator !== 'undefined' && 'geolocation' in navigator) {
            // check if geolocation is supported/enabled on current browser
            navigator.geolocation.getCurrentPosition(
                function success(position) {
                    const result = {
                        label: 'Aktueller Standort', // TODO: TRANSLATE
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    } as IGeolocationType;

                    currentGeolocation = result;
                    isInitialized = true;
                    successCallbk(GeolocationService.checkBoundaries(result), 'short');
                },
                function error(errorMessage) {
                    isInitialized = true;

                    // for when getting location results in an error
                    GeolocationService.getGeolocationFromMaxData(successCallbk, errorCallbk);
                },
            );
        } else {
            // geolocation is not supported
            // get your location some other way
            console.log('geolocation is not enabled on this browser');
            isInitialized = true;
            GeolocationService.getGeolocationFromMaxData(successCallbk, errorCallbk);
        }
    }

    /**
     * Returns current geolocation
     */
    public static getCurrentLocation(): IGeolocationType {
        return currentGeolocation;
    }

    /**
     * Returns a unique current geolocation key
     */
    public static getCurrentLocationKey(): string {
        return currentGeolocation.lat + '-' + currentGeolocation.lng;
    }

    /**
     * Sets the current Geolocation
     */
    public static setCurrentLocation(newLocation: IGeolocationType, expiry: 'short' | 'medium' | 'long' = 'long') {
        currentGeolocation = newLocation;
        GeolocationService.saveLocation(newLocation, expiry);
    }

    /**
     * Saves the location to cookies to be able to use it later
     *
     * @param geolocation
     * @param expiry
     */
    public static saveLocation(geolocation: IGeolocationType, expiry: 'short' | 'medium' | 'long' | null = 'long') {
        let expireTime = 60;

        if (expiry === null) {
            return;
        }

        switch (expiry) {
            case 'long':
                expireTime = COOKIE_EXPIRY_LONG;
                break;
            case 'medium':
                expireTime = COOKIE_EXPIRY_MEDIUM;
                break;
            default:
                expireTime = COOKIE_EXPIRY_SHORT;
        }

        CookieService.set('loc', JSON.stringify(geolocation), expireTime);
    }

    /**
     * Adds location coordinates to the params object
     *
     * @param {IGeolocationType} geolocation
     * @param {[key: string]: any} params
     * @param {number} geoPrecision Should the geodata be shortened for caching reasons?
     *
     * @return {{lat: number, lng: number} & {[p: string]: any}}
     */
    public static addGeolocationParams(geolocation: IGeolocationType, params: { [key: string]: any } = {}, geoPrecision: number = 2) {
        const returnParams = Object.assign({}, params);

        const geo = currentGeolocation ? currentGeolocation : geolocation;

        if (!returnParams.lng) {
            returnParams.lng = this.shortenLocationParam(geo.lng, geoPrecision);
        }
        if (!returnParams.lat) {
            returnParams.lat = this.shortenLocationParam(geo.lat, geoPrecision);
        }

        // make sure we got at least a fallback
        if (!returnParams.lat || isNaN(returnParams.lat)) {
            returnParams.lat = geolocationConfig.defaultLocation.lat;
        }
        if (!returnParams.lng || isNaN(returnParams.lng)) {
            returnParams.lng = geolocationConfig.defaultLocation.lng;
        }

        return returnParams;
    }

    /**
     * Shortens a lat or long value to geoPrecision
     *
     * @param latOrLng
     * @param geoPrecision
     */
    public static shortenLocationParam(latOrLng: number, geoPrecision: number = 2): number {
        const commaMover = Math.pow(10, geoPrecision);

        if (geoPrecision === 0) {
            Math.round(latOrLng);
        }

        return geoPrecision < 7 ? Math.round(latOrLng * commaMover) / commaMover : latOrLng;
    }

    /**
     * Gets the user's geolocation using maxdata's webservice
     */
    private static getGeolocationFromMaxData(successCallbk: any, errorCallbk: any) {
        // if no geoip2 loaded -> just go directly for next test
        if (typeof geoip2 === 'undefined' || !geoip2 || !geoip2.city) {
            currentGeolocation = geolocationConfig.fallbackLocation;
            successCallbk(GeolocationService.checkBoundaries(currentGeolocation), 'medium');
            return;
        }

        geoip2.city(
            (geoIpResult: any) => {
                const location = geoIpResult && geoIpResult.location ? geoIpResult.location : {};
                const postal = geoIpResult && geoIpResult.postal && geoIpResult.postal.code ? geoIpResult.postal.code : geolocationConfig.defaultPostalcode;
                const city = geoIpResult && geoIpResult.city && geoIpResult.city.names && geoIpResult.city.names.de ? geoIpResult.city.names.de : geoIpResult.city.names.en; // TRANSLATE - Set correct locale
                const result = {
                    label: `${postal} ${city}`,
                    lat: location.latitude,
                    lng: location.longitude,
                } as IGeolocationType;

                currentGeolocation = result;
                successCallbk(GeolocationService.checkBoundaries(result), 'medium');
            },
            (error: any) => {
                if (errorCallbk) {
                    errorCallbk(error);
                }
            },
        );
    }

    private static checkBoundaries = (result: IGeolocationType): IGeolocationType => {
        const borderLatMin = 46.33;
        const borderLatMax = 49.04;
        const borderLngMin = 9.37;
        const borderLngMax = 17.12;
        const borderExcludeLatMax = 48.95;
        const borderExcludeLatMin = 47.79;
        const borderExcludeLngMax = 12.76;
        const borderExcludeLngMin = 9.42;

        if (result.lat < borderLatMax && result.lat > borderLatMin && result.lng > borderLngMin && result.lng < borderLngMax) {
            if (result.lat < borderExcludeLatMax && result.lat > borderExcludeLatMin && result.lng > borderExcludeLngMin && result.lng < borderExcludeLngMax) {
                return geolocationConfig.fallbackLocation;
            }
            return result;
        } else {
            return geolocationConfig.fallbackLocation;
        }
    };
}
