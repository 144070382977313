import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as homepageReducers from './components/General/Homepage/reducers';
import * as accountReducers from './modules/Account/reducers';
import * as baseReducers from './modules/Base/reducers';
import * as bookmarkReducers from './modules/Bookmark/reducers';
import * as brandReducers from './modules/Brand/reducers';
import * as categoryWidgetReducers from './modules/Category/reducers';
import * as contentFragmentReducers from './modules/ContentFragment/reducers';
import * as demoReducers from './modules/Example/reducers';
import * as leafletWidgetReducers from './modules/Leaflet/reducers';
import * as lotteryReducers from './modules/Lottery/reducers';
import * as newsletterReducers from './modules/Newsletter/reducers';
import * as promotionReducers from './modules/Promotion/reducers';
import * as searchReducers from './modules/Search/reducers';
import * as shoppingCenterReducers from './modules/ShoppingCenter/reducers';
import * as tagWidgetReducers from './modules/Tag/reducers';
import * as themeworldReducers from './modules/Themeworld/reducers';
import * as vendorReducers from './modules/Vendor/reducers';
import * as promotionworldReducers from './modules/Promotionworld/reducers';
import * as carouselReducers from './modules/Carousel/store/Carousel/carouselReducer';
import * as mixedEntityReducers from './modules/MixedEntity/store/mixedEntity/mixedEntityReducer';
import { appProps } from './modules/Base/store/App/appPropsReducer';

const createRootReducer = (history) =>
    combineReducers({
        appProps: appProps,
        router: connectRouter(history),
        ...demoReducers,
        ...brandReducers,
        ...vendorReducers,
        ...baseReducers,
        ...themeworldReducers,
        ...promotionReducers,
        ...tagWidgetReducers,
        ...leafletWidgetReducers,
        ...categoryWidgetReducers,
        ...homepageReducers,
        ...searchReducers,
        ...shoppingCenterReducers,
        ...contentFragmentReducers,
        ...accountReducers,
        ...newsletterReducers,
        ...lotteryReducers,
        ...bookmarkReducers,
        ...promotionworldReducers,
        ...carouselReducers,
        ...mixedEntityReducers,
    });

export default createRootReducer;
