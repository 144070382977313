/* ### CONSTANTS ### */

import { IGeneralStateType } from "./generalReducer";

export const GENERAL_SET_DATA = '@BasePipeline/generalReducer_SET_DATA';

/* ### ACTIONS ### */

export function setGeneral(newData: IGeneralStateType) {
	return {
		type: GENERAL_SET_DATA,
		newData,
	};
}
