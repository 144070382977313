import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import VendorDetail from '../../components/VendorDetail/VendorDetail';
import { IVendorType } from '../../types/IVendorType';
import { connect } from 'react-redux';

interface IVendorDetailProps {
	vendor: string;
	queryKey: string;
}

class VendorDetailContainerInner extends React.Component<IVendorDetailProps & IWithDataProps> {
	public render() {
		const { data, vendor, ...restProps } = this.props;

		return (
			<VendorDetail
				vendor={data as IVendorType}
				vendorSlug={vendor}
				{...restProps}
			/>
		);
	}
}


function mapStateToProps (state: any) {
	return {
		shopGroupedByStateReducer: state.shopGroupedByStateReducer,
	};
}

const VendorDetailContainerShoploader = connect(mapStateToProps)(VendorDetailContainerInner);

export default function VendorDetailContainer(props: IVendorDetailProps) {
	const RenderComponent = withData(
		VendorDetailContainerShoploader,
		'vendorReducer',
		`vendor-detail-${props.vendor}`,
		{
			vendor: props.vendor,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'vendor',
			LoadingComponent: () => (
				<VendorDetail
					vendorSlug={props.vendor}
				/>
			)
		},

	);

	return <RenderComponent {...props} />
}

