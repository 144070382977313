import * as React from 'react';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import * as styles from './LeafletMenu.scss';
import Overlay from '../../../Base/components/Overlay/Overlay';
import { ILeafletPageType } from '../../types/ILeafletPageType';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ILeafletType } from '../../types/ILeafletType';
import ShopListWidgetContainer from '../../../Vendor/containers/ShopListWidgetContainer/ShopListWidgetContainer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Animated } from 'react-animated-css';
import ProductPromotionGridContainer from '../../../Promotion/containers/ProductPromotionGrid/ProductPromotionGridContainer';
import { goToLeafletViewerPage } from '../../store/LeafletViewer/leafletViewerActions';
import Scroller from '../../../../components/Layout/Scroller/Scroller';

interface ILeafletMenuPropsType {
    openMenuItem: string;
    leaflet: ILeafletType;
    onCloseMenu: (item: any) => void;
    pages: ILeafletPageType[];
    goToPage?: (pageNumber: number) => void,
    leafletViewerUiState?: { [key: string]: any };
}

class LeafletMenu extends React.Component<ILeafletMenuPropsType, {}> {
	public static defaultProps = {
      openMenuItem: '',
  };

	public render() {
		const { openMenuItem, pages, leaflet, leafletViewerUiState } = this.props;

		const activeSlide = (leafletViewerUiState && leafletViewerUiState.slideStates && leafletViewerUiState.slideStates[leafletViewerUiState.currentSlide]) ? leafletViewerUiState.slideStates[leafletViewerUiState.currentSlide] : {};
    const activePages = (activeSlide.pagesOnSlide) ? activeSlide.pagesOnSlide : [];
    const activePageMap = {};
    for (const page of activePages) {
      activePageMap[page] = 1;
    }

		return (
            <React.Fragment>
                <TransitionGroup className={styles.leafletMenuWrapper}>
                    <CSSTransition 
                        timeout={{
                            enter: 3000,
                            exit: 1000,
                        }}
                        // classnames="leaflet-menu-animation"
                        >
                        
                        <Animated
                            animationIn="slideInUp"
                            animationOut="slideOutDown"
                            isVisible={openMenuItem !== ''}
                            className={styles.leafletMenuAnimation}
                            animationInDuration={600}
                            animationOutDuration={600}
                        >
                            <div className={styles.leafletMenuContainer}>
                              {( !openMenuItem ) ? null :
                                <div className={styles.leafletMenuClose} onClick={this.onCloseMenu}>
                                    SCHLIESSEN
                                </div>
                              }
                                <div className={styles.leafletMenuInnerContainer}>

                                    {(openMenuItem === "pages") ? 
                                        <React.Fragment>

                                            <span className={styles.leafletMenuTitle}>
                                                Seitenübersicht
                                            </span>

                                            <span className={styles.leafletMenuImageWrapper}>

                                                <Scroller height={240}>

                                                    {pages.map((page: ILeafletPageType, indx: number) => {
                                                        const isActive = activePageMap[indx];

                                                        return (
                                                            <a
                                                                key={`leaflet-viewer-page-overview-${indx}`}
                                                                onClick={() => this.goToPage((indx + 1))}
                                                                className={`${styles.leafletMenuImage} ${(isActive) ? styles.leafletMenuImageActive : ''}`}
                                                            >
                                                                <ImageEnhanced
                                                                    alt={page.title}
                                                                    source={page.resolutions}
                                                                    size={'m'}
                                                                    type='lazy'
                                                                />

                                                                <span className={styles.leafletMenuImageTitle}>Seite {indx + 1}</span>
                                                            </a>
                                                        )
                                                    })}
                                                </Scroller>

                                            </span>
                                        
                                        </React.Fragment>

                                    : null }
                                    
                                    {(openMenuItem === "shops") ? 
                                        <React.Fragment>

                                            <span className={styles.leafletMenuTitle}>
                                                In deiner Nähe
                                            </span>

                                            <ShopListWidgetContainer
                                                widgetKey={`leaflet-page-${leaflet.slug}-shops-nearby`}
                                                orderBy="distance"
                                                orderDir="desc"
                                                limit={10}
                                                widgetType="leaflet"
                                                leafletSlug={leaflet.slug}
                                            />

                                        </React.Fragment>
                                    : null}

                                    {(openMenuItem === "promotions") ? 
                                        <React.Fragment>

                                            <span className={styles.leafletMenuTitle}>
                                                Top Aktionen in diesem Prospekt
                                            </span>

                                            <ProductPromotionGridContainer
                                                limit={40}
                                                gridKey={`leaflet-viewer-promotions-${leaflet.slug}`}
                                                leafletSlug={leaflet.slug}
                                                onlyNearbyPromotions={false}
                                            />
                                            
                                        </React.Fragment>
                                    : null}

                                </div>

                            </div>
                        </Animated>
                    </CSSTransition>
                </TransitionGroup>

                {(openMenuItem === '') ? null :
                    <Overlay onClick={this.onCloseMenu} />
                }

            </React.Fragment>
		);
    }
    
    /**
	 * Closes menu
	 */
	private onCloseMenu = () => {
        const { onCloseMenu } = this.props;
        
        onCloseMenu("")
    };
    
    /**
     * Changes the slide number
     */
    private goToPage = (slide: number) => {
        const { goToPage } = this.props;

        goToPage(slide);
        this.onCloseMenu();
    }
}

function mapStateToProps(state: any) {
	return {
		leafletViewerUiState: state.leafletViewer,
	};
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
		goToPage: (pageNumber: number) => {
			dispatch(goToLeafletViewerPage(pageNumber));
		},
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(LeafletMenu);
