/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';

export const PROMOTIONS_AGGREGATED_BY_BRANDS_WIDGET_FETCH_ENTRIES = '@BasePipeline/promotionsAggregatedByBrandsWidgetReducer_FETCH_ENTRIES';
export const PROMOTIONS_AGGREGATED_BY_BRANDS_WIDGET_ADD_ENTRIES = '@BasePipeline/promotionsAggregatedByBrandsWidgetReducer_ADD_ENTRIES';

export const PROMOTIONS_AGGREGATED_BY_BRANDS_WIDGET_SET_ERRORS   = '@BasePipeline/promotionsAggregatedByBrandsWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchPromotionsAggregatedByBrandsWidgets (queryKey: string) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_BRANDS_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addPromotionsAggregatedByBrandsWidgetEntries (queryKey: string, entries: IPromotionsAggregatedByBrandType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {

	return {
		type: PROMOTIONS_AGGREGATED_BY_BRANDS_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setPromotionsAggregatedByBrandsWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_BRANDS_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
