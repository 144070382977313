import { ActionType } from 'typesafe-actions';
import { baseInitialState } from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import LeafletBaseReducer from '../LeafletBaseReducer';
import * as leafletActions from './leafletActions';
import { ILeafletType } from '../../types/ILeafletType';

export type LeafletGridAction = ActionType<typeof leafletActions>;

export interface ILeafletStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: ILeafletType };
	readonly queries: { [queryKey: string]: ILeafletType[] };
	readonly showLeafletDiscountModal: boolean;
	readonly discountLeaflet: ILeafletType;
}

const defaultState = {
	showLeafletDiscountModal: false,
	discountLeaflet: {},
};

export function leafletReducer(
	state = {...baseInitialState, ...defaultState} as ILeafletStateType,
	action: LeafletGridAction,
) {
	const Reducer = new LeafletBaseReducer('leafletReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {

		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			case 'SHOW_LEAFLET_DISCOUNT_MODAL':
				return Object.assign({}, state, {
					showLeafletDiscountModal: true,
				});
		
			case 'HIDE_LEAFLET_DISCOUNT_MODAL':
				return Object.assign({}, state, {
					showLeafletDiscountModal: false,
					discountLeaflet: {},
				});

			case 'SET_LEAFLET_DISCOUNT_DATA': 
				return Object.assign({}, state, {
					discountLeaflet: action.discountLeaflet,
				});

			default:
				return innerState;
		}
	});
}
