import { SlugType } from '../../../Base/types/SlugType';
import { BookmarkEntityType } from '../../types/BookmarkEntityType';

/* ### CONSTANTS ### */

export const BOOKMARK_FETCH_ENTRIES = '@BasePipeline/bookmarkReducer_FETCH_ENTRIES';
export const BOOKMARK_SYNC_LOCAL_ENTRIES = '@BasePipeline/bookmarkReducer_SYNC_LOCAL_ENTRIES';
export const BOOKMARK_ADD_ENTRY = '@BasePipeline/bookmarkReducer_ADD_ENTRY';
export const BOOKMARK_REMOVE_ENTRY = '@BasePipeline/bookmarkReducer_REMOVE_ENTRY';
export const BOOKMARK_RESET = '@BasePipeline/bookmarkReducer_RESET';

export const BOOKMARK_SET_ERRORS   = '@BasePipeline/bookmarkReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchBookmarks () {
	return {
		type: BOOKMARK_FETCH_ENTRIES,
	};
}


/* ### ACTIONS ### */

export function syncLocalBookmarks (remoteBookmarks: any) {
	return {
		type: BOOKMARK_SYNC_LOCAL_ENTRIES,
		remoteBookmarks,
	};
}

export function addBookmarkEntry (entity: BookmarkEntityType, slug: SlugType) {
	return {
		type: BOOKMARK_ADD_ENTRY,
		entity,
		slug,
	};
}

export function removeBookmarkEntry (entity: BookmarkEntityType, slug: SlugType) {
	return {
		type: BOOKMARK_REMOVE_ENTRY,
		entity,
		slug,
	};
}

export function resetBookmarks () {
	return {
		type: BOOKMARK_RESET,
	};
}

export function setBookmarkErrors () {
	return {
		type: BOOKMARK_SET_ERRORS,
	};
}
