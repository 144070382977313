import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import { IShopType } from '../../types/IShopType';
import { IVendorType } from '../../types/IVendorType';
import Distance from '../Distance/Distance';
import * as styles from './NearestShopWidget.scss';
import OpeningHours from '../OpeningHours/OpeningHours';
import Link from '../../../Base/components/Link/Link';


interface INearestShopWidgetProps {
	widgetKey: string,
	shop: IShopType,
	title?: string,
	vendor: IVendorType,
	size: 's' | 'l',
}


class NearestShopWidget extends React.Component<INearestShopWidgetProps, {}> {

	public static defaultProps = {
		size: 'l',
	};

	public render() {
		const { shop, vendor, size } = this.props;

		const shopVendor = (shop && shop.vendor) ? shop.vendor : (vendor) ? vendor : {} as IVendorType;

		return (
			<div className={`${styles.Container} ${styles[size + 'ContainerSize']}`} >
				<div className={styles.Store}>
					{(!shopVendor.resolutions) ? null :
						<div className={styles.Image}>
							<ImageEnhanced
								source={shopVendor.resolutions}
								size={'s'}
								alt={`${shopVendor.name} Logo`}
							/>
						</div>
					}

					<div>
						<div className={styles.ShopName}>

							{(!shop) ? null :
								<Link
									route="ShopDetailRoute"
									params={{
										shop: shop.slug
									}}
								>
									<a>{shop.title}</a>
								</Link>
							}

							{(!vendor) ? null :
								<Link
									route="VendorDetailRoute"
									params={{
										vendor: vendor.slug
									}}
								>
									<a>{vendor.name}</a>
								</Link>
							}

						</div>

						{(!shop) ? null :
							<div className={styles.Info}>
								<div>{shop.address}{shop.address && (shop.zipCode || shop.city) && ','} {shop.zipCode} {shop.city}</div>
								<OpeningHours mode="next" shop={shop} />
							</div>
						}
					</div>
				</div>
				<div className={styles.Place}>
					{(!shop || !shop.distance) ? null :
						<span>
							<Distance
								distanceInMeters={shop.distance}
							/>
							<span className={styles.distanceDivider}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
							<a href={this.getGoogleNavigationLink()}>zur Karte</a>
						</span>
					}
				</div>
			</div>
		)
	}

	/**
	 * Returns a link that points to googles navigation service
	 *
	 * @return {string}
	 */
	private getGoogleNavigationLink(): string {
		const { shop } = this.props;

		return `https://maps.google.com/maps?&q=${shop.latitude},${shop.longitude}&hl=de&t=h&z=12&t=m`;
	}
}
export default NearestShopWidget;
