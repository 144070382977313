import * as React from 'react';
import * as styles from './MixedEntityItem.scss';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import OpeningHours from '../../../Vendor/components/OpeningHours/OpeningHours';
import Distance from '../../../Vendor/components/Distance/Distance';
import Link from '../../../Base/components/Link/Link';
import { IMixedEntityType } from '../../types/IMixedEntityType';

interface IMixedEntityStoreItemProps {
	data: IMixedEntityType;
	columns?: '1' | '2' | '3' | '4' | '5';
}

class MixedEntityStoreItem extends React.Component<IMixedEntityStoreItemProps, {}> {
	public render () {
		const { data, columns } = this.props;

		return (
			<Link
				route="ShopDetailRoute"
				params={{
					shop: data.slug,
				}}
			>
				<span
					className={`${styles.MixedEntityItemWrapper} ${styles['MixedEntityItemWrapperColumns' + columns]}`}
				>
					<span
						className={styles.MixedEntityItemWrapperInner}
					>
						<span className={styles.ImageWrapper}>
							<ImageEnhanced
								 source={data.image}
								 bgImage={true}
							/>
						</span>

						<span className={styles.Content}>
							<span className={styles.Heading}>
								{data.title}
								{/* // if title is too long -> show fade out */}
								{ (data.title?.length < 55) ? null :
									<span className={styles.HeadingFadeout} />
								}
							</span>

							<span className={styles.Line}>{data.address}</span>
							<span className={styles.Line}>{data.storePostalCode} {data.storeCityName}</span>

							<span className={styles.OpeningHours}>
								<OpeningHours
									shop={data}
									mode="next"
								/>
							</span>

							<span className={styles.ActionBar}>
								<span className={styles.ActionBarGrow}>
									<Distance distanceInMeters={data.distance} />
								</span>
							</span>
						</span>
					</span>
				</span>
			</Link>
		);
	}

}


export default MixedEntityStoreItem;


