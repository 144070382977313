import { call, put, takeEvery, select } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addShoppingCenterGroupedEntries,
	setShoppingCenterGroupedErrors,
	SHOPPING_CENTER_GROUPED_FETCH_ENTRIES,
} from './shoppingCentersGroupedActions';
import RouteMetadataService from '../../../../services/RouteMetadataService';
import { IRouteMetadataType } from '../../../../modules/Base/types/IRouteMetadataType';
import GeolocationService from '../../../Base/services/GeolocationService';

/**
 * Fetch entry list
 */
function* fetchShoppingCentersGrouped(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const paramsWithoutCoords = afCoreRequest.mergeParams({
		page: 0,
		size: 30,
	}, action.params);

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, paramsWithoutCoords);

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: `/api/public/v1/shopping-centers/grouped`,
					params,
				} as IRequestObject);
			},
		);

		const content = response.getContent('content');

		// put route metadata
		if (action.params.pushRouteMetadata) {
			let totalItemsCount = 0;

			for (let i = 0; i < content.length; i++) {
				totalItemsCount += content[i].totalItemsCount;
			}

			RouteMetadataService.setMetadata(action.queryKey, {
				totalItemsCount,
			} as IRouteMetadataType);
		}

		yield put(
			addShoppingCenterGroupedEntries(
				action.queryKey,
				content,
				response.getMetaData(),
				action.options
			),
		);
	} catch (e) {
		yield put(setShoppingCenterGroupedErrors(action.queryKey, e));
	}
}

export function* shoppingCenterGroupedSaga() {
	yield takeEvery(SHOPPING_CENTER_GROUPED_FETCH_ENTRIES, fetchShoppingCentersGrouped);
}
