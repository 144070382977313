import * as React from 'react';
import Scroller from '../../../../components/Layout/Scroller/Scroller';
import * as styles from './FilterChipsListing.scss';
import { IFilterChip } from '../../types/IFilterChip';

export interface IFilterChipsListingProps {
	tags: IFilterChip[];
    activeTag: IFilterChip;
    onActiveTagChange: (item: IFilterChip | null) => void;
    allLabel?: string;
}

class FilterChipsListing extends React.Component<IFilterChipsListingProps, {}> {
	public static defaultProps = {};

	public render() {
		const { tags, activeTag, onActiveTagChange, allLabel } = this.props;

        const tagsSorted: IFilterChip[] = [...tags];

        // Moving active tag to the first place after "All"
        if (activeTag) {
            const index = tagsSorted.findIndex(group => group.title === activeTag.title);
            
            if (index > -1) {
                const [item] = tagsSorted.splice(index, 1);
                tagsSorted.unshift(item);
            }
        }

		return (
            <div className={styles.filterChipsContainer}>
                <div className={styles.filterChipsWrapper}>
                    <Scroller height={45}>
                        <React.Fragment>
                            {
                                !allLabel ? null : (
                                    <button
                                        className={`${!activeTag?.id ? styles.active : ''}`}
                                        key={`filter-chip-all-${allLabel}`}
                                        onClick={() => onActiveTagChange(null)}
                                    >
                                        { allLabel }
                                    </button>
                                )
                            }
                            {
                                tagsSorted.map((tag: IFilterChip, idx: number) => (
                                    <button
                                        className={`${tag.id === activeTag?.id ? styles.active : ''}`}
                                        key={`filter-chip-${tag.id}-${idx}`}
                                        onClick={() => onActiveTagChange(tag)}
                                    >
                                        {tag.title}
                                    </button>
                                ))
                            }
                        </React.Fragment>
                    </Scroller>
                </div>
            </div>
		);
	}
}

export default FilterChipsListing;
