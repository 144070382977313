import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import LotteryList from '../../components/LotteryList/LotteryList';
import { ILotteryType } from '../../types/ILotteryType';
import LotteryListSkeleton from '../../components/LotteryList/LotteryListSkeleton';

interface ILotteryListProps {
	queryKey?: string;
	moreRoute?: string;
	moreLabel?: string;
	columns?: '2' | '3';
	title?: string;
	boxed?: boolean;
}

class LotteryListContainerInner extends React.Component<ILotteryListProps & IWithDataProps> {
	public render() {
        const { data, ...restProps } = this.props;
		
		return (
			<LotteryList
				lotteries={data as ILotteryType[]}
				{...restProps}
			/>
		);
	}
}

export default function LotteryListContainer(props: ILotteryListProps) {
	const RenderComponent = withData(
		LotteryListContainerInner,
		'lotteryListReducer',
		props.queryKey || 'lottery-list-container',
		{
			
		},
		{
			LoadingComponent: () => <LotteryListSkeleton {...props} />
		}
	);

	return <RenderComponent {...props} />
}

