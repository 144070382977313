import * as React from 'react';
import * as styles from './LeafletImagePlaceholder.scss';

interface ILeafletImagePlaceholderProps {
  className?: string,
  style?: any,
  alt: string,
  activatedPlaceholder?: boolean;
}

class LeafletImagePlaceholder extends React.Component<ILeafletImagePlaceholderProps, {}> {
  public static defaultProps = {
    size: 'm',
  };

  public render() {
    const { className, activatedPlaceholder, ...restProps } = this.props;

    let theClassName = styles.ResponsivePlaceholder;

    if (className) {
      theClassName += ` ${className}`;
    }

    if (activatedPlaceholder) {
      theClassName += ` ${styles.PlaceholderActivated}`;
    }

    return (
      <figure className={styles.PlaceholderWrapper}>
        <img className={theClassName} src={require('./leafletImagePlaceholder.png')} {...restProps} />
      </figure>
    );
  }
}

export default LeafletImagePlaceholder;
