import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './Skeleton.scss';

interface IColProps {
	children?: ReactChild,
	minWidth?: number,
	maxWidth?: number,
	style?: any,
	noBorderRadius?: boolean,
}

class SkeletonBlock extends React.PureComponent<IColProps, {}> {
	public render () {
		const { children, noBorderRadius, style } = this.props;
		const styleObj: any = style || {};

		const blockWidth = this.getRandomSkeletonWidth();
		if (blockWidth) {
			styleObj.width = blockWidth;
		}

		let classString = '';

		if (noBorderRadius) {
			classString = ` ${styles.noBorderRadius}`;
		}

		return (
			<div className={`${styles.skeletonBlock} ${classString}`} style={styleObj}>
				{children} &nbsp;
			</div>
		);
	}

	/**
	 * Returns either a random or one of the given width values as percentage string
	 *
	 * @returns {string | null}
	 */
	private getRandomSkeletonWidth (): string | null {
		const { minWidth, maxWidth } = this.props;

		if (!minWidth && !maxWidth) {
			return null;
		}

		if (minWidth && maxWidth) {
			return this.getRandomInt(minWidth, maxWidth) +'%';
		}

		if (minWidth) {
			return minWidth +'%';
		}

		return maxWidth +'%';
	}

	/**
	 * Returns a random integer between min and max
	 *
	 * @param {number} min
	 * @param {number} max
	 *
	 * @return {number}
	 */
	private getRandomInt(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
}

export default SkeletonBlock;
