import * as React from 'react';
import * as styles from './ProductPromotionImageModal.scss';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import ProductImagePlaceholder from '../ProductImagePlaceholder/ProductImagePlaceholder';
import Button from '../../../../components/General/Button/Button';

interface IProductPromotionImageModalPropsType {
    onCloseModal?:() => void;
    data: any;
}

const ProductPromotionImageModal = (props: IProductPromotionImageModalPropsType) => {

    const { data, onCloseModal } = props;

    const mainImage = data.source || data.imageUrl;

    return (
        <div className={styles.productPromotionImageModalWrapper}>

            <div className={styles.productPromotionModalImageWrapper}>

                <span className={styles.imageItem}>
                    <ImageEnhanced
                        size="l"
                        source={mainImage ? {} : data.resolutions}
                        sourceOriginal={mainImage as string}
                        alt={data.fullDisplayName}
                    >
                        <ProductImagePlaceholder
                            alt={data.fullDisplayName}
                        />
                    </ImageEnhanced>

                </span>
            </div>

            <div className={styles.productPromotionModalSide}>
                <h4>
                    {data.fullDisplayName}
                </h4>

                <Button
                    theme="primary"
                    size="xl"
                    onClick={onCloseModal}
                >
                    Fenster schliessen
                </Button>
            </div>

        </div>
    );

}

export default ProductPromotionImageModal;
