import * as React from 'react';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import * as styles from './PromotionworldGridGrouped.scss';
import PromotionworldGridGroupSkeleton from './PromotionworldGridGroupSkeleton';

export interface IPromotionworldGridGroupedSkeletonProps { }

class PromotionworldGridGroupedSkeleton extends React.PureComponent<IPromotionworldGridGroupedSkeletonProps, {}> {
	public static defaultProps = { };

	public render () {
		const groups = Array.from(Array(3).keys());

		return (
			groups.map((item: number) =>  {
				return (
					<div className={styles.promotionworldGridGroupWrapper} key={`product-grid-group-${item}`}>
						<h2 className={styles.promotionworldGroupGroupedTitle}>
							<SkeletonInline />
						</h2>
						<PromotionworldGridGroupSkeleton />
					</div>
				)
			})		
		);
	}
}

export default PromotionworldGridGroupedSkeleton;
