import * as React from 'react';
import * as styles from './ThemeworldCards.scss';
import ContentBox from '../../../../components/Layout/PageWrapper/ContentBox';
import ThemeworldCardItemSkeleton from '../ThemeworldCardItem/ThemeworldCardItemSkeleton';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';


interface IThemeworldCardsProps {
    title?: string;
    widgetKey?: string;
    moreLabel?: string;
    moreRoute?: string;
    promotionLabel?: string;
    promotionLabelPlural?: string;
    columns?: '2' | '3';
    firstCardFullWidth?: boolean;
    boxed?: boolean;
}

class ThemeworldCardsSkeleton extends React.PureComponent<IThemeworldCardsProps, {}> {
    public static defaultProps = {
        widgetKey: 'themeworlds-card-widget',
        columns: '2',
        firstCardFullWidth: true,
    };

    public render() {
        const { title, columns, firstCardFullWidth, boxed } = this.props;

        let className = `${styles.themeworldCardItemsContainer} ${styles['themeworldCardItemsContainerColumns-' + columns]}`;

        if (firstCardFullWidth) {
            className += ` ${styles.themeworldCardFirstCardFullWidth}`
        }

        if (boxed) {
            className += ` ${styles.themeworldCardBoxed}`
        }

        const fakeArray = Array(8).fill(' ');

        return (
            <ContentBox title={title} nested={true}>
                <div>
                    <div className={className}>
                        <div className={styles.headingSkeleton} >
                            <SkeletonBlock style={{ width: 100, height: 20 }} />
                            <SkeletonBlock style={{ width: 200, height: 15, marginTop: 10 }} />
                        </div>
                        {fakeArray.map((_, index) => {

                            return (
                                <React.Fragment key={index}>
                                    <ThemeworldCardItemSkeleton />
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </ContentBox>
        );
    }

}

export default ThemeworldCardsSkeleton;
