// @flow
import * as React from 'react';
import { IWithDataProps } from '../../../../boilerplate/redux/types/IWithDataProps';
import { ICarouselTeaser } from '../types/CarouselTeaser/ICarouselTeaser';
import withData from '../../../../boilerplate/redux/hocs/withData';
import CarouselTeaserListing from '../components/CarouselTeaserListing';
import SkeletonBlock from '../../../components/Layout/Skeleton/SkeletonBlock';

interface ICarouselContainerProps extends IWithDataProps {
    data?: ICarouselTeaser[];
}

class CarouselContainerInner extends React.Component<ICarouselContainerProps> {
    public render() {
        const { data, metadata } = this.props;
        return <CarouselTeaserListing teasers={data} demoQueryMetadata={metadata} />;
    }
}

export default function CarouselContainer(props: ICarouselContainerProps) {
    const RenderComponent = withData(
        CarouselContainerInner,
        'carouselReducer',
        'some-unique-key',
        {},
        {
            LoadingComponent: () => <SkeletonBlock style={{paddingBottom: '48%'}} />,
            additionalStateProperties: ['updateQuery'],
        },
    );

    return <RenderComponent {...props} />;
}
