import * as React from 'react';
import * as styles from './VendorList.scss';
import VendorListItemSkeleton from './VendorListItemSkeleton';

class VendorListSkeleton extends React.Component<{}, {}> {
  public static defaultProps = {
  };

  public render () {
    return (
        <div className={styles.vendorListWrapper}>
          {[0,1,2,3,4,5,6,7,8].map(vendor => {
            return (
              <div className={styles.vendorListItemWrapper} key={`vendor-list-skeleton-${vendor}`}>
                <VendorListItemSkeleton />
              </div>
            )
          })}
        </div>
    );
  }
}

export default VendorListSkeleton;
