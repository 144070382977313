import * as React from 'react';
import * as styles from './ForgottenPassword.scss';
import ForgottenPasswordService from '../../services/ForgottenPasswordService';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import InputField from '../../../../components/Layout/Form/InputField';
import Button from '../../../../components/General/Button/Button';
import AlertService from '../../../Base/services/AlertService';

interface IForgottenPasswordProps {
    email: string,
    loading: boolean,
    success: boolean
}

class ForgottenPassword extends React.Component<{}, IForgottenPasswordProps> {

    constructor(props: IForgottenPasswordProps) {
        super(props);

        this.state = {
            email: '',
            loading: false,
            success: false,
        };
    }

    public render() {
        const { loading, success } = this.state;

        if (success) {
            return (
                <PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
                    <H1 align="center">
                        Vielen Dank
                    </H1>

                    <FormWrapper>

                        <p>
                            Wir haben dir weitere Informationen zum Zurücksetzen deines Passworts per E-Mail zugesandt. Bitte prüfe auch deinen Spam-Ordner solltest du die Nachricht nicht sofort erhalten
                        </p>

                    </FormWrapper>
                </PageWrapper>
            );
        }

        return (
            <PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
                <H1 align="center">
                    Passwort vergessen
				</H1>

                <FormWrapper
                    onSubmit={this.onSubmit}
                >

                    <InputField
                        title="E-Mail"
                        name="email"
                        placeholder="email@example.com"
                        type="email"
                        onChange={this.onChangeInput}
                        value={this.state.email}
                        required={true}
                    />

                    <p>&nbsp;</p>

                    <Button
        theme="primary"
                        size="xxl"
                        fullWidth={true}
                        type="submit"
                        loading={loading}
                    >
                        Absenden
                    </Button>

                    <Button
        theme="default"
                        size="xxl"
                        fullWidth={true}
                        loading={loading}
                        route="LoginRoute"
                    >
                        Abbrechen
                    </Button>

                </FormWrapper>
            </PageWrapper>
        );
    }

    private onSubmit = () => {

        const { email } = this.state;

        if (email && email !== '' && !/\S+@\S+\.\S+/.test(email)) {
            AlertService.error(
                'error-email',
                'Nicht gültiges E-Mail-Feld.',
            );
            return
        }

        this.setState({ loading: true });

        const data = { email };

        ForgottenPasswordService.sendEmail(data, (response: any) => {

            this.setState({
                loading: false,
                success: true,
            });

        }, (error: any) => {

            this.setState({ loading: false });

            AlertService.error(
                'error-' + error,
                error,
            );
        });
    }

    private onChangeInput = (name: string, value: string | boolean): void => {
        this.setState({
            [name]: value
        });
    }

	/**
	 * Returns the breadcrumb path for this page
	 */
    private getBreadcrumbs() {
        return [
            {
                label: 'Passwort vergessen',
            } as IBreadcrumbItemType,
        ];
    }
}

export default ForgottenPassword;
