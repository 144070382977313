import queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isBrowser, isServer } from '../../razzle/razzleUtils';
import ReducerManipulator from '../ReducerManipulator';
import { IWithDataOptions } from '../types/IWithDataOptions';
import Error from '../../../App/components/Layout/Error/Error';
import LoadingIndicator from '../../../App/components/Layout/LoadingIndicator/LoadingIndicator';
import DataLayerService from '../../../App/modules/Base/services/DataLayerService';
import { getRouteAndObject } from '../../../server/addCorrectResponseHeaders';
import { Component } from 'react';
import { LeafletViewerType } from '../../../App/modules/Leaflet/hocs/withLeaflet';
// import { IWithDataProps } from '../types/IWithDataProps';
/*

interface IWithDataInnerComponentProps extends IWithDataProps {
	withDataChildComponent: any;
	fetchEntries: Function;
	fetchEntry: Function;
}
*/

interface ErrorComponentPropType {
    errorComponent: () => Component;
    statusCode: string;
    errors?: string[];
    appProps?: any;
    metadata?: any;
}

/**
 * Wraps the error component and pushes the data to google analytics
 * 
 * @param props 
 * @returns 
 */
const ErrorComponentWrapper = (props: any) => {
    const ErrorComponent = props.errorComponent;
    const screenData = getRouteAndObject();

    React.useEffect(() => {
        const statusCode = props?.metadata?.errors?.[0]?.status ?? '000';
        DataLayerService.push(`ERROR_SCREEN`, {
            screenName: screenData?.name ?? '(unknown)',
            route: screenData?.syntax ?? (isBrowser() ? window.location.pathname : 'unknown'),
            path: props?.appProps?.requestUrl ?? (isBrowser() ? window.location.pathname : 'unknown'),
            errorCode: statusCode,
        });
    }, ['']);

    return <ErrorComponent {...props} />;
};

const defaultWithDataReducerOptions = {
    LoadingComponent: () => <LoadingIndicator />,
    showLoading: true,
    ErrorComponent: (props: ErrorComponentPropType) => {
        return (
            <Error title="Ups! Es ist ein Fehler aufgetreten">
                Das hätte nicht passieren sollen, aber wir kümmern uns so schnell wie möglich darum. <br />Bitte versuche es später erneut!
            </Error>
        );
    },
    showError: true,
    fetchOnInit: true,
};

class InnerComponent extends React.Component<any, {}> {
    public constructor(props: any) {
        super(props);

      const { withDataOptions } = this.props;

        if (withDataOptions.fetchOnInit &&
            ( !this.props.metadata || ( !this.props.metadata.loading && !this.props.metadata.done ) )) {
            this.fetch();
        } else if (withDataOptions.fetchOnInit && isBrowser() &&
            this.props.metadata && this.props.metadata.loading && this.props.metadata.requestOrigin === 'server') {
            // if the loading started on server, but hasn't finished before output to client -> reload again on client
            this.fetch();
        } else if (withDataOptions.fetchOnInit && isBrowser() && this.props.metadata && this.props.metadata.reloadOnClient) {
            // if we want this data to reload on the client, although it already has been loaded on the client (caching reasons)
            this.fetch({ reloadOnClient: true });
        }
    }

    public render() {
        const {
                  fetchEntries,
                  fetchEntry,
                  withDataChildComponent,
                  ...restProps
              }                             = this.props;
        const { withDataOptions, metadata } = this.props;

        // display loading screen
        if (this.showErrorComponent()) {
            const ErrorComponent = withDataOptions.ErrorComponent;
            return <ErrorComponentWrapper {...restProps} errors={metadata.errors} errorComponent={withDataOptions.ErrorComponent} />;
        }

        // display loading screen
        if (this.showLoadingComponent()) {
            const LoadingComponent = withDataOptions.LoadingComponent;

            return <LoadingComponent {...restProps} />;
        }

        const WrappedComponent = this.props.withDataChildComponent;

        return (
            <WrappedComponent
                fetch={this.fetch}
                fetchMore={this.fetchMore}
                refetch={this.refetch}
                {...restProps}
            />
        );
    }

    /**
     * Returns whether the loading component is visible right now
     */
    private showLoadingComponent(): boolean {
        const { metadata, withDataOptions } = this.props;

        if (metadata && metadata.reloadOnClient) {
          return false;
        }

        if (!withDataOptions.showLoading) {
            return false;
        }

        if (metadata && metadata.backgroundLoading) {
            return false;
        }

        if (!metadata ||
            ( typeof metadata.loading === 'undefined' && !metadata.success ) ||
            metadata.loading) {

            return true;
        }

        return false;
    }

    /**
     * Returns whether the error component is visible right now
     */
    private showErrorComponent(): boolean {
        const { metadata, withDataOptions } = this.props;

        if (!withDataOptions.showError) {
            return false;
        }

        if (metadata && metadata.errors) {
            return true;
        }
        return false;
    }

    /**
     * Calls the correct fetch action, depending on whether this is a single or multi fetch
     */
    private fetch = (options?: any) => {
        const {
                  fetchEntry,
                  fetchEntries,
                  queryKey,
                  params,
                  withDataOptions,
              } = this.props;

        if (withDataOptions.isSingleEntry) {
            fetchEntry(queryKey, params, options);
            return;
        }

        fetchEntries(queryKey, this.addPageNumberInUrlToParams(params), options);
    };

    /**
     * Takes the params, we are working with and adds the page number to request, if set via url
     * (this is mostly done in "no javascript" mode, when infinite loading is disabled or with search engines
     */
    private addPageNumberInUrlToParams(params: any) {
        const { appProps } = this.props;

        // get correct url
        let requestUrl = '';
        if (isServer()) {
            requestUrl = appProps.requestUrl;
        } else if (typeof window !== 'undefined') {
            requestUrl = window.location.href;
        }

        // split the path and query part apart
        const requestSplit = requestUrl.split('?');
        const urlParams    = ( requestSplit[ 1 ] ) ? queryString.parse(requestSplit[ 1 ]) : {};

        // if we have a page number, add it
        if (urlParams.page) {
            params.page = ( parseInt(urlParams.page + '') - 1 );
        }

        return params;
    }

    /**
     * Calls the correct fetch more action, depending on whether this is a single or multi fetch
     */
    private fetchMore = () => {
        const {
                  fetchEntries,
                  queryKey,
                  params,
                  withDataOptions,
                  metadata,
              } = this.props;

        // use different offset
        params.page = ( metadata.lastOffset + 1 );

        fetchEntries(this.props.queryKey, params, {
            queryMergeMode: 'add',
        } as IFetchOptionType);
    };

    /**
     * Calls the correct refetch action, depending on whether this is a single or multi fetch
     */
    private refetch = () => {
        const {
                  fetchEntries,
                  queryKey,
                  params,
                  withDataOptions,
                  metadata,
              } = this.props;

        // use different offset

        fetchEntries(this.props.queryKey,
                     this.addPageNumberInUrlToParams(params), {
                         queryMergeMode: 'replace',
                         forceRefetch: true,
                     } as IFetchOptionType);
    };
}

function isCallbk(functionToCheck: any) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

function withData(
    WrappedComponent: React.ComponentType,
    reducerKey: string,
    queryKey: string | ( (props: any) => string )                                   = 'default',
    params: { [ key: string ]: any } | ( (props: any) => { [ key: string ]: any } ) = {},
    options: IWithDataOptions | ( (props: any) => IWithDataOptions )                = {},
) {
    const opts = Object.assign({}, defaultWithDataReducerOptions, options);

    function mapStateToProps(state: any, ownPropsRaw: { [ key: string ]: any }) {
        const ownProps = Object.assign({}, ownPropsRaw, params);

        let optsObject = opts;
        if (isCallbk(opts)) {
            optsObject = opts(ownProps);
        }

        let queryKeyName = queryKey;
        if (isCallbk(queryKey)) {
            queryKeyName = queryKey(ownProps);
        }

        let paramsObject = params;
        if (isCallbk(params)) {
            paramsObject = params(ownProps);
        }

        const dataState = state[ reducerKey ];

        let data     = null;
        let metadata = null;

        if (!dataState) {
            metadata = ReducerManipulator.defaultQueryMetadata;
            console.warn(`Reducer ${reducerKey} was not found in reducer index. Make sure you have added it to the projects reducer list.`);
        } else if (optsObject.isSingleEntry) {
            const primaryKeyName = ( optsObject.primaryKeyName ) ? optsObject.primaryKeyName : 'id';
            const entryMapKey    = ownProps[ primaryKeyName ];

            if (typeof entryMapKey === 'undefined') {
                console.warn(`WithData ${queryKeyName} HOC: Couldn't get a valid entryMapKey. Expected a prop "${primaryKeyName}. If you want to use a different key for props, define it using "primaryKeyName" in options. Got the following props:`, ownProps);
            }

            // if we got only a single entry, don't use the queries object, but get it from entry map and create a fake metadata object
            data = dataState.queries[queryKeyName];

            if (!dataState.queryMetadata[ queryKeyName ]) {
                metadata = Object.assign(
                    {},
                    ReducerManipulator.defaultQueryMetadata,
                    {
                        count: data ? 1 : 0,
                        done: !!data,
                        loading: ( data && data.loading ),
                        success: !!data,
                        totalCount: data ? 1 : 0,
                    },
                );
            } else {
                metadata = dataState.queryMetadata[ queryKeyName ];
            }
        } else {
            data     = dataState.queries[ queryKeyName ];
            metadata = dataState.queryMetadata[queryKeyName];
        }

        // define the data keyname
        const dataKey = ( opts.dataKey ) ? opts.dataKey : 'data';


        const retObj = {
          [ dataKey ]: data,
          metadata,
          params: paramsObject,
          updateQuery: dataState.updateQuery,
          queryKey: queryKeyName,
          withDataChildComponent: WrappedComponent,
          withDataOptions: optsObject,
          appProps: state.appProps,
        };


        // additional data
        if (opts.additionalStateProperties) {
          for (const additionalKey of opts.additionalStateProperties) {
            if (dataState[additionalKey]) {
              retObj[additionalKey] = dataState[additionalKey];
            }
          }
        }

        return retObj;
    }

    function mapDispatchToProps(dispatch: (actionType: any) => void, ownProps: { [ key: string ]: any }) {
        let optsObject = opts;
        if (isCallbk(opts)) {
            optsObject = opts(ownProps);
        }

        return mapDispatchToPropsBase(
            dispatch,
            reducerKey,
            optsObject ? optsObject.actions : null,
        );
    }

    const withConnect = connect(
        mapStateToProps,
        mapDispatchToProps,
    );

    return compose(withConnect)(InnerComponent);
}

function mapDispatchToPropsBase(
    dispatch: any,
    reducerKey: string,
    actionTypes: any = {},
) {
    return {
        fetchEntries: (queryKey: string, params: { [ key: string ]: any }, options: IFetchOptionType) => {
            dispatch({
                         params,
                         options,
                         queryKey,
                         type: `@BasePipeline/${reducerKey}_FETCH_ENTRIES`,
                     });
        },
        fetchEntry: (queryKey: string, params: { [ key: string ]: any }, options?: { [ key: string ]: any }) => {
            dispatch({
                         params,
                         queryKey,
                         options,
                         type: `@BasePipeline/${reducerKey}_FETCH_ENTRY`,
                     });
        },
        ...actionTypes,
    };
}

export default withData;
