import { IProductPromotionGroupedType } from 'App/modules/Promotion/types/IProductPromotionGroupedType';
import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import ProductPromotionGridGroupedContainer from '../../../Promotion/containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import ShopLocationMapWidget from '../../../Vendor/components/ShopLocationMapWidget/ShopLocationMapWidget';
import { IShoppingCenterType } from '../../types/IShoppingCenterType';
import * as styles from './ShoppingCenterDetail.scss';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IShoppingCenterProps {
	data: IShoppingCenterType;
}

class ShoppingCenterDetail extends React.Component<IShoppingCenterProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { data, routeMetadata } = this.props;

		if (!routeMetadata) {
			return;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403495}
					maxHeight={250}
					type="billboard"
				/>

				<H1 title={routeMetadata.pageTitle}>
					{data.description}
				</H1>

				<Row>
					<AppMainCol boxed={false}>
						<AppMainColBox
							boxed={true}
							nested={true}
						>

							<ProductPromotionGridGroupedContainer
								shoppingCenterSlug={data.slug}
								limitItemsPerGroup={6}
								limit={12}
								onlyNearbyPromotions={false}
								enablePagination={true}
								moreLabel="Weitere %title% Aktionen"
								moreRouteCallbk={this.routerCallbkPromotionGridMoreLink}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								useLeafletRoute={false}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403482}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403481}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridGroupedContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={false}>
						<div>

							<ShopLocationMapWidget
								title={data.title}
								center={{
									lat: data.latitude,
									lng: data.longitude,
								}}
								description={`${data.address} \n ${data.city.name}`}
							>
								<span className={styles.mapDescription}>
									{ (data.address) ? <span>{data.address}, </span> : null }
		
									{ (data.zipCode) ? <span>{data.zipCode} </span> : null } 
									{ (data.city && data.city.name) ? <span>{data.city.name}</span> : null }<br/>
			
									{ (data.website) ? <a href={data.website} target="_blank" rel="nofollow noopener noreferrer">{data.website}</a> : null }<br/>
							
									{ (data.phone) ? <a href={`tel:${data.phone}`} target="_blank" rel="nofollow noopener noreferrer">{data.phone}</a> : null }<br/>
								</span>

							</ShopLocationMapWidget>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								title={`Beliebte Produktkategorien in ${data.title}`}
								widgetKey={`vendor-product-group-cloud-${data.slug}`}
								limit={10}
								shoppingCenterSlug={data.slug}
								routeCallbk={this.routerCallbkProductGroupListWidget}
							/>

							<AdItem
								desktopAdId={4403494}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Einkaufszenten',
				route: 'ShoppingCenterListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.shoppingCenter.title : '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		const { data } = this.props;

		return {
			name: 'PromotionsInShoppingCenterAndProductGroupRoute',
			params: {
				productGroup: item.group.slug,
				shoppingCenter: data.slug,
			},
		};
	};

	/**
	 * Defines the route the tag list widget should be routed towards
	 *
	 * @param productGroup
	 */
	private routerCallbkPromotionGridMoreLink = (productGroup: IProductPromotionGroupedType) => {
		return {
			name: 'ProductGroupDetailDestRoute',
			params: {
				productGroup: productGroup.group.slug,
			},
		};
	}
}

export default ShoppingCenterDetail;