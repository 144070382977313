import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { IShoppingCenterAggregatedByStateType } from '../../types/IShoppingCenterAggregateByStateType';

export interface IShoppingCenterAggregatedByStatesWidgetProps {
	states: IShoppingCenterAggregatedByStateType[];
	title: string;
	widgetKey?: string;

	routeCallbk: (item: IShoppingCenterAggregatedByStateType) => { name: string, params: { [key: string]: any } },
}

class ShoppingCenterAggregatedByStatesWidget extends React.Component<IShoppingCenterAggregatedByStatesWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'states-list-widget',
	};

	public render() {
		const { states, title, widgetKey, routeCallbk } = this.props;

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					{states.map((state: IShoppingCenterAggregatedByStateType) => {
						const route = routeCallbk(state);

						return (
							<WidgetListItemLinkRow
								title={state.federalState.name}
								subtitle={this.normalizeTitle(state.shoppingCenterCount)}
								key={`${widgetKey}-${state.federalState.slug}`}
								route={route.name}
								routeParams={route.params}
							/>
						);
					})}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}

	/**
	 * Pluralizer and normalizer of widget title
	 */
	private normalizeTitle(shoppingCenterCount: number) {
		if (shoppingCenterCount === 1) {
			return `${shoppingCenterCount} Einkaufszentrum`;
		}

		return `${shoppingCenterCount} Einkaufszentren`
	}
}

export default ShoppingCenterAggregatedByStatesWidget;
