import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	addVendorBookmarkEntries,
	setVendorBookmarkErrors, VENDOR_BOOKMARK_FETCH_ENTRIES,
} from './vendorBookmarkActions';

/**
 * Fetch entry list
 */
function* fetchVendorBookmarks(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	// add geolocations
	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: '/api/public/v1/personal-list/vendor/detail',
				params,
			} as IRequestObject);
		});

		yield put(addVendorBookmarkEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setVendorBookmarkErrors(action.queryKey, e));
	}
}

export function* vendorBookmarkSaga() {
	yield takeEvery(VENDOR_BOOKMARK_FETCH_ENTRIES, fetchVendorBookmarks);
}
