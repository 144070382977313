/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';

export const PRODUCT_PROMOTION_STATUS_FETCH_ENTRY = '@BasePipeline/productPromotionStatusReducer_FETCH_ENTRY';
export const PRODUCT_PROMOTION_STATUS_FETCH_ENTRIES = '@BasePipeline/productPromotionStatusReducer_FETCH_ENTRIES';
export const PRODUCT_PROMOTION_STATUS_ADD_ENTRIES = '@BasePipeline/productPromotionStatusReducer_ADD_ENTRIES';
export const PRODUCT_PROMOTION_STATUS_ADD_ENTRY = '@BasePipeline/productPromotionStatusReducer_ADD_ENTRY';

export const PRODUCT_PROMOTION_STATUS_SET_ERRORS = '@BasePipeline/productPromotionStatusReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function addPromotionsStatusEntry(queryKey: string, entries: IProductPromotionStatusType, metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: PRODUCT_PROMOTION_STATUS_ADD_ENTRY,
        queryKey,
        entry: {
            ...entries,
            slug: queryKey,
        },
        metaData,
        options,
    };
}
export function setPromotionErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PRODUCT_PROMOTION_STATUS_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
