import * as React from 'react';
import { ComponentType } from 'react';
import withData from '../../../../boilerplate/redux/hocs/withData';
import { SlugType } from '../../Base/types/SlugType';
import ProductPromotionDetailSkeleton from '../components/ProductPromotionDetail/ProductPromotionDetailSkeleton';
import ProductPromotionDetailContainer from '../containers/ProductPromotionDetail/ProductPromotionDetailContainer';
import { isBrowser } from '../../../../boilerplate/razzle/razzleUtils';

export default function withPromotion (WrapperComponent: ComponentType<any>, promotionSlug: SlugType, theProps: any) {
	const props = Object.assign({
		size: 'm',
	}, theProps);

	const RenderComponent = withData(
		WrapperComponent,
		'productPromotionReducer',
		`product-promotion-detail-${promotionSlug}`,
		{
			promotion: promotionSlug,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'promotion',
			dataKey: 'promotion',

			LoadingComponent: () => {
				if (isBrowser()) {
					return (
						<ProductPromotionDetailSkeleton />
					);
				}

				return (
					<ProductPromotionDetailContainer
						promotionSlug={promotionSlug}
						promotion={null}
					/>
				);
			}
		}
	);

	return (
		<RenderComponent {...props} />
	);
}
