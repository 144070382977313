import { SHOW_OVERLAY, HIDE_OVERLAY, SHOW_LOCATION_MODAL, HIDE_LOCATION_MODAL } from './modalActions';

export interface IModalStateType {
	readonly showOverlay: boolean;
	readonly showLocationModal: boolean;
}

const defaultModalState = {
	showOverlay: false,
	showLocationModal: false,
};

export function modalReducer(
	state = defaultModalState as IModalStateType,
	action: any,
) {
	switch (action.type) {

		case SHOW_OVERLAY:
			return Object.assign({}, state, {
				showOverlay: true,
			});

		case HIDE_OVERLAY:
			return Object.assign({}, state, {
				showOverlay: false,
			});

		case SHOW_LOCATION_MODAL:
			return Object.assign({}, state, {
				showLocationModal: true,
			});

		case HIDE_LOCATION_MODAL:
			return Object.assign({}, state, {
				showLocationModal: false,
			});

		default:
			return state;
	}
}
