import * as React from 'react';
import * as styles from './AdvantageCard.scss';

interface IAppExclusiveCardItemProps {
  imageSrc?: string;
  title: string;
}

class AdvantageCard extends React.Component<IAppExclusiveCardItemProps, {}> {

  public render() {
    const { title, imageSrc } = this.props;

    return (
      <span className={styles.AppCardContainer}>
        <img src={imageSrc} alt="Vorteile" className={styles.CardImage} />
        <div className={styles.CardDescription}>Aktionsfinder App Vorteile</div>
        <div className={styles.CardTitle}>{title}</div>
      </span>
    );
  }
}

export default AdvantageCard;
