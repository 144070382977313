import { call, put, takeEvery, select } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addPromotionsAggregatedByBrandsWidgetEntries,
	PROMOTIONS_AGGREGATED_BY_BRANDS_WIDGET_FETCH_ENTRIES, setPromotionsAggregatedByBrandsWidgetErrors,
} from './promotionsAggregatedByBrandsWidgetActions';
import GeolocationService from '../../../Base/services/GeolocationService';

/**
 * Fetch entry list
 */
function* fetchPromotionsAggregatedByBrandsWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	let params = action.params;

	if (params.nearby_only) {
		const geolocationReducer = yield select((state: any) => state.geolocationReducer);
		params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: '/api/public/v1/widgets/brands',
					params,
				} as IRequestObject
			);
		});

		const theContent = response.getContent('content');

		yield put(addPromotionsAggregatedByBrandsWidgetEntries(
			action.queryKey,
			theContent,
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionsAggregatedByBrandsWidgetErrors(action.queryKey, e));
	}
}


export function* promotionsAggregatedByBrandsWidgetSaga() {
	yield takeEvery(PROMOTIONS_AGGREGATED_BY_BRANDS_WIDGET_FETCH_ENTRIES, fetchPromotionsAggregatedByBrandsWidget);
}
