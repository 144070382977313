import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataOptions } from '../../../../../boilerplate/redux/types/IWithDataOptions';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import PromotionworldGridGrouped from '../../components/ProductPromotionGridGrouped/PromotionworldGridGrouped';
import { IFilterChip } from '../../../Tag/types/IFilterChip';
import PromotionworldGridGroupedSkeleton from '../../components/ProductPromotionGridGrouped/PromotionworldGridGroupedSkeleton';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import { IPromotionworldType } from '../../types/IPromotionworldType';

interface IPromotionworldGridGroupedContainerProps {
	gridKey?: string;

	promotionworld: IPromotionworldType;
	groupSlug?: string;
	promotionCount?: string;
	withImages?: boolean;
	
	moreLabel?: string;
	
	children?: React.ReactNode;  // if this is given, this will be shown if the array doesn't return any results
	activeTag?: IFilterChip;
	onPromotionClick: (promotion: IProductPromotionType) => void;
}

class PromotionworldGridGroupedContainerInner extends React.Component<IPromotionworldGridGroupedContainerProps & IWithDataProps, {}> {
	public static defaultProps = {};

	public render () {
		const { data, children, ...restProps } = this.props;

		return (
			<PromotionworldGridGrouped
				groups={data}
				{...restProps}
			>
				{children}
			</PromotionworldGridGrouped>
		);
	}
}

export default function PromotionworldGridGroupedContainer (theProps: IPromotionworldGridGroupedContainerProps) {
	const props = Object.assign({
		limit: 10,
		orderBy: 'relevance',
		orderDir: 'desc',
		onlyNearbyPromotions: true,
		withImages: true,
	}, theProps) as IPromotionworldGridGroupedContainerProps;

	const RenderComponent = withData(
		PromotionworldGridGroupedContainerInner,
		'promotionworldGroupedReducer',
		props.gridKey,
		{
			leafletSlug: props.promotionworld.leaflet.slug,
			groupSlug: props.groupSlug,
			promotionCount: props.promotionCount,
			withImages: props.withImages,
		},
		{
			LoadingComponent: () => (
				<PromotionworldGridGroupedSkeleton />
			),
		} as IWithDataOptions
	);

	return <RenderComponent {...props} />;
}
