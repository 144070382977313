/* ### CONSTANTS ### */

export const SHOW_OVERLAY = '@BasePipeline/modalReducer_SHOW_OVERLAY';
export const HIDE_OVERLAY  = '@BasePipeline/modalReducer_HIDE_OVERLAY';
export const SHOW_LOCATION_MODAL = '@BasePipeline/modalReducer_SHOW_LOCATION_MODAL';
export const HIDE_LOCATION_MODAL  = '@BasePipeline/modalReducer_HIDE_LOCATION_MODAL';

/* ### ACTIONS ### */

export function hideOverlay () {
	return {
		type: HIDE_OVERLAY,
	};
}

export function showOverlay () {
	return {
		type: SHOW_OVERLAY,
	};
}

export function hideLocationModal () {
	return {
		type: HIDE_LOCATION_MODAL,
	};
}

export function showLocationModal () {
	return {
		type: SHOW_LOCATION_MODAL,
	};
}