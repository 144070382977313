import * as React from 'react';
import { IFederalStateType } from '../../../Geographic/types/IFederalStateType';
import * as styles from './ShoppingCenterGroup.scss';
import ShoppingCenterListItem from '../ShoppingCenterListItem/ShoppingCenterListItem';
import { IShoppingCenterType } from '../../types/IShoppingCenterType';

interface IShoppingCenterGroupedProps {
	shoppingCenters: IShoppingCenterType[],
	state: IFederalStateType,
	widgetKey?: string,
}

class ShoppingCenterGroup extends React.Component<IShoppingCenterGroupedProps, {}> {
	public static defaultProps = {
		widgetKey: 'default',
	};

	public render () {
		const { shoppingCenters, widgetKey } = this.props;

		return (
			<React.Fragment>
				{shoppingCenters.map((shoppingCenter: IShoppingCenterType) => (
					<div className={styles.shoppingCenterGroupWrapper} key={`shops-grid-list-${widgetKey}-${shoppingCenter.slug}`}>

						<ShoppingCenterListItem shoppingCenter={shoppingCenter}/>

					</div>
				))}
			</React.Fragment>
		);
	}
}

export default ShoppingCenterGroup;
