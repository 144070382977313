import * as React from 'react';
import { ReactNode } from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Link from '../../../Base/components/Link/Link';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';
import * as styles from './ThemeworldCardItem.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';


interface IThemeworldCardItemProps {
    themeworld: IThemeworldType,
    children?: ReactNode,
    subtitle?: string,
    columns?: '2' | '3';
    firstCardFullWidth?: boolean;
    themeworldIndex?: number;
}

class ThemeworldCardItemSkeleton extends React.Component<IThemeworldCardItemProps> {
    public static defaultProps = {

    };

    public render() {
        const { columns, firstCardFullWidth, themeworldIndex } = this.props;

        let className = `${styles.themeworldCardItem} ${styles['themeworldCardItemColumns-' + columns]}`;

        if (firstCardFullWidth && themeworldIndex === 0) {
            className += ` ${styles.themeworldCardFirstItem}`
        }
        return (

            <a className={className}>
                <span className={styles.themeworldCardItemTextContainer}>
                    <SkeletonBlock style={{ width: 200, height: 80 }} />
                    <span className={styles.themeworldCardItemContent}>
                        <SkeletonInline />
                    </span>
                </span>
            </a>

        );
    }
}

export default ThemeworldCardItemSkeleton;
