import * as React from 'react';
import * as styles from './NotFound.scss';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import Error from '../../../../components/Layout/Error/Error';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import AdItem from '../../../../components/General/AdItem/AdItem';

class NotFound extends React.PureComponent<{}> {

    public render() {
        const links = [
            {route: "HomeRoute", label: "Zur Startseite"},
            {route: "LeafletListingRoute", label: "Alle Prospekte"},
            {route: "PromotionListingRoute", label: "Aktionen"},
            {route: "VoucherListingRoute", label: "Gutscheine"},
            {route: "VendorListingRoute", label: "Händler & Märkte"},
        ];

        return (
            <AppMainCol>
                <AppMainColBox>
                    <Error title="Uups. Die gesuchte Seite wurde leider nicht gefunden.">

                        <AdItem
							mobileAdId={4493492}
							maxHeight={250}
							type="content"
							title="Mobile Rectangle 1"
						/>

                        <div className={styles.notFoundWrapper}>

                            <div>Wie können wir dir weiterhelfen?</div>
                            
                            <div className={styles.notFoundLinks}>

                                {links.map((link: any) => {
                                    return (
                                        <WidgetListItemLinkRow
                                            title={link.label}
                                            key={`${link.label}`}
                                            route={link.route}
                                        />
                                    );
                                })}

                            </div>
                        </div>
                    </Error>
                </AppMainColBox>
            </AppMainCol>
        );
    }
}

export default NotFound;