import * as React from 'react';
import * as styles from './BrandList.scss';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import BrandListItemSkeleton from '../BrandListItem/BrandListItemSkeleton';

export interface IBrandListSkeletonProps {
	limit: number,
}

class BrandListSkeleton extends React.PureComponent<IBrandListSkeletonProps, {}> {
	public static defaultProps = {
		boxed: false,
		limit: 10
	};

	public render () {
		const { limit } = this.props;

		const limitArr = Array.from(Array(limit).keys());

		return (
			<AppMainColBox boxed={false} nested={true}>
				<div>
					{limitArr.map(indx => {
						return (
							<div className={styles.brandListWrapper} key={`brands-grid-list-skeleton-${indx}`}>
								<BrandListItemSkeleton />
							</div>
						);
					})
					}
				</div>
			</AppMainColBox>
		);
	}
}

export default BrandListSkeleton;