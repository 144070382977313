import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IRouteMetadataType } from '../../types/IRouteMetadataType';
import { ROUTE_METADATA_SET_DATA, ROUTE_METADATA_SET_ERRORS } from './routeMetadataActions';

export interface IRouteMetadataStateType extends IBaseStateType {
	readonly routeMetadata: { [ key: string ]: IRouteMetadataType };
}

const defaultRouteMetadataState = {};

export function routeMetadataReducer (
	state = defaultRouteMetadataState as IRouteMetadataStateType,
	action: any,
) {
	switch (action.type) {
		case ROUTE_METADATA_SET_DATA:
			const dataObj = Object.assign({}, state[ action.queryKey ], action.routeMetadata);
			return Object.assign({}, state, { [ action.queryKey ]: dataObj });

		case ROUTE_METADATA_SET_ERRORS:
			return state;

		default:
			return state;
	}
}
