import * as React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../services/TelemetryService';
import { withRouter } from 'react-router';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
interface TelemetryProviderProps {
    connectionString: string;
}

class TelemetryProvider extends React.Component<TelemetryProviderProps> {
    state = {
        initialized: false,
    };

    componentDidMount() {
        const { history, connectionString } = this.props;
        const { initialized } = this.state;

        if (!Boolean(initialized) && Boolean(connectionString) && Boolean(history)) {
            ai.initialize(connectionString, history);
            this.setState({ initialized: true });
        }
    }

    render() {
        const { children } = this.props;
        return <React.Fragment>{children}</React.Fragment>;
    }
}

export default withRouter(/*withAITracking(ai.reactPlugin, */TelemetryProvider/*)*/);
