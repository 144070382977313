import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addLotteryListEntries,
	LOTTERY_LIST_FETCH_ENTRIES, setLotteryListErrors,
} from './lotteryListActions';

/**
 * Fetch entry list
 */
function* fetchLotteryLists(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = afCoreRequest.mergeParams({
		page: 0,
		size: 5,
	}, action.params);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/external-lotteries',
				params: null,
			} as IRequestObject);
		});

		yield put(addLotteryListEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setLotteryListErrors(action.queryKey, e));
	}
}

export function* lotteryListSaga() {
	yield takeEvery(LOTTERY_LIST_FETCH_ENTRIES, fetchLotteryLists);
}
