import * as React from 'react';
import { ReactNode } from 'react';
import * as styles from './Intro.scss';

interface IIntroProps {
  children: ReactNode,
}

class Intro extends React.Component<IIntroProps, {}> {
  public render () {
    const { children } = this.props;

    return (
      <div className={`leadText ${styles.intro}`}>
        {children}
      </div>
    );
  }
}

export default Intro;
