const serverErrorMap = {
    401: 'E-Mail Adresse oder Passwort ungültig.',
    400: 'Fehlerhafte Eingabe',
    403: 'Diese Aktion ist leider nicht erlaubt.',
    409: 'Deine Einlösung ist bereits bestätigt.',
    404: 'Konnte nicht gefunden werden',
    500: 'Leider ist ein unerwarteter Fehler aufgetreten, bitte versuche es erneut.',
};

export default serverErrorMap;
