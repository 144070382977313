import React from 'react';
import styles from '../CarouselTeaser.scss';

interface ICarouselTeaserIndicatorPropsType {
  isSelected: boolean;
  clickHandler: () => void;
  index: number;
}

class CarouselTeaserIndicator extends React.PureComponent<ICarouselTeaserIndicatorPropsType> {
  public render() {
    const { clickHandler, isSelected, index } = this.props;
    const indicatorSelector = isSelected ? styles.carouselIndicatorDotSelected : styles.carouselIndicatorDot;
    return (
      <li
        onClick={clickHandler}
        className={`${styles.carouselIndicator} ${indicatorSelector}`}
        aria-label={`Go to slide ${index + 1}`}
        role="button"
        tabIndex={0}
        key={index}
      />
    );
  }
}

export default CarouselTeaserIndicator;
