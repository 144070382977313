import * as React from 'react';
import { ReactChild } from 'react';
import H1 from '../H1/H1';
import * as styles from './AppMainColBox.scss';

interface IAppMainColBoxProps {
	children: ReactChild | JSX.Element[],
	boxed?: boolean,
	title?: string,
	nested?: boolean, // describes whether this is already inside other elements or it is a appmaincolbox on it's own (so there will be extra padding Y)
	titleAlign?: 'left' | 'center' | 'right',
}

class AppMainColBox extends React.Component<IAppMainColBoxProps, {}> {
	public render () {
		const { children, title, titleAlign, boxed, nested } = this.props;

		const styleClassName = (nested) ? styles.appMainColBoxNested : styles.appMainColBox;
		const theStyle = (boxed) ? styleClassName : null;

		return (
			<div className={theStyle}>
				{!title ? null : (
					<H1 align={titleAlign}>{title}</H1>
				)}

				{children}
			</div>
		);
	}
}

export default AppMainColBox;
