import AfCoreRequest from '../bootstrap/redux-data/AfCoreRequest';
import { IRequestObject } from '../../boilerplate/redux/types/IRequestObject';

const PersonaService = {
    /**
     * Contains the generated persona token
     */
    currentToken: '',

    /**
     * Get users persona token
     */
    get(): string {
        return this.currentToken;
    },

    /**
     * Initiate the persona token process by trying to load it from localstorage
     * If the user is a first time visitor there will be nothing in localstorage,
     * so we will query the api to generate a new one.
     */
    init() {
        if (localStorage.getItem('pt')) {
            this.currentToken = localStorage.getItem('pt') as string;
            return;
        }

        this.fetchToken();
    },

    /**
     * Sets the token in our temporary and persistent areas
     * @param token
     */
    setToken(token: string) {
        this.currentToken = token;
        localStorage.setItem('pt', token);
    },

    /**
     * Generate a new persona token, by requesting one from the API
     */
    async fetchToken() {
        const afCoreRequest = new AfCoreRequest('personatoken');

        return afCoreRequest
            .postRequest({
                url: '/api/public/v1/sessions',
            } as IRequestObject)
            .then(data => {
                this.setToken(data.pipelineObject.response.body.id);
            })
            .catch(err => {
                console.warn('Persona token error: ', err);
            });
    },
};

export default PersonaService;
