import * as React from 'react';
import { ReactNode } from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import { IShopType } from '../../types/IShopType';
import NearestShopWidgetSkeleton from '../../components/NearestShopWidget/NearestShopWidgetSkeleton';

interface INearestShopWidgetContainerProps {
    title?: string;
    widgetKey: string;
    limit?: number;

    lng?: number;
    lat?: number;
    radius?: number;

    childComponentRenderer: (nearestShop: IShopType) => ReactNode, // a method returning a react component we can use inside this one, that get's the full nearest shop parameter as well

    setNearestShop?: (nearestShop: IShopType) => void,

    promotionSlug?: SlugType; // ID of vendor
    vendorSlug?: SlugType; // ID of vendor
    children?: ReactNode;
}

class NearestShopWidgetContainerSkeleton extends React.PureComponent<INearestShopWidgetContainerProps & IWithDataProps> {
    public render() {
        const { widgetKey, } = this.props;


        return (
            <React.Fragment>
                <NearestShopWidgetSkeleton
                    widgetKey={widgetKey}
                />
            </React.Fragment>
        );
    }
}

export default NearestShopWidgetContainerSkeleton

