import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import { IShoppingCenterAggregatedByStateType } from '../../types/IShoppingCenterAggregateByStateType';
import ShoppingCenterAggregatedByStatesWidget from "../../components/ShoppingCenterAggregatedByStatesWidget/ShoppingCenterAggregatedByStatesWidget";
import DataStateService from '../../../../services/DataStateService';


interface IShoppingCenterAggregatedByStatesWidgetContainerProps {
	title: string;
	widgetKey: string;

	limit?: number;
	placeholderLimit?: number;
	page?: number;

	routeCallbk: (item: IShoppingCenterAggregatedByStateType) => { name: string, params: { [key: string]: any } },

	style?: IWidgetStyleType;
	orderBy?: 'leaflets'
	orderDir?: 'desc';

	excludeSlug?: SlugType,
	vendorSlug?: SlugType,
}

class ShoppingCenterAggregatedByStatesWidgetContainerInner extends React.Component<IShoppingCenterAggregatedByStatesWidgetContainerProps & IWithDataProps> {
	public render() {
		const { data, title, widgetKey, excludeSlug, routeCallbk, ...restProps } = this.props;

		if (data.length < 1) {
			return null;
		}

		return (
			<ShoppingCenterAggregatedByStatesWidget
				states={ ( !excludeSlug ) ? data as IShoppingCenterAggregatedByStateType[] : this.excludedEntry(data)}
				routeCallbk={routeCallbk}
				title={title}
				{...restProps}
			/>
		);
	}

	/**
	 * Cleans up our entries and makes sure the excluded entry is not in there
	 */
	private excludedEntry(entries: IShoppingCenterAggregatedByStateType[]): IShoppingCenterAggregatedByStateType[] {
		const { limit, excludeSlug } = this.props;

		// now try to filter it out
		return entries.filter((entry: IShoppingCenterAggregatedByStateType, indx: number) => {
			if (entry.federalState.slug === excludeSlug) {
				return false;
			}

			// if we are over our limit -> also return false (this might be because, we don't have the excludee in our data set,
			// but to make sure we have the correct amount of data even after removing the excludee, we have added +1 to limit on fetch
			if (limit && indx > limit) {
				return false;
			}

			// otherwise return true and add it to our results
			return true;
		});
	}
}

export default function ShoppingCenterAggregatedByStatesWidgetContainer(props: IShoppingCenterAggregatedByStatesWidgetContainerProps) {
	const limit = (props.limit) ? props.limit : 5;

	const RenderComponent = withData(
		ShoppingCenterAggregatedByStatesWidgetContainerInner,
		'shoppingCenterAggregatedByStatesWidgetReducer',
		`shopping-center-aggregated-by-states-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			page: props.page,
			size: (props.excludeSlug) ? (limit + 1) : limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,
			exclude: props.excludeSlug,
			store_vendor: props.vendorSlug,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
