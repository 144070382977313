import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import ShopListGroupedByStateGrouped from '../../components/ShopListGroupedByState/ShopListGroupedByStateGrouped';
import { IShopGroupedByStateType } from '../../types/IShopGroupedByStateType';
import { SlugType } from 'App/modules/Base/types/SlugType';
import DataStateService from '../../../../services/DataStateService';
import ShopListGroupedByStateGroupedSkeleton from '../../components/ShopListGroupedByState/ShopListGroupedByStateGroupedSkeleton';


interface IShopListGroupedByStateContainerProps {
	size?: 's' | 'm' | 'l';
	widgetKey?: string;

	titleLabel?: string;
	titleLabelPlural?: string;
	moreLabel?: string;
	moreRouteCallbk?: (shopGroup: IShopGroupedByStateType) => { name: string, params: { [key: string]: any } };

	page?: number;
	limit?: number;
	limitItemsPerGroup?: number;

	orderBy?: 'relevance';
	orderDir?: 'desc';
	enablePagination?: boolean;

	vendorSlug?: SlugType;
	stateSlug?: SlugType;

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class ShopListGroupedByStateContainerInner extends React.Component<IShopListGroupedByStateContainerProps & IWithDataProps, {}> {
	public static defaultProps = {
		limit: 6,
		orderBy: 'relevance',
		orderDir: 'desc',
		size: 'm',
	};

	public render() {
		const { data, ...restProps } = this.props;

		return (
			<ShopListGroupedByStateGrouped
				shopGroups={data}
				{...restProps}
			/>
		);
	}
}

export default function ShopListGroupedByStateContainer(theProps: IShopListGroupedByStateContainerProps) {
	const props = Object.assign({
		limit: 50,

		enablePagination: false,
	}, theProps);

	const RenderComponent = withData(
		ShopListGroupedByStateContainerInner,
		'shopGroupedByStateReducer',
		`shopGroupedByState-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			page: props.page,

			store_vendor: props.vendorSlug,
			state: props.stateSlug,
		},
		{ LoadingComponent: () => (<ShopListGroupedByStateGroupedSkeleton />) }
	);

	return <RenderComponent {...props} />;
}


