import * as React from 'react';
import Link from '../../../Base/components/Link/Link';
import { IShopGroupedByStateType } from '../../types/IShopGroupedByStateType';
import * as styles from './ShopListGroupedByStateGrouped.scss';
import ShopListGroupedByStateGroup from './ShopListGroupedByStateGroup';



export interface IShopListGroupedByStateGroupedProps {
	shopGroups: IShopGroupedByStateType[],
	size?: 's' | 'm' | 'l',
	gridKey?: string,

	enablePagination?: boolean;
	// TODO: implement pagination on this

	titleLabel?: string,
	titleLabelPlural?: string,
	moreLabel?: string,
	moreRouteCallbk?: (state: IShopGroupedByStateType, parentProps: IShopListGroupedByStateGroupedProps) => { name: string, params: { [key: string]: any } },

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing
}

class ShopListGroupedByStateGrouped extends React.PureComponent<IShopListGroupedByStateGroupedProps, {}> {
	public static defaultProps = {
		columns: 2,
		gridKey: 'default',
		size: 'm',
		contentBreaks: {},
	};

	// TRANSLATE
	public render() {
		const { shopGroups, gridKey, moreLabel, moreRouteCallbk, contentBreaks } = this.props;

		return (
			<React.Fragment>
				{shopGroups.map((state, indx) => {

					
					let route = {
						name: '',
						params: {},
					};

					if (moreRouteCallbk) {
						route = moreRouteCallbk(state, this.props);
					}

					return (
						<React.Fragment key={`shops-grid-group-${gridKey}-entry${indx}`}>

							{(!contentBreaks || !contentBreaks[indx]) ? null :
								<React.Fragment>
									{contentBreaks[indx]}
								</React.Fragment>
							}

							<div className={styles.shopListGroupedByStateWrapper}>
								<h2 className={styles.shopListGroupedByStateTitle}>
									{this.getTitleLabel(state)}
								</h2>

								<ShopListGroupedByStateGroup
									state={state.group}
									shops={state.items}
								/>

								{(!moreLabel) ? null :
									<div className={`${styles.shopListGroupedByStateMoreItem}`}>
										<Link
											route={route.name}
											params={route.params}
										>
											<a className={styles.shopListGroupedByStateMore}>
												{this.getMoreLabel(state)}
											</a>
										</Link>
									</div>
								}
							</div>
						</React.Fragment>
					);
				})
				}
			</React.Fragment>
		);
	}

	/**
	 * Returns the title label with replaced placeholders
	 *
	 * @param state
	 */
	private getTitleLabel(state: IShopGroupedByStateType): string {
		const { titleLabel, titleLabelPlural } = this.props;

		if (!titleLabel) {
			return '';
		}

		const totalItemsCount = state.totalItemsCount;

		let ret = (totalItemsCount === 1) ? titleLabel + '' : titleLabelPlural + '';

		// replace a few things
		ret = ret.replace('%state%', state.group.name);
		ret = ret.replace('%count%', totalItemsCount + '');
		ret = ret.replace('%city%', state.group.name || `${state.group.zipCode} ${state.group.city.name}`);

		return ret;
	}

	/**
	 * Returns the more label with replaced placeholders
	 *
	 * @param state
	 */
	private getMoreLabel(state: IShopGroupedByStateType): string {
		const { moreLabel, } = this.props;

		if (!moreLabel) {
			return '';
		}

		let ret = moreLabel;

		// replace a few things
		ret = ret.replace('%state%', state.group.name);
		ret = ret.replace('%city%', state.group.name || `${state.group.zipCode} ${state.group.city.name}`);

		return ret;
	}
}

export default ShopListGroupedByStateGrouped;
