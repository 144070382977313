import { ActionType } from 'typesafe-actions';
import * as confirmNewslettersActions from './confirmNewslettersActions';
import BaseReducer, { baseInitialState} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';


export type ConfirmNewslettersGridAction = ActionType<typeof confirmNewslettersActions>;

export interface IConfirmNewslettersStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: {} };
	readonly queries: { [queryKey: string]: [] };
}

export function confirmNewslettersReducer(
	state = baseInitialState as IConfirmNewslettersStateType,
	action: ConfirmNewslettersGridAction,
) {
	const Reducer = new BaseReducer('confirmNewslettersReducer');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}

