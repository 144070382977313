import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import ProductPromotionGridContainer
	from '../../../Promotion/containers/ProductPromotionGrid/ProductPromotionGridContainer';
import ProductPromotionGridGroupedContainer
	from '../../../Promotion/containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import ProductPromotionListWidgetContainer
	from '../../../Promotion/containers/ProductPromotionListWidget/ProductPromotionListWidgetContainer';
import PromotionsAggregatedByBrandsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import PromotionsAggregatedByStatesWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByStatesWidget/PromotionsAggregatedByStatesWidgetContainer';
import { IProductPromotionGroupedType } from '../../../Promotion/types/IProductPromotionGroupedType';
import { IPromotionsAggregatedByBrandType } from '../../../Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByStateType } from '../../../Promotion/types/IPromotionsAggregatedByStateType';
import { IProductGroupType } from '../../types/IProductGroupType';
import ProductGroupListWidget from '../ProductGroupListWidget/ProductGroupListWidget';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionsAggregatedByProductGroupsWidgetSkeleton
	from '../../../Promotion/components/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregateByProductGroupsWidgetSkeleton';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IProductGroupDetailHubPageProps {
	productGroup?: IProductGroupType,
	productGroupSlug: SlugType,
}

class ProductGroupDetailHubPage extends React.Component<IProductGroupDetailHubPageProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	// TRANSLATE
	public render () {
		const { productGroup, routeMetadata, productGroupSlug } = this.props;

		const productGroupTitle = (productGroup) ? productGroup.title : '';

		return (
			<React.Fragment>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol boxed={false}>

						<AppMainColBox nested={true} boxed={true}>
							<div>
								<ProductPromotionGridContainer
									moreLabel={`Weitere ${(productGroup) ? productGroupTitle : ''} Aktionen`}
                  					gridKey={`${routeMetadata.queryKey}-main`}
									limit={6}
									productGroupSlug={productGroupSlug}
									moreHref={this.getDestinationRouteForCurrentProductGroup()}
									enablePagination={false}
								/>

								<ProductPromotionGridGroupedContainer
									gridKey={routeMetadata.queryKey}
									productGroupSlug={productGroupSlug}
									limitItemsPerGroup={6}
									limit={12}
									enablePagination={true}
									moreLabel="Weitere %title% Aktionen"
									moreRouteCallbk={this.routerCallbkPromotionGridMoreLink}
									pushRouteMetadata={true}
									contentBreaks={{
										1: (
											<AdItem
												mobileAdId={4403480}
												maxHeight={250}
												type="content"
												title="Mobile Rectangle 1"
											/>
										),
										3: (
											<AdItem
												mobileAdId={4403479}
												maxHeight={250}
												type="content"
												title="Mobile Rectangle 2"
											/>
										)
									}}
								>
									<NoDataError />
								</ProductPromotionGridGroupedContainer>
							</div>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							{( !productGroup ) ?
							 <PromotionsAggregatedByProductGroupsWidgetSkeleton />
			                  :
							 <ProductGroupListWidget
								 title="Verfeinere deine Auswahl"
								 widgetKey={`subcategory-widget-${productGroupSlug}`}
								 productGroups={productGroup.childProductGroups}
								 routeCallbk={this.routerCallbkProductGroupListWidget}
							 />
							}

							<PromotionsAggregatedByStatesWidgetContainer
								title={`${productGroupTitle} Aktionen nach Bundesland`}
								widgetKey={`productgroup-detail-aggregated-states-widget-${productGroupSlug}`}
								routeCallbk={this.routerCallbkStateListWidget}
								productGroupSlug={productGroupSlug}
							/>
							
							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<PromotionsAggregatedByBrandsWidgetContainer
								title={`${productGroupTitle} Marken in Aktion`}
								widgetKey={`productgroup-detail-aggregated-brands-widget-${productGroupSlug}`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								productGroupSlug={productGroupSlug}
							/>

							<ProductPromotionListWidgetContainer
								widgetKey={`productgroup-detail-promotion-list-coupons-${productGroupSlug}`}
								title={`${productGroupTitle} Gutscheine`}
								fetchVoucherProductPromotions={true}
								productGroupSlug={productGroupSlug}
								noGeolocation={true}
								placeholderLimit={6}
								actionButtonLabel={`Alle ${productGroupTitle} Gutscheine`}
								actionButtonRouteCallbk={this.routerCallbkVoucherListWidget}
							/>

							<ProductPromotionListWidgetContainer
								widgetKey={`productgroup-detail-promotion-list-assortments-${productGroupSlug}`}
								title={`${productGroupTitle} Sortimentsaktionen`}
								fetchAssortementProductPromotions={true}
								productGroupSlug={productGroupSlug}
								noGeolocation={true}
								placeholderLimit={6}
								actionButtonLabel={`Alle ${productGroupTitle} Sortimentsaktionen`}
								actionButtonRouteCallbk={this.routerCallbkPromotionListWidget}
							/>

						</div>
					</AppSideCol>
				</Row>

			</React.Fragment>
		);
	}

	/**
	 * Returns the destination path for this group
	 */
	private getDestinationRouteForCurrentProductGroup(): string {
		const { productGroup } = this.props;

		if (!productGroup) {
			return '';
		}

		return productGroup.detailUrlPath.replace('/ppc/', '/ppcdest/');
	}

	/**
	 * Defines the route the tag list widget should be routed towards
	 *
	 * @param productGroup
	 */
	private routerCallbkPromotionGridMoreLink = (productGroup: IProductPromotionGroupedType) => {	
		if (!productGroup) {
			return '';
		}

		return productGroup.group.detailUrlPath;
	};

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkVoucherListWidget = () => {
		const { productGroupSlug } = this.props;

		return {
			name: 'VouchersInProductGroupRoute',
			params: {
				productGroup: productGroupSlug,
			},
		};
	};

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = () => {
		const { productGroupSlug } = this.props;

		return {
			name: 'AssortementPromotionsInProductGroupRoute',
			params: {
				productGroup: productGroupSlug,
			},
		};
	};

	/**
	 * Defines the route the product group list widget should be routed towards
	 * @param item
	 */
	private routerCallbkProductGroupListWidget = (item: IProductGroupType) => {
		return item.detailUrlPath;
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkStateListWidget = (item: IPromotionsAggregatedByStateType) => {
		const { productGroupSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndStateRoute',
			params: {
				state: item.group.slug,
				productGroup: productGroupSlug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { productGroupSlug } = this.props;

		return {
			name: 'PromotionsFromProductGroupAndBrandRoute',
			params: {
				productGroup: productGroupSlug,
				brand: item.group.slug,
			},
		};
	}
}

export default ProductGroupDetailHubPage;
