import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataOptions } from '../../../../../boilerplate/redux/types/IWithDataOptions';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import LeafletGrid from '../../components/LeafletGrid/LeafletGrid';
import LeafletGridSkeleton from '../../components/LeafletGrid/LeafletGridSkeleton';
import { ILeafletType } from '../../types/ILeafletType';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import DataStateService from '../../../../services/DataStateService';

interface ILeafletGridContainerProps {
	gridKey?: string;
	size?: 's' | 'm' | 'l';
	title?: string;
	titleLevel?: number; // the level of h${x} for the html title tag
	columns?: '1' | '2' | '3' | '4' | '5';
	radius?: number;

	boxed?: boolean;
	nested?: boolean;
	bottomButton?: boolean;
	moreLabel?: string;
	moreRoute?: string;
	moreRouteParams?: { [key: string]: any };

	limit?: number;
	page?: number;
	orderBy?: 'rank' | 'createdAt';
	orderDir?: 'asc' | 'desc';
	enablePagination?: boolean;

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing

	onlyNearbyLeaflets?: boolean;
	pushRouteMetadata?: boolean;
	includeAll?: boolean;

	vendorSlug?: SlugType;
	shopSlug?: SlugType;
	industrySlug?: SlugType;
	productGroupSlug?: SlugType;
	stateSlug?: SlugType;
	excludeSlug?: SlugType;
	referenceSlug?: SlugType;
	children?: React.ReactChild | JSX.Element[] | string[];
}

class LeafletGridContainerInner extends React.PureComponent<ILeafletGridContainerProps & IWithDataProps> {
	public static defaultProps = {
		columns: 5,
		size: 10,
		orderBy: 'rank',
		orderDir: 'desc',
		moreRouteParams: {},
		onlyNearbyLeaflets: true,
		// size: 'm',
	};

	public render() {
		const { data, metadata, gridKey, fetchMore, boxed, nested, columns, children, enablePagination, ...restProps } = this.props;

		const leafletWithoutExcluded = this.getEntriesWithExcluded(data);

		return (
			<Pagination
				fetchMore={fetchMore}
				metadata={metadata}
				queryKey={gridKey}
				enablePagination={enablePagination}
				LoaderComponent={
					<AppMainColBox boxed={boxed} nested={nested}>
						<LeafletGridSkeleton
							limit={Math.min(0, (((metadata && metadata.totalItemsCount) ? metadata.totalItemsCount : 0) - leafletWithoutExcluded.length))}
							gridKey={gridKey}
							columns={columns}
						/>
					</AppMainColBox>
				}
			>
				<LeafletGrid
					leaflets={leafletWithoutExcluded}
					gridKey={gridKey}
					metadata={metadata}
					boxed={boxed}
					enablePagination={enablePagination}
					fetchMore={fetchMore}
					nested={nested}
					columns={columns}
					{...restProps}
				>
					{children}
				</LeafletGrid>

			</Pagination>
		);
	}

	/**
	 * Cleans up our entries and makes sure the excluded entry is not in there
	 *
	 * @param {ILeafletType} entries
	 *
	 * @return {ILeafletType}
	 */
	private getEntriesWithExcluded(entries: ILeafletType[]): ILeafletType[] {
		const { limit, excludeSlug } = this.props;

		if (!excludeSlug) {
			return entries;
		}

		// now try to filter it out
		return entries.filter((entry: ILeafletType, indx: number) => {
			if (entry.slug === excludeSlug) {
				return false;
			}

			// if we are over our limit -> also return false (this might be because, we don't have the excludee in our data set,
			// but to make sure we have the correct amount of data even after removing the excludee, we have added +1 to limit on fetch
			if (limit && indx > limit) {
				return false;
			}

			// otherwise return true and add it to our results
			return true;
		});
	}
}


export default function LeafletGridContainer(theProps: ILeafletGridContainerProps) {
	const props = Object.assign({
		columns: '3',
		limit: 12,
		orderBy: 'rank',
		orderDir: 'desc',
		size: 'm',
		radius: 50000,
		onlyNearbyLeaflets: true,
	}, theProps) as ILeafletGridContainerProps;

	const RenderComponent = withData(
		LeafletGridContainerInner,
		'leafletReducer',
		`${props.gridKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			page: props.page,

			orderBy: props.orderBy,
			orderDir: props.orderDir,

			radius: props.radius,
			onlyNearbyLeaflets: props.onlyNearbyLeaflets,
			pushRouteMetadata: props.pushRouteMetadata,
			include_all: props.includeAll === false ? "false" : props.includeAll,

			store_vendor: props.vendorSlug,
			store: props.shopSlug,
			state: props.stateSlug,
			industry: props.industrySlug,
			product_group: props.productGroupSlug,
			reference: props.referenceSlug,
		},
		{
			LoadingComponent: LeafletGridSkeleton,
		} as IWithDataOptions
	);

	return <RenderComponent {...props} />;
}


