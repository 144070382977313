import { call, put, takeEvery, select } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addShoppingCenterEntries,
	addShoppingCenterEntry,
	setShoppingCenterErrors,
	SHOPPING_CENTER_FETCH_ENTRIES,
	SHOPPING_CENTER_FETCH_ENTRY,
} from './shoppingCenterActions';
import RouteMetadataService from '../../../../services/RouteMetadataService';
import { IRouteMetadataType } from '../../../../modules/Base/types/IRouteMetadataType';
import GeolocationService from '../../../Base/services/GeolocationService';

/**
 * Fetch entry list
 */
function* fetchShoppingCenters(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const paramsWithoutCoords = afCoreRequest.mergeParams({
		page: 0,
		size: 30,
	}, action.params);

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, paramsWithoutCoords);

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: `/api/public/v1/shopping-centers/`,
					params: params,
				} as IRequestObject);
			},
		);

		const content = response.getContent('content');

		// put route metadata
		if (action.params.pushRouteMetadata) {
			RouteMetadataService.setMetadata(action.queryKey, {
				totalItemsCount: response.getContent('numberOfElements'),
			} as IRouteMetadataType);
		}

		yield put(
			addShoppingCenterEntries(
				action.queryKey,
				content,
				response.getMetaData(),
			),
		);
	} catch (e) {
		yield put(setShoppingCenterErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry details
 */
function* fetchShoppingCenter(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, {});

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: `/api/public/v1/shopping-centers/${
						action.params.shoppingCenterSlug
					}`,
					params,
				} as IRequestObject);
			},
		);

		yield put(
			addShoppingCenterEntry(
				action.queryKey,
				response.getContent(),
				response.getMetaData(),
				action.options,
			),
		);
	} catch (e) {
		yield put(setShoppingCenterErrors(action.queryKey, e));
	}
}

export function* shoppingCenterSaga() {
	yield takeEvery(SHOPPING_CENTER_FETCH_ENTRY, fetchShoppingCenter);
	yield takeEvery(SHOPPING_CENTER_FETCH_ENTRIES, fetchShoppingCenters);
}
