import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataOptions } from '../../../../../boilerplate/redux/types/IWithDataOptions';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import LeafletGridGrouped from '../../components/LeafletGridGrouped/LeafletGridGrouped';
import LeafletGridGroupedSkeleton from '../../components/LeafletGridGrouped/LeafletGridGroupedSkeleton';
import { ILeafletGroupedType } from '../../types/ILeafletGroupedType';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import DataStateService from '../../../../services/DataStateService';

interface ILeafletGridGroupedContainerProps {
	size?: 's' | 'm' | 'l';
	columns?: '1' | '2' | '3';
	gridKey?: string;

	page?: number;
	limit?: number;
	limitItemsPerGroup?: number;
	orderBy?: 'relevance' | 'created' | 'published';
	orderDir?: 'asc' | 'desc';
	enablePagination?: boolean;

	pushRouteMetadata: boolean;
	onlyNearbyLeaflets?: boolean; // defines whether only nearby promotions should be used or all of them

	lat?: number;
	lng?: number;
	radius?: number;

	stateSlug?: SlugType;
	vendorSlug?: SlugType;
	vendorName?: SlugType;

	groupTitle?: string;
	groupTitlePlural?: string;

	withCurrentLeafletVendor?: boolean;
	leafletDescription?: string;
	moreLabel?: string;
	moreRouteCallbk?: (productGroup: ILeafletGroupedType) => { name: string, params: {[key: string]: any} };

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing

	children?: React.ReactNode; // if this is given, this will be shown if the array doesn't return any results
}

class LeafletGridGroupedContainerInner extends React.Component<ILeafletGridGroupedContainerProps & IWithDataProps, {}> {
	public static defaultProps = {
		limit: 6,
		orderBy: 'relevance',
		orderDir: 'desc',
		size: 'm'
	};

	public render () {
		const { data, fetchMore, metadata, gridKey, children, enablePagination, ...restProps } = this.props;

		return (
			<Pagination
				fetchMore={fetchMore}
				metadata={metadata}
				queryKey={gridKey}
				enablePagination={enablePagination}
				LoaderComponent={<LeafletGridGroupedSkeleton />}
			>
				<LeafletGridGrouped
					leafletGroups={data as ILeafletGroupedType[]}
					metadata={metadata}
					{...restProps}
				>
					{children}
				</LeafletGridGrouped>
			</Pagination>
		);
	}
}

export default function LeafletGridGroupedContainer (theProps: ILeafletGridGroupedContainerProps) {
	const props = Object.assign({
		limit: 60,
		orderBy: 'relevance',
		orderDir: 'desc',
		size: 'm',
		columns: '3',
		limitItemsPerGroup: 6,
		radius: 50000,

		enablePagination: false,
		onlyNearbyLeaflets: true,
	}, theProps);

	const RenderComponent = withData(
		LeafletGridGroupedContainerInner,
		'leafletGroupedReducer',
		`${props.gridKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			page: props.page,

			limit_Leaflets_per_category: props.limitItemsPerGroup,

			lat: props.lat,
			lng: props.lng,
			radius: props.radius,

			onlyNearbyLeaflets: props.onlyNearbyLeaflets,

			pushRouteMetadata: props.pushRouteMetadata,

			state: props.stateSlug,
			store_vendor: props.vendorSlug,
		},
		{
			LoadingComponent: () => <LeafletGridGroupedSkeleton withCurrentLeafletVendor={props.withCurrentLeafletVendor}/>,
		} as IWithDataOptions
	);

	return <RenderComponent {...props} />;
}


