import * as React from 'react';
import { ReactChild, ReactNode } from 'react';
import * as styles from './Row.scss';

interface IRowProps {
  children: ReactNode | ReactNode[],
}

class Row extends React.Component<IRowProps, {}> {
  public render () {
    const { children } = this.props;

    return (
      <div className={styles.row}>
        {children}
      </div>
    );
  }
}

export default Row;
