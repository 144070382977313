import * as React from 'react';
import { LazyImage } from 'react-lazy-images';
import * as styles from './ImageEnhanced.scss';
import { isServer } from '../../../../boilerplate/razzle/razzleUtils';
import { IImageResolutions } from '../../../modules/Base/types/IImageResolutions';
import { ReactNode } from 'react';
// import 'react-lazy-load-image-component/src/effects/blur.css';

interface IImageEnhancedProps {
	children?: ReactNode | ReactNode[];
	source?: IImageResolutions | null;
	sourceOriginal?: string;
	alt?: string;
	size?: 's' | 'm' | 'l' | 'lx' | 'mx';
	fallbackSize?: 's' | 'm' | 'l' | 'lx' | 'mx';
	className?: string;
	bgImage?: boolean;
	disableWebP?: boolean;
	type?: 'progressive' | 'lazy';
	style?: any;
	placeHolderImage?: (ref: any, props: any) => ReactNode;
}

interface IImageEnhancedStateProps {
	imageLoadError: boolean;
}

declare const process: any;

const sizeMapping = {
	's': 'small',
	'm': 'medium',
	'mx': 'xmedium',
	'l': 'large',
	'lx': 'xlarge'
};

let supportsWebP = false;

// check if we have webp support and save it globally, so we don't recheck everytime an image loads
const detectWebpSupport = () => {
	if (isServer()) {
		return;
	}

	const elem = document.createElement('canvas');
	if (elem.getContext && elem.getContext('2d')) {
		// was able or not to get WebP representation
		supportsWebP = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
	} else {
		// very old browser like IE 8, canvas not supported
		supportsWebP = false;
	}
};

detectWebpSupport();



/**
 * Converts the normal resolution jpg to a webp address, by changing the file extension
 *
 * @param imageUrl
 *
 * @return {string}
 */
const convertUrlToWebP = (imageUrl: string): string => {
	// TODO: revert this to support webp
	return imageUrl;
	// return imageUrl.substring(0, imageUrl.lastIndexOf('.')) + '.webp';
}


/**
 * Takes care of rendering the image, once it is loaded by progressive image
 *
 * @param src
 * @param style
 */
const renderImage = (props: IImageEnhancedProps, src: string | null, style: any) => {
	const { bgImage, alt, className, size, source, children, style: theStyle, ...restProps } = props;

	if (bgImage) {
		let theClassName = styles.BackgroundImage;

		if (className) {
			theClassName += ` ${className}`;
		}

		return (
			<span
				style={Object.assign(style, theStyle, { backgroundImage: (src) ? `url(${src})` : undefined })}
				className={theClassName}
				{...restProps}
			>
				{children}
			</span>
		);
	}

	if (!src) {
		return null;
	}

	return (
		<img src={src} style={style} className={className} alt={alt} />
	);
};

export default class ImageEnhanced extends React.Component <IImageEnhancedProps, IImageEnhancedStateProps> {
	constructor(props) {
		super(props);
		this.state = {
			imageLoadError: false,
		};
	}

	render() {
		const {
			bgImage = false,
			children,
			placeHolderImage,
			source,
			size = 'm',
			disableWebP,
			alt,
			sourceOriginal,
			className,
			style,
			type = 'progressive',
			fallbackSize,
		} = this.props;

		if (!source) {
			return null;
		}
	
		// let previewSrc = source[sizeMapping.s];
		let imageSrc = source[sizeMapping[(size) ? size : 'm']];
	
		// fix xmedium and xlarge cases, without these versions
		if (size === 'mx' && !imageSrc) {
			imageSrc = source[sizeMapping.m];
		} else if (size === 'lx' && !imageSrc) {
			imageSrc = source[sizeMapping.l];
		}
	
		if (sourceOriginal) {
			imageSrc = sourceOriginal;
		}
	
		if (!disableWebP && supportsWebP) {
			imageSrc = convertUrlToWebP(imageSrc);
		}

		// In case of error, try to load the fallback size if provided
		if (this.state.imageLoadError && fallbackSize) {
			imageSrc = source[sizeMapping[fallbackSize]];
		}
	
		const theClassName = `${className} ${styles.BackgroundImage}`;
		
		// if (true || isServer()) {
		// 	if (bgImage) {
		// 		const mergedStyle = Object.assign({}, style, { backgroundImage: `url('${imageSrc}')` });
		//
		// 		return (
		// 			<span
		// 				{...restImageProps}
		// 				style={mergedStyle}
		// 				className={theClassName}
		// 			>
		//         {( children ) ?
		//          <React.Fragment>{children}</React.Fragment>
		//                       :
		//          <img
		//            src={blankImage}
		//            alt={alt} style={style}
		//          />
		//         }
		//       </span>
		// 		);
		// 	}
		//
		// 	return (
		// 		<img
		// 			src={imageSrc}
		// 		  className={theClassName}
		// 			style={style}
		// 		/>
		// 	);
		// }

		// if (!source) {
		// 	if (bgImage && children) {
		// 		return this.renderImage(null, {});
		// 	}
		//
		// 	return null;
		// }
	
		const theProps = {
			alt,
			style,
			className: theClassName,
		};
	
		const blankImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAC7npUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAHja7ZZdstwoDIXfWcUsAUkIieVgfqqyg1n+HLDbt/veZCappGpe2pQNluUjWR/GDuPvbzP8hY1K5pDUPJecI7ZUUuGKgcdzK/tIMe1jvM4e/Ys93BcYI0Ev56nVy7/Crh83PGLQ8WoPfl1hv4Q+hPcmK/Ia9+ckYefTTukSKuMc5OL2nOpxCbXLcady7clfH3OdhxeDoUpdEUiYh5DEffQzAzn3il330eAXxffYw9ldYijIy+M9+hifC/Ra/GsUPlf/Hn0qPtfLLp9qmR/U8vcvkH6yyx2GnwPLnRG/XjB5SH0t8pzd5xzn09WUUdF8zahdbHrIwPFAyWXfltEMu2JsuxU0jzU2IO+xxQOtUSEGlRkoUadKk8buGzWkmHgwmDBzY9k2B6PCDWBI0mo02aRIBzWWxiOIwMx3LrTjlh2vkSNyJ7gyQYxwyw9b+LeLv9LCnG2ViKLftUJevOY10ljk1hFeAELz4qa7wI924Y9P82dN1QS3VWbHA9Z4nBKH0sfcks1Z4Kfoz1eIgvVLACVCbEUyJCAQM4lSpmjMRoQ6OgBVZM6S+AABUuWOJDmJYD0ydl6xcY/R9mXlzMuMtQkgVLIY2BSpgJWSYv5YcsyhqqJJVbOaetCiNUtOWXPOltciV00smVo2M7di1cWTq2c3dy9eCxfBGqglFyteSqmVQ0WgCq0K/wrLwYcc6dAjH3b4UY7aMH1aatpys+attNq5S8cy0XO37r30OigMrBQjDR152PBRRp2Ya1NmmjrztOmzzHpTu6h+ab9AjS5qvEktP7upwRrMHhK0lhNdzECME4G4LQKY0LyYRaeUeJFbzGJhvBTKSFIXm9BpEQPCNIh10s3ug9xPcQvqP8WN/4tcWOj+BLkAdF+5fYdaX9+5tomdb+GqaRS8fdNKZQ+V8aVSSro+UL/Rh98VeAu9hd5Cb6G30FvoLfS/C+E/fXb8GoR/AFGJO3plxn8GAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw1AUhU9Ta0UqDnYQcQhSnSyIijhqFYpQIdQKrTqYvPRHaNKQpLg4Cq4FB38Wqw4uzro6uAqC4A+Ik6OToouUeF9SaBHjg8v7OO+dw333AUK9zDSrYwzQdNtMJxNiNrcihl8RQhidVEMys4xZSUrBd33dI8D3uzjP8r/35+pR8xYDAiLxDDNMm3ideGrTNjjvE0dZSVaJz4lHTWqQ+JHrisdvnIsuCzwzambSc8RRYrHYxkobs5KpEU8Sx1RNp3wh67HKeYuzVq6yZp/8hZG8vrzEdapBJLGARUgQoaCKDZRhI067ToqFNJ0nfPwDrl8il0KuDTByzKMCDbLrB/+D37O1ChPjXlIkAYReHOdjGAjvAo2a43wfO07jBAg+A1d6y1+pA9OfpNdaWuwI6N0GLq5bmrIHXO4A/U+GbMquFKQSCgXg/Yy+KQf03QLdq97cmuc4fQAyNKvUDXBwCIwUKXvN591d7XP7905zfj/9V3J4hU/xhAAAAAZiS0dEAL4AuwC0U7NrbgAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+QFBwYICji3W1kAAAANSURBVAjXY/j58wcDAAjEAuukAxjpAAAAAElFTkSuQmCC';
	
		// loadEagerly={!!children}
		return (
			<LazyImage
				loadEagerly={true}
				src={imageSrc}
				alt={alt}
				className={theClassName}
				placeholder={({ imageProps, ref }) => {
					if (bgImage) {
						const { src, ...restImageProps } = imageProps;
	
						if (placeHolderImage) {
							return (
								<React.Fragment>{placeHolderImage(ref, theProps)}</React.Fragment>
							);
						}
	
						return (
							<span
								{...restImageProps}
							>
								{(children) ?
									<React.Fragment>{children}</React.Fragment>
									:
									<img
										ref={ref}
										src={blankImage} alt={imageProps.alt} style={style}
									/>
								}
							</span>
						);
					}
	
					if (placeHolderImage) {
						return placeHolderImage(ref, theProps);
					}
	
					return (
						<img
							ref={ref}
							src={blankImage}
							alt={imageProps.alt}
							className={className}
						/>
					);
				}}
				actual={({ imageProps }) => {
					if (bgImage) {
						const { src, ...restImageProps } = imageProps;
						
						return (
							<span
								{...restImageProps}
								style={{ backgroundImage: `url('${imageSrc}')` }}
							>
								{(children) ?
									<React.Fragment>{children}</React.Fragment>
									:
									<img
										src={blankImage} alt={imageProps.alt} style={style}
									/>
								}
							</span>
						);
					}
					
					return (
						<img {...imageProps} onError={() => this.setState({ imageLoadError: true })} />
					);
				}}
			/>	
		);
		//
		// if (type === 'lazy') {
		// 	return (
		// 		<LazyLoadImage
		// 			src={imageSrc}
		// 			effect="blur"
		// 			alt={alt}
		// 			scrollposition={scrollPosition}
		// 			className={className}
		// 			placeholder={<img src={blankImage} alt={alt} style={style} />}
		// 		/>
		// 	)
		// }
		//
		// return (
		// 	<ProgressiveImage
		// 		preview={previewSrc}
		// 		src={imageSrc}
		// 		render={this.renderImage}
		// 	/>
		// );
	}
}

