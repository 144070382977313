import BaseReducer from '../../../../../boilerplate/redux/BaseReducer';
import { IImageResolutions } from '../../../Base/types/IImageResolutions';
import { IMixedEntityType } from '../../../MixedEntity/types/IMixedEntityType';

export default class MixedSearchBaseReducer extends BaseReducer {
    /**
     * This method is used to transform entry data before putting it into the store
     * @param entry
     */
    public transformEntry(entry: IMixedEntityType) {
        const image = MixedSearchBaseReducer.getResultImage(entry);

        return Object.assign(entry, {
            // validFrom: entry.validFrom ? DateTime.fromISO(entry.validFrom).setLocale('de') : null,
            // validTo: entry.validTo ? DateTime.fromISO(entry.validTo).setLocale('de') : null,
            image: image ? image : null,
        });
    }

    /**
     * Returns the main result image for this item
     *
     * @returns {IImageType} | undefined
     */
    private static getResultImage(item: IMixedEntityType): IImageResolutions {
        if (item.image) {
            return item.image;
        }

        return {
            type: 'ImageResolution',
            large: '/image/layout/productPromotionPlaceholder.png',
            medium: '/images/layout/productPromotionPlaceholder.png',
            small: '/images/layout/productPromotionPlaceholder.png',
        };
    }
}
