import * as React from 'react';
import appConfig from '../../../../config/appConfig';
import { ILeafletType } from '../../types/ILeafletType';

interface ICurrentVendorLeafletStructuredDataPropTypes {
    leaflet: ILeafletType;
}

class CurrentVendorLeafletStructuredData extends React.PureComponent<ICurrentVendorLeafletStructuredDataPropTypes, {}> {

    public render() {
        const { leaflet } = this.props;

        if (!leaflet) {
            return null;
        }

        const emojiPostfix = (['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'].indexOf(leaflet.vendorName.substr(0, 1)) !== -1) ? '✔️' : '☝️';

        const scriptContent = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "OfferCatalog",
            "itemListElement":
                [
                    {
                        "@type": "SaleEvent",
                        "name": `Aktuelles ${leaflet.vendorName} Flugblatt `+ emojiPostfix,
                        "description": `Der aktuelle ${leaflet.vendorName} Prospekt mit günstigen Angeboten und Aktionen für Ihren Haushalt.`,
                        "image": (leaflet?.coverPage?.resolutions?.large) ? leaflet.coverPage.resolutions.large : '',
                        "startDate": leaflet.validFrom,
                        "endDate": leaflet.validTo,
                        "url": `https://${appConfig.mainDomain}/cvl/${leaflet.vendorSlug}/`,
                        "location": {
                            "address": {
                                "name": leaflet.vendorName,
                                "@type": "PostalAddress"
                            },
                            "image": leaflet.vendorLogoResolutions?.large,
                            "name": leaflet.title,
                            "url": `https://${appConfig.mainDomain}/lv/${leaflet.vendorSlug}/`,
                            "@type": "ShoppingCenter"
                        }
                    },
                ]
        });

        return (
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: scriptContent }}>

            </script>
        );
    }
}

export default CurrentVendorLeafletStructuredData;
