import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addPromotionsAggregatedByVendorsWidgetEntries,
	PROMOTIONS_AGGREGATED_BY_VENDORS_WIDGET_FETCH_ENTRIES, setPromotionsAggregatedByVendorsWidgetErrors,
} from './promotionsAggregatedByVendorsWidgetActions';

/**
 * Fetch entry list
 */
function* fetchPromotionsAggregatedByVendorsWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const { params } = action;

	let url = '/api/public/v1/widgets/vendors';
	if (params.entityType === 'leaflets') {
		url = '/api/public/v1/widgets/leaflet/vendors';
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url,
					params,
				} as IRequestObject
			);
		});

		const theContent = response.getContent('content');

		yield put(addPromotionsAggregatedByVendorsWidgetEntries(
			action.queryKey,
			theContent,
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionsAggregatedByVendorsWidgetErrors(action.queryKey, e));
	}
}


export function* promotionsAggregatedByVendorsWidgetSaga() {
	yield takeEvery(PROMOTIONS_AGGREGATED_BY_VENDORS_WIDGET_FETCH_ENTRIES, fetchPromotionsAggregatedByVendorsWidget);
}
