import { isServer } from '../../../../boilerplate/razzle/razzleUtils';

declare const window: any;

const DataLayerService = {
    push(event: string, payload: any) {
        if (isServer()) {
            return;
        }

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
           event,
           payload,
       });
    },
};

export default DataLayerService;
