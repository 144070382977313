import AfCoreRequest from '../../../bootstrap/redux-data/AfCoreRequest';
import serverErrorMap from '../components/ResetPassword/serverErrorMap';
import { IApiErrorType } from 'App/types/BaseTypes';

const LoginService = {

	/**
	 * Allows the calling of an event
	 *
	 * @param data
	 * @param callbk
	 */
	sendNewPassword (data: {}, callbk:(response: any) => void, errorCallbk: (error: string) => void) {

		const afCoreRequest = new AfCoreRequest('resetPassword');
		afCoreRequest.postRequest({
			url: '/api/public/v1/password-reset',
			body: data,
		} as IRequestObject).then((res: any) => {
			const response = res.getContent();

			if (callbk) {
				callbk(response);
			}
		}).catch((error: IApiErrorType) => {

			const errStatus = (error[0] && error[0].status) ? error[0].status : (error.status) ? error.status : '';
			const err = (serverErrorMap[errStatus]) ? serverErrorMap[errStatus] : 'Nicht erfolgreich';

			if (errorCallbk) {
				errorCallbk(err);
			}
		});
	}
};

export default LoginService;
