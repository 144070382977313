import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import Row from '../../../Base/components/Grid/Row';
import DataLayerService from '../../../Base/services/DataLayerService';
import ProductPromotionGridContainer
	from '../../../Promotion/containers/ProductPromotionGrid/ProductPromotionGridContainer';
import ThemeworldListWidgetContainer from '../../containers/ThemeworldListWidget/ThemeworldListWidgetContainer';
import { IThemeworldWidgetType } from '../../types/IThemeworldWidget';
import * as styles from './ThemeworldDetail.scss';
import { IWithRouteMetadataType } from 'App/types/IWithRouteMetadataType';
import { SlugType } from '../../../Base/types/SlugType';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import ThemeworldTagsListingContainer from '../../containers/ThemeworldTagsListing/ThemeworldTagsListingContainer';
import { ILeafletType } from '../../../Leaflet/types/ILeafletType';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import { Helmet } from 'react-helmet-async';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';

interface IThemeworldDetailProps {
	themeworld: IThemeworldType;
	themeworldSlug: SlugType;
}

interface IThemeworldDetailrStatesType {
	activeTag: ITagType;
}


const tracked: { [ themeworldId: string ]: boolean } = {};

class ThemeworldDetail extends React.Component<IThemeworldDetailProps & IWithRouteMetadataType, IThemeworldDetailrStatesType> {
	public static defaultProps = {};

	constructor(props: IThemeworldDetailProps){
		super(props);
		this.state = {
			activeTag: null,
		};
	}

	public componentDidMount () {
		this.trackThemeworldView();
	}

	public componentDidUpdate (prevProps: IThemeworldDetailProps) {
		if (prevProps.lastThemeworldSlug !== this.props.themeworldSlug) {
			this.trackThemeworldView();
		}
	}

	// TRANSLATE
	public render () {
		const { themeworld, themeworldSlug, routeMetadata } = this.props;
		const { activeTag } = this.state;

		// themeworld css example
		/*if (themeworldSlug === 'fussball') {
					themeworld.css = `
			.page-wrapper-outer {
				background-color:darkgreen!important;
			}

			.page-wrapper {
				background-color:green!important;
				box-shadow:0 0 24px rgba(0, 0, 0, 0.3);
				padding-left:20px;
				padding-right:20px;
				padding-bottom:50px;
				padding-top:30px;
			}

			.breadcrumb-wrapper,
		    .breadcrumb-item .breadcrumb-label,
			.breadcrumb-item .breadcrumb-divider {
				color:rgba(255, 255, 255, 0.7)!important;
			}

			.location-info,
			.location-info-button {
				color:#fff!important;
			}

			.themeworld-head h1 {
				color:#fff!important;
			}

			.themeworld-head .leadText {
				color:rgba(255, 255, 255, 0.7);!important;
			}`;

		}*/

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				{( !themeworld ) ? null :
					<Helmet encodeSpecialCharacters={false}>
						<meta name="og:title" content={themeworld?.title} />
						{isBrowser() && <meta name="og:description" content={document.querySelector('meta[name="description"]')?.content} />}
						<meta name="og:type" content="website" />
						<meta name="og:url" content={`https://www.aktionsfinder.at/tw/${themeworld.slug}`} />
						<meta name="og:image" content={themeworld.mobileImage?.resolutions?.small || themeworld.resolutions?.small} />
					</Helmet>
				}

				{( themeworld ) ?
				 <div className={`themeworld-head`}>
					 <H1 title={themeworld.headline}>
						 <div className={styles.themeworldDescription} dangerouslySetInnerHTML={{ __html: themeworld.description }} />
					 </H1>
				 </div>
				                :
				 <div className={`themeworld-head`}>
					 <H1>
						 <SkeletonInline />
					 </H1>
				 </div>
				}

				<Row>
					<AppMainCol boxed={false}>
						{( !themeworld || !themeworld.resolutions ) ? null :
						 <div className={styles.headerImage}>
							 <ImageEnhanced
								 source={themeworld.resolutions}
							 />
						 </div>
						}

						<AppMainColBox boxed={true} nested={true}>

							<ProductPromotionGridContainer
								limit={10}
								enablePagination={true}
								themeworldSlug={themeworldSlug}
								gridKey={`${routeMetadata.queryKey}-${activeTag?.slug ?? 'all'}`}
								pushRouteMetadata={true}
								useLeafletRoute={false}
								tagSlug={activeTag?.slug}
								onPromotionClick={this.trackPromotionClick}
								directClickout={!!themeworld?.leaflet}  // if we have a leaflet (=we are in a Promotionworld, not a normal themeworld => open clickouts directly)
							/>

							{/*
							<AdItem
								mobileAdId={4403491}
								maxHeight={250}
								type="content"
								title="Mobile Rectangle 2"
							/>
					*/}

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={false}>
						<div>

							<ThemeworldTagsListingContainer
								title="In dieser Themenwelt..."
								themeworldSlug={themeworldSlug}
								activeTag={activeTag}
								onActiveTagChange={this.changeActiveTag}
							/>

							<ThemeworldListWidgetContainer
								title="Weitere Themenwelten"
								widgetKey={`themeworlds-widget-${themeworldSlug}`}
								routeCallbk={this.routerCallbkThemeworldListWidget}
								btnLabel="Alle Themenwelten"
								btnRoute="ThemeworldListingRoute"
								excludeSlug={themeworldSlug}
								limit={10}
							/>

							{/*
							<AdItem
								desktopAdId={4403482}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>
							*/}
						</div>

					</AppSideCol>
				</Row>

				{(!themeworld || themeworld.css ) ? null :
				 <style dangerouslySetInnerHTML={{ __html: themeworld.css }} />
				}

			</PageWrapper>
		);
	}

	/**
	 * A callback after selecting tag to apply its' value to promotions list filter
	 */
	private changeActiveTag = (tag: ITagType): void => {
		// To switch of if the tag was already selected
		if (this.state.activeTag?.id === tag.id) {
			this.setState({
				activeTag: null
			});
			return;	
		}
		
		this.setState({
			activeTag: tag
		});
	}


	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { themeworld } = this.props;

		return [
			{
				label: 'Themenwelten',
				route: 'ThemeworldListingRoute',
			} as IBreadcrumbItemType,
			{
				label: (themeworld) ? themeworld.title : 'Detail',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkThemeworldListWidget = (item: IThemeworldWidgetType) => {
		return {
			name: 'ThemeworldDetailRoute',
			params: {
				themeworld: item.slug,
			},
		};
	};

	/**
	 * Track view
	 */
	private trackThemeworldView = () => {
		const { themeworld } = this.props;

		if (!themeworld) {
			return;
		}

		if (tracked[ themeworld.id ]) {
			return;
		}

		if (themeworld.leaflet) {
			this.trackThemeworldAsLeaflet(themeworld.leaflet);
		}

		tracked[ themeworld.id ] = true;

		InternalTrackingService.track('TOPICWORLD_VIEW', {
			themeWorldId: themeworld.id,
		});
		DataLayerService.push('TOPICWORLD_VIEW', {
			themeWorldId: themeworld.id,
			themeWorldTitle: themeworld.title,
		});
	};

	/**
	 * Tracks the opening of the Themeworld if it's used as leaflet
	 */
	private trackThemeworldAsLeaflet = (leaflet: ILeafletType) => {
		InternalTrackingService.track('LEAFLET_OPENING', {
			leafletId: leaflet.id,
			vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
			industry_slug: leaflet.vendorIndustrySlugs?.join(','),
		});

		DataLayerService.push('LEAFLET_OPENING', {
			leafletId: leaflet.id,
			leafletTitle: leaflet.title,
			vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
			vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
			industrySlug: leaflet.vendorIndustrySlugs?.join(','),
			leafletSubtitle: leaflet.subtitle,
		});
	}

	/**
     * Track promotion click out
     */
	private trackPromotionClick = (promotion: IProductPromotionType) => {
		const { themeworld } = this.props;

		// Only for Themeworld with leaflet (=we are in a Promotionworld)
		if (!themeworld?.leaflet) {
			return;
		}

		const leaflet = themeworld.leaflet;

		InternalTrackingService.track('CLICKOUT_LEAFLET', {
			leafletId: leaflet.id,
			leafletPageId: leaflet.id,
			targetUrl: promotion.clickoutUrl,
			industry_slug: leaflet.vendorIndustrySlugs?.join(','),
		});

		DataLayerService.push('CLICKOUT_LEAFLET', {
			leafletId: leaflet.id,
			leafletTitle: leaflet.title,
			vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
			vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
			industrySlug: leaflet.vendorIndustrySlugs?.join(','),
			pageNumber: leaflet.id,
			targetUrl: promotion.clickoutUrl,
		});
	};
}

export default ThemeworldDetail;
