import { IGeolocationType } from "../../types/IGeolocationType";

/* ### CONSTANTS ### */

export const GEOLOCATION_FETCH_ENTRIES = '@BasePipeline/geolocationReducer_FETCH_ENTRIES';
export const GEOLOCATION_SET_DATA  = '@BasePipeline/geolocationReducer_SET_DATA';

export const GEOLOCATION_SET_ERRORS   = '@BasePipeline/geolocationReducer_SET_ERRORS';

/* ### ACTIONS ### */

// TODO: refactor this to use only reducer and sagas to get the location - not really important, but cleaner
export function fetchGeolocations (queryKey: string) {
	return {
		type: GEOLOCATION_FETCH_ENTRIES,
		queryKey,
	};
}

export function setGeolocation (geolocation: IGeolocationType, expiry: 'short' | 'medium' | 'long' | null = 'long') {
	return {
		type: GEOLOCATION_SET_DATA,
		geolocation,
		expiry,
	};
}
