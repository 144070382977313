import * as React from 'react';
import * as styles from './MixedEntityGrid.scss';
import MixedEntityItemSkeleton from '../MixedEntityGridItem/MixedEntityItemSkeleton';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';

interface IMixedEntityGridSkeletonProps {
	limit?: number;
	title?: string;
	moreLabel?: string;
	columns?: '1' | '2' | '3' | '4' | '5';
	mobileScrollable?: boolean;
}

class MixedEntityGridSkeleton extends React.Component<IMixedEntityGridSkeletonProps, {}> {
	public render () {
		const { title, mobileScrollable, limit } = this.props;
		const items = Array.from(Array(limit ?? 10).keys());

		return (
			<div>
				{(!title) ? null :
					<SectionTitle
						title={title}
						boxed={true}
					/>
				}

				<div className={`${styles.MixedEntityGridWrapper} ${mobileScrollable ? styles.MixedEntityGridWrapperScrollable : ''}`}>
					{
						items.map((_, indx: number) => {
							return (
								<MixedEntityItemSkeleton key={`mixed-entity-skeleton-${indx}`} />
							);
						})
					}
				</div>
				
			</div>
		);
	}
}

export default MixedEntityGridSkeleton;


