import * as React from 'react';
import Button from '../../../../components/General/Button/Button';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import InputField from '../../../../components/Layout/Form/InputField';
import H1 from '../../../../components/Layout/H1/H1';
import InfoScreenWrapper from '../../../../components/Layout/InfoScreenWrapper/InfoScreenWrapper';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import AlertService from '../../../Base/services/AlertService';
import AccountService from '../../services/AccountService';

interface IAccountChangeEmailPropsType {
	data: IAccountInfoType,
}

interface IAccountChangeEmailStateType {
	email: string,
	loading: boolean,
	success: boolean,
}

class AccountChangeEmail extends React.Component<IAccountChangeEmailPropsType, IAccountChangeEmailStateType> {
	constructor(props: IAccountChangeEmailPropsType) {
		super(props);

		this.state = {
			email: props.data.email + '',
			loading: false,
			success: false,
		};
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {

		return [
			{
				label: 'Mein Benutzerkonto',
				route: 'AccountInfoRoute',
			} as IBreadcrumbItemType,
			{
				label: 'E-Mail Ändern',
			} as IBreadcrumbItemType,
		];
	}

	public render () {
        const { loading, success } = this.state;

        if (success) {
        	return (
        		<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
			        <InfoScreenWrapper>
			            <H1 align="center">E-mail Adresse geändert</H1>
				        <p>
							Wir haben dir eine E-Mail zur Bestätigung der Änderung deiner E-Mail Adresse zugesandt.
							Bitte klicke auf den Link um die Änderung durchzuführen.<br /><br />
							Solltest Du die E-Mail nicht erhalten, prüfe bitte auch deinen Spam-Ordner.
				        </p>

				        <Button
                            theme="primary"
					        size="xxl"
					        fullWidth={true}
					        loading={loading}
					        route="AccountInfoRoute"
				        >
					        Weiter
				        </Button>
					</InfoScreenWrapper>
				</PageWrapper>
			);
		}

		return (
			<PageWrapper hideLocation={true} fluid={true}>
				<H1 align="center">
					Email ändern
				</H1>

				<FormWrapper
					onSubmit={this.onSubmit}
				>

					<InputField
						title="E-Mail"
						name="email"
						placeholder="email@example.com"
						type="email"
						onChange={this.onChangeInput}
						value={this.state.email}
						required={true}
					/>

					<p>&nbsp;</p>

                    <Button
                        theme="primary"
                        size="xxl"
                        fullWidth={true}
                        type="submit"
                        loading={loading}
                    >
                        Änderungen speichern
                    </Button>

                    <Button
                        theme="default"
                        size="xxl"
                        fullWidth={true}
                        loading={loading}
                        route="AccountInfoRoute"
                    >
                        Abbrechen
                    </Button>

				</FormWrapper>
			</PageWrapper>
		);
	}

	/**
	 * Submit form
	 */
	private onSubmit = () => {

		const { email } = this.state;

		if (email && email !== '' && !/\S+@\S+\.\S+/.test(email)) {
			AlertService.error(
				'error-email',
				'Bitte gib eine gültige E-mail Adresse sein',
			);
			return
		}

		this.setState({ loading: true });

		const data = Object.assign({}, this.props.data, { email });

		AccountService.changeAccountData(data, (response: any) => {

			this.setState({ loading: false });

			if (response) {

				this.setState({
					success: true,
				});
			}

		}, (error: any) => {

			this.setState({ loading: false });

			AlertService.error(
				'error-' + error,
				error,
			);
		});
	}

	/**
	 * Update input field
	 *
	 * @param name
	 * @param value
	 */
	private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[name]: value
		});
	}
}

export default AccountChangeEmail;
