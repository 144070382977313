import * as React from 'react';
import { IShopType } from '../../types/IShopType';
import * as styles from './ShopListItemLarge.scss';
import OpeningHours from '../OpeningHours/OpeningHours';
import { getClickoutGatewayUrl } from '../../../../utils/clickoutGatewayGenerator';

interface IShopLargeProps {
	shop: IShopType;
}


class ShopListItemLarge extends React.Component<IShopLargeProps, {}> {
	public render() {
		const { shop } = this.props;

		// if (!shop.legacyOpeningHours && !shop.phone && !shop.email && !shop.url) {
		// 	return null;
		// }

		const validExternalUrl = ( shop.vendor && shop.vendor.openingHoursURL ) ? shop.vendor.openingHoursURL.indexOf('http') : null ;
		const openingHoursUrl= ( validExternalUrl !== -1 ) ? shop.vendor.openingHoursURL : `https://${shop.vendor.openingHoursURL}`;
		const openingHoursReadableStep1 = openingHoursUrl && openingHoursUrl.split('//')[1];
		const openingHoursReadable = (openingHoursReadableStep1) ? openingHoursReadableStep1.split('/')[0] : '';

		return (
			<div>
				<div className={styles.shopListItemLargeContainer}>
					{!shop.legacyOpeningHours && !shop.openingHoursPeriods ? null : (
						<div className={styles.shopListItemLargeItem}>
							{shop.openingHoursPeriods.length > 0 ? (
								<div className={styles.shopListItemLargeOpened}>
									<OpeningHours shop={shop} mode="all" opennedLabel="geöffnet" />
								</div>
							) : (
								<div
									className={styles.shopListItemLargeHours}
									dangerouslySetInnerHTML={{ __html: shop.legacyOpeningHours }}
								/>
							)}
						</div>
					)}

					<div className={`${styles.shopListItemLargeItem} ${styles.shopListItemLargeSubDetails}`}>

						{!shop.phone ? null : (
							<div className={styles.shopListItemLargeRow}>
								<div className={styles.shopListItemLargeRowLabel}>Tel.:</div>
								<div className={styles.shopListItemLargeRowValue}>
									<a href={`tel:${shop.phone}`}>{shop.phone}</a>
								</div>
							</div>
						)}


						{!shop.email ? null : (
							<div className={styles.shopListItemLargeRow}>
								<div className={styles.shopListItemLargeRowLabel}>E-Mail:</div>
								<div className={styles.shopListItemLargeRowValue}>
									<a href={`mailto:${shop.email}`}>{shop.email}</a>
								</div>
							</div>
						)}

						{!openingHoursReadable ? null : (
							<div className={styles.shopListItemLargeRow}>
								<div className={styles.shopListItemLargeRowLabel}>Web:</div>
								<div className={styles.shopListItemLargeRowValue}>
									<a href={getClickoutGatewayUrl(openingHoursUrl)} target="_blank" rel="nofollow noopener noreferrer">
										{openingHoursReadable}
									</a>
								</div>
							</div>
						)}
						</div>
				</div>
			</div>
		);
	}
}

export default ShopListItemLarge;
