import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import PromotionsAggregatedByBrandsWidgetContainer
	from '../../containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IPromotionsFromProductGroupAndBrandProps {
	brandSlug: SlugType;
	productGroupSlug: SlugType;
}

class PromotionsFromProductGroupAndBrand extends React.Component<IPromotionsFromProductGroupAndBrandProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, productGroupSlug, brandSlug } = this.props;

		if (!routeMetadata) {
			return null;
		}

		// const brandName = (routeMetadata && routeMetadata.brand && routeMetadata.brand.title) ? routeMetadata.brand.title : brandSlug;
		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridContainer
								limit={20}
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								radius={100000}
								brandSlug={brandSlug}
								productGroupSlug={productGroupSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByBrandsWidgetContainer
								title={`Andere ${productGroupName} Marken in Aktion`}
								widgetKey={`ppcb-${routeMetadata.queryKey}-brand`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								productGroupSlug={productGroupSlug}
								excludeSlug={brandSlug}
								onlyNearby={true}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.brand.title: '',
				route: 'BrandDetailRoute',
				routeParams: {brand: this.props.brandSlug},
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.productGroup.title: '',
				route: 'ProductGroupDetailRoute',
				routeParams: {productGroup: this.props.productGroupSlug},
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.pageTitle: '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the brand list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { productGroupSlug } = this.props;

		return {
			name: 'PromotionsFromProductGroupAndBrandRoute',
			params: {
				productGroup: productGroupSlug,
				brand: item.group.slug,
			},
		};
	}
}

export default PromotionsFromProductGroupAndBrand;
