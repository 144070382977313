import * as React from 'react';
import * as styles from './Menu.scss';
import Icon from '../../../modules/Base/components/Icon/Icon';
import Link from '../../../modules/Base/components/Link/Link';

interface IMenuItemProps {
	title?: string;
	routeLink?: string;
	routeHref?: string;
	onClick?: (value?: any) => void;
	onCloseMenu?: () => void;
	children?: React.ReactNode;
	visible: boolean;
	href?: string;
	isCategory?: boolean; // Shows the link to another menu
	categoryData?: {};
	onlyMobile?: boolean;
	showIcon?: boolean;
}

class MenuItem extends React.Component<IMenuItemProps, {}> {

	public static defaultProps = {
		isCategory: false,
		title: '',
		routeLink: 'HomeRoute',
		visible: true,
		onlyMobile: false,
		showIcon: true
	};

	public render() {

		const { title, routeLink, onClick, children, isCategory, visible, onlyMobile, href, routeHref, showIcon } = this.props;

		let style = styles.menuGroupItem;

		if (isCategory) {
			style += ` ${styles.menuGroupItemCategory}`;
		}

		if (onlyMobile) {
			style += ` ${styles.menuGroupItemMobile}`;
		}

		if (!visible) {
			return null;
		}

		if (onClick) {
			return (
				<a className={style} onClick={this.handleClick}>

					{showIcon ? (
						<span className={styles.menuGroupItemIcon}>
							<Icon icon="arrow-right" className={styles.menuGroupItemIconRowArrow} />
						</span>
					) : null}
					<span className={styles.menuGroupItemTitle}>
						{title}

						{children}
					</span>

				</a>
			);
		}

		if (href) {
			return (
				<a
					className={style}
					href={href}
					target="_blank"
					rel="nofollow noopener noreferrer"
					onClick={this.linkHandleClick}
				>
					{showIcon ? (
						<span className={styles.menuGroupItemIcon}>
							<Icon icon="arrow-right" className={styles.menuGroupItemIconRowArrow} />
						</span>
					) : null}
					<span className={styles.menuGroupItemTitle}>
						{title}

						{children}
					</span>

				</a>
			);
		}

		const linkProps = {};

		if (routeLink) {
			linkProps.route = routeLink;
		}

		if (routeHref) {
			linkProps.href = routeHref;
		}

		return (
			<Link
				{...linkProps}
			>
				<a
					className={style}
					onClick={this.handleClick}
				>

					{showIcon ? (
						<span className={styles.menuGroupItemIcon}>
							<Icon icon="arrow-right" className={styles.menuGroupItemIconRowArrow} />
						</span>
					) : null}

					<span className={styles.menuGroupItemTitle}>
						{title}

						<span className={styles.LinkInner}>
							{children}
						</span>
					</span>

				</a>
			</Link>
		);
	}

	/**
	 * Handles a click on item
	 */
	private linkHandleClick = (event?: any) => {
		// if (event) {
		// 	event.preventDefault();
		// }

		this.handleClick(event);
	};

	/**
	 * Handles a click on item
	 */
	private handleClick = (event?: any) => {
		const { onClick, categoryData, onCloseMenu } = this.props;
		
		if (onClick) {
			onClick(categoryData);
		}

		if (onCloseMenu) {
			onCloseMenu();
		}
	};
}

export default MenuItem;
