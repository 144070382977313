import * as React from 'react';
import * as styles from './AccountInfo.scss';
import DataBlock from '../DataBlock/DataBlock';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import Modal from '../../../Base/components/Modal/Modal';
import DeleteAccount from '../DeleteAccount/DeleteAccount';

interface IAccountInfoProps {
    data: IAccountInfoType,
    showDeleteAccountModal: boolean
}

interface IAccountInfoState {
    showDeleteAccountModal: boolean,
}

class AccountInfo extends React.Component<IAccountInfoProps, IAccountInfoState> {

    constructor(props: IAccountInfoProps) {
        super(props);

        this.state = {
            showDeleteAccountModal: false,
        };
    }

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {

		return [
			{
				label: 'Mein Benutzerkonto',
				// route: 'AccountInfoRoute',
			} as IBreadcrumbItemType,
			// {
			// 	label: 'Allgemeine Daten Ändern',
			// } as IBreadcrumbItemType,
		];
	}

    public render() {

        const { data } = this.props;

        const { showDeleteAccountModal } = this.state;

        return (
            <PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
                <H1 align="center">
                    Meine Daten
				</H1>

                <div className={styles.accountInfoWrapper}>
                    <div className={styles.accountInfoInformation}>
                        Hier findest du einen Überblick über die von dir auf Aktionsfinder gespeicherten Daten. Wenn du dein Benutzerkonto löscht, werden auch alle hier angezeigten Daten gelöscht oder unkenntlich gemacht.
                    </div>

                    <DataBlock
                        title="Allgemein"
                        dataObject={{
                            Vorname: data.firstName,
                            Nachname: data.lastName
                        }}
                        linkRoute="AccountChangeDataRoute"
                    />

                    <DataBlock
                        title="Passwort"
                        dataString="****** (verschlüsselt)"
                        linkRoute="AccountChangePasswordRoute"
                    />

                    <DataBlock
                        title="E-Mail Adresse"
                        dataString={data.email}
                        linkRoute="AccountChangeEmailRoute"
                    />

                    {/* TODO: Add real data */}

                    {/* <DataBlock
                        title="Letzte Suchbegriffe"
                        dataObject={{
                            '19.10.2018': 'Billa',
                            '16.10.2018': 'Spar',
                            '15.10.2018': 'Billa',
                        }}
                        linkRoute="/"
                        linkTitle="alle anzeigen"
                        borderBottom={true}
                    /> */}


                    <div className={styles.accountInfoDeleteAccount}>
                        <a onClick={this.toggleInputModal}>
                            Benutzerkonto löschen?
                        </a>
                    </div>

                    {(!showDeleteAccountModal) ? null :
                        <Modal
                            content={DeleteAccount}
                            title="Schade!"
                            titleStyle="center"
                            onCloseModal={this.toggleInputModal}
                        />
                    }

                </div>
            </PageWrapper>
        );
    }


	/**
	 * Toggles the input modal visibility
	 */
    private toggleInputModal = () => {
        this.setState({
            showDeleteAccountModal: !this.state.showDeleteAccountModal,
        })
    };
}

export default AccountInfo;
