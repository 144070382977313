import { ActionType } from 'typesafe-actions';
import BaseReducer, { baseInitialState } from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';
import * as promotionsAggregatedByBrandsWidgetActions from './promotionsAggregatedByBrandsWidgetActions';

export type PromotionsAggregatedByBrandsWidgetAction = ActionType<typeof promotionsAggregatedByBrandsWidgetActions>;

export interface IPromotionsAggregatedByBrandsWidgetBrandType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IPromotionsAggregatedByBrandType };
	readonly queries: { [queryKey: string]: IPromotionsAggregatedByBrandType[] };
}

export function promotionsAggregatedByBrandsWidgetReducer(
	state = baseInitialState as IPromotionsAggregatedByBrandsWidgetBrandType,
	action: PromotionsAggregatedByBrandsWidgetAction,
) {
	const Reducer = new BaseReducer('promotionsAggregatedByBrandsWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
