import { ActionType } from 'typesafe-actions';
import { baseInitialState } from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import ProductPromotionBaseReducer from '../ProductPromotionBaseReducer';
import * as productPromotionActions from './productPromotionActions';

export type ProductPromotionGridAction = ActionType<typeof productPromotionActions>;

export interface IPromotionStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IProductPromotionType };
	readonly queries: { [queryKey: string]: IProductPromotionType[] };
}

export function productPromotionReducer(
	state = baseInitialState as IPromotionStateType,
	action: ProductPromotionGridAction,
) {
	const Reducer = new ProductPromotionBaseReducer('productPromotionReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}
