import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 */
const createTelemetryService = () => {
    const initialize = (connectionString: string, browserHistory: History): void => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!connectionString) {
            throw new Error('ConnectionString key not provided');
        }

        reactPlugin = new ReactPlugin();
        appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                maxBatchInterval: 3000,
                extensions: [reactPlugin],
                loggingLevelTelemetry: 2,
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory,
                    },
                },
                enableAutoRouteTracking: true, // Track route changes in SPA
                autoTrackPageVisitTime: true,
                enableRequestHeaderTracking: true, // Default is false
                enableResponseHeaderTracking: true, // Default is false
            },
        });

        appInsights.loadAppInsights();
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
