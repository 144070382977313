import * as React from 'react';
import WidgetBox from '../WidgetBox/WidgetBox';
import WidgetBoxContent from '../WidgetBox/WidgetBoxContent';
import AppWidgetImg from './AppPromo.png';
import * as styles from './MobileAppWidget.scss';

class MobileAppWidget extends React.Component<{}> {
    public render() {
        return (
            <span className={styles.mobileAppWidgetWrapper}>
                <WidgetBox>
                    <WidgetBoxContent>
                        <p>&nbsp;</p>
                        <div className={styles.mobileAppWidget}>
                            <img src={AppWidgetImg} alt="Installiere die App" />

                            <a href="https://play.google.com/store/apps/details?id=at.aktionsfinder.app&hl=de" target="_blank" rel="nofollow noopener noreferrer" className={`${styles.mobileAppButton} ${styles.mobileAppAndroid}`} />

                            <a href="https://itunes.apple.com/at/app/aktionsfinder/id485749840?mt=8" target="_blank" rel="nofollow noopener noreferrer" className={`${styles.mobileAppButton} ${styles.mobileAppApple}`} />

                            <a href="https://appgallery.huawei.com/#/app/C101705493" target="_blank" rel="nofollow noopener noreferrer" className={`${styles.mobileAppButton} ${styles.mobileAppHuawei}`} />
                        </div>
                    </WidgetBoxContent>
                </WidgetBox>
            </span>
        );
    }
}

export default MobileAppWidget;
