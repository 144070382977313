import { DateTime } from 'luxon';
import * as React from 'react';
import appConfig from '../../../../config/appConfig';
import Link from '../../../Base/components/Link/Link';
import HumanReadableDate from '../../../Base/components/HumanReadableDate/HumanReadableDate';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import PromotionImage from '../PromotionImage/PromotionImage';
import * as styles from './ProductPromotionItemHorizontal.scss';
import BookmarkIconContainer from '../../../Bookmark/containers/BookmarkIcon/BookmarkIconContainer';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import PromotionBookmark from '../PromotionBookmark/PromotionBookmark';
import PromotionBadge from '../PromotionBadge/PromotionBadge';
import Currency from '../../../Base/components/Currency/Currency';
import Percentage from '../../../Base/components/Percentage/Percentage';

interface IProductPromotionItemHorizontalProps {
	promotion: IProductPromotionType,
	size: 's' | 'm' | 'l';
	useLeafletRoute?: boolean;
	onClick?: (promotion: IProductPromotionType) => void;
	showBookmarkIcon?: boolean;
	directClickout?: boolean;
}

class ProductPromotionItemHorizontal extends React.PureComponent<IProductPromotionItemHorizontalProps, {}> {
	public static defaultProps = {
		size: 'm',
	};

	public render () {
		const { promotion, showBookmarkIcon, directClickout } = this.props;

		const validTo = ( typeof promotion.validTo === 'string' ) ? DateTime.fromISO(promotion.validTo) : promotion.validTo; // TODO: remove this sooner or later, because with transformation in reducer this should always be a date

		const expired = DateTime.local().startOf('day') > validTo;

		const statusClassName = expired ? styles.inactiveItem : styles.activeItem;

		const ProductPromotionInnerItem = () => {
			return (
				<React.Fragment>
					<PromotionImage
						promotion={promotion}
						className={styles.productPromotionItemImage}
					/>

					<span className={styles.productPromotionItemContent}>
						<span className={styles.productPromotionItemLabelWrapper}>
							<span className={styles.productPromotionItemLabel}>
								{this.renderPromotionName()}
							</span>

							<PromotionBookmark />
						</span>

						{/* <ProductPromotionDiscountBar promotion={promotion} /> */}

						<span className={styles.productPromotionValidTime}>

							{( promotion.vendor && promotion.vendor.resolutions) ? 
								<ImageEnhanced
									source={promotion.vendor.resolutions}
									className={styles.productPromotionVendorItemImage}
									alt={promotion.vendor.name}
									size={'s'}
								/> 
								: 
								`${promotion.vendorName}`
							}

							{( promotion.vendor && !promotion.vendor.resolutions && promotion.validTo) ? " – " : ""}

							<HumanReadableDate
								from={promotion.validFrom}
								to={promotion.validTo}
								outputIfEnded="abgelaufen"
								openValidity={promotion.openValidity}
							/>

							<span className={styles.productPromotionDiscount}>
								{this.getDiscountValue()}
							</span>

						</span>
						
						{!promotion.couponCode ? null :
							<span className={styles.productPromotionCouponCode}>
								<b>Code: </b>{promotion.couponCode}
							</span>
						}
					</span>

					<PromotionBadge promotion={promotion} />

					{/* <HumanReadableDate
						from={promotion.validFrom}
						to={promotion.validTo}
						outputIfEnded="abgelaufen"
						type="short"
						className={styles.productPromotionExtraTime}
						openValidity={promotion.openValidity}
					/> */}
				</React.Fragment>
			);
		}

		return (
			<div className={`${styles.productPromotionItemOuter} ${statusClassName}`}>
				{( !expired ) ? 

					( directClickout && promotion.clickoutUrl ) ? 
					
						<a
							id={`promotion-direct-link-${promotion.id}`}
							className={styles.productPromotionItem}
							href={promotion.clickoutUrl}
							target="_blank"
							rel="nofollow noopener noreferrer"
							onClick={this.handleOnClickEvent}
						>
							<ProductPromotionInnerItem />
						</a>

						:

						<Link
							{...this.getLinkParams()}
						>
							<a
								className={styles.productPromotionItem}
								onClick={this.handleOnClickEvent}
							>
								<ProductPromotionInnerItem />
							</a>
						</Link>

					:
					
					<span className={styles.productPromotionItem}>
						<ProductPromotionInnerItem />
					</span>
				}

				{( !showBookmarkIcon ) ? null : 
					<div className={styles.productPromotionPin}>
						<BookmarkIconContainer
							entity="promotion"
							slug={promotion.slug}
						/>
					</div>
				}

				<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: this.getSchemaOrgJson() }} />
			</div>
		);
	}

	/**
	 * Checks a promotion name and cut it, if it's very long
	 */
	private renderPromotionName = (): string => {
		const { promotion } = this.props;
		const promotionTitle = promotion.fullDisplayName ?? promotion.title;

		if (promotionTitle.length <= 40) {
			return promotionTitle;
		}
		
		return promotionTitle.substr(0, promotionTitle.lastIndexOf(" ", 45)) + ' ...';
		// Alternative way to cut long strings
		// return string.replace(/^(.{40}[^\s]*).*/, "$1") + "...";
	}

	/**
	 * Returns the correct schema org data
	 */
	private getSchemaOrgJson(): string {
		const { promotion } = this.props;

		const productGroup = (promotion.productGroups && promotion.productGroups[0]) ? promotion.productGroups[0].title : null;

		let lowPrice = (promotion.discountedPrice) ? promotion.discountedPrice : promotion.newPrice;
		if (!lowPrice && promotion.originalPrice) {
			lowPrice = promotion.originalPrice;
		}

		if (!lowPrice) {
			return '';
		}

		if (promotion.type === 'new_price'
			|| promotion.type === 'product') {
			return JSON.stringify({
				"@context": "http://schema.org",
				"@type": "Product",
				"image": promotion.mainImage,
				"name": promotion.fullDisplayName,
				"offers": {
					"@type": "AggregateOffer",
					"priceCurrency": (promotion.currency) ? promotion.currency.iso : 'EUR',
					"validFrom": promotion.validFrom,
					"priceValidUntil": promotion.validTo,
					"highPrice": (promotion.originalPrice) ? promotion.originalPrice : undefined,
					"category": productGroup,
					"lowPrice": lowPrice,
					"offers": [
						{
							"@type": "Offer",
							"url": `${appConfig.mainDomain}/p/${promotion.slug}`,
						}
					]
				}
			});
		}

		return '';
	}

	/**
	 * Takes the promotion type and returns the correct route to link them to
	 */
	private getLinkParams(): { [key: string]: unknown } {

		const { promotion, useLeafletRoute } = this.props;

		// TODO: Change the page value as soon as API is ready for it
		if (useLeafletRoute && promotion.leaflet) {
			return {
				href: `/l/${promotion.leaflet.slug}/#${((promotion.leaflet.coverPage?.pageNumber || 0) + 1)}`
			}
		}

		return {
			route: 'PromotionDetailRoute',
			params: { promotion: promotion.slug }
		}
	}

	/**
	 * Handles the onclick event on this promotion
	 */
	private handleOnClickEvent = () => {
		const { onClick, promotion } = this.props;

		if (onClick) {
			onClick(promotion);
		}
	};

	/**
	 * Returns the correct discount value based on the available discounts we have
	 *
	 * @return {string}
	 */
	private getDiscountValue(): string | React.ReactNode {
		const { promotion } = this.props;

		// if (promotion.discountedPrice) {
		// 	return (
		// 		<Currency
		// 			amount={promotion.discountedPrice}
		// 			currency={promotion.currency}
		// 		/>
		// 	);
		// }

		if (promotion.newPrice) {
			return (
				<Currency
					amount={promotion.newPrice}
					currency={promotion.currency}
				/>
			);
		}

		if (promotion.discountAmount) {
			return (
				<Currency
					amount={promotion.discountAmount}
					currency={promotion.currency}
				/>
			);
		}

		if (promotion.discountPercentage) {
			return <Percentage amount={promotion.discountPercentage} />;
		}

		return '';
	}
}

export default ProductPromotionItemHorizontal;
