import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addThemeworldWidgetEntries,
	THEMEWORLD_WIDGET_FETCH_ENTRIES, setThemeworldWidgetErrors,
} from './themeworldWidgetActions';

/**
 * Fetch entry list
 */
function* fetchThemeworldWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = action.params;

	// if we have an order
	if (params.orderBy && (params.orderBy === 'createdAt' || params.orderBy === 'rank')) { // TODO: fix this to make it usable for all of them
		params.sort = params.orderBy + ','+ ((params.orderDir) ? params.orderDir : 'desc');
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: '/api/public/v1/widgets/themeworlds',
				params,
			} as IRequestObject);
		});

		yield put(addThemeworldWidgetEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setThemeworldWidgetErrors(action.queryKey, e));
	}
}

export function* themeworldWidgetSaga() {
	yield takeEvery(THEMEWORLD_WIDGET_FETCH_ENTRIES, fetchThemeworldWidget);
}
