import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsFromProductGroupAndBrand
	from '../App/modules/Promotion/components/PromotionsFromProductGroupAndBrand/PromotionsFromProductGroupAndBrand';
import {
	pluralizerPromotionsAndSpecials,
	maxDiscountInTitle,
	withClickbait,
	withTitlePostfix,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


const today = new Date()
const currentMonth = today.toLocaleString('default', { month: 'long' });

class PromotionsFromProductGroupAndBrandRoute extends BaseRoute {
	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<PromotionsFromProductGroupAndBrand
							productGroupSlug={query.productGroup}
							brandSlug={query.brand}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PromotionsFromProductGroupAndBrandRoute,
		(props: any) => `ppcb-${props.match.params.brand}-${props.match.params.productGroup}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.brand.title} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.brand.title} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle ${routeMetadata.brand.title} ${routeMetadata.productGroup.title} Aktionen im ${currentMonth} auf einen Blick » Händler finden ⭐ Preise vergleichen ⭐ Geld sparen`, `⭐ Auf Schnäppchenjagd gehen!`),
		} as IRouteMetadataSetterType,
	),
);
