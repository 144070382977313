import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { SlugType } from '../../../Base/types/SlugType';
import BrandListWidget from '../../components/BrandListWidget/BrandListWidget';
import { IBrandType } from '../../types/IBrandType';
import { IBrandWidgetType } from '../../types/IBrandWidgetType';
import DataStateService from '../../../../services/DataStateService';

interface IBrandListWidgetContainerProps {
	title: string;
	widgetKey: string;
	limit?: number;
	style?: IWidgetStyleType;
	orderBy?: 'relevance' | 'created' | 'published';
	orderDir?: 'asc' | 'desc';

	city?: string; // the city in which we are looking
	manufacturerSlug?: SlugType; // brands by manfacturer
	productGroupSlug?: SlugType; // beloging to product group
	shopSlug?: SlugType; // beloging to speicif cstore
	vendorSlug?: SlugType; // beloging to speicif cstore
	excludeSlug?: SlugType; // ids to exclude from this list
	brandSlug?: SlugType;

	similar?: boolean;

	routeCallbk: (item: IBrandType) => { name: string, params: {[key: string]: any} },
}

class BrandListWidgetContainerInner extends React.Component<IBrandListWidgetContainerProps & IWithDataProps> {
	public render () {
		const { data, ...restProps } = this.props;

		if (data.length < 1) {
			return null;
		}
		
		return (
			<BrandListWidget
				brands={data}
				{...restProps}
			/>
		);
	}
}

export default function BrandListWidgetContainer (props: IBrandListWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;

	const RenderComponent = withData(
		BrandListWidgetContainerInner,
		'brandWidgetReducer',
		`brand-cloud-widget-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			manufacturer: props.manufacturerSlug,
			product_group: props.productGroupSlug,
			store: props.shopSlug,
			store_vendor: props.vendorSlug,
			orderBy: props.orderBy,
			orderDir: props.orderDir,
			brand: props.brandSlug,
			exclude: props.excludeSlug,
			city: props.city,
			similar: props.similar,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={limit}
					widgetKey={props.widgetKey}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={limit}
					widgetKey={props.widgetKey}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
