import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ContentFragmentContainer from '../App/modules/ContentFragment/containers/ContentFragment/ContentFragmentContainer';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import BaseRoute from './_BaseRoute';


class FeedbackRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await ContentFragmentProps(state, "app_feedback");

		return { reduxData };
	}

	public render () {

		return (
			<div>
				<Header />
				<MainNavigation />

				<ContentFragmentContainer
					fragmentKey="app_feedback"
					pageTitle="Feedback"
				/>

				<Footer />
			</div>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		FeedbackRoute,
		`feedback`,
		{
			metaTitle: `Dein Feedback | Aktionsfinder`,
			metaDescription: `Sie wollen uns eine Rückmeldung zu unserem Service geben? Das können Sie jederzeit schnell und unkompliziert online machen! » Jetzt mehr erfahren.`,
		} as IRouteMetadataSetterType
	)
);
