import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import LotteryRegistration from '../App/modules/Lottery/components/LotteryRegistration/LotteryRegistration';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfig from '../App/components/General/AdConfig/AdConfig';


class LotteryRegistrationRoute extends BaseRoute {
	public render () {

		return (
			<React.Fragment>
				<AdConfig
					desktopIds={[4493474]}
					sitebarId={4493473}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<LotteryRegistration />

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493473}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>
			</React.Fragment>
		);
	}
}

export default withRouter(LotteryRegistrationRoute);
