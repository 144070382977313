import * as React from 'react';
import * as styles from './SearchPageFilterGroupCheckboxGroupItem.scss';
import Checkbox from '../../../../../components/Layout/Checkbox/Checkbox';
import { SearchPageFilterInputType } from '../SearchPageFilterGroup';

export type SearchPageFilterValueType = string | string[];

export type SearchPageFilterItemMetadataType = {
	count: number;
	slug: string;
	title: string;
};

interface ISearchPageFilterGroupCheckboxGroupItemProps {
	metadataKey: string;
	values: SearchPageFilterValueType;
	type: SearchPageFilterInputType;
	metadata: SearchPageFilterItemMetadataType;
	onChangeValue: (metadataKey: string, newValue: SearchPageFilterValueType) => void;
}

class SearchPageFilterGroupCheckboxGroupItem extends React.Component<ISearchPageFilterGroupCheckboxGroupItemProps, {}> {
	public render() {
		const { metadataKey, type, metadata } = this.props;

		const componentProps = {
			checked: this.isSelected(),
			name: `field-${metadataKey}-${metadata.slug}`,
		};

		return (
			<div className={styles.ItemWrapper}>
				{(type === 'multiselect') ?
					<Checkbox
						{...componentProps}
						className={styles.FilterCheckbox}
						style="v3"
						onChange={(k: string, checked: boolean) => { this.setArrayValue(checked) }}
					>
						{metadata.title}
						{(metadata.count < 1) ? null : <span>&nbsp;({metadata.count})</span>}
					</Checkbox>
					:
					<Checkbox
						{...componentProps}
						className={styles.FilterCheckbox}
						style="v3"
						type="radio"
						onChange={(k: string, checked: boolean) => { this.setValue(checked) }}
					>
						{metadata.title}
						{(metadata.count < 1) ? null : <span>&nbsp;({metadata.count})</span>}
					</Checkbox>
				}
			</div>
		);
	}

	/**
	 * Just passes up the new value.
	 * This can be used, when we have caluclation, that cannot be done using setArrayValue easily.
	 * So just make it in the FilterInput itself and pass the final result to this method.
	 *
	 * @param isChecked
	 */
	private setValue = (isChecked: boolean) => {
		const { onChangeValue, metadataKey, metadata } = this.props;

		if (!onChangeValue) {
			return;
		}

		onChangeValue(
			metadataKey,
			(isChecked) ? metadata.slug : null,
		);
	}

	/**
	 * Sets the value based on the input type and structure
	 * by either adding it to an array or setting the value.
	 * But also unsetting it, if necessary.
	 *
	 * @param isChecked
	 */
	private setArrayValue = (isChecked: boolean) => {
		const { onChangeValue, values, metadataKey, metadata } = this.props;
		const checkedValue = metadata.slug;

		// if we don't do anything with it -> just stop here
		if (!onChangeValue) {
			return;
		}

		// correct possibly wrong format and make sure we have an array
		const currentValues = (!values) ? [] : (Array.isArray(values) ? values : [values]);

		// if we added something
		if (isChecked) {
			// if checkbox is checked (we want to add the value) -> add it only if it doesn't exist in the values yet
			if (currentValues.indexOf(checkedValue) === -1) {
				currentValues.push(checkedValue);

				// pass the value up
				onChangeValue(metadataKey, currentValues);
			}
		} else {
			// otherwise -> remove it from array
			const finalValues = currentValues.filter((item: string) => {
				return (item !== checkedValue);
			});

			// pass the value up
			onChangeValue(metadataKey, finalValues);
		}
	}

	/**
	 * Checks whether this content item is selected
	 *
	 * @return {boolean}
	 */
	private isSelected(): boolean {
		const { values, metadata } = this.props;

		// if we have single values, compare them
		if (!Array.isArray(values)) {
			return (values === metadata.slug);
		}

		// if we have an array -> compare array items
		for (const value of values) {
			if (value === metadata.slug) {
				return true;
			}
		}

		return false;
	}
}

export default SearchPageFilterGroupCheckboxGroupItem;
