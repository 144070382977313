import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import VoucherPage from '../App/modules/Promotion/components/VoucherPage/VoucherPage';
import { currentEntryWord, pluralizerVouchers } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class VoucherListingRoute extends BaseRoute {
	render() {
		return (

			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<VoucherPage
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
	public static async getInitialProps(state: any) {
		const reduxData = await VoucherListingRouteProps(state);

		return { reduxData };
	}
}

export default withRouter(
	withRouteMetadata(
		VoucherListingRoute,
		`gutscheine`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${pluralizerVouchers(routeMetadata.totalItemsCount)} in deiner Nähe`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${pluralizerVouchers(routeMetadata.totalItemsCount)} einlösbar in Österreich | Aktionsfinder`,
			metaDescription: `Aktuelle Gutscheine für Top-Aktionen » bei Aktionsfinder informieren ✓ Preise gegenüberstellen ✓ bares Geld sparen ✓ Den besten Deal schnappen!`,
		} as IRouteMetadataSetterType,
	),
);
