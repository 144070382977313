import * as React from 'react';
import * as styles from './Overlay.scss';
import { ReactComponentElement } from 'react';

interface IOverlayProps {
	children?: ReactComponentElement<any, any>;
	onClick?: () => void;
	onMouseleave?: () => void;
	style?: any;
	className?: string;
}

class Overlay extends React.PureComponent<IOverlayProps> {
	public constructor(props: IOverlayProps) {
		super(props);

		document.body.style.position = "fixed";
		document.body.style.overflow = "auto";

		// Adding scroll spacing on the right only if body height is bigger than viewport height
		if (document.body.offsetHeight > window.innerHeight) {
			document.body.style.overflowX = "hidden";
			document.body.style.overflowY = "scroll";
		}
	}

	public componentWillUnmount() {
		document.body.style.overflow = "auto";
		document.body.style.position = "static";
	}

	public render() {
		const { onClick, onMouseleave, style, className, children } = this.props;

		return (
			<div
				className={`${styles.Overlay} ${className}`}
				onClick={onClick}
				onMouseLeave={onMouseleave}
				style={style}
			>
				{children}
			</div>
		);
	}
}

export default Overlay;
