/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { ILeafletsAggregatedByProductGroupType } from '../../types/ILeafletsAggregatedByProductGroupType';

export const LEAFLETS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_FETCH_ENTRIES = '@BasePipeline/leafletsAggregatedByProductGroupsWidgetReducer_FETCH_ENTRIES';
export const LEAFLETS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_ADD_ENTRIES = '@BasePipeline/leafletsAggregatedByProductGroupsWidgetReducer_ADD_ENTRIES';

export const LEAFLETS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_SET_ERRORS   = '@BasePipeline/leafletsAggregatedByProductGroupsWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchLeafletsAggregatedByProductGroupsWidgets (queryKey: string) {
	return {
		type: LEAFLETS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addLeafletsAggregatedByProductGroupsWidgetEntries (queryKey: string, entries: ILeafletsAggregatedByProductGroupType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLETS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setLeafletsAggregatedByProductGroupsWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLETS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
