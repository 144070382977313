import * as React from 'react';
import Icon from '../Icon/Icon';
import * as styles from './LocationInputField.scss';

interface ILocationInputFieldProps {
	onChange: (value: string) => void;
	value: string;
	label?: string;
	placeholder?: string;
	style?: 'light' | 'default';
	autoFocus?: boolean;
	onClickCancel: () => void;

}

// TRANSLATE
class LocationInputField extends React.Component<ILocationInputFieldProps> {

	public static defaultProps = {
		style: 'default',
	};

	constructor(props: any) {
		super(props);
	}

	public render() {
		const { value, label, placeholder, style, autoFocus, onClickCancel } = this.props;

		return (
			<div>
				{(!label) ? null :
					<div className={styles.locationInputFieldTitle}>{label}</div>
				}

				<div className={styles.locationInputFieldWrapper}>
					<label
						className={styles.locationInputFieldIcon}
					>
						<Icon icon="search" />
					</label>

					<input
						type="text"
						name="search"
						placeholder={placeholder}
						autoComplete="off"
						autoFocus={autoFocus}
						className={`${styles.locationInputFieldField} ${(style === 'light') ? styles.styleLight : ''}`}
						onChange={this.onChange}
						value={value}
					/>

					<label
						className={styles.locationInputFieldIconCancel} 
						onClick={onClickCancel}
					>
						<Icon icon="cancel"/>
					</label>

				</div>

			</div>
		);
	}

	/**
	 * Handles onchange events
	 * @param e
	 */
	private onChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { onChange } = this.props;

		if (onChange) {
			onChange(e.currentTarget.value);
		}
	};

}

export default LocationInputField;
