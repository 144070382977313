import * as React from 'react';
import * as styles from '../AppExclusive.scss';
import H1 from '../../../../../components/Layout/H1/H1';
import AdvantageCard from './AdvantageCard/AdvantageCard';

class AppExclusiveAdvantages extends React.PureComponent {

    public render() {

        return (
            <div className={styles.advantageWrapper}>
                <H1>
                    Deine Vorteile
                </H1>
                <div className={styles.advantageCardWrapper}>
                    <span>
                        <AdvantageCard imageSrc={require('../assets/advantage1.png')} title='Prospekte & Aktionen unterwegs immer mit dabei' />
                    </span>
                    <span>
                        <AdvantageCard imageSrc={require('../assets/advantage2.png')} title='Merke dir Lieblingsprospekte als deine persönlichen Favoriten' />
                    </span>
                    <span>
                        <AdvantageCard imageSrc={require('../assets/advantage3.png')} title='Mit einem Klick zur Einkaufsliste hinzufügen.' />
                    </span>
                </div>
            </div>
        );
    }
}

export default AppExclusiveAdvantages;