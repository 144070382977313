import * as React from 'react';
import { ReactNode } from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import { IWidgetStyleType } from '../../../types/IWidgetStyleType';
import * as styles from './WidgetBox.scss';

interface IWidgetListItemLinkRowProps {
	children?: ReactNode,
	title?: string,
	subtitle?: string | ReactNode,
	href?: string,
	route?: string,
	routeParams?: any,
	style?: IWidgetStyleType,
}

class WidgetListItemLinkRow extends React.Component<IWidgetListItemLinkRowProps> {
	public static defaultProps = {
		// showArrow: true,
	};

	public render() {
		const { route, href, routeParams, style } = this.props;

		const className = `${styles.widgetListItemLinkOnelineRow} ${styles['widgetListItemStyle-' + style]}`;

		if (href) {
			return (
				<Link
					href={href}
				>
					<a href="#" className={className}>
						{this.getLinkContent()}
					</a>
				</Link>
			);
		}

		if (!route) {
			return (
				<a href="#" className={className}>
					{this.getLinkContent()}
				</a>
			);
		}

		return (
			<Link
				route={route}
				params={routeParams}
			>
				<a className={className}>
					{this.getLinkContent()}
				</a>
			</Link>
		);
	}

	/**
	 * Returns the content of our link row
	 */
	private getLinkContent() {
		const { children, title, subtitle } = this.props;

		return (
			<span className={styles.widgetListItemLinkRowWrapper}>
				{(children) ? children : null}

				{(!title) ? null :
					<span className={styles.widgetListItemLinkRowTitle}>{title}&nbsp; </span>
				}

				{(!subtitle) ? null :
					<span className={styles.ListItemSubtitle}>
						{subtitle}
					</span>
				}
			</span>
		);
	}
}

export default WidgetListItemLinkRow;
