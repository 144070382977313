import * as React from 'react';
import * as styles from './NoJsWarning.scss';


class NoJsWarning extends React.PureComponent<{}, {}> {
	public render () {

		return (
			<noscript>
				<div className={styles.noJsWarning}>
					In deinem Browser scheint Javascript deaktiviert zu sein, daher stehen nicht alle Funktionen zur Verfügung!<br />
					Bitte aktiviere Javascript in deinem Browser oder verwende einen modernen Browser!
				</div>
			</noscript>
		);
	}
}

export default NoJsWarning;
