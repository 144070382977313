import * as React from 'react';
import { ReactNode, ReactNodeArray } from 'react';
import * as styles from './PageHeading.scss';
import H1 from '../H1/H1';

interface IPageHeadingProps {
	title: string;
	children?: ReactNode | ReactNodeArray;
	titleAlign: 'left' | 'center' | 'right';
}

class PageHeading extends React.Component<IPageHeadingProps, {}> {
	public static defaultProps = {
		titleAlign: 'left'
	};

	public render() {
		const { title, children, titleAlign } = this.props;

		return (
			<React.Fragment>
				<div className={styles.TitleSpacer} />

				<div className={styles.PageHeading}>
					<H1 align={titleAlign} className={styles.Title}>{title}</H1>

					{ (!children) ? null :
						<div className={styles.PageHeadingAction}>
							{children}
						</div>
					}
				</div>
			</React.Fragment>
		);
	}
}

export default PageHeading;
