import * as React from 'react';
import { ReactChild } from 'react';
import Col from '../../../modules/Base/components/Grid/Col';
import * as styles from './AppSideCol.scss';

export interface IAppSideColProps {
	children: ReactChild;
	boxed?: boolean,
	onTop?: boolean, // whether there should be margin on top to mask it. If this is true, all margins on top will be removed and it will align with ß
	h1Outside?: boolean, // Defines whether the titel on this page is outside of the boxed element. If so, we need to push this box down a little more
	darkBackground?: boolean,
	colWrapper?: boolean; // should the sidecol also use the col wrapper element (default: true)
	background?: 'default' | 'dark' | 'body' | 'nostyle',
}

class AppSideCol extends React.Component<IAppSideColProps, {}> {
	public static defaultProps = {
		colWrapper: true,
	};

	public render() {
		const { children, boxed, h1Outside, onTop, darkBackground, background, colWrapper } = this.props;

		let mainClass = '';

		if (background === 'body') {
			mainClass += ` ${styles.sideColOuterBodyBackground}`
		} else if (background === 'nostyle') {
			mainClass += ` ${styles.sideColNoStyle}`;
		} else if (!darkBackground || background === 'dark') {
			mainClass += ` ${styles.sideColOuter}`
		} else {
			mainClass += ` ${styles.sideColOuterDarkBackground}`
		}

		let boxedStyle = (h1Outside) ? styles.boxedH1Outside : styles.boxed;

		if (onTop) {
			boxedStyle = styles.boxedOnTop;
		}

		if (!colWrapper) {
			return (
				<aside className={(boxed) ? `${boxedStyle} sideColBoxed` : ''}>
					<div className={styles.sideColGradient} />

					{children}
				</aside>
			);
		}

		return (
			<Col size={3} width="33%" className={`sideCol ${mainClass}`}>
				<aside className={(boxed) ? `${boxedStyle} sideColBoxed` : ''}>
					<div className={styles.sideColGradient} />

					{children}
				</aside>
			</Col>
		);
	}
}

export default AppSideCol;
