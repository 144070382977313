import { addAlert } from '../store/Alert/alertActions';
import CookieService from '../../../services/CookieService';
import ReduxService from '../../../../boilerplate/redux/services/ReduxService';

const AlertService = {
	add (id: string, title: string, message: string = '', level: 'success' | 'error' | 'info' = 'success', timeoutInSeconds: number = 5, icon: boolean | string = false) {
		ReduxService.dispatch(
			addAlert(id, title, message, level, timeoutInSeconds, icon),
		);
	},

	success (id: string, title: string, message: string = '', timeoutInSeconds: number = 5, icon: boolean | string = false) {
		this.add(id, title, message, 'success', timeoutInSeconds, icon);
	},

	error (id: string, title: string, message: string = '', timeoutInSeconds: number = 5, icon: boolean | string = false) {
		this.add(id, title, message, 'error', timeoutInSeconds, icon);
	},

	info (id: string, title: string, message: string = '', timeoutInSeconds: number = 5, icon: boolean | string = false) {
		this.add(id, title, message, 'info', timeoutInSeconds, icon);
	},

	/**
	 * TODO: Move this out of the service. This is a very specific use case of alert service, that should rather be in wrapper or init location component
	 * Shows location alert on page init
	 */
	locationAlert () {
		if (!CookieService.get("lctalrt")) {
			setTimeout(() => {
				AlertService.info(
					'location',
					'Ich zeige dir Aktionen in…',
				);
				CookieService.set('lctalrt', 'true');
			}, 500);
		}
	}
};

export default AlertService;
