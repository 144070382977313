import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import { withClickbait, withTitlePostfix } from '../App/utils/routeMetadataUtils';
import * as queryString from 'query-string';
import { withRouter } from 'react-router';
import BaseRoute from './_BaseRoute';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import SearchPageContainerOuter from '../App/modules/Search/containers/SearchPageContainer/SearchPageContainerOuter';

class SearchRoute extends BaseRoute {
	public render () {
		const { location } = this.props;
		const query = (location) ? queryString.parse(location.search) : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4493482]}
					mobileIds={[4493492]}
					sitebarId={4493481}
				/>

				<Header />
				<MainNavigation
					hideOnMobile={true}
				/>

				<FlexWrapper>
					<div>
						<SearchPageContainerOuter
							searchTerm={query.q + ''}
							queryParams={query}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493481}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(withRouteMetadata(
	SearchRoute,
	(props: any) => `search-${props.match.params.q}`,
	{
		metaTitle: (routeMetadata: IRouteMetadataType, currentProps: any) => {
			const params = queryString.parse(currentProps.location.search);

			return withTitlePostfix(`Suche: ${params.q}`);
		},
		metaDescription: (routeMetadata: IRouteMetadataType, currentProps: any) =>
			withClickbait(`Finde tolle Angebote und Aktionen in deiner Nähe.`, `✓ Hier erfahren Sie mehr!`),
	} as IRouteMetadataSetterType,
));
