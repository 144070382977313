import * as React from 'react';
import * as styles from './MixedEntityItem.scss';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';

class MixedEntityItemSkeleton extends React.Component<{}, {}> {
	public render () {

		return (
			<div className={styles.MixedEntityItemWrapper}>
				<span
					className={styles.MixedEntityItemWrapperInner}
				>
					<span className={styles.ImageWrapper}>
						<SkeletonBlock style={{height: 100 +'%'}} />
					</span>

					<span className={styles.Content}>
						<span className={styles.Heading}>
							<SkeletonInline width={85} />
						</span>

						<span className={styles.ActionBar}>
							<span className={styles.ActionBarShrink}>
								<SkeletonInline width={40} />&nbsp;&nbsp;
							</span>

							<span className={styles.ActionBarGrow}>
								<SkeletonInline width={76} />
							</span>
						</span>

						<span className={styles.ActionBar}>
							<span className={styles.ActionBarGrow}>
								 <span className={`${styles.Line} ${styles.Price} `}>
									 <SkeletonInline width={10} />
								 </span>

								 <span className={`${styles.Line} ${styles.OldPrice} `}>
									 <SkeletonInline width={15} />
								 </span>
							</span>
							<span className={styles.ActionBarShrink}>&nbsp;</span>
						</span>
					</span>
				</span>
			</div>
		);
	}

}


export default MixedEntityItemSkeleton;


