/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { ILeafletType } from '../../types/ILeafletType';
import { ILeafletViewerUIState } from './leafletViewerReducer';
import { IdType } from '../../../Base/types/IdType';

export const LEAFLET_VIEWER_INIT_SLIDES = '@BasePipeline/leafletViewerReducer_INIT_SLIDES';
export const LEAFLET_VIEWER_GO_TO_SLIDE = '@BasePipeline/leafletViewerReducer_GO_TO_SLIDE';
export const LEAFLET_VIEWER_GO_TO_PAGE = '@BasePipeline/leafletViewerReducer_GO_TO_PAGE';
export const LEAFLET_VIEWER_SET_ERRORS = '@BasePipeline/leafletViewerReducer_SET_ERROR';


/* ### ACTIONS ### */

/**
 * Initiates current leaflet
 *
 * @param leafletId
 * @param slideStates
 * @param currentSlide
 */
export function initLeafletViewerSlides (leafletId: IdType, slideStates: { [indx: number]: ILeafletViewerUIState }, currentSlide = 0) {
	return {
		type: LEAFLET_VIEWER_INIT_SLIDES,
		slideStates,
		currentSlide,
		leafletId,
	};
}

export function goToLeafletViewerSlide (newSlideIndx: number) {
    return {
        type: LEAFLET_VIEWER_GO_TO_SLIDE,
        slideIndx: newSlideIndx,
    };
}

export function goToLeafletViewerPage (newPageNumber: number) {
    return {
        type: LEAFLET_VIEWER_GO_TO_PAGE,
        pageNumber: (newPageNumber - 1),
    };
}

export function setLeafletErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LEAFLET_VIEWER_SET_ERRORS,
		queryKey,
		errors,
		metaData,
		options,
	};
}
