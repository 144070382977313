import * as React from 'react';
import { ReactNode } from 'react';
import Intro from '../PageWrapper/Intro';
import * as styles from './H1.scss';

interface IH1Props {
	children?: ReactNode[] | ReactNode | string[],
	align?: 'left' | 'center' | 'right',
	title?: string,
	className?: string,
}

class H1 extends React.PureComponent<IH1Props, {}> {
	public render() {
		const { children, align, title, className } = this.props;

		const alignClass = (styles['h1TitleAlign-' + align]) ? styles['h1TitleAlign-' + align] : '';

		if (title) {
			return (
				<div className={`${styles.withIntro} ${className}`}>
					<h1 className={`${styles.h1Title} h1Title ${alignClass}`}>
						{title}
					</h1>
					<Intro>
						{children}
					</Intro>
				</div>
			);
		}

		return (
			<h1 className={`${styles.h1Title} h1Title ${alignClass} ${className}`}>
				{children}
			</h1>
		);
	}
}

export default H1;
