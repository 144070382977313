import * as React from 'react';
import { withRouter } from 'react-router';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import Footer from '../App/components/Layout/Footer/Footer';
import ForgottenPassword from '../App/modules/Account/components/ForgottenPassword/ForgottenPassword';
import BaseRoute from './_BaseRoute';


class ForgottenPasswordRoute extends BaseRoute {
	public render() {
		return (
			<div>
				<Header />
				<MainNavigation />

				<ForgottenPassword />

				<Footer fluid={true} />
			</div>
		);
	}
}

export default withRouter(ForgottenPasswordRoute);
