import * as React from 'react';
import MostUsedQuery from '../MostUsedQuery/MostUsedQuery';
import * as styles from './MostUsedQueries.scss';

interface IMostUsedQueriesProps {
	queries: [];
	title: string;
	widgetKey?: string;
}

class MostUsedQueries extends React.Component<IMostUsedQueriesProps, {}> {
	public static defaultProps = {
		widgetKey: 'search-page',
	};

	public render() {
        const { queries, title, widgetKey } = this.props;

		return (
            <div className={styles.mostUsedQueriesWrapper}>

                {( !title ) ? null :
                 <h3 className={styles.mostUsedQueriesTitle}>
                    {title}
                 </h3>
                }
                {queries.map((query: string) => {

                    return (
                        <MostUsedQuery
                            query={query}
                            key={`${widgetKey}-most-used-queries-${query}`}
                        />
                    );
                })}
           </div>
		);
    }
}

export default MostUsedQueries;
