import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RouteMetadataService from '../../../../services/RouteMetadataService';
import GeolocationService from '../../../Base/services/GeolocationService';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import {
	addPromotionEntries, addPromotionEntry,
	PRODUCT_PROMOTION_FETCH_ENTRIES, PRODUCT_PROMOTION_FETCH_ENTRY, setPromotionErrors,
} from './productPromotionActions';

/**
 * Fetch entry list
 */
function* fetchPromotions(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	// add geolocation params
	let theUrl =  '/api/public/v1/promotions/';
	let params = action.params;

	if (action.params.onlyNearbyPromotions) {
		const geolocationReducer = yield select((state: any) => state.geolocationReducer);
		params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

		theUrl =  '/api/public/v1/promotions/nearby';
	}

	if (action.params.themeworld) {
		theUrl =  `/api/public/v1/themeworlds/${action.params.themeworld}/promotions`;
	}

	if (action.params.leaflet) {
		theUrl =  `/api/public/v1/leaflets/${action.params.leaflet}/promotions`;
	}

	// add default params
	params.include_ended = true;
	params.include_publish_to_all =  true;

	// if we have an order
	if (params.orderBy && params.orderBy === 'createdAt') { // TODO: fix this to make it usable for all of them
		params.sort = params.orderBy + ','+ ((params.orderDir) ? params.orderDir : 'desc');
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: theUrl,
				params,
			} as IRequestObject);
		});

		const content = response.getContent('content');

		yield put(addPromotionEntries(
			action.queryKey,
			content,
			response.getMetaData(),
			action.options,
		));

		// put route metadata
		if (action.params.pushRouteMetadata) {
			// get max discount
			let maxDiscount = 0;
			for (const item of content) {
				if (item.discountPercentage > maxDiscount) {
					maxDiscount = Math.round(item.discountPercentage);
				}
			}

			const totalActiveElements = response.getContent('totalActiveElements');

			RouteMetadataService.setMetadata(action.queryKey, {
				totalItemsCount: typeof totalActiveElements !== "undefined" ? totalActiveElements : response.getContent('totalElements'),
				fullItemsCount: response.getContent('totalElements'),
				maxItemDiscount: maxDiscount,
			} as IRouteMetadataType);
		}
	} catch (e) {
		yield put(setPromotionErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry detail
 */
function* fetchPromotion(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	// add geolocation params
	const theUrl =  `/api/public/v1/promotions/${action.params.promotion}`;

	let params = action.params;
	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: theUrl,
				params,
			} as IRequestObject);
		});

		yield put(addPromotionEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionErrors(action.queryKey, e));
	}
}

export function* productPromotionSaga() {
	yield takeEvery(PRODUCT_PROMOTION_FETCH_ENTRY, fetchPromotion);
	yield takeEvery(PRODUCT_PROMOTION_FETCH_ENTRIES, fetchPromotions);
}
