import * as React from 'react';
import MenuCategory from './MenuCategory';
import SkeletonInline from '../Skeleton/SkeletonInline';
import * as styles from './Menu.scss';
import Icon from '../../../modules/Base/components/Icon/Icon';

interface IMenuSkeletonProps {
    title: string
}

class MenuSkeleton extends React.Component<IMenuSkeletonProps, {}> {

    public render () {

        const { title } = this.props;

        const limitArr = Array.from(Array(10).keys());

        return (
            <div>
                <MenuCategory title={title}>

                    {limitArr.map((index: number) => {
                        return (
                            <a className={styles.menuGroupItem} key={index}>
                                <span className={styles.menuGroupItemIcon}>
                                    <Icon icon="arrow-right" className={styles.menuGroupItemIconRowArrow} />
                                </span>
                                <span className={styles.menuGroupItemTitle}>
                                    <SkeletonInline />
                                </span>
                            </a>
                        )
                        })
                    }

                </MenuCategory>
            </div>

        );
    }
}

export default MenuSkeleton;
