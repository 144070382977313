import { IRouteMetadataType } from '../modules/Base/types/IRouteMetadataType';
import ReduxService from '../../boilerplate/redux/services/ReduxService';
import { setRouteMetadata } from '../modules/Base/store/RouteMetadata/routeMetadataActions';

export const entitiesToResolve = {
    vendor: 'vendor',
    store_vendor: 'store_vendor',
    productGroup: 'productGroup',
    shop: 'store',
    brand: 'brand',
    industry: 'industry',
    city: 'city',
    state: 'state',
    shoppingCenter: 'shoppingCenter',
    tag: 'tag',
    manufacturer: 'manufacturer',
    themeworld: 'themeWorld',
    promotion: 'promotion',
};

const SimpleEntityDetailService = {
	fetchEntities(queryKey: string, props: {[key: string]: any}, fetchRouteMetadatas:  (queryKey: string, props: any) => any) {
		const routerProps = (props.match && props.match.params) ? props.match.params : {};

		const paramObj = {};

		let i = 0;
		for (const key of Object.keys(entitiesToResolve)) {
			if (routerProps[key]) {
				paramObj[entitiesToResolve[key]] = routerProps[key];
				i++;
			}
		}

		// only call it, when there are at least 1 entries
		if (i > 0) {
            fetchRouteMetadatas(queryKey, paramObj);
        }
        // else -> just add an empty value
        else {
            ReduxService.dispatch(setRouteMetadata(queryKey, {} as IRouteMetadataType));
        }
	},

	/**
	 * Tries to get the entity details from reducer or will return fallback values from slug to make sure we get at least something
	 *
	 * @param props
	 */
	resolveEntities(props: {[key: string]: any}) {
		const retObj = {};

		// if we don't have a router elemnent -> nothing to do here
		if (!props.match || !props.match.params) {
			return retObj;
		}

		// otherweise loop through all entities we want to resolve and check if we have something for them
		for (const entityName of Object.keys(entitiesToResolve)) {
			if (props.match.params[entityName]) {
				retObj[`${entityName}Slug`] = props.match.params[entityName];

				retObj[entityName] =  {
					name: props.match.params[entityName][0].toUpperCase() + props.match.params[entityName].slice(1),
					title: props.match.params[entityName][0].toUpperCase() + props.match.params[entityName].slice(1),
				}
			}
		}

		return retObj;
	},


};

export default SimpleEntityDetailService;
