import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import BrandListItemSkeleton from '../../../Brand/components/BrandListItem/BrandListItemSkeleton';
import { IVendorType } from '../../../Vendor/types/IVendorType';
import VendorBookmarkListingPage from '../../components/VendorBookmarkListingPage/VendorBookmarkListingPage';
import VendorBookmarkListingPageSkeleton
	from '../../components/VendorBookmarkListingPage/VendorBookmarkListingPageSkeleton';
import BookmarkService from '../../services/BookmarkService';

interface IVendorBookmarkListingPageContainerProps {
	data?: IVendorType[],
	limit?: number;
}

class VendorBookmarkListingPageContainerInner extends React.Component<IVendorBookmarkListingPageContainerProps & IWithDataProps> {
	public render() {
		const { data, metadata, fetchMore, ...restProps } = this.props;

		return (
			<Pagination
				LoaderComponent={<BrandListItemSkeleton />}
				fetchMore={fetchMore}
				metadata={metadata}
			>
				<VendorBookmarkListingPage
					bookmarks={this.getOnlyBookmarkedEntries(data)}
					{...restProps}
				/>
			</Pagination>
		);
	}

	/**
	 * Make sure only bookmarked vendors are shown.
	 * This is helpful if the user removes an item from bookmarks, to make sure it disappears right away.
	 *
	 * @param entries
	 */
	private getOnlyBookmarkedEntries = (entries: IVendorType[]) => {
		const entriesFormatted = entries.map((entry: any) => {
			return (entry.vendor) ? entry.vendor : entry;
		});

		return entriesFormatted.filter((entry: IVendorType) => {
			return BookmarkService.isMarked('vendor', entry.slug);
		});
	}
}

export default function VendorBookmarkListingPageContainer (props: IVendorBookmarkListingPageContainerProps) {
	const limit = ( props.limit ) ? props.limit : 16;

	const RenderComponent = withData(
		VendorBookmarkListingPageContainerInner,
		'vendorBookmarkReducer',
		`vendorBookmarkListing`,
		{
			size: limit,
		},
		{
			additionalStateProperties: ['updateQuery'],
			LoadingComponent: () => (
				<VendorBookmarkListingPageSkeleton />
			),
		}
	);

	return <RenderComponent {...props} />
}
