/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IVendorType } from '../../types/IVendorType';

export const VENDOR_FETCH_ENTRIES = '@BasePipeline/vendorReducer_FETCH_ENTRIES';
export const VENDOR_FETCH_ENTRY   = '@BasePipeline/vendorReducer_FETCH_ENTRY';
export const VENDOR_ADD_ENTRIES = '@BasePipeline/vendorReducer_ADD_ENTRIES';
export const VENDOR_ADD_ENTRY = '@BasePipeline/vendorReducer_ADD_ENTRY';

export const VENDOR_SET_ERRORS   = '@BasePipeline/vendorReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchVendors (queryKey: string) {
	return {
		type: VENDOR_FETCH_ENTRIES,
		queryKey,
	};
}

export function addVendorEntries (queryKey: string, entries: IVendorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addVendorEntry (queryKey: string, entry: IVendorType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setVendorErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: VENDOR_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
