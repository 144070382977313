import * as React from 'react';
import * as styles from './FormWrapper.scss';
import { ReactNode } from 'react';
import NoJsWarning from '../NoJsWarning/NoJsWarning';

interface IFormWrapperProps {
  children: ReactNode;
  onSubmit?: () => void,
  noStyles?: boolean,
}

class FormWrapper extends React.PureComponent<IFormWrapperProps, {}> {

  public static defaultProps = {
    noStyles: false,
  };

  public render () {
    const { children, noStyles, location } = this.props;


    let style = styles.formBlock;

    if ( noStyles ) {
      style = '';
    }

    return (
      <React.Fragment>
        <form
          action="#"
          className={style}
          onSubmit={this.onSubmit}
          method="POST"
        >
          {children}
        </form>

        <NoJsWarning />
      </React.Fragment>
    );
  }

  private onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const { onSubmit } = this.props;

    if (!onSubmit) {
      return;
    }

    onSubmit();
  }

}

export default FormWrapper;

