import * as React from 'react';
import { connect } from 'react-redux';
import bookmarkConfig from '../../../../config/bookmarkConfig';
import AccountService from '../../../Account/services/AccountService';
import { SlugType } from '../../../Base/types/SlugType';
import BookmarkIcon from '../../components/BookmarkIcon/BookmarkIcon';
import { addBookmarkEntry, removeBookmarkEntry } from '../../store/Bookmark/bookmarkActions';
import routes, { Router } from '../../../../routes';
import { BookmarkEntityType } from '../../types/BookmarkEntityType';
import { RouterProps, withRouter } from 'react-router';

interface IBookmarkIconContainerProps {
	className?: string;
	entity: BookmarkEntityType,
	slug: SlugType,
	label?: string;

	// added by reducer
	isActive?: boolean;
	component?: any;
	bookmarkReducer?: any,
	addBookmark?: (entity: BookmarkEntityType, slug: SlugType) => void;
	removeBookmark?: (entity: BookmarkEntityType, slug: SlugType) => void;
}

class BookmarkIconContainer extends React.Component<IBookmarkIconContainerProps & RouterProps> {
	public render() {
		const { entity, className, component, label } = this.props;
		const theBookmarkEntry = this.getBookmarkedEntry();

		return (
			<BookmarkIcon
				isActive={theBookmarkEntry}
				entity={entity}
				label={label}
				className={className}
				component={component}
				addBookmark={this.addBookmark}
				removeBookmark={this.removeBookmark}
			/>
		);
	}

	/**
	 * Toggles the bookmark status
	 */
	private addBookmark = (event) => {
		event.stopPropagation();
		event.preventDefault();

		const { addBookmark, slug, entity } = this.props;

		if (!this.redirectIfNotLoggedin() || !addBookmark) {
			return;
		}

		addBookmark(entity, slug);
	};

	/**
	 * Toggles the bookmark status
	 */
	private removeBookmark = (event) => {
		event.stopPropagation();
		event.preventDefault();

		const { removeBookmark, slug, entity } = this.props;

		if (!this.redirectIfNotLoggedin() || !removeBookmark) {
			return;
		}

		removeBookmark(entity, slug);
	};

	/**
	 * If the user is not logged in -> redirect him to the bookmark page to see the access denied error
	 */
	private redirectIfNotLoggedin() {
		const { entity } = this.props;

		if (AccountService.check()) {
			return true;
		}

		const route = bookmarkConfig.entities[entity].route;

		this.props.history.push(routes[route]);

		// Router.pushRoute(route);
	}

	/**
	 * Tries to get the selected entry bookmark
	 */
	private getBookmarkedEntry() {
		const { bookmarkReducer, slug, entity } = this.props;

		return (bookmarkReducer && bookmarkReducer.entryMap && bookmarkReducer.entryMap[entity] && bookmarkReducer.entryMap[entity][slug]) ? bookmarkReducer.entryMap[entity][slug] : null;
	}
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
		addBookmark: (entity: BookmarkEntityType, slug: SlugType) => {
			dispatch(addBookmarkEntry(entity, slug));
		},
		removeBookmark: (entity: BookmarkEntityType, slug: SlugType) => {
			dispatch(removeBookmarkEntry(entity, slug));
		},
	};
}

function mapStateToProps(state: any) {
	return {
		bookmarkReducer: state.bookmarkReducer,
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookmarkIconContainer));
