import { call, put, fork, cancel, take, select } from 'redux-saga/effects';
import AfCoreRequest from '../../../../../../App/bootstrap/redux-data/AfCoreRequest';
import { isServer } from '../../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import { IRequestObject } from '../../../../../../boilerplate/redux/types/IRequestObject';
import GeolocationService from '../../../../../modules/Base/services/GeolocationService';
import { addHomepageEntries, HOMEPAGE_FETCH_ENTRIES, setHomepageErrors } from './homepageActions';

/**
 * Fetch entry list
 */
function* fetchHomepages(action: any) {
    const afCoreRequest = new AfCoreRequest(action.queryKey);

    // add geolocation params
    const theUrl = '/api/public/v1/explore/';

    const geolocationReducer = yield select((state: any) => state.geolocationReducer);
    const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

    try {
        const response = yield call(
            (): Promise<RequestMiddlewarePipeline> => {
                return afCoreRequest.getRequest({
                    url: theUrl,
                    params,
                } as IRequestObject);
            },
        );

        if (isServer()) {
            response.addMetaData('reloadOnClient', true);
        }

        yield put(addHomepageEntries(action.queryKey, response.getContent(), response.getMetaData(), action.options));
    } catch (e) {
        yield put(setHomepageErrors(action.queryKey, e));
    }
}

export function* homepageSaga() {
    let lastTask;

    while (true) {
        const action = yield take(HOMEPAGE_FETCH_ENTRIES);

        if (lastTask) {
            yield cancel(lastTask);
        }

        lastTask = yield fork(fetchHomepages, action);
    }
}
