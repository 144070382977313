import * as React from 'react';
import { connect } from 'react-redux';
import { setAccountData } from '../../store/Account/accountActions';
import Login from '../../components/Login/Login';
import CookieService from '../../../../services/CookieService';
import { Redirect } from 'react-router';

interface ILoginContainerProps {
	accountReducer?: any,
    setAccountData: (account: IAccountLoginType) => void,
}

class LoginContainer extends React.Component<ILoginContainerProps, {}> {

	public render () {
		const { setAccountData } = this.props;
		
		const accessToken = CookieService.get('at');

		if (accessToken) {
			return <Redirect to='/' />
		}

		return (
            <Login 
                setAccountData={setAccountData}
            />
		);
	}
}

function mapDispatchToProps (dispatch: (actionType: any) => void) {
	return {
		setAccountData: (account: IAccountLoginType) => {
			dispatch(setAccountData(account));
		},
	};
}

function mapStateToProps (state: any) {
	return {
		accountReducer: state.accountReducer,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);