import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import ThemeworldDetail from '../../components/ThemeworldDetail/ThemeworldDetail';
import { SlugType } from '../../../Base/types/SlugType';

interface IThemeworldDetailProps {
	themeworldSlug: SlugType;
}

class ThemeworldDetailContainerInner extends React.Component<IThemeworldDetailProps & IWithDataProps> {
	public render() {
		const { data, ...restProps } = this.props;
		
		return (
			<ThemeworldDetail
				themeworld={data as IThemeworldType}
				{...restProps}
			/>
		);
	}
}

export default function ThemeworldDetailContainer(props: IThemeworldDetailProps) {
	const RenderComponent = withData(
		ThemeworldDetailContainerInner,
		'themeworldReducer',
		props.routeMetadata.queryKey,
		{
			themeworldSlug: props.themeworldSlug,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'themeworldSlug',
			LoadingComponent: () => (
				<ThemeworldDetail
					queryKey={props.routeMetadata.queryKey}
					themeworldSlug={props.themeworldSlug}
					routeMetadata={props.routeMetadata}
				/>
			)
		},
	);

	return <RenderComponent {...props} />
}

