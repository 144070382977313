import * as React from 'react';
import Icon from '../../../Base/components/Icon/Icon';
import Link from '../../../Base/components/Link/Link';
import { IShopType } from '../../types/IShopType';
import Distance from '../Distance/Distance';
import * as styles from './NearestShop.scss';

interface INearestShopProps {
	shop: IShopType,
}

class NearestShop extends React.Component<INearestShopProps, {}> {
	public static defaultProps = {
		size: 'm',
	};

	public render() {
		const { shop } = this.props;

		if (!shop || !shop.slug) {
			return null;
		}
		
		const shopTooltip = (shop && shop.address) ? `${shop.title} / ${shop.address}, ${shop.zipCode} ${shop.city}` : '';

		return (
			<div className={styles.shopListItemWrapper}>
				<Icon icon="direction-filled" className={styles.distanceIcon} />

				<span className={styles.shopListItemWrapperTooltip}>{shopTooltip}</span>

				<Link
					route="ShopDetailRoute"
					params={{
						shop: shop.slug,
					}}
				>

					<a className={styles.shopListItem}>
						<span className={styles.shopListItemTitle}>nächstgelegener Markt</span>
						<Distance
							distanceInMeters={shop.distance}
							hideIcon={true}
						/>
					</a>
				</Link>
			</div>
		);
	}
}

export default NearestShop;
