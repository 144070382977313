import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsInProductGroupAndCity
	from '../App/modules/Promotion/components/PromotionsInProductGroupAndCity/PromotionsInProductGroupAndCity';
import {
	pluralizerPromotionsAndSpecials,
	withTitlePostfix,
	withClickbait,
	maxDiscountInTitle,
	currentEntryWord
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class PromotionsInProductGroupAndCityRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await PromotionsInProductGroupAndCityRouteProps(state);

		return { reduxData };
	}

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>

						<PromotionsInProductGroupAndCity
							productGroupSlug={query.productGroup}
							citySlug={query.city}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}


export default withRouter(
	withRouteMetadata(
		PromotionsInProductGroupAndCityRoute,
		(props: any) => `ppcc-${props.match.params.productGroup}-${props.match.params.city}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => 
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} in ${routeMetadata.city.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.productGroup.title} Aktionen & Angebote in ${routeMetadata.city.title} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`Alle aktuellen ${routeMetadata.productGroup.title} Aktionen & Angebote in ${routeMetadata.city.title} » Preise vergleichen ✓ bares Geld sparen ✓ online informieren`, `✓ Jetzt mehr erfahren!`),
		} as IRouteMetadataSetterType,
	),
);
