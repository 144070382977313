import * as React from 'react';
import * as styles from './Drawer.scss';
import { ReactComponentElement } from 'react';

interface IDrawerContentOutsetProps {
	children: ReactComponentElement<any>;
}

class DrawerContentOutset extends React.PureComponent<IDrawerContentOutsetProps, {}> {
  public render() {
  	const { children } = this.props;

    return (
	    <div className={styles.DrawerContentOutset}>
	      {children}
	    </div>
    );
  }
}

export default DrawerContentOutset;
