import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addPromotionsAggregatedByStatesWidgetEntries,
	PROMOTIONS_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES, setPromotionsAggregatedByStatesWidgetErrors,
} from './promotionsAggregatedByStatesWidgetActions';

/**
 * Fetch entry list
 */
function* fetchPromotionsAggregatedByStatesWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const { params } = action;

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: '/api/public/v1/widgets/states',
					params,
				} as IRequestObject
			);
		});

		const theContent = response.getContent('content');

		yield put(addPromotionsAggregatedByStatesWidgetEntries(
			action.queryKey,
			theContent,
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionsAggregatedByStatesWidgetErrors(action.queryKey, e));
	}
}


export function* promotionsAggregatedByStatesWidgetSaga() {
	yield takeEvery(PROMOTIONS_AGGREGATED_BY_STATES_WIDGET_FETCH_ENTRIES, fetchPromotionsAggregatedByStatesWidget);
}
