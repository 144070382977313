import * as React from 'react';

interface ICurrencyProps {
	amount: number;
	alwaysNegative?: boolean;
	round?: boolean;
}

class Percentage extends React.PureComponent<ICurrencyProps> {
	public static defaultProps = {
		alwaysNegative: true,
		round: true,
	};

	public render () {
		const { amount, alwaysNegative, round } = this.props;

		const percentageFormat = Math.abs(amount)
		                             .toFixed(( round ) ? 0 : 2)
		                             .replace('.', ',');

		return (
			<span>{( alwaysNegative ) ? '- ' : ''}{percentageFormat} %</span>
		);
	}
}

export default Percentage;
