import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRowSkeleton from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRowSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { IPromotionsAggregatedByProductGroupType } from '../../types/IPromotionsAggregatedByProductGroupType';

// TODO: @Stefan: Make sure to also clean up interfaces, when you create components
interface IPromotionsAggregatedByProductGroupsWidgetProps {
    aggregatedEntries: IPromotionsAggregatedByProductGroupType[];
    title: string;
    widgetKey?: string;
    promotionLabel?: string;
    promotionLabelPlural?: string;
    style?: IWidgetStyleType;

    routeCallbk: (item: IPromotionsAggregatedByProductGroupType) => { href?: string, name?: string, params?: { [key: string]: any } },
}

class PromotionsAggregatedByProductGroupsWidgetSkeleton extends React.Component<IPromotionsAggregatedByProductGroupsWidgetProps, {}> {
    public static defaultProps = {
        widgetKey: 'promotion-list-widget',
        promotionLabel: 'Aktion',
        promotionLabelPlural: 'Aktionen',
    };

    public render() {
        const fakeEntries = Array(1).fill(' ');

        return (
            <WidgetBox>
                <WidgetBoxContent>
                    {fakeEntries.map((aggregatedEntry: IPromotionsAggregatedByProductGroupType, indx) => {

                        return (
                            <WidgetListItemLinkRowSkeleton
                                key={`${indx}-skeleton-widget`}
                            />
                        );
                    }
                    )}
                </WidgetBoxContent>
            </WidgetBox>
        );
    }
}

export default PromotionsAggregatedByProductGroupsWidgetSkeleton;
