import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import DataLayerService from '../../../Base/services/DataLayerService';
import LeafletViewerContentContainer from '../../containers/LeafletDetail/LeafletViewerContentContainer';
import { ILeafletType } from '../../types/ILeafletType';
import LeafletHeader from './LeafletHeader';
import { DateTime } from 'luxon';
import * as styles from './LeafletViewer.scss';
import AlertService from '../../../Base/services/AlertService';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';
import LeafletExternalTracker from './LeafletExternalTracker';
import { LeafletViewerType } from '../../hocs/withLeaflet';
import PromotionworldDetailContainer from '../../../Promotionworld/containers/PromotionworldDetail/PromotionworldDetailContainer';

interface ILeafletViewerPropsType {
	leaflet: ILeafletType;
	setLeafletDiscountData?: (leaflet: ILeafletType) => void;
	leafletViewerType: LeafletViewerType;
}

class LeafletViewerInner extends React.PureComponent<ILeafletViewerPropsType & IWithDataProps, {}> {
	public static defaultProps = {};

	/**
	 * the time the leaflet was opened (used to get reading duration)
	 */
	private leafletOpenTime?: DateTime;

	public componentDidMount() {
		const { leaflet, setLeafletDiscountData } = this.props;

		this.trackLeafletOpening();

		// Adding an alert to leaflet view
		if (leaflet.discountType && leaflet.discountType !== "NONE" && setLeafletDiscountData) {
			const title = (leaflet.discountType === "STICKER") ? "Rabattsticker im Postkasten!" : (leaflet.discountType === "VOUCHER") ? "Rabattgutschein im Postkasten!" : "";

			setLeafletDiscountData(leaflet);

			setTimeout(() => {
				AlertService.add(
					`${this.props.leaflet.slug}-discount-alert`,
					title,
					'',
					'info',
					undefined,
					'postkasten',
				);
			}, 1000);
		}

		if (!leaflet.promotionWorld) {
			document.body.style.position = "fixed";
			document.body.style.overflow = "auto";
		}
	}

	public componentWillUnmount() {
		document.body.style.overflow = "auto";
		document.body.style.position = "static";

		this.trackLeafletClose();
	}

	public render() {
		const { leaflet, leafletViewerType } = this.props;

		// Different content for promotionworlds and default one
		if (leaflet.promotionWorld) {
			return (
				<PromotionworldDetailContainer
					queryKey={`leaflet-themeworld-${leaflet.slug}`}
					leafletSlug={leaflet.slug}
				/>
			)
		}

		return (
			<PageWrapper noLayout={true}>
				<div className={styles.leafletViewer}>

					<LeafletHeader
						leaflet={leaflet}
						leafletViewerType={leafletViewerType}
					/>

					<LeafletViewerContentContainer
						leaflet={leaflet}
						queryKey={`leaflet-page-${leaflet.slug}`}
						leafletViewerType={leafletViewerType}
					/>

					<LeafletExternalTracker
						key={`et-ol-${leaflet.id}`}
						event="OPEN"
						leaflet={leaflet}
					/>
				</div>
			</PageWrapper>
		);
	}

	/**
	 * Tracks the opening of the leaflet
	 */
	private trackLeafletOpening() {
		const { leaflet, leafletViewerType } = this.props;

		if (!leaflet.promotionWorld) {
			InternalTrackingService.track('LEAFLET_OPENING', {
				leafletId: leaflet.id,
				infopost: leafletViewerType === LeafletViewerType.INFOPOST,
				vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
				industry_slug: leaflet.vendorIndustrySlugs?.join(','),
			});

			DataLayerService.push('LEAFLET_OPENING', {
				leafletId: leaflet.id,
				leafletTitle: leaflet.title,
				vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
				vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
				leafletSubtitle: leaflet.subtitle,
				industrySlug: leaflet.vendorIndustrySlugs?.join(','),
			});
		}

		// reset pages_read counter
		if (isBrowser()) {
			// update opening time
			this.leafletOpenTime = DateTime.local();

			localStorage.setItem('leafletPagesReadCounter', '0');
		}
	}

	/**
	 * Calls the leaflet close tracking event
	 */
	private trackLeafletClose() {
		const { leaflet, leafletViewerType } = this.props;

		if (!isBrowser()) {
			return;
		}

		// get read count
		const pagesReadCounter = localStorage.getItem('leafletPagesReadCounter');

		// get visit duration
		const visitDuration = (this.leafletOpenTime) ? DateTime.local().diff(this.leafletOpenTime, 'seconds').seconds : 1;

		// unset leaflet pages read counter
		localStorage.setItem('leafletPagesReadCounter', null);

		// track closing
		InternalTrackingService.track('LEAFLET_CLOSE', {
			leafletId: leaflet.id,
			visitDuration: visitDuration,
			readPages: leaflet.promotionWorld ? 1 : parseInt(pagesReadCounter, 10),
			infopost: leafletViewerType === LeafletViewerType.INFOPOST
		});
	}
}

export default LeafletViewerInner;
