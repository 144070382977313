import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { SlugType } from '../../../Base/types/SlugType';
import DataStateService from '../../../../services/DataStateService';
import LeafletWidget from '../../components/LeafletWidget/LeafletWidget';

interface ICurrentLeafletWidgetProps {
	title: string;
	widgetKey: string;

	limit?: number;
	orderBy?: 'rank' | 'validFrom' | 'createdAt';
	orderDir?: 'asc' | 'desc';

	vendorSlug?: SlugType;
	shopSlug?: SlugType;
	industrySlug?: SlugType;
	promotionSlug?: SlugType;
	brandSlug?: SlugType;

	onlyNearbyLeaflets?: boolean;
	includeEnded?: boolean;
}

class LeafletWidgetContainerInner extends React.Component<ICurrentLeafletWidgetProps & IWithDataProps> {
	public render () {
		const { data, ...restProps } = this.props;

		if (!data || data.length < 1) {
			return null;
		}

		return (
			<LeafletWidget
				leaflets={data}
				{...restProps}
			/>
		);
	}
}


export default function LeafletWidgetContainer (props: ICurrentLeafletWidgetProps) {
	const limit = ( props.limit ) ? props.limit : 1;

	const RenderComponent = withData(
		LeafletWidgetContainerInner,
		'leafletWidgetReducer',
		`leaflet-widget-${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			store_vendor: props.vendorSlug,
			store: props.shopSlug,
			industry: props.industrySlug,
			promotionSlug: props.promotionSlug,
			brand: props.brandSlug,
			onlyNearbyLeaflets: props.onlyNearbyLeaflets,
			include_ended: props.includeEnded,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={limit}
					type="linkrow"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: true,
						special: true,
						extra: true,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => {

				if (errorProps.errors && errorProps.errors[0] && errorProps.errors[0].status && errorProps.errors[0].status === 404) {
					return null;
				}

				return (
					<WidgetBoxError
						errors={errorProps.errors}
						title={props.title}
						rows={limit}
						type="item"
						widgetKey={props.widgetKey}
						showValues={{
							title: true,
							subtitle: true,
							special: true,
							extra: true,
						}}
					/>
				)
			},
		},
	);

	return <RenderComponent {...props} />;
}
