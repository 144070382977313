import * as React from 'react';
import * as styles from './ShopsByVendorAndCity.scss';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import ShopListWidgetContainer from '../../containers/ShopListWidgetContainer/ShopListWidgetContainer';
import ShopListContainer from '../../containers/ShopList/ShopListContainer';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import ProductPromotionListWidgetContainer from '../../../Promotion/containers/ProductPromotionListWidget/ProductPromotionListWidgetContainer';
import CurrentLeafletWidgetContainer from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';
import LeafletGridContainer from '../../../Leaflet/containers/LeafletGrid/LeafletGridContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IShopsByVendorAndCityProps {
	citySlug: SlugType;
	vendorSlug: SlugType;
}

interface IShopsByVendorAndCityState {
	pagination: boolean;
}

class ShopsByVendorAndCity extends React.Component<IShopsByVendorAndCityProps & IWithRouteMetadataType, IShopsByVendorAndCityState> {
	public static defaultProps = {};

	constructor (props) {
		super(props);
		this.state = {
			pagination: false
		};
	  }

	public render() {
		const { routeMetadata, vendorSlug, citySlug } = this.props;
		const { pagination } = this.state;

		const cityName =
			routeMetadata && routeMetadata.city && routeMetadata.city.title ? routeMetadata.city.title : citySlug;
		const vendorName =
			routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title ? routeMetadata.vendor.title : vendorSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403495}
					maxHeight={250}
					type="billboard"
				/>

				<H1>{routeMetadata.pageTitle}</H1>

				<Row>
					<AppMainCol boxed={true} onTop={true}>
						<AppMainColBox nested={true}>

							{/* <CityPostCodesWrapper postCodes={wienPostCodes} city="Wien" /> */}
						
							<ShopListContainer
								limit={3}
								enablePagination={pagination}
								vendorSlug={vendorSlug}
								citySlug={citySlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								design='large'
								contentBreaks={{
									2: (
										<div className={styles.leafletSpacing}>
											{(pagination) ? null :
												<div className={styles.shopMoreItem}>
													<span className={styles.shopMore} onClick={() => this.setState({pagination: !pagination})}>
														Alle {vendorName} Märkte in {citySlug}
													</span>
												</div>
											}
											<LeafletGridContainer
												title={`Aktuelle ${vendorName} Prospekte`}
												titleLevel={2}
												gridKey={routeMetadata.queryKey}
												columns="3"
												limit={3}
												boxed={true}
												nested={true}
												orderBy="rank"
												orderDir="desc"
												enablePagination={false}
												vendorSlug={vendorSlug}
												onlyNearbyLeaflets={false}
											>
												{null}
											</LeafletGridContainer>
										</div>
									),
									10: (
										<AdItem
											mobileAdId={4403482}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									30: (
										<AdItem
											mobileAdId={4403481}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)							
								}}
							/>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							<ProductPromotionListWidgetContainer
								widgetKey={`svc-product-promotion-list-${vendorSlug}`}
								title={`Aktuelle ${vendorName} Aktionen`}
								actionButtonLabel={`Alle aktuellen ${vendorName} Aktionen`}
								actionButtonRouteCallbk={this.routerCallbkPromotionsListWidget}
								limit={10}
								orderBy="createdAt"
								orderDir="desc"
								fetchPriceProductPromotion={true}
								vendorSlug={vendorSlug}
							/>

							<CurrentLeafletWidgetContainer
								widgetKey={`svc-leaflet-widget-${vendorSlug}`}
								title={`Die aktuellen ${vendorName} Prospekte`}
								btnLabel={`Alle ${vendorName} Prospekte`}
								vendorSlug={vendorSlug}
								limit={1}
								orderBy="validFrom"
								orderDir="asc"
								btnRoute="LeafletsByVendorRoute"
								btnParams={{
									vendor: vendorSlug
								}}
							/>

							<AdItem
								desktopAdId={4403494}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

 							<ProductPromotionListWidgetContainer
 								widgetKey={`svc-${vendorSlug}-list-assortments`}
								title={`Sortimentsaktionen bei ${vendorName}`}
								fetchAssortementProductPromotions={true}
								vendorSlug={vendorSlug}
								limit={10}
								actionButtonLabel={`Alle ${vendorName} Sortimentsaktionen`}
								actionButtonRouteCallbk={this.routerCallbkVendorListWidget}
								orderBy="createdAt"
								orderDir="desc"
								onlyNearbyPromotions={true}
							/>

							<ShopListWidgetContainer
								widgetKey={`svc-vendor-list-widget-${cityName}`}
								title={`Märkte anderer Händler in ${cityName}`}
								citySlug={citySlug}
								limit={5}
								excludeVendorSlug={vendorSlug}
								orderBy="relevance"
							/>
						</div>
					</AppSideCol>
				</Row>
			</PageWrapper>
		);
	}

	/**
	 * Defines the route the vendor list widget should be routed towards
	 */
	private routerCallbkPromotionsListWidget = () => {
		const { vendorSlug } = this.props;

		return {
			name: 'PromotionsByVendorRoute',
			params: {
				vendor: vendorSlug,
			},
		};
	};

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkVendorListWidget = () => {
		const { vendorSlug } = this.props;

		return {
			name: 'AssortementPromotionsByVendorRoute',
			params: {
				vendor: vendorSlug,
			},
		};
	};

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		const { routeMetadata, vendorSlug, citySlug } = this.props;

		const cityName =
			routeMetadata && routeMetadata.city && routeMetadata.city.title ? routeMetadata.city.title : citySlug;
		const vendorName =
			routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title ? routeMetadata.vendor.title : vendorSlug;

		return [
			{
				label: 'Händler Übersicht',
				route: 'VendorListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.vendor.title : '',
				route: 'VendorDetailRoute',
				routeParams: { vendor: this.props.vendorSlug }
			} as IBreadcrumbItemType,
			{
				label: `${vendorName} Märkte in ${cityName}`
			} as IBreadcrumbItemType
		];
	}
}

export default ShopsByVendorAndCity;
