import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import SearchPage from '../../components/SearchPage/SearchPage';
import { searchFilterConfig } from '../../components/SearchPage/SearchPageFilters';
import {
    UrlFilterFilterData
} from '../../components/UrlFilterProvider/UrlFilterProvider';

export type SearchFilterContentObjectType = {
    [key: string]: string | string[];
};

interface ISearchPageContainerPropType {
    searchTerm: string;
    page?: number;
    limit?: number;
    filterData?: UrlFilterFilterData;
    searchRequest: {
        params: SearchFilterContentObjectType;
        body: SearchFilterContentObjectType;
    };
    params: {
        uniqueQueryIdentifier: string;
    };
    searchCount: number;
}

class SearchPageContainerInner extends React.Component<
    ISearchPageContainerPropType & IWithDataProps,
    {}
> {
    public render() {
        const {
            data,
            queryKey,
            fetchMore,
            metadata,
            searchTerm,
            searchMetaData,
            filterData,
        } = this.props;


        return (
            <React.Fragment>
                <SearchPage
                    results={data}
                    searchTerm={searchTerm}
                    queryKey={queryKey}
                    fetchMore={fetchMore}
                    metadata={metadata}
                    filterData={filterData}
                    startSearch={this.startSearch}
                    searchMetaData={searchMetaData}
                />
            </React.Fragment>
        );
    }
}

/**
 * Generate Search Object from filterParams
 *
 * @param filterParams
 */
function generateSearchRequest(filterParams: SearchFilterContentObjectType) {
    const searchRequest = {
        body: {},
        params: {},
        combined: {},
    };

    for (const key of Object.keys(filterParams)) {
        if (searchFilterConfig[key]) {
            if (searchFilterConfig[key].requestKeyPosition === 'query') {
                searchRequest.params[searchFilterConfig[key].requestKey] =
                    filterParams[key];
            } else {
                searchRequest.body[searchFilterConfig[key].requestKey] =
                    filterParams[key];
            }

            searchRequest.combined[key] = filterParams[key];
        }
    }

    return searchRequest;
}

/**
 * @param theProps
 *
 * @constructor
 */
export default connect(null, { push })(
    function SearchPageContainer(theProps: ISearchPageContainerPropType,) {
    const props = Object.assign(
        {
            limit: 36,
            enablePagination: false,
        },
        theProps,
    );

    const searchRequest = generateSearchRequest(theProps.filterData);
    const queryKey =
        (`searchpage-results-${theProps.searchCount}` +
            JSON.stringify(searchRequest.combined)).replace(/[^a-zA-Z ]/g, "");

    const uniqueQueryIdentifier = JSON.stringify(searchRequest.combined);

    const RenderComponent = withData(
        SearchPageContainerInner,
        'mixedEntityReducer',
        queryKey,
        {
            size: props.limit,
            page: props.page,

            searchRequest,
            uniqueQueryIdentifier,
        },
        {
            showLoading: false,
            additionalStateProperties: ['searchMetaData', 'searchResults'],
        },
    );

    return <RenderComponent {...props} />;
});
