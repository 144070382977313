import { DateTime } from 'luxon';
import appConfig from '../../../config/appConfig';
import CookieService from '../../../services/CookieService';
import BookmarkService from '../../Bookmark/services/BookmarkService';
import { fetchAccount } from '../store/Account/accountActions';
import fetch from 'cross-fetch';
import ReduxService from '../../../../boilerplate/redux/services/ReduxService';


const RefreshTokenService = {

	/**
	 * Get access token
	 */
	getAccessToken: function <T> (): Promise<T> {

		if (!CookieService.get('rfshtkn') || CookieService.getWithoutCache('at') || DateTime.local() < this.accessTokenLifetime) {
			return new Promise((resolve, reject) => {
				resolve(CookieService.get('at'));
			});
		}

		return fetch(appConfig.apiUrl + '/api/v1/auth/token', {
			headers: {
				'X-Client-Token': appConfig.apiClientToken,
				'Content-Type': 'application/json',
				'Ocp-Apim-Subscription-Key': appConfig.azureApiSubscriptionKey,
			},
			method: 'POST',
			body: JSON.stringify({
				'grant_type': 'refresh_token',
				'refresh_token': CookieService.get('rfshtkn'),
			}),
		}).then((response: any) => {
			return response.json();
		}).then((response: any) => {
			if (response.status === 401) {
				CookieService.delete('at');
				CookieService.delete('rfshtkn');
				CookieService.delete('sli');

				// console.log('DID NOT WORK: ', response);

				return null;
			}

			// console.log('GOT A NEW TOKEN', response);

			const refreshTTL = (CookieService.get('sli') === '1') ? (60 * 24 * 31) : undefined;

			// save the new tokens
			CookieService.set('at', response.access_token, ( response.expires_in / 60 ));
			CookieService.set('rfshtkn', response.refresh_token, refreshTTL);

			// AccountService.initAccount();
			ReduxService.dispatch(fetchAccount('account-data'));

			// // init bookmarks
			BookmarkService.initBookmarks();

			return response.access_token;
		}).catch((err) => {
			// console.log('ERROR HERE IN REFRESHO: ', err);
		});
	},

};

export default RefreshTokenService;
