import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import LoadingIndicator from '../../../../components/Layout/LoadingIndicator/LoadingIndicator';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import ConfirmNewsletters from '../../components/ConfirmNewsletters/ConfirmNewsletters';
import GenericErrorMap from '../../../../components/Layout/GenericErrorMap/GenericErrorMap';
import serverErrorMap from '../../components/ConfirmNewsletters/serverErrorMap';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import { connect } from 'react-redux';

interface IConfirmNewlettersContainerProps {
    mail: string,
	token: string,
	accountReducer: any;
}

class ConfirmNewslettersContainerInner extends React.Component<IConfirmNewlettersContainerProps & IWithDataProps> {

	public render () {
		const { accountReducer } = this.props;

		if (isServer()) {
			return null;
		}

		return (
			<ConfirmNewsletters 
				account={accountReducer}
			/>
		)
	}
}

function mapStateToProps(state: any) {
	return {
		accountReducer: state.accountReducer,
	}
}

export default function ConfirmNewslettersContainer (theProps: IConfirmNewlettersContainerProps) {
	const props = Object.assign({

	}, theProps);

	if (isServer()) {
		return (
			<LoadingIndicator>
				Daten werden geprüft ...
			</LoadingIndicator>
		);
	}

	const RenderComponent = withData(
		connect(mapStateToProps, {})(ConfirmNewslettersContainerInner),
		'confirmNewslettersReducer',
		`confirmNewsletters`,
		{
            email: props.mail,
            verificationToken: props.token,
        },
        {
	        ErrorComponent: (errorProps: any) => (
		        <GenericErrorMap error={errorProps} serverErrorMap={serverErrorMap}/>
	        ),
	        LoadingComponent: (errorProps: any) => (
		        <LoadingIndicator>
					Daten werden geprüft ...
		        </LoadingIndicator>
	        ),
        }
        
	);

	return <RenderComponent {...props} />;
}


