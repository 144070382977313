import * as React from 'react';
import Link from '../../../modules/Base/components/Link/Link';
import * as layoutStyles from '../Layout.scss';
import * as styles from './Header.scss';

interface ISimpleHeaderProps {
	logo?: boolean;
}

interface ISimpleHeaderStateProps {
}

class SimpleHeader extends React.Component<ISimpleHeaderProps, ISimpleHeaderStateProps> {

	public render() {
		return (
			<React.Fragment>
				<div className={styles.headerSpacer} />

				<header className={`${styles.header}`} id="main-header">
					<div className={styles.headerPadd}>
						<div className={layoutStyles.containerFluid}>
							<div className={styles.headerInner}>

								<div className={styles.headerBrand}>
									<Link route="HomeRoute">
										<a title="Zur Startseite">

											<img src={require('./logo-l.svg')} itemProp="logo" alt="Aktionsfinder Logo" className={styles.desktopLogo}/>

											<img src={require('./logo-xs.png')} alt="Aktionsfinder Logo" className={styles.mobileLogo}/>

										</a>
									</Link>
								</div>

							</div>
						</div>
					</div>
				</header>

			</React.Fragment>
		);
	}

}

export default SimpleHeader;
