import * as React from 'react';
import { ReactNode } from 'react';
import * as styles from './ContentBox.scss';

interface IContentBoxProps {
	children: ReactNode;
	title?: string;
	nested?: boolean;
	titleLevel?: 'h1' | 'h2' | 'h3' | 'h4';
	theme?: 'white' | 'gray';
}

class ContentBox extends React.Component<IContentBoxProps, {}> {
	public render() {
		const { title, children, nested, theme, titleLevel } = this.props;

		const customStyle = nested
			? styles.nestedContentBox
			: styles.contentBox;

		const HeadlineLevel = (titleLevel) ? titleLevel : 'h2';

		return (
			<div style={{ backgroundColor: theme }} className={customStyle}>
				{!title ? null : (
					<div className={styles.contentBoxTitle}>
						<HeadlineLevel className={styles.titleHead}>{title}</HeadlineLevel>
					</div>
				)}
				<div className={styles.contentBoxContainer}>{children}</div>
			</div>
		);
	}
}

export default ContentBox;
