import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import LeafletListingPage from '../App/modules/Leaflet/components/LeafletListingPage/LeafletListingPage';
import { pluralizerLeaflets, withTitlePostfix, withClickbait, currentEntryWord } from '../App/utils/routeMetadataUtils';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import AdItem from '../App/components/General/AdItem/AdItem';
import BaseRoute from './_BaseRoute';


class LeafletListingRoute extends BaseRoute {
	public render () {
		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403488]}
					mobileIds={[4403478, 4403477]}
					superbannerId={4403489}
					sitebarId={4403487}
				/>

				<Header />

				<MainNavigation />

				<FlexWrapper>
					<div>
						<LeafletListingPage
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403487}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		LeafletListingRoute,
		`prospekte`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${pluralizerLeaflets(routeMetadata.totalItemsCount)} in deiner Nähe`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} Prospekte & Flugblätter in Österreich`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle Flugblätter & Prospekte auf einen Blick » Aktionen & Angebote entdecken ✓ Preise vergleichen ✓ Rabatt sichern`, `✓ Hier klicken & sparen!`),
		} as IRouteMetadataSetterType
	)
);
