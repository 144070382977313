import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsInProductGroupAndState from '../App/modules/Promotion/components/PromotionsInProductGroupAndState/PromotionsInProductGroupAndState';
import {
	currentEntryWord,
	pluralizerPromotionsAndSpecials,
	withClickbait,
	withTitlePostfix
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class PromotionsInProductGroupAndStateRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await PromotionsInProductGroupAndStateRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<PromotionsInProductGroupAndState
							productGroupSlug={query.productGroup +''}
							stateSlug={query.state +''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		PromotionsInProductGroupAndStateRoute,
		(props: any) => `ppcst-${props.match.params.productGroup}-${props.match.params.state}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => 
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${( routeMetadata.maxItemDiscount > 3 ) ? ` bis zu -${routeMetadata.maxItemDiscount}%` : ''} in ${routeMetadata.state.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.productGroup.title} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} » ${routeMetadata.state.title}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`Die besten ${routeMetadata.productGroup.title} Aktionen & Angebote ${routeMetadata.state.title} » Händler finden ⭐ Geld sparen ⭐ Preise vergleichen ⭐`, `Auf Schnäppchenjagd gehen!`),
		} as IRouteMetadataSetterType,
	),
);
