/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IMixedEntityType } from '../../../MixedEntity/types/IMixedEntityType';
import { ILeafletType } from '../../types/ILeafletType';
import { IPromotionLeafletType } from '../../types/IPromotionLeafletType';

export const LEAFLET_FETCH_ENTRIES = '@BasePipeline/leafletReducer_FETCH_ENTRIES';
export const LEAFLET_FETCH_ENTRY = '@BasePipeline/leafletReducer_FETCH_ENTRY';
export const LEAFLET_ADD_ENTRIES = '@BasePipeline/leafletReducer_ADD_ENTRIES';
export const LEAFLET_ADD_ENTRY = '@BasePipeline/leafletReducer_ADD_ENTRY';
export const LEAFLET_SET_ERRORS = '@BasePipeline/leafletReducer_SET_ERRORS';
export const SHOW_LEAFLET_DISCOUNT_MODAL = 'SHOW_LEAFLET_DISCOUNT_MODAL';
export const HIDE_LEAFLET_DISCOUNT_MODAL = 'HIDE_LEAFLET_DISCOUNT_MODAL';
export const SET_LEAFLET_DISCOUNT_DATA = 'SET_LEAFLET_DISCOUNT_DATA';

/* ### ACTIONS ### */

export function addLeafletEntries(queryKey: string, entries: ILeafletType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: LEAFLET_ADD_ENTRIES,
        queryKey,
        entries: entries.map(transformLeaflet),
        metaData,
        options,
    };
}

export function addLeafletEntry(queryKey: string, entry: ILeafletType, metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: LEAFLET_ADD_ENTRY,
        queryKey,
        entry: transformLeaflet(entry),
        metaData,
        options,
    };
}

export function setLeafletErrors(queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: LEAFLET_SET_ERRORS,
        queryKey,
        errors,
        metaData,
        options,
    };
}

export function hideLeafletDiscountModal() {
    return {
        type: HIDE_LEAFLET_DISCOUNT_MODAL,
    };
}

export function showLeafletDiscountModal() {
    return {
        type: SHOW_LEAFLET_DISCOUNT_MODAL,
    };
}

export function setLeafletDiscountData(leaflet: ILeafletType) {
    return {
        type: SET_LEAFLET_DISCOUNT_DATA,
        discountLeaflet: leaflet,
    };
}

/* #### Global leaflet transformations #### */

function transformLeaflet(leaflet: ILeafletType): ILeafletType {
    return {
        ...leaflet,
        onlyRelativeDate: onlyRelativeDateHoferHack(leaflet),
    };
}

/// Applies a temporary hack for the 'Hofer Aktionswelten' to show special date-format
export function onlyRelativeDateHoferHack(leaflet: ILeafletType | IPromotionLeafletType | IMixedEntityType): boolean {
    // if we got openValidity true -> show it
    if (typeof leaflet.openValidity != 'undefined') {
        return leaflet.openValidity ?? false;
    }

    // TODO: the following can be removed if not really used anymore after 01.07.2023 (because it is just an old hack, that got replaced by cleaner api approach)
    // if we got a json with onlyRelativeDate, we use it
    // if (typeof leaflet.onlyRelativeDate != 'undefined') {
    // 	return leaflet.onlyRelativeDate ?? false;
    // }
    //
    // Otherwise use the special hack case for 'Hofer Aktionswelten' to show special date-format
    // This is just a temporary solution, because the backend is not ready yet and they need it urgently.
    // This should be removed, as soon as we get the `onlyRelativeDate`-flag from the backend.
    // Check for the `onlyRelativeDate`-flag again around 01.06.2023, then it should be there at the latest!
    // If we get the flag, then we can just remove this whole function and just assign the json value, like in lines 129 - 131
    // if (leaflet.vendorSlug == 'hofer' &&
    // 	(leaflet.promotionWorld ?? false) == true) {
    // 	return true;
    // }

    return false;
}
