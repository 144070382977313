import { UrlFilterFilterData } from '../components/UrlFilterProvider/UrlFilterProvider';

/**
 * Only returns non-standard filters (our multiselect filters, that don't have default values)
 *
 * @param filterData
 */
export function getNonStandardFilters(filterData: UrlFilterFilterData) {
    // extract main filter values to only have everything else in otherFilters
    const { query, sort, q, distance, ...otherFilters } = filterData;

    return otherFilters;
}

/**
 * Checks wheter we are currently querying for more than a search word and the default filters we always have
 * This will let us know whether the user used too many filters and may have no results, because of that
 */
export function getNonStandardFilterLength(filterData: UrlFilterFilterData) {
    const nonStandardFilters = getNonStandardFilters(filterData);

    // now turn this otherfilters array into an array to be able to get the number of elements inside it
    return Object.keys(nonStandardFilters).length;
}
