import * as React from 'react';
import * as styles from './Icon.scss';

interface IIconProps {
    iconset?: 'af';
    icon?: string;
    customIcon?: string;
    className?: string;
    onClick?: (item: any) => void;
    name?: string;
    title?: string;
}

class Icon extends React.PureComponent<IIconProps> {
    public render() {
        const { icon, iconset, className, title, customIcon, ...restProps } = this.props;

        // TODO: remove as soon as loading icon is here
        const theIcon = icon === 'loading' ? 'settings-android' : icon;

        // determine the className for the icon set
        const iconClassName = className ? `${styles.Icon} afpwa-glyphs af-${theIcon} ${className} ${styles['IconAfpwa-' + theIcon]}` : `afpwa-glyphs af-${theIcon}`;

        // determine the className for the SVG icon
        const imageClassName = className ? `${styles.Icon} ${className}` : styles.Icon;

        return (
            <span className={customIcon ? imageClassName : iconClassName} title={title} onClick={this.onClick} {...restProps}>
                {customIcon && <img src={customIcon} className={styles.imgMulticolorIcon} />}
            </span>
        );
    }

    /**
     * Handles the onclick event
     */
    private onClick = () => {
        const { onClick, name } = this.props;

        if (!onClick) {
            return;
        }

        onClick(name);
    };
}

export default Icon;
