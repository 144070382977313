import * as React from 'react';
import { ReactChild, ReactNode } from 'react';
import * as styles from './Error.scss';
import ErrorImage from './ErrorImage.png';

interface IErrorProps {
	title?: string,
	children?: ReactNode | ReactNode[] | string[],
}

class Error extends React.PureComponent<IErrorProps, {}> {
	public static defaultProps = {
		title: 'Leider nichts hier',
	};

	public render () {
		const { children, title } = this.props;

		return (
			<div className={styles.noDataErrorWrapper}>
				<div className={styles.image}>
					<img src={ErrorImage} alt="Uups" />
				</div>

				<div className={styles.content}>
					{ (title) ? <h1>{title}</h1> : null }

					{ (children) ?
					    <div className={styles.contentMessage}>
						    {children}
					    </div>
					    :
					    <div className={styles.contentMessage}>
						    Es ist ein unbekannter Fehler aufgetreten!
						</div>
					}
				</div>
			</div>
		);
	}
}

export default Error;
