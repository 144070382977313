import * as React from 'react';
import * as styles from './ShopListItem.scss';
import Icon from '../../../Base/components/Icon/Icon';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';

interface ShopListItemSkeletonProps {
	isLarge: boolean;
}

class ShopListItemSkeleton extends React.Component<ShopListItemSkeletonProps, {}> {

	public render () {

		const { isLarge } = this.props;

		return (
			<div>
				<div className={styles.shopListItem}>

					<div className={styles.shopTextContainer}>
						<span className={styles.shopListItemTitle}>
							<SkeletonInline width={200}/>
						</span>

						<span className={styles.shopListItemAddress}>
							<SkeletonInline />
						</span>
					</div>

					<div className={styles.shopDistanceContainer}>
						<Icon icon="direction-filled" className={styles.shopListDistanceIcon}/>&nbsp;
						<span className={styles.shopListItemDistance}>
							<SkeletonInline />
						</span>
					</div>

				</div>
				{!isLarge ? null :
					<div className={styles.shopListItem}>
						<div className={styles.shopTextContainer}>
							<SkeletonBlock maxWidth={320} style={{margin: '-10px 0 0'}} />
							<SkeletonBlock maxWidth={320}/>
							<SkeletonBlock maxWidth={320}/>
							<SkeletonBlock maxWidth={320}/>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default ShopListItemSkeleton;
