import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import { IProductGroupType } from '../../types/IProductGroupType';
import ProductGroupInMenu from '../../components/ProductGroupInMenu/ProductGroupInMenu';
import MenuSkeleton from '../../../../components/Layout/Menu/MenuSkeleton';

interface IProductGroupInMenuProps {
	data?: IProductGroupType,
	toMainMenu: () => void,
	onCloseMenu: () => void,
	title: string,
}

class ProductGroupInMenuContainerInner extends React.Component<IProductGroupInMenuProps & IWithDataProps & IWithRouteMetadataType> {
	public render() {
		const { data, toMainMenu, title, ...restProps } = this.props;

		return (
			<ProductGroupInMenu
				productGroups={data as IProductGroupType[]}
				toMainMenu={toMainMenu}
				title={title}
				{...restProps}
			/>
		);
	}
}

export default function ProductGroupInMenuContainer (props: IProductGroupInMenuProps) {
	const RenderComponent = withData(
		ProductGroupInMenuContainerInner,
		'productGroupReducer',
		`productGroupInMenu`,
		{

		},
		{
			primaryKeyName: 'productGroup',

			LoadingComponent: () => (
				<MenuSkeleton
					title={props.title}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}

