import * as React from 'react';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import TipBox from '../../../../components/Layout/TipBox/TipBox';
import VendorListSkeleton from '../../../Vendor/components/VendorList/VendorListSkeleton';
import * as styles from './VendorBookmarkListingPage.scss';


class VendorBookmarkListingPageSkeleton extends React.Component<{}, {}> {
	public static defaultProps = {};

	public render () {
		return (
			<div className={styles.Wrapper}>
				<TipBox>
					<SkeletonInline />
				</TipBox>

				<VendorListSkeleton>
	                <NoDataError />
				</VendorListSkeleton>
			</div>
		);
	}
}

export default VendorBookmarkListingPageSkeleton;
