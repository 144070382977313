import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import Link from '../../../Base/components/Link/Link';
import * as styles from './TagCloudWidget.scss';

export interface ITagCloudWidgetProps {
	tags: ITagWidgetItemType[];
	title: string;
	widgetKey: string;
	grayscaled?: boolean;
	style?: IWidgetStyleType;

	itemCountLabel?: string;
	itemCountLabelPlural?: string;

	routeCallbk: (item: ITagWidgetItemType) => { name: string, params: {[key: string]: any} },
}

class TagCloudWidget extends React.Component<ITagCloudWidgetProps, {}> {
	public static defaultProps = {
		itemCountLabel: 'Aktion',
		itemCountLabelPlural: 'Aktionen',
	};

	public render() {
		const { tags, title, style, widgetKey, grayscaled, routeCallbk, itemCountLabel, itemCountLabelPlural } = this.props;

		// TRANSLATE
		return (
			<WidgetBox title={title} style={style}>
				<WidgetBoxContent>
					<div className={`${styles.tagList} ${(grayscaled) ? styles.tagListGrayscaled : ''}`}>
						{
							tags.map((tagWidgetItem: ITagWidgetItemType) => {
								const route = routeCallbk(tagWidgetItem);

								return (
									<Link
										key={`${widgetKey}-tagcloudwidget-item-${tagWidgetItem.group.headline}`}
										route={route.name}
										params={route.params}
									>
										<a
											className={styles.tagListItem}
										>
											{tagWidgetItem.group.headline} ({tagWidgetItem.totalItemsCount} { (tagWidgetItem.totalItemsCount === 1) ? itemCountLabel : itemCountLabelPlural })
										</a>
									</Link>
								)
							}
						)}
					</div>
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default TagCloudWidget;
