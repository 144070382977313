import * as React from 'react';
import { ComponentType } from 'react';
import { isBrowser } from '../../../../boilerplate/razzle/razzleUtils';
import withData from '../../../../boilerplate/redux/hocs/withData';
import Icon from '../../Base/components/Icon/Icon';
import { SlugType } from '../../Base/types/SlugType';
import WithLeafletSkeleton from './withLeafletSkeleton'
import Error from '../../../../App/components/Layout/Error/Error';
import * as leafletHeaderStyles from '../components/LeafletViewer/LeafletHeader.scss';

export enum LeafletViewerType {
	INFOPOST,
	DEFAULT,
}

export default function withLeaflet(WrapperComponent: ComponentType<any>, leafletSlug: SlugType, props: any, leafletViewerType: LeafletViewerType) {
	const goBack = function() {
		if (isBrowser()) {
			window.history.back();
		}
	};

	var requestKey = `vendor-detail-${leafletSlug}`;

	if(props.vendorSlug != null && props.stateSlug != null) {
		requestKey= `leaflet-detail-${props.vendorSlug}-${props.stateSlug}`;
	}

	const RenderComponent = withData(
		WrapperComponent,
		'leafletReducer',
		requestKey,
		{
			slug: leafletSlug,
			vendorSlug: props.vendorSlug,
			stateSlug: props.stateSlug,
			leafletViewerType: leafletViewerType,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'leafletSlug',
			dataKey: 'leaflet',
			LoadingComponent: () => {
				return (
					<WithLeafletSkeleton />
				);
			},
			ErrorComponent: () => {
				return (
					<div>
						<div className={leafletHeaderStyles.leafletHeaderContainer}>
							<div className={leafletHeaderStyles.leafletHeaderBlockControls}>

								<button type="button" className={leafletHeaderStyles.leafletHeaderIcon} onClick={goBack}>
									<Icon icon="close" />
								</button>

							</div>
						</div>

						<Error title="Ups! Es ist ein Fehler aufgetreten">
							Das hätte nicht passieren sollen, aber wir kümmern uns so schnell wie möglich darum. <br />Bitte versuche es später erneut!
						</Error>
					</div>
				);
			}
		});

	return <RenderComponent {...props} />;
}
