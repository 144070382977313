/**
 * Checks whether our current device is mobile
 */
export function isMobile(): boolean {
	if (typeof window === 'undefined') {
		return false;
	}

	return window.matchMedia("only screen and (max-width: 890px)").matches;
}

/**
 * Checks whether our current device is a tablet
 */
export function isTablet(): boolean {
	if (typeof window === 'undefined') {
		return false;
	}

	return window.matchMedia("only screen and (min-width: 890px) and (max-width: 1200px)").matches;
}


/**
 * Checks whether our current device is a desktop
 */
export function isDesktop(): boolean {
	if (typeof window === 'undefined') {
		return false;
	}

	return window.matchMedia("only screen and (min-width: 1200px)").matches;
}
