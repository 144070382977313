import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addPromotionsAggregatedByProductGroupsWidgetEntries,
	PROMOTIONS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_FETCH_ENTRIES, setPromotionsAggregatedByProductGroupsWidgetErrors,
} from './promotionsAggregatedByProductGroupsWidgetActions';
import GeolocationService from '../../../Base/services/GeolocationService';

/**
 * Fetch entry list
 */
function* fetchPromotionsAggregatedByProductGroupsWidget(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	// add geolocation params
	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

	let url = '/api/public/v1/widgets/product-groups';

	if (params.type === 'leaflets') {
		url = '/api/public/v1/widgets/product-groups-for-leaflets/nearby';
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url,
					params,
				} as IRequestObject
			);
		});

		const theContent = response.getContent('content');

		yield put(addPromotionsAggregatedByProductGroupsWidgetEntries(
			action.queryKey,
			theContent,
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionsAggregatedByProductGroupsWidgetErrors(action.queryKey, e));
	}
}


export function* promotionsAggregatedByProductGroupsWidgetSaga() {
	yield takeEvery(PROMOTIONS_AGGREGATED_BY_PRODUCT_GROUPS_WIDGET_FETCH_ENTRIES, fetchPromotionsAggregatedByProductGroupsWidget);
}
