import * as React from 'react';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import * as styles from './AccountInfo.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import DataBlockSkeleton from '../DataBlock/DataBlockSkeleton';

class AccountInfoSkeleton extends React.Component<{}> {

	public render () {

		return (
			<PageWrapper hideLocation={true}>
				<H1 align="center">
                    Meine Daten
				</H1>

                <div className={styles.accountInfoWrapper}>
                    <div className={styles.accountInfoInformation}>
                        Hier findest du einen Überblick über die von dir auf Aktionsfinder gespeicherten Daten. Wenn du dein Benutzerkonto löscht, werden auch alle hier angezeigten Daten gelöscht bzw. anonymisiert.
                    </div>

                    <DataBlockSkeleton
                        title="Allgemein"
                        dataObject={true}
                    />

                    <DataBlockSkeleton
                        title="Passwort"
                        dataString={true} 
                    />

                    <DataBlockSkeleton
                        title="E-Mail Adresse"
                        dataString={true} 
                    />

                    <DataBlockSkeleton
                        title="Letzte Suchbegriffe"
                        dataObject={true}
                    />

                    <div className={styles.accountInfoDeleteAccount}> 
                        <SkeletonInline />
                    </div>

                </div>   
			</PageWrapper>
		);
    }
}

export default AccountInfoSkeleton;
