import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';

export interface IPromotionsAggregatedByVendorsWidgetProps {
	aggregatedEntries: IPromotionsAggregatedByVendorType[];
	title: string;
	widgetKey?: string;
	promotionLabel?: string;
	promotionLabelPlural?: string;

	routeCallbk: (item: IPromotionsAggregatedByVendorType) => { name: string, params: {[key: string]: any} },
}

class PromotionsAggregatedByVendorsWidget extends React.Component<IPromotionsAggregatedByVendorsWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'promotion-list-widget',
		promotionLabel: 'Aktion',
		promotionLabelPlural: 'Aktionen',
	};

	public render() {
		const { aggregatedEntries, title, widgetKey, promotionLabel, promotionLabelPlural, routeCallbk } = this.props;

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					{
						aggregatedEntries.map((aggregatedEntry: IPromotionsAggregatedByVendorType) => {
							const route = routeCallbk(aggregatedEntry);

							return (
								<WidgetListItemLinkRow
									title={aggregatedEntry.group.name}
									subtitle={`${aggregatedEntry.totalItemsCount} ${ (aggregatedEntry.totalItemsCount === 1) ? promotionLabel : promotionLabelPlural}`}
									key={`${widgetKey}-${aggregatedEntry.group.slug}`}
									route={route.name}
									routeParams={route.params}
								/>
							);
					})
					}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default PromotionsAggregatedByVendorsWidget;
