import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import ShopsByVendorAndState from '../App/modules/Vendor/components/ShopsByVendorAndState/ShopsByVendorAndState';
import { maxDiscountInTitle } from '../App/utils/routeMetadataUtils';
import { withClickbait, withTitlePostfix } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';


class ShopsByVendorAndStateRoute extends BaseRoute {

	public static async getInitialProps(state: any) {
		const reduxData = await ShopsByVendorAndStateRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403494]}
					mobileIds={[4403482, 4403481]}
					superbannerId={4403495}
					sitebarId={4403493}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<ShopsByVendorAndState
							vendorSlug={query.vendor + ''}
							stateSlug={query.state + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403493}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		ShopsByVendorAndStateRoute,
		(props: any) => `svst-${props.match.params.state}-${props.match.params.vendor}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => `${routeMetadata.vendor.title} Märkte in ${routeMetadata.state.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => withTitlePostfix(`${routeMetadata.vendor.title.toUpperCase()} ${routeMetadata.state.title} Angebote & Aktionen ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => withClickbait(`Angebote & Aktionen für ${routeMetadata.vendor.title.toUpperCase()} in ${routeMetadata.state.title} auf einen Blick » Schnäppchen entdecken ✓ Preise vergleichen ✓ Geld sparen ✓`, `Den besten Deal schnappen!`),
		} as IRouteMetadataSetterType,
	),
);
