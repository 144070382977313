import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import { ILeafletsAggregatedByStateType } from '../../types/ILeafletsAggregatedByStateType';

export interface ILeafletsAggregatedByStatesWidgetProps {
	leaflets: ILeafletsAggregatedByStateType[];
	title: string;
	widgetKey?: string;

	routeCallbk: (item: ILeafletsAggregatedByStateType, parentProps: ILeafletsAggregatedByStatesWidgetProps) => { name: string, params: {[key: string]: any} },
}

class LeafletsAggregatedByStatesWidget extends React.Component<ILeafletsAggregatedByStatesWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'leaflet-list-widget',
	};

	public render() {
		const { leaflets, title, widgetKey, routeCallbk } = this.props;

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					{leaflets.map((leafletAggregated: ILeafletsAggregatedByStateType) => {
						const route = routeCallbk(leafletAggregated, this.props);

						return (
							<WidgetListItemLinkRow
								title={leafletAggregated.group.name}
								subtitle={`${leafletAggregated.totalItemsCount} Prospekte`}
								key={`${widgetKey}-${leafletAggregated.group.slug}`}
								route={route.name}
								routeParams={route.params}
							/>
						);
					})}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default LeafletsAggregatedByStatesWidget;
