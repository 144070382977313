import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import * as styles from './Menu.scss';
import { connect } from 'react-redux';
import Overlay from '../../../modules/Base/components/Overlay/Overlay';
import MenuCategory from './MenuCategory';
import MenuItem from './MenuItem';
import Button from '../../General/Button/Button';
import ProductGroupInMenuContainer
	from '../../../modules/Category/containers/ProductGroupInMenu/ProductGroupInMenuContainer';
import { showLocationModal } from '../../../modules/Base/store/Modal/modalActions';
import Link from '../../../modules/Base/components/Link/Link';

interface IMenuProps {
	modalReducer?: any;
	onCloseMenu: () => void;
	accountReducer?: any;
	showLocationModal?: () => void;
}

interface IMenuState {
	index: number,
}

class Menu extends React.Component<IMenuProps, IMenuState> {

	constructor(props: IMenuProps) {
		super(props);
		this.state = {
			index: 0,
		};
	}

	public render() {

		const { onCloseMenu, accountReducer, showLocationModal } = this.props;

		const { index } = this.state;

		const isLoggedIn = accountReducer.isLoggedIn;

		return (
			<React.Fragment>
				<Overlay   />

				<div className={styles.menuWrapper}>
					<div className={styles.menuHeader}>

						<Link route="HomeRoute">
							<a title="Zur Startseite" onClick={onCloseMenu}>
								<img src={require('./Logo-l-primaryColor.svg')} itemProp="logo" alt="Aktionsfinder Logo" className={styles.desktopLogo} />
							</a>
						</Link>
					</div>

					<div className={styles.appButtonMobile}>
						<Button
							theme="primary"
							size="l"
							href="https://aktionsfinder.page.link/app"
							target="_blank"
							uppercase={false}
							fullWidth
						>
							Jetzt App downloaden
						</Button>
					</div>
					<div className={styles.appButtonDesktop}>
						<Button
							theme="primary"
							size="l"
							route='AppExclusiveRoute'
							uppercase={false}
							fullWidth
						>
							Jetzt App downloaden
						</Button>
					</div>

					{/* Only show this field if user is not logged in */}
					{/* {!isLoggedIn && (
						<div className={styles.loggedOutView}>

							<span className={styles.loggedOutViewText}>Nütze zusätzliche Funktionen mit deinem kostenlosen Aktionsfinder Benutzerkonto!</span>

							<Button route="LoginRoute" theme="primary" size="l" fullWidth={true} uppercase={false} customClassName={styles.loginBtn} fontSize={14}>
								Jetzt anmelden
							</Button>
							<Button route="RegistrationRoute" theme="flat" size="l" fullWidth={true} uppercase={false} fontSize={14}>
								Kostenlos registrieren
							</Button>
						</div>
					)} */}

					<div className={!isLoggedIn ? styles.loggedOutMenuContainer : styles.menuContainer}>
						<div className={styles.menuGroup}>

							<SwipeableViews
								index={index}
								onChangeIndex={this.handleChangeIndex}
								animateHeight={index === 0}
								containerStyle={{
									transform: 'none',
								}}
							>
								<div className={styles.menuViewContainer}>
									<MenuCategory title="Inhalte">

										<MenuItem title="Stöbern" routeLink="HomeRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Prospekte" routeLink="LeafletListingRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Favoriten" routeLink="AppExclusiveRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Aktionen" routeLink="PromotionListingRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Märkte" routeLink="VendorListingRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Gutscheine" routeLink="VoucherListingRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Gewinnen" routeLink="LotteryListingRoute" showIcon={false} onCloseMenu={onCloseMenu} />

									</MenuCategory>

									<MenuCategory title="Meine Aktionsfinder">

										<MenuItem
											title="Mein Profil" routeLink="AccountInfoRoute" visible={isLoggedIn} showIcon={false} onCloseMenu={onCloseMenu} 
										/>
										<MenuItem title="Abmelden" routeLink="LogoutRoute" visible={isLoggedIn} showIcon={false} />
										<MenuItem title="Anmelden" routeLink="LoginRoute" visible={!isLoggedIn} showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem
											title="Registrieren" routeLink="RegistrationRoute" visible={!isLoggedIn} showIcon={false} onCloseMenu={onCloseMenu}
										/>
										<MenuItem title="Mein Standort" onClick={showLocationModal} showIcon={false} />
										<MenuItem title="Cookie Einstellungen" onClick={() => window.OneTrust.ToggleInfoDisplay()} showIcon={false} />

									</MenuCategory>

									<MenuCategory title="Über Aktionsfinder">

										{/* <MenuItem title="Aktionsfinder weiterempfehlen" routeLink="/" showIcon={false} /> */}
										{/* <MenuItem title="Aktionsfinder bewerten" routeLink="/" showIcon={false} /> */}
										{/* <MenuItem title="Feedback" routeLink="/" showIcon={false} /> */}
										<MenuItem title="Impressum" routeLink="ImprintRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Rechtliche Hinweise" routeLink="PrivacyInfoRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Teilnahmebedingungen" routeLink="LotteryParticipationConditionsRoute" showIcon={false} onCloseMenu={onCloseMenu} />
										<MenuItem title="Allg. Nutzungsbedingungen" routeLink="TermsAndConditionsRoute" showIcon={false} onCloseMenu={onCloseMenu} />

									</MenuCategory>

								</div>

								<ProductGroupInMenuContainer
									title="Produktkategorien"
									toMainMenu={this.toMainMenu}
									onCloseMenu={onCloseMenu}
								/>

							</SwipeableViews>

						</div>
					</div>

				</div>
			</React.Fragment>
		);
	}

	/**
	 * Returns to main menu
	 */
	private toMainMenu = () => {
		this.setState({
			index: 0,
		});
	};

	/**
	 * Handles change of item by clicking
	 */
	private slideMenuRight = () => {
		this.setState({
			index: this.state.index + 1,
		});
	};

	/**
	 * Handles change of tab by swiping
	 */
	private handleChangeIndex = (index: number) => {
		this.setState({ index });
	};

}

function mapStateToProps(state: any) {
	return {
		accountReducer: state.accountReducer,
		geolocationReducer: state.geolocationReducer,
	};
}


function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
		showLocationModal: () => {
			dispatch(showLocationModal());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
