import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as leafletWidgetActions from './leafletWidgetActions';

export type LeafletWidgetAction = ActionType<typeof leafletWidgetActions>;

export interface ILeafletWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: ILeafletWidgetType };
	readonly queries: { [queryKey: string]: ILeafletWidgetType[] };
}

export function leafletWidgetReducer(
	state = baseInitialState as ILeafletWidgetStateType,
	action: LeafletWidgetAction,
) {
	const Reducer = new BaseReducer('leafletWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
