import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isServer } from '../../../../../boilerplate/razzle/razzleUtils';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	addPromotionEntries,
	PRODUCT_PROMOTION_IN_OTHER_SHOP_FETCH_ENTRIES, setPromotionErrors,
} from './productPromotionInOtherShopActions';

/**
 * Fetch entry list
 */
function* fetchPromotionInOtherShops(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	// add geolocation params
	const geolocationReducer = yield select((state: any) => state.geolocationReducer);
	const params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params, 7);

	// add default params
	params.include_publish_to_all =  true;

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: `/api/public/v1/promotions/${action.params.reference}/related-promotions`,
				params,
			} as IRequestObject);
		});

		if (isServer()) {
			response.addMetaData('reloadOnClient', true);
		}

		yield put(addPromotionEntries(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setPromotionErrors(action.queryKey, e));
	}
}

export function* productPromotionInOtherShopSaga() {
	yield takeEvery(PRODUCT_PROMOTION_IN_OTHER_SHOP_FETCH_ENTRIES, fetchPromotionInOtherShops);
}
