import { SlugType } from '../../../Base/types/SlugType';

/* ### CONSTANTS ### */

export const SET_SHOPPING_NOTE = '@BasePipeline/shoppingNoteReducer_SET_NOTE';
export const SET_SHOPPING_STATE = '@BasePipeline/shoppingNoteReducer_SET_STATE';
export const GET_SHOPPING_NOTE = '@BasePipeline/shoppingNoteReducer_GET_NOTE';

export const SAVE_SHOPPING_NOTE       = '@BasePipeline/shoppingNoteReducer_SAVE_NOTE';
export const SHOPPING_NOTE_SET_ERRORS = '@BasePipeline/shoppingNoteReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function getShoppingNote () {
	return {
		type: GET_SHOPPING_NOTE,
	};
}

export function setShoppingNote (note: string) {
	return {
		type: SET_SHOPPING_NOTE,
		note,
	};
}

export function setShoppingNoteSavingState (state: 'saved' | 'unsaved' | 'saving' | 'error' | null) {
	return {
		type: SET_SHOPPING_STATE,
		state,
	};
}

export function saveShoppingNote (note: string) {
	return {
		type: SAVE_SHOPPING_NOTE,
		note,
	};
}

export function setShoppingNoteErrors (error: string) {
	return {
		type: SHOPPING_NOTE_SET_ERRORS,
		error,
	};
}
