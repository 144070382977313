import * as React from 'react';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import Footer from '../App/components/Layout/Footer/Footer';
import Registration from '../App/modules/Account/components/Registration/Registration';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { withRouter } from 'react-router';
import BaseRoute from './_BaseRoute';


export class RegistrationRoute extends BaseRoute {
	public render() {
		return (
			<div>
				<Header />
				<MainNavigation />

				<Registration />

				<Footer fluid={true} />
			</div>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		RegistrationRoute,
		`registration`,
		{
			metaTitle: `Jetzt registrieren | Aktionsfinder`,
			metaDescription: `Sie wollen ein neues Konto registrieren? Hier haben Sie die Möglichkeit dazu! » Jetzt klicken und unkompliziert einen Benutzeraccount anlegen!`,
		} as IRouteMetadataSetterType
	)
);