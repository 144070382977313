import * as React from 'react';
import { ReactNode, MouseEvent } from 'react';
import * as styles from './ZoomableView.scss';
import panzoom from 'panzoom';
import animate from 'amator'; // Sub-dependency of panzoom
import { EventTarget } from 'event-target-shim';

interface IPanZoomOptions {
	minZoom: number,
	maxZoom: number,
	zoomDoubleClickSpeed: number,
	zoomSpeed: number,
	smoothScroll: boolean,
};

interface IZoomableViewProps {
	children: ReactNode[] | ReactNode,
	className?: string,
	options?: IPanZoomOptions,
	width: number,
	height: number,
};
interface IZoomableViewState {
	sceneWidth: number,
	sceneHeight: number,
	wrapperWidth: number,
	wrapperHeight: number,
};

interface IPanZoomTransform {
	x: number,
	y: number,
	scale: number,
};

interface IPanZoomEvent {
	getTransform: () => IPanZoomTransform,
	moveTo: (x: number, y: number) => void,
};

export const zoomEventTarget = new EventTarget();
export const zoomEvent = (type: 'zoom' | 'requestZoom', scale: number) => new CustomEvent(type, { detail: scale });

export const minZoom = 1;
export const maxZoom = 2.5;

const panzoomDefaultOptions = {
	minZoom,
	maxZoom,
	zoomSpeed: 0.038,
	pinchSpeed: 2.5,
	zoomDoubleClickSpeed: 2.6,
	smoothScroll: true,
};

class ZoomableView extends React.PureComponent<IZoomableViewProps & React.HTMLProps<HTMLDivElement>, IZoomableViewState> {
	public static defaultProps = {
	};

	public state = {
		sceneWidth: 0,
		sceneHeight: 0,
		wrapperWidth: 0,
		wrapperHeight: 0,
	};

	private setDimensionsDeferer: any;

	private panzoomInstance: any;
	private sceneRef: React.RefObject<HTMLDivElement> = React.createRef();
	private wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
	private lastTouch: number;
	private zoomAction: 'none' | 'pinch' | 'tap' = 'none';

	public componentDidMount() {
		this.initPanzoom();
		zoomEventTarget.addEventListener('requestZoom', this.zoomTo);

		this.setDimensions();
	}

	public componentWillUnmount() {
		zoomEventTarget.removeEventListener('requestZoom', this.zoomTo);

		if (this.panzoomInstance) {
			this.panzoomInstance.dispose();
		}
	}

	public componentDidUpdate() {
		this.setDimensions();
	}

	public render () {
		const { children, className, width, height, ...restProps } = this.props;
		const { wrapperWidth, wrapperHeight } = this.state;
		const leftOffset = (wrapperWidth - width) / 2;
		const topOffset = (wrapperHeight - height) / 2;

		const theClassName = (className) ? `${styles.innerWrapper} ${className}` : styles.wrapper;

		return (
			<div className={styles.wrapper} ref={this.wrapperRef} style={{ left: leftOffset, top: topOffset }}>
				<div
					className={theClassName}
					ref={this.sceneRef}
					{...restProps}
					style={{ width, height }}
					onDoubleClick={this.handleDoubleClick}
					onTouchEnd={this.handleTouchEnd}
				>
					{children}
				</div>
			</div>
		);
	}

	/**
	 * Initiates the panzoom behavior
	 */
	private initPanzoom() {
		const scene: HTMLDivElement = this.sceneRef.current!;
		const { options } = this.props;
		const opts = { ...panzoomDefaultOptions, ...options };

		this.zoomAction = 'pinch';
		this.panzoomInstance = panzoom(scene, {
			...opts,
			// Propagate touch events when zoomed out
			onTouch: (event: TouchEvent) => {
				return false;
				// Propagate touch events when zoomed out
				// const { scale } = this.panzoomInstance.getTransform();
				// return !(scale === 1 && event.targetTouches.length === 1);
			},
			beforeWheel: ({ deltaX, deltaY }: WheelEvent) => {
				// Only consider scroll events which are mostly vertical
				return Math.abs(deltaY) < Math.abs(3 * deltaX);
			},
			// Propagate double click event
			onDoubleClick: () => false,
		});

		this.panzoomInstance.on('zoom', (event: IPanZoomEvent) => {
			this.respectBoundaries();
			const { scale } = event.getTransform();
			zoomEventTarget.dispatchEvent(zoomEvent('zoom', scale))

			// Snap to minimum
			if (scale < minZoom * 1.01 && scale !== minZoom) {
				this.panzoomInstance.zoomAbs(0, 0, minZoom);
			}
		});

		// Make sure the page can't leave a bounding box
		this.panzoomInstance.on('pan', this.respectBoundaries);
	}

	/**
	 * Ensure the page stays within the viewport
	 */
	private respectBoundaries = () => {
		const { sceneWidth, sceneHeight } = this.state;
		const { x: originalX, y: originalY, scale } = this.panzoomInstance.getTransform();

		const maxXOffset = sceneWidth * (scale - 1);
		const x = Math.min(Math.max(0 - maxXOffset, originalX), 0);
		const maxYOffset = sceneHeight * (scale - 1);
		const y = Math.min(Math.max(0 - maxYOffset, originalY), 0);

		if (x !== originalX || y !== originalY) {
			this.panzoomInstance.moveTo(x, y);
		}
	}

	/**
	 * Saves the dimensions of the current wrapper and scene width to the state to trigger a rerender which will
	 * center the scene again.
	 */
	private setDimensions = () => {

		// if we already got a deferer
		if (this.setDimensionsDeferer) {
			clearTimeout(this.setDimensionsDeferer);
		}

		this.setDimensionsDeferer = setTimeout(() => {
			const { clientWidth: wrapperWidth, clientHeight: wrapperHeight } = this.wrapperRef.current!;
			const scene: HTMLDivElement = this.sceneRef.current!;
			const { clientWidth: sceneWidth, clientHeight: sceneHeight } = scene;

			this.setState({ sceneWidth, sceneHeight, wrapperWidth, wrapperHeight });
		});
	}

	/**
	 * Zooms to a certain scale maintaining the current center
	 */
	private zoomTo = ({ detail: scale }: CustomEvent) => {
		this.panzoomInstance.zoomAbs(this.state.sceneWidth / 2, this.state.sceneHeight / 2, scale);
	}

	/**
	 * When a double tap is recognized with maximum zoom, zooms out completey
	 */
	private handleTouchEnd = () => {
		const now = new Date().getTime();

		if (this.lastTouch && now - this.lastTouch < 300) {
			this.zoomOutIfMax();
		}

		this.zoomAction = 'none';
		this.lastTouch = now;
	}

	/**
	 * When a double click is recognized with maximum zoom, zooms out completey
	 *
	 * @param event
	 */
	private handleDoubleClick = (event: MouseEvent) => {
		this.zoomAction = 'tap';
		event.preventDefault();
		event.stopPropagation();
		this.zoomOutIfMax();
	}

	/**
	 * Zooms out completely if at the maximum zoom level
	 */
	private zoomOutIfMax = () => {
		const { scale: currentScale } = this.panzoomInstance.getTransform();

		if (this.zoomAction === 'pinch') {
			return;
		}

		if (currentScale === maxZoom) {
			this.panzoomInstance.zoomTo(0, 0, 2); // Hack to cancel default panzoom animation
			setImmediate(() =>
				animate({ scale: currentScale }, { scale: minZoom }, {
					duration: 400,
					step: ({ scale }: { scale: number }) => {
						this.panzoomInstance.zoomAbs(this.state.sceneWidth / 2, this.state.sceneHeight / 2, scale);
					}
				})
			);
		}
	}
}

export default ZoomableView;
