import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RouteMetadataService from '../../../../services/RouteMetadataService';
import GeolocationService from '../../../Base/services/GeolocationService';
import {
	addLeafletGroupedEntries,
	LEAFLET_GROUPED_FETCH_ENTRIES, setLeafletGroupedErrors,
} from './leafletGroupedActions';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';

/**
 * Fetch entry list
 */
function* fetchLeafletGrouped(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	let theParams = action.params;
	let theUrl = '/api/public/v1/leaflets/grouped';

	// add geolocation
	if (theParams.onlyNearbyLeaflets) {
		const geolocationReducer = yield select((state: any) => state.geolocationReducer);
		theParams = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, theParams);
		theUrl = '/api/public/v1/leaflets/nearbygrouped';
	}

	// if we have a state as filter -> we will use in federal states endpoint
	if (theParams.store_vendor) {
		theUrl = `/api/public/v1/vendors/${theParams.store_vendor}/leaflets-grouped/`;
	} else if (theParams.state) {
		theUrl = `/api/public/v1/federal-states/${theParams.state}/leaflets-grouped/`;
	}

	// add default params
	theParams.include_ended = true;
	theParams.include_publish_to_all =  true;

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url: theUrl,
				params: theParams,
			} as IRequestObject);
		});

		// put route metadata
		if (action.params.pushRouteMetadata) {
			RouteMetadataService.setMetadata(action.queryKey, {
				totalItemsCount: (response.getContent('totalGroupMembersCount')) ? response.getContent('totalGroupMembersCount') : 0,
				maxItemDiscount: response.getContent('totalGroupMembersCount') ? 40 : 0, // TODO: change this to real part
			} as IRouteMetadataType);
		}

		yield put(addLeafletGroupedEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setLeafletGroupedErrors(action.queryKey, e));
	}
}

export function* leafletGroupedSaga() {
	yield takeEvery(LEAFLET_GROUPED_FETCH_ENTRIES, fetchLeafletGrouped);
}
