import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addProductGroupEntries, addProductGroupEntry,
	setProductGroupErrors,
	VENDOR_FETCH_ENTRIES, VENDOR_FETCH_ENTRY,
} from './productGroupActions';
import { IRequestObject } from '../../../../../boilerplate/redux/types/IRequestObject';

/**
 * Fetch entry list
 */
function* fetchProductGroups(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: '/api/public/v1/product-groups',
					params: action.params,
				} as IRequestObject);
			},
		);

		yield put(
			addProductGroupEntries(
				action.queryKey,
				response.getContent(),
				response.getMetaData(),
				action.options,
			),
		);
	} catch (e) {
		yield put(setProductGroupErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry details
 */
function* fetchProductGroup(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: `/api/public/v1/product-groups/${action.params.product_group}`,
				} as IRequestObject
			);
		});

		yield put(addProductGroupEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setProductGroupErrors(action.queryKey, e));
	}
}

export function* productGroupSaga() {
	yield takeEvery(VENDOR_FETCH_ENTRY, fetchProductGroup);
	yield takeEvery(VENDOR_FETCH_ENTRIES, fetchProductGroups);
}
