import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import ProductPromotionDetail from '../../components/ProductPromotionDetail/ProductPromotionDetail';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import { withTitlePostfix, withClickbait } from '../../../../utils/routeMetadataUtils';
import withRouteMetadata from '../../../../hocs/withRouteMetadata';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionDetailSkeleton from '../../components/ProductPromotionDetail/ProductPromotionDetailSkeleton';
import { formatCurrency } from '../../../Base/components/Currency/Currency';
import AdConfigContainer from '../../../../components/General/AdConfig/AdConfigContainer';

interface IProductPromotionDetailContainerProps {
	gridKey?: string;
	promotion: IProductPromotionType | null;
	promotionSlug?: SlugType;
}

class ProductPromotionDetailContainer extends React.Component<IProductPromotionDetailContainerProps & IWithDataProps> {

	public render () {
		const { gridKey, promotion, promotionSlug } = this.props;

		if (!promotion) {
			return (
				<ProductPromotionDetailSkeleton />
			);
		}

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={promotion.vendor.slug}
					desktopIds={[4403491]}
					mobileIds={[4403480]}
					sitebarId={4403490}
				/>

				<ProductPromotionDetail
					promotion={promotion}
					gridKey={gridKey}
					promotionSlug={promotionSlug}
				/>
			</React.Fragment>
		);
	}
}

function generateMetaTitle (routeMetadata: IRouteMetadataType, props: any) {
	const { promotion } = props;

	let titleString = '';
	
	if (!promotion) {
		titleString = 'Top Angebot';
	} else if (promotion.discountedPrice) {
		titleString = `${promotion.title} nur ${formatCurrency(promotion.discountedPrice, promotion.currency)} statt ${formatCurrency(promotion.originalPrice, promotion.currency)} bei ${promotion.vendorName}`;
	} else if (promotion.newPrice) {
		titleString = `${promotion.title} nur ${formatCurrency(promotion.newPrice, promotion.currency)} bei ${promotion.vendorName}`;
	} else if (promotion.discountAmount) {
		titleString = `${promotion.title}`;
	} else if (promotion.discountPercentage || promotion.type === "assortment") {
		titleString = `${promotion.title} bei ${promotion.vendorName}`;
	}

	return withTitlePostfix(titleString);
}


function generateMetaDescription (routeMetadata: IRouteMetadataType, props: any) {
	const { promotion } = props;

	if (!promotion) {
		return withClickbait(`Top Angebot zum Sonderpreis » bei Aktionsfinder informieren ✓ Rabatt sichern ✓ bares Geld sparen`, `✓ Den besten Deal schnappen!`);
	} else if (promotion.discountedPrice) {
		return withClickbait(`Nicht verpassen: ${promotion.title} zum Sonderpreis » bei Aktionsfinder informieren ✓ Rabatt sichern ✓ bares Geld sparen`, `✓ Den besten Deal schnappen!`);
	} else if (promotion.newPrice) {
		return withClickbait(`Nicht verpassen: ${promotion.title} zum Sonderpreis » bei Aktionsfinder informieren ✓ Rabatt sichern ✓ bares Geld sparen`, `✓ Den besten Deal schnappen!`);
	} else if (promotion.discountAmount) {
		return withClickbait(`Top-Angebot: ${promotion.title} » Gutschein entdecken ✓ Preise vergleichen ✓ bares Geld sparen`, `✓ Den besten Deal schnappen!`);
	} else if (promotion.discountPercentage || promotion.type === "assortment") {
		return withClickbait(`Sortimentsaktion: ${promotion.title} bei ${promotion.vendorName} » bei Aktionsfinder informieren ✓ Preise vergleichen ✓ kein Schnäppchen verpassen`, `✓ Hier klicken & sparen!`);
	}

	return '';
}

export default withRouteMetadata(
	ProductPromotionDetailContainer,
	(theProps: any, otherProps: any) => {
		return `p-${theProps.promotionSlug}`;
	},
	{
		metaTitle: generateMetaTitle,
		metaDescription: generateMetaDescription,
	} as IRouteMetadataSetterType,
);
