import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import ProductPromotionGridGroupedContainer
	from '../../containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import PromotionsAggregatedByBrandsWidgetContainer from '../../containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../types/IPromotionsAggregatedByBrandType';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IPromotionsByBrandInStateProps {
	brandSlug: SlugType;
	stateSlug: SlugType;
}

class PromotionsByBrandInState extends React.Component<IPromotionsByBrandInStateProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, brandSlug, stateSlug } = this.props;

		const brandName = (routeMetadata && routeMetadata.brand && routeMetadata.brand.title) ? routeMetadata.brand.title : brandSlug;
		const stateName = (routeMetadata && routeMetadata.state && routeMetadata.state.title) ? routeMetadata.state.title : stateSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>

						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridGroupedContainer
								enablePagination={true}
								groupBy="city"
								groupTitle={`%city%: %count% ${brandName} Aktion %maxDiscount%`}
								groupTitlePlural={`%city%: %count% ${brandName} Aktionen %maxDiscount%`}
								brandSlug={brandSlug}
								stateSlug={stateSlug}
								orderDir="asc"
								orderBy="createdAt"
								moreLabel={`Alle ${brandName} Aktionen in %city%`}
								moreRouteCallbk={this.routerCallbkPromotionListWidget}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridGroupedContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<React.Fragment>
							<PromotionsAggregatedByBrandsWidgetContainer
								title={`Ähnliche Marken in ${stateName}`}
								widgetKey={`pbst-brand-widget-${brandSlug}-${stateSlug}`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								brandSlug={brandSlug}
								stateSlug={stateSlug}
								excludeSlug={brandSlug}
							/>
							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>
						</React.Fragment>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.brand.title : '',
				route: 'BrandDetailRoute',
				routeParams: {brand: this.props.routeMetadata ? this.props.routeMetadata.brandSlug: ''}
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.state.title: '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: IProductPromotionGroupedType) => {
		const { brandSlug } = this.props;

		return {
			name: 'PromotionsFromBrandAndCityRoute',
			params: {
				brand: brandSlug,
				city: item.group.slug,
			},
		};
	};

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { stateSlug } = this.props;

		return {
			name: 'PromotionsByBrandInStateRoute',
			params: {
				state: stateSlug,
				brand: item.group.slug,
			},
		};
	}
}

export default PromotionsByBrandInState;
