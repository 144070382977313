import { ILeafletType } from "../types/ILeafletType";

const LeafletService = {

    /**
	 * Generates a href to link to leaflet. 
	 */
    generateLeafletHref(leaflet: ILeafletType): string {
        // For cases when we need to show [Themeworld] instead of leaflet viewer
        if (leaflet.promotionWorld && leaflet.linkedThemeWorld) {
            return '/tw/' + leaflet.linkedThemeWorld.slug;
        }
        return '/l/' + leaflet.slug;
    },

    /**
     * Generates route name and params to navigate to leaflet details
     */
    generateLeafletRoute(leaflet: ILeafletType): { route: string, params: { [key: string]: string } } {
        // Route settings for cases when we need to show [Themeworld] instead of leaflet viewer
        // Note: commented out because we had to navigate to this page when Leaflet has a Themeworld slug
        // if (leaflet.promotionWorld && leaflet.linkedThemeWorld) {
        //     return {
        //         route: 'ThemeworldDetailRoute',
        //         params: {
        //             themeworld: leaflet.linkedThemeWorld.slug,
        //         },
        //     }
        // }

        // Default leaflet route settings
        return {
            route: 'LeafletDetailRoute',
                params: {
                leaflet: leaflet.slug
            },
        }
    }
}

export default LeafletService;
