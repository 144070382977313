import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import ShopDetail from '../../components/ShopDetail/ShopDetail';
import { IShopType } from '../../types/IShopType';


interface IShopDetailProps {
	shopSlug: string;
	queryKey: string;
	shop: string;
	data: IShopType;
}

class ShopDetailContainerInner extends React.Component<IShopDetailProps & IWithDataProps & IWithRouteMetadataType> {
	public render() {
		const { data, shop, shopSlug, ...restProps } = this.props;

		return (
			<ShopDetail
				shop={data as IShopType}
				shopSlug={shopSlug}
				{...restProps}
			/>
		);
	}
}

export default ShopDetailContainerInner;
