import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as leafletsAggregatedByStatesWidgetActions from './leafletsAggregatedByStatesWidgetActions';
import { ILeafletsAggregatedByStateType } from '../../types/ILeafletsAggregatedByStateType';

export type LeafletsAggregatedByStatesWidgetAction = ActionType<typeof leafletsAggregatedByStatesWidgetActions>;

export interface ILeafletsAggregatedByStatesWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: ILeafletsAggregatedByStateType };
	readonly queries: { [queryKey: string]: ILeafletsAggregatedByStateType[] };
}

export function leafletsAggregatedByStatesWidgetReducer(
	state = baseInitialState as ILeafletsAggregatedByStatesWidgetStateType,
	action: LeafletsAggregatedByStatesWidgetAction,
) {
	const Reducer = new BaseReducer('leafletsAggregatedByStatesWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
