import * as React from 'react';
import * as styles from '../AppExclusive.scss';
import Button from '../../../../../components/General/Button/Button';
import InternalTrackingService from '../../../../../services/InternalTrackingService';
import DataLayerService from '../../../services/DataLayerService';

interface IMobileAppButtonProps {
    title: string;
    href: string;
}

class AppExclusiveMobileButton extends React.PureComponent<IMobileAppButtonProps> {

    public render() {
        const { title, href } = this.props;

        return (
            <div className={styles.appButton}>
                <Button
                    href={href}
                    size={'l2'}
                    theme='primary'
                    fullWidth={true}
                    onClick={this.trackAppEvent}
                >
                    {title}
                </Button>
            </div>
        );
    }

    /**
     * Track App click event
     */
    private trackAppEvent = () => {
        const { href, title } = this.props;

        InternalTrackingService.track('PWA_TO_APP', {
            'targetUrl': href,
            'pageTitle': document.title,
        });

        DataLayerService.push('PWA_TO_APP', {
            'targetUrl': href,
            'pageTitle': document.title,
            'pagePath': window.location.pathname,
            'buttonText': title,
        });
    };
}

export default AppExclusiveMobileButton;