import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { ILeafletType } from '../../types/ILeafletType';
import * as styles from './LeafletViewer.scss';
import LeafletHeaderSkeleton from './LeafletHeaderSkeleton'

interface ILeafletViewerPropsType {
    leaflet: ILeafletType;
}

class LeafletViewerSkeleton extends React.PureComponent<ILeafletViewerPropsType & IWithDataProps, {}> {
    public static defaultProps = {};


    public render() {


        return (
            <PageWrapper noLayout={true}>
                <div className={styles.leafletViewer}>
                    <LeafletHeaderSkeleton />
                </div>
            </PageWrapper>
        );
    }


}

export default LeafletViewerSkeleton;
