import * as React from 'react';
import { ILeafletPageType } from '../../types/ILeafletPageType';
import { ILeafletType } from '../../types/ILeafletType';
import LeafletViewerPageWrapper from './LeafletViewerPageWrapper';
import LeafletFooter from './LeafletFooter';
import { LeafletViewerType } from '../../hocs/withLeaflet';	


interface ILeafletViewerContentPropsType {
	leaflet: ILeafletType;
	pages: ILeafletPageType[];
	leafletViewerType: LeafletViewerType;
}

class LeafletViewerContent extends React.Component<ILeafletViewerContentPropsType> {
	public render() {
		const { leaflet, pages, leafletViewerType } = this.props;

		return (
			<React.Fragment>

				<LeafletViewerPageWrapper
					leaflet={leaflet}
					pages={pages}
					leafletViewerType={leafletViewerType}
				/>

				<LeafletFooter
					leaflet={leaflet}
					pages={pages}
					showPagesMenu={true}
					showShopsmenu={true}
					showPromotionsMenu={true}
					showBookmarkMenu={false}
					leafletViewerType={leafletViewerType}
				/>

			</React.Fragment>
		);
	}

}

export default LeafletViewerContent;
