import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import VouchersByVendor from '../App/modules/Promotion/components/VouchersByVendor/VouchersByVendor';
import { currentEntryWord, pluralizerVouchers, withClickbait, withTitlePostfix } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';


class VouchersByVendorRoute extends BaseRoute {

	public static async getInitialProps(state: any) {
		const reduxData = await VouchersByVendorRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						
						<VouchersByVendor
							vendorSlug={query.vendor + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		VouchersByVendorRoute,
		(props: any) => `vov-${props.match.params.vendor}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount)} ${pluralizerVouchers(routeMetadata.totalItemsCount)} von ${routeMetadata.vendor.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.vendor.title} ${pluralizerVouchers(routeMetadata.totalItemsCount)} jetzt sichern`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Entdecken Sie alle aktuellen ${routeMetadata.vendor.title} Gutscheine » kein Angebot verpassen ✓ Rabatt sichern ✓ bares Geld sparen`, `✓ Jetzt entdecken!`),
		} as IRouteMetadataSetterType,
	),
);



