import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { IQueryMetaDataType } from '../../../../boilerplate/redux/types/IQueryMetaDataType';
import * as styles from './Pagination.scss';
import { isBrowser, isServer } from '../../../../boilerplate/razzle/razzleUtils';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
import { Helmet } from 'react-helmet-async';
import appConfig from '../../../config/appConfig';

interface IPaginationProps {
	fetchMore?: (queryKey: string) => void;
	metadata?: IQueryMetaDataType;
	queryKey?: string;
	children: React.ReactNode;
	enablePagination?: boolean;
	LoaderComponent: React.ReactNode;

	// injected
	appProps?: any;
}

class Pagination extends React.PureComponent<IPaginationProps> {

	public static defaultProps = {
		enablePagination: true,
		LoaderComponent: <div>Lade ...</div>,
	};

	public componentDidMount() {
		if (!isBrowser()) {
			return;
		}

		// make sure to disable overflow anchor
		if (document?.getElementById('root')?.style) {
			document.getElementById('root').style.overflowAnchor = 'none';
		}
	}

	public componentWillUnmount() {
		if (!isBrowser()) {
			return;
		}

		// make sure to disable overflow anchor
		if (document?.getElementById('root')?.style) {
			document.getElementById('root').style.overflowAnchor = 'auto';
		}
	}

	public render () {
		const { metadata, children, enablePagination } = this.props;

		if (!metadata || !enablePagination) {
			return children;
		}

		if (!isBrowser()) {
			return (
				<div>
					{children}
					{this.LoadingComponent()}
				</div>
			);
		}

		return (
			<div>
				<InfiniteScroll
					pageStart={metadata.lastOffset}
					loadMore={this.fetchMore}
					hasMore={!metadata.loading && metadata.hasMore}
					useWindow={true}
					threshold={100}
				>
					{children}
					{this.LoadingComponent()}
				</InfiniteScroll>
			</div>
		);
	}

	private LoadingComponent = () => {
		const { metadata, LoaderComponent, queryKey } = this.props;

		if (metadata) {
			const baseLinkObject = this.getCurrentBaseLinkObject();

			if (!metadata.totalPages) { metadata.totalPages = 1; }

			let currentPageNumber = (baseLinkObject.params && baseLinkObject.params.page) ? parseInt(baseLinkObject.params.page +'', 10) : 1;
			if (isNaN(currentPageNumber)) {
				currentPageNumber = 1;
			}

			if ((metadata.hasMore && metadata.totalPages)
				|| (baseLinkObject.params.page)) {

				const paginationItemArray = this.renderPagesLinks(currentPageNumber, metadata.totalPages);

				const hasPrevPage = (currentPageNumber > 1);
				const hasNextPage =  currentPageNumber < metadata.totalPages;

				return (
					<div className={styles.paginationWrapper}>
						<Helmet>
							{( !hasPrevPage ) ? null :
								<link rel="prev" href={this.getPaginatedLink(currentPageNumber - 1)} />
							}
							{( !hasNextPage) ? null :
								<link rel="next" href={this.getPaginatedLink(currentPageNumber + 1)} />
							}
							<link rel="canonical" href={this.getPaginatedLink(currentPageNumber)} />
						</Helmet>

						{(!hasPrevPage) ? null :
						 <a
								href={this.getPaginatedLink(currentPageNumber - 1)}
							 rel="prev"
							 className={styles.paginationPageNumber}
						 >
							 &laquo;
						 </a>
						}

						{
							paginationItemArray.map((i, index) => {
								const isCurrentPage = (currentPageNumber && currentPageNumber === i);

								return (
									<a
										href={this.getPaginatedLink(i)}
										key={`pagination-${i}-${index}`}
										className={`${styles.paginationPageNumber} ${(isCurrentPage) ? styles.paginationPageNumberActive : ''}`}
									>
										{i}
									</a>
								);
							})
						}

						{(!hasNextPage) ? null :
						 <a
								href={this.getPaginatedLink(currentPageNumber + 1)}
							 rel="next"
							 className={styles.paginationPageNumber}
						 >
							 &raquo;
						 </a>
						}
					</div>
				);
			}

			return (
				<Helmet>
					<link rel="canonical" href={this.getPaginatedLink(currentPageNumber)} />
				</Helmet>
			);
		}

		return (
			<React.Fragment key={queryKey}>
				{LoaderComponent}
			</React.Fragment>
		);
	};

	/**
	 * Takes the correct request url string from either res (server) or window (client)
	 * and returns a normalized object of current path and params
	 */
	private getCurrentBaseLinkObject() {
		const { appProps } = this.props;

		// get correct url
		let requestUrl = '';
		if (isServer()) {
			requestUrl = appConfig.mainDomain + appProps.requestUrl;

			// remove the base domain
			if (requestUrl.indexOf('http') === -1) {
				requestUrl = `https://${requestUrl}`;
			}
		} else if (typeof window !== 'undefined') {
			requestUrl = window.location.href;
		}

		// split the path and query part apart
		const requestSplit = requestUrl.split('?');

		return {
			path: requestSplit[0],
			params: (requestSplit[1]) ? queryString.parse(requestSplit[1]) : {},
		};
	}

	/**
	 * Returns the path for a specific paginated page and makes sure, that the first page has no page param attached
	 * 
	 * @param pageNumber the page number
	 */
	private getPaginatedLink(pageNumber: number): string {
		const baseLinkObject = this.getCurrentBaseLinkObject();

		// if we are on the first page -> no ?page param needed
		if (pageNumber <= 1) {
			return baseLinkObject.path;
		}

		return `${baseLinkObject.path}?page=${pageNumber}`;
	}

	/**
	 * Fetch more entries for these results
	 */
	private fetchMore = () => {
		const { fetchMore, queryKey } = this.props;

		if (fetchMore) {
			fetchMore(queryKey + '');
		}
	};

	/**
	 * Renders the pages for navigating
	 */
	private renderPagesLinks = (currentPage: number, pageCount: number) => {
		const delta = 5;

		const range = [];

		if (pageCount > 26) {
			for (let i = 1; i <= 20; i++) {
				range.push(i);
			}

			if ((currentPage - delta) > 2 && currentPage - delta > 20 ) {
				range.push('...');
			}

			for (let i = Math.max(2, (currentPage - delta)); i <= Math.min((pageCount - 1), (currentPage + delta)); i += 1) {
				!range.includes(i) && range.push(i);
			}

			if ((currentPage + delta) < (pageCount - 1)) {
				range.push('...');
			}

			if (pageCount !== 1) range.push(pageCount);
		} else {
			for (let i = 1; i <= pageCount; i++) {
				range.push(i);
			}
		}

		return range;
	}

}

function mapStateToProps(state: any) {
	return {
		appProps: state.appProps,
	};
}

const withConnect = connect(
	mapStateToProps,
);

export default compose(withConnect)(Pagination);
