import * as React from 'react';
import Icon from '../../../modules/Base/components/Icon/Icon';
import * as styles from './Menu.scss';

interface IMenuGoBackProps {
    toMainMenu: () => void;
    title?: string;
}

class MenuGoBack extends React.Component<IMenuGoBackProps, {}> {

    public static defaultProps = {
        title: "Hauptmenü",
    };

    public render () {

        const { toMainMenu, title } = this.props;

        return (
            <a className={styles.menuGoBack} onClick={toMainMenu}>
                <span className={styles.menuGoBackIcon}>
                    <Icon icon="arrow-left" className={styles.menuGoBackIconRowArrow} />
                </span>
                <span>{title}</span> 
            </a>
        )
    }
}

export default MenuGoBack;