/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IMixedEntityType } from '../../types/IMixedEntityType';
import { SearchPageFilterItemMetadataType } from '../../../Search/components/SearchPage/FilterInputs/SearchPageFilterGroupCheckboxGroupItem';

export const MIXED_ENTITY_FETCH_ENTRIES = '@BasePipeline/mixedEntityReducer_FETCH_ENTRIES';
export const MIXED_ENTITY_FETCH_ENTRY = '@BasePipeline/mixedEntityReducer_FETCH_ENTRY';
export const MIXED_ENTITY_ADD_ENTRIES = '@BasePipeline/mixedEntityReducer_ADD_ENTRIES';
export const MIXED_ENTITY_ADD_ENTRY = '@BasePipeline/mixedEntityReducer_ADD_ENTRY';

export const MIXED_ENTITY_SET_ERRORS = '@BasePipeline/mixedEntityReducer_SET_ERRORS';

export type SearchMetaDataType = {
    searchResult?: {
        content?: IMixedEntityType[];
        first?: boolean;
        last?: boolean;
        number?: number;
        numberOfElements?: number;
        pageable?: any;
        size?: number;
        sort?: {
            unsorted?: boolean;
            sorted?: boolean;
        };
        totalElements?: number;
        totalPages?: number;
    };
    optionBrand?: SearchPageFilterItemMetadataType[];
    optionCity?: SearchPageFilterItemMetadataType[];
    optionIndustry?: SearchPageFilterItemMetadataType[];
    optionProductGroup?: SearchPageFilterItemMetadataType[];
    optionState?: SearchPageFilterItemMetadataType[];
    optionType?: SearchPageFilterItemMetadataType[];
    optionUnit?: SearchPageFilterItemMetadataType[];
    optionVendor?: SearchPageFilterItemMetadataType[];
};

/* ### ACTIONS ### */

export function fetchMixedEntities(queryKey: string) {
    return {
        type: MIXED_ENTITY_FETCH_ENTRIES,
        queryKey,
    };
}

export function addMixedEntityEntries(queryKey: string, searchTerm: string, entries: IMixedEntityType[], metaData?: IQueryMetaDataType, searchMetaData?: SearchMetaDataType, options?: IFetchOptionType) {
    return {
        type: MIXED_ENTITY_ADD_ENTRIES,
        queryKey,
        searchMetaData,
        entries,
        metaData,
        searchTerm,
        options,
    };
}

export function setMixedEntityErrors(queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: MIXED_ENTITY_SET_ERRORS,
        queryKey,
        errors,
        metaData,
    };
}
