import * as React from 'react';
import { ReactNode } from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Link from '../../../Base/components/Link/Link';
import BookmarkIconContainer from '../../../Bookmark/containers/BookmarkIcon/BookmarkIconContainer';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';
import { IVendorType } from '../../types/IVendorType';
import * as styles from './VendorListItem.scss';

interface IVendorListItemProps {
    vendor: IVendorType;
    children?: ReactNode;
    sideItem?: JSX.Element;
    route?: string;
	routeParams?: any;
}

class VendorListItem extends React.Component<IVendorListItemProps> {
    public static defaultProps = {
		widgetKey: 'vendor-list-widget',
        route: 'VendorDetailRoute',
	};

    public render() {
        const { vendor, sideItem, route, routeParams } = this.props;

        return (
            <div className={styles.vendorItemWrapper}>
                <Link
                    route={route}
                    params={routeParams || {
                        vendor: vendor.slug
                    }}
                >
                    <a className={styles.vendorItemContainer}>

                        <span className={styles.vendorItemImageContainer}>
                            {(vendor.resolutions) ?
                                <ImageEnhanced
                                    source={vendor.resolutions}
                                    className={styles.vendorItemImage}
                                    alt={vendor.name}
                                    size={'s'}
                                    bgImage={true}
                                />
                                :
                                <ProductImagePlaceholder
                                    alt={vendor.name}
                                />
                            }
                        </span>

                        <span className={styles.vendorItemInfoContainer}>

                            <span className={styles.vendorItemTextContainer}>

                                {(!vendor.name) ? null :
                                    <span className={styles.vendorItemTitle}>{vendor.name}</span>
                                }

                            </span>
                        </span>
                    </a>
                </Link>

                {sideItem ? sideItem :
                    <span className={styles.vendorItemIconContainer}>
                        <BookmarkIconContainer
                            entity="vendor"
                            slug={vendor.slug}
                        />
                    </span>
                }
            </div>
        );
    }
}

export default VendorListItem;
