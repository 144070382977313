const serverErrorMap = {
    201: 'Dieses Benutzerkonto ist bereits vorhanden.',
    401: 'E-Mail Adresse oder Passwort ungültig.',
    400: 'Fehlerhafte Eingabe',
    403: 'Diese Aktion ist leider nicht erlaubt.',
    409: 'Diese E-Mail Adresse ist bereits vergeben.',
    404: 'Konnte nicht gefunden werden',
    500: 'Leider ist ein unerwarteter Fehler aufgetreten, bitte versuche es erneut.'
};

export default serverErrorMap;
