import { IShoppingListItemType } from '../../types/IShoppingListItemType';
import {
	ADD_SHOPPING_LIST_ITEM,
	ADD_SHOPPING_LIST_ITEM_LINE,
	REMOVE_SHOPPING_LIST_ITEM,
	REPLACE_SHOPPING_LIST_ITEM,
	SET_SHOPPING_LIST,
	SET_SHOPPING_LIST_STATE,
	SHOPPING_LIST_SET_ERRORS,
	UPDATE_SHOPPING_LIST_ITEM,
} from './shoppingListActions';

const NEW_ITEM_OBJ = {
	id: 'new1000',
	isNew: true,
	item: '',
	itemOrder: 0,
	quantity: '',
} as IShoppingListItemType;


const PlaceholderArray = [
	{
		id: 'new1001',
		placeholderWidth: '50%',
		item: '',
		itemOrder: 0,
		quantity: '1',
	} as IShoppingListItemType,
	{
		id: 'new1002',
		placeholderWidth: '60%',
		item: '',
		itemOrder: 0,
		quantity: '1',
	} as IShoppingListItemType,

	{
		id: 'new1003',
		placeholderWidth: '45%',
		item: '',
		itemOrder: 0,
		quantity: '1',
	} as IShoppingListItemType,
];

export interface IShoppingListStateType {
	items: IShoppingListItemType[];
	error: null | string;
	loaded: boolean;
	state: 'saved' | 'unsaved' | 'saving' | 'error' | null;
}

const initialState = {
	items: PlaceholderArray,
	loaded: false,
	error: null,
	state: null,
} as IShoppingListStateType;

let lastNewId = 1000;

let lastShoppingListState = initialState;

export function shoppingListReducer (
	state = initialState,
	action: any,
) {

	switch (action.type) {
		case ADD_SHOPPING_LIST_ITEM:
			lastShoppingListState = Object.assign({}, state);
			return Object.assign({}, state, { note: action.note });


		case REMOVE_SHOPPING_LIST_ITEM:
			lastShoppingListState = Object.assign({}, state);

			const removeItems = state.items.filter((theItem: IShoppingListItemType) => {
				return theItem.id !== action.id;
			});

			return Object.assign({}, state, { items: removeItems });


		case UPDATE_SHOPPING_LIST_ITEM:
			lastShoppingListState = Object.assign({}, state);

			const updateItems = state.items.map((theItem: IShoppingListItemType) => {
				if (theItem.id === action.item.id) {
					return action.item;
				}

				return theItem;
			});

			return Object.assign({}, state, { items: updateItems });


		case REPLACE_SHOPPING_LIST_ITEM:
			lastShoppingListState = Object.assign({}, state);

			const replaceItems = state.items.map((theItem: IShoppingListItemType) => {
				if (theItem.id === action.replaceId) {
					return action.item;
				}

				return theItem;
			});

			return Object.assign({}, state, { items: replaceItems });


		case ADD_SHOPPING_LIST_ITEM_LINE:
			let hasEmptyLine = false;
			for (const item of state.items) {
				if (!item.item) {
					hasEmptyLine = true;

					return state;
				}
			}

			const items = state.items.slice();
			items.push(Object.assign({}, NEW_ITEM_OBJ, { id: `new${lastNewId}` }));
			lastNewId++;

			return Object.assign({}, state, { items });


		case SET_SHOPPING_LIST:
			const setItems = action.items;
			setItems.push(Object.assign({}, NEW_ITEM_OBJ, { id: `new${lastNewId}` }));
			lastNewId++;

			return Object.assign({}, state, { loaded: true, items: setItems });


		case SET_SHOPPING_LIST_STATE:
			return Object.assign({}, state, { state: action.state });


		case SHOPPING_LIST_SET_ERRORS:

			return Object.assign({}, lastShoppingListState, { error: action.error });


		default:
			return state;
	}
}
