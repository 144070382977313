import * as React from 'react';
import * as styles from './ShopListWidgetLeaflet.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';

class ShopListWidgetLeafletSkeleton extends React.Component<{}> {

	public render () {

		const limitArr = Array.from(Array(5).keys());

		return (
			<div className={styles.shopListWidgetContainer}>

				{ limitArr.map((index) => 
	
					<div className={styles.shopContainer}>

						<span className={styles.shopWrapper}>
							<span className={styles.shopContainerVendorItemImage}>
								<SkeletonInline />
							</span>
							
							<span className={styles.shopInfoWrapper}>

								<span className={styles.shopTitle}>
									<SkeletonInline width={70} />
								</span>

								<span className={styles.shopAdditionalInfo}>
									<SkeletonInline width={80}/>
								</span>

								<span className={styles.shopAdditionalInfo}>
									<SkeletonInline width={50}/>
								</span>
							</span>

							<span className={styles.shopDistanceWrapper}>
								<SkeletonInline width={10}/>
							</span>

						</span>

					</div>

				)}
			</div>
		)
	}
}

export default ShopListWidgetLeafletSkeleton;
