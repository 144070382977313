import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ShoppingCentersInState from '../App/modules/ShoppingCenter/components/ShoppingCentersInState/ShoppingCentersInState';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { withTitlePostfix, withClickbait, pluralizerShoppingCenters } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class ShoppingCentersInStateRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await ShoppingCentersInStateRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = this.props.match.params ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403494]}
					mobileIds={[4403482, 4403481]}
					superbannerId={4403495}
					sitebarId={4403493}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<ShoppingCentersInState
							stateSlug={query.state + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403493}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		ShoppingCentersInStateRoute,
		(props: any) => `scst-${props.match.params.state}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${pluralizerShoppingCenters(routeMetadata.totalItemsCount)} in ${routeMetadata.state.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${pluralizerShoppingCenters(routeMetadata.totalItemsCount)} » ${routeMetadata.state.title}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`Einkaufszentren ${routeMetadata.state.title} im Überblick bei Aktionsfinder » Hier klicken und über alle Einzelheiten informieren.`),
		} as IRouteMetadataSetterType,
	),
);