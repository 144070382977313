import { withRouter } from 'react-router';
import * as React from 'react';
import LeafletDetailContainer from '../App/modules/Leaflet/containers/LeafletDetail/LeafletDetailContainer';
import withLeaflet from '../App/modules/Leaflet/hocs/withLeaflet';
import BaseRoute from './_BaseRoute';
import { LeafletViewerType } from '../App/modules/Leaflet/hocs/withLeaflet';

class LeafletInfopostRoute extends BaseRoute {
    public render() {
        const query = this.props.match.params ? this.props.match.params : {};

        return (
            <React.Fragment>
                {withLeaflet(
                    LeafletDetailContainer,
                    query.leaflet + '',
                    Object.assign(
                        {},
                        this.props,
                        {
                            leafletSlug: query.leaflet,
                            vendorSlug: query.vendorSlug,
                            stateSlug: query.stateSlug,
                        },
                    ),
					LeafletViewerType.INFOPOST,
                )
				}
            </React.Fragment>
        );
    }
}

export default withRouter(LeafletInfopostRoute);
