import { DateTime } from 'luxon';
import * as React from 'react';
import Icon from '../../../Base/components/Icon/Icon';
import * as styles from './PromotionworldHeader.scss';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';
import { history } from '../../../../../boilerplate/razzle/history';
import { RouteComponentProps, withRouter } from 'react-router';
import HistoryService from '../../../../services/HistoryService';
import { IPromotionworldType } from '../../types/IPromotionworldType';
import Link from '../../../Base/components/Link/Link';
import LeafletRelativeValidDate from '../../../Leaflet/components/LeafletRelativeValidDate/LeafletRelativeValidDate';
import { onlyRelativeDateHoferHack } from '../../../Leaflet/store/Leaflet/leafletActions';

interface IPromotionworldHeaderPropsType {
    promotionworld: IPromotionworldType;
}

class PromotionworldHeader extends React.Component<IPromotionworldHeaderPropsType & RouteComponentProps, {}> {
    public static defaultProps = {};

    public state = {

    };

    public render() {
        const { promotionworld: { leaflet } } = this.props;

        const vendorLogo = leaflet.vendorLogoResolutions && typeof leaflet.vendorLogoResolutions['small'] !== 'undefined' ? leaflet.vendorLogoResolutions['small'] : null;

        return (
            <div className={styles.promotionworldHeaderContainer}>
                <div className={styles.promotionworldHeaderBlockInfo}>
                    {/* <Icon
                        icon="favorite"
                        className={styles.promotionworldHeaderInfoFavouriteIcon}
                    /> */}

                    <Link
                        route="VendorDetailRoute"
                        params={{
                            vendor: leaflet.vendorSlug,
                        }}
                    >
                        <a className={styles.promotionworldHeaderVendorItemImage}
                            style={{
                                backgroundImage: `url(${vendorLogo})`,
                            }}
                        />
                    </Link>

                    <div className={styles.promotionworldHeaderInfo}>
                        {!leaflet.title ? null : <h1 className={styles.promotionworldHeaderInfoTitle}>{leaflet.title}</h1>}

                        {!leaflet.validTo ? null : 
                            <div>
                                {leaflet.onlyRelativeDate || onlyRelativeDateHoferHack(leaflet) ?
                                    <LeafletRelativeValidDate
                                        validFrom={leaflet.validFrom}
                                        validTo={leaflet.validTo}
                                    />
                                    :
                                    <span>
                                        gültig bis{' '}
                                        {DateTime.fromISO(leaflet.validTo)
                                            .setLocale('de')
                                            .toLocaleString()}
                                    </span>
                                }
                            </div>
                        }
                    </div>
                </div>

                <div className={styles.promotionworldHeaderBlockControls}>
                    {/* <button type="button" className={`${styles.promotionworldHeaderIcon} ${styles.promotionworldHeaderControlsFavouriteIcon}`} >
                        <Icon icon="favorite" />
                    </button> */}

                    <button type="button" className={styles.promotionworldHeaderIcon} onClick={this.goBack}>
                        <Icon icon="close" />
                    </button>
                </div>
            </div>
        );
    }

    /**
     * Go back to last page
     */
    private goBack = () => {
        if (!isBrowser()) {
            return;
        }

        // if we have a history on this page (excluding all other promotionworlds) -> go back
        const lastHistoryEntry = HistoryService.last();
        if (lastHistoryEntry && lastHistoryEntry.substr(0, 3) !== '/l/') {
            history.goBack();
        } else {
            window.location.href = '/';
        }
    };
}

export default withRouter(PromotionworldHeader);
