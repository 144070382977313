import * as React from 'react';
import BlankPixel from '../../../../components/General/BlankPixel/BlankPixel';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import Link from '../../../Base/components/Link/Link';
import { IVendorType } from '../../types/IVendorType';
import * as styles from './VendorLogoWidget.scss';
import Icon from '../../../Base/components/Icon/Icon';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';

interface IVendorLogoWidgetProps {
	vendors: IVendorType[];
	title: string;
	widgetKey?: string;
	moreLabel?: string;
	moreRoute?: string;
}

class VendorLogoWidget extends React.Component<IVendorLogoWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'vendor-logo-widget',
	};

	public render () {
		const { vendors, title, widgetKey, moreLabel, moreRoute } = this.props;

		// TRANSLATE
		return (
			<WidgetBox style="none" noBackground={true}>

				<SectionTitle
					title={title}
					route={moreRoute}
					boxed={true}
				/>

				<div className={styles.vendorLogoWidgetWrapperOuter}>
					<div className={styles.vendorLogoWidgetWrapper}>
						{vendors.map((vendorWidgetItem: IVendorType) => {
							if (!vendorWidgetItem.resolutions) {
								return null;
							}

							return (
								<div className={styles.vendorLogo} key={`${widgetKey}-${vendorWidgetItem.slug}`}>
									<Link
										route="VendorDetailRoute"
										params={{
											vendor: vendorWidgetItem.slug
										}}
									>
										<a
											className={styles.vendorLogoLink}
											title={vendorWidgetItem.name}
										>
											<ImageEnhanced
												className={styles.vendorLogoInner}
												source={vendorWidgetItem.resolutions}
												size="s"
												bgImage={true}
											>
												<BlankPixel />
											</ImageEnhanced>
										</a>
									</Link>
									<Link route='AppExclusiveRoute'>
										<a className={styles.vendorLogoBookmark}>
											<Icon icon="heart" />
										</a>
									</Link>
								</div>
							);
						})}

						{( !moreLabel ) ? null :
							<div className={styles.vendorLogoWidgetMoreItem}>
								<Link route={moreRoute + ''}>
									<a className={styles.vendorLogoWidgetMore}>
										<span className={styles.vendorLogoWidgetMoreLabel}>
											{moreLabel}
										</span>
									</a>
								</Link>
							</div>
						}
					</div>
				</div>
			</WidgetBox>
		);
	}
}

export default VendorLogoWidget;
