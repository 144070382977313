/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IShopWidgetType } from '../../types/IShopWidgetType';

export const SHOP_WIDGET_FETCH_ENTRIES = '@BasePipeline/shopWidgetReducer_FETCH_ENTRIES';
export const SHOP_WIDGET_ADD_ENTRIES = '@BasePipeline/shopWidgetReducer_ADD_ENTRIES';

export const SHOP_WIDGET_SET_ERRORS   = '@BasePipeline/shopWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchShopWidgets (queryKey: string) {
	return {
		type: SHOP_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addShopWidgetEntries (queryKey: string, entries: IShopWidgetType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOP_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setShopWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: SHOP_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
