import * as React from 'react';
import * as styles from './ShopListGroupedByStateGrouped.scss'
import SkeletonBlock from '../../../../../App/components/Layout/Skeleton/SkeletonBlock';
import ShopListSkeleton from '../../../../../App/modules/Vendor/components/ShopList/ShopListSkeleton';

class ShopListGroupedByStateGroupedSkeleton extends React.PureComponent<{}> {

    // TRANSLATE
    public render() {

        const fakeShopGroups = Array(4).fill(' ');
        return (
            <React.Fragment>
                {fakeShopGroups.map((state, indx) => {
                    return (
                        <React.Fragment key={`shops-grid-group-skeleton-${indx}`}>

                            <div className={styles.shopListGroupedByStateWrapper} style={{ marginTop: 20 }}>
                                <h2 className={styles.shopListGroupedByStateTitle}>
                                    <SkeletonBlock style={{ width: 200, height: 24 }} />
                                </h2>
                                
                                <ShopListSkeleton design="large" />

                            </div>
                        </React.Fragment>
                    );
                })
                }
            </React.Fragment>
        );
    }

}

export default ShopListGroupedByStateGroupedSkeleton;
