import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import VendorDetailContainer from '../App/modules/Vendor/containers/VendorDetail/VendorDetailContainer';
import DataStateService from '../App/services/DataStateService';
import { withRouter } from 'react-router';
import { withTitlePostfix, withClickbait } from '../App/utils/routeMetadataUtils';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import BaseRoute from './_BaseRoute';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';


class VendorDetailRoute extends BaseRoute {
	public static async getInitialProps(state: any) {
		const reduxData = await VendorDetailRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403494]}
					mobileIds={[4403482, 4403481]}
					superbannerId={4403495}
					sitebarId={4403493}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<VendorDetailContainer
							queryKey={`vendorDetail${query.vendor}-${DataStateService.getStateKey()}`}
							vendor={query.vendor + ''}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403493}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		VendorDetailRoute,
		(props: any) => `v-${props.match.params.vendor}`,
		{
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.vendor.title.toUpperCase()} Filialen » Aktuelle Öffnungszeiten`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle Märkte von ${routeMetadata.vendor.title.toUpperCase()} im Überblick » Öffnungszeiten & Kontakt ✓ aktuelle Prospekte & Flugblätter`, `✓ Aktionen, Angebote & Gutscheine!`),
		} as IRouteMetadataSetterType,
	),
);
