import React from 'react';
import * as styles from './ShopList.scss';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import ShopListItemSkeleton from '../ShopListItem/ShopListItemSkeleton';

export interface IShopListSkeletonProps {
	limit: number;
	gridKey?: string;
	boxed?: boolean;
	design?: 'small' | 'large';
}

class ShopListSkeleton extends React.PureComponent<IShopListSkeletonProps, {}> {
	public static defaultProps = {
		gridKey: 'shop-list-skeleton',
		boxed: false,
	};

	// TRANSLATE
	public render () {
		const { limit, gridKey, boxed, design } = this.props;

		const limitArr = Array.from(Array(limit).keys());

		return (
			<AppMainColBox boxed={boxed} nested={true}>

				<div>
					{limitArr.map((index: number) => {
						return (
							<div className={styles.shopListWrapper} key={`shops-grid-list-${gridKey}-${index}`}>

								<ShopListItemSkeleton isLarge={design === 'large'} />

							</div>
						);
					})
					}

				</div>
			</AppMainColBox>
		);
	}

}

export default ShopListSkeleton;
