import * as React from 'react';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import Button from '../../../../components/General/Button/Button';
import SymbolGraphic from '../../../Base/components/SymbolGraphic/SymbolGraphic';
import { UrlFilterResetFilterType } from '../UrlFilterProvider/UrlFilterProvider';
import SearchPageGraphic from './SearchPageGraphic.svg';
import * as styles from './SearchPageNoAdvancedFilterResults.scss';

interface ISearchPageNoAdvancedFilterResultsPropType {
    searchTerm: string;
    resetFilters: UrlFilterResetFilterType;
}

class SearchPageNoAdvancedFilterResults extends React.Component<ISearchPageNoAdvancedFilterResultsPropType & IWithDataProps, {}> {
    public render() {
        const { resetFilters } = this.props;

        return (
            <div className={styles.Wrapper}>
                <SymbolGraphic src={SearchPageGraphic} alt="Keine Suchergebnisse für diese Filter" />

                <h1>Leider keine Ergebnisse für deine Filterung gefunden</h1>
                <div>
                    Es wurden keine Ergebnisse mit deiner Filterkombination gefunden.
                    <br />
                    Bitte entferne einige Filter, um passende Ergebnisse zu erhalten.
                </div>

                <div>&nbsp;</div>

                <Button uppercase={false} onClick={resetFilters}>
                    Alle Filter entfernen
                </Button>
            </div>
        );
    }
}

/**
 * @param theProps
 *
 * @constructor
 */
export default SearchPageNoAdvancedFilterResults;
