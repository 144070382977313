import { IQueryMetaDataType } from "../../../../../boilerplate/redux/types/IQueryMetaDataType";
import { IApiErrorType } from "../../../../types/BaseTypes";

/* ### CONSTANTS ### */
export const LOTTERY_PAYMENT_FETCH_ENTRIES = '@BasePipeline/lotteryPaymentReducer_FETCH_ENTRIES';
export const LOTTERY_PAYMENT_ADD_ENTRIES = '@BasePipeline/lotteryPaymentReducer_ADD_ENTRIES';
export const LOTTERY_PAYMENT_ADD_ENTRY = '@BasePipeline/lotteryPaymentReducer_ADD_ENTRY';

export const LOTTERY_PAYMENT_SET_ERRORS   = '@BasePipeline/lotteryPaymentReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function addLotteryPaymentEntries (queryKey: string, entries: [], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LOTTERY_PAYMENT_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setLotteryPaymentErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: LOTTERY_PAYMENT_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
