/* ### CONSTANTS ### */

import { IRouteMetadataType } from '../../types/IRouteMetadataType';

export const ROUTE_METADATA_FETCH_ENTRIES = '@BasePipeline/routeMetadataReducer_FETCH_ENTRIES';
export const ROUTE_METADATA_SET_DATA      = '@BasePipeline/routeMetadataReducer_ADD_ENTRIES';

export const ROUTE_METADATA_SET_ERRORS   = '@BasePipeline/routeMetadataReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchRouteMetadatas (queryKey: string, params: any) {
	return {
		type: ROUTE_METADATA_FETCH_ENTRIES,
		queryKey,
		params,
	};
}

export function setRouteMetadata (queryKey: string, routeMetadata: IRouteMetadataType) {
	return {
		type: ROUTE_METADATA_SET_DATA,
		routeMetadata,
		queryKey,
	};
}

export function setRouteMetadataErrors (queryKey: string, errors: any) {
	return {
		type: ROUTE_METADATA_SET_ERRORS,
	};
}
