import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import LoadingIndicator from '../../../../components/Layout/LoadingIndicator/LoadingIndicator';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import LotteryPayment from '../../components/LotteryPayment/LotteryPayment';
import GenericErrorMap from '../../../../components/Layout/GenericErrorMap/GenericErrorMap';
import serverErrorMap from '../../components/LotteryPayment/serverErrorMap';

interface ILotteryPaymentContainerProps {
    email: string,
    verificationToken: string,
}

class LotteryPaymentContainerInner extends React.Component<ILotteryPaymentContainerProps & IWithDataProps> {
	
	public render () {
		const { data, verificationToken } = this.props;

		return (
			<LotteryPayment 
				data={data}
				verificationToken={verificationToken}
			/>
		)
	}
}

export default function LotteryPaymentContainer (props: ILotteryPaymentContainerProps) {
	const RenderComponent = withData(
		LotteryPaymentContainerInner,
		'lotteryPaymentReducer',
		`lotteryPayment`,
		{
            email: encodeURIComponent(props.email),
            verificationToken: props.verificationToken,
        },
        {
	        ErrorComponent: (errorProps: any) => (
		        <GenericErrorMap error={errorProps} serverErrorMap={serverErrorMap}/>
	        ),
	        LoadingComponent: (errorProps: any) => (
		        <LoadingIndicator>
					Daten werden geprüft ...
		        </LoadingIndicator>
	        ),
        }
        
	);

	return <RenderComponent {...props} />;
}


