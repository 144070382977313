import * as React from 'react';
import * as styles from './DataBlock.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';

interface IDataBlockSkeletonProps {
    title: string,
    dataString?: boolean,
    dataObject?: boolean, //Should be dataString or dataObject defined to show data
}

class DataBlockSkeleton extends React.Component<IDataBlockSkeletonProps, {}> {

	public render () {

        const { title, dataString, dataObject } = this.props;

        let style = styles.dataBlockData;

        if ( dataString ) {
            style += ` ${styles.dataBlockDataString}`
        }

        if ( dataObject ) {
            style += ` ${styles.dataBlockDataObject}`
        }

		return (
			<div className={styles.dataBlockWrapper}>

                <div className={style}>

                    <div className={styles.dataBlockTitle}>
                        <SkeletonInline />
                    </div>

                    {( !dataString ) ? null :
                        <div className={styles.dataBlockDataString}>
                            <SkeletonInline />
                        </div>
                    }

                    {( !dataObject ) ? null :
                        <div>

                            <SkeletonInline /><br/>
                            <SkeletonInline /><br/>
                            <SkeletonInline /><br/>
             
                        </div>
                    }

                </div>

                <a className={styles.dataBlockLink}>
                    <SkeletonInline />
                </a>

            </div>
		);
    }
}

export default DataBlockSkeleton;
