import { ActionType } from 'typesafe-actions';
import BaseReducer, {
baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { ILeafletsAggregatedByProductGroupType } from '../../types/ILeafletsAggregatedByProductGroupType';
import * as leafletsAggregatedByProductGroupsWidgetActions from './leafletsAggregatedByProductGroupsWidgetActions';

export type LeafletsAggregatedByProductGroupsWidgetAction = ActionType<typeof leafletsAggregatedByProductGroupsWidgetActions>;

export interface ILeafletsAggregatedByProductGroupsWidgetProductGroupType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: ILeafletsAggregatedByProductGroupType };
	readonly queries: { [queryKey: string]: ILeafletsAggregatedByProductGroupType[] };
}

export function leafletsAggregatedByProductGroupsWidgetReducer(
	state = baseInitialState as ILeafletsAggregatedByProductGroupsWidgetProductGroupType,
	action: LeafletsAggregatedByProductGroupsWidgetAction,
) {
	const Reducer = new BaseReducer('leafletsAggregatedByProductGroupsWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
