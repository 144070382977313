import * as React from 'react';
import { ReactNode } from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItem from '../../../../components/Layout/WidgetBox/WidgetListItem';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import { IShopWidgetType } from '../../types/IShopWidgetType';
import Distance from '../Distance/Distance';
import OpeningHours from '../OpeningHours/OpeningHours';

interface IShopWidgetTypeListWidgetProps {
	shops: IShopWidgetType[],
	title: string,
	widgetKey?: string,
	style: IWidgetStyleType;
}

class ShopListWidget extends React.Component<IShopWidgetTypeListWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'shop-list-widget',
	};

	public render () {
		const { shops, title, widgetKey, style } = this.props;

		return (
			<WidgetBox title={title} style={style}>
				<WidgetBoxContent>
				{
					shops.map((shopWidgetItem: IShopWidgetType) => {
						const theShop: any = (shopWidgetItem.group) ? shopWidgetItem.group : shopWidgetItem;

						return (
							<WidgetListItem
								key={`${widgetKey}-shopWidgetItemlistwidg-${theShop.slug}`}
								title={`${theShop.title}, ${theShop.address}`}
								subtitle={this.getSubline(theShop)}
								special={(shopWidgetItem.totalItemsCount) ? `${shopWidgetItem.totalItemsCount} Aktionen`: null}
								extra={(theShop.distance) ? this.getDistance(theShop.distance) : null}
								route="ShopDetailRoute"
								params={{ shop: theShop.slug }}
							/>
						);
					})
				}

				</WidgetBoxContent>
			</WidgetBox>
		);
	}

	/**
	 * Returns the correct opening hours for the current day
	 *
	 * @param {IShopOpeningHoursPeriod[]} shop
	 *
	 * @return {string}
	 */
	private getSubline(shop: any): ReactNode {
		return (
			<div>
				<div>
					{shop.zipCode} {shop.city}
				</div>
				<OpeningHours
					mode="next"
					shop={shop}
				/>
			</div>
		);
	}

	/**
	 * Makes sure the rounded distance is a nicely rounded number, but never 0
	 *
	 * @param {number} distance
	 *
	 * @return {number}
	 */
	private getDistance(distance: number) : ReactNode {
		return (
			<Distance
				distanceInMeters={distance}
			/>
		)
	}
}

export default ShopListWidget;
