import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import ShopDetailContainer from '../App/modules/Vendor/containers/ShopDetail/ShopDetailContainer';
import DataStateService from '../App/services/DataStateService';
import { withClickbait, withTitlePostfix } from '../App/utils/routeMetadataUtils';
import withData from '../boilerplate/redux/hocs/withData';
import { connect } from 'react-redux';
import BaseRoute from './_BaseRoute';
import ShopDetail from '../App/modules/Vendor/components/ShopDetail/ShopDetail';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';

class ShopDetailRoute extends BaseRoute {
	public render() {
		const { shop } = this.props;

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4403494]}
					mobileIds={[4403482, 4403481]}
					superbannerId={4403495}
					sitebarId={4403493}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>

						<ShopDetailContainer
							queryKey={`shopDetail-${shop}`}
							shopSlug={shop + ''}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403493}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

const ShopWithRoute =
	withRouteMetadata(
		ShopDetailRoute,
		(props: any) => `s-${props.shop}`,
		{
			metaTitle: (routeMetadata: IRouteMetadataType, currentProps: any) => {
				return withTitlePostfix(`Öffnungszeiten ${(currentProps.data) ? currentProps.data.title + ' ' + currentProps.data.address + ', ' + currentProps.data.zipCode + ' ' + currentProps.data.city : ''}`)
			},
			metaDescription: (routeMetadata: IRouteMetadataType, currentProps: any) =>
				withClickbait(`${(currentProps.data && currentProps.data.title) || ''} » Öffnungszeiten, Adresse & Kontaktdaten ✓ aktuelle Angebote & Aktionen`, `✓ Hier erfahren Sie mehr!`),
		} as IRouteMetadataSetterType,
	);

class ShopDetailRouteOuter extends BaseRoute {
	public static async getInitialProps(state: any) {
		const reduxData = await ShopDetailRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};
		const queryKey = `shop-detail-${query.shop}-${DataStateService.getStateKey()}`;

		const RenderComponent = withData(
			ShopWithRoute,
			'shopReducer',
			queryKey,
			{
				shop: query.shop,
			},
			{
				isSingleEntry: true,
				primaryKeyName: 'shop',

				LoadingComponent: () => (
					<div>
						<Header />
						<MainNavigation />

						<ShopDetail
							queryKey={queryKey}
							shopSlug={query.shop}
							shop={null}
						/>

						<Footer />
					</div>
				),
			},
		);

		return (
			<RenderComponent {...query} />
		);
	}
}

function mapStateToProps(state: any) {
	return {
		geolocationReducer: state.geolocationReducer,
	}
}

export default withRouter(connect(mapStateToProps)(ShopDetailRouteOuter));
