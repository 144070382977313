import { IRequestMiddlewareObject } from '../types/IRequestMiddlewareObject';

export default class BaseMutator {

	public static setRequest (requestMiddlewareObject: IRequestMiddlewareObject): IRequestMiddlewareObject {
		return requestMiddlewareObject;
	}

	public static beforeFetch (requestMiddlewareObject: IRequestMiddlewareObject): IRequestMiddlewareObject {
		return requestMiddlewareObject;
	}

	public static onSuccess (requestMiddlewareObject: IRequestMiddlewareObject): IRequestMiddlewareObject {
		return requestMiddlewareObject;
	}

	public static setResponse (requestMiddlewareObject: IRequestMiddlewareObject): IRequestMiddlewareObject {
		return requestMiddlewareObject;
	}

	public static onError (requestMiddlewareObject: IRequestMiddlewareObject): IRequestMiddlewareObject {
		return requestMiddlewareObject;
	}

	public static afterFetch (requestMiddlewareObject: IRequestMiddlewareObject): IRequestMiddlewareObject {
		return requestMiddlewareObject;
	}

}
