import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addVendorEntries, addVendorEntry,
	setVendorErrors,
	VENDOR_FETCH_ENTRIES, VENDOR_FETCH_ENTRY,
} from './vendorActions';

/**
 * Fetch entry list
 */
function* fetchVendors(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = afCoreRequest.mergeParams({
		page: 0,
		size: 10,
	}, action.params);

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: '/api/public/v1/vendors/',
					params: action.params,
				} as IRequestObject);
			},
		);

		yield put(
			addVendorEntries(
				action.queryKey,
				response.getContent('content'),
				response.getMetaData(),
				action.options,
			),
		);
	} catch (e) {
		yield put(setVendorErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry details
 */
function* fetchVendor(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	let url = `/api/public/v1/vendors/${action.params.vendor}`;

	if (action.params.checkClient) {
		url = `/api/public/v1/vendors/${action.params.vendor}/settings`;
	}
	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url,
				} as IRequestObject
			);
		});

		yield put(addVendorEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setVendorErrors(action.queryKey, e));
	}
}

export function* vendorSaga() {
	yield takeEvery(VENDOR_FETCH_ENTRY, fetchVendor);
	yield takeEvery(VENDOR_FETCH_ENTRIES, fetchVendors);
}
