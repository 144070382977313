import { IApiErrorType } from 'App/types/BaseTypes';
import AfCoreRequest from '../../../bootstrap/redux-data/AfCoreRequest';
// import BookmarkService from '../../Bookmark/services/BookmarkService';
import serverErrorMap from '../components/AccountInfo/serverErrorMap';
import { isLoop } from 'tslint';
// import ReduxService from '../../Base/services/ReduxService';
// import { fetchAccount } from '../store/Account/accountActions';

const AccountService = {
	/**
	 * The current login state
	 */
	isLoggedIn: false,

	/**
	 * Has the user already been fetched or tried to
	 */
	fetchFinished: false,

	/**
	 * Resets this service for ssr and later use
	 */
	reset() {
		this.isLoggedIn =false;
		this.fetchFinished =false;
	},

	/**
	 * Initiates the account fetching part
	 */
	initAccount() {
		// ReduxService.dispatch(fetchAccount('account-data'));
		//
		// // // init bookmarks
		// BookmarkService.initBookmarks();
	},

	/**
	 * Sets the login state
	 * @param loggedIn
	 */
	setLoggedInState(loggedIn: boolean) {
		this.fetchFinished = true;
		this.isLoggedIn = loggedIn;
	},

	/**
	 * Returns the current log in status
	 */
	check() : boolean {
		return this.isLoggedIn;
	},

	/**
	 * Allows the calling of an event
	 *
	 * @param data
	 * @param callbk
	 * @param errorCallbk
	 */
	changeAccountData (data: {}, callbk:(response: IAccountLoginType) => void, errorCallbk: (error: string) => void) {

		const afBeCoreRequest = new AfCoreRequest('account');

		afBeCoreRequest.putRequest({
			url: '/api/v1/profile',
			body: data,
		} as IRequestObject).then((res: any) => {
			const response = res.getContent();

			if (callbk) {
				callbk(response);
			}
		}).catch((error: IApiErrorType) => {

			const errStatus = (error[0] && error[0].status) ? error[0].status : (error.status) ? error.status : '';
			const err = (serverErrorMap[errStatus]) ? serverErrorMap[errStatus] : 'Nicht erfolgreich';

			if (errorCallbk) {
				errorCallbk(err);
			}
		});
	},


	changePassword (data: {},  callbk:(response: IAccountLoginType) => void, errorCallbk: (error: string) => void) {

		const afBeCoreRequest = new AfCoreRequest('changePassword');

		afBeCoreRequest.patchRequest({
			url: '/api/v1/profile/set-password',
			body: data,
		} as IRequestObject).then((res: any) => {
			const response = res.getContent();

			if (callbk) {
				callbk(response);
			}
		}).catch((error: IApiErrorType) => {

			const errStatus = (error[0] && error[0].status) ? error[0].status : (error.status) ? error.status : '';
			const err = (serverErrorMap[errStatus]) ? serverErrorMap[errStatus] : 'Nicht erfolgreich';

			if (errorCallbk) {
				errorCallbk(err);
			}
		});
	},

	deleteAccount (data: {}, callbk:(status: boolean) => void, errorCallbk: (error: string) => void) {

		const afBeCoreRequest = new AfCoreRequest('deleteAccount');

		afBeCoreRequest.deleteRequest({
			url: '/api/v1/profile',
			body: data,
		} as IRequestObject).then((res: any) => {
			if (callbk) {
				callbk(true);
			}

		}).catch((error: IApiErrorType) => {

			const errStatus = (error[0] && error[0].status) ? error[0].status : (error.status) ? error.status : '';
			const err = (serverErrorMap[errStatus]) ? serverErrorMap[errStatus] : 'Nicht erfolgreich';

			if (errorCallbk) {
				errorCallbk(err);
			}
		});
	},
};

export default AccountService;
