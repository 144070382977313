import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import FlexItem from '../../../../components/Layout/Flexbox/FlexItem';
import H1 from '../../../../components/Layout/H1/H1';
import Intro from '../../../../components/Layout/PageWrapper/Intro';
import { SlugType } from '../../../Base/types/SlugType';
import BookmarkIconContainer from '../../../Bookmark/containers/BookmarkIcon/BookmarkIconContainer';
import NearestShopContainer from '../../containers/NearestShop/NearestShopContainer';
import { IVendorType } from '../../types/IVendorType';
import * as styles from './VendorDetailHeader.scss';


interface IVendorDetailHeaderProps {
	vendor: IVendorType;
	title?: string;
	vendorSlug?: SlugType;
}

class VendorDetailHeader extends React.Component<IVendorDetailHeaderProps, {}> {
	public static defaultProps = {};

	public render() {
		const { vendor, vendorSlug, title } = this.props;

		if (!vendor) {
			return null;
		}
		
		const vendorName = vendor.name;

		return (
			<div className={styles.vendorHeaderContainer}>
							
				<div className={styles.vendorHeader}>
					<FlexItem grow={1}>
						{title ? <H1>{title}</H1>
							   :
							<H1>{vendorName}  <span className={styles.subHeading}>Märkte &#x26; Öffnungszeiten</span></H1>
						}

						<div className={styles.vendorLocation}>
							<span className={styles.bookmarkVendor}>
								<BookmarkIconContainer
									entity="vendor"
									slug={vendorSlug}
									label="Händler auf Merkliste"
									className={styles.bookmarkTitle}
								/>
							</span>

							<span className={styles.nearestLocation}>
								<NearestShopContainer 
									vendorSlug={vendorSlug} 
									queryKey={`nearest-shop-${vendorSlug}`} 
								/>
							</span>
						</div>
					</FlexItem>

					<div className={styles.vendorDetailLogoContainer}>
						<div className={styles.vendorDetailLogo}>
							<figure>
								{!vendor || !vendor.resolutions ? null : (
									<ImageEnhanced source={vendor.resolutions} alt={`${vendorName} Logo`} />
								)}
							</figure>
						</div>
					</div>
				</div>

				{!vendor || !vendor.description ? (
					<div className={styles.vendorNoDescriptionSpacer} />
				) : (
					<Intro>{vendor.description}</Intro>
				)}
			</div>
		);
	}
}

export default VendorDetailHeader;
