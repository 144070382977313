import * as React from 'react';
import { ReactNode } from 'react';
import { IWidgetStyleType } from '../../../types/IWidgetStyleType';
import * as styles from './WidgetBox.scss';

interface IWidgetBoxProps {
	children: ReactNode,
	title?: string,
	style?: IWidgetStyleType,
	noBackground?: boolean,
}

class WidgetBox extends React.Component<IWidgetBoxProps, {}> {
	public render() {
		const { title, children, style, noBackground } = this.props;

		let styleClass = (styles[`widgetBoxStyle-${style}`]) ? styles[`widgetBoxStyle-${style}`] : '';

		if (noBackground) {
			styleClass += ` ${styles.noBackground}`;
		}

		return (
			<div className={`${styles.widgetBox} widgetBox ${styleClass}`}>
				{(!title) ? null :
					<div className={styles.widgetBoxTitle}>
						<div className={styles.widgetTitle}>{title}</div>
					</div>
				}

				<div className={styles.widgetBoxContainer}>
					{children}
				</div>
			</div>
		);
	}
}

export default WidgetBox;
