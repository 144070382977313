import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Icon from '../../../Base/components/Icon/Icon';
import { ILeafletType } from '../../types/ILeafletType';
import * as styles from './LeafletFooter.scss';
import LeafletMenu from './LeafletMenu';
import { ILeafletPageType } from '../../types/ILeafletPageType';
import ShopListWidgetContainer from '../../../Vendor/containers/ShopListWidgetContainer/ShopListWidgetContainer';
import ProductPromotionGridContainer from '../../../Promotion/containers/ProductPromotionGrid/ProductPromotionGridContainer';
import { ILeafletViewerStoreType } from '../../store/LeafletViewer/leafletViewerReducer';
import { LeafletViewerType } from '../../hocs/withLeaflet';
import LeafletInfopostFooter from './LeafletInfopostFooter';

interface ILeafletFooterPropsType {
    leaflet: ILeafletType;
    pages: ILeafletPageType[];
    showPagesMenu: boolean;
    showShopsmenu: boolean;
    showPromotionsMenu: boolean;
    showBookmarkMenu: boolean;
    leafletViewerUiState: ILeafletViewerStoreType;
    leafletViewerType: LeafletViewerType;
}

interface ILeafletFooterStatesType {
    showMenu: string;
}

class LeafletFooter extends React.Component<ILeafletFooterPropsType, ILeafletFooterStatesType> {
    constructor(props: ILeafletFooterPropsType) {
        super(props);
        this.state = {
            showMenu: '',
        };
    }

    public render() {
        const { leaflet, pages, showPagesMenu, showShopsmenu, showPromotionsMenu, showBookmarkMenu, leafletViewerType } = this.props;

        const { showMenu } = this.state;

        // If the page has is an Infopost
        if (leafletViewerType === LeafletViewerType.INFOPOST) {
            return (
                <LeafletInfopostFooter
                    pageCounter={this.getPageCounter()}
                    {...this.props}
                />
            );
        }

        return (
            <React.Fragment>
                <div className={`${styles.leafletFooterContainer} ${showMenu ? styles.leafletFooterExpanded : ''}`}>
                    <div className={styles.leafletFooterWrapper}>
                        {!showPagesMenu ? null : (
                            <div className={styles.leafletFooterPagesWrapper} onClick={() => this.toggleMenu('pages')}>
                                <Icon icon="grid" className={`${styles.leafletFooterIcon} ${showMenu === 'pages' ? styles.active : ''}`} title="Seitenübersicht" />
                                <span className={styles.leafletFooterPagesCount}>{this.getPageCounter()}</span>
                            </div>
                        )}
                        <ShopListWidgetContainer widgetKey={`leaflet-page-${leaflet.slug}-shops-nearby`} orderBy="distance" orderDir="desc" limit={10} widgetType="leaflet" leafletSlug={leaflet.slug}>
                            {!showShopsmenu ? undefined : <Icon icon="vendor" className={`${styles.leafletFooterIcon} ${showMenu === 'shops' ? styles.active : ''}`} onClick={() => this.toggleMenu('shops')} title="Märkte" />}
                        </ShopListWidgetContainer>

                        <ProductPromotionGridContainer limit={40} gridKey={`leaflet-viewer-promotions-${leaflet.slug}`} leafletSlug={leaflet.slug} onlyNearbyPromotions={false} hideLoadingComponent={true}>
                            {!showPromotionsMenu ? null : <Icon icon="promotions" className={`${styles.leafletFooterIcon} ${showMenu === 'promotions' ? styles.active : ''}`} onClick={() => this.toggleMenu('promotions')} title="Aktionen" />}
                        </ProductPromotionGridContainer>
          

                        {!showBookmarkMenu ? null : <Icon icon="shoppinglist-add" className={`${styles.leafletFooterIcon} ${showMenu === 'bookmarks' ? styles.active : ''}`} onClick={() => this.toggleMenu('bookmarks')} />}
                    </div>
                </div>

                <LeafletMenu openMenuItem={showMenu} onCloseMenu={this.toggleMenu} pages={pages} leaflet={leaflet} />
            </React.Fragment>
        );
    }

    /**
     * Toggles the input footer visibility
     */
    private toggleMenu = (name: string) => {
        let action = name;

        if (name === this.state.showMenu) {
            action = '';
        }

        this.setState({
            showMenu: action,
        });
    };

    /**
     * Shows a page counter for desktop
     */
    private getPageCounter = (): string => {
        const {
            leafletViewerUiState: { currentSlide, slideStates },
            pages,
        } = this.props;
        const slideState = slideStates[currentSlide];

        const pagesCount = pages.length;

        // not all infos, we need are here -> show nothing
        if (!pagesCount || !slideState || !slideState.pagesOnSlide) {
            return '';
        }

        // convert to real page numbers + filter the page numbers to display if we have placeholder pages in there
        const pagesToDisplay = slideState.pagesOnSlide
            .filter((pageNumber) => isNaN(parseInt(pageNumber, 10)) === false)
            .map((pageNumber) => Number(pageNumber) + 1)
            .filter((pageNumber: number) => {
                // filter all pages out, that exceed our official page numbers (for example "next leaflets page")
                if (pageNumber > pagesCount) {
                    return false;
                }

                return true;
            });

        // if no page to display left -> return empty string
        if (pagesToDisplay.length < 1) {
            return '';
        }

        // if we got more than 1 page visible right now -> show range
        if (pagesToDisplay.length > 1) {
            return `Seiten ${pagesToDisplay[0]} - ${pagesToDisplay[pagesToDisplay.length - 1]} / ${pagesCount}`;
        }

        // otherwise just show the page number
        return `Seite ${pagesToDisplay[0]} / ${pagesCount}`;
    };
}

function mapStateToProps(state: any) {
    return {
        leafletViewerUiState: state.leafletViewer,
    };
}
const withConnect = connect(mapStateToProps, {});

export default compose(withConnect)(LeafletFooter);
