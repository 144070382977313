import * as React from 'react';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import InputField from '../../../../components/Layout/Form/InputField';
import Button from '../../../../components/General/Button/Button';
import Link from '../../../Base/components/Link/Link';
import RegistrationService from '../../services/RegistrationService';
import * as styles from './Registration.scss';
import Checkbox from '../../../../components/Layout/Checkbox/Checkbox';
import AlertService from '../../../Base/services/AlertService';
import Recaptcha from '../../../../components/General/Recaptcha/Recaptcha';
import NewsletterService from '../../../Newsletter/services/NewsletterService';

interface IRegistrationProps {
	firstName: string,
	lastName: string,
	email: string,
	password: string,
	terms: boolean,
	loading: boolean,
	success: boolean,
	securityToken: string,
}

class Registration extends React.Component<{}, IRegistrationProps> {

	constructor(props: IRegistrationProps) {
		super(props);

		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			terms: false,
			loading: false,
			success: false,
			securityToken: '',
		};
	}

	private onSubmit = () => {
		const { firstName, lastName, email, password, securityToken } = this.state;

		if (!this.checkFields()) {
			return;
		}

		this.setState({ loading: true });

		const data = { firstName, lastName, email, password, securityToken };

		RegistrationService.sendRegistrationData(data as NewAccountData, (response: any) => {

			this.setState({
				securityToken: '',
				loading: false,
				success: true,
			});
		}, (error: any) => {
			this.setState({ loading: false, securityToken: '' });

			AlertService.error(
				'error-' + error,
				error,
			);
		});
	};

	private validateRecaptcha = (securityToken: string) => {
		this.setState({
			securityToken,
		})
	};

	public render() {

		const { loading, success, firstName, lastName, email, password, terms, securityToken } = this.state;

		if (success) {
			return (
				<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
					<H1 align="center" className={styles.registrationTitle}>
						Vielen Dank für deine Anmeldung
					</H1>

					<FormWrapper>

						<p>
							Du erhältst in Kürze eine E-Mail von uns in der du einen Bestätigungslink für die Aktivierung deines Benutzerkontos findest.<br /><br />
							Bitte aktiviere dein Benutzerkonto innerhalb von 24 Stunden, da es ansonsten automatisch wieder gelöscht wird.
						</p>

					</FormWrapper>
				</PageWrapper>
			);
		}

		return (
			<Recaptcha onVerify={this.validateRecaptcha} enable={false} securityToken={securityToken}>
				<PageWrapper hideLocation={true} breadcrumbs={this.getBreadcrumbs()} fluid={true}>
					<H1 align="center" className={styles.registrationTitle}>
						Kostenlos registrieren!
					</H1>

					<div className={styles.registrationContainer}>
						<FormWrapper
							onSubmit={this.onSubmit}
						>

							<InputField
								title="Vorname"
								name="firstName"
								placeholder="Erforderlich"
								onChange={this.onChangeInput}
								value={firstName}
								required={true}
							/>

							<InputField
								title="Nachname"
								name="lastName"
								onChange={this.onChangeInput}
								value={lastName}
							/>

							<div className={styles.formDivider}>
								Anmeldedaten
							</div>

							<InputField
								title="E-Mail"
								name="email"
								placeholder="email@example.com"
								type="email"
								onChange={this.onChangeInput}
								value={email}
								required={true}
							/>

							<div style={{ marginBottom: '20px' }}>
								<InputField
									title="Passwort"
									name="password"
									placeholder="Erforderlich"
									type="password"
									onChange={this.onChangeInput}
									value={password}
									required={true}
								/>
							</div>

							<Checkbox
								name="terms"
								onChange={this.onChangeInput}
								checked={terms}
							>
								<React.Fragment>
									<span>Ja, ich habe die </span>
									<Link route="PrivacyInfoRoute">
										<a target="_blank">Rechtlichen Hinweise</a>
									</Link>
									<span> und </span>
									<Link route="TermsAndConditionsRoute">
										<a target="_blank">Nutzungsbedingungen</a>
									</Link>
									<span> gelesen und akzeptiert</span>
								</React.Fragment>
							</Checkbox>

							<p>&nbsp;</p>

							<Button
	        theme="primary"
								size="xxl"
								fullWidth={true}
								type="submit"
								loading={loading}
							>
								Registrieren
							</Button>

							<Button
	        theme="default"
								size="xxl"
								fullWidth={true}
								loading={loading}
								route="LoginRoute"
							>
								Anmelden
							</Button>

						</FormWrapper>
					</div>
				</PageWrapper>
			</Recaptcha>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Registrieren',
			} as IBreadcrumbItemType,
		];
	}

	private onChangeInput = (name: string, value: string | boolean): void => {
		this.setState({
			[name]: value,
		});
	};

	/**
	 * Validate fields and checks terms checkbox.
	 */
	private checkFields(): boolean {

		const { email, password, terms } = this.state;

		const errors = [];

		if (email && email !== '' && !/\S+@\S+\.\S+/.test(email)) {
			errors.push('Es muss eine gültige E-mail Adresse eingegeben werden.');
		}

		if (password !== '' && password.length < 8) {
			errors.push('Das Passwort muss aus mindestens 8 Zeichen bestehen.');
		}

		if (!terms) {
			errors.push('Bitte akzeptiere unsere rechtlichen Hinweise und Nutzungsbedingungen.');
		}

		if (errors.length > 0) {
			for (let i = 0; i < errors.length; i++) {
				AlertService.error(
					'error-' + errors[i],
					errors[i],
				);
			}
			return false;
		}

		return true
	}
}

export default Registration;
