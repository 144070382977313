import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import { IShopGroupedByStateType } from '../../types/IShopGroupedByStateType';
import * as shopGroupedByStateActions from './shopGroupedByStateActions';

export type ShopGroupedByStateAction = ActionType<typeof shopGroupedByStateActions>;

export interface IShopGroupedByStateStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IShopGroupedByStateType };
	readonly queries: { [queryKey: string]: IShopGroupedByStateType[] };
}

export function shopGroupedByStateReducer(
	state = baseInitialState as IShopGroupedByStateStateType,
	action: ShopGroupedByStateAction,
) {
	const Reducer = new BaseReducer('shopGroupedByStateReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}
