/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';

export const PROMOTIONS_AGGREGATED_BY_VENDORS_WIDGET_FETCH_ENTRIES = '@BasePipeline/promotionsAggregatedByVendorsWidgetReducer_FETCH_ENTRIES';
export const PROMOTIONS_AGGREGATED_BY_VENDORS_WIDGET_ADD_ENTRIES = '@BasePipeline/promotionsAggregatedByVendorsWidgetReducer_ADD_ENTRIES';

export const PROMOTIONS_AGGREGATED_BY_VENDORS_WIDGET_SET_ERRORS   = '@BasePipeline/promotionsAggregatedByVendorsWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchPromotionsAggregatedByVendorsWidgets (queryKey: string) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_VENDORS_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addPromotionsAggregatedByVendorsWidgetEntries (queryKey: string, entries: IPromotionsAggregatedByVendorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {

	return {
		type: PROMOTIONS_AGGREGATED_BY_VENDORS_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function setPromotionsAggregatedByVendorsWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: PROMOTIONS_AGGREGATED_BY_VENDORS_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
