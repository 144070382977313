import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import CurrentLeafletWidgetContainer
	from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';
import TagCloudWidgetContainer from '../../../Tag/containers/TagCloudWidget/TagCloudWidgetContainer';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import ProductPromotionListWidgetContainer from '../../containers/ProductPromotionListWidget/ProductPromotionListWidgetContainer';
import PromotionsAggregatedByVendorsWidgetContainer from '../../containers/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidgetContainer';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';


interface IPromotionsInProductCategoryAndIndustryProps {
	industrySlug: SlugType,
	productGroupSlug: SlugType,
}

class PromotionsInProductCategoryAndIndustry extends React.Component<IPromotionsInProductCategoryAndIndustryProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, industrySlug, productGroupSlug } = this.props;

		const industryName = (routeMetadata && routeMetadata.industry && routeMetadata.industry.title) ? routeMetadata.industry.title : industrySlug;
		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<ProductPromotionGridContainer
								limit={20}
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								industrySlug={industrySlug}
								productGroupSlug={productGroupSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
							>
								<NoDataError />
							</ProductPromotionGridContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							<PromotionsAggregatedByVendorsWidgetContainer
								widgetKey={`ppci-${productGroupSlug}-${industrySlug}-vendor-widget`}
								title={`${productGroupName} Aktionen bei...`}
								limit={5}
								productGroupSlug={productGroupSlug}
								routeCallbk={this.routerCallbkVendorListWidget}
								orderBy="promotionCount"
								orderDir="desc"
							/>
						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, industrySlug, productGroupSlug } = this.props;

		const industryName = (routeMetadata && routeMetadata.industry && routeMetadata.industry.title) ? routeMetadata.industry.title : industrySlug;
		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.productGroup.title : '',
				route: 'ProductGroupDetailRoute',
				routeParams: {productGroup: this.props.productGroupSlug},
			} as IBreadcrumbItemType,
			{
				label: `${productGroupName} Aktionen in ${industryName}`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkVendorListWidget = (item: IPromotionsAggregatedByVendorType) => {
		const { productGroupSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndVendorRoute',
			params: {
				vendor: item.group.slug,
				productGroup: productGroupSlug,
			},
		};
	}
}

export default PromotionsInProductCategoryAndIndustry;
