import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import PromotionsByVendor from '../App/modules/Promotion/components/PromotionsByVendor/PromotionsByVendor';
import {
	currentEntryWord,
	maxDiscountInTitle,
	pluralizerPromotionsAndSpecials,
	withClickbait,
	withTitlePostfix
} from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import withVendor from '../App/modules/Vendor/hocs/withVendor';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';
import AdConfigContainer from '../App/components/General/AdConfig/AdConfigContainer';


class PromotionsByVendorRoute extends BaseRoute {

	public static async getInitialProps(state: any) {
		const reduxData = await PromotionsByVendorRouteProps(state);

		return { reduxData };
	}

	public render() {
		const query = (this.props.match.params) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfigContainer
					slug={query.vendor}
					desktopIds={[4403491]}
					mobileIds={[4403480, 4403479]}
					superbannerId={4403492}
					sitebarId={4403490}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						{withVendor(
							ContainerComponent,
							query.vendor,
							this.props
						)}

						<Footer />
					</div>

					<AdItem
						desktopAdId={4403490}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

function ContainerComponent (props) {

	return (
		<PromotionsByVendor
			vendorSlug={props.match.params.vendor}
			{...props}
		/>
	)
}

export default withRouter(
	withRouteMetadata(
		PromotionsByVendorRoute,
		(props: any) => `pv-${props.match.params.vendor}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) =>
				`${routeMetadata.totalItemsCount || ''} ${currentEntryWord(routeMetadata.totalItemsCount, 'aktuelle', 'aktuelle')} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} bei ${routeMetadata.vendor.title} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>
				withTitlePostfix(`${routeMetadata.totalItemsCount || ''} ${routeMetadata.vendor.title.toUpperCase()} ${pluralizerPromotionsAndSpecials(routeMetadata.totalItemsCount)} ${maxDiscountInTitle(routeMetadata.maxItemDiscount)}`),
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Alle ${routeMetadata.totalItemsCount || ''}${routeMetadata.vendor.title.toUpperCase()} Angebote & Aktionen auf einen Blick » Schnäppchen entdecken ⭐ Preise gegenüberstellen`, `⭐ Hier klicken und sparen! 🤩`),
		} as IRouteMetadataSetterType,
	),
);


