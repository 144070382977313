import geolocationConfig from '../../../../config/geolocationConfig';
import GeolocationService from '../../services/GeolocationService';
import { IGeolocationType } from '../../types/IGeolocationType';
import { GEOLOCATION_SET_DATA } from './geolocationActions';

export interface IGeolocationStateType {
    readonly currentLocation: IGeolocationType;
    readonly isInitiated: boolean;
}

const defaultGeolocationState = {
    currentLocation: geolocationConfig.defaultLocation,
    isInitiated: false,
};

export function geolocationReducer(state = defaultGeolocationState as IGeolocationStateType, action: any) {
    switch (action.type) {
        case GEOLOCATION_SET_DATA:
            const currentLat = state.currentLocation.lat;
            const currentLng = state.currentLocation.lng;
            const nextLat = action.geolocation.lat;
            const nextLng = action.geolocation.lng;

            if (currentLat === nextLat && currentLng === nextLng && state.geolocation === action.geolocation) {
                return state;
            }

            GeolocationService.setCurrentLocation(action.geolocation, action.expiry);
            return Object.assign({}, state, {
                currentLocation: action.geolocation,
                isInitiated: true,
            });

        default:
            return state;
    }
}
