import * as React from 'react';
import { IProductPromotionGroupedType } from '../../types/IProductPromotionGroupedType';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import ProductPromotionItem from '../ProductPromotionItem/ProductPromotionItem';
import * as styles from './ProductPromotionGridGroup.scss';

interface IProductPromotionGridGroupedProps {
  promotions: IProductPromotionType[],
  productGroup: IProductPromotionGroupedType,
  size?: 's' | 'm' | 'l',
  gridKey?: string,
  moreLabel?: string,
  columns?: '1' | '2' | '3',
  useLeafletRoute?: boolean,
}

class ProductPromotionGridGroup extends React.Component<IProductPromotionGridGroupedProps, {}> {
  public static defaultProps = {
    columns: 3,
    gridKey: 'default',
    size: 'm',
  };

  public render () {
    const { productGroup, promotions, size, gridKey, columns, useLeafletRoute} = this.props;

    return (
      <div className={`${styles.productPromotionGroupWrapper} ${styles[ 'productPromotionGroupColumns' + columns ]}`}>
	      <div className={`${styles.productPromotionGroup}`}>
	        {promotions.map((product) => (
	          <div className={styles.productPromotionGroupItem} key={`product-grid-item-${gridKey}-${productGroup.slug}-${product.slug}`}>
	            <div className={styles.productPromotionGroupItemInner}>
	              <ProductPromotionItem
	                promotion={product}
	                size={size}
                  useLeafletRoute={useLeafletRoute}
	              />
	             </div>
	          </div>
	          )
	        )}
        </div>
      </div>
    );
  }
}

export default ProductPromotionGridGroup;
