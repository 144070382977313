import AfCoreRequest from '../../../bootstrap/redux-data/AfCoreRequest';
import { IApiErrorType } from 'App/types/BaseTypes';
import serverErrorMap from '../components/LotteryRegistration/serverErrorMap';


const LotteryService = {

	/**
	 * Allows the calling of an event
	 *
	 * @param event
	 * @param eventProps
	 */
	sendLotteryRegistrationData (
		data: NewAccountData, 
		callbk:(response: IAccountLoginType) => void, 
		errorCallbk: (error: string) => void) {

		const afCoreRequest = new AfCoreRequest('lotteryRegistrationPage');
		
		afCoreRequest.postRequest({
			url: '/api/public/v1/lottery/subscribe',
			body: data,
		} as IRequestObject).then((res: any) => {

			const response = res.getContent();

			if (callbk) {
				callbk(response);
			}

		}).catch((error: IApiErrorType) => {

			const errStatus = (error[0] && error[0].status) ? error[0].status : (error.status) ? error.status : '';
			const err = (serverErrorMap[errStatus]) ? serverErrorMap[errStatus] : 'Nicht erfolgreich';

			if (errorCallbk) {
				errorCallbk(err);
			}
		});
	},

	sendLotteryPaymentData (
		data: any, 
		callbk:(response: IAccountLoginType) => void, 
		errorCallbk: (error: string) => void) {

		const afCoreRequest = new AfCoreRequest('lotteryPaymentPage');
		
		afCoreRequest.postRequest({
			url: '/api/public/v1/lottery/verify-winner',
			body: data,
		} as IRequestObject).then((res: any) => {

			const response = res.getContent();

			if (callbk) {
				callbk(response);
			}

		}).catch((error: IApiErrorType) => {

			const errStatus = (error[0] && error[0].status) ? error[0].status : (error.status) ? error.status : '';
			const err = (serverErrorMap[errStatus]) ? serverErrorMap[errStatus] : 'Nicht erfolgreich';

			if (errorCallbk) {
				errorCallbk(err);
			}
		});
	}
};

export default LotteryService;
