import * as React from 'react';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import H1 from '../../../../components/Layout/H1/H1';
import FormWrapper from '../../../../components/Layout/Form/FormWrapper';
import Button from '../../../../components/General/Button/Button';
import * as styles from './ConfirmLotteryRegistration.scss';


interface IConfirmLotteryRegistrationProps {

}

class ConfirmLotteryRegistration extends React.Component<{}, IConfirmLotteryRegistrationProps> {

	public render() {

		return (
			<PageWrapper hideLocation={true} fluid={true}>
				<FormWrapper>
					<H1 align="center" title="Anmeldung erfolgreich">
						Deine Anmeldung zu unserem Gewinnspiel Aktionsfinder der Woche war erfolgreich!
					</H1>

					<Button
						theme="primary"
						size="xxl"
						fullWidth={true}
						route="HomeRoute"
					>
						Aktionen ansehen
					</Button>

				</FormWrapper>
			</PageWrapper>
		);
	}
}

export default ConfirmLotteryRegistration;
