import * as React from 'react';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import * as styles from './ProductPromotionGridGrouped.scss';
import ProductPromotionGridGroupSkeleton from './ProductPromotionGridGroupSkeleton';
import SectionTitleSkeleton from '../../../../components/Layout/SectionTitle/SectionTitleSkeleton';

export interface IProductPromotionGridGroupedSkeletonProps {
	size?: 's' | 'm' | 'l',
	gridKey?: string,
	columns?: '1' | '2' | '3',
	limit?: number,
	limitItemsPerGroup?: number,

	moreLabel?: string,

	contentBreaks?: { [ key: number ]: any };
}

class ProductPromotionGridGroupedSkeleton extends React.PureComponent<IProductPromotionGridGroupedSkeletonProps, {}> {
	public static defaultProps = {
		columns: 3,
		gridKey: 'default',
		size: 'm',
		contentBreaks: {},
		limit: 4
	};

	public render () {
		const { size, gridKey, moreLabel, columns, contentBreaks, limit } = this.props;

		return (
			<React.Fragment>
				{Array.apply(null, {length: limit}).map((indx, i) => {
					return (
						<React.Fragment key={`product-grid-group-${gridKey}-${i}`}>

							{( !contentBreaks || !contentBreaks[ indx ] ) ? null :
							 <React.Fragment>
								 {contentBreaks[ indx ]}
							 </React.Fragment>
							}

							<div className={styles.productPromotionGridGroupWrapper}>
								<SectionTitleSkeleton />

								<ProductPromotionGridGroupSkeleton
									gridKey={`product-grid-group-${gridKey}-${indx}`}
									size={size}
									columns={columns}
								/>
							</div>
						</React.Fragment>
					);
				})
				}
			</React.Fragment>
		);
	}
}

export default ProductPromotionGridGroupedSkeleton;
