import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer 	from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IVouchersInProductGroupProps {
    productGroupSlug: SlugType;
}

class VouchersInProductGroup extends React.Component<IVouchersInProductGroupProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render () {
		const { routeMetadata, productGroupSlug} = this.props;

        const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>
				
				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
                            <ProductPromotionGridContainer
                                limit={40}
                                productGroupSlug={productGroupSlug}
                                enablePagination={true}
                                fetchVoucherProductPromotions={true}
                                gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								orderBy="createdAt"
								orderDir="desc"
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
                            >
                                <NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey={`vopc-${productGroupSlug}-product-group-widget`}
								title={`Gutscheine in anderen Produktkategorien`}
								referenceSlug={productGroupSlug}
								exclude={productGroupSlug}
								limit={100}
								placeholderLimit={5}
								promotionLabel="Gutschein"
								promotionLabelPlural="Gutscheine"
								routeCallbk={this.routerCallbkProductGroupListWidget}
								fetchVoucherProductPromotions={true}
								onlyNearby={true}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>
							
						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, productGroupSlug} = this.props;
		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		return [
			{
				label: 'Gutscheine',
				route: 'VoucherListingRoute'
			} as IBreadcrumbItemType,
			{
				label: `${productGroupName} Gutscheine`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {

		return {
			name: 'VouchersInProductGroupRoute',
			params: {
                productGroup: item.group.slug,
			},
		};
	};
}

export default VouchersInProductGroup;
