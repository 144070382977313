import * as React from 'react';
import { isServer } from '../../../../boilerplate/razzle/razzleUtils';
import { Helmet } from 'react-helmet-async';
import * as styles from '../AdItem/AdItem.scss';
import CookieTrustService from '../../../services/CookieTrustService';
import { connect } from 'react-redux';

declare const adition: any;

interface IAdConfigProps {
  desktopIds?: number[];
  mobileIds?: number[];
  superbannerId?: number;
  sitebarId?: number;
  adFarmRoute?: string;
  tcString?: string;
  cookieTrustGroups?: { [key: string]: boolean };
  profile?: { [key: string]: string };
}

interface IAdConfigStates {
  cookiesAccepted: boolean,
}

class AdConfig extends React.Component<IAdConfigProps, IAdConfigStates> {

  public static defaultProps = {
    adFarmRoute: "ad1.adfarm1.adition.com",
  };

  private winWidth?: number;
  private winHeight?: number;

  private loadedTimeout: any;

  constructor(props: IAdConfigProps) {
    super(props);
    this.state = {
      cookiesAccepted: true,
    }
  }

  public componentDidMount() {
    if (isServer()) {
      return;
    }

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    this.setState({
      cookiesAccepted: CookieTrustService.isActive('C0004'),
    });

    // check if cookies are accepted after 3 seconds
    setTimeout(() => {
      console.info('OneTrust set', !!window.OneTrust);
      this.setState({
        cookiesAccepted: CookieTrustService.isActive('C0004'),
      });
    }, 3000);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));

    if (this.loadedTimeout) {
      clearTimeout(this.loadedTimeout);
    }
  }

  public resize() {
    this.winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  public sanitizeProfileProperty(property: string): string {
    return property.replace(/[^a-zA-Z0-9]/g, '_').substring(0, 255);
  }


  public render() {
    const { adFarmRoute, tcString } = this.props;
    const { cookiesAccepted } = this.state;

    if (isServer()) {
      return null;
    }

    // if cookie compliance is false -> don't render anything
    if (!CookieTrustService.isActive('C0004')) {
      return null;
    }

    const configuredSlots = this.configureSlots();

    if (typeof configuredSlots === 'undefined' || configuredSlots.length < 1) {
      return null;
    }

    // if not loaded yet (to make sure we get the tcstring in time)
    if (!tcString) {
      return null;
    }

    return (
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `
						var adition = adition || {};
						adition.srq = adition.srq || [];
						(function() {
							var script = document.createElement("script");
							script.type = "text/javascript";
							script.src = (document.location.protocol === "https:" ? "https:" : "http:") + "//imagesrv.adition.com/js/srp.js";
							script.charset = "utf-8";
							script.async = true;
							var firstScript = document.getElementsByTagName("script")[0];
							firstScript.parentNode.insertBefore(script, firstScript);
						})()
					`
        }, (!configuredSlots) ? {} : {
          type: 'text/javascript',
          innerHTML: `
              adition.srq.push(function(api) {

                // ${(!cookiesAccepted) ? 'api.disableCookies();' : ''}
                // api.setProfile("consent", ${(!cookiesAccepted) ? '"optout"' : '"optin"'});
                api.setProfile('consent', 'optin');
                api.setProfile('US', '${(CookieTrustService.isActive('S0006')) ? 1 : 0}');

                // add gdpr consent
                api.consentService.setGdprConsent('${tcString}')
                                  .setGdpr(1)
                                  .setGdprPd(1);

                // set ad farm
                api.registerAdfarm("${adFarmRoute}");

                ${this.getRenderSlots(configuredSlots)}
                api.events.onPreRender(function(
                  renderSlotElement,
                  bannerDescriptionApi,
                  renderControlApi) {
                    // console.log(renderSlotElement);
                    // console.log(renderControlApi);
                    // console.log(bannerDescriptionApi);
                  }
                );
                  api.load(${this.getRenderSlotIds(configuredSlots)}).completeRendering();
              });
					`
        }]}
      />
    );
  }

  /**
   * Returns a list of
   * @param renderSlotIds
   */
  private getRenderSlots (renderSlotIds: number[]) {
    const { profile } = this.props;
    let retStr = '';

    for (const renderSlot of renderSlotIds) {
      retStr += `
        ${!profile ?
          `api.configureRenderSlot("renderSlot_${renderSlot}").setContentunitId(${renderSlot});` :
          `api.configureRenderSlot("renderSlot_${renderSlot}").setContentunitId(${renderSlot})${Object.keys(profile)
              .map((key) => `.setProfile("${key}", "${this.sanitizeProfileProperty(profile[key])}")`).join('')};`
        }
        api.configureRenderSlot("renderSlot_${renderSlot}").events.onPreRender(
          function(renderSlotElement, bannerDescriptionApi, renderControlApi) {
            var wrapper1;

            wrapper1 = document.createElement("div");
            wrapper1.className = "adItemContent ${styles.adItemContent}";

            renderControlApi.addInnerWrapper(wrapper1);
          }
        );
      `;
    }

    return retStr;
  }

  /**
   * Returns a list of
   * @param renderSlotIds
   */
  private getRenderSlotIds (renderSlotIds: number[]) {
    const retStr = [];

    for (const renderSlot of renderSlotIds) {
      retStr.push(`renderSlot_${renderSlot}`);
    }

    return JSON.stringify(retStr);
  }
  /**
   * Checks whether this ad should be visible or not
   */
  private isAdVisible() {
    if (isServer()) {
      return false;
    }

    if (this.props.type === 'side' && this.winHeight < 663) {
      return false;
    }

    return true;
  }

  /**
   * Adds rendering of ad slots
   */
  private configureSlots = () => {
    const { desktopIds, mobileIds, superbannerId, sitebarId } = this.props;

    if (!this.winWidth ) {
      this.winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }
    if (!this.winHeight ) {
      this.winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    const slots = [];

    if (this.winWidth >= 1200 && sitebarId && this.winHeight >= 663) {
      slots.push(sitebarId);
    }

    if (this.winWidth < 890 && mobileIds) {
      for (let i = 0; i < mobileIds.length; i++) {
        slots.push(mobileIds[ i ]);
      }
    }

    if (this.winWidth >= 890 && desktopIds) {
      for (let i = 0; i < desktopIds.length; i++) {
        slots.push(desktopIds[ i ]);
      }
    }

    if (this.winWidth >= 890 && superbannerId) {
      slots.push(superbannerId);
    }

    return slots;
  };
}


function mapStateToProps(state: any) {
  return {
      tcString: state.generalReducer?.tcString,
  };
}

export default connect(mapStateToProps)(AdConfig);