import * as React from 'react';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import routeMetadataConfig from '../config/routeMetadataConfig';
import { fetchRouteMetadatas } from '../modules/Base/store/RouteMetadata/routeMetadataActions';
import { IRouteMetadataType } from '../modules/Base/types/IRouteMetadataType';
import DataStateService from '../services/DataStateService';
import SimpleEntityDetailService from '../services/SimpleEntityDetailService';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet-async';
// import InternalTrackingService from '../services/InternalTrackingService';


/**
 * Gets either the value from our setter or a default value
 *
 * @param key
 * @param metaDataSetter
 * @param metaData
 * @param currentProps
 */
function getSetterOrDefault(key: string, metaDataSetter: IRouteMetadataSetterType, metaData: IRouteMetadataType, currentProps: any): string {
	if (!metaDataSetter[key]) {
		return routeMetadataConfig[key];
	}

	if (typeof metaDataSetter[key] === 'string') {
		return metaDataSetter[key];
	}

	return metaDataSetter[key](metaData, currentProps);
}

export function getCharacterizedMetaContent(cont: string): string {

	if (!cont) {
		return '';
	}

	return decodeURIComponent(cont
		.replace(/\+/g, ' ')
		.replace(/month/g, DateTime.now().monthLong)
		.replace(/year/g, DateTime.now().year)
		.replace(/star/g, '⭐')
		.replace(/checkmark/g, '✓')
	).replace(/[<\>]/g, '');
}

function getApiMetadata(key: string, metaData: IRouteMetadataType): string {
	const headers = metaData.headers;

	if (!headers || !headers[key]) {
		return '';
	}

	return getCharacterizedMetaContent(headers[key]);
}


function mapDispatchToProps(dispatch: (actionType: any) => void) {
	return {
		fetchRouteMetadatas: (queryKey: string, props: any) => {
			dispatch(fetchRouteMetadatas(queryKey, props));
		},
	};
}

function mapStateToProps(state: any) {
	return {
		appProps: state.appProps,
		routeMetadataReducer: state.routeMetadataReducer,
		geolocationReducer: state.geolocationReducer,
	};
}

type CallbackType = (props: any) => string;

function withRouteMetadata(
	WrappedComponent: React.ComponentType | any,
	queryKey: string | CallbackType,
	metaDataSetter: IRouteMetadataSetterType,
	useApiMetadata: boolean = true, // TODO: Remove later and metaDataSetter prop when Api metadata works perfectly
	pathname: string = '', // an alternative pathname, that will be taken instead of the current one.
) {
	return withRouter(connect(mapStateToProps, mapDispatchToProps)(class extends React.Component<any, any> {
		constructor(props: any) {
			super(props);

			this.state = {
				error: null,
				info: null,
			};

			this.componentWillMountCalls();
		}


		private lastRouteMetadata: any = {};
		private lastMetaDataProps: any = {};
		private lastQueryKey = '';

		public static getInitialProps = WrappedComponent.getInitialProps;

		public componentDidCatch(error, info) {
			this.setState({
				error,
				info,
			});
		}

		public componentWillMountCalls() {
			this.lastRouteMetadata = {};
			this.lastMetaDataProps = {};

			this.fetchRouteMetadata(this.props);
		}

		public componentDidMount(prevProps: any) {
			if (this.lastQueryKey !== this.resolveQueryKey(this.props)) {
				this.fetchRouteMetadata(this.props);
			} else {
				this.lastRouteMetadata = {};
				this.lastMetaDataProps = {};
			}
		}

		public render() {
			const { routeMetadataReducer } = this.props;
			const { error, info } = this.state;
			const resolvedQueryKey = this.resolveQueryKey(this.props);

			const routeMetadata = (routeMetadataReducer) ? this.setMetadataProps(
				metaDataSetter,
				resolvedQueryKey,
				routeMetadataReducer,
				this.props,
				useApiMetadata
			) : {} as IRouteMetadataType;

			if (error || info) {
				return null;
			}

			return (
				<React.Fragment>
					<Helmet encodeSpecialCharacters={false}>
						<title>{routeMetadata.metaTitle}</title>
						<meta name="title" content={routeMetadata.metaTitle} />
						<meta name="description" content={routeMetadata.metaDescription} />
						{(typeof routeMetadata.noIndex != 'undefined') ? <meta name="robots" content={routeMetadata.noIndex ? 'noindex' : 'index'} /> : null}
						<meta name="og:image" content={routeMetadata.ogImage} />
					</Helmet>
					<WrappedComponent {...this.props} routeMetadata={routeMetadata} />
				</React.Fragment>
			);
		}

		/**
		 * Fetches route metadata from our url endpoint for this
		 *
		 * @param props
		 */
		private fetchRouteMetadata(props: any) {
			const lastQueryKeyBackup = this.lastQueryKey + '';
			this.lastQueryKey = this.resolveQueryKey(props);
			if (lastQueryKeyBackup !== this.lastQueryKey && typeof props.routeMetadataReducer[this.lastQueryKey] === 'undefined' && typeof props.routeMetadataReducer[this.alternativeLastQueryKey] === 'undefined') {
				SimpleEntityDetailService.fetchEntities(this.lastQueryKey, { ...props, pathname }, props.fetchRouteMetadatas);
			}
		}

		/**
		 * Resolves our query key when is a callback and makes sure we only get a string as result
		 *
		 * @param props
		 *
		 * @return {string}
		 */
		private resolveQueryKey(props: any, addStateKey = false): string {
			if (typeof queryKey === 'string') {
				return queryKey + ((addStateKey) ? DataStateService.getStateKey() : '');
			}

			return queryKey(props) + ((addStateKey) ? DataStateService.getStateKey() : '');
		}

		/**
		 * Resolves our query key when is a callback and makes sure we only get a string as result
		 *
		 * @param props
		 *
		 * @return {string}
		 */
		private resolveNormalQueryKey(props: any): string {
			if (typeof queryKey === 'string') {
				return queryKey;
			}

			return queryKey(props);
		}


		/**
		 * Sets the meta data props by method or default value
		 *
		 * @param {IRouteMetadataSetterType} metaDataSetter
		 * @param {string} queryKeyString
		 * @param {IRouteMetadataType} metaDataReducer
		 * @param {IRouteMetadataType} metaDataReducer
		 * @param currentProps
		 */
		private setMetadataProps(
			metaDataSetter: IRouteMetadataSetterType,
			queryKeyString: string,
			metaDataReducer: any,
			currentProps: any,
			useApiMetadata: boolean,
		) {
			// const resolvedEntities = SimpleEntityDetailService.resolveEntities(currentProps);
			
			const metaDataProps: any = SimpleEntityDetailService.resolveEntities(currentProps);
			const metaData = (metaDataReducer[queryKeyString]) ? metaDataReducer[queryKeyString] : {};

			// make sure to only reassign everything, if routeMetadata has changed (performance saving -  because of the anonymous functions)
			if (metaData === this.lastRouteMetadata) {
				return this.lastMetaDataProps;
			}

			this.lastRouteMetadata = metaData;

			// ssr headers from addCorrentResponseHeaders preloaded already
			const ssrHeaders = (typeof currentProps['routeMetadataReducer']?.[queryKeyString] != 'undefined') ? currentProps['routeMetadataReducer'][queryKeyString] : null;

			const combinedMetadata = Object.assign({}, metaDataProps, metaData);
			metaDataProps.metaTitle = useApiMetadata && metaData.headers && getApiMetadata('x-route-metatitle', metaData)
				|| getSetterOrDefault('metaTitle', metaDataSetter, combinedMetadata, currentProps);

			metaDataProps.metaDescription = useApiMetadata && metaData.headers && getApiMetadata('x-route-metadescription', metaData)
				|| getSetterOrDefault('metaDescription', metaDataSetter, combinedMetadata, currentProps);

			metaDataProps.pageTitle = getSetterOrDefault('pageTitle', metaDataSetter, combinedMetadata, currentProps);

			// if no metatitle -> just use page title as fallback
			if (!metaDataSetter.metaTitle) {
				metaDataSetter.metaTitle = metaDataSetter.pageTitle;
			}


			metaDataProps.queryKey = queryKeyString;
			metaDataProps.ogImage = getSetterOrDefault('ogImage', metaDataSetter, combinedMetadata, currentProps);

			this.lastMetaDataProps = Object.assign({}, metaDataProps, combinedMetadata, (ssrHeaders ?? {}));

			return this.lastMetaDataProps;
		}
	}));

}

export default withRouteMetadata;
