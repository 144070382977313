import * as React from 'react';
import withData from '../../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../../boilerplate/redux/types/IWithDataProps';
import HomepageGrid from '../../components/HomepageGrid/HomepageGrid';
import HomepageGridSkeleton from '../../components/HomepageGrid/HomepageGridSkeleton';
import DataStateService from '../../../../../services/DataStateService';
import { IHomepageExploreType } from '../../types/IHomepageExploreType';

interface IHomepageContainerProps {
	data: IHomepageExploreType;
	gridKey: string;
	title: string;
	vendorLeafletsSize?: number;
	currentLeafletsSize?: number;
	currentPromotionsSize?: number;
	leafletsByIndustrySize?: number;
	currentThemeWorldsSize?: number;
	currentLotteriesSize?: number;
	priceHitsSize?: number;
	topVouchersSize?: number;
}

class HomepageContainerInner extends React.Component<IHomepageContainerProps & IWithDataProps> {
	public static defaultProps = {
		columns: 5,
		limit: 10,
	};

	public render() {
		
		const { data, title } = this.props;

		return (
			<HomepageGrid
				data={data}
				title={title}
			/>
		);
	}
}


export default function HomepageContainer(theProps: IHomepageContainerProps) {
	const props = Object.assign({
		radius: 50000,
		vendorLeafletsSize: 8,
		currentLeafletsSize: 10,
		currentPromotionsSize: 5,
		leafletsByIndustrySize: 5,
		currentThemeWorldsSize: 3,
		currentLotteriesSize: 6,
		priceHitsSize: 8,
		topVouchersSize: 3,
	}, theProps);
	
	const RenderComponent = withData(
		HomepageContainerInner,
		'homepageReducer',
		`home-${props.gridKey}-${DataStateService.getStateKey()}}`,
		{
			radius: props.radius,
			vendorLeafletsSize: props.vendorLeafletsSize,
			currentLeafletsSize: props.currentLeafletsSize,
			currentPromotionsSize: props.currentPromotionsSize,
			leafletsByIndustrySize: props.leafletsByIndustrySize,
			currentThemeWorldsSize: props.currentThemeWorldsSize,
			currentLotteriesSize: props.currentLotteriesSize,
			priceHitsSize: props.priceHitsSize,
			topVouchersSize: props.topVouchersSize,
		},
		{
			LoadingComponent: () => <HomepageGridSkeleton />
		}
	);

	return <RenderComponent {...props} />;
}


