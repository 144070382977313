import * as React from 'react';
import ProductPromotionItemSkeleton from '../ProductPromotionItem/ProductPromotionItemSkeleton';
import * as styles from './ProductPromotionGridGroup.scss';

interface IProductPromotionGridGroupSkeletonProps {
	size?: 's' | 'm' | 'l',
	gridKey?: string,
	moreLabel?: string,
	columns?: '1' | '2' | '3',
}

class ProductPromotionGridGroupSkeleton extends React.Component<IProductPromotionGridGroupSkeletonProps, {}> {
	public static defaultProps = {
		columns: 3,
		gridKey: 'default',
		size: 'm',
	};

	public render () {
		const { size, gridKey, columns } = this.props;

		return (
			<div
				className={`${styles.productPromotionGroupWrapper} ${styles[ 'productPromotionGroupColumns' + columns ]}`}
			>
				<div className={`${styles.productPromotionGroup}`}>
					{[ 1, 2, 3, 4, 5, 6 ].map((indx) => (
							<div
								className={styles.productPromotionGroupItem}
								key={`product-grid-item-${gridKey}-${indx}`}
							>
								<div className={styles.productPromotionGroupItemInner}>
									<ProductPromotionItemSkeleton
										size={size}
									/>
								</div>
							</div>
						),
					)}
				</div>
			</div>
		);
	}
}

export default ProductPromotionGridGroupSkeleton;
