/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { ILeafletType } from 'App/modules/Leaflet/types/ILeafletType';
import { SearchPageFilterItemMetadataType } from '../../components/SearchPage/FilterInputs/SearchPageFilterGroupCheckboxGroupItem';
import { IMixedEntityType } from '../../../MixedEntity/types/IMixedEntityType';

export const MIXED_SEARCH_FETCH_ENTRIES = '@BasePipeline/mixedSearchReducer_FETCH_ENTRIES';
export const MIXED_SEARCH_FETCH_ENTRY = '@BasePipeline/mixedSearchReducer_FETCH_ENTRY';
export const MIXED_SEARCH_ADD_ENTRIES = '@BasePipeline/mixedSearchReducer_ADD_ENTRIES';
export const MIXED_SEARCH_ADD_ENTRY = '@BasePipeline/mixedSearchReducer_ADD_ENTRY';

export const MIXED_SEARCH_SET_ERRORS = '@BasePipeline/mixedSearchReducer_SET_ERRORS';

export type SearchMetaDataType = {
    searchResult?: {
        content?: IMixedEntityType[];
        first?: boolean;
        last?: boolean;
        number?: number;
        numberOfElements?: number;
        pageable?: any;
        size?: number;
        sort?: {
            unsorted?: boolean;
            sorted?: boolean;
        };
        totalElements?: number;
        totalPages?: number;
    };
    optionBrand?: SearchPageFilterItemMetadataType[];
    optionCity?: SearchPageFilterItemMetadataType[];
    optionIndustry?: SearchPageFilterItemMetadataType[];
    optionProductGroup?: SearchPageFilterItemMetadataType[];
    optionState?: SearchPageFilterItemMetadataType[];
    optionType?: SearchPageFilterItemMetadataType[];
    optionUnit?: SearchPageFilterItemMetadataType[];
    optionVendor?: SearchPageFilterItemMetadataType[];
};

/* ### ACTIONS ### */

export function fetchmixedSearchs(queryKey: string) {
    return {
        type: MIXED_SEARCH_FETCH_ENTRIES,
        queryKey,
    };
}

export function addmixedSearchEntries(queryKey: string, searchTerm: string, entries: ILeafletType[], metaData?: IQueryMetaDataType, searchMetaData?: SearchMetaDataType, options?: IFetchOptionType) {
    return {
        type: MIXED_SEARCH_ADD_ENTRIES,
        queryKey,
        searchMetaData,
        entries,
        metaData,
        searchTerm,
        options,
    };
}

export function setmixedSearchErrors(queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType, options?: IFetchOptionType) {
    return {
        type: MIXED_SEARCH_SET_ERRORS,
        queryKey,
        errors,
        metaData,
    };
}
