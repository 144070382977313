import * as React from 'react';
import { ReactNode } from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataOptions } from '../../../../../boilerplate/redux/types/IWithDataOptions';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { SlugType } from '../../../Base/types/SlugType';
import ProductPromotionGrid from '../../components/ProductPromotionGrid/ProductPromotionGrid';
import ProductPromotionGridSkeleton from '../../components/ProductPromotionGrid/ProductPromotionGridSkeleton';
import { IProductPromotionType } from '../../types/IProductPromotionType';
import { getPromotionTypeApiFilterString } from '../../utils/fetchTypeHelper';
import ProductPromotionInLeafletGrid from '../../components/ProductPromotionInLeafletGrid/ProductPromotionInLeafletGrid';
import ProductPromotionInLeafletGridSkeleton from '../../components/ProductPromotionInLeafletGrid/ProductPromotionInLeafletGridSkeleton';
import DataStateService from '../../../../services/DataStateService';

interface IProductPromotionGridContainerProps {
	gridKey?: string;
	size?: 's' | 'm' | 'l';
	title?: string;
	subtitle?: string;
	description?: string;
	titleLevel?: number; // the level of h${x} for the html title tag
	columns?: '1' | '2' | '3' | '4' | '5';
	boxed?: boolean;

	moreLabel?: string;
	moreHref?: string;
	moreRoute?: string;
	moreRouteParams?: { [key: string]: any };

	fetchPriceProductPromotion?: boolean;
	fetchAssortementProductPromotions?: boolean;
	fetchProductPromotions?: boolean;
	fetchVoucherProductPromotions?: boolean;

	limit?: number;
	page?: number;
	orderBy?: 'relevance' | 'createdAt' | 'published';
	orderDir?: 'asc' | 'desc';

	placeholderLimit?: number;

	enablePagination?: boolean; // whether this component should be paginated

	onlyNearbyPromotions?: boolean;

	pushRouteMetadata?: boolean;

	hideLoadingComponent?: boolean; // if this is true -> no loading component will be shown (used in cases, where we don't want a temporary grid)

	radius?: number,

	brandSlug?: SlugType;
	vendorSlug?: SlugType;
	shopSlug?: SlugType;
	industrySlug?: SlugType;
	productGroupSlug?: SlugType;
	citySlug?: SlugType;
	themeworldSlug?: SlugType;
	stateSlug?: SlugType;
	shoppingCenterSlug?: SlugType;
	directProductGroupSlug?: SlugType;
	tagSlug?: SlugType;
	storySlug?: SlugType; // TODO : Check if the prop is used and needed
	leafletSlug?: SlugType;

	excludeSlug?: SlugType; // the promotion we want to exclude
	referenceSlug?: SlugType; // the promotion we want to find similar entries for

	contentBreaks?: { [key: number]: any }; // allows us to just add content at certain positions of the listing

	children?: ReactNode; // if this is given, this will be shown if the array doesn't return any results
	useLeafletRoute?: boolean;
	directClickout?: boolean;  // if we have a leaflet (=we are in a Promotionworld, not a normal themeworld => open clickouts directly)
	onPromotionClick?: (promotion: IProductPromotionType) => void;
}

class ProductPromotionGridContainerInner extends React.Component<
	IProductPromotionGridContainerProps & IWithDataProps
	> {
	public static defaultProps = {
		columns: 3,
		limit: 10,
		orderBy: 'relevance',
		orderDir: 'desc',
		size: 'm',
		titleLevel: 1,
		pushRouteMetadata: true,
		useLeafletRoute: true,
	};

	public render() {
		const { data, excludeSlug, children, ...restProps } = this.props;

		const promotions = excludeSlug
			? this.getEntriesWithExcluded(data)
			: data;

		if (this.props.leafletSlug) {
			
			if (promotions.length > 0 && children) {
				return children;
			} else if (promotions.length === 0) {
				return null;
			}

			return (
				<ProductPromotionInLeafletGrid
					products={promotions}
				/>
			)
		}

		if (!promotions || promotions.length < 1 && children) {
			return (
				<React.Fragment>{children}</React.Fragment>
			);
		}

		return (
			<ProductPromotionGrid
				products={promotions}
				{...restProps}
			>
				{children}
			</ProductPromotionGrid>
		);
	}

	/**
	 * Cleans up our entries and makes sure the excluded entry is not in there
	 *
	 * @param {IProductPromotionType} entries
	 *
	 * @return {IProductPromotionType}
	 */
	private getEntriesWithExcluded(
		entries: IProductPromotionType[],
	): IProductPromotionType[] {
		const { limit, excludeSlug } = this.props;

		let excluded = false;
		// now try to filter it out
		return entries.filter((entry: IProductPromotionType, indx: number) => {
			if (entry.slug === excludeSlug) {
				excluded = true
				return false;
			}

			// if we are over our limit -> also return false (this might be because, we don't have the excludee in our data set,
			// but to make sure we have the correct amount of data even after removing the excludee, we have added +1 to limit on fetch
			if (limit && indx >= limit && !excluded) {
				return false;
			}

			// otherwise return true and add it to our results
			return true;
		});
	}
}

export default function ProductPromotionGridContainer(
	theProps: IProductPromotionGridContainerProps,
) {
	const props = Object.assign(
		{
			limit: 12,
			orderBy: 'relevance',
			orderDir: 'desc',
			size: 'm',
			radius: 50000,
			onlyNearbyPromotions: true,
		},
		theProps,
	);

	const promotionTypes = getPromotionTypeApiFilterString(theProps);

	const RenderComponent = withData(
		ProductPromotionGridContainerInner,
		'productPromotionReducer',
		`${props.gridKey}-${DataStateService.getStateKey()}`,
		{
			// if we exclude something, we want to fetch +1 more than we actually need to make sure we still have the correct
			// number of results, even after excluding the one
			size: props.excludeSlug ? props.limit + 1 : props.limit,
			page: props.page,

			radius: props.radius,
			orderBy: props.orderBy,
			orderDir: props.orderDir,

			promotion_type: promotionTypes,

			onlyNearbyPromotions: props.onlyNearbyPromotions,
			pushRouteMetadata: props.pushRouteMetadata,

			store_vendor: props.vendorSlug,
			store: props.shopSlug,
			brand: props.brandSlug,
			industry: props.industrySlug,
			reference: props.referenceSlug,
			product_group: props.productGroupSlug,
			direct_product_group: props.directProductGroupSlug,
			city: props.citySlug,
			themeworld: props.themeworldSlug,
			state: props.stateSlug,
			shopping_center: props.shoppingCenterSlug,
			tag_slug: props.tagSlug,
			story: props.storySlug,
			leaflet: props.leafletSlug,
		},
		{
			LoadingComponent: () => {
				if (props.hideLoadingComponent) {
					return null;
				}

				if (props.leafletSlug) {
					return <ProductPromotionInLeafletGridSkeleton />
				}

				return (
					<ProductPromotionGridSkeleton limit={10}/>
				)
			},
		} as IWithDataOptions,
	);

	return <RenderComponent {...props} />;
}
