import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import BrandDetailPage from '../../components/BrandDetailPage/BrandDetailPage';
import { IBrandType } from '../../types/IBrandType';
import BrandDetailPageSkeleton from '../../components/BrandDetailPage/BrandDetailPageSkeleton';

interface IBrandDetailPageProps {
	brandSlug: string;
}

class BrandDetailPageContainerInner extends React.Component<IBrandDetailPageProps & IWithDataProps> {
	public render() {
		const { data, ...restProps } = this.props;

		return (
			<BrandDetailPage
				brand={data as IBrandType}
				{...restProps}
			/>
		);
	}
}

export default function BrandDetailPageContainer(props: IBrandDetailPageProps) {
	const RenderComponent = withData(
		BrandDetailPageContainerInner,
		'brandReducer',
		`brand-detail-${props.brandSlug}`,
		{
			brand: props.brandSlug,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'brand',
			LoadingComponent: () => <BrandDetailPageSkeleton />
		}
	);

	return <RenderComponent {...props} />
}