/* ### CONSTANTS ### */

export const ADD_ALERT = '@BasePipeline/alertReducer_ADD_ALERT';
export const CLOSE_ALERT  = '@BasePipeline/alertReducer_CLOSE_ALERT';
export const MARK_ALERT  = '@BasePipeline/alertReducer_MARK_ALERT';

/* ### ACTIONS ### */

/**
 * Adds a new alert
 *
 * @param id
 * @param title
 * @param message
 * @param level
 * @param timeoutInSeconds
 * @param icon
 */
export function addAlert (id: string, title: string, message: string, level: 'success' | 'error' | 'info' = 'success', timeoutInSeconds: number = 5, icon: boolean | string = false) {
	return {
		type: ADD_ALERT,
		id,
		title,
		message,
		level,
		icon,
		timeoutInSeconds: (timeoutInSeconds < 0.3) ? timeoutInSeconds : 5,
	};
}

export function closeAlert (id: string) {
	return {
		type: CLOSE_ALERT,
		id,
	};
}

export function markAlertAsShown (id: string) {
	return {
		type: MARK_ALERT,
		id,
	};
}
