import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { IWithRouteMetadataType } from "../../../../types/IWithRouteMetadataType";
import ShoppingCenterGroupedContainer from '../../container/ShoppingCenterGrouped/ShoppingCenterGroupedContainer';
import { IShoppingCenterGroupedType } from '../../types/IShoppingCenterGroupedType';
import ThemeworldListWidgetContainer from '../../../Themeworld/containers/ThemeworldListWidget/ThemeworldListWidgetContainer';
import { IThemeworldWidgetType } from '../../../Themeworld/types/IThemeworldWidget';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import AdItem from '../../../../components/General/AdItem/AdItem';

class ShoppingCentersListingPage extends React.Component <IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata } = this.props;

		if (!routeMetadata) {
			return;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403495}
					maxHeight={250}
					type="billboard"
				/>

				<H1>{routeMetadata.pageTitle}</H1>

				<Row>
					<AppMainCol boxed={true} onTop={true}>
						<AppMainColBox boxed={true}>

							<ShoppingCenterGroupedContainer
								limitItemsPerGroup={3}
								limit={5}
								titleLabel="Einkaufszentrum in %city%"
								titleLabelPlural="Einkaufszentren in %city%"
								pushRouteMetadata={true}
								queryKey={routeMetadata.queryKey}
								moreLabel="Alle Einkaufszentren in %city% anzeigen"
								moreRouteCallbk={this.routerCallbkShoppingCenterGrouped}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403482}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403481}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							/>
	
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>

						<ThemeworldListWidgetContainer
							widgetKey="promotions-themeworld-widget"
							title="Themenwelten"
							routeCallbk={this.routerCallbkThemeworldListWidget}
							limit={10}
							orderBy="title"
							orderDir="asc"
							btnRoute="ThemeworldListingRoute"
							btnLabel="Alle Themewelten"
						/>

						<AdItem
							desktopAdId={4403494}
							maxHeight={250}
							maxWidth={300}
							type="content"
							title="Medium Rectangle 1"
						/>

					</AppSideCol>
				</Row>
			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: "Einkaufszentren",
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route
	 */
	private routerCallbkShoppingCenterGrouped = (item: IShoppingCenterGroupedType) => {

		return {
			name: 'ShoppingCentersInStateRoute',
			params: {
				state: item.group.slug,
			},
		};
	};

	/**
	 * Defines the route the brand list widget should be routed towards
	 */
	private routerCallbkThemeworldListWidget = (item: IThemeworldWidgetType) => {
		return {
			name: 'ThemeworldDetailRoute',
			params: {
				themeworld: item.slug,
			},
		};
	}
}

export default ShoppingCentersListingPage;
