import { Settings } from 'luxon';
import { officialVersion } from '../../../version';
import envConfig from '../env.config';
import serverConfigEnv from '../server_env.config';

const appConfig = {
    /**
     * The current semver version
     * @type string
     */
    featureVersion: officialVersion,

    /**
     * URL of our API
     * @type string
     */
    url: 'https://' + envConfig.mainDomain,

    /**
     * The environment
     * @type string
     */
    environment: envConfig.environment,

    /**
     * Checks if process is in development
     * @type string
     */
    isDev: process && process.env && process.env.NODE_ENV === 'development',

    /**
     * URL of our API
     * @type string
     */
    apiUrl: envConfig.apiUrl,

    /**
     * Main domain for this application
     * @type string
     */
    mainDomain: envConfig.mainDomain ? envConfig.mainDomain : 'www.aktionsfinder.at',

    /**
     * URL of our CDN server
     * @type string
     */
    cdnUrl: envConfig.cdnUrl,

    /**
     * The api key for the azure api service
     * @type string
     */
    azureApiSubscriptionKey: envConfig.azureApiSubscriptionKey,

    /**
     * Token to authorize as this client with API
     * @type string
     */
    apiClientToken: envConfig.apiClientToken,

    /**
     * Token to authorize as this client with API
     * @type string
     */
    apiClientTokenMobile: envConfig.apiClientTokenMobile,

    /**
     * Defines the Authorization token (incl. type keyword like "Basic", "Bearer")
     */
    apiAuthorizationToken: 'Basic QWt0aW9uc2ZpbmRlcjpkUF82Y1A3OA==',

    /**
     * The locale used for intl and dates
     */
    locale: 'de',

    /**
     * The ttl for our normal access token
     * (used to automatically refresh the user token)
     */
    refreshTokenLifetime: 50000,

    /**
     * The google recaptcha v3 token
     * @type string
     */
    recaptchaV3SiteKey: envConfig.recaptchaV3SiteKey,

    /**
     * Url to sitemap file
     * @type string
     */
    sitemapUrl: serverConfigEnv.sitemapUrl,

    /**
     * The ssr caching method
     * @type 'mysql' | 'mssql' | 'none'
     */
    cachingService: serverConfigEnv?.cachingService,

    /**
     * The url to the matomo instance
     * @type string
     */
    matomoUrl: envConfig.matomoUrl,

    /**
     * Configuration for the Blob Container
     */
    assetBlobContainer: {
        account: serverConfigEnv?.assetBlobAccount,
        securityToken: serverConfigEnv?.assetBlobSecurityToken,
        containerName: serverConfigEnv?.assetBlobContainerName,
    },

    /**
     * Url to newsletter signup page
     */
    newsletterSignupUrl: envConfig.newsletterSignupUrl,

    /**
     * Credentials for the Azure App Insights
     */
    azureApplicationInsightsConnectionString: envConfig.azureApplicationInsightsConnectionString,
};

Settings.defaultLocale = appConfig.locale;

export default appConfig;
