import * as React from 'react';
import { ReactChild } from 'react';
import * as styles from './FlexWrapper.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import LayoutService from '../../../services/LayoutService';

interface IFlexWrapperProps {
	children?: ReactChild | JSX.Element[] | string[],
}

class FlexWrapper extends React.PureComponent<IFlexWrapperProps & RouteComponentProps, {}> {
	public render () {
		const { children, location } = this.props;

		return (
			<div className={`${styles.flexWrapper} ${LayoutService.isPromotionsRoute(location.pathname) ? styles.flexWrapperPadded : ''}`}>
				{children}
			</div>
		);
	}
}

export default withRouter(FlexWrapper);
