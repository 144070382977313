import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as shoppingCenterAggregatedByStatesWidgetActions from './shoppingCenterAggregatedByStatesWidgetActions';
import { IShoppingCenterAggregatedByStateType } from '../../types/IShoppingCenterAggregateByStateType';

export type ShoppingCenterAggregatedByStatesWidgetAction = ActionType<typeof shoppingCenterAggregatedByStatesWidgetActions>;

export interface IShoppingCenterAggregatedByStatesWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IShoppingCenterAggregatedByStateType };
	readonly queries: { [queryKey: string]: IShoppingCenterAggregatedByStateType[] };
}

export function shoppingCenterAggregatedByStatesWidgetReducer(
	state = baseInitialState as IShoppingCenterAggregatedByStatesWidgetStateType,
	action: ShoppingCenterAggregatedByStatesWidgetAction,
) {
	const Reducer = new BaseReducer('shoppingCenterAggregatedByStatesWidgetReducer', 'id');

	return Reducer.extend(state, action);
}
