import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import * as styles from './StaticWidget.scss';

interface IStaticWidgetPropsType {
	data: any;
	title: string;
	widgetKey: string;
}

class StaticWidget extends React.Component<IStaticWidgetPropsType, {}> {
	public render() {
		const { title, data, widgetKey } = this.props;

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>

					<div className={styles.widgetList}>
						{data.map((item: any) => {
							return (
								<WidgetListItemLinkRow
									key={`${widgetKey}-staticwidget-${item.title}`}
									href={item.link}
									className={styles.widgetListItem}
									title={item.title}
								/>
							);
						})}
					</div>

				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default StaticWidget;
