import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType';
import PromotionsAggregatedByProductGroupsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IAssortementPromotionsInProductGroupProps {
	productGroupSlug: SlugType;
}

class AssortementPromotionsInProductGroup extends React.Component<IAssortementPromotionsInProductGroupProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, productGroupSlug } = this.props;

		if (!routeMetadata) {
			return null;
		}

		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>

						<AppMainColBox nested={true} boxed={true}>

							<ProductPromotionGridContainer
								limit={50}
								enablePagination={true}
								orderBy="createdAt"
								orderDir="desc"
								productGroupSlug={productGroupSlug}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								fetchAssortementProductPromotions={true}
								onlyNearbyPromotions={false}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey={`appc-${productGroupSlug}-product-group-widget`}
								title={`Andere Produktkategorien mit Sortimentsaktionen`}
								referenceSlug={productGroupSlug}
								exclude={productGroupSlug}
								placeholderLimit={5}
								limit={50}
								routeCallbk={this.routerCallbkProductGroupListWidget}
								fetchAssortementProductPromotions={true}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		const { routeMetadata, productGroupSlug } = this.props;
		const productGroupName = (routeMetadata && routeMetadata.productGroup && routeMetadata.productGroup.title) ? routeMetadata.productGroup.title : productGroupSlug;

		return [
			{
				label: 'Aktionen',
				route: 'PromotionListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.productGroup.title : '',
				route: 'ProductGroupDetailRoute',
				routeParams: {productGroup: this.props.productGroupSlug},
			} as IBreadcrumbItemType,
			{
				label: `${productGroupName} Sortimentsaktionen`,
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {

		return {
			name: 'AssortementPromotionsInProductGroupRoute',
			params: {
				productGroup: item.group.slug,
			},
		};
	}
}

export default AssortementPromotionsInProductGroup;
