import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { IWidgetStyleType } from '../../../types/IWidgetStyleType';
import SkeletonBlock from '../Skeleton/SkeletonBlock';
import WidgetBox from './WidgetBox';
import WidgetBoxContent from './WidgetBoxContent';
import WidgetListItem from './WidgetListItem';
import WidgetListItemLinkRow from './WidgetListItemLinkRow';

export interface IWidgetBoxSkeletonProps {
	title?: string,
	rows: number,
	widgetKey: string,
	type?: 'linkrow' | 'item',
	style?: IWidgetStyleType,
	showValues?: {
		title?: boolean,
		subtitle?: boolean,
		image?: boolean,
		special?: boolean,
		extra?: boolean,
	},
}

class WidgetBoxSkeleton extends React.Component<IWidgetBoxSkeletonProps> {
	public static defaultProps = {
		type: 'linkrow',
		showValues: {
			title: true,
		}
	};

	public render () {
		const { title, style } = this.props;

		return (
			<WidgetBox title={title} style={style}>
				<WidgetBoxContent>
					{this.getRows()}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}

	/**
	 * Returns the skeleton rows using the correct row components
	 *
	 * @return {Array<ReactElement<any>>}
	 */
	private getRows (): Array<ReactElement<any>> {
		const { rows }  = this.props;
		const EntryRows = [];

		for (let i = 0; i < rows; i++) {
			EntryRows.push(
				this.getRowComponent(i)
			);
		}

		return EntryRows;
	}

	/**
	 * Returns the correct row component based on the widget box type
	 *
	 * @return {ReactElement<any>}
	 */
	private getRowComponent (indx: number): ReactElement<any> {
		const { type, widgetKey, showValues } = this.props;

		const showValuesOptions = (showValues) ? showValues : {
			title: true,
		};

		switch (type) {
			case 'linkrow':
				return	(
					<WidgetListItemLinkRow key={`${widgetKey}-skeleton-${indx}`} showArrow={false}>
						<SkeletonBlock
							minWidth={40}
							maxWidth={70}
						/>
					</WidgetListItemLinkRow>
				);

			case 'item':
				return	(
					<WidgetListItem
						key={`${widgetKey}-skeleton-${indx}`}
						title={this.renderSkeletonBlock(60, 80, true)}
						subtitle={this.renderSkeletonBlock(30, 50, showValuesOptions.subtitle)}
						special={this.renderSkeletonBlock(23, 35, showValuesOptions.special)}
						extra={this.renderSkeletonBlock(30, 50, showValuesOptions.extra)}
						image={this.renderSkeletonBlock(30, 50, showValuesOptions.image)}
					/>
				);

			default: return (
				<div>&nbsp;</div>
			);
		}
	}

	/**
	 * Returns a rendered skeleton block to put in our widget list item params
	 *
	 * @param {number} minWidth
	 * @param {number} maxWidth
	 * @param {boolean} visible
	 *
	 * @return {ReactNode}
	 */
	private renderSkeletonBlock(minWidth: number, maxWidth: number, visible: boolean = false): ReactNode {
		if (!visible) {
			return undefined;
		}

		return (
			<SkeletonBlock
				minWidth={minWidth}
				maxWidth={maxWidth}
			/>
		);
	}
}

export default WidgetBoxSkeleton;
