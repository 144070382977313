import { IRequestMiddlewareObject } from './types/IRequestMiddlewareObject';

export default class RequestMiddlewarePipeline {
	/**
	 * contains the middlewares we want to use in this transformation
	 * @param IRequestMiddlewareObject
	 */
	private pipelineObject: IRequestMiddlewareObject;

	/**
	 * Initiate a new instance with an array of entries
	 *
	 * @param queryKey
	 * @param middlewares
	 * @param options
	 */
	constructor (queryKey: string, middlewares: { [key: string]: any }, options = {}) {
		this.pipelineObject = {
			queryKey,
			metaData: {},
			middlewares,
			pipelineData: {},
			options: {},
		};
	}

	/**
	 * Returns either the whole response content or just a specific key of that
	 *
	 * @param key
	 * @param fallback
	 *
	 * @return any
	 */
	public getContent(key?: string, fallback?: any): any {
		if (!this.pipelineObject || !this.pipelineObject.response || !this.pipelineObject.response.body) {
			return fallback;
		}

		if (!key) {
			return this.pipelineObject.response.body;
		}

		if (this.pipelineObject.response.body && typeof this.pipelineObject.response.body[key] !== undefined) {
			return this.pipelineObject.response.body[key];
		}

		return fallback;
	}

	/**
	 * Adds a meta data value
	 *
	 * @param key
	 * @param value
	 */
	public addMetaData(key: string, value: any) {
		if (!this.pipelineObject.metaData) {
			this.pipelineObject.metaData = {};
		}

		this.pipelineObject.metaData[key] = value;
	}

	/**
	 * Returns either the whole response meta data or just a specific key of that
	 *
	 * @param key
	 * @param fallback
	 *
	 * @return any
	 */
	public getMetaData(key?: string, fallback?: any): any {
		if (!key) {
			return this.pipelineObject.metaData;
		}

		if (this.pipelineObject.metaData && typeof this.pipelineObject.metaData[key] !== undefined) {
			return this.pipelineObject.metaData[key];
		}

		return fallback;
	}

	/**
	 * Sets the request
	 *
	 * @param {IRequestObject} request
	 *
	 * @return {self}
	 */
	public setRequest (request: IRequestObject): this {
		this.pipelineObject.request = request;
		this.applyMiddlewares('setRequest');

		return this;
	}

	/**
	 * Sets the response
	 *
	 * @param {any} body
	 * @param { [key: string]: string } headers
	 *
	 * @return {self}
	 */
	public setResponse(body: any, headers: { [key: string]: string } = {}): this {
		this.pipelineObject.response = {
			body,
			headers,
		};
		this.applyMiddlewares('setResponse');

		return this;
	}

	/**
	 * Initiate a new instance with an existing IRequestMiddlewareObject
	 *
	 * @param pipelineObject
	 *
	 * @return {self}
	 */
	public setRequestMiddlewareObject(pipelineObject: IRequestMiddlewareObject): this {
		this.pipelineObject = pipelineObject;
		return this;
	}

	/**
	 * Calls the before fetch hook
	 */
	public beforeFetch() {
		this.applyMiddlewares('beforeFetch');
	}

	/**
	 * Calls the after fetch hook
	 */
	public afterFetch() {
		this.applyMiddlewares('afterFetch');
	}

	/**
	 * Calls the on success hook
	 */
	public onSuccess() {
		this.applyMiddlewares('onSuccess');
	}

	/**
	 * Calls the on error hook
	 *
	 * @param {any} error
	 */
	public onError(error: any) {
		// TODO: implement error handling
		this.applyMiddlewares('onError');
	}

	/**
	 * Loops through all middlewares and calls the given hook
	 * @param {string} hook
	 */
	private applyMiddlewares (hook: string): this {
		const middlewares = this.pipelineObject.middlewares;

		for (const key of Object.keys(middlewares)) {
			const MutatorClass = middlewares[key];

			if (MutatorClass[hook]) {
				this.pipelineObject = MutatorClass[hook](this.pipelineObject);
			}
		}

		return this;
	}

}
