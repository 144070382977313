/* tslint:disable:no-console */

import appConfig from '../../App/config/appConfig';
import reduxPipeline from '../../App/config/reduxPipeline';
import { IApiErrorType } from '../../App/types/BaseTypes';
import RequestError from './errors/RequestError';
import RequestMiddlewarePipeline from './RequestMiddlewarePipeline';
import RefreshTokenService from '../../App/modules/Account/services/RefreshTokenService';
import fetch from 'cross-fetch';
import { IRequestObject } from './types/IRequestObject';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getAppInsights } from '../../App/services/TelemetryService';

interface IFetchParameters {
    url: string;
    options: { [key: string]: any };
}

export default class BaseHttpRequest {
    /**
     * Defines the base Url of this http requestor
     */
    public baseUrl: string;

    /**
     *  Contains the request middleware instance
     *
     *  @param {RequestMiddlewarePipeline} requestMiddlewareInstance
     *  @private
     */
    private readonly requestMiddlewareInstance: RequestMiddlewarePipeline;

    constructor(queryKey: string, middlewares2apply?: string[], options = {}) {
        this.requestMiddlewareInstance = new RequestMiddlewarePipeline(queryKey, this.onlyGetMiddlewareMutators2Apply(reduxPipeline, middlewares2apply), options);
    }

    /**
     * Performs a get request and all pipeline hook mutations
     *
     * @param requestObject
     *
     * @return Promise <RequestMiddlewarePipeline>
     */
    public getRequest<T>(requestObject: IRequestObject): Promise<RequestMiddlewarePipeline> {
        return this.performRequest('get', requestObject);
    }

    /**
     * Performs a post request and all pipeline hook mutations
     *
     * @param requestObject
     *
     * @return Promise <RequestMiddlewarePipeline>
     */
    public postRequest<T>(requestObject: IRequestObject): Promise<RequestMiddlewarePipeline> {
        return this.performRequest('post', requestObject);
    }

    /**
     * Performs a delete request and all pipeline hook mutations
     *
     * @param requestObject
     *
     * @return Promise <RequestMiddlewarePipeline>
     */
    public deleteRequest<T>(requestObject: IRequestObject): Promise<RequestMiddlewarePipeline> {
        return this.performRequest('delete', requestObject);
    }

    /**
     * Performs a put request and all pipeline hook mutations
     *
     * @param requestObject
     *
     * @return Promise <RequestMiddlewarePipeline>
     */
    public putRequest<T>(requestObject: IRequestObject): Promise<RequestMiddlewarePipeline> {
        return this.performRequest('put', requestObject);
    }

    /**
     * Performs a patch request and all pipeline hook mutations
     *
     * @param requestObject
     *
     * @return Promise <RequestMiddlewarePipeline>
     */
    public patchRequest<T>(requestObject: IRequestObject): Promise<RequestMiddlewarePipeline> {
        return this.performRequest('patch', requestObject);
    }

    /**
     * Returns the actual error message from an error object
     *
     * @param errorObj
     *
     * @return {IApiErrorType[]}
     */
    public getErrorMessage(errorObj: any): IApiErrorType[] {
        return errorObj;
    }

    /**
     * Merges two param objects and makes sure only defined values overwrite defaults
     *
     * @param params1
     * @param params2
     */
    public mergeParams(params1: { [key: string]: any }, params2: { [key: string]: any }): { [key: string]: any } {
        const newParams = Object.assign({}, params1);

        for (const key of Object.keys(params2)) {
            if (typeof params2[key] !== 'undefined') {
                newParams[key] = params2[key];
            }
        }

        return newParams;
    }

    /**
     * Takes the request object and transforms url, params, body and options into a format that wysig-fetch can work with
     *
     * @param method
     * @param requestObject
     *
     * return {IFetchParameters}
     */
    protected constructFetchParameters(method: 'get' | 'post' | 'delete' | 'put' | 'patch' = 'get', requestObject: IRequestObject): IFetchParameters {
        return {
            url: this.getUrl(requestObject),
            options: this.getOptions(method, requestObject),
        } as IFetchParameters;
    }

    /**
     * Parses requestObject and adds creates options array
     *
     * @param {'get' | 'post' | 'delete' | 'put'} method
     * @param {IRequestObject} requestObject
     *
     * @return { [key: string]: string | number | boolean  }
     * @protected
     */
    protected getOptions(method: 'get' | 'post' | 'delete' | 'put' | 'patch' = 'get', requestObject: IRequestObject): { [key: string]: string | number | boolean } {
        const options = requestObject.options ? requestObject.options : {};

        if (!options.method) {
            options.method = method.toUpperCase();
        }

        if (!options.headers) {
            options.headers = this.getHeaders(requestObject);
        }

        if (!options.body) {
            options.body = this.getBody(requestObject);
        }

        return options;
    }

    /**
     * Parses requestObject and adds headers based on the values in there or adds additional ones
     *
     * @param {IRequestObject} requestObject
     *
     * @return { [key: string]: string | number | boolean  } | undefined
     * @protected
     */
    protected getHeaders(requestObject: IRequestObject): { [key: string]: string | number | boolean } | undefined {
        return requestObject.headers;
    }

    /**
     * Parses requestObject and adds additional url params
     *
     * @param {IRequestObject} requestObject
     *
     * @return { [key: string]: string | number | boolean  } | undefined
     * @protected
     */
    protected getAdditionalParams(requestObject: IRequestObject): { [key: string]: string | number | boolean } {
        return {};
    }

    /**
     * Constructs the url based on our url string and params object
     *
     * @param {IRequestObject} requestObject
     *
     * @return string
     * @protected
     */
    protected getUrl(requestObject: IRequestObject): string {
        const url = requestObject.url ? `${this.baseUrl}${requestObject.url}` : '';
        const params = Object.assign(this.getAdditionalParams(requestObject), requestObject.params);

        if (!params || params.length < 1) {
            return url;
        }

        // create param string
        let paramString = '';
        for (const key of Object.keys(params)) {
            if (params[key]) {
                paramString += `&${key}=${params[key]}`;
            }
        }

        if (paramString.length > 0) {
            if (url.indexOf('?') === -1) {
                return `${url}?${paramString.substr(1)}`;
            } else {
                return `${url}${paramString}`;
            }
        }

        return url;
    }

    /**
     * Parses requestObject and returns the request body
     *
     * @param {IRequestObject} requestObject
     *
     * @return string | undefined
     * @protected
     */
    protected getBody(requestObject: IRequestObject): string | undefined {
        if (!requestObject.body) {
            return undefined;
        }

        return JSON.stringify(requestObject.body);
    }

    /**
     * Performs the http request and prepares the data
     *
     * @param url
     * @param options
     *
     * @return Promise<T>
     */
    protected callRequest<T>(url: string, options: { [key: string]: any }): Promise<T> {
        return RefreshTokenService.getAccessToken().then((accessToken: string) => {
            return fetch(url, options)
                .catch(this.handleConnectionErrors)
                .then(this.checkStatus)
                .then(this.parseResponse)
                .then(this.parseJSON);
        });
    }

    /**
     * Handles first stage connection errors
     *
     * @param error
     */
    protected handleConnectionErrors(error: RequestError) {
        throw new Error('Konnte keine Verbindung herstellen');
        /*return Object.assign({}, error, {
			response: {
				status: 0,
				statusText:
					"Cannot connect. Please make sure you are connected to internet."
			},
		});*/
    }

    /**
     * Handles errors during fetching
     *
     * @param error
     */
    protected handleErrors(error: RequestError, fetchParameters: IFetchParameters): RequestMiddlewarePipeline {
        const formattedError = this.getErrorMessage(error);

        // Tracking exceptions in Azure App Insights
        for (const error of formattedError) {
            getAppInsights()?.trackException(
                {
                    error: new Error(error.key ?? 'An error occured'),
                    severityLevel: SeverityLevel.Error,
                },
                {
                    url: fetchParameters.url,
                    method: fetchParameters.options.method,
                },
            );
        }

        if (appConfig.isDev) {
            console.error('BaseHttpRequest Error: ', formattedError);
        }

        this.requestMiddlewareInstance.onError(formattedError);

        throw formattedError;

        return this.requestMiddlewareInstance;
    }

    /**
     * Performs an http request and calls all the necessary pipelines for our request middlework to work
     *
     * @param { 'get' | 'post' | 'delete' | 'put'} method
     * @param {IRequestObject} requestObject
     *
     * @return Promise<RequestMiddlewarePipeline>
     */
    private async performRequest<T>(method: 'get' | 'post' | 'delete' | 'put' | 'patch' = 'get', requestObject: IRequestObject): Promise<RequestMiddlewarePipeline> {
        // set the request
        this.requestMiddlewareInstance.setRequest(requestObject);

        // mutate before fetch
        this.requestMiddlewareInstance.beforeFetch();

        // prepare our data
        const fetchParameters = this.constructFetchParameters(method, requestObject);

        // uncomment this to test a
        /*await new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, (7 * 1000));
		});*/

        // perform the http request
        return this.callRequest(fetchParameters.url, fetchParameters.options)
            .then((response: any) => {
                this.requestMiddlewareInstance.onSuccess();

                // set the response
                this.requestMiddlewareInstance.afterFetch();

                this.requestMiddlewareInstance.setResponse(response.body, response.headers);

                return this.requestMiddlewareInstance;
            })
            .catch(error => {
                this.handleErrors(error, fetchParameters);

                return this.requestMiddlewareInstance;
            });
    }

    /**
     * Parses the JSON returned by a network request
     *
     * @param  {object} response A response from a network request
     *
     * @return {object}          The parsed JSON from the request
     */
    private parseResponse(response: any): any {
        if (response.status === 204 || response.status === 205) {
            return null;
        }

        return response.text().then(body => {
            return {
                body,
                headers: response.headers.map,
            };
        });
    }

    /**
     * Parses the JSON returned by a network request
     *
     * @param  {string} responseText A response from a network request
     *
     * @return {string}          The parsed JSON from the request
     */
    private parseJSON(response: { body: string; headers: { [key: string]: string } }): any {
        try {
            return {
                body: JSON.parse(response.body),
                headers: response.headers,
            };
        } catch (e) {
            return {
                body: response.body + '',
                headers: response.headers,
            };
        }
    }

    /**
     * Checks if a network request came back fine, and throws an error if not
     *
     * @param  {object} response   A response from a network request
     *
     * @return {object|undefined} Returns either the response, or throws an error
     */
    private checkStatus(response: any): any {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        const contentType = response && response.headers ? response.headers.get('content-type') : '';

        if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json().then((json: any) => {
                return Promise.reject({
                    status: response.status,
                    ok: false,
                    statusText: response.statusText,
                    body: json,
                });
            });
        } else {
            return Promise.reject({
                status: response.status,
                ok: false,
                statusText: response.statusText,
            });
        }
    }

    /**
     * Takes all the middlewares available as a map and an array of middlewares we want to apply to this request.
     * It will only return middlewares, that are set in middlewares2apply
     *
     * @param allMiddlewares
     * @param middlewares2apply
     *
     * return { [key: string]: any }
     */
    private onlyGetMiddlewareMutators2Apply(allMiddlewares: { [key: string]: any }, middlewares2apply?: string[]): { [key: string]: any } {
        const applyableMiddlewares = {};

        if (!middlewares2apply) {
            return allMiddlewares;
        }

        for (const applyKey of middlewares2apply) {
            if (typeof allMiddlewares[applyKey]) {
                applyableMiddlewares[applyKey] = allMiddlewares[applyKey];
            } else if (appConfig.isDev) {
                console.warn(`AFRequestMiddlewarePipeline: MiddlewareRequestMutator ${applyKey} was not found in pipeline list`);
            }
        }

        return applyableMiddlewares;
    }
}
