import { DateTime } from 'luxon';
import Slider from '@material-ui/core/Slider';
import * as React from 'react';
import Icon from '../../../Base/components/Icon/Icon';
import { ILeafletType } from '../../types/ILeafletType';
import * as styles from './LeafletHeader.scss';
import { minZoom, maxZoom, zoomEventTarget, zoomEvent } from '../../../Base/components/ZoomableView/ZoomableView';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';
import { history } from '../../../../../boilerplate/razzle/history';
import { RouteComponentProps, withRouter } from 'react-router';
import HistoryService from '../../../../services/HistoryService';
import LeafletHeaderVendorLogo from './LeafletHeaderVendorLogo';
import { LeafletViewerType } from '../../hocs/withLeaflet';
import LeafletInfopostHeaderLogo from './LeafletInfopostHeaderLogo';

interface ILeafletHeaderPropsType {
    leaflet: ILeafletType;
    leafletViewerType: LeafletViewerType;
}

class LeafletHeader extends React.Component<ILeafletHeaderPropsType & RouteComponentProps, {}> {
    public static defaultProps = {};

    public state = {
        zoom: minZoom,
    };

    private goBackTimeout: any;

    public componentDidMount() {
        zoomEventTarget.addEventListener('zoom', this.handleZoom);
    }

    public componentWillUnmount() {
        zoomEventTarget.removeEventListener('zoom', this.handleZoom);
    }

    public render() {
        const { leaflet, leafletViewerType } = this.props;

        return (
            <div className={styles.leafletHeaderContainer}>
                <div className={styles.leafletHeaderBlockInfo}>
                    {/* TODO: Uncomment 'favorite' icons and make leaflet bookmarks */}
                    {/* <Icon
                        icon="favorite"
                        className={styles.leafletHeaderInfoFavouriteIcon}
                    /> */}

                    <LeafletHeaderVendorLogo leaflet={leaflet} leafletViewerType={leafletViewerType} />

                    <div className={styles.leafletHeaderInfo}>
                        {!leaflet.title ? null : <h1 className={styles.leafletHeaderInfoTitle}>{leaflet.title}</h1>}

                        {!leaflet.validTo ? null : (
                            <div>
                                gültig bis{' '}
                                {DateTime.fromISO(leaflet.validTo)
                                    .setLocale('de')
                                    .toLocaleString()}
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.leafletHeaderBlockZoom}>
                    <button type="button" className={styles.leafletHeaderIcon} onClick={this.shiftSlider(-0.2)}>
                        <Icon icon="minus" />
                    </button>

                    <Slider
                        classes={{
                            thumb: styles.sliderThumb,
                            track: styles.sliderTrack,
                        }}
                        min={minZoom}
                        max={maxZoom}
                        value={this.state.zoom}
                        onChange={this.handleSliderChange}
                    />

                    <button type="button" className={styles.leafletHeaderIcon} onClick={this.shiftSlider(+0.2)}>
                        <Icon icon="add" />
                    </button>
                </div>
                <div className={styles.leafletHeaderBlockControls}>
                    {/* <button type="button" className={`${styles.leafletHeaderIcon} ${styles.leafletHeaderControlsFavouriteIcon}`} >
                        <Icon icon="favorite" />
                    </button> */}

                    {leafletViewerType === LeafletViewerType.INFOPOST ? (
                        <LeafletInfopostHeaderLogo leaflet={leaflet} />
                    ) : (
                        <button type="button" className={styles.leafletHeaderIcon} onClick={this.goBack}>
                            <Icon icon="close" />
                        </button>
                    )}
                </div>
            </div>
        );
    }

    /**
     * Go back to last page
     */
    private goBack = () => {
        if (!isBrowser()) {
            return;
        }

        // if we have a history on this page (excluding all other leaflets) -> go back
        const lastHistoryEntry = HistoryService.last();
        if (lastHistoryEntry && lastHistoryEntry.substr(0, 3) !== '/l/') {
            history.goBack();
        } else {
            window.location.href = '/';
        }
    };

    /**
     * Move the slider and update zoom accordingly
     */
    private shiftSlider = (factor: number) => () => {
        const shift = factor * (maxZoom - minZoom);
        this.changeZoom(this.state.zoom + shift);
    };

    /**
     * Apply the zoom factor selected in the slider
     */
    private handleSliderChange = (event: React.ChangeEvent, zoom: number) => this.changeZoom(zoom);

    /**
     * Apply a new zoom factor
     *
     * @param zoom
     */
    private changeZoom = (zoom: number) => zoomEventTarget.dispatchEvent(zoomEvent('requestZoom', zoom));

    /**
     * Get notified when zoom changes and store the new zoom factor
     *
     * @param event
     */
    private handleZoom = ({ detail: zoom }: CustomEvent) => this.setState({ zoom });
}

export default withRouter(LeafletHeader);
