import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IProductGroupType } from '../../../Category/types/IProductGroupType';
import { IVendorType } from '../../../Vendor/types/IVendorType';
import ProductPromotionStatusWidget from '../../components/ProductPromotionStatusWidget/ProductPromotionStatusWidget';
import DataStateService from '../../../../services/DataStateService';


interface IProductPromotionStatusWidgetContainerProps {
	widgetKey: string;
	productGroup: IProductGroupType;
	vendor: IVendorType;
}

class ProductPromotionStatusWidgetContainerInner extends React.Component<IProductPromotionStatusWidgetContainerProps & IWithDataProps> {
	public render () {
		const { data } = this.props;

		if (!data) {
			return null;
		}

		return (
			<ProductPromotionStatusWidget
				data={data}
				{...this.props}
			/>
		);
	}
}

export default function ProductPromotionStatusWidgetContainer (props: IProductPromotionStatusWidgetContainerProps) {
	// TODO: Remove this RETURN after API is ready
	// return <ProductPromotionStatusWidget
	// 	data={{
	// 		"hasActiveLeafletForVendor": true,
	// 		"hasActivePromotionsForVendor": true,
	// 		"hasActivePromotionsForProductGroup": true
	// 	}}
	// 	{...props}
	// />

	const RenderComponent = withData(
		ProductPromotionStatusWidgetContainerInner,
		'productPromotionStatusReducer',
		`${props.widgetKey}-${DataStateService.getStateKey()}`,
		{
			productGroupSlug: props.productGroup.slug,
			vendorSlug: props.vendor.slug,
			slug: props.widgetKey,
		},
		{
			primaryKeyName: 'slug',
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					rows={ 5 }
					widgetKey={props.widgetKey}
				/>
			),
			ErrorComponent: (ddd) => {
				console.log('StatusWidget ERROR', ddd)
				return null;
			},
		}
	);

	return <RenderComponent {...props} />
}
