import { withRouter } from 'react-router';
import * as React from 'react';
import BaseRoute from './_BaseRoute';
import SimpleHeader from '../App/components/Layout/Header/SimpleHeader';
import RedirectContent from '../App/components/Layout/RedirectContent/RedirectContent';
import queryString from 'query-string';
import DataLayerService from '../App/modules/Base/services/DataLayerService';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { isBrowser } from '../boilerplate/razzle/razzleUtils';

class ServiceRoute extends BaseRoute {

	/**
	 * Track this redirect
	 */
	private static trackRedirect(redirectUrl: string) {
		const redirectUrlData = ServiceRoute.parseUrl(redirectUrl);

		DataLayerService.push('REDIRECT', {
			targetDomain: redirectUrlData.hostname,
			targetPath: redirectUrlData.pathname + redirectUrlData.search,
		});
	}

	/**
	 * Parses url to get different parts of it
	 *
	 * @param url
	 */
	private static parseUrl(url: string) {
		const m = url.match(/^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/);

		if (!m) {
			return {
				hostname: '',
				pathname: '',
				search: '',
			};
		}

		const r = {
			hostname: m[6] || "",                // localhost
			pathname: m[8] || (m[1] ? "/" : ""), // /deploy/
			search: m[9] || "",                  // ?asd=asd
		};

		return m && r;
	}

	public constructor(props: Readonly<RouteComponentProps<{}, {}, any>>){
		super(props);

		this.state = {
			urlError: false,
		};
	}

	public componentDidMount (): void {
		const redirectUrl = this.getRedirectUrl();

		if (!isBrowser()) {
			return;
		}

		if (!redirectUrl) {
			this.setState({
				urlError: true,
			});
			return;
		}

		ServiceRoute.trackRedirect(redirectUrl);

		// do the redirect
		// setTimeout(() => {
		// 	window.location.href = redirectUrl;
		// }, 2000);
	}

	public render () {
		const { urlError } = this.state;
		const { location } = this.props;
		const params = queryString.parse(location.search);


		return (
			<React.Fragment>
				<Helmet>
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
				<SimpleHeader />
				<RedirectContent error={urlError} targetUrl={`${params.url}`} />
			</React.Fragment>
		);
	}

	/**
	 * Gets the redirect url from query params
	 */
	private getRedirectUrl(): string {
		const { location } = this.props;
		const params = queryString.parse(location.search);

		if (!params.url) {
			return '';
		}

		// get redirect url params
		// const urlParams: {[key: string]: string} = this.getQuery(params.url as string);
		// let urlParamsAddition = '';

		// if no utm params in url -> add our own
		// if (!urlParams.utm_source) {
		// 	urlParamsAddition =
		// 	'utm_source=aktionsfinder.at&utm_medium=Mehr Traffic von Aktionsfinder? - digitalservices@post.at&utm_campaign=Mehr Traffic von Aktionsfinder? - digitalservices@post.at';

		// 	const connectingChar = params.url.indexOf('?') !== -1 ? '&' : '?';

		// 	return decodeURIComponent((params.url + connectingChar + urlParamsAddition) as string);
		// }

		return decodeURIComponent(params.url as string);
	}


	/**
	 * Takes a url string and returns the query params for it
	 * @param url
	 */
	private getQuery(url: string) {
		const query: {[key: string]: string} = {};
		const href = url || window.location.href;

		href.replace(/[?&](.+?)=([^&#]*)/g, (_, key, value): string => {
			query[key] = decodeURI(value).replace(/\+/g, ' ');
			return '';
		});

		return query;
	}

}

export default withRouter(
	ServiceRoute,
);
