import * as React from 'react';
import Button from '../../../../components/General/Button/Button';
import H1 from '../../../../components/Layout/H1/H1';
import InfoScreenWrapper from '../../../../components/Layout/InfoScreenWrapper/InfoScreenWrapper';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';


interface IVerifyMailProps {

}

class VerifyMail extends React.Component<{}, IVerifyMailProps> {
	public render() {

		return (
			<PageWrapper hideLocation={true} fluid={true}>
				<InfoScreenWrapper>
					<H1 align="center">
						Erfolgreich bestätigt
					</H1>

					<p>
						Dein Benutzerkonto wurde erfolgreich aktiviert und du kannst<br />
						dich ab sofort damit anmelden.
					</p>

					<Button
        				theme="primary"
						size="xxl"
						fullWidth={true}
						route="LoginRoute"
					>
						Zur Anmeldung
					</Button>
				</InfoScreenWrapper>
			</PageWrapper>
		);
	}
}

export default VerifyMail;
