import * as React from 'react';
import Link from '../../../Base/components/Link/Link';
import * as styles from './LotteryList.scss';
import ContentBox from '../../../../components/Layout/PageWrapper/ContentBox';
import LotteryListItemSkeleton from '../LotteryListItem/LotteryListItemSkeleton';

interface ILotteryListSkeletonProps {
	title?: string;
	moreLabel?: string;
	moreRoute?: string;
    columns?: '2' | '3';
	boxed?: boolean;
}

class LotteryListSkeleton extends React.Component<ILotteryListSkeletonProps, {}> {
	public static defaultProps = {
		columns: '2',
	};

	public render() {
        const {  title, moreLabel, moreRoute, columns, boxed } = this.props;
        
        let className = `${styles.lotteryListItemsContainer} ${styles['lotteryListItemsContainerColumns-'+ columns]}`;
		
		if ( boxed ) {
            className += ` ${styles.lotteryListBoxed}`
        }

		return (
			<ContentBox title={title} nested={true}>
				<div>
                    <div className={className}>
                        {Array.from(Array(3).keys()).map((item: any, index: number) => {

                            return (
                                <LotteryListItemSkeleton
                                    key={`${index}`}
                                    columns={columns}
                                />
                            );
                        })}
                    </div>


					{( !moreLabel ) ? null :
						<div className={styles.lotteryListMoreItem}>
							<Link route={moreRoute + ''}>
								<a href="#" className={styles.lotteryListMore}>
                                    <span className={styles.lotteryListMoreLabel}>
										{moreLabel}
									</span>
								</a>
							</Link>
						 </div>
						}
				</div>
			</ContentBox>
		);
	}

}

export default LotteryListSkeleton;
