import * as React from 'react';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import * as styles from './LeafletGridGrouped.scss';
import LeafletGridGroupSkeleton from './LeafletGridGroupSkeleton';
import { ILeafletType } from '../../types/ILeafletType';
import CurrentVendorLeafletSkeleton from '../CurrentVendorLeaflet/CurrentVendorLeafletSkeleton';

export interface ILeafletGridGroupedSkeletonProps {
	size?: 's' | 'm' | 'l',
	gridKey?: string,
	columns?: '1' | '2' | '3',

	moreLabel?: string,
	groupTitle?: string,

	contentBreaks?: { [key: number]: any };
	limit?: number;
	withCurrentLeafletVendor?: boolean;
}

class LeafletGridGroupedSkeleton extends React.PureComponent<ILeafletGridGroupedSkeletonProps, {}> {
	public static defaultProps = {
		columns: 3,
		gridKey: 'default',
		size: 'm',
		contentBreaks: {},
		limit: 1
	};

	// TRANSLATE
	public render () {
		const { size, gridKey, moreLabel, columns, contentBreaks, groupTitle, limit, withCurrentLeafletVendor } = this.props;

		const limitArr = Array.from(Array(limit).keys());
		let currentVendorComponent = null;

		if (withCurrentLeafletVendor) {
			currentVendorComponent = (
				<div className={styles.currentVendorLeafletContainer}>
					<CurrentVendorLeafletSkeleton>
						<div className={`${styles.actionWrapper}`}>
							<span className={styles.actionRoute}>
								<SkeletonInline />
							</span>

							<span className={styles.actionRoute}>
								<SkeletonInline />
							</span>
							
							<span className={styles.actionRoute}>
								<SkeletonInline />
							</span>

						</div> 
					</CurrentVendorLeafletSkeleton>
				</div>
			);
		}

		return (
			<React.Fragment>
				{limitArr.map((indx) => {
					return (
						<React.Fragment key={`leaflet-grid-group-${gridKey}-${indx}`}>

							{currentVendorComponent}

							{ (!contentBreaks || !contentBreaks[indx]) ? null :
								<React.Fragment>
									{contentBreaks[indx]}
								</React.Fragment>
							}

							<div className={styles.GridGroupWrapper}>
								{ (!groupTitle) ? null :
									<h2 className={styles.GroupGroupedTitle}>
										<SkeletonInline />
									</h2>
								}

								<LeafletGridGroupSkeleton
									size={size}
									columns={columns}
									gridKey={`${gridKey}-${indx}`}
								/>

								{( !moreLabel ) ? null :
								 <div className={`${styles.GridGroupedMoreItem}`}>
									 <a href="#" className={styles.GridGroupedMore}>
										 <SkeletonInline />
									 </a>
								 </div>
								}
							</div>
						</React.Fragment>
					);
				})
				}
			</React.Fragment>
		);
	}
}

export default LeafletGridGroupedSkeleton;
