import React from 'react';
import { Route, Switch } from 'react-router-dom';
import version from '../version';
import { setGeolocation } from './App/modules/Base/store/Geolocation/geolocationActions';
import { IGeolocationType } from './App/modules/Base/types/IGeolocationType';
import DataStateService from './App/services/DataStateService';
import ProductGroupDetailRoute from './pages/ProductGroupDetailRoute';
import HomeRoute from './pages/HomeRoute';
import Widgets from './pages/widgets';
import BrandListingRoute from './pages/BrandListingRoute';
import LeafletsInIndustryRoute from './pages/LeafletsInIndustryRoute';
import VendorDetailRoute from './pages/VendorDetailRoute';
import VouchersInStateRoute from './pages/VouchersInStateRoute';
import LotteryRegistrationRoute from './pages/LotteryRegistrationRoute';
import PromotionsInProductCategoryAndIndustryRoute from './pages/PromotionsInProductCategoryAndIndustryRoute';
import ShopsByVendorAndStateRoute from './pages/ShopsByVendorAndStateRoute';
import BrandsWithFirstLetterRoute from './pages/BrandsWithFirstLetterRoute';
import ShoppingCentersInStateRoute from './pages/ShoppingCentersInStateRoute';
import ThemeworldDetailRoute from './pages/ThemeworldDetailRoute';
import VoucherListingRoute from './pages/VoucherListingRoute';
import LotteryInfoRoute from './pages/LotteryInfoRoute';
import PromotionsInIndustryRoute from './pages/PromotionsInIndustryRoute';
import PromotionsByBrandInStateRoute from './pages/PromotionsByBrandInStateRoute';
import PromotionsInShopByBrandRoute from './pages/PromotionsInShopByBrandRoute';
import PromotionsInProductGroupAndVendorRoute from './pages/PromotionsInProductGroupAndVendorRoute';
import PromotionsByVendorRoute from './pages/PromotionsByVendorRoute';
import PromotionsByVendorInStateRoute from './pages/PromotionsByVendorInStateRoute';
import PromotionsInProductGroupAndCityRoute from './pages/PromotionsInProductGroupAndCityRoute';
import LotteryParticipationConditionsRoute from './pages/LotteryParticipationConditionsRoute';
import FeedbackRoute from './pages/FeedbackRoute';
import AssortementPromotionsByVendorRoute from './pages/AssortementPromotionsByVendorRoute';
import PromotionsFromBrandAndVendorRoute from './pages/PromotionsFromBrandAndVendorRoute';
import VendorListingRoute from './pages/VendorListingRoute';
import ForgottenPasswordRoute from './pages/ForgottenPasswordRoute';
import PromotionListingRoute from './pages/PromotionListingRoute';
import PromotionsInProductGroupAndStateRoute from './pages/PromotionsInProductGroupAndStateRoute';
import BrandDetailRoute from './pages/BrandDetailRoute';
import LeafletDetailRoute from './pages/LeafletDetailRoute';
import VendorBookmarkListingRoute from './pages/VendorBookmarkListingRoute';
import VerifyMailRoute from './pages/VerifyMailRoute';
// import CashbackRoute from './pages/CashbackRoute';
import RegistrationRoute from './pages/RegistrationRoute';
import ShoppingCenterDetailRoute from './pages/ShoppingCenterDetailRoute';
import PromotionsInShopAndProductGroupRoute from './pages/PromotionsInShopAndProductGroupRoute';
import LotteryPaymentRoute from './pages/LotteryPaymentRoute';
import LeafletsInStateRoute from './pages/LeafletsInStateRoute';
import PromotionDetailRoute from './pages/PromotionDetailRoute';
import AssortementPromotionsInProductGroupRoute from './pages/AssortementPromotionsInProductGroupRoute';
import ThemeworldListingRoute from './pages/ThemeworldListingRoute';
import AccountChangeEmailRoute from './pages/AccountChangeEmailRoute';
import PromotionsInStateRoute from './pages/PromotionsInStateRoute';
import AccountChangeDataRoute from './pages/AccountChangeDataRoute';
import PromotionBookmarkListingRoute from './pages/PromotionBookmarkListingRoute';
import ShopDetailRoute from './pages/ShopDetailRoute';
import LeafletsByVendorRoute from './pages/LeafletsByVendorRoute';
import PromotionsFromBrandAndCityRoute from './pages/PromotionsFromBrandAndCityRoute';
import PromotionsFromProductGroupAndBrandRoute from './pages/PromotionsFromProductGroupAndBrandRoute';
import VouchersByVendorInStateRoute from './pages/VouchersByVendorInStateRoute';
import ConfirmNewslettersRoute from './pages/ConfirmNewslettersRoute';
import ResetPasswordRoute from './pages/ResetPasswordRoute';
import VouchersByVendorRoute from './pages/VouchersByVendorRoute';
import LeafletsInProductGroupRoute from './pages/LeafletsInProductGroupRoute';
import ConfirmLotteryRegistrationRoute from './pages/ConfirmLotteryRegistrationRoute';
import SearchRoute from './pages/SearchRoute';
import LoginRoute from './pages/LoginRoute';
import ShopsByVendorAndCityRoute from './pages/ShopsByVendorAndCityRoute';
import TermsAndConditionsRoute from './pages/TermsAndConditionsRoute';
import PromotionsInShoppingCenterAndProductGroupRoute from './pages/PromotionsInShoppingCenterAndProductGroupRoute';
import AccountChangePasswordRoute from './pages/AccountChangePasswordRoute';
import NotFoundRoute from './pages/NotFoundRoute';
import PrivacyInfoRoute from './pages/PrivacyInfoRoute';
import LogoutRoute from './pages/LogoutRoute';
import ImprintRoute from './pages/ImprintRoute';
import AccountInfoRoute from './pages/AccountInfoRoute';
import TagDetailRoute from './pages/TagDetailRoute';
import VouchersInProductGroupRoute from './pages/VouchersInProductGroupRoute';
import ShoppingCenterListingRoute from './pages/ShoppingCenterListingRoute';
import LeafletsByVendorInStateRoute from './pages/LeafletsByVendorInStateRoute';
import LeafletListingRoute from './pages/LeafletListingRoute';
import AccountService from './App/modules/Account/services/AccountService';
import ReduxService from './App/../boilerplate/redux/services/ReduxService';
import { connect } from 'react-redux';
import GeolocationService from './App/modules/Base/services/GeolocationService';
import PersonaService from './App/services/PersonaService';
import { isBrowser, isServer } from './boilerplate/razzle/razzleUtils';
import ProductGroupDetailDestRoute from './pages/ProductGroupDetailDestRoute';
import CookieService from './App/services/CookieService';
import { Helmet } from 'react-helmet-async';
import ServusRoute from './pages/ServusRoute';
import LotteryListingRoute from './pages/LotteryListingRoute';
import CookieTrustService from './App/services/CookieTrustService';
import CurrentVendorLeaflet from './pages/CurrentVendorLeafletRoute';
import LeafletInfopostRoute from './pages/LeafletInfopostRoute';
import appConfig from './App/config/appConfig';
import AppExclusiveRoute from './pages/AppExclusiveRoute';
import PrivacyInfoRouteOld from './pages/PrivacyInfoRouteOld';

if (isBrowser()) {
    console.log(`${appConfig.featureVersion} (${version})`);
}

interface IAppPropType {
    ctx?: {
        res: any,
        req: any,
        appProps: any,
    },
    appProps: any,
    geolocationReducer: any,
    setGeolocation: (geolocation: IGeolocationType, expiry: IExpiryType = null) => void;
}

class App extends React.Component<IAppPropType> {
    public constructor (props: IAppPropType) {
        super(props);

        this.componentWillMountCalls();
    }

    private componentWillMountCalls(): void {
        const { geolocationReducer } = this.props;

        // Init
        AccountService.reset();

        // init cookies
        CookieService.init();

        // only trigger the following services, if called by client
        if (isServer()) {
            GeolocationService.initFromSSR(geolocationReducer);
            return;
        }

        this.setGeolocation();
    }

    public componentDidMount(): void {
        // only trigger the following services, if called by client
        if (isServer()) {
            return;
        }

        // Init persona token
        PersonaService.init();

        CookieTrustService.init();
    }

    public render() {
        const { appProps }  = this.props;
        const notFoundRoute = ( appProps && appProps.notFoundRoute ) ? appProps.notFoundRoute : null;

        // Generates route list based on the routes we have in routes.ts4
        // TODO: refactor to service that is run before "yarn start"
        // let lat = "";
        //
        // Object.keys(routes).map((pageFile: string) => {
        //   const routePattern = routes[pageFile];
        //
        //   lat += `
        //       <Route exact={true} path="${routePattern}" component={${pageFile}} />`;
        // })
        //
        // console.log(lat);

        return (
            <React.Fragment>
                {(appProps && typeof appProps.noindex != 'undefined') ?
                    <>
                        {(appProps.noIndex) ?
                            <Helmet>
                                <meta name="robots" content="noindex" />
                            </Helmet>
                            :
                            <Helmet>
                                <meta name="robots" content="index, follow" />
                            </Helmet>
                        }
                    </>
                    : null}

                <Switch>
                    {( notFoundRoute ) ?
                     <Route exact={true} path={notFoundRoute} component={NotFoundRoute} />
                                       : null
                    }
                    <Route exact={true} path="/" component={HomeRoute} />
                    <Route exact={true} path="/widgets/" component={Widgets} />
                    <Route exact={true} path="/v/:vendor/" component={VendorDetailRoute} />
                    <Route exact={true} path="/s/:shop/" component={ShopDetailRoute} />
                    <Route exact={true} path="/prospekte/" component={LeafletListingRoute} />
                    <Route exact={true} path="/ppc/:productGroup/" component={ProductGroupDetailRoute} />
                    <Route exact={true} path="/b/:brand/" component={BrandDetailRoute} />
                    <Route exact={true} path="/aktionen/" component={PromotionListingRoute} />
                    <Route exact={true} path="/p/:promotion/" component={PromotionDetailRoute} />
                    <Route
                        exact={true} path="/ppcv/:productGroup/:vendor/"
                        component={PromotionsInProductGroupAndVendorRoute}
                    />
                    <Route
                        exact={true} path="/ppcc/:productGroup/:city/" component={PromotionsInProductGroupAndCityRoute}
                    />
                    <Route exact={true} path="/gutscheine/" component={VoucherListingRoute} />
                    <Route exact={true} path="/pi/:industry/" component={PromotionsInIndustryRoute} />
                    <Route exact={true} path="/pbv/:brand/:vendor/" component={PromotionsFromBrandAndVendorRoute} />
                    <Route exact={true} path="/vost/:state/" component={VouchersInStateRoute} />
                    <Route exact={true} path="/lst/:state/" component={LeafletsInStateRoute} />
                    <Route exact={true} path="/lv/:vendor/" component={LeafletsByVendorRoute} />
                    <Route exact={true} path="/cvl/:vendor/" component={CurrentVendorLeaflet} />
                    <Route
                        exact={true} path="/appc/:productGroup/" component={AssortementPromotionsInProductGroupRoute}
                    />
                    <Route exact={true} path="/apv/:vendor/" component={AssortementPromotionsByVendorRoute} />
                    <Route exact={true} path="/vov/:vendor/" component={VouchersByVendorRoute} />
                    <Route exact={true} path="/vopc/:productGroup/" component={VouchersInProductGroupRoute} />
                    <Route
                        exact={true} path="/ppci/:productGroup/:industry/"
                        component={PromotionsInProductCategoryAndIndustryRoute}
                    />
                    <Route exact={true} path="/svst/:vendor/:state/" component={ShopsByVendorAndStateRoute} />
                    <Route exact={true} path="/svc/:vendor/:city/" component={ShopsByVendorAndCityRoute} />
                    <Route exact={true} path="/lpc/:productGroup/" component={LeafletsInProductGroupRoute} />
                    <Route exact={true} path="/pv/:vendor/" component={PromotionsByVendorRoute} />
                    <Route exact={true} path="/pst/:state/" component={PromotionsInStateRoute} />
                    <Route exact={true} path="/pbs/:brand/:shop/" component={PromotionsInShopByBrandRoute} />
                    <Route
                        exact={true} path="/ppcb/:productGroup/:brand/"
                        component={PromotionsFromProductGroupAndBrandRoute}
                    />
                    <Route exact={true} path="/pbc/:brand/:city/" component={PromotionsFromBrandAndCityRoute} />
                    <Route exact={true} path="/lvst/:vendor/:state/" component={LeafletsByVendorInStateRoute} />
                    <Route
                        exact={true} path="/ppcst/:productGroup/:state/"
                        component={PromotionsInProductGroupAndStateRoute}
                    />
                    <Route exact={true} path="/suche/" component={SearchRoute} />
                    <Route exact={true} path="/l/:leaflet/" component={LeafletDetailRoute} />
                    <Route exact={true} path="/infopost/:vendorSlug/:stateSlug" component={LeafletInfopostRoute} />
                    <Route exact={true} path="/tw/:themeworld/" component={ThemeworldDetailRoute} />
                    <Route exact={true} path="/sc/:shoppingCenter/" component={ShoppingCenterDetailRoute} />
                    <Route exact={true} path="/v/" component={VendorListingRoute} />
                    <Route exact={true} path="/t/:tag/" component={TagDetailRoute} />
                    <Route
                        exact={true} path="/ppcs/:productGroup/:shop/" component={PromotionsInShopAndProductGroupRoute}
                    />
                    <Route
                        exact={true} path="/ppcsc/:productGroup/:shoppingCenter/"
                        component={PromotionsInShoppingCenterAndProductGroupRoute}
                    />
                    <Route exact={true} path="/pvst/:vendor/:state/" component={PromotionsByVendorInStateRoute} />
                    <Route exact={true} path="/vovst/:vendor/:state/" component={VouchersByVendorInStateRoute} />
                    <Route exact={true} path="/pbst/:brand/:state/" component={PromotionsByBrandInStateRoute} />
                    <Route exact={true} path="/tw/" component={ThemeworldListingRoute} />
                    <Route exact={true} path="/b/" component={BrandListingRoute} />
                    <Route exact={true} path="/bin/:letter/" component={BrandsWithFirstLetterRoute} />
                    <Route exact={true} path="/li/:industry/" component={LeafletsInIndustryRoute} />
                    <Route exact={true} path="/feedback/" component={FeedbackRoute} />
                    <Route exact={true} path="/impressum/" component={ImprintRoute} />
                    <Route exact={true} path="/nutzungsbedingungen/" component={TermsAndConditionsRoute} />
                    <Route exact={true} path="/rechtliche-hinweise/" component={PrivacyInfoRouteOld} />
                    <Route exact={true} path="/datenschutz/" component={PrivacyInfoRoute} />
                    <Route exact={true} path="/sc/" component={ShoppingCenterListingRoute} />
                    <Route exact={true} path="/scst/:state/" component={ShoppingCentersInStateRoute} />
                    <Route exact={true} path="/registrieren/" component={RegistrationRoute} />
                    <Route exact={true} path="/mein-benutzerkonto/bestaetigen/" component={VerifyMailRoute} />
                    <Route exact={true} path="/passwort-vergessen/" component={ForgottenPasswordRoute} />
                    <Route exact={true} path="/passwort-zuruecksetzen/" component={ResetPasswordRoute} />
                    <Route exact={true} path="/login/" component={LoginRoute} />
                    <Route exact={true} path="/logout/" component={LogoutRoute} />
                    <Route exact={true} path="/mein-benutzerkonto/meine-daten/" component={AccountInfoRoute} />
                    <Route exact={true} path="/mein-benutzerkonto/daten-aendern/" component={AccountChangeDataRoute} />
                    <Route exact={true} path="/mein-benutzerkonto/email-aendern/" component={AccountChangeEmailRoute} />
                    <Route
                        exact={true} path="/mein-benutzerkonto/passwort-aendern/" component={AccountChangePasswordRoute}
                    />
                    <Route exact={true} path="/newsletter/bestaetigung/" component={ConfirmNewslettersRoute} />
                    {/* <Route exact={true} path="/cashback/" component={CashbackRoute} /> */}
                    <Route exact={true} path="/servus/" component={ServusRoute} />
                    <Route exact={true} path="/app/" component={AppExclusiveRoute} />
                    <Route exact={true} path="/aktionsfinder-der-woche/" component={LotteryInfoRoute} />
                    <Route
                        exact={true} path="/aktionsfinder-der-woche/mitspielen/" component={LotteryRegistrationRoute}
                    />
                    <Route
                        exact={true} path="/aktionsfinder-der-woche/anmeldung-bestaetigt/"
                        component={ConfirmLotteryRegistrationRoute}
                    />
                    <Route
                        exact={true} path="/aktionsfinder-der-woche/gewinneinloesung/" component={LotteryPaymentRoute}
                    />
                    <Route
                        exact={true} path="/aktionsfinder-der-woche/teilnahmebedingungen/"
                        component={LotteryParticipationConditionsRoute}
                    />
                    <Route
                        exact={true} path="/gewinnen/" component={LotteryListingRoute}
                    />
                    <Route exact={true} path="/einkaufsliste/" component={PromotionBookmarkListingRoute} />
                    <Route exact={true} path="/merkliste/" component={VendorBookmarkListingRoute} />
                    <Route exact={true} path="/404-not-found/" component={NotFoundRoute} />
                    <Route exact={true} path="/ppc/:productGroup/" component={ProductGroupDetailRoute} />
                    <Route exact={true} path="/ppcdest/:productGroup/" component={ProductGroupDetailDestRoute} />
                    <Route exact={true} path="/ppc/:productGroup1/:productGroup/" component={ProductGroupDetailRoute} />
                    <Route
                        exact={true} path="/ppcdest/:productGroup1/:productGroup/"
                        component={ProductGroupDetailDestRoute}
                    />
                    <Route
                        exact={true} path="/ppc/:productGroup1/:productGroup2/:productGroup/"
                        component={ProductGroupDetailRoute}
                    />
                    <Route
                        exact={true} path="/ppcdest/:productGroup1/:productGroup2/:productGroup/"
                        component={ProductGroupDetailDestRoute}
                    />
                    <Route
                        exact={true} path="/ppc/:productGroup1/:productGroup2/:productGroup3/:productGroup/"
                        component={ProductGroupDetailRoute}
                    />
                    <Route
                        exact={true} path="/ppcdest/:productGroup1/:productGroup2/:productGroup3/:productGroup/"
                        component={ProductGroupDetailDestRoute}
                    />
                    <Route
                        exact={true}
                        path="/ppc/:productGroup1/:productGroup2/:productGroup3/:productGroup4/:productGroup/"
                        component={ProductGroupDetailRoute}
                    />
                    <Route
                        exact={true}
                        path="/ppcdest/:productGroup1/:productGroup2/:productGroup3/:productGroup4/:productGroup/"
                        component={ProductGroupDetailDestRoute}
                    />
                    <Route
                        exact={true}
                        path="/ppc/:productGroup1/:productGroup2/:productGroup3/:productGroup4/:productGroup5/:productGroup/"
                        component={ProductGroupDetailRoute}
                    />
                    <Route
                        exact={true}
                        path="/ppcdest/:productGroup1/:productGroup2/:productGroup3/:productGroup4/:productGroup5/:productGroup/"
                        component={ProductGroupDetailDestRoute}
                    />



                    <Route path="" component={NotFoundRoute} />
                </Switch>
            </React.Fragment>
        );
    }

    /**
     * Sets the geolocation
     */
    private async setGeolocation() {
        const location = await GeolocationService.initGeolocationAsync();
        this.props.setGeolocation(location);
    }
}

function mapStateToProps(state: any) {
    return {
        geolocationReducer: state.geolocationReducer,
        appProps: state.appProps,
        cookieTrustGroups: state.generalReducer?.cookieTrustGroups,
    };
}

function mapDispatchToProps(dispatch: (actionType: any) => void) {
    // if we haven't initialized our redux service (an abstraction of dispatch that makes it available globally, init it)
    ReduxService.initDispatcher(dispatch);

    return {
        setGeolocation: (geolocation: IGeolocationType) => {
            DataStateService.refreshStateKey({ geolocationKey : `${geolocation.lng}-${geolocation.lat}`});
            dispatch(setGeolocation(geolocation));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
