import * as React from 'react';
import AppPromotionBanner from '../../../../../modules/Base/AppPromotionBanner/AppPromotionBanner';
import AppExclusiveAdvantages from '../../../../../modules/Base/components/AppExclusive/components/AppExclusiveAdvantages';
import AppExclusiveMainBlock from '../../../../../modules/Base/components/AppExclusive/components/AppExclusiveMainBlock';
import AppExclusiveMobileButton from '../../../../../modules/Base/components/AppExclusive/components/AppExclusiveMobileButton';
import AppExclusiveRatings from '../../../../../modules/Base/components/AppExclusive/components/AppExclusiveRatings';
import DataLayerService from '../../../../../modules/Base/services/DataLayerService';
import CarouselContainer from '../../../../../modules/Carousel/containers/CarouselContainer';
import LeafletGrid from '../../../../../modules/Leaflet/components/LeafletGrid/LeafletGrid';
import LotteryList from '../../../../../modules/Lottery/components/LotteryList/LotteryList';
import MixedEntityGrid from '../../../../../modules/MixedEntity/components/MixedEntityGrid/MixedEntityGrid';
import NewsletterSignupComponent from '../../../../../modules/Newsletter/components/Newsletter/NewsletterSignupComponent';
import ProductPromotionGrid from '../../../../../modules/Promotion/components/ProductPromotionGrid/ProductPromotionGrid';
import ThemeworldCards from '../../../../../modules/Themeworld/components/ThemeworldCards/ThemeworldCards';
import VendorLogoWidgetContainer from '../../../../../modules/Vendor/containers/VendorLogoWidgetContainer/VendorLogoWidgetContainer';
import InternalTrackingService from '../../../../../services/InternalTrackingService';
import ContentBox from '../../../../Layout/PageWrapper/ContentBox';
import AdItem from '../../../AdItem/AdItem';
import { IHomepageExploreType } from '../../types/IHomepageExploreType';
import { IMixedEntityType } from '../../../../../modules/MixedEntity/types/IMixedEntityType';
import * as styles from './HomepageGrid.scss';

interface IHomepageGridProps {
    data: IHomepageExploreType;
    title?: string;
}

class HomepageGrid extends React.Component<IHomepageGridProps, {}> {
  public render() {
    const { data } = this.props;

    return (
      <div className={styles.homepageGridWrapper}>
        <CarouselContainer />

        {/* Current leaflets */}
        <ContentBox
          nested={true}
        >
          <LeafletGrid
            title="Aktuelle Prospekte"
            titleLevel={2}
            leaflets={data.currentLeaflets}
            gridKey="homepage-currentLeaflets"
            columns="5"
            limit={5}
            boxed={true}
            nested={true}
            moreLabel="Alle Prospekte"
            moreRoute="LeafletListingRoute"
            mobileScrollable={true}
          />
        </ContentBox>

        <ContentBox>
          <AppPromotionBanner />
        </ContentBox>

        {/* First vendor leaflets-promotions */}
        {data.vendorLeaflets[0]?.items.length === 0 ? null :
          <MixedEntityGrid
            items={data.vendorLeaflets[0].items}
            columns='5'
            title={`Tipp des Tages: ${data.vendorLeaflets[0]?.vendor.name}`}
            moreLabel='Alle Tipps des Tages'
            moreRoute="LeafletsByVendorRoute"
            moreRouteParams={{
              vendor: data.vendorLeaflets[0]?.vendor.slug
            }}
            mobileScrollable={true}
            onItemClick={(item) => this.trackVendorEntitiesClick(0, item)}
            onItemLoad={(item) => this.trackVendorEntitiesImpression(0, item)}
          />
        }

        {/* Current Promotions I (Bier) */}
        {data.currentPromotionsAzureSearch[0]?.items.length === 0 || !data.currentPromotionsAzureSearch[0].productGroup ? null :
          <MixedEntityGrid
            items={data.currentPromotionsAzureSearch[0].items}
            columns='5'
            title={`Top ${data.currentPromotionsAzureSearch[0].productGroup.title} Aktionen`}
            moreLabel={`Alle ${data.currentPromotionsAzureSearch[0].productGroup.title} Aktionen`}
            moreRoute="ProductGroupDetailRoute"
            moreRouteParams={{
              productGroup: data.currentPromotionsAzureSearch[0].productGroup.slug
            }}
            mobileScrollable={true}
          />
        }

        {/* Supermarket Industry leaflets */}
        <ContentBox
          nested={true}
        >
          <LeafletGrid
            title={data.leafletsByIndustry[0].title}
            titleLevel={2}
            leaflets={data.leafletsByIndustry[0].leaflets}
            gridKey={`homepage-leafletsByIndustry-${data.leafletsByIndustry[0].slug}`}
            columns="5"
            limit={5}
            boxed={true}
            nested={true}
            moreLabel={`Alle ${data.leafletsByIndustry[0].title}-Prospekte`}
            moreRoute="LeafletsInIndustryRoute"
            moreRouteParams={{
              industry: data.leafletsByIndustry[0].slug
            }}
            mobileScrollable={true}
          />
        </ContentBox>

        <AdItem 
          desktopAdId={4403461}
          mobileAdId={4403459}
          maxHeight={250}
          type="content"
          title="Medium Rectangle 1"
        />

        {/* Themeworlds */}
        <ContentBox
          nested={true}
          boxed={true}
        >
          <ThemeworldCards
            title="Themenwelten"
            moreRoute='ThemeworldListingRoute'
            widgetKey="homepage-themeworlds"
            themeworlds={data.currentThemeWorlds}
            boxed={true}
            firstCardFullWidth={false}
            mobileScrollable={true}
            columns='3'
          />
        </ContentBox>

        {/* Top-Deals */}
        <MixedEntityGrid
          items={data.priceHits}
          columns='5'
          title='Top Deals'
          moreLabel='Alle Top-Deals'
          moreRoute="TagDetailRoute"
          moreRouteParams={{
            tag: 'preishits'
          }}
          mobileScrollable={true}
          onItemClick={this.trackPriceHitClick}
          onItemLoad={this.trackPriceHitImpression}
        />

        {/* Current Promotions II */}
        {data.currentPromotionsAzureSearch[1]?.items.length === 0 || !data.currentPromotionsAzureSearch[1].productGroup ? null :
          <MixedEntityGrid
            items={data.currentPromotionsAzureSearch[1].items}
            columns='5'
            title={`Top ${data.currentPromotionsAzureSearch[1].productGroup.title} Aktionen`}
            moreLabel={`Alle ${data.currentPromotionsAzureSearch[1].productGroup.title} Aktionen`}
            moreRoute="ProductGroupDetailRoute"
            moreRouteParams={{
              productGroup: data.currentPromotionsAzureSearch[1].productGroup.slug
            }}
            mobileScrollable={true}
          />
        }

        {/* Lotteries */}
        <LotteryList
          lotteries={data.currentLotteries}
          title="Gewinnspiele"
          columns="3"
          moreRoute='LotteryListingRoute'
          mobileScrollable={true}
        />

        {/* Favorite vendors */}
        <VendorLogoWidgetContainer
          title="Beliebte Händler"
          widgetKey="homepage-vendors-logos-popular"
          limit={16}
          mobileLimit={9}
          moreLabel="Alle Händler"
          moreRoute="VendorListingRoute"
          popular={true}
        />

        <AdItem
          desktopAdId={4403499}
          mobileAdId={4403458}
          maxHeight={250}
          type="content"
          title="Medium Rectangle 2"
        />

        <div className={styles.newsletterBlock}>
          <ContentBox>
            <NewsletterSignupComponent fullWidth={true} />
          </ContentBox>
        </div>

        <ProductPromotionGrid
          title='Top Gutscheine'
          titleLevel={2}
          products={data.topVouchers}
          gridKey="homepage-topVouchers"
          columns="3"
          maxItems={6}
          boxed={true}
          moreLabel="Weitere Gutscheine ansehen"
          moreRoute="VoucherListingRoute"
          useLeafletRoute={false}
          theme="horizontal"
        />

        <div>&nbsp;</div>

        <ContentBox nested={true}>
          <AppExclusiveMainBlock />
          <AppExclusiveAdvantages />
          <AppExclusiveRatings />
          <AppExclusiveMobileButton title='Zur App' href="https://aktionsfinder.page.link/app" />
        </ContentBox>

        <div>&nbsp;</div>
      </div>
    );
  }

    /**
     * Defines the route the product group list widget should be routed towards
     */
    // private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
    //   const { shop } = this.props;

    //   return {
    //     name: 'PromotionsInShopAndProductGroupRoute',
    //     params: {
    //       productGroup: item.group.slug,
    //       shop: shop.slug
    //     }
    //   };
    // };

  /**
   * Track pricehit click out
   */
  private trackPriceHitClick = (priceHit: IMixedEntityType) => {
        InternalTrackingService.track('PRICEHIT_CLICK', {
            promotionId: priceHit.id,
            vendorId: priceHit.vendorId ?? '',
        });

        DataLayerService.push('PRICEHIT_CLICK', {
            brandId: priceHit.brand ? priceHit.brand[0] : '',
            promotionId: priceHit.id,
            promotionTitle: priceHit.title ? priceHit.title : priceHit.title,
            targetUrl: priceHit.clickoutUrl ? priceHit.clickoutUrl : '',
            vendorId: priceHit.vendorId ?? '',
            vendorName: priceHit.vendorTitle ?? '',
        });

        // Track promotion clickout if clickout url is available
        if (priceHit.clickoutUrl) {
          let promotionEventType;
          
          switch (priceHit.promotionType) {
            case 'new_price': promotionEventType = 'CLICKOUT_PRICE_PROMOTION'; break;
            case 'product': promotionEventType = 'CLICKOUT_PRODUCT_PROMOTION'; break;
            case 'assortment': promotionEventType = 'CLICKOUT_ASSORTMENT_PROMOTION'; break;
            case 'voucher': promotionEventType = 'CLICKOUT_VOUCHER_PROMOTION'; break;
          }

          if (promotionEventType) {
            InternalTrackingService.track(promotionEventType, {
              promotionId: priceHit.id,
              targetUrl: priceHit.clickoutUrl,
              vendorId: priceHit.vendorId ?? '',
            });
          }
        }
    };

    /**
     * Track pricehit impression
     */
    private trackPriceHitImpression = (priceHit: IMixedEntityType) => {
        InternalTrackingService.batchTrack(`pricehit-impression-${priceHit.id}`, 'PRICEHIT_IMPRESSION', {
            promotionId: priceHit.id ?? '',
            targetUrl: priceHit.clickoutUrl ?? '',
            vendorId: priceHit.vendorId ?? '',
        });
    };

    /**
     * Track clickout for vendor leaflets and promotions
     */
    private trackVendorEntitiesClick = (vendorIdx: number, item: IMixedEntityType) => {
        if (item.type === 'LEAFLET') {
            InternalTrackingService.track(`LEAFLET_CLICK_VENDOR${vendorIdx + 1}`, {
                leafletId: item.id,
                vendorId: item.vendorId,
            });
        } else if (item.type === 'PROMOTION') {
            InternalTrackingService.track(`PROMOTION_CLICK_VENDOR${vendorIdx + 1}`, {
                promotionId: item.id,
                vendorId: item.vendorId,
            });
        }
    };

    /**
     * Track impression for vendor entities
     */
    private trackVendorEntitiesImpression = (vendorIdx: number, item: IMixedEntityType) => {
      if (item.type === 'LEAFLET') {
        InternalTrackingService.batchTrack(`vendor-leaflet-impression-${item.vendorId}-${item.id}`, `LEAFLET_IMPRESSION_VENDOR${vendorIdx + 1}`, {
          vendorId: item.vendorId,
          leafletId: item.id,
        });
      }
    };
}


export default HomepageGrid;
