import * as React from 'react';
import * as styles from './ProductPromotionDetailHeader.scss';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import ProductPromotionDiscountBarSkeleton from '../ProductPromotionDiscountBar/ProductPromotionDiscountBarSkeleton';


class ProductPromotionDetailHeaderSkeleton extends React.Component<{}> {

	public render () {

		return (
			<div className={styles.Container}>

				<SkeletonBlock maxWidth={20} style={{marginRight: 15}}/>

				<div className={styles.Info}>

					<SkeletonInline width={300}/>
					
					<div>&nbsp;</div>

					<ProductPromotionDiscountBarSkeleton size="l" />

					<div className={styles.SubtitleWrapper}>
						<SkeletonInline width={200}/>
					</div>
				</div>
			</div>
		);
	}
}

export default ProductPromotionDetailHeaderSkeleton;
