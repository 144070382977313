import AfCoreRequest from '../bootstrap/redux-data/AfCoreRequest';
import GeolocationService from '../modules/Base/services/GeolocationService';
import PersonaService from './PersonaService';
import { isServer } from '../../boilerplate/razzle/razzleUtils';
import { IRequestObject } from '../../boilerplate/redux/types/IRequestObject';

const InternalTrackingService = {
    /**
     * Allows the calling of an event
     *
     * @param event
     * @param eventProps
     */
    track(event: string, eventProps: { [key: string]: string | number | boolean }) {
        // only track on client to prevent duplicate tracking
        if (isServer()) {
            return;
        }

        setTimeout(() => {
            const params = Object.assign(
                {
                    event,
                    personaToken: PersonaService.get(),
                },
                eventProps,
            );

            const currentLocation = GeolocationService.getCurrentLocation();
            params.latitude = GeolocationService.shortenLocationParam(currentLocation.lat, 2);
            params.longitude = GeolocationService.shortenLocationParam(currentLocation.lng, 2);

            if (!params.latitude) {
                return;
            }

            const afCoreRequest = new AfCoreRequest('internal-event');
            afCoreRequest
                .postRequest({
                    url: '/api/public/v1/events',
                    body: params,
                } as IRequestObject)
                .then((res: any) => {
                    // don't do anything
                })
                .catch(() => {
                    // don't do anything
                });
        }, 1000);
    },

    /* ############################################################# */
    /* ####################### BATCH TRACKING ###################### */
    /* ############################################################# */

    /**
     * A map of all events to be tracked in the next tracking request
     */
    toBeTracked: {},

    /**
     * A map of all leaflets that have already been tracked by this user.
     * This is to avoid duplicate tracking of the same leaflet in one session.
     */
    alreadyTracked: {},

    /**
     * The batch timeout that will be triggered
     */
    batchTimeoutHolder: null,

    /**
     * Get users persona token
     */
    batchTrack(eventId: string, eventType: string, eventParams: { [key: string]: string | number } = {}): string {
        // if already tracked -> don't do anything
        if (this.alreadyTracked[eventId]) {
            return '';
        }

        // otherwise add it
        this.toBeTracked[eventId] = {
            event: eventType,
            ...eventParams,
        };

        // enqueue the sending
        this.queueBatchSending();

        return '';
    },

    /**
     * Enqueues the sending of batched events, to make sure we can collect a couple of them
     *
     * @returns
     */
    queueBatchSending() {
        if (isServer() || typeof window === 'undefined') {
            return;
        }

        // If we already have a batch event -> clear it to delay it even further
        if (this.batchTimeoutHolder) {
            clearInterval(this.batchTimeoutHolder);
        }

        // otherwise create a new timeout and prepare sending
        this.batchTimeoutHolder = setInterval(() => {
            // send the batch request
            this.makeBatchRequest();
        }, 1200);
    },

    /**
     * Sends the request to log all the open tracking events
     */
    makeBatchRequest() {
        // only track on client to prevent duplicate tracking
        if (isServer()) {
            return;
        }

        const toBeTrackedObj = { ...this.toBeTracked };
        const toBeTrackedArr = Object.keys(toBeTrackedObj);

        // clear the leaflets to be tracked
        this.toBeTracked = {};

        // if we have less than 1 event to be tracked -> do nothing
        if (toBeTrackedArr.length < 1) {
            return;
        }

        // get current location
        const currentLocation = GeolocationService.getCurrentLocation();
        const pt = PersonaService.get();

        // if no lat -> nothing to event about
        if (!currentLocation.lat) {
            return;
        }

        // otherwise generate an action array
        const trackingEvents = toBeTrackedArr.map((eventId: string) => {
            // add it to the list of already tracked leaflets
            this.alreadyTracked[eventId] = 1;

            return {
                ...toBeTrackedObj[eventId],

                // add personaToken
                personaToken: pt,

                // add geolocation to it
                latitude: GeolocationService.shortenLocationParam(currentLocation.lat, 2),
                longitude: GeolocationService.shortenLocationParam(currentLocation.lng, 2),
            };
        }) as IRequestObject;

        const afCoreRequest = new AfCoreRequest('internal-event-list');
        afCoreRequest
            .postRequest({
                url: '/api/public/v1/events/list',
                body: trackingEvents,
            } as IRequestObject)
            .then((res: any) => {
                // don't do anything
            })
            .catch(() => {
                // don't do anything
            });
    },
};

export default InternalTrackingService;
