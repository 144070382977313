import { ActionType } from 'typesafe-actions';
import * as verifyMailActions from './verifyMailActions';
import BaseReducer, { baseInitialState} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';


export type VerifyMailGridAction = ActionType<typeof verifyMailActions>;

export interface IVerifyMailStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: {} };
	readonly queries: { [queryKey: string]: [] };
}

export function verifyMailReducer(
	state = baseInitialState as IVerifyMailStateType,
	action: VerifyMailGridAction,
) {
	const Reducer = new BaseReducer('verifyMailReducer');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			case 'OTHER_ACTION_TYPE':
				return Object.assign({}, innerState, {
					data: null,
					done: false,
					error: null,
					loading: true,
				});

			default:
				return innerState;
		}
	});
}

