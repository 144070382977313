import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import ThemeworldListingContainer from '../App/modules/Themeworld/containers/ThemeworldListing/ThemeworldListingContainer';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import { withClickbait } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class ThemeworldListingRoute extends BaseRoute {
	public render () {

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4493482]}
					sitebarId={4493481}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<ThemeworldListingContainer />

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493481}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		ThemeworldListingRoute,
		(props: any) => `themeworld-listing`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => `Aktuelle Themen und Aktionen`,
			metaTitle: (routeMetadata: IRouteMetadataType) =>'Aktuelle Themen und Aktionen',
			metaDescription: (routeMetadata: IRouteMetadataType) =>
				withClickbait(`Themenbezogene Aktionen und Angebote » kein Schnäppchen verpassen ✓ Rabatt sichern ✓ bares Geld sparen`, `✓ Hier erfahren Sie mehr!`),
		} as IRouteMetadataSetterType,
	),
);