import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import { SlugType } from '../../../Base/types/SlugType'
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import PromotionsAggregatedByVendorsWidgetContainer from '../../containers/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidgetContainer';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IVouchersByVendorProps {
	vendorSlug: SlugType;
}

class VouchersByVendor extends React.Component<IVouchersByVendorProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	public render() {
		const { routeMetadata, vendorSlug } = this.props;

		const vendorName = (routeMetadata && routeMetadata.vendor && routeMetadata.vendor.title) ? routeMetadata.vendor.title : vendorSlug;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridContainer
								limit={40}
								vendorSlug={vendorSlug}
								enablePagination={true}
								fetchVoucherProductPromotions={true}
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								orderBy="createdAt"
								orderDir="desc"
								onlyNearbyPromotions={false}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<PromotionsAggregatedByVendorsWidgetContainer
								widgetKey={`vov-${vendorSlug}-vendor-widget`}
								title={`Gutscheine bei anderen Händlern`}
								limit={5}
								promotionLabel="Gutschein"
								promotionLabelPlural="Gutscheine"
								vendorSlug={vendorSlug}
								fetchVoucherProductPromotions={true}
								routeCallbk={this.routerCallbkVendorListWidget}
								orderBy="promotionCount"
								orderDir="desc"
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Gutscheine',
				route: 'VoucherListingRoute'
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.vendor.title: '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 */
	private routerCallbkVendorListWidget = (item: IPromotionsAggregatedByVendorType) => {

		const { vendorSlug } = this.props;

		return {
			name: 'VouchersByVendorRoute',
			params: {
				vendor: item.group.slug
			},
		};
	};
}

export default VouchersByVendor;
